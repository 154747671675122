/* Imports */
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ContentChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'aerial-floating-bar',
	templateUrl: './floating-bar.html',
	styleUrls: ['./floating-bar.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AerialFloatingBar implements OnInit, AfterViewInit {

	/* Inputs */
	@Input('icon') icon: string = 'Uploading';
	@Input('text') text: string = 'Uploading';
	@Input('hideDismiss') hideDismiss: boolean = false;

	/* Outputs */
	@Output('closeBar') closeBar: EventEmitter<any> = new EventEmitter();

	@ContentChild('bodyRef') bodyWrapper: ElementRef;
	public showToggle: boolean = false;

	/* Variables */
	public expandBar: boolean = false;

	constructor() {

	}	// End-of constructor

	ngOnInit() {

	}	// End-of ngOnInit

	ngAfterViewInit() {

	}	// End-of ngAfterViewInit

    ngAfterContentChecked(): void {

    }

	toggleExpandBar(toggle?: boolean) {

		this.expandBar = (toggle != undefined) ? toggle : !this.expandBar;

	}	// End-of toggleExpandBar

	dismiss(): void {

		this.closeBar.emit(true);

	}	// End-of dismiss

}	// End-of class AerialFloatingBar