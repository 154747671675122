
<ng-container *ngIf="toolbar.elevationModeActivated">
    <div class="elevation-legend-container bottom-right" [ngClass]="{'bottom-bump': map.publicView, 'sidebar-collapsed': !map.sidebar?.expanded}">
      <div #thumbOne class="legend-container" [ngStyle]="{'top': thumbOnePos + 'px'}">
        <div class="elevation-value">{{legendRanges[0] * map.selectedUnits.metersConversion | number:'1.0-0'}} {{map.selectedUnits.id}}</div>
        <div class="thumb"
          (mousedown)="startDraggingThumb($event)"></div>
      </div>
      <div #thumbTwo class="legend-container" [ngStyle]="{'top': thumbTwoPos + 'px'}">
        <div class="elevation-value">{{legendRanges[2] * map.selectedUnits.metersConversion | number:'1.0-0'}} {{map.selectedUnits.id}}</div>
        <div class="thumb"
          (mousedown)="startDraggingThumb($event)"></div>
      </div>
      <div class="mid-container" [ngStyle]="{'top': gradientPos + 'px', 'height': currentRange + 'px'}">
        <p class="elevation-value" [ngStyle]="{'opacity': middleNumberOpacity}">
          {{legendRanges[1] * map.selectedUnits.metersConversion | number:'1.0-0'}} {{map.selectedUnits.id}}
        </p>
        <div class="gradient-bar" [ngStyle]="{'background-image': setLegendGradientColors()}"
          (mousedown)="startDraggingWindow($event)"></div>
      </div>
      <div class="legend-container label-container top" [ngStyle]="{'opacity': maxNumberOpacity}">
        <div class="elevation-value">{{maxElevation * map.selectedUnits.metersConversion | number:'1.0-0'}} {{map.selectedUnits.id}}</div>
      </div>
      <div class="legend-container label-container bottom" [ngStyle]="{'opacity': minNumberOpacity}">
        <div class="elevation-value">{{(maxElevation - globalRange) * map.selectedUnits.metersConversion | number:'1.0-0'}} {{map.selectedUnits.id}}</div>
      </div>
      <div class="hatch-container">
        <div class="hatch big-hatch" [ngStyle]="{'top': '0px'}"></div>
        <div *ngFor="let small of smallHatches" class="hatch lil-hatch" [ngStyle]="{'top': small + 'px'}"></div>
        <div *ngFor="let medium of mediumHatches" class="hatch" [ngStyle]="{'top': medium + 'px'}"></div>
        <div class="hatch big-hatch" [ngStyle]="{'top': '192px'}"></div>
      </div>
      <div class="bar-silhouette"></div>
    </div>
</ng-container>
