import L from "leaflet";

import { MapComponent } from "../map.component";
import { AnnotationType } from "../../viewer/classes/measure";
import { BoxLabelSet } from "./classes/boxLabelSet";
import { MAP_OPTIONS } from "../map.options";

import {
	randomColor,
} from "../utils/map.utils";
import { LabelAnnotation } from "./classes/labelAnnotation";
import { PolyLabelSet } from "./classes/polyLabelSet";
import { TrainingRegion } from "./classes/trainingRegion";
import { Label } from "./classes/labelSetAnnotation";

export class LabelTools {
	map: MapComponent;
	
    publicView: boolean;
	mapPermissions;

	activeLabelSet;
	drawingLabeling = false;
	selectedLabel;
	
	// Helper Props
	startPoint;
	tempRect;

	pointsSoFar;
	tempPoly;

	pointIcon;

	constructor(
		m: MapComponent
	) {
		this.map = m;

		this.pointIcon = L.divIcon({
			className: "measurement-dot-container",
			html: "<div class=\"measurement-dot\"></div>",
			iconAnchor: [ 17, 16 ]
		});
	}

	public async addNewLabelSet(type) {

		const options = {
			projectId: this.map.project.id,
			color: this.getNewLabelSetColor() };

		let newLabelSet: LabelAnnotation|null = null;
		switch(type) {
			case AnnotationType.BOX_LABEL_SET:
				newLabelSet = new BoxLabelSet(options);
				break;

			case AnnotationType.POLY_LABEL_SET:
				newLabelSet = new PolyLabelSet(options);
				break;

			case AnnotationType.TRAINING_REGION:
				newLabelSet = new TrainingRegion(options);
				break;
		}
		
		if(newLabelSet)
		{
			await newLabelSet.create();
			this.map.labelSets.unshift(newLabelSet);
			this.map.renderer.selectAnnotation(newLabelSet);
		}
	}

	public loadLabelAnnotation(labelAnnotation) {

		let newLabelSet: LabelAnnotation|null = null;

		switch(labelAnnotation.type) {
			case AnnotationType.BOX_LABEL_SET:
				newLabelSet = new BoxLabelSet(labelAnnotation, labelAnnotation.descriptors.labels);
				break;

			case AnnotationType.POLY_LABEL_SET:
				newLabelSet = new PolyLabelSet(labelAnnotation, labelAnnotation.descriptors.labels);
				break;

			case AnnotationType.TRAINING_REGION:
				newLabelSet = new TrainingRegion(labelAnnotation, labelAnnotation.descriptors.coordinates);
				break;
		}

		if(newLabelSet)
		{
			this.map.labelSets.unshift(newLabelSet);
		}
	}

	public removeLabelSet(labelSet) {
		const index = this.map.labelSets.indexOf(labelSet);
		if (index > -1) {
			this.map.labelSets.splice(index, 1);
		}
		labelSet.delete();

		this.updateLabelsetVisibility(labelSet);
		this.map.renderer.selectAnnotation(null);
	}

	public enterLabelMode(): void {
		if (!this.map.toolbar.labelingModeActivated) {
			if (this.map.toolbar.elevationModeActivated) {
				this.map.exitElevationMode();
			}
			this.map.header.showMessage("Entering Labeling Mode");
			this.map.threeDMode = false;
			this.map.toolbar.labelingModeActivated = true;
		}
	}

	public exitLabelMode() {
		if (this.map.toolbar.labelingModeActivated || (this.map.sidebar.labelModeActive && this.selectedLabel)) {
			this.map.header.showMessage("Exiting Labeling Mode");
			this.map.toolbar.labelingModeActivated = false;
			this.doneLabeling();
		}
	}

	getNewLabelSetColor(): string {
		for(let color of MAP_OPTIONS.LAYER_GROUP_COLORS) {
			let colorTaken = false;
			for(let labelSet of this.map.labelSets) {
				if(color === labelSet.color) {
					colorTaken = true;
					break;
				}
			}
			if(!colorTaken) return color;
		}
		return '#' + randomColor();
	}

	selectLabel(labelSet, label) {
		this.resetLabelSelection();
		this.activeLabelSet = labelSet;
		this.selectedLabel = label;
		this.map.sidebar.selectedLabel = label;

		if (this.map.sidebar.labelModeActive) {
			this.map.sidebar.labelModeActive = false;
			this.exitLabelMode();
		}
		
		label.leafletLayer.setStyle( {color: labelSet.color, weight: 4, fill: ''} );
		this.addResizePoints(labelSet, label);
	}

	addResizePoints(labelSet, label) {
		this.removeResizePoints();

		for (let i = 0; i < label.coordinates.length; i++) {
			this.renderPointCoords(label.coordinates[i], labelSet, i);
		}
	}

	renderPointCoords( coord, labelSet, i ) {
		let lat = 0;
		let lng = 0;

		if (labelSet.type === AnnotationType.BOX_LABEL_SET) {
			lat = coord[0];
			lng = coord[1];
		} else if (labelSet.type === AnnotationType.POLY_LABEL_SET) {
			lat = coord.lat;
			lng = coord.lng;
		}

		let resizePoint = L.marker([lat, lng], {icon: this.pointIcon, draggable: this.map.mapPermissions.canAnnotate})?.addTo(labelSet.layer);
		resizePoint.index = i;

		resizePoint.on( "drag", this.handlePointDrag, this );
		resizePoint.on( "dragstart", this.handlePointDragStart, this );
		resizePoint.on( "dragend", this.handlePointDragEnd, this );
	}

	handlePointDrag( e ): void {
		this.updateLabelCoords(e);
	}

	handlePointDragStart( e ): void {
		e.target.setOpacity( 0.50 );
	}

	handlePointDragEnd( e ): void {
		e.target.setOpacity( 1 );
		this.updateLabelCoords(e);
	}

	updateLabelCoords(e) {
		if (e.type === 'drag') {
			if (this.activeLabelSet.type === AnnotationType.BOX_LABEL_SET) {
				this.selectedLabel.coordinates[e.target.index] = [e.latlng.lat, e.latlng.lng];
				this.selectedLabel.leafletLayer.setBounds([this.selectedLabel.coordinates[0], this.selectedLabel.coordinates[1]]);
			} else if (this.activeLabelSet.type === AnnotationType.POLY_LABEL_SET) {
				this.selectedLabel.coordinates[e.target.index] = e.latlng;
				this.selectedLabel.leafletLayer.setLatLngs(this.selectedLabel.coordinates);
			}
		} else if (e.type === 'dragend') {
			this.activeLabelSet.save();
		}
	}

	removeResizePoints() {
		this.map.labelSets.forEach((labelSet) => {
			labelSet.layer?.eachLayer( (layer:any ) => {
				if (layer.options.icon) {
					layer.removeFrom(labelSet.layer)
				}
			})
		})
	}

	resetLabelSelection() {
		this.map.labelSets.forEach((labelSet) => {
			labelSet.layer?.eachLayer( (layer:any ) => {
				if (!layer.options.icon) {
					layer?.setStyle( {color: labelSet.color, weight: 2, fill: ''} );
				}
			})
		})
	}

	startLabeling(labelSet) {
		this.map.renderer.annotating = true;
		this.enterLabelMode();

		this.activeLabelSet = labelSet;
		this.activeLabelSet.visible = true;
		this.showLabelAnnotation(this.activeLabelSet);

		// TODO - switch up the cursor
		//this._leafletViewer._mapPane.parentElement.style.cursor = "crosshair !important";

		let mouseClickFunc = this.boxLabelClick;
		let mouseDownFunc = this.boxLabelMouseDown;
		let mouseMoveFunc = this.boxLabelMouseMove;


		if(labelSet.type === AnnotationType.POLY_LABEL_SET)
		{
			mouseClickFunc = this.polyLabelClick;
			mouseDownFunc = this.polyLabelMouseDown;
			mouseMoveFunc = this.polyLabelMouseMove;
		}
		else if(labelSet.type === AnnotationType.TRAINING_REGION) {
			
			// ghost rect selector-cursor
			this.tempRect = L.polygon([[0,0], [0,0]], {color: this.activeLabelSet.color, weight: 2, fill: ''});
			this.tempRect.addTo(this.activeLabelSet.layer);

			mouseClickFunc = this.trainingRegionClick;
			mouseDownFunc = this.trainingRegionMouseDown;
			mouseMoveFunc = this.trainingRegionMouseMove;
		}

		this.map.renderer._twoDRenderer.leafletViewer.on("mousemove", mouseMoveFunc);
		this.map.renderer._twoDRenderer.leafletViewer.on("click", mouseClickFunc);
		this.map.renderer._twoDRenderer.leafletViewer.on("mousedown", mouseDownFunc);
	}

	// Box Label Drawing
	boxLabelClick = (e) => {
		if(this.drawingLabeling) {
			// finishing drawing
			this.drawingLabeling = false;
			this.tempRect.setBounds([this.startPoint, e.latlng]);
			this.activeLabelSet.labels.push({
				nameId: this.activeLabelSet.assignUniqueNameId(),
				coordinates:[[this.startPoint.lat, this.startPoint.lng], [e.latlng.lat, e.latlng.lng]],
				leafletLayer: this.tempRect
			});
			this.tempRect = null;
			this.activeLabelSet.save();
		} else if (e.type === "click") {
			// start drawing
			this.drawingLabeling = true;
			this.startPoint = e.latlng;
			this.tempRect = L.rectangle([this.startPoint, this.startPoint], {color: this.activeLabelSet.color, weight: 2, fill: ''});
			this.tempRect.addTo(this.activeLabelSet.layer);
		}
	}

	// for right clicks only
	boxLabelMouseDown = (e) => {
		if(e.originalEvent.button == 2) {
			// stop labeling
			this.tempRect?.remove();
			this.drawingLabeling = false;
		}
	};

	boxLabelMouseMove = (e) => {
		if(this.drawingLabeling && this.tempRect) {
			this.tempRect.setBounds([this.startPoint, e.latlng])
		}
	};

	// Polygon Label Drawing
	polyLabelClick = (e) => {
		if(this.drawingLabeling) {
			// add another point
			this.pointsSoFar.push(e.latlng);
			this.tempPoly.setLatLngs(this.pointsSoFar)
		} else {
			// start drawing
			this.drawingLabeling = true;
			this.pointsSoFar = [e.latlng];
			this.tempPoly = L.polygon([...this.pointsSoFar, ...this.pointsSoFar],
				{ color: this.activeLabelSet.color, weight: 2, fill: (this.activeLabelSet.color + '44') });
			this.tempPoly.addTo(this.activeLabelSet.layer);
		}
	};

	polyLabelMouseDown = (e) => {
		if(e.originalEvent.button == 2) {
			// finish the polygon

			this.tempPoly.setLatLngs(this.pointsSoFar);
			if(this.pointsSoFar.length >= 3) {
				this.activeLabelSet.labels.push({
					nameId: this.activeLabelSet.assignUniqueNameId(),
					coordinates: this.pointsSoFar,
					leafletLayer: this.tempPoly
				});
				this.activeLabelSet.save();
			}
			else {
				this.tempPoly.remove();
			}

			this.tempPoly = null;
			this.pointsSoFar = [];
			this.drawingLabeling = false;
		}
	};

	polyLabelMouseMove = (e) => {
		if(this.drawingLabeling && this.tempPoly) {
			this.tempPoly.setLatLngs([...this.pointsSoFar, e.latlng])
		}
	};

	// Training Region Drawing
	trainingRegionClick = (e) => {
		if(this.drawingLabeling) {
			// finishing drawing
			this.drawingLabeling = false
			let endPoint = this.activeLabelSet.toTile_SE(e.latlng);
			
			this.activeLabelSet.updateTrainingRegionStats(this.startPoint, endPoint);
			this.tempRect.setLatLngs(this.activeLabelSet.twoPointsToTileRects(this.startPoint, endPoint));

			this.activeLabelSet.coordinates = [[this.startPoint.lat, this.startPoint.lng], [endPoint.lat, endPoint.lng]];
			this.activeLabelSet.save();

			this.activeLabelSet.layer.clearLayers();

			this.tempRect.addTo(this.activeLabelSet.layer);

			this.tempRect = L.polygon([[0,0], [0,0]], {color: this.activeLabelSet.color, weight: 2, fill: ''});
			this.tempRect.addTo(this.activeLabelSet.layer);
		} else {
			// start drawing
			this.drawingLabeling = true;
			this.startPoint = this.activeLabelSet.toTile_NW(e.latlng);	
		}
	}

	trainingRegionMouseDown = (e) => {
		if(e.originalEvent.button == 2) {
			// stop labeling
			this.doneLabeling();
			this.map.renderer.selectAnnotation(null);
		}
	};

	trainingRegionMouseMove = (e) => {
		if(this.tempRect) {
			if(this.drawingLabeling) {
				
				this.activeLabelSet.updateTrainingRegionStats(this.startPoint, this.activeLabelSet.toTile_SE(e.latlng));
				this.tempRect.setLatLngs(this.activeLabelSet.twoPointsToTileRects(this.startPoint, this.activeLabelSet.toTile_SE(e.latlng)))
			} else {
				let nwTile = this.activeLabelSet.toTile_NW(e.latlng);
				let seTile = this.activeLabelSet.toTile_SE(e.latlng);
				let points = [];
				// @ts-ignore
				points.push([nwTile.lat, nwTile.lng])// @ts-ignore
				points.push([nwTile.lat, seTile.lng])// @ts-ignore
				points.push([seTile.lat, seTile.lng])// @ts-ignore
				points.push([seTile.lat, nwTile.lng])// @ts-ignore
				points.push([nwTile.lat, nwTile.lng])

				this.tempRect.setLatLngs(points)
			}
		}
	};

	doneLabeling() {

		this.drawingLabeling = false;
		this.map.renderer.annotating = false;

		this.tempRect?.remove();
		this.tempPoly?.remove();

		if (!this.selectedLabel) {
			this.activeLabelSet = null;
		}

		// TODO - switch up the cursor, put it back to normal
		//this._leafletViewer._mapPane.parentElement.style.cursor = "";

		this.map.renderer._twoDRenderer.leafletViewer.off("click", this.boxLabelClick);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousedown", this.boxLabelMouseDown);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousemove", this.boxLabelMouseMove);

		this.map.renderer._twoDRenderer.leafletViewer.off("click", this.polyLabelClick);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousedown", this.polyLabelMouseDown);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousemove", this.polyLabelMouseMove);

		this.map.renderer._twoDRenderer.leafletViewer.off("click", this.trainingRegionClick);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousedown", this.trainingRegionMouseDown);
		this.map.renderer._twoDRenderer.leafletViewer.off("mousemove", this.trainingRegionMouseMove);
	}

	public showLabelAnnotation(labelAnnotation): void {
		if(labelAnnotation.layer) {
			this.map.renderer._twoDRenderer.leafletViewer.addLayer(labelAnnotation.layer);
		} else {
			this.map.renderer._twoDRenderer.leafletViewer.addLayer(labelAnnotation.createLayer());
		}
	}

	public hideLabelSet(labelSet): void {
		if(labelSet.layer)
			this.map.renderer._twoDRenderer.leafletViewer.removeLayer(labelSet.layer);
	}

	updateLabelsetVisibility(labelSet): void {
		if(labelSet.visible) {
			this.showLabelAnnotation(labelSet);
			this.map.renderer.switchView_2D();
		} else {
			this.hideLabelSet(labelSet);
		}
	}

	focusOnLabelSet(labelSet): void {
		// find the centerpoint of the labelset and go there?
	}

	focusOnLabelSetLabel(labelSet, label): void {
		// go to a specific label
		if(label?.coordinates?.length > 1) {
			this.map.renderer._twoDRenderer.leafletViewer.setView(
				labelSet.getLabelCoordinates(label), 18);
		}
	}
}