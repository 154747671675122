<div class="image" [ngClass]=" (uploaded ? 'uploaded ' : '')  + listType" matTooltip="Open" matTooltipShowDelay="1000">

	<span class="image-selection" *ngIf="listType === 'list'">
		<mat-checkbox style="flex: none;" [checked]="selected" (change)="onSelectionChange($event)"></mat-checkbox>
	</span>

	<span class="image-index">{{ index + 1 }}</span>

	<div *ngIf="!image.medium_url" class="view-skel-item view-skel-shimmer thumbnail"></div>

	<span class="image-container" *ngIf="(image.medium_url)">
		<img class="thumbnail" data-max-retry="5" data-retry="0" (error)="waitAndReload($event)"
			[src]="file_url + (listType === 'large' ? image.medium_url : image.small_url)" alt="Image" loading="lazy">

	</span>

	<div (click)="openImage(image)" class="overlay" [ngClass]="{'show-info': showInfo}">
		<div class="overlay-content">
			<p class="ellipsis">{{image.name}}</p>

			<ng-container *ngIf="isComplete(image)">
				<ng-container *ngTemplateOutlet="t_imageDetails"></ng-container>
			</ng-container>
			<ng-container *ngIf="!isComplete(image)">
				<ng-container *ngTemplateOutlet="t_imageUploadState"></ng-container>
			</ng-container>
		</div>

		<span class="floating-icon multi" *ngIf="sensor"
			  matTooltip="Show Multispectral Bands"
			  [matTooltipDisabled]="!image.bands?.length"
			  [matMenuTriggerFor]="multispecMenu"
			  (click)="$event.stopPropagation()">
			<mat-icon>burst_mode</mat-icon>
			<p *ngIf="showInfo && listType !== 'list'">
				{{ image.bands?.length ?? 0 }} bands
			</p>

			<mat-menu #multispecMenu="matMenu">
				<div class="bands-menu">
					<ng-container *ngIf="image.bands?.length">
						<span class="bands-menu-container" *ngFor="let band of image.bands" (click)="openImage(band); $event.stopPropagation();">
							<img
								class="thumbnail"
								data-max-retry="5"
								data-retry="0"
								(error)="waitAndReload($event)"
								[src]="file_url + band.small_url"
								alt="Band"
								loading="lazy">
							<p>{{ band.name }}</p>
						</span>
					</ng-container>
					<ng-container *ngIf="!image.bands?.length">No multispectral bands to show.</ng-container>
				</div>
			</mat-menu>
		</span>

		<span class="icon-container">
			<span *ngIf="listType !== 'list'" class="icon image-info" (click)="toggleInfo($event);"
				  [matTooltip]="showInfo ? 'Hide Image Info' : 'Show Image Info'"
				  [matTooltipShowDelay]="showInfo ? 0 : 400">
				<mat-icon>{{showInfo ? 'close' : 'help_outline'}}</mat-icon>
			</span>

			<span *ngIf="image.favorited" class="icon favorite"
				  matTooltip="Favorited"
				  [matTooltipShowDelay]="showInfo ? 0 : 400">
				<mat-icon> grade </mat-icon>
			</span>
			<ng-container *ngTemplateOutlet="t_menu"></ng-container>
		</span>
	</div>
</div>

<ng-template #t_menu>
	<span class="icon image-options" (click)="openMenu($event);" matTooltip="Options"
		  [ngClass]="{'disabled': !isComplete(image)}"
		  [matTooltipShowDelay]="showInfo ? 0 : 400">

		<mat-icon class="list-item-icon">
			more_horiz
		</mat-icon>

		<button
			class="context-button"
			#itemMenuTrigger
			[matMenuTriggerFor]="itemMenu">
		</button>

		<mat-menu #itemMenu="matMenu">
			<ng-template matMenuContent>
				<button mat-menu-item (click)="outEvent('open');">
					<mat-icon> open_in_new </mat-icon>
					Open Image
				</button>
				<button mat-menu-item (click)="outEvent('download');">
					<mat-icon> vertical_align_bottom </mat-icon>
					Download
				</button>
				<button mat-menu-item (click)="outEvent('move');" [disabled]="disableMove"  [ngClass]="{'disabled': !hasPermissions}">
					<mat-icon> drive_file_move_outline </mat-icon>
					Move
				</button>
				<button mat-menu-item (click)="outEvent('favorite');">
					<mat-icon> {{image.favorited ? 'grade' : 'star_border'}} </mat-icon>
					{{image.favorited ? 'Remove from Favorites' : 'Add to Favorites'}}
				</button>
				<button mat-menu-item (click)="outEvent('delete-images');" [ngClass]="{'disabled': !hasPermissions}">
					<mat-icon> delete_outline </mat-icon>
					Move to Trash
				</button>
			</ng-template>
		</mat-menu>
	</span>
</ng-template>

<ng-template #t_imageDetails>
	<p *ngIf="image.created_at">
		{{image.created_at | date}}
		<span class="H7Black500Regular" *ngIf="uploaded"> (New Upload)</span>
	</p>
	<p *ngIf="!image.created_at">No Date</p>
	<p *ngIf="image.size">{{image.size | bytes}}</p>
	<p *ngIf="!image.size">- - -</p>
	<p *ngIf="image.status">{{image.status | titlecase}}</p>
	<p *ngIf="!image.status">- - -</p>
	<p *ngIf="image.pixels">{{image.pixels | bytes:'pixels'}}</p>
	<p *ngIf="!image.pixels">- - -</p>
	<p *ngIf="image.latitude && image.longitude">({{image.latitude | number:'1.0-4'}}, {{image.longitude |
		number:'1.0-4'}})</p>
	<p *ngIf="!(image.latitude && image.longitude)">No Location Data</p>
	<p *ngIf="image.x_res && image.y_res">{{image.x_res}} x {{image.y_res}}</p>
	<p *ngIf="!(image.x_res && image.y_res)">Unknown Resolution</p>
</ng-template>

<ng-template #t_imageUploadState>
	<div class="upload-state">
		<ng-container *ngIf="isFailure(image)">
			Image conversion failed, please re-upload.
		</ng-container>
		<ng-container *ngIf="!isFailure(image)">
			<p>Uploading...</p>
			<ng-container *ngIf="image.percentageComplete">
				<app-upload-circle [percentage]="image.percentageComplete"></app-upload-circle>
			</ng-container>
			<span class="spinning-loader-container" *ngIf="!image.percentageComplete">
				<span class="spinning-loader"></span>
			</span>
		</ng-container>
	</div>
</ng-template>

