import { get as t, GLOBAL as o, set as e, remove as r } from "@analytics/global-storage-utils";
export { GLOBAL, globalContext } from "@analytics/global-storage-utils";
import { hasCookies as a, COOKIE as i, getCookie as n, setCookie as s, removeCookie as l } from "@analytics/cookie-utils";
export { COOKIE, getCookie, hasCookies, removeCookie, setCookie } from "@analytics/cookie-utils";
import { hasLocalStorage as u, LOCAL_STORAGE as c } from "@analytics/localstorage-utils";
export { LOCAL_STORAGE, hasLocalStorage } from "@analytics/localstorage-utils";
import { hasSessionStorage as g, SESSION_STORAGE as f } from "@analytics/session-storage-utils";
import { isObject as m, ANY as S, ALL as p, isUndefined as v, isString as y } from "@analytics/type-utils";
export { ALL, ANY } from "@analytics/type-utils";
function I(t) {
  var o = t;
  try {
    if ("true" === (o = JSON.parse(t))) return !0;
    if ("false" === o) return !1;
    if (m(o)) return o;
    parseFloat(o) === o && (o = parseFloat(o));
  } catch (t) {}
  if (null !== o && "" !== o) return o;
}
var k = u(),
  x = g(),
  C = a();
function L(o, e) {
  if (o) {
    var r = A(e),
      a = !E(r),
      i = d(r) ? I(localStorage.getItem(o)) : void 0;
    if (a && !v(i)) return i;
    var s = h(r) ? I(n(o)) : void 0;
    if (a && s) return s;
    var l = N(r) ? I(sessionStorage.getItem(o)) : void 0;
    if (a && l) return l;
    var u = t(o);
    return a ? u : {
      localStorage: i,
      sessionStorage: l,
      cookie: s,
      global: u
    };
  }
}
function O(r, a, l) {
  if (r && !v(a)) {
    var u = {},
      g = A(l),
      m = JSON.stringify(a),
      S = !E(g);
    return d(g) && (u[c] = G(c, a, I(localStorage.getItem(r))), localStorage.setItem(r, m), S) ? u[c] : h(g) && (u[i] = G(i, a, I(n(r))), s(r, m), S) ? u[i] : N(g) && (u[f] = G(f, a, I(sessionStorage.getItem(r))), sessionStorage.setItem(r, m), S) ? u[f] : (u[o] = G(o, a, t(r)), e(r, a), S ? u[o] : u);
  }
}
function b(t, e) {
  if (t) {
    var a = A(e),
      n = L(t, p),
      s = {};
    return !v(n.localStorage) && d(a) && (localStorage.removeItem(t), s[c] = n.localStorage), !v(n.cookie) && h(a) && (l(t), s[i] = n.cookie), !v(n.sessionStorage) && N(a) && (sessionStorage.removeItem(t), s[f] = n.sessionStorage), !v(n.global) && F(a, o) && (r(t), s[o] = n.global), s;
  }
}
function A(t) {
  return t ? y(t) ? t : t.storage : S;
}
function d(t) {
  return k && F(t, c);
}
function h(t) {
  return C && F(t, i);
}
function N(t) {
  return x && F(t, f);
}
function E(t) {
  return t === p || "all" === t;
}
function F(t, o) {
  return t === S || t === o || E(t);
}
function G(t, o, e) {
  return {
    location: t,
    current: o,
    previous: e
  };
}
var J = {
  setItem: O,
  getItem: L,
  removeItem: b
};
export default J;
export { L as getItem, b as removeItem, O as setItem };
