import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ngConnection } from "@app/store";
import { activate, deactivate } from "@app/react/components/Portal";

const connect = ngConnection( {
	mapDispatchToProps: { activate, deactivate }
} );

@Component( {
	selector: "portal-hook",
	template: `
		<div
			class="portal-hook"
			[attr.data-portal-hook]="selector"
		></div>`
} )
export class PortalHookComponent implements OnInit, OnDestroy {
	@Input( "selector" ) selector = "";

	activate;
	deactivate;

	constructor() {
		connect(this)
	}

	// might need to be ngAfterViewInit
	ngOnInit(): void {
		this.activate( this.selector );
	}

	ngOnDestroy(): void {
		this.deactivate( this.selector );
	}
}
