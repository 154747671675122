
<div class="container">
	<ng-container *ngFor="let i of itemArray">
		<span class="tag-container">
			<span class="tag-content">{{i}}</span>
			<button class="tag-remove-button" type="button" mat-button (click)="deleteTag(i)" matTooltip="Remove">
				<mat-icon class="tag-icon">cancel_outline</mat-icon>
			</button>
		</span>
	</ng-container>
	<div class="input-line">
		<input class="input-text"
			type="text"
			placeholder="{{itemArray.length > 0 ? dirtyText : pristineText}}"
			autocomplete="off"
			maxlength="16"
			[matAutocomplete]="auto"
			(keydown.enter)="onEnter()"
			[formControl]="tagControl"
			data-testid="new_tag_field">
		<mat-autocomplete #auto="matAutocomplete" class="autocomplete" (optionSelected)="onEnter()">
			<mat-option
				class="option"
				*ngFor="let opt of filteredTagSuggestions | async"
				[value]="opt">
				<div class="epsg-view">{{ opt }}</div>
			</mat-option>
		</mat-autocomplete>
		<span class="input-tip tag-length" *ngIf="tagControl.value?.length">{{tagControl.value.length}} of 16</span>
		<span class="input-tip" *ngIf="tagControl.value?.length">{{hintText}}</span>
	</div>
</div>

