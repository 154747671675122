import {ViewerLayer, ViewerLayerGroup} from '@app/pages/viewer';

// Model Model
export class Model {

	public id: 			number;
	public project_id:	number;
	public name: 		string;
	public status: 		string;
	public batch_ids:	any;
	public descriptors: object;
	public created_by_user_id: number;
	public created_at: Date;
	public updated_at: Date;
	public active:		number;
	public enabled?:		boolean;
	public isLayer?:		boolean;
	public trash: 		number;
	public cesium_files: CesiumFile[];
	public potree_files: PotreeFile[];
	public public_guid: string;
	public public_share: boolean;
	public geotiffs: Geotiff[];
	public credits_organization_id: number; // Set to another org to use that org's processing credits
	public payment_source_id: string;
	public gp: number;
	public layers: ViewerLayer[];

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of class ModelModel

export class ProcessOrder {
	public project_id?: number;
	public name?: string; // TODO: Remove optional with
	public batch_ids:	Array<number>;
	public descriptors: object;
	public payment_source_id: {
		resolution: string,
		key_points: number,
		tie_points: number
	};

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
}

// export interface Model {
// 	id: number;
// 	project_id: number;
// 	name: string;
// 	status: string;
// 	renders: any;
// 	descriptors: object;
// 	created_by_user_id: number;
// 	created_at: Date;
// 	updated_at: Date;
// 	active: number;
// 	trash: number;
// 	cesium_files: CesiumFile[];
// 	potree_files: PotreeFile[];
// 	geotiffs: Geotiff[];
// }

interface CesiumFile {
	id: number;
	model_id: number;
	size: number;
	guid: string;
	json_file: string;
	status: string;
	active: number;
	source: string;
	created_by_user_id: number;
	created_at: Date;
	updated_at: Date;
	url: string;
}

interface PotreeFile {
	id: number;
	model_id: number;
	size: number;
	guid: string;
	json_file: string;
	status: string;
	active: number;
	source: string;
	created_by_user_id: number;
	created_at: Date;
	updated_at: Date;
	url: string;
}

interface Geotiff {
	id: number;
	type: string;
	guid: string;
	uploaded_at: Date;
	tiled_at: Date;
	north: number;
	south: number;
	east: number;
	west: number;
	zoom: number;
	size: number;
	inspection_id?: any;
	model_id: number;
	active: number;
	source: string;
	created_by_id: number;
	created_at: Date;
	updated_at: Date;
	url: string;
	fullName?: string;
}
