
<div class="image-group-container" *ngIf="imageGroup?.id">
	<div class="image-group-header">
		<div class="image-group-title">
			<mat-form-field class="target-highlight" *ngIf="editName">
				<input [maxLength]="maxLength" minLength="1" matInput [(ngModel)]="editNameString">
				<span class="form-count">{{ editNameString.length }} / {{ maxLength }}</span>
			</mat-form-field>

			<p *ngIf="!editName">{{imageGroup.name}}
				<mat-icon class="multi"
				  *ngIf="imageGroup.sensor && !editName"
				  matTooltip="Multispectral Image Group"
				  [matTooltipDisabled]="!imageGroup.sensor">
					burst_mode
				</mat-icon>
			</p>


			<ng-container *ngIf="editName">
				<mat-icon [ngClass]="{'disabled': disableRename(editNameString)}"
						  matTooltip="Confirm"
						  matTooltipShowDelay="500"
						  (click)="submitEdit()">
					check
				</mat-icon>
				<mat-icon
					matTooltip="Cancel"
					matTooltipShowDelay="500"
					(click)="toggleEditName()">
						close
				</mat-icon>
			</ng-container>
			<ng-container *ngIf="!editName">
				<mat-icon
					matTooltip="Rename"
					matTooltipShowDelay="500"
					(click)="toggleEditName()"
					[ngClass]="{'disabled': !hasPermissions}">
						edit
				</mat-icon>
			</ng-container>
		</div>
		<span class="image-group-actions">
			<button mat-icon-button
					(click)="outEvent('upload');"
					matTooltip="Upload"
					matTooltipShowDelay="250"
					[ngClass]="{'disabled': !hasPermissions}">
				<mat-icon> upload </mat-icon>
			</button>
			<button mat-icon-button
					(click)="outEvent('download');"
					matTooltip="Download All"
					matTooltipShowDelay="250">
				<mat-icon> download </mat-icon>
			</button>
			<button mat-icon-button
					(click)="outEvent('delete');"
					matTooltip="Trash Image Group"
					matTooltipShowDelay="250"
					[ngClass]="{'disabled': !hasPermissions}">
				<mat-icon> delete_outline </mat-icon>
			</button>
		</span>
	</div>

	<div class="image-group-details">
		<p class="image-group-subtitle">Image Group Details</p>
		<div class="image-group-detail-items">
			<div class="image-group-detail-item">
				<p class="H7Black500Regular">Created on:</p>
				<p *ngIf="!isLoading" class="H7Black700Regular">{{imageGroup.created_at | date}}</p>
			</div>
			<div class="image-group-detail-item">
				<p class="H7Black500Regular">Images:</p>
				<p *ngIf="!isLoading" class="H7Black700Regular">{{imageGroup.images_count ?? 0}}</p>
			</div>
			<div class="image-group-detail-item">
				<p class="H7Black500Regular">Total Size:</p>
				<p *ngIf="!isLoading" class="H7Black700Regular">{{imageGroup.size ?? 0 | bytes}}</p>
			</div>
		</div>
		<div class="image-group-detail-items" *ngIf="imageGroup.sensor">
			<div class="image-group-detail-item">
				<p class="H7Black500Regular">Multispectral Sensor:</p>
				<p *ngIf="!isLoading" class="H7Black700Regular">{{imageGroup.sensor | uppercase}}</p>
			</div>
		</div>
	</div>

	<div class="image-group-content">
		<ng-container>
			<app-images [showInfo]="showInfo"
						[imageGroup]="imageGroup"
						[disableMove]="disableMove"
						(outEvent)="outEvent($event)"
						[hasPermissions]="hasPermissions">
			</app-images>
		</ng-container>
		<div *ngIf="isLoading" class="loading-container">
			<div class="dot-stretching"></div>
		</div>
	</div>
</div>
