import { Component, Input, ViewChild } from "@angular/core";
import { MatMenu } from "@angular/material/menu";

import { environment } from "@/environments/environment";
import { UtilsService } from "@shared/services";

import { MapComponent } from "../map.component";
import { MAP_OPTIONS } from "../map.options";

export const MAP_TOOLS = {
	HAND: "hand",
	MARKER: "marker",
	POLYLINE: "polyline",
	ELEVATION: "elevation"
}

@Component({
	selector: "app-map-tools",
	styleUrls: ["./map.tools.component.scss"],
	templateUrl: "./map.tools.component.html",
})
export class MapToolsComponent {

	@Input() map: MapComponent;
	@Input() publicView: boolean;
	@Input() mapPermissions;

	@ViewChild(MatMenu) supportMenu: MatMenu;
	@ViewChild(MatMenu) settingsMenu: MatMenu;

	public activeTool = MAP_TOOLS.HAND;

	public elevationModeActivated = false;
	public labelingModeActivated = false;

	public activeMenu;
	options = MAP_OPTIONS;
	public hasElevationViewLayers: boolean;

	public pointSize = 8;

	/* Support Info */
	public supportLink: string = environment.supportEmail;
	public supportGuide: string = environment.supportGuide;

	public selectTool(tool: string): void {
		this.activeTool = tool;
	}

	public activateTool(tool: string): void {
		this.activeTool = tool;
		switch(this.activeTool) {
			case MAP_TOOLS.MARKER:
				this.map.renderer.startPlacingMarker();
				break;

			case MAP_TOOLS.POLYLINE:
				this.map.renderer.startPlacingPolyline();
				break;

			default: this.map.renderer.startHandTool();
				break;
		}
	}

	public toggleComparisonMode() {
		if (!this.map.comparisonModeActivated) {
			this.map.enterComparisonMode();
			this.map.sidebar.dataFilter = 'layers';
		} else {
			this.map.exitComparisonMode();
		}
	}

	public toggleElevationMode() {

		this.elevationModeActivated = !this.elevationModeActivated;

		if (this.elevationModeActivated) {
			this.map.enterElevationMode();
		} else {
			this.map.exitElevationMode();
		}
	}

	public getElevationTooltip() {
		if (this.hasElevationViewLayers) {
			return 'Elevation View';
		} else {
			return 'Elevation View Disabled: No point cloud layers available';
		}
	}

	public updatePointSize(size): void {
		this.map.renderer.updatePointSize(size / 10);
	}

	public selectMenu(option) {
		this.activeMenu = option;
	}

	public menuClosed(): void {
		this.activeMenu = '';
	}

	public sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	public openFAQ(): void {
		window.open(this.supportGuide, "_blank");
	}
}
