/* Imports */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";

/* Services */
import { GlobalService, Measurement } from "@shared/services";
import { MeasurementService, PotreeService } from "../../shared/services";
import { analyticsLayer } from "@shared/analyticsLayer";

/* Classes */
import { LAYER_TYPES_3D, ViewerLayer, ViewerLayerGroup } from "./";
import { ModelMeasure, AnnotationType } from "./classes/measure";
import { Image, Model, Project } from "../../shared/models";

import calc from "./classes/calc";

import tinygradient from "tinygradient";
import $ from "jquery";
import "jstree";
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";
import { ViewerComponent, VIEWERSTATE } from "./viewer.component";
import { connect, store } from "@app/store";
import { getVisibleDetails } from "@features/ViewerLayers";
import { Annotations, Mark3D } from "@app/pages/viewer/classes/annotate";
import { isNil } from "lodash";
import * as THREE from 'three';

export interface PointLayer extends ViewerLayer {
	data, // Cesium.Cesium3DTileset or a Potree PointCloud
	boundingObj: THREE.Object3D,
}

const connection = connect( {
		mapStateToProps: ( state, cmp ) => {
			return {
				_layerVisibility: getVisibleDetails( state )
			};
		}
	}
);

@Component( {
	selector: "app-point-viewer",
	template: "<div #renderTarget class=\"render_target\"><div id=\"cesiumContainer\" style=\"position: absolute; width: 100%; height: 100%; background-color:rgba(16,16,16,1)\"></div></div>",
	styleUrls: [ "./viewer.component.scss" ]
} )
export class PointViewerComponent implements OnInit, AfterViewInit {

	@ViewChild( "renderTarget" )
	public renderTarget: ElementRef;

	@Output( "ready" ) ready: EventEmitter<any> = new EventEmitter<any>();

	@Input() viewerParent: ViewerComponent;

	@Input() userId: number;

	@Input() project: Project;
	@Input() model: Model;
	public gradientList: Array<any> = [ "Clamp", "Repeat", "Mirrored Repeat" ];
	public gradientStyle: Array<any> = [ "B&W", "Rainbow" ];
	public pointShapeList: Array<any> = [ "Square", "Circle", "Paraboloid" ];
	public pointSizeTypeList: Array<any> = [ "Square", "Circle", "Paraboloid" ];
	/* External Expressions */
	public measureInfo: any;
	public showMeasureInfo = {
		showMeasures: true,
		yourMeasurementCount: 0,
		teamMeasurementCount: 0,
		showYourMeasurements: true,
		showTeamMeasurements: true,
		showDistances: false,
		showArea: false,
		showHeight: false,
		showAngles: false
	};
	public selected: any = null;		// Selected pointcloud/model
	/* Libraries */
	public Potree: any;
	public THREE: any;
	public proj4: any;
	public camgram;
	public lightBackground: boolean = false;
	public qualityOptions: Array<any> = [
		{
			text: "Low",
			maxScreenSpaceError: 2
		}, {
			text: "Medium",
			maxScreenSpaceError: 1
		}, {
			text: "High",
			maxScreenSpaceError: 0.5
		}, {
			text: "Ultra",
			maxScreenSpaceError: 0.1
		} ];
	public qualitySelection: any = this.qualityOptions[ 1 ];
	/* Layers */
	public layerGroups: Array<ViewerLayerGroup> = [];
	public layers: Map<string, PointLayer>;
	public lastMoved: any = null;
	public coneSize: number = 0.1;
	public tileset: any;
	public dynamicErrorFactor: number = 4.0;
	public coneErrorFactor: number = 4.0;
	public showCameraPivot: boolean = false;
	/* Debugging Visualizations */
	public debuggingViz = {
		showControls: false,
		showImagePositions: false,
		showEstimatedPositions: false,
		showModelSpaceAxes: false,
		showUTMSpaceAxes: false,
		showFlightPath: false,
		showError: false,
		averageError: {
			long: 0,
			lat: 0,
			alt: 0,
			total: 0
		}
	};
	tree = null;
	private _ms: Measurement[];
	private showMeasure: boolean = true; // TODO @remove temp-team-measurements
	private _selectedMeasurement: any;
	private _tileset: any;
	private _projectImages: Array<Image> = new Array<Image>();
	/* Cesium */
	private _cesiumViewer: any;
	private toMap: any;
	private toScene: any;
	private _exPrim;
	/* Viewer */
	private _viewer: any;	// Potree Viewer Reference
	private _measuringTool: any;
	private _viewerState: VIEWERSTATE;
	private viewerSetup: boolean = false;
	private readonly LIGHT_BACKGROUND = new Cesium.Color( 207 / 256, 216 / 256, 220 / 256, 1.0 );
	private readonly DARK_BACKGROUND = new Cesium.Color( 4 / 256, 10 / 256, 20 / 256, 1.0 );
	private _lastViewedLayerId: string;

	// TODO: @remove viewer-layers
	private readonly _contiguousLayerThreshold: number = 200;
	private deltabetween: Array<any> = [ 0, 0, 0, 0, 0 ];

	// TODO-END: @remove viewer-layers
	/* Camera */
	private _mouse: any = { x: 0, y: 0, doUse: false };
	private INTERSECTED = null;
	private _camsVisible: boolean = true;
	private _cameraPlaneView = false;
	private _nCams: number;
	private _startingView: any;
	private _isMarker: boolean;
	// };
	private _debuggingGroups: any = {};

	// private _pointStyle: PointCloudStyle = {
	// 	heightMin: 0,
	// 	heightMax: 100,
	// 	sizeType: 2,
	// 	size: 0.8,
	// 	shape: 0,
	// 	gradient: [],
	// 	repeatGradient: true,
	// 	attribute: "rgba"
	private _utmTransformationMatrix: THREE.Matrix4;

	constructor(
		private _potreeService: PotreeService,
		private _measurementService: MeasurementService,
		private _cdr: ChangeDetectorRef
	) {

		connection( this, store );
	}	// End-of constructor

	private _annotationHasChanges: boolean = false;

	get annotationHasChanges() {
		return this._annotationHasChanges;
	}

	set annotationHasChanges( val: boolean ) {
		this._annotationHasChanges = val;
	}

	set _layerVisibility( visibleDetails ) {
		visibleDetails.forEach( ( [ id, visibility ] ) => {
			let layer = this.layers.get( id );
			if ( layer ) {
				layer.visible = visibility;
				this.updateLayerVisibility( layer );
			}
		} );
	}

	private _measurements: Array<any> = new Array<any>();

	@Input() set measurements( ms: Measurement[] ) {
		this._ms = ms;
		if ( this._ms.length && this.viewerSetup ) this.setupMeasurements();
	}

	public get showingDebugTiles(): boolean {
		return this._tileset?.debugColorizeTiles;
	}

	public get activeState(): VIEWERSTATE {
		return this._viewerState;
	}

	public get measurementEditorState(): VIEWERSTATE {
		if ( !isNil( this._selectedMeasurement ) ) {
			return this._selectedMeasurement.measuring ?
				VIEWERSTATE.MEASURE_ADDING : VIEWERSTATE.MEASURE_EDITING;
		}
		return VIEWERSTATE.NONE;
	}

	public get measurementPoints(): number {
		if ( this._selectedMeasurement != null ) {
			return this._selectedMeasurement.measureRef.points.length;
		}
		return 0;
	}

	/* Loaded */
	private _loaded: Array<any> = Array<any>();

	public get loaded(): Array<any> {
		return this._loaded;
	}

	public clearMeasureInfo() {
		this.measureInfo = null;
	}

	ngOnInit() {
	}	// End-of ngOnInit

	ngAfterViewInit() {
		this._potreeService.lazyLoad().subscribe( d => {
			if ( !this._viewer ) {
				this.setupViewer();
			}

			// $(() => {
			// 	setTimeout(() => {
			// 		this.setupTree();
			// 	}, 10);
			// });
		} );
	}	// End-of ngAfterViewInit

	setupViewer(): void {
		// Get libs
		this.THREE = window[ "THREE" ];
		this.Potree = window[ "Potree" ];
		this.proj4 = window[ "proj4" ];

		// Potree
		this.setupPotree();
		// Cesium
		this.setupCesium();

		// Emit that viewer is ready
		this.ready.emit( this );

		this._startingView = {
			yaw: this._viewer.scene.view.yaw,
			pitch: this._viewer.scene.view._pitch
		};
		if ( this._ms.length ) this.setupMeasurements();

		this.addWebGLErrorListeners();

		this.viewerSetup = true;

		// Testing function exposure, devs only
		if ( flagLayer.isEnabled( availableFeatureFlags.devTesting ) ) {
			( window as any ).pointViewer = this;
			( window as any ).hotLoadPointCloud = ( apiUrl, rawLayer ) => this.hotLoadPointCloud( apiUrl, rawLayer );
			( window as any ).crashContext = this.crashContext;
			window.document.dispatchEvent( new CustomEvent( "devToolsLoaded", {} ) );
		}
	}	// End-of setupViewer

	setupMeasurements(): void {
		this._ms.forEach( ( { id, model_id, name, type, details, created_by_id, data: { points } } ) => {
			let existingMeasurement = this._measurements.find( measurement => measurement.measure.id == id );
			if ( !existingMeasurement ) {
				let measureOptions = {
					closed: true,
					id,
					model_id,
					project: this.project,
					name,
					type,
					details,
					created_by_id,
					points,
					showMeasureInfo: this.showMeasureInfo,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				};

				let measure;
				if ( type === AnnotationType.MARKER && flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ) {
					measure = new Mark3D( this._viewer, measureOptions );
				} else {
					measure = new ModelMeasure( this._viewer, measureOptions );
				}

				const sub_change = measure.change.subscribe( d => {
					this.measureInfo = d.info;
					this.selectMeasurement( d.ref );
				} );

				this._measurements.push( {
					measure,
					sub_change
				} );
			}
		} );
		this.updateMeasurementCounts();
		this.stopMeasure();
		this.updateMeasureVisibility();
	}

	@HostListener( "document:keydown", [ "$event" ] ) onKeydownHandler( event: KeyboardEvent ) {
		const ESCAPE_KEYCODE = 27;
		if ( event.keyCode === ESCAPE_KEYCODE ) {
			this.stopMeasure();
		}
	}	// HostListner on keydown

	addWebGLErrorListeners(): void {
		const canvases = document.getElementsByTagName( "canvas" );
		for ( let canvas of canvases ) {
			canvas.addEventListener( "webglcontextlost", ( event ) => {
				event.preventDefault();
				this.viewerParent.handleViewerCrash();
			} );
		}
	}

	// OutSide intersectionCheck

	// Potree calls this to check for pos in other datasets
	getMousePointIntersectionCheckOnCesium( mouse, params = {} ): any {
		let picked = GetWorldPosition.getWorldPosition(
			this._cesiumViewer.scene,
			mouse,
			this._exPrim
		);
		if ( !picked || !Cesium.defined( picked.object ) ) {
			return null;
		}

		// Add point
		// this._cesiumViewer.entities.add({
		// 	position: picked.position,
		// 	point: {
		// 		pixelSize: 10,
		// 		color: Cesium.Color.RED
		// 	}
		// });

		// Polyline
		// if (!this.polyCollection) {
		// 	this.polyCollection = new Cesium.PolylineCollection();
		// 	this._cesiumViewer.scene.primitives.add(this.polyCollection);
		// } else {
		// 	// let mat = new Cesium.PolylineArrowMaterialProperty(Cesium.Color.BLUE);
		// 	// let p1 = toCes(pTarget);
		// 	// let p2 = toCes(this._viewer.scene.getActiveCamera().position);
		// 	// this.polyCollection.add({
		// 	// 	positions: [p1, p2],
		// 	// 	width: 5,
		// 	// });

		// 	// let a = this._cesiumViewer.entities.add({
		// 	// 	polyline: {
		// 	// 		positions: [p1, p3],
		// 	// 		width: 10,
		// 	// 		material: Cesium.Color.RED,
		// 	// 		arcType: Cesium.ArcType.NONE,
		// 	// 	}
		// 	// });

		// 	let origin = toCes(this._viewer.scene.getActiveCamera().position);
		// 	let ray = new Cesium.Ray(origin, cDir);
		// }

		// convert position to potree
		this.getLastPointLatLng( picked.position );

		return {
			position: this.fromCartesian( picked.position ),
			object: picked.object
		};
		// Returns distance
	}

	addHiddenBoxVolume( x, y, z, s ): any {
		let volume = new Potree.BoxVolume();
		volume.position.set( x, y, z );
		volume.scale.set( s, s, s );
		volume.visible = false;

		this._viewer.scene.addVolume( volume );
		return volume;
	}

	fromCartesian( coords: any ): any {
		let carto = Cesium.Cartographic.fromCartesian( coords );
		let toPotNext = ( cart ) => {
			let xy = [ Cesium.Math.toDegrees( cart.longitude ), Cesium.Math.toDegrees( cart.latitude ) ];
			let height = cart.height;
			let c = this.toScene.forward( xy );
			return new this.THREE.Vector3( c[ 0 ], c[ 1 ], height );
		};
		return toPotNext( carto );
	}

	fromPoints( coords: Object ): any {

		let fromPotNext = ( coords ) => {
			const inv = this.toScene.inverse( [ coords.x, coords.y ] );
			const [ lat, long ] = [ Cesium.Math.toRadians( inv[ 0 ] ), Cesium.Math.toRadians( inv[ 1 ] ) ];
			return new Cesium.Cartographic( lat, long, coords.z );
		};
		const t = fromPotNext( coords );
		return Cesium.Cartographic.toCartesian( t );
	}

	fromLatLongToPoints( lat, long, altitude = 0 ): any {
		const point = this.toScene.forward( [ long, lat ] );
		return new this.THREE.Vector3( point[ 0 ], point[ 1 ], altitude );
	}

	convertToLatLng( position ): any {
		const carto = Cesium.Cartographic.fromCartesian( position );
		const [ lat, lng ] = [ Cesium.Math.toDegrees( carto.latitude ), Cesium.Math.toDegrees( carto.longitude ) ];
		return calc( [ { lat, lng } ] );
	}

	getLastPointLatLng( position ): any {
		if ( !position || !this.measureInfo ) return;
		Object.assign( this.measureInfo, this.convertToLatLng( position ) );
	}

	setupLayers( incomingLayerGroups: Array<any>, proj4: string ): Promise<any> {
		this.layers = new Map<string, any>();

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			this.setProjection( proj4 );

			this.layerGroups = incomingLayerGroups;

			let promises = [];
			this.layerGroups.forEach( layerGroup => {
				layerGroup.layers?.forEach( layer => {
					switch (layer.type) {
						case "dense point cloud":
						case "sparse point cloud":
							promises.push( this.addPointCloud( layer ) );
							break;
						case "3D model":
							promises.push( this.addModel( layer ) );
							break;
						default:
							console.warn( "Type unknown: ", layer.type );
							break;
					}
				} );
			} );

			return Promise.all( promises );
		} else { // TODO: @remove viewer-layers
			let promises = [];
			incomingLayerGroups.forEach( layer => {
				switch (layer.type) {
					case "pointcloud":
						promises.push( this.addPointCloud( layer ) );
						break;
					case "model":
						promises.push( this.addModel( layer ) );
						break;
					default:
						console.warn( "Type unknown" );
						break;
				}
			} );
			return Promise.all( promises );
		}
	}	// End-of setupLayers

	rendersLayerType( layerType: string ): boolean {
		return LAYER_TYPES_3D[ layerType ] ?? false;
	}

	showInitialLayers(): void {
		this.hideAllLayers();

		if ( this.layers.size === 1 ) {
			this.toggleLayer( this.layers.values().next().value.id );
		} else if ( this.layers.size > 1 ) {
			/* initially show max-contiguous-layers
			const layersToShow = this.getMaxContiguousLayers();
			layersToShow.forEach( layer => {
				this.toggleLayer(layer.id, true)
			});
			*/

			const meshLayers = [ ...this.layers ].filter( ( [ k, v ] ) => v.type === "3D model" );
			if ( meshLayers.length >= 1 ) { // show the first 3D model layer
				this.toggleLayer( meshLayers[ 0 ][ 0 ], true );
			} else {
				this.toggleLayer( this.layers.values().next().value.id );
			}
		}
	}

	hideAllLayers(): void {
		this.layers.forEach( layer => {
			this.toggleLayer( layer.id, false );
		} );
	}

	getMaxContiguousLayers(): Array<any> {
		let maxContiguous = [];
		this.layers.forEach( layer => {
			let contiguousLayers = this.getLayersContiguousWith( layer );
			if ( contiguousLayers.length > maxContiguous.length ) {
				maxContiguous = contiguousLayers;
			}
		} );
		return maxContiguous;
	}

	getLayersContiguousWith( centerLayer ): Array<any> {
		let contiguous = [];
		const centerPoint = centerLayer.boundingObj.position;
		this.layers.forEach( layer => {
			if ( centerPoint.distanceTo( layer.boundingObj.position ) < this._contiguousLayerThreshold ) {
				contiguous.push( layer );
			}
		} );
		return contiguous;
	}

	setProjection( projectProjection: string ): void {
		if ( !projectProjection ) return;

		let mapProjection = this.proj4.defs( "WGS84" );
		this.toMap = this.toMap ?? this.proj4( projectProjection, mapProjection );
		this.toScene = this.toScene ?? this.proj4( mapProjection, projectProjection );
	}

	addModel( layer: any ): Promise<any> {
		return new Promise( ( resolve: Function, reject: Function ) => {
			let file_url = GlobalService.databaseApiUrl + layer.url;

			let options = {
				url: file_url,
				maximumScreenSpaceError: 1,
				maximumNumberOfLoadedTiles: 2000,
				dynamicScreenSpaceError: true,
				dynamicScreenSpaceErrorDensity: 0.00278,
				dynamicScreenSpaceErrorFactor: 4.0,
				dynamicScreenSpaceErrorHeightFalloff: 0.25,
				shadows: Cesium.ShadowMode.DISABLED,
				skipLevelOfDetail: true
			};
			let tileset = this._cesiumViewer.scene.primitives.add( new Cesium.Cesium3DTileset( options ) );

			this._tileset = tileset;
			tileset.readyPromise.then( tile => {
				tile.show = layer.visible;

				// Cesium Projection
				let tileBoundingSphere = tile.boundingSphere;
				let carto = Cesium.Cartographic.fromCartesian( tileBoundingSphere.center );
				let tilesetLatLong = [ Cesium.Math.toDegrees( carto.latitude ), Cesium.Math.toDegrees( carto.longitude ) ];
				let zone = ( Math.floor( ( tilesetLatLong[ 1 ] + 180 ) / 6 ) % 60 ) + 1;
				let northSouth = tilesetLatLong[ 0 ] >= 0 ? "north" : "south";

				let projectProjection = `+proj=utm +zone=${ zone } +${ northSouth } +datum=WGS84 +units=m +no_defs`;
				this.setProjection( projectProjection );

				// Add a hidden volume to potree to use with the potree camera and potree coord system
				let potreeSpherePos = this.fromCartesian( tileBoundingSphere.center );
				let potreeVolume = this.addHiddenBoxVolume( potreeSpherePos.x, potreeSpherePos.y, potreeSpherePos.z, tileBoundingSphere.radius * 1.0 );
				potreeVolume.name = "cesiumVolume";

				layer.data = tile;
				layer.boundingObj = potreeVolume;
				layer.coordinate = this.convertToLatLng( this.fromPoints( layer.boundingObj.position ) ).coordinate;

				this.layers.set( layer.id, layer );
				if ( layer.visible )
					this.goToLayer( layer.id );

				if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
					resolve( layer );
				} else { // TODO: @remove view-layers
					tile.name = "Model";
					let model_layer = {
						name: layer.name,
						data: tile,
						type: "model"
					};
					this._loaded.push( model_layer );
					resolve( model_layer );
				}
			} );
		} );
	}	// End-of addModel

	addPointCloud( layer: any ): Promise<any> {

		return new Promise( ( resolve: Function, reject: Function ) => {
			// CAMERA RECTIFICATION
			// let file_url = layer.url;
			// this.camgram = new PotCameras(this._viewer);

			let file_url = GlobalService.databaseApiUrl + layer.url;

			if ( layer.apiUrl )
				file_url = layer.apiUrl + layer.url;

			let loaded = ( e ) => {
				let pointcloud = e.pointcloud;
				pointcloud.visible = typeof layer.visible != "undefined" ? layer.visible : true;

				let material = pointcloud.material;
				this._viewer.scene.addPointCloud( pointcloud );
				material.pointSizeType = this.Potree.PointSizeType.ADAPTIVE;
				material.size = 0.8;
				pointcloud.material.activeAttributeName = "composite";

				// Cesium Projection
				let pointcloudProjection = e.pointcloud.projection;
				this.setProjection( pointcloudProjection );

				if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
					layer.data = pointcloud;
					let node = new this.THREE.Object3D();
					node.boundingBox = this._viewer.getBoundingBox( [ pointcloud ] );

					layer.boundingObj = node;
					let bounding_obj_center = new THREE.Vector3();
					layer.boundingObj.boundingBox.getCenter(bounding_obj_center);
					layer.coordinate = this.convertToLatLng( this.fromPoints(bounding_obj_center) ).coordinate;

					this.layers.set( layer.id, layer );
					if ( layer.visible )
						this.goToLayer( layer.id );

					resolve( layer );
				} else { // TODO: @remove viewer-layers
					this._viewer.fitToScreen();

					let pointcloud_layer = {
						name: layer.name,
						data: pointcloud,
						type: "pointcloud"
					};

					this._loaded.push( pointcloud_layer );
					resolve( pointcloud_layer );
				}
			};

			this.Potree.loadPointCloud( file_url, layer.name ? layer.name : "pointcloud", loaded );
		} );
	}	// End-of addPointCloud

	updateMeasurementCounts(): void {
		this.showMeasureInfo.yourMeasurementCount = this._measurements?.filter( m => m.measure.created_by_id === this.userId ).length;
		this.showMeasureInfo.teamMeasurementCount = this._measurements.length - this.showMeasureInfo.yourMeasurementCount;
	}

	updateMeasureVisibility(): void {
		const shouldShowYourMeasures = this.showMeasureInfo.showMeasures && this.showMeasureInfo.showYourMeasurements;
		const shouldShowTeamMeasures = this.showMeasureInfo.showMeasures && this.showMeasureInfo.showTeamMeasurements;

		// measurement tool
		if ( !this.showMeasureInfo.showMeasures ) {
			if ( this._viewerState === VIEWERSTATE.MEASURE ) {
				this.stopMeasure();
			} else {
				this.measureInfo = null;
			}
		} else if ( this._selectedMeasurement ) {
			const isYours = this._selectedMeasurement.created_by_id === this.userId;
			if (
				( isYours && !shouldShowYourMeasures ) ||
				( !isYours && !shouldShowTeamMeasures )
			) {
				this.stopMeasure();
			}
		}

		// measurements
		let scene = this._viewer.scene;
		[ ...scene.measurements, ...scene.profiles ].forEach(
			( m ) => {
				const isYours = m.created_by_id === this.userId;
				m.visible = ( isYours && shouldShowYourMeasures ) ||
					( !isYours && shouldShowTeamMeasures );
			} );
		scene.volumes.forEach(
			( m ) => ( m.visible = m.name.startsWith( "cesium" ) ? false : this.showMeasureInfo.showMeasures )
		);

		// measurement labels
		this._measurements.forEach( item => {
			// TODO @remove new-annotations
			if(flagLayer.isEnabled( availableFeatureFlags.newAnnotations)) item.measure.toggleAnno(this.showMeasureInfo.showMeasures);
			item.measure.setLabelVisibility( "_showDistances", this.showMeasureInfo.showDistances );
			item.measure.setLabelVisibility( "_showArea", this.showMeasureInfo.showArea );
		} );
	}

	// TODO @remove - temp-team-measurements
	toggleMeasurementsVisibility( visible?: boolean ): void {
		this.showMeasure = visible === undefined ? !this.showMeasure : visible; // visible || !this.showMeasure would miss when visible is deliberately false
		if ( !this.showMeasure ) {
			if ( this._viewerState === VIEWERSTATE.MEASURE ) {
				this.stopMeasure();
			} else {
				this.measureInfo = null;
			}
		}
		let scene = this._viewer.scene;
		if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
			[ ...scene.measurements, ...scene.profiles, ...scene.volumes ].forEach(
				( m ) => m.visible = this.showMeasure
			);
		} else {
			[ ...scene.measurements, ...scene.profiles ].forEach(
				( m ) => m.visible = this.showMeasure
			);
			scene.volumes.forEach(
				( m ) => m.visible = m.name.startsWith( "cesium" ) ? false : this.showMeasure
			);
		}
	}	// toggleMeasurementsVisibility

	setVisible( name?: string, visible?: boolean ): void {
		if ( name ) {
			if ( name === "measure" ) { // TODO @remove - temp-team-measurements
				this.toggleMeasurementsVisibility( visible );
				return;
			} else if ( this.showMeasureInfo[ name ] ) { // TODO @remove - temp-team-measurements
				this._measurements.forEach( item => {
					item.measure.toggleLabels( { [ name ]: !item.measure.showDistances } );
				} );
			} else if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
				let mapElement_index = this._loaded.findIndex( ld => {
					return ld.name === name;
				} );

				if ( mapElement_index >= 0 ) {
					let mapElement = this._loaded[ mapElement_index ];
					if ( mapElement.type === "model" ) {
						let model = mapElement.data;
						model.show = visible != null ? visible : !model.show;
					} else if ( mapElement.type === "pointcloud" ) {
						let pointcloud = mapElement.data;
						pointcloud.visible = visible != null ? visible : !pointcloud.visible;
					}
				}
			}
		} else if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
			this._loaded.forEach( mapElement => {
				if ( mapElement.type === "model" ) {
					let model = mapElement.data;
					model.show = visible != null ? visible : !model.show;
				} else if ( mapElement.type === "pointcloud" ) {
					let pointcloud = mapElement.data;
					pointcloud.visible = visible != null ? visible : !pointcloud.visible;
				}
			} );
		}
	}	// End-of setVisibility

	// TODO @remove - temp-team-measurements
	isVisible( name: string ): boolean {
		if ( name === "measure" ) {
			return this.showMeasure;
		} else if ( this.showMeasureInfo[ name ] ) {
			return this.showMeasureInfo[ name ];
		}

		// TODO: @remove viewer-layers
		if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			let index = this._loaded.findIndex( pc => {
				return pc.name === name;
			} );

			if ( index >= 0 ) {
				let mapElement = this._loaded[ index ];

				if ( mapElement.type === "model" ) {
					return mapElement.data.show;
				} else if ( mapElement.type === "pointcloud" ) {
					return mapElement.data.visible;
				}
			} else {
				return false;
			}
		}
	}	// End-of isVisible

	toggleLayer( layerId: string, visible?: boolean ): void {
		let layer = this.layers.get( layerId );
		if ( layer ) {
			layer.visible = visible != null ? visible : !layer.visible;
			this.updateLayerVisibility( layer );
		}
	}

	updateLayerVisibility( layer ): void {
		if ( layer.type === "3D model" ) {
			layer.data.show = layer.visible;
		} else if ( layer.type === "dense point cloud" || layer.type === "sparse point cloud" ) {
			layer.data.visible = layer.visible;
		}
	}

	recenter(): void {
		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			const lastViewedLayer = this.layers.get( this._lastViewedLayerId );
			if ( lastViewedLayer && lastViewedLayer.visible ) {
				this.recenterOnLayer( this._lastViewedLayerId );
			} else {
				// zoom to the first visible layer
				this.layers.forEach( layer => {
					if ( layer.visible ) {
						this.recenterOnLayer( layer.id );
						return;
					}
				} );
			}
		} else { // TODO: @remove viewer-layer
			// Return to the initial view
			this._viewer.resetToView( this._startingView );
		}
	}	// End-of recenter

	recenterOnLayer( layerId ) {
		this._viewer.setViewAngles( this._startingView );
		this.goToLayer( layerId );
	}

	// Sets the background of the viewer
	setBackground( color ): void {
		this._cesiumViewer.scene.backgroundColor = color;
	}	// End-of setBackground

	toggleBackground(): void {
		this.lightBackground = !this.lightBackground;
		if ( this.lightBackground ) {
			this.setBackground( this.LIGHT_BACKGROUND );
		} else {
			this.setBackground( this.DARK_BACKGROUND );
		}
	}

	changeQualitySetting( e ): void {
		this._tileset.maximumScreenSpaceError = this.qualitySelection.maxScreenSpaceError;
	}

	toggleTileColors( e ): void {
		this._tileset.debugColorizeTiles = !this._tileset.debugColorizeTiles;
	}

	public goToCameraView( view ): void {
		this._viewer.setView( view );
	}

	public toggleFlightPathViz(): void {
		this.debuggingViz.showFlightPath = !this.debuggingViz.showFlightPath;
		this.updateDebugging();
	}

	public toggleImagePositionViz(): void {
		this.debuggingViz.showImagePositions = !this.debuggingViz.showImagePositions;
		this.updateDebugging();
	}

	public toggleEstimatedPositionViz(): void {
		this.debuggingViz.showEstimatedPositions = !this.debuggingViz.showEstimatedPositions;
		this.updateDebugging();
	}

	public toggleEstimatedErrorViz(): void {
		this.debuggingViz.showError = !this.debuggingViz.showError;
		this.updateDebugging();
	}

	public toggleModelSpaceAxesViz(): void {
		this.debuggingViz.showModelSpaceAxes = !this.debuggingViz.showModelSpaceAxes;
		this.updateDebugging();
	}

	public toggleUTMSpaceAxesViz(): void {
		this.debuggingViz.showUTMSpaceAxes = !this.debuggingViz.showUTMSpaceAxes;
		this.updateDebugging();
	}

	setupDetailsViz(): void {
		if ( !this._projectImages.length && this.project.batches?.length )
			this._projectImages = this.project.batches.flatMap( ( batch ) => batch.images );

		this._utmTransformationMatrix =
			this._utmTransformationMatrix ?? this.createUTMTransformationMatrix();

		this._debuggingGroups.diamondCubeGeo =
			this._debuggingGroups.diamondCubeGeo ?? this.createDiamondCubeGeometry();
	}

	updateDebugging(): void {
		if ( this.debuggingViz.showControls ) {
			this._debuggingGroups.parent = this._debuggingGroups.parent ?? new this.THREE.Group();
			this._viewer.scene.scene.add( this._debuggingGroups.parent );

			this.setupDetailsViz();
		}

		this.renderFlightPath( this.debuggingViz.showControls && this.debuggingViz.showFlightPath );
		this.renderImagePositions( this.debuggingViz.showControls && this.debuggingViz.showImagePositions );
		this.renderEstimatedPositions( this.debuggingViz.showControls && this.debuggingViz.showEstimatedPositions );
		this.renderEstimatedError( this.debuggingViz.showControls && this.debuggingViz.showError );
		this.renderModelSpaceAxes( this.debuggingViz.showControls && this.debuggingViz.showModelSpaceAxes );
		this.renderUTMSpaceAxes( this.debuggingViz.showControls && this.debuggingViz.showUTMSpaceAxes );

		if ( !this.debuggingViz.showControls && this._debuggingGroups.parent ) {

			this._viewer?.scene?.scene?.remove( this._debuggingGroups.parent );
			delete this._debuggingGroups.parent;
		}
	}

	renderImagePositions( visible ): void {
		if ( !visible && this._debuggingGroups.images ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.images );
			delete this._debuggingGroups.images;

		} else if ( visible && !this._debuggingGroups.images ) {

			this._debuggingGroups.images = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.images );

			if ( this._projectImages.length ) {
				const line_material = new THREE.LineBasicMaterial( {
					color: 0xffffff
				} );
				const circle_material = new THREE.LineBasicMaterial( {
					color: 0xffff00
				} );
				const geometry = this._debuggingGroups.diamondCubeGeo;
				const circleGeometry = new THREE.CircleGeometry( 0.72, 12 );

				this._projectImages.forEach( ( image ) => {
					if ( image.latitude && image.longitude ) {
						const point = this.fromLatLongToPoints( image.latitude, image.longitude );
						const pointVec = new THREE.Vector3( point.x, point.y, image.altitude );

						const line = new THREE.Line( geometry, line_material );
						line.position.copy( pointVec );
						this._debuggingGroups.images.add( line );

						const circle = new THREE.Mesh( circleGeometry, circle_material );
						circle.position.copy( pointVec );
						this._debuggingGroups.images.add( circle );
					}
				} );
			}
		}
	}

	renderEstimatedPositions( visible ): void {
		if ( !visible && this._debuggingGroups.estimated ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.estimated );
			delete this._debuggingGroups.estimated;
		} else if ( visible && !this._debuggingGroups.estimated ) {
			this._debuggingGroups.estimated = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.estimated );
			if ( this._projectImages.length && this._utmTransformationMatrix ) {
				const material = new this.THREE.LineBasicMaterial( {
					color: 0x005dea,
					side: this.THREE.DoubleSide
				} );
				const geometry = this._debuggingGroups.diamondCubeGeo;
				const circleGeometry = new this.THREE.CircleGeometry( 0.72, 12 );

				this._projectImages.forEach( ( image ) => {
					const estimatedPosition = this.getEstimatedImagePosition( image, this._utmTransformationMatrix );
					if ( estimatedPosition ) {
						const line = new this.THREE.Line( geometry, material );
						line.position.copy( estimatedPosition );
						this._debuggingGroups.estimated.add( line );

						const circle = new this.THREE.Mesh( circleGeometry, material );
						circle.position.copy( estimatedPosition );
						this._debuggingGroups.estimated.add( circle );
					}
				} );
			}
		}
	}

	renderFlightPath( visible ): void {
		if ( !visible && this._debuggingGroups.flightPath ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.flightPath );
			delete this._debuggingGroups.flightPath;
		} else if ( visible && !this._debuggingGroups.flightPath ) {
			this._debuggingGroups.flightPath = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.flightPath );

			if ( this._projectImages.length > 2 ) {
				// @ts-ignore
				this._projectImages.sort( ( a, b ) => new Date( a.descriptors.captured_at ) - new Date( b.descriptors.captured_at ) );

				const line_material = new THREE.LineBasicMaterial( {
					color: 0x00ff00
				} );

				const delayPerPoint = 20;
				let prevPoint;
				let count = 0;
				this._projectImages.forEach( ( image ) => {
					if ( image.latitude && image.longitude ) {
						const point = this.fromLatLongToPoints( image.latitude, image.longitude );
						const pointVec = new this.THREE.Vector3( point.x, point.y, image.altitude );

						if ( prevPoint ) {
							const geometry = new THREE.BufferGeometry().setFromPoints( [ prevPoint, pointVec ] );
							const line = new THREE.Line( geometry, line_material );

							setTimeout(
								() => {
									this._debuggingGroups.flightPath.add( line );
								},
								count * delayPerPoint );
							count++;
						}
						prevPoint = pointVec;
					}
				} );
			}
		}
	}

	renderEstimatedError( visible ): void {
		if ( !visible && this._debuggingGroups.latLongError ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.latLongError );
			delete this._debuggingGroups.latLongError;

		} else if ( visible && !this._debuggingGroups.latLongError ) {
			this._debuggingGroups.latLongError = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.latLongError );
			if ( this._projectImages.length && this._utmTransformationMatrix ) {

				const clamp = ( num, min, max ) => Math.min( Math.max( num, min ), max );
				const gradient = tinygradient( "#3232de", "#32de32", "#de3232" );
				const errorBarScale = 10;
				const errorGradientScale = 5;

				let totalComputedDeltas = 0,
					totalLong = 0,
					totalLat = 0,
					totalAlt = 0,
					totalDelta = 0;

				this._projectImages.forEach( ( image ) => {
					const imagePoint = this.fromLatLongToPoints( image.latitude, image.longitude );
					const imagePosition = new this.THREE.Vector3( imagePoint.x, imagePoint.y, image.altitude );
					const computedPosition = this.getEstimatedImagePosition( image, this._utmTransformationMatrix );
					if ( computedPosition ) {
						let delta = imagePosition.distanceTo( computedPosition );
						if ( computedPosition.z < imagePosition.z )
							delta *= -1;

						totalComputedDeltas++;
						totalLong += Math.abs( computedPosition.x - imagePosition.x );
						totalLat += Math.abs( computedPosition.y - imagePosition.y );
						totalAlt += Math.abs( computedPosition.z - imagePosition.z );
						totalDelta += Math.abs( delta );

						const deltaNormalized = clamp( ( delta / ( errorGradientScale * 2 ) ) + 0.5, 0, 1 );
						const color = gradient.rgbAt( deltaNormalized ).toHexString();

						const directionVector = imagePosition.clone().subVectors( computedPosition, imagePosition );
						directionVector.multiplyScalar( errorBarScale );
						const endPos = imagePosition.clone().addVectors( imagePosition, directionVector );
						this.drawLine( imagePosition, endPos, color, 2, this._debuggingGroups.latLongError );
					}
				} );

				this.debuggingViz.averageError.long = totalLong / totalComputedDeltas;
				this.debuggingViz.averageError.lat = totalLat / totalComputedDeltas;
				this.debuggingViz.averageError.alt = totalAlt / totalComputedDeltas;
				this.debuggingViz.averageError.total = totalDelta / totalComputedDeltas;
			}
		}
	}

	renderModelSpaceAxes( visible ): void {
		if ( !visible && this._debuggingGroups.axes ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.axes );
			delete this._debuggingGroups.axes;
		} else if ( visible && !this._debuggingGroups.axes ) {
			this._debuggingGroups.axes = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.axes );
			if ( this._utmTransformationMatrix ) {
				const
					zero = new this.THREE.Vector3( 0, 0, 0 ).applyMatrix4( this._utmTransformationMatrix ),
					x_vector = new this.THREE.Vector3( 1, 0, 0 ).applyMatrix4( this._utmTransformationMatrix ),
					y_vector = new this.THREE.Vector3( 0, 1, 0 ).applyMatrix4( this._utmTransformationMatrix ),
					z_vector = new this.THREE.Vector3( 0, 0, 1 ).applyMatrix4( this._utmTransformationMatrix );

				this.drawSphere( zero, 0xffffff, 2, this._debuggingGroups.axes );
				this.drawSphere( x_vector, 0xff0000, 2, this._debuggingGroups.axes );
				this.drawSphere( y_vector, 0x00ff00, 2, this._debuggingGroups.axes );
				this.drawSphere( z_vector, 0x0000ff, 2, this._debuggingGroups.axes );
				this.drawLine( zero, x_vector, 0xff0000, 2, this._debuggingGroups.axes );
				this.drawLine( zero, y_vector, 0x00ff00, 2, this._debuggingGroups.axes );
				this.drawLine( zero, z_vector, 0x0000ff, 2, this._debuggingGroups.axes );
			}
		}
	}

	renderUTMSpaceAxes( visible ): void {
		if ( !visible && this._debuggingGroups.utmAxes ) {
			this._debuggingGroups.parent.remove( this._debuggingGroups.utmAxes );
			delete this._debuggingGroups.utmAxes;
		} else if ( visible && !this._debuggingGroups.utmAxes ) {
			this._debuggingGroups.utmAxes = new this.THREE.Group();
			this._debuggingGroups.parent.add( this._debuggingGroups.utmAxes );
			if ( this._utmTransformationMatrix ) {
				const length = 100; // in meters
				const
					zero = new this.THREE.Vector3( 0, 0, 0 ).applyMatrix4( this._utmTransformationMatrix ),
					x_vector = new this.THREE.Vector3( length, 0, 0 ).add( zero ),
					y_vector = new this.THREE.Vector3( 0, length, 0 ).add( zero ),
					z_vector = new this.THREE.Vector3( 0, 0, length ).add( zero );

				this.drawSphere( zero, 0xffffff, 2, this._debuggingGroups.utmAxes );
				this.drawSphere( x_vector, 0xff00ff, 2, this._debuggingGroups.utmAxes );
				this.drawSphere( y_vector, 0xffff00, 2, this._debuggingGroups.utmAxes );
				this.drawSphere( z_vector, 0x00ffff, 2, this._debuggingGroups.utmAxes );
				this.drawLine( zero, x_vector, 0xff00ff, 2, this._debuggingGroups.utmAxes );
				this.drawLine( zero, y_vector, 0xffff00, 2, this._debuggingGroups.utmAxes );
				this.drawLine( zero, z_vector, 0x00ffff, 2, this._debuggingGroups.utmAxes );
			}
		}
	}

	createDiamondCubeGeometry(): THREE.BufferGeometry {
		const width = 1.8, depth = 1.4, height = 1;
		const
			north = new THREE.Vector3( 0, depth, 0 ),
			south = new THREE.Vector3( 0, -depth, 0 ),
			east = new THREE.Vector3( width, 0, 0 ),
			west = new THREE.Vector3( -width, 0, 0 ),
			up = new THREE.Vector3( 0, 0, height ),
			down = new THREE.Vector3( 0, 0, -height );

		const points = [];
		points.push( north );
		points.push( up );
		points.push( east );
		points.push( north );
		points.push( down );
		points.push( east );
		points.push( south );
		points.push( up );
		points.push( west );
		points.push( south );
		points.push( down );
		points.push( west );
		points.push( north );
		return new THREE.BufferGeometry().setFromPoints( points );
	}

	createUTMTransformationMatrix(): THREE.Matrix4 {
		const descriptors = this.model?.descriptors as any;
		if ( descriptors && descriptors.utm_transformation ) {
			let transformationMatrix = new this.THREE.Matrix4();
			const utm_transformation = descriptors.utm_transformation;
			const translationVector = utm_transformation.translation;
			const translationMatrix = new this.THREE.Matrix4();
			translationMatrix.makeTranslation( translationVector[ 0 ], translationVector[ 1 ], translationVector[ 2 ] );

			const rotation_1 = utm_transformation.rotation_1;
			const rotation_2 = utm_transformation.rotation_2;
			const rotation_3 = utm_transformation.rotation_3;
			const basisMatrix = new this.THREE.Matrix4();
			basisMatrix.makeBasis(
				new this.THREE.Vector3( rotation_1[ 0 ], rotation_1[ 1 ], rotation_1[ 2 ] ).multiplyScalar( -1 ),
				new this.THREE.Vector3( rotation_2[ 0 ], rotation_2[ 1 ], rotation_2[ 2 ] ).multiplyScalar( -1 ),
				new this.THREE.Vector3( rotation_3[ 0 ], rotation_3[ 1 ], rotation_3[ 2 ] ).multiplyScalar( -1 )
			);
			basisMatrix.transpose();
			transformationMatrix.multiplyMatrices( translationMatrix, basisMatrix );
			return transformationMatrix;
		}
		return null;
	}

	getEstimatedImagePosition( image, transformationMatrix ): THREE.Vector3 {
		if ( image.descriptors.computed_position
			&& image.descriptors.computed_orientation ) {
			const positionData = image.descriptors.computed_position;
			const quatData = image.descriptors.computed_orientation;

			const translation = new this.THREE.Vector3( positionData.x, positionData.y, positionData.z );
			const quat = new this.THREE.Quaternion( quatData.x, quatData.y, quatData.z, quatData.w ).inverse();

			const untransformed = translation.applyQuaternion( quat );
			return untransformed.applyMatrix4( transformationMatrix );
		}
		return null;
	}

	drawSphere( position, color, radius, group ): void {
		const geometry = new this.THREE.SphereGeometry( radius, 12, 12 ); // (radius, widthSegments, heightSegments)
		const material = new this.THREE.MeshBasicMaterial( { color: color } );
		const sphere = new this.THREE.Mesh( geometry, material );
		sphere.position.copy( position );
		group.add( sphere );
	}

	drawLine( start, end, color, size, group ): void {
		const material = new this.THREE.LineBasicMaterial( { color: color, linewidth: size } );
		const geometry = new this.THREE.BufferGeometry().setFromPoints( [ start, end ] );
		const line = new this.THREE.Line( geometry, material );
		group.add( line );
	}

	toggleCameraPivot( e ): void {
		this.showCameraPivot = !this.showCameraPivot;
		this._viewer.orbitControls.updatePivotIndicator( this.showCameraPivot );
		this._viewer.earthControls.updatePivotIndicator( this.showCameraPivot );
	}

	goToLayer( layerId ): void {
		let layer = this.layers.get( layerId );
		if ( layer ) {
			this._lastViewedLayerId = layerId;
			layer.visible = true;
			this.updateLayerVisibility( layer );
			this._viewer.zoomTo( layer.boundingObj, 1, 0 );
		}
	}

	/* Flies to end point */
	flyTo( endpt, steps: number, totaltime: number = 1 ): void {
		let XYZPTstart = this.getCameraXYZPT();
		let XYZPTend = endpt;
		let flyTimer = setInterval( () => {
			this.stepFlyTo();
		}, totaltime / steps );
		setTimeout( function() {
			clearTimeout( flyTimer );
		}, totaltime );
		this.deltabetween[ 0 ] = ( XYZPTend.x - XYZPTstart.x ) / steps;
		this.deltabetween[ 1 ] = ( XYZPTend.y - XYZPTstart.y ) / steps;
		this.deltabetween[ 2 ] = ( XYZPTend.z - XYZPTstart.z ) / steps;
		this.deltabetween[ 3 ] = ( XYZPTend.yaw - XYZPTstart.yaw ) / steps;
		this.deltabetween[ 4 ] = ( XYZPTend.pitch - XYZPTstart.pitch ) / steps;
	}	// End-of flyTo

	/* Takes step in fly to */
	stepFlyTo(): void {
		this._viewer.scene.view.position.x += this.deltabetween[ 0 ];
		this._viewer.scene.view.position.y += this.deltabetween[ 1 ];
		this._viewer.scene.view.position.z += this.deltabetween[ 2 ];
		this._viewer.scene.view.yaw += this.deltabetween[ 3 ];
		this._viewer.scene.view.pitch += this.deltabetween[ 4 ];
	}	// End-of stepFlyTo

	cameras(): void {
		var THREE = this.THREE;
		var Potree = this.Potree;

		this._mouse = { x: 0, y: 0, doUse: false };
		this.INTERSECTED = null;
		this._camsVisible = true;
		this._cameraPlaneView = false;

		let lastXYZ = [ 0, 0, 0 ];
		let raycaster = new THREE.Raycaster();
		let wantcamsvisible = true;
		// let ncams = camX.length;
		// let currentid = ncams-1;
		let measuringTool = new Potree.MeasuringTool( this._viewer );
		let mapshow = true;
		let lookAtPtNum = null;
		let dofilterimages = false;
		let lastLookAtPt = [ 0, 0, 0 ];
		let SCALEIMG = 3;
	}	// End-of

	makeImageFrustrum( imageDir, imageName, Rx, Ry, Rz, Cx, Cy, Cz ) {
		// Get reference to libraries using
		var THREE = window[ "THREE" ];

		// Loading
		let loader = new THREE.TextureLoader();
		loader.crossOrigin = "anonymous";
		let imageTexture = loader.load( imageDir + imageName );

		// let pixx = camPix[0]/camFocal;
		// let pixy = camPix[1]/camFocal;
	}	// End-of makeImageFrustrum

	// Positive zoom in, Negative zoom out
	onZoom( direction: number ): void {
		let c = this._viewer.scene.getActiveCamera();
		let view = this._viewer.scene.view;
		let amnt = ( view.radius / 2 ) * Math.sign( direction );
		let targetRadius = view.radius - amnt;
		if ( targetRadius >= 1 ) {
			view.translate( 0, amnt, 0 );
			view.radius = targetRadius;
			this._viewer.setMoveSpeed( this._viewer.scene.view.radius / 2.5 );
		}
	}	// End-of onZoom

	/* Potree Sets */
	setPointBudget( cnt ): void {
		this._viewer.setPointBudget( cnt );
	}	// End-of setPointBudge

	setPointSize( size ): void {
		this._viewer.minNodeSize = size;
	}	// End-of setPointSize

	setFov( v: number ): void {
		this._viewer.setFOV( v );
	}	// End-of setFov

	// TODO: @remove viewer-layers
	getByName( name: string ): any {
		let index = this._loaded.findIndex( pc => {
			return pc.name === name;
		} );
		return ( index >= 0 ) ? this._loaded[ index ] : null;
	}	// End-of getByName

	updateAttribute( ...attribute ): void {
		let setAttribute = ( att, pc ) => {

			// Calculate bounding box bounds
			let box = [ pc.pcoGeometry.tightBoundingBox, pc.getBoundingBoxWorld() ].find( v => v !== undefined );

			pc.updateMatrixWorld( true );
			box = this.Potree.Utils.computeTransformedBoundingBox( box, pc.matrixWorld );

			let bWidth = box.max.z - box.min.z;
			let bMin = box.min.z - 0.2 * bWidth;
			let bMax = box.max.z + 0.2 * bWidth;
			let range = bMax - bMin;
			// let range = pointcloud.material.elevationRange;

			switch (att.type) {
				case "rgb":
					pc.material.weightRGB = att.val;
					break;
				case "elevation":
					pc.material.weightElevation = att.val;
					break;
				case "intensity":
					pc.material.weightIntensity = att.val;
					break;
				case "classification":
					pc.material.weightClassification = att.val;
					break;
				case "size":
					pc.material.size = att.val;
					break;
				case "sizetype":
					switch (att.val) {
						case "Fixed":
							pc.material.pointSizeType = this.Potree.PointSizeType.FIXED;
							break;
						case "Attenuated":
							pc.material.pointSizeType = this.Potree.PointSizeType.ATTENUATED;
							break;
						case "Adaptive":
							pc.material.pointSizeType = this.Potree.PointSizeType.ADAPTIVE;
							break;
					}
					break;
				case "shape":
					switch (att.val) {
						case "Square":
							pc.material.shape = this.Potree.PointShape.SQUARE;
							break;
						case "Circle":
							pc.material.shape = this.Potree.PointShape.CIRCLE;
							break;
						case "Paraboloid":
							pc.material.shape = this.Potree.PointShape.PARABOLOID;
							break;
					}
					break;
				case "gradientrepeat":
					switch (att.val) {
						case "Clamp":
							this._viewer.setElevationGradientRepeat( this.Potree.ElevationGradientRepeat.CLAMP );
							break;
						case "Repeat":
							this._viewer.setElevationGradientRepeat( this.Potree.ElevationGradientRepeat.REPEAT );
							break;
						case "Mirrored Repeat":
							this._viewer.setElevationGradientRepeat( this.Potree.ElevationGradientRepeat.MIRRORED_REPEAT );
							break;
					}
					break;
				case "gradientcolor":
					switch (att.val) {
						// case "B&W":
						// 	break;
					}
					break;
				case "maxheight":
					pc.material.heightMax = bMax + ( att.val * ( range / 2 ) );
					break;
				case "minheight":
					pc.material.heightMin = bMin + ( att.val * ( range / 2 ) );
					break;
				default:
					console.warn( "Attribute Type not recognized: ", att );
					break;
			}
		};

		if ( this.selected ) {
			attribute.forEach( att => {
				setAttribute( att, this.selected );
			} );
		} else {
			for ( let pointcloud of this._viewer.scene.pointclouds ) {

				attribute.forEach( att => {
					setAttribute( att, pointcloud );
				} );
			}
		}
	}	// End-of updateAttribute

	setLengthUnit( unit: "m" | "ft" | "in" ): void {
		// we want to consistently store our measurements as meter, hence the static 'm'
		// but can still change the display type
		this._viewer.setLengthUnitAndDisplayUnit( "m", unit );
		analyticsLayer.trackSwitchMeasurementUnits( unit );
	}	// End-of setLengthUnit

	setCameraControls( controlName: string ): void {
		switch (controlName) {
			case "earth":
				this._viewer.setControls( this._viewer.earthControls );
				break;
			case "orbit":
				this._viewer.setControls( this._viewer.orbitControls );
				break;
			case "fp":
				this._viewer.setControls( this._viewer.fpControls );
				break;
			default:
				console.warn( "Camera controls not recognized" );
				break;
		}
		analyticsLayer.trackSwitchViewerControls( controlName );
	}	// End-of setCameraControls

	togglePointLighting( toggle? ): void {
		this._viewer.useEDL = ( toggle != undefined || toggle != null ) ? toggle : !this._viewer.useEDL;
	}	// End-of togglePointLighting

	selectMeasurement( ref: ModelMeasure ): void {
		if ( this._selectedMeasurement && this._selectedMeasurement != ref ) {
			this._selectedMeasurement.deselect();
		}
		this._selectedMeasurement = ref;

		if ( this._selectedMeasurement ) {
			this._measurementService.setModelId( this._selectedMeasurement.model_id );
			this._selectedMeasurement.select();
			this._isMarker = this._selectedMeasurement?.type === AnnotationType.MARKER;
			this._viewerState = this._isMarker ?
				VIEWERSTATE.MARKER :
				VIEWERSTATE.MEASURE;
		} else {
			this._viewerState = VIEWERSTATE.NONE;
		}
	}

	startMeasure( project, isMarker = false ): void {

		if (!flagLayer.isEnabled( availableFeatureFlags.newAnnotations )) isMarker = false; // Annotations required for markers
		this._isMarker = isMarker;
		const toggleOff = this._viewerState === ( isMarker ? VIEWERSTATE.MARKER : VIEWERSTATE.MEASURE );

		if ( toggleOff ) {
			this.stopMeasure();
		} else {
			// Set visibility
			this.measureInfo = {};
			if ( !flagLayer.isEnabled( availableFeatureFlags.teamMeasurements ) ) {
				this.setVisible( "measure", true );
			} else {
				this.showMeasureInfo.showYourMeasurements = true;
				this.showMeasureInfo.showMeasures = true;
				this.updateMeasureVisibility();
				this._cdr.detectChanges();
			}

			let newMeasurement;

			// Measure
			if ( isMarker ) {
				newMeasurement = new Mark3D( this._viewer, {
					name: "New Marker",
					type: AnnotationType.MARKER,
					details: "",
					project,
					showDistances: false,
					showEdges: false,
					closed: false,
					model: this.model,
					model_id: this.model.id,
					created_by_id: this.userId,
					showMeasureInfo: this.showMeasureInfo,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				} );
			} else {
				newMeasurement = new ModelMeasure( this._viewer, {
					name: "New Measurement",
					type: AnnotationType.POLYLINE,
					project,
					model: this.model,
					model_id: this.model.id,
					created_by_id: this.userId,
					showMeasureInfo: this.showMeasureInfo,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				} );
			}
			newMeasurement.start();

			this.selectMeasurement( newMeasurement );

			// Create subscription from measurementChanges
			let sub_change = this._selectedMeasurement.change.subscribe( d => {
				// info and ref are undefined on the initial call
				this.measureInfo = d.info || this.measureInfo;
				this.selectMeasurement( d.ref || this._selectedMeasurement );
			} );

			this._measurements.push( {
				measure: this._selectedMeasurement,
				sub: sub_change
			} );

			this.updateMeasurementCounts();

			// Set State
			this._viewerState = isMarker ? VIEWERSTATE.MARKER : VIEWERSTATE.MEASURE;
		}
	}	// End-of startMeasure

	measureRemove(): void {
		if ( this._selectedMeasurement ) {
			this._selectedMeasurement.clear();
			if ( this._measurements ) {
				let index = this._measurements.findIndex( x => {
					if ( x.measure ) {
						return x.measure.id === this._selectedMeasurement.id;
					} else {
						return false;
					}
				} );

				if ( index >= 0 ) {
					this._measurements.splice( index, 1 );
					this._viewerState = VIEWERSTATE.NONE;
					this.selectMeasurement( null );
					this.measureInfo = null;
				}
			}
			this.updateMeasurementCounts();
		}
	}	// End-of measureRemove

	stopMeasure(): void {
		if ( this._selectedMeasurement ) {
			this._selectedMeasurement.stop();
		}
		this._viewerState = VIEWERSTATE.NONE;
		this.selectMeasurement( null );
		this.measureInfo = null;
	}	// End-of stopMeasure

	updateAnnotation(): void {
		this.annotationHasChanges = false;
		this._selectedMeasurement.markAndUpdate();
	}

	startAddingToMeasure(): void {
		this._selectedMeasurement?.start();
	}

	startEditingMeasure(): void {
		this._selectedMeasurement?.stop();
	}

	updateMeasureName( event ): void {
		if ( this._selectedMeasurement ) {
			this.annotationHasChanges = true;
			this._selectedMeasurement.setName( event.target.value );
			this.tree?.jstree( "rename_node", this.getJSTreeNode( this._selectedMeasurement.treeNodeId ), event.target.value );
		}
	};

	updateMeasureDetails( event ): void {
		if ( this._selectedMeasurement ) {
			this.annotationHasChanges = true;
			this._selectedMeasurement.setDetails( event.target.value );
		}
	}

	getJSTreeNode( id ) {
		return this.tree.jstree( true ).get_node( id );
	}

	setupTree(): void {
		let elScene = $( "#menu_scene" );
		let elObjects = elScene.find( "#scene_objects" );

		let tree = $( `<div id="jstree_scene"></div>` );
		elObjects.append( tree );
		this.tree = tree;
		tree.jstree( {
			"plugins": [ "checkbox", "state", "types" ],
			"types": {
				"default": { "icon": "glyphicon glyphicon-flash" }
			},
			"core": {
				"dblclick_toggle": false,
				"state": {
					"checked": true
				},
				"check_callback": true,
				"expand_selected_onload": true,
				"themes": {
					"name": "default-aa",
					"dots": false,
					"icons": false
				}
			},
			"checkbox": {
				"keep_selected_style": true,
				"three_state": true,
				"whole_node": false,
				"tie_selection": false
			}
		} );

		// Method for creating nodes
		let createNode = ( parent, text, icon, object ) => {
			let nodeID = tree.jstree( "create_node", parent, {
					"text": text ? ( text.charAt( 0 ).toUpperCase() + text.slice( 1 ) ) : "Unknown",
					// "icon": icon,
					"data": object
				},
				"last", false, false );

			object.visible ? tree.jstree( "check_node", nodeID ) : tree.jstree( "uncheck_node", nodeID );
			return nodeID;
		};

		// Create Node Parents
		let pcID = tree.jstree( "create_node", "#", {
			"text": "<b>Point Clouds<b>",
			"id": "pointclouds"
		}, "last", false, false );

		let modelID = tree.jstree( "create_node", "#", {
			"text": "<b>Meshes</b>",
			"id": "models"
		}, "last", false, false );

		let measurementID = tree.jstree( "create_node", "#", {
			"text": "<b>Measurements</b>",
			"id": "measurements"
		}, "last", false, false );

		// Activates Check
		tree.jstree( "check_node", pcID );
		tree.jstree( "check_node", measurementID );
		tree.jstree( "check_node", modelID );

		tree.on( "create_node.jstree", ( e, data ) => {
			tree.jstree( "open_all" );
		} );

		tree.on( "select_node.jstree", ( e, data ) => {
			// console.log("Select node: ", e, data)
			let object = data.node.data;

			this._viewer.inputHandler.deselectAll();
			// propertiesPanel.set(object);

			if ( object instanceof this.Potree.PointCloudOctree ) {
				this.selected = object;
			} else {
				this.selected = null;
			}
		} );

		tree.on( "deselect_node.jstree", ( e, data ) => {
			// console.log("Deselect node")
			// propertiesPanel.set(null);
			this.selected = null;
		} );

		tree.on( "delete_node.jstree", ( e, data ) => {
			// console.log("Delete node")
		} );

		tree.on( "dblclick", ".jstree-anchor", ( e ) => {
			// ignore double click on checkbox
			if ( e.target.classList.contains( "jstree-checkbox" ) ) {
				return;
			}

			// console.log("Double Click node")
		} );

		tree.on( "uncheck_node.jstree", ( e, data ) => {
			let object = data.node.data;
			if ( data.node.id === "models" || data.node.parent === "models" ) {
				if ( object ) {
					object.show = false;
				} else if ( data.instance.is_parent( data.node ) ) {
					let childrenNodes = data.node.children;
					childrenNodes.forEach( id => {
						let child = data.instance.get_node( id );
						if ( child.data ) {
							child.data.show = false;
						}
					} );
				}
			} else {
				if ( object ) {
					object.visible = false;
				} else if ( data.instance.is_parent( data.node ) ) {
					let childrenNodes = data.node.children;
					childrenNodes.forEach( id => {
						let child = data.instance.get_node( id );
						if ( child.data ) {
							child.data.visible = false;
						}
					} );
				}
			}
		} );

		tree.on( "check_node.jstree", ( e, data ) => {
			let object = data.node.data;
			if ( data.node.id === "models" || data.node.parent === "models" ) {
				if ( object ) {
					object.show = true;
				} else if ( data.instance.is_parent( data.node ) ) {
					let childrenNodes = data.node.children;
					childrenNodes.forEach( id => {
						let child = data.instance.get_node( id );
						if ( child.data ) {
							child.data.show = true;
						}
					} );
				}
			} else {
				if ( object ) {
					object.visible = true;
				} else if ( data.instance.is_parent( data.node ) ) {
					let childrenNodes = data.node.children;
					childrenNodes.forEach( id => {
						let child = data.instance.get_node( id );
						if ( child.data ) {
							child.data.visible = true;
						}
					} );
				}
			}
		} );

		tree.on( "activate_node.jstree", ( e, data ) => {
			if ( data.instance.is_leaf( data.node ) ) {
				let parentNode = data.node.parent;
				// do {
				// 	data.instance.check_node(parentNode);
				// parentNode = data.instance.get_node(parentNode).parent;
				// } while (parentNode)
			}
		} );

		let onPointCloudAdded = ( e ) => {
			let pointcloud = e.pointcloud;
			let icon = "../../assets/icons/aa_logo.png";
			let node = createNode( pcID, pointcloud.name, icon, pointcloud );

			pointcloud.addEventListener( "visibility_changed", () => {
				if ( pointcloud.visible ) {
					tree.jstree( "check_node", node );
				} else {
					tree.jstree( "uncheck_node", node );
				}
			} );
		};

		let onMeasurementAdded = ( e ) => {
			let measurement = e.measurement;
			let icon = "../../assets/icons/aa_logo.png";
			let nodeId = createNode( measurementID, measurement.name, icon, measurement );
			this._selectedMeasurement.treeNodeId = nodeId;
		};

		let onModelAdded = ( e ) => {
			let model = e.model;
			let icon = "../../assets/icons/aa_logo.png";
			let node = createNode( modelID, model.name, icon, model );

			// Object.keys(model).forEach(key => {
			// 	if (/./.test(key)) {
			// 		model.addEventListener(key.slice(2), event => {
			// 			console.log("EVENT: ", key ,event);
			// 		});
			// 	}
			// })
		};

		this._viewer.scene.addEventListener( "pointcloud_added", onPointCloudAdded );
		this._viewer.scene.addEventListener( "measurement_added", onMeasurementAdded );

		const scene = this._viewer.scene;
		for ( let pointcloud of scene.pointclouds ) {
			onPointCloudAdded( { pointcloud: pointcloud } );
		}

		for ( let measurement of scene.measurements ) {
			onMeasurementAdded( { measurement: measurement } );
		}

		if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			for ( let model of this._loaded ) {
				if ( model.type === "model" ) {
					onModelAdded( { model: model.data } );
				}
			}
		}

		// console.log("WHAT IS TREE: ", tree, elObjects, elScene);
		// let checks = elObjects.find(".jstree-icon.jstree-checkbox");
		// let checks = $("i.jstree-icon.jstree-checkbox");

		// const toggle_code = '<h2>BACON</h2>';

// const toggle_code = '<mat-slide-toggle _ngcontent-ell-c201="" class="mat-slide-toggle mat-accent mat-checked" style="float:right;" id="mat-cell-slide-toggle-1" tabindex="-1"><label class="mat-slide-toggle-label" for="mat-cell-slide-toggle-1-input"><div class="mat-slide-toggle-bar mat-slide-toggle-bar-no-side-margin"><input type="checkbox" role="switch" class="mat-slide-toggle-input cdk-visually-hidden" id="mat-cell-slide-toggle-1-input" tabindex="0" aria-checked="true"><div class="mat-slide-toggle-thumb-container"><div class="mat-slide-toggle-thumb"></div><div mat-ripple="" class="mat-ripple mat-slide-toggle-ripple"><div class="mat-ripple-element mat-slide-toggle-persistent-ripple"></div></div></div></div><span class="mat-slide-toggle-content"><span style="display: none;">&nbsp;</span></span></label></mat-slide-toggle>';

// let toggle_code = '<mat-slide-toggle style="float:right;"> </mat-slide-toggle>'

		// let toggle_code = '<div style="width:10px; height:10px; background:red;"></div>';
		// $("i.jstree-icon.jstree-checkbox").replaceWith(toggle_code);


		// let docElm = document.getElementsByClassName("jstree-checkbox");
		// for (let d of docElm) {
		// 	let elm = document.createElement("mat-slide-toggle");
		// 	elm.setAttribute("style", "background:red;float:right;width:10px;height:10px")
		// 	d.appendChild(elm);
		// }

		// this.cd.detectChanges();

	}	// End-of setupTree

	// Dev-Testing helper function for spoofing a webgl crash
	crashContext(): void {
		const canvases = document.getElementsByTagName( "canvas" );
		for ( let canvas of canvases ) {
			const webgl2Context = canvas.getContext( "webgl2", {} );
			if ( webgl2Context ) {
				console.warn( `Losing WebGL2 context...` );
				webgl2Context.getExtension( "WEBGL_lose_context" ).loseContext();
				return;
			} else {
				const webglContext = canvas.getContext( "webgl", {} );
				if ( webglContext ) {
					console.warn( `Losing WebGL context...` );
					webglContext.getExtension( "WEBGL_lose_context" ).loseContext();
					return;
				}
			}
		}
	}

	/*
	* This function will let us hot load point clouds from either prod or staging.
	* It's a secret, console accessible function for internal testing only.
	*/
	hotLoadPointCloud( apiUrl, layer_raw ): void {
		let hotLayer = {
			id: "p" + layer_raw.id,
			name: "HOT ~" + layer_raw.id + " - point cloud",
			type: layer_raw.type + " point cloud",
			fileType: "potree_file",
			url: layer_raw.url,
			source: layer_raw.source,
			createdOn: layer_raw.created_at,
			createdBy: layer_raw.created_by_user_id,
			visible: false,
			showDetails: false,
			apiUrl: apiUrl,
			errors: {}
		};
		this.addPointCloud( hotLayer );
	}

	private setupPotree(): void {
		this._viewer = new this.Potree.Viewer( this.renderTarget.nativeElement );
		this._viewer.setBackground( null );
		this._viewer.setEDLEnabled( false );
		this._viewer.setEDLRadius( 1.5 );
		this._viewer.setEDLStrength( .1 );
		this._viewer.setMinNodeSize( 200 );
		this._viewer.setPointBudget( 1000_000 );
	}	// End-of setupPotree

	private setupCesium(): void {
		this._cesiumViewer = new Cesium.Viewer( "cesiumContainer", {
			useDefaultRenderLoop: false,
			animation: false,
			baseLayerPicker: false,
			fullscreenButton: false,
			vrButton: false,
			geocoder: false,
			homeButton: false,
			infoBox: false,
			sceneModePicker: false,
			selectionIndicator: false,
			timeline: false,
			navigationHelpButton: false,
			navigationInstructionsInitiallyVisible: false,
			scene3DOnly: true,
			skyAtmosphere: false,
			skyBox: false,
			showRenderLoopErrors: false,
			globe: false,
			terrainShadows: Cesium.ShadowMode.DISABLED
		} );
		this._cesiumViewer.resolutionScale = 0.75;
		this._cesiumViewer.scene.frameState.creditDisplay.container.style.visibility = "hidden";
		this.setBackground( this.DARK_BACKGROUND );
		this.renderLoop();
		let att = ( mouse ) => {
			return this.getMousePointIntersectionCheckOnCesium( mouse );
		};
		this.Potree.Utils.addExternalIntersectionCheck( att );
		this._exPrim = new Cesium.PrimitiveCollection();

	}	// End-of setupCesium

	private renderLoop( timestamp = 0 ): void {
		this._viewer.update( this._viewer.clock.getDelta(), timestamp );
		this._viewer.render();

		if ( this.toMap !== undefined ) {
			let camera = this._viewer.scene.getActiveCamera();

			if ( camera?.isCamera && camera?.isPerspectiveCamera ) {
				let pPos = new this.THREE.Vector3( 0, 0, 0 ).applyMatrix4( camera.matrixWorld );
				let pRight = new this.THREE.Vector3( 600, 0, 0 ).applyMatrix4( camera.matrixWorld );
				let pUp = new this.THREE.Vector3( 0, 600, 0 ).applyMatrix4( camera.matrixWorld );
				let pTarget = this._viewer.scene.view.getPivot();

				let toCes = ( pos ) => {
					let xy = [ pos.x, pos.y ];
					let height = pos.z;
					let deg = this.toMap.forward( xy );
					let cPos = Cesium.Cartesian3.fromDegrees( ...deg, height );
					return cPos;
				};

				let cPos = toCes( pPos );
				let cUpTarget = toCes( pUp );
				let cTarget = toCes( pTarget );

				let cDir = Cesium.Cartesian3.subtract( cTarget, cPos, new Cesium.Cartesian3() );
				let cUp = Cesium.Cartesian3.subtract( cUpTarget, cPos, new Cesium.Cartesian3() );

				cDir = Cesium.Cartesian3.normalize( cDir, new Cesium.Cartesian3() );
				cUp = Cesium.Cartesian3.normalize( cUp, new Cesium.Cartesian3() );

				this._cesiumViewer.camera.setView( {
					destination: cPos,
					orientation: {
						direction: cDir,
						up: cUp
					}
				} );

				let aspect = this._viewer.scene.getActiveCamera().aspect;
				if ( aspect < 1 ) {
					let fovy = Math.PI * ( this._viewer.scene.getActiveCamera().fov / 180 );
					this._cesiumViewer.camera.frustum.fov = fovy;
				} else {
					let fovy = Math.PI * ( this._viewer.scene.getActiveCamera().fov / 180 );
					let fovx = Math.atan( Math.tan( 0.5 * fovy ) * aspect ) * 2;
					this._cesiumViewer.camera.frustum.fov = fovx;
				}

				this._cesiumViewer.render();
			}
		}
		requestAnimationFrame( ( t ) => {
			this.renderLoop( t );
		} );
	}	// End-of renderLoop

	private getCameraXYZPT(): any {
		return {
			x: this._viewer.scene.view.position.x,
			y: this._viewer.scene.view.position.y,
			z: this._viewer.scene.view.position.z,
			yaw: this._viewer.scene.view.yaw,
			pitch: this._viewer.scene.view.pitch
		};
	}	// End-of getCameraXYZPT

}	// End-of class ViewerComponent

export class GetWorldPosition {
	static hide( primitiveCollection ): Array<any> {
		if ( !Cesium.defined( primitiveCollection ) ) {
			return [];
		}

		let primitiveCount = primitiveCollection.length;
		let visibilityStates = new Array( primitiveCount );

		for ( let i = 0; i < primitiveCount; i++ ) {
			let primitive = primitiveCollection.get( i );

			if ( primitive instanceof Cesium.Cesium3DTileset ) {
				continue;
			}

			if ( primitive instanceof Cesium.PointPrimitiveCollection ) {
				let pointPrimitiveCount = primitive.length;
				visibilityStates[ i ] = new Array( pointPrimitiveCount );
				for ( let j = 0; j < pointPrimitiveCount; j++ ) {
					let point = primitive.get( j );
					visibilityStates[ i ][ j ] = point.show;
					point.show = false;
				}
			} else {
				visibilityStates[ i ] = primitive.show;
				primitive.show = false;
			}
		}
		return visibilityStates;
	}	// End-of hide

	static restore( primitiveCollection, visibilityStates ): void {
		if ( !Cesium.defined( primitiveCollection ) ) {
			return;
		}

		let primitiveCount = primitiveCollection.length;
		for ( let i = 0; i < primitiveCount; i++ ) {
			if ( visibilityStates[ i ] ) {
				let primitive = primitiveCollection.get( i );
				if ( visibilityStates[ i ] instanceof Array ) {
					let count = primitive.length;
					for ( let j = 0; j < count; j++ ) {
						let p = primitive.get( j );
						p.show = visibilityStates[ i ][ j ];
					}

				} else {
					primitive.show = visibilityStates[ i ];
				}
			}
		}
	}	// End-of restore

	static getWorldPosition( scene, mousePosition, primitives, result? ): any {
		let cartesianScratch = new Cesium.Cartesian3();
		let rayScratch = new Cesium.Ray();
		result = Cesium.defined( result ) ? result : new Cesium.Cartesian3();

		let position;
		if ( scene.pickPositionSupported ) {
			let showStates = this.hide( primitives );
			let pickedObject = scene.pick( mousePosition, 1, 1 );
			this.restore( primitives, showStates );
			if ( Cesium.defined( pickedObject ) && ( pickedObject instanceof Cesium.Cesium3DTileFeature || pickedObject.primitive instanceof Cesium.Cesium3DTileset || pickedObject.primitive instanceof Cesium.Model ) ) {
				position = scene.pickPosition( mousePosition, cartesianScratch );

				// if (Cesium.defined(position)) {
				// 	return Cesium.Cartesian3.clone(position, result);
				// }
				if ( Cesium.defined( position ) ) {
					return {
						position: Cesium.Cartesian3.clone( position, result ),
						object: pickedObject
					};
				}
			}
		}

		let ray = scene.camera.getPickRay( mousePosition, rayScratch );
		if ( scene.globe ) {
			position = scene.globe.pick( ray, scene, cartesianScratch );
		}

		if ( Cesium.defined( position ) ) {
			return {
				position: Cesium.Cartesian3.clone( position, result )
			};
		}
	}	// End-of getWorldPosition
}	// End-of class GetWorldPosition

export class PropertiesPanel {

	private container;
	private viewer;
	private object;
	private cleanupTasks: Array<any>;
	private scene;

	constructor( container, viewer ) {

		this.container = container;
		this.viewer = viewer;
		this.object = null;
		this.cleanupTasks = [];
		this.scene = null;

	}	// End-of constructor

	setScene( scene ): void {
		this.scene = scene;
	}	// end-of setScene

	set( object ): void {
		if ( this.object === object ) {
			return;
		}

		this.object = object;
		for ( let task of this.cleanupTasks ) {
			task();
		}
		this.cleanupTasks = [];
		this.container.empty();

		if ( object instanceof Potree.PointCloudTree ) {
			this.setPointCloud( object );
		}
	}	// End-of set

	addVolatileListener( target, type, callback ): void {
		target.addEventListener( type, callback );
		this.cleanupTasks.push( () => {
			target.removeEventListener( type, callback );
		} );
	}	// End-of addVolatileListener

	setPointCloud( pointcloud ): void {

		let material = pointcloud.material;

		let panel = $( `` );
		this.container.append( panel );

		{	// Point Size
			// let sldPointSize = panel.find(`#sldPointSize`);
			// let lblPointSize = panel.find(`#lblPointSize`);

			// sldPointSize.slider({
			// 	value: material.size,
			// 	min: 0,
			// 	max: 3,
			// 	step: 0.01,
			// 	slide: function (event, ui) { material.size = ui.value; }
			// });

			// let update = () => {
			// 	lblPointSize.html(material.size.toFixed(2));
			// 	sldPointSize.slider({value: material.size});
			// };
			// this.addVolatileListener(material, "point_size_changed", update);

			// update();
		}

	}	// End-of setPointCloud

}	// End-of class PropertiesPanel
