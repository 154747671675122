import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { GlobalService } from "@shared/services";
import { makeURLParams } from "@shared/services/utils.service";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: GlobalService.databaseApiUrl,
		prepareHeaders: (headers) => {
			const user = GlobalService?.authenticationService?.user;
			if (user && user.api_key) {
				headers.set(
					"x-api-key",
					GlobalService.authenticationService.token
				);
			}

			return headers;
		},
	}),
	endpoints: (build) => ({
		getProject: build.query({
			providesTags: (result, error, id) => [{ id, type: "Project" }],
			query: (id) => GlobalService.databaseApiUrl + `/projects/${id}`,
		}),
		getProjectDeep: build.query({
			providesTags: (result, error, organization_id) => [
				{ organization_id, type: "Project" },
			],
			query: (id) => GlobalService.databaseApiUrl + `/v2/projects/${id}`,
		}),
		getProjectsShallow: build.query({
			providesTags: (result, error, organization_id) => [
				{ organization_id, type: "Project" },
			],
			query: (organization_id) =>
				`${GlobalService.databaseApiUrl}/v2/projects${makeURLParams({
					organization_id,
				})}`,
		}),
	}),
	tagTypes: ["Project"],
});

export const { useGetProjectQuery } = api;
