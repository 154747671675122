
<div class="paywall-info" *ngIf="showInfo && disabled">
	<mat-icon>info</mat-icon>
	<div>
		<div class="paywall-title">No active subscription</div>
		<div class="paywall-text">Your team doesn't have an active subscription. To access your existing projects and create new ones, please choose a
			<span class="link" (click)="openUpgrade()">subscription plan.</span>
		</div>
	</div>
</div>

<div data-testid="paywall-content" class="paywall" [ngClass]="{'paywall-active': disabled}">
	<ng-content></ng-content>
</div>
