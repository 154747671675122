
<div id="floating-bar" class="floating-bar">
	<div class="top-bar" (click)="toggleExpandBar();">
		<div class="bar-content">
			<ng-content select="[header]"></ng-content>
			<span class="bar-content-inner">
				<span class="expand-icons" *ngIf="bodyRef && bodyRef.innerHTML.trim()">
					<mat-icon *ngIf="expandBar">
						keyboard_arrow_down
					</mat-icon>

					<mat-icon *ngIf="!expandBar">
						keyboard_arrow_up
					</mat-icon>
				</span>

				<span class="dismiss"
					(click)="dismiss()">
					Dismiss
				</span>
			</span>
		</div>
	</div>

	<div #bodyRef
		id="bar-view"
		class="bar-view"
		[ngClass]="{'shrunk' : !expandBar}">
		<ng-content select="[body]"></ng-content>
	</div>
</div>
