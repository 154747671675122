import { useState } from "react";

import { store } from "@app/store";
import { api as annotationsAPI } from "@features/Annotations";

import { AnnotationCreate } from "./AnnotationCreate";
import { AnnotationsList } from "./AnnotationsList";

const ProjectAnnotations = ({ project }) => {
	const [annotations, setAnnotations] = useState(null);

	const getAnnotations = (project) => {
		store
			.dispatch(annotationsAPI.endpoints.getList.initiate(project.id))
			.then(({ data }) => {
				setAnnotations(data);
			});
	};

	const getAnnotationsThunk = (project) => () => {
		setTimeout(() => {
			getAnnotations(project);
		}, 200);
	};

	return (
		<div className="outlined-container">
			<div className="H3Black500">{project.name}</div>
			<div className="H6Black500Semibold">ID: {project.id}</div>
			<div className="annotation-tools">
				<AnnotationCreate
					project={project}
					getAnnotationsThunk={getAnnotationsThunk(project)}
				/>
				<AnnotationsList
					annotations={annotations}
					project={project}
					getAnnotationsThunk={getAnnotationsThunk(project)}
				/>
			</div>
		</div>
	);
};

export default ProjectAnnotations;
