import L from "leaflet";

import { AnnotationType } from "../../../viewer/classes/measure";
import { LabelAnnotation } from "./labelAnnotation";

export class TrainingRegion extends LabelAnnotation {
	
	name = "New Training Region";
	type = AnnotationType.TRAINING_REGION;

	public coordinates = [];

	public nwTile = [];
	public seTile = [];

	public tiles = 0;
	public width = 0;
	public height = 0;
	public area = 0;
	public superTiles = 0;
	public trainingRegionID = "idk"
	public zoomLevel = 21;

	public tempRect: L.Polygon;

	constructor(
		params = {},
		coords: [] = []
	) {
		super();
		Object.assign(this, params);

		this.coordinates = coords;
	}

	render(): void {
		if(this.coordinates?.length == 2)
			L.rectangle([this.coordinates[0], this.coordinates[1]], {color: this.color, weight: 2, fill: ''}).addTo(this.layer);

	}

	highlight(highlightedLabel) {
		if (this.layer) {
			this.tempRect = L.rectangle([highlightedLabel.coordinates[0], highlightedLabel.coordinates[1]], {color: this.color, weight: 4, fill: ''}).addTo(this.layer);
		}
	}

	unhighlight() {
		if (this.layer) {
			this.layer.removeLayer(this.tempRect)
		}
	}

	getDescriptors():{} {
		return {
			coordinates: this.coordinates
		};
	}

	updateTrainingRegionStats(start, end) {
		let startTile = this.latLng_ToTile(start, this.zoomLevel);
		let endTile = this.latLng_ToTile(end, this.zoomLevel);

		console.log("start tile", startTile)
		console.log("endTile", endTile)

		if(startTile.x <= endTile.x) {//@ts-ignore
			this.nwTile = { x: startTile.x };//@ts-ignore
			this.seTile = { x: endTile.x };
		} else {//@ts-ignore
			this.nwTile = { x: endTile.x };//@ts-ignore
			this.seTile = { x: startTile.x };
		}

		if(startTile.y <= endTile.y) {//@ts-ignore
			this.nwTile.y = startTile.y;//@ts-ignore
			this.seTile.y = endTile.y;
		} else {//@ts-ignore
			this.nwTile.y = endTile.y;//@ts-ignore
			this.seTile.y = startTile.y;
		}
		//@ts-ignore
		this.width = this.seTile.x - this.nwTile.x;//@ts-ignore
		this.height = this.seTile.y - this.nwTile.y;
		this.tiles = this.width * this.height;

		let tilesize = 15.755;
		tilesize = 20.054;
		tilesize = 5165.726;
		this.area = Math.ceil(this.tiles * tilesize);

		this.calcSuperTiles();
	}

	tileXY_toLatLng(xtile, ytile, zoom) {
		let n = Math.pow(2.0, zoom);
		let lng = xtile / n * 360.0 - 180.0;
		let lat_rad = Math.atan(Math.sinh(Math.PI * (1 - 2.0 * ytile / n)));
		let lat = 180.0 * lat_rad / Math.PI;
		return {lat, lng};
	}

	latLng_ToTile(latLng, zoom) {
		let lat_rad = latLng.lat * (Math.PI / 180);
		let n = Math.pow(2.0, zoom);
		let xTile = Math.floor(((latLng.lng + 180) / 360) * n);
		let yTile = Math.floor(((1 - Math.asinh(Math.tan(lat_rad)) / Math.PI) / 2) * n);
		return {
			x: xTile,
			y: yTile
		};
	} 

	toTile_NW(latlng) {
		let tileXY = this.latLng_ToTile(latlng, this.zoomLevel);
		return this.tileXY_toLatLng(tileXY.x, tileXY.y, this.zoomLevel);
	}

	toTile_SE(latlng) {
		let tileXY = this.latLng_ToTile(latlng, this.zoomLevel);
		return this.tileXY_toLatLng(tileXY.x+1, tileXY.y+1, this.zoomLevel);
	}

	twoPointsToTileRects(start, end) {
		let points = [];

		//@ts-ignore
		let northWest = this.toTile_NW(this.tileXY_toLatLng(this.nwTile.x, this.nwTile.y, this.zoomLevel)) //@ts-ignore
		let southEast = this.toTile_SE(this.tileXY_toLatLng(this.seTile.x, this.seTile.y, this.zoomLevel))

		/*
		//@ts-ignore
		points.push([northWest.lat, northWest.lng])//@ts-ignore
		points.push([northWest.lat, southEast.lng])//@ts-ignore
		points.push([southEast.lat, southEast.lng])//@ts-ignore
		points.push([southEast.lat, northWest.lng])
*/

		//this.updateTrainingRegionStats(start, end);

		// go thru each tile
		// @ts-ignore
		let lastLat = this.tileXY_toLatLng(this.nwTile.x, this.nwTile.y, this.zoomLevel).lat;// @ts-ignore
		let lastLng = this.tileXY_toLatLng(this.nwTile.x, this.nwTile.y, this.zoomLevel).lng;

		// @ts-ignore
		for(let y = this.nwTile.y; y < this.seTile.y; y++) {// @ts-ignore
			for(let x = this.nwTile.x; x < this.seTile.x; x++) {			
				// got a tile
				// add tile points to the list
				let topLeftLatLng = this.tileXY_toLatLng(x, y, this.zoomLevel)
				let botRightLatLng = this.tileXY_toLatLng(x+1, y+1, this.zoomLevel)// @ts-ignore
				
				if(topLeftLatLng.lat != lastLat)
				{
					// @ts-ignore
					points.push([lastLat, topLeftLatLng.lng]);
				}

				if(topLeftLatLng.lng != lastLng)
				{
					// @ts-ignore
					//points.push([topLeftLatLng.lat, lastLng]);
				}

				// @ts-ignore
				points.push([topLeftLatLng.lat, topLeftLatLng.lng])// @ts-ignore
				points.push([topLeftLatLng.lat, botRightLatLng.lng])// @ts-ignore
				points.push([botRightLatLng.lat, botRightLatLng.lng])// @ts-ignore
				points.push([botRightLatLng.lat, topLeftLatLng.lng])// @ts-ignore
				points.push([topLeftLatLng.lat, topLeftLatLng.lng])
				
				lastLat = topLeftLatLng.lat;
				lastLng = topLeftLatLng.lng;
			}
		}
		// @ts-ignore
		let nETile = this.tileXY_toLatLng(this.seTile.x, this.nwTile.y, this.zoomLevel);// @ts-ignore
		points.push([nETile.lat, lastLng])
		

		return points;
	}

	printOutTiles() {
		let csv = "id,x,y\n";
		let id = 0;
		// @ts-ignore
		for(let y = this.nwTile.y; y < this.seTile.y; y++) {// @ts-ignore
			for(let x = this.nwTile.x; x < this.seTile.x; x++) {			
				csv += id+","+x+","+y+"\n";
				id++;
			}
		}
		return csv;
	}

	calcSuperTiles() {
		let superTiles = [];

		if(this.width > 1 && this.height > 1) {
			// @ts-ignore
			for(let y = this.nwTile.y; y < this.seTile.y-1; y++) {// @ts-ignore
				for(let x = this.nwTile.x; x < this.seTile.x-1; x++) {	// @ts-ignore
					//@ts-ignore
					superTiles.push([
						[x, y],
						[x+1, y],
						[x+1, y+1],
						[x, y+1]])
				}
			}
		}
		this.superTiles = superTiles.length;
	}

	makeid(length) {
		let result = '';
		const characters = 'abcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	}
}