
/* Imports */
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

import { CreateProjectsComponent } from '../create/create.projects.component';
/* Router */
import { Router } from '@angular/router';

/* Services */
import {
	AlertService,
	AuthenticationService,
	ProjectService,
	OrganizationService,
	FavoriteService,
	SessionService,
	SelectedProjectService,
	ShareService,
	UtilsService,
	sortName,
	sortNameReversed,
	sortDate,
} from "@shared/services";
import { analyticsLayer } from '@shared/analyticsLayer';

/* Model */
import { Alert, Organization, Project } from '@shared/models';
import { ShareComponent } from '@app/components';
import { RenameModal } from '../../rename';
import { ConfirmationModal } from '@app/components/confirmation';
import { TagsModal } from '@app/pages/tags';
import { Subscription } from "rxjs";
import { PermissionService } from '../../../shared/services';
import {availableFeatureFlags, flagLayer} from '@shared/featureFlags';

@Component({
	selector: 'app-list-projects',
	templateUrl: './list.projects.component.html',
	styleUrls: ['./list.projects.component.scss']
})
export class ListProjectsComponent implements OnInit {

	/* View Children */
	@ViewChild('rename_model') renameModelTrigger: MatDialog;
	public gridDisplay: boolean = true;
	public sortOptions: Array<any> = [{ text: 'Name (A-Z)', value: 'name' }, { text: 'Name (Z-A)', value: 'name_reverse' }, { text: 'Last Created', value: 'created_at' }, { text: 'Last Modified', value: 'updated_at' }];
	public sortSelection: any = this.sortOptions[3];
	public isLoading: boolean = false;
	public selectedProject: Project;
	public user: any;
	public isMobile: boolean = false;
	public projectList: Array<any>;
	public tagList: Array<string> = [];
	public activeOrg: Organization = null;
	public activeOrgSubscription: Subscription;
	private isCreateProjectOpen: boolean = false;
	public userPermissions;

	private sortingMap = {
		created_at: () => sortDate("created_at"),
		updated_at: () => sortDate("updated_at"),
		name: () => sortName,
		name_reverse: () => sortNameReversed,
	};

	constructor(
		public dialog: MatDialog,
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
		private _organizationService: OrganizationService,
		private _projectService: ProjectService,
		private _favoriteService: FavoriteService,
		private _shareService: ShareService,
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _cdr: ChangeDetectorRef,
		private _utilsService: UtilsService,
		private _selectedProject: SelectedProjectService,
	) {

		this.isMobile = this._utilsService.getIsMobile();

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.isMobile = isMobile;
			this.gridDisplay = SessionService.get('project_gridDisplay') ||
				(this.isMobile && this._utilsService.getIsMobilePortrait()
				);
		})

		this.setupActiveOrgSubscription();

	}	// End-of constructor

	ngOnInit() {

		this.user = this._authService.user;

		if (SessionService.isActive) {
			this.gridDisplay = SessionService.get('project_gridDisplay') ||
				(this.isMobile && this._utilsService.getIsMobilePortrait()
				);
		}

	}	// End-of ngOnInit

	ngOnDestroy() {
		this.activeOrgSubscription?.unsubscribe();
	}

	setupActiveOrgSubscription() {

		this.activeOrgSubscription = this._organizationService
			.getActiveOrg()
			.subscribe(({ organization }) => {
				this.activeOrg = organization;
				this.setup(organization);
				this._permissionService.getUserPermissions(organization).then(rtnPermissions => {
					this.userPermissions = rtnPermissions;
				})
			})
	}

	setup(org: Organization = this.activeOrg): void {
		this.isLoading = true;
		if (org?.id) {
			(flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)
				? this._projectService.getListV2(org.id)
				: this._projectService.getList(org))
				.then(this.handleProjects)
				.catch(error => {
					console.error(error);
					this.isLoading = false;
				});
		}

	}	// End-of setup

	openCreateProject() {
		if (!this.isCreateProjectOpen) {
			const dialogRef = this.dialog.open(CreateProjectsComponent);
			this.isCreateProjectOpen = true;
			dialogRef.afterClosed().subscribe(event => {
				this.isCreateProjectOpen = false;
			});
		}
	}

	// Run through each of the major project functions, to assign, sort, and define
	handleProjects = async (projects: Array<any>): Promise<any> => {

		projects = Array.isArray(projects) ? projects : [];

		this.assignAndFilterProjects(projects)
			.then(this.assignProjectSharesFavorites.bind(this))
			.then(this.sortProjectList.bind(this))
			.catch(err => {
				console.error(err);
				this._alertService.error(new Alert('There was an error getting your projects. Please refresh the page.'))
			});

	}

	assignAndFilterProjects(projects): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {

			// Get the tags used in all of the projects, for easier re-use
			this.tagList = this.getProjectTags(projects);

			const filteredProjects = projects.filter(proj => {

				// Initialize dates
				proj.created_at = new Date(proj.created_at);
				proj.updated_at = new Date(proj.updated_at);

				// Filter out trashed projects
				return proj.trash === 0;
			});
			resolve(filteredProjects);
		})
	}

	assignProjectSharesFavorites(projects: Array<any> = []): Promise<any> {

		return this._favoriteService.getList(this.activeOrg).then(favList => {
			const projectFavorites = favList.projects;
			projects.forEach((project) => {
				if (projectFavorites?.length) {
					const favorite = projectFavorites.find(x => x.project_id === project.id)
					if (favorite) {
						Object.assign(project, { favorited: true, favorited_id: favorite.id })
					}
				}
				project['shared_with_user'] = project['shares']?.find(x => x.shared_with_user_id == this.user.id);
			});
			return projects;
		});

	}	// End-of updateFileList

	toggleGrid(): void {

		this.gridDisplay = !this.gridDisplay;
		SessionService.set('project_gridDisplay', this.gridDisplay);
		analyticsLayer.trackSwitchProjectView(this.gridDisplay);

	}	// End-of changeGrid

	userSortProjectList(list: Array<Project>): void {
		this.sortProjectList(list);
		analyticsLayer.trackSortProjects(this.sortSelection.value);
	}

	sortProjectList(list: Array<Project>): void {

		this.isLoading = true;

		const newList = list.sort(this.sortingMap[this.sortSelection.value]());
		this.projectList = [...newList];
		this.isLoading = false;

	}	// End-of sortData

	navigateTo(project, tab?): void {
		this._selectedProject.setSelectedProject(project);
		this._router.navigate(['projects', 'view', project.id], { queryParams: { tab: tab ?? 0 } });

	}	// End-of navigateTo

	handleMenuClose(res, close: boolean = true): void {

		let project = this.projectList.find(x => x.id === res.project.id);

		switch (res.text) {
			case ('share'):
				// Sending !close opens the shared user list first, before showing the options
				this.openShareModal(res.project, !close);
				break;
			case ('favorite'):
				this.onFavorite(res.project);
				break;
			case ('tags'):
				this.openTagsModal(res.project);
				break;
			case ('rename'):
				this.openRenameModal(res.project);
				break;
			case ('remove'):
				const isSharedWithUser = project['shares']?.length > 0 && project['shared_with_user'];
				isSharedWithUser ? this.openRemoveFromShare(project) : this.onRemove(res.project);
				break;
		}
	}

	openRemoveFromShare(project): void {
		let confirmString: string =
			`Are you sure you want to remove project ${project.name}? You will lose access until it is shared with you again.`;
		let dialogRef = this._dialog.open(ConfirmationModal, {
			data: confirmString,
		});
		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn) {
				const share = project.shares.find(share => share.shared_with_user_id === this.user.id);
				this.onRemoveShare(project, share);
			}
		});
	}

	openTagsModal(proj): void {
		this.selectedProject = proj;
		let dialogRef = this.dialog.open(TagsModal, { data: { project: proj, tagList: this.tagList } });
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.setup();
			}
		});
	}

	openShareModal(project, showShareList = false): void {

		this.selectedProject = project;
		let dialogRef = this.dialog.open(ShareComponent, { data: { project, showShareList } });
		dialogRef.afterClosed().subscribe(rtn => {
			if (rtn && rtn.proj) {
				this.onRename(rtn.proj);
			}
		});

	}	// End-of openRenameModal

	openRenameModal(proj): void {

		this.selectedProject = proj;
		let dialogRef = this.dialog.open(RenameModal, { data: proj });
		dialogRef.afterClosed().subscribe(rtn => {
			if (rtn) {
				let proj = new Project(rtn);
				this.onRename(proj);
			}
		});

	}	// End-of openRenameModal

	onRename(proj: Project): void {
		let index = this.projectList.findIndex(p => { return p.id == proj.id; });
		if (index != undefined || index != null) {
			this._projectService.update(proj).then(() => {
				let alertText = "Project renamed to '" + proj.name + "'";
				this._alertService.notification(new Alert(alertText, 'check'));
				this.selectedProject = null;
				this.projectList[index] = proj;
				this.setup();
				this._cdr.detectChanges();
				analyticsLayer.trackRename(proj, "Project");
			}, error => {
				switch (error) {
					case 402:
						console.error("Failed to rename project: ", error.status);
						break;
					default:
						console.warn(error);
						break;
				}
			});
		}

	}	// End-of onRename

	onRemoveShare(proj, share): void {
		this._shareService.remove(share.id).then(rtn => {
			this._alertService.success(new Alert(`Project ${proj.name} is no longer shared with you`));
			this.projectList = this.projectList.filter(p => p.id !== proj.id);
		}).catch(err => {
			console.error(err);
		})
	}

	onRemove(proj: Project): void {

		this._projectService.trash(proj).then(() => {
			let alertText = "'" + proj.name + "' moved to trash";
			this._alertService.notification(new Alert(alertText, 'delete_outline'));
			this.projectList = this.projectList.filter(p => p.id !== proj.id);
			analyticsLayer.trackRemove(proj, "Project");
		}, error => {
			switch (error) {
				case 402:
					console.error('Failed to remove project: ', error.status);
					break;
				default:
					console.warn(error);
					break;
			}
		});

	}	// End-of onRemove

	onFavorite(proj: Project): void {

		if (proj["id"]) {
			let idx = this.projectList.findIndex(x => x.id == proj.id);
			if (proj['favorited']) {
				this._favoriteService.getByProjectId(proj['id']).then(res => {
					this._favoriteService.remove(res).then(() => {
						let alertText = "Removed from Favorites";
						this._alertService.notification(new Alert(alertText));
						this.projectList[idx]['favorited'] = false;
						analyticsLayer.trackFavorite("remove", proj, "Project");
					}, () => {
						let alertText = "Failed to remove item";
						this._alertService.error(new Alert(alertText));
					});
				});
			} else {
				this._favoriteService.create({ project_id: proj["id"] }).then(rtnData => {
					let alertText = "Added to Favorites";
					this._alertService.notification(new Alert(alertText, 'star_border'));
					this.projectList[idx]['favorited_id'] = rtnData.favorite_id;
					this.projectList[idx]['favorited'] = true;
					analyticsLayer.trackFavorite("add", proj, "Project");
				}).catch(err => {
					if (err === 412) {
						this._alertService.error(new Alert(`Project ${proj.name} has already been favorited`))
					} else {
						this._alertService.error(new Alert(`Could not favorite project ${proj.name}`))
					}
					console.error(err);
				});
			}
		}

	}	// End-of favoriteProject


	getProjectTags = (projects): Array<string> =>
		projects
			.reduce((tagList, p) => tagList.concat(p.descriptors?.tags ?? []), [])
			.filter((item, pos, tagList) => tagList.indexOf(item) === pos);

	canCreateProject = (): boolean => {
		return this._permissionService.compareToOrgPermissions(this.activeOrg, "process", this.user.id);
	}

}	// End-of ListProjectComponent
