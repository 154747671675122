
/* Imports */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	Router,
	NavigationEnd,
} from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import pluralize from "pluralize";

/* Services */
import {
	AuthenticationService,
	ProjectService,
	SessionService,
	PermissionService,
	UrlService,
	objectSwapKeyValue,
	AlertService,
	UploadService,
	ModelService
} from '@shared/services';

/* Models */
import { Alert, Model, Project } from '@shared/models';
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";
import { isNil } from 'lodash';
import { matchPaths } from "@app/utils/matchPaths";
import { ImportDialog } from '@app/components/import-dialog';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { UploadDialog } from '@app/components/upload-dialog';
import {NewProcessComponent} from "@app/components/process";
import {AnalysisComponent} from "@app/pages/ai/analysis.component";

const baseTabs = {
	"models": 0,
	"photos": 1,
	"data": 2,
	"exports": 3
}

const processTabs = {
	"models": 0,
	"processes": 1,
	"photos": 2,
	"data": 3,
	"exports": 4
}

let projectTabs = baseTabs;

interface TabIndex {
	tab: string;
	index: number
}

const matcher = matchPaths({
	'#/projects/view/:projectId\\?tab=:tab': params => params['projectId'],
	'#/projects/view/:projectId': params => params['projectId'],
});

const dataHasChanged = (checkProj, project, type): boolean => {
	return (!isNil(project?.[type + "_count"]) && checkProj?.[type]?.length !== project?.[type + "_count"]);
}

const updateProjectData = (checkProj, project, type) => {
	project[type] = checkProj[type];
	project[type + "_count"] = checkProj?.[type]?.length
}

@Component({
	selector: 'app-view-projects',
	templateUrl: './view.projects.component.html',
	styleUrls: ['./view.projects.component.scss']
})
export class ViewProjectsComponent implements OnInit, OnDestroy {

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	/* Boolean toggles */
	public isProcessing: boolean = true;
	public user: any;
	public userPermissions: boolean = false;
	public hasPermissions: boolean = false;
	public projectPromise: Promise<any> = null
	public projectDetailsPromise: Promise<any> = null;
	public dialogSubscription: Subscription = null;

	/* Public variables */
	public project: Project;

	/* Private variables */
	private _activatedRouteSubscription: Subscription;
	private readonly DEFAULT_TAB_INDEX = 0;

	public set selectedTabIndex(index: number) {
		this._selectedTabIndex = index;
		SessionService.set('project_tab_index', this._selectedTabIndex);
	}
	public get selectedTabIndex() {
		return this._selectedTabIndex;
	}
	public _selectedTabIndex: number = this.DEFAULT_TAB_INDEX;
	constructor(
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _router: Router,
		private _projectService: ProjectService,
		private _urlService: UrlService,
		private _uploadService: UploadService,
		private _modelService: ModelService,
		private _permissionService: PermissionService,
		private _location: Location,
	) {
		this.user = this._authService.user;
		this.getProject();
	}	// End-of constructor

	onProjectUpdate(change) {
		if (dataHasChanged(change.project, this.project, change.type)) {
			updateProjectData(change.project, this.project, change.type);
		}
	}

	ngOnDestroy() {
		this._activatedRouteSubscription?.unsubscribe();
		this.dialogSubscription?.unsubscribe();
	}

	ngOnInit() {

		if (flagLayer.isEnabled(availableFeatureFlags.processesPage)) {
			projectTabs = processTabs;
		}
		this._activatedRouteSubscription = this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.getProject();
			}
		});

	}	// End-of ngOnInit

	async getProject() {
		const projectId = parseInt(matcher(window.location.hash) as string);

		if (!isNil(projectId) && !isNaN(projectId) && this.project?.id !== projectId) {
			this.projectPromise = null;
			this.projectDetailsPromise = null;
			this.isProcessing = true;

			this.projectPromise = flagLayer.isEnabled(availableFeatureFlags.apiV2Routes) ?
				this._projectService.getByIdV2(projectId) :
				this._projectService.getById(projectId);

			this.projectPromise.then(rtnProj => {
				this.project = rtnProj;
				this.updateProjectDetails(rtnProj);
				this._permissionService.getUserPermissions(rtnProj.organization_id).then(rtn => {
					this.userPermissions = rtn;
					this.checkRolePermissions(this.userPermissions);
					this.isProcessing = false;

					this._urlService.getQueryParams().then(({ tab }) => {
						this.setTab(tab);
					})
				}).catch(console.error);
				this.projectPromise = null;
			}).catch(err => {
				console.error(err);
				this._alertService.error(new Alert("Couldn't get your project's information."));
				this.isProcessing = false;
				this.projectPromise = null;
			})
		}
	}

	updateProjectDetails(project = this.project) {
		if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes) && !isNil(project?.id) && !this.projectDetailsPromise) {
			this.projectDetailsPromise = this._projectService.getProjectDetailsV2(project.id).then(projDetails => {
				this.project = Object.assign({}, project, projDetails);
				this.projectDetailsPromise = null;
			}).catch(err => {
				this.projectDetailsPromise = null;
				console.error(err);
			});
		}
	}

	setTab(tabOrIndex: string | number) {
		const { tab, index } = this.getTabAndIndex(tabOrIndex);
		this.selectedTabIndex = index;
		this._urlService.applyParams({ tab });
	}

	getTabAndIndex(tabOrIndex: any = 0): TabIndex {
		const isTab = isNaN(tabOrIndex),
			tab = isTab ? tabOrIndex : objectSwapKeyValue(projectTabs)[tabOrIndex],
			index = isTab ? projectTabs[tabOrIndex] : tabOrIndex;
		return { tab, index }
	}

	onIndexChange(event): void {
		if (!isNil(event) && this.selectedTabIndex !== event) {
			this.selectedTabIndex = event;
			this.setTab(event)
		}
	}

	badProject(): boolean {
		return !this.project;
	}

	openAiInferencingWizard() {
		this._dialog.open(AnalysisComponent, {
			data: {
				project_id: this.project.id,
			}
		});
	}

	openCreateProcess(openImport = false): void {
		if (flagLayer.isEnabled(availableFeatureFlags.newProcess)) {
			this.dialogSubscription = this._dialog.open(NewProcessComponent, {
				data: {
					hasPermissions: this.hasPermissions,
					project: this.project,
				}
			}).afterClosed().subscribe((val) => {
				if (val) this.openUploadDialog();
			});
		} else {
			const route = openImport ? "/projects/import/" : "/projects/process/";
			this._router.navigate([route], {
				queryParams: { project_id: this.project.id },
			});
		}
	} // End-of openCreateProcess

	openImportDialog(): void {
		const dialogRef = flagLayer.isEnabled(availableFeatureFlags.newUpload) ?
			this._dialog.open(UploadDialog, { data: { project: this.project } }) :
			this._dialog.open(ImportDialog, { data: [] });

		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn?.files?.length) {
				this.importModels(rtn.files);
			}
		});
	}

	importModels(files: File[]): void {
		files.forEach(file => {
			this._modelService.createV2({ name: file.name, project_id: this.project.id })
				.then((rtnModel: Model) => {
					this._alertService.success(new Alert(`Uploading ${file.name}...`));
					this.tryModelUpload(file, rtnModel);
				})
				.catch(err => {
					this.handleImportError(err, file);
				})
		})
	}

	tryModelUpload(file, model): void {
		try {
			this._uploadService.uploadModelImportV2(file, model.id).then(rtn => {
				if (rtn) {
					this._alertService.success(new Alert(`Successfully uploaded ${file.name}`));
				}
			});
		} catch (err) {
			this.handleImportError(err, file);
		}
	}

	handleImportError(err, file): void {
		console.error(err);
		this._alertService.error(new Alert(`Failed to upload ${file.name}, please try again.`))
	}

	openMap() {
		window.open(`#/map/${this.project.id}`, "_blank");
	}

	disableMapButton() {
		return !(this.project?.id);
	}

	goBack(): void {
		this._router.navigateByUrl("/");
	}

	setPlural(text, number): string {
		return pluralize(text, number);
	}

	hasValue(value: any): boolean {
		return !isNil(value);
	}

	openUploadDialog(options = {}): void {

		const dialogRef =
			this._dialog.open(UploadDialog, { data: { project: this.project, ...options } });

		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn?.files?.length) {
				this.importModels(rtn.files);
			}
		});
	}

	checkRolePermissions(rolePermissions) {
		this.hasPermissions = rolePermissions.admin || rolePermissions.process;
	}

}	// End-of class TrashComponent
