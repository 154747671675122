import { useState } from "react";

type Expandable = Record<string, boolean>;
export const useExpandableSections = (
	initialValue = {}
): [Expandable, (name: string) => void] => {
	const [expansions, toggleExpansions] = useState(initialValue);

	function toggleExpansion(name) {
		toggleExpansions((d) => {
			d[name] = !d[name];
		});
	}

	return [expansions, toggleExpansion];
};
