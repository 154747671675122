<div class="confirmation-container" data-testid="confirmation-modal">
	<h1 mat-dialog-title class="H3Black700 text-centered">
		Move Selected Images
	</h1>
	<button mat-button class="close-button" matTooltip="Close" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>

	<ng-container *ngTemplateOutlet="t_batchSelect"></ng-container>

	<div class="dialog-buttons">
		<button mat-button
				matDialogClose>
			Cancel
		</button>
		<button
			data-testid="move-confirm"
			class="move-button"
			[disabled]="isLoading"
			mat-stroked-button
			color="primary"
			(click)="confirmationAction()">
			Move
		</button>
	</div>
</div>

<ng-template #t_batchSelect>
	<ng-container *ngIf="isLoading">
		<div class="loading-container">
			<div class="dot-stretching"></div>
		</div>
	</ng-container>
	<form class="move-form" *ngIf="!isLoading && batchForm && fromBatch" [formGroup]="batchForm">
		<div class="upload-text">
			You can move {{(selectedImages?.length > 0) ? getSelectedImagesText(selectedImages) : "your selected images"}} to another <b>Image Group</b> in your project.
		</div>
		<div class="H6Black500Regular">Current Image Group: <b>{{ fromBatch.name }}</b></div>
		<div class="H6Black500Regular">Move to:</div>
		<mat-form-field appearance="outline" class="batch-select">
			<mat-select formControlName="batch" data-testid="upload-batch">
				<mat-option data-testid="batch-option" *ngFor="let batch of batches" [value]="batch">{{ batch.name }}
					({{ batch.images?.length }} images)
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
</ng-template>
