
/* Imports */
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

/* Services */
import { GlobalService }  from '../../shared/services/global.service';

// TEMP DATA
// const AIR_PORTS = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

/* Needed packages
    "@loaders.gl/draco": "^2.2.7",
    "loaders.gl": "^0.3.5",
    "mapbox-gl": "^1.11.1",
    "deck.gl": "^8.2.2"
*/

// import * as turf from '@turf/turf'

// import { Deck, MapView, OrbitView, OrthographicView, FlyToInterpolator } from '@deck.gl/core';

// import { ScatterplotLayer, GeoJsonLayer, ArcLayer, BitmapLayer } from '@deck.gl/layers';
// import { TileLayer, Tile3DLayer } from '@deck.gl/geo-layers';
// import { PolygonLayer } from '@deck.gl/layers';

// import { load, registerLoaders } from '@loaders.gl/core';
// import { ImageLoader } from '@loaders.gl/images';
// import { DracoWorkerLoader } from '@loaders.gl/draco';

// registerLoaders([DracoWorkerLoader]);

// import mapboxgl from 'mapbox-gl';

@Component({
	selector: 'app-map-viewer',
	template: '<div id="mapContainer"><div #renderTarget style="height: 100%; width: 100%;"></div><div #renderCloud style="height: 100%; width: 100%;"></div><canvas #renderCanvas></canvas></div>',
	styleUrls: ['./viewer.component.scss']
})
export class MapViewerComponent implements OnInit, AfterViewInit {

	@ViewChild("renderTarget")
	public renderTarget: any;

	@ViewChild("renderCanvas")
	public renderCanvas: ElementRef;

	@ViewChild("renderCloud")
	public renderCloud: ElementRef;

	@Output('ready') ready: EventEmitter<any> = new EventEmitter<any>();

	/* Libraries */
	public Potree: any;
	public THREE: any;
	public proj4: any;

	/* Transforms */
	public toMap: any;
	public toScene: any;

	/* Viewer */
	private _potreeInstance: any;
	private _deckInstance: any;
	private _mapboxInstance: any;

	/* Controller */
	private _deckViewController: any;

	/* Map data */
	private _layers: Array<any> = [];
	private _hiddenLayers: Array<any> = [];

	/* viewer state */
	private _loaded: boolean;
	private _timeStamp = 0;

	/* Defaults */
	private defaultViewState: InitialViewState = {
		latitude: 35,
		longitude: -80,
		bearing: 0,
		pitch: 0,
		zoom: 4,
		maxZoom: 24,
		minZoom: 3
	};

	constructor() {

	}	// End-of constructor

	ngOnInit() {

	}	// End-of ngOnInit

	ngAfterViewInit() {
		// if (!this._loaded) { this.setupViewer(); }
	}	// End-of ngAfterViewInit

// 	setupViewer(): void {
// 		// Stop repeated loading
// 		if (this._loaded) { return; } else { this._loaded = true; }

// 		this._mapboxInstance = this.setupMapBox('mapbox://styles/mapbox/dark-v9');

// 		// DECK setup
// 		this._deckViewController = new MapView({ controller: true });
// 		this._deckInstance = new Deck({
// 			canvas: this.renderCanvas.nativeElement,
// 			// width: '100vw',
// 			// height: '100vh',
// 			useDevicePixels: 0.75,
// 			initialViewState: this.defaultViewState,
// 			onViewStateChange: ({ viewState, interactionState }) => { this.onViewStateChange(viewState, interactionState); },
// 			onLoad: () => { this.onLoad(); },
// 			// _onMetrics: (stats) => {
// 			// 	console.log("FPS: ", stats.fps);
// 			// },
// 			views: [this._deckViewController],
// 			layers: this._layers
// 		});
// 	}	// End-of setupViewer

// 	onLoad(): void {
// 		this.ready.emit(this);
// 	}	// End-of onLoad

// 	onViewStateChange(viewState, interactionState): void {
// 		if (this._mapboxInstance) {
// 			this._mapboxInstance.jumpTo({
// 				center: [viewState.longitude, viewState.latitude],
// 				zoom: viewState.zoom,
// 				bearing: viewState.bearing,
// 				pitch: viewState.pitch,
// 			});
// 		}

// 		this._deckInstance.setProps({viewState});
// 	}	// End-of onViewStateChange

// 	setupLayers(layers: Array<any>): Promise<any> {
// console.log("SETING UP THE LAYERS: ", layers);
// 		let promises = [];

// 		layers.forEach(layer => {
// 			switch (layer.type) {
// 				case "pointcloud":

// 					break;
// 				case "model":
// 					// let cesiumLayer = this.addCesiumLayer({
// 					// 	url: layer.url
// 					// });

// 					break;
// 				case "orthophoto":
// 					// Loads ortho Layer
// 					let orthoLayer = this.addOrthoLayer(layer);

// 					// Fly to ortho
// 					this.zoomToBoundary(layer.boundries, layer.minZoom);
// 					break;
// 				case "dem":
// 					// Loads dem Layer
// 					let demLayer = this.addOrthoLayer(layer);
// 					break;
// 				default:
// 					console.warn("Unrecognized layer type: ", layer);
// 					break;
// 			}
// 		});

// 		return Promise.all(promises);

// 	}	// End-of setupLayers

// 	addTileLayer(tile: any): void {

// 		console.log("SETUP: ", tile);

// 		let geoTiff = tile.geoTiff;
// 		// Loads Ortho Layer
// 		let orthoLayer = this.addOrthoLayer(geoTiff);

// 		// // Loads Cesium Tile Layer
// 		// let tileLayer = this.addCesiumLayer({
// 		// 	// url: './assets/viewer/cesium/models/tileset.json'
// 		// 	url: tile.cesium.url
// 		// });

// 		// let geoLayer = this.addGeoJsonLayer("airports", AIR_PORTS);

// // 		this._deckViewController = new MapView({ controller: true });
// // 		this._deckInstance = new Deck({
// // 			canvas: this.renderCanvas.nativeElement,
// // 			width: '100vw',
// // 			height: '100vh',
// // 			initialViewState: tileViewState,
// // 			controller: true,
// // 			onViewStateChange: ({ viewState, interactionState }) => {
// // 				this._mapboxInstance.jumpTo({
// // 					center: [viewState.longitude, viewState.latitude],
// // 					zoom: viewState.zoom,
// // 					bearing: viewState.bearing,
// // 					pitch: viewState.pitch,
// // 				});

// // // 				console.log("DECK: ", this._deckInstance);
// // // 				console.log("ViewerState: ", viewState, interactionState);

// // // 				let vMang = this._deckInstance.viewManager;
// // // 				let vPorts = vMang._viewports[0] ;
// // // 				console.log("Mang: ", vPorts);

// // // // console.log("_deckViewController: ", this._deckViewController);
// // // 				// let a = this._deckViewController.projectPosition(viewState.longitude, viewState.latitude, viewState.altitude);
// // // 				// console.log("What is a: ", a);

// // // 				// Potree
// // // 				// let view = this._potreeInstance.scene.view;
// // // 				// let pos = view.position.clone();
// // // 				// if (this.toScene) {
// // // 				// 	let x = viewState.latitude;
// // // 				// 	let y = viewState.longitude;
// // // 				// 	console.log("POs: ", pos, this.toScene.forward([x, y]));
// // // 				// }

// // // 				// let x = vPorts.cameraPosition[0];
// // // 				// let y = vPorts.cameraPosition[0];
// // // 				// let z = vPorts.cameraPosition[0];
// // // 				// pos.x = x;
// // // 				// view.position.copy(pos);

// // // 				let camera = this._potreeInstance.scene.getActiveCamera();
// // // // 				let vpm = vPorts.projectionMatrix;
// // // // 				let projectionMatrix = new this.THREE.Matrix4();
// // // // 				projectionMatrix.set(
// // // // 					vpm[0], vpm[1], vpm[2], vpm[3],
// // // // 					vpm[4], vpm[5], vpm[6], vpm[7],
// // // // 					vpm[8], vpm[9], vpm[10], vpm[12],
// // // // 					vpm[11], vpm[13], vpm[14], vpm[15]);
// // // // console.log("PROJ: ", projectionMatrix);
// // // 				// camera.projectionMatrix = projectionMatrix;

// // // // camera.position.x += 100;
// // // // camera.translateZ( 100 );
// // // 				console.log("Camera: ", camera);
// // // 				// view.pitch = viewState.pitch;

// // // 				// this._potreeInstance.scene.view.position.set(e.pointcloud.position.x + 100, e.pointcloud.position.y + 100, e.pointcloud.position.z + 100);

// // // 				// Trigger Potree Render
// // // 				this._potreeInstance.update(this._potreeInstance.clock.getDelta(), this._timeStamp);
// // // 				this._potreeInstance.render();
// // 			},
// // 			layers: [
// // 				// geoLayer,
// // 				// orthoLayer,
// // 				// tileLayer,
// // 			],
// // 			// views: [this._deckViewController]
// // 			// views: [new FirstPersonView({controller: true})]
// // 		});


// 	}	// End-of addTileLayer

// 	zoomToBoundary(boundary, zoom = 15): void {
// 		const tileViewState = {
// 			latitude: (boundary.north + boundary.south) / 2,
// 			longitude: (boundary.east + boundary.west) / 2,
// 			zoom: zoom,
// 			bearing: 0,
// 			pitch: 0,
// 			maxZoom: this.defaultViewState.maxZoom,
// 			minZoom: this.defaultViewState.minZoom,
// 			transitionDuration: 'auto',
// 			transitionInterpolator: new FlyToInterpolator()
// 		};

// 		this._deckInstance.setProps({
// 			viewState: tileViewState
// 		});
// 	}	// End-of zoomTo

// 	zoomToLayer(layer: any): void {
// 		this._deckInstance.setProps({
// 			layers: [layer]
// 		});
// 	}	// End-of zoomToLayer

// 	setupMapBox(style: string, initialView?: InitialViewState): any {
// 		initialView = initialView ? initialView : this.defaultViewState;

// 		mapboxgl.accessToken = GlobalService.getEnvironmentVariable('mapbox');
// 		let mapboxLayer = new mapboxgl.Map({
// 			id: "mapbox-layer",
// 			container: this.renderTarget.nativeElement,
// 			style: style,
// 			center: [initialView.longitude, initialView.latitude],
// 			zoom: initialView.zoom,
// 			bearing: initialView.bearing,
// 			pitch: initialView.pitch,
// 			interactive: false
// 		});
// 		return mapboxLayer;
// 	}	// End-of setupMapBox

// 	setupPotree(): any {
// 		this.THREE = window['THREE'];
// 		this.Potree = window['Potree'];
// 		this.proj4 = window['proj4'];

// 		// this._potreeInstance = new this.Potree.Viewer(this.renderCloud.nativeElement);
// 		this._potreeInstance = new this.Potree.Viewer(this.renderCloud.nativeElement, {
// 			useDefaultRenderLoop: false
// 		});
// 		this._potreeInstance.setEDLEnabled(true);
// 		this._potreeInstance.setFOV(60);
// 		this._potreeInstance.setPointBudget(1000 * 1000);
// 		this._potreeInstance.setMinNodeSize(0);
// 		this._potreeInstance.setBackground(null);
// 	}	// End-of setupPotree

// 	addPointcloudLayer(tile: any): any {
// 		let file_url = GlobalService.databaseApiUrl + tile.url;
// 		this.Potree.loadPointCloud(file_url, name ? name : "pointcloud", e => {
// 			let material = e.pointcloud.material;
// 			this._potreeInstance.scene.addPointCloud(e.pointcloud);
// 			material.pointColorType = this.Potree.PointColorType.RGB;
// 			material.size = 0.8;
// 			material.pointSizeType = this.Potree.PointSizeType.ADAPTIVE;
// 			material.shape = this.Potree.PointShape.SQUARE;

// 			this._potreeInstance.scene.view.position.set(e.pointcloud.position.x + 100, e.pointcloud.position.y + 100, e.pointcloud.position.z + 100);

// 			this._potreeInstance.scene.view.lookAt(new this.THREE.Vector3(e.pointcloud.position.x, e.pointcloud.position.y, e.pointcloud.position.z));

// 			// this._potreeInstance.fitToScreen();
// 			// this._potreeInstance.fitToScreen(0.7);

// 			let meLopps = (t) => {
// 				requestAnimationFrame(meLopps);
// 				this._timeStamp = t;
// 			};
// 			requestAnimationFrame(meLopps);

// 			let pointcloudProjection = e.pointcloud.projection;
// 			let mapProjection = this.proj4.defs("WGS84");
// console.log("pointcloudProjectiond, ", pointcloudProjection, mapProjection);
// 			this.toMap = this.proj4(pointcloudProjection, mapProjection);
// 			this.toScene = this.proj4(mapProjection, pointcloudProjection);

// 			let geometry = new this.THREE.BoxGeometry(10, 10, 10);
// 			let material2 = new this.THREE.MeshBasicMaterial({ color: 0x00ff00 });
// 			let cube = new this.THREE.Mesh(geometry, material2);
// 			let scene = this._potreeInstance.scene.scene;
// 			scene.add(cube);
// 			cube.position.set(e.pointcloud.position.x, e.pointcloud.position.y, e.pointcloud.position.z);
// 		});
// 	}	// End-of addPointcloudLayer

// 	addGeoJsonLayer(name: string, geojson: any): any {
// 		let geoLayer = new GeoJsonLayer({
// 			id: 'geojson-'+name,
// 			data: geojson,
// 			filled: true,
// 			pointRadiusMinPixels: 2,
// 			pointRadiusScale: 2000,
// 			getRadius: f => 11 - f.properties.scalerank,
// 			getFillColor: [200, 0, 80, 180],
// 			pickable: true,
// 			autoHighlight: true,
// 			onClick: info =>
// 				info.object && alert(`${info.object.properties.name} (${info.object.properties.abbrev})`)
// 		});
// 		return geoLayer;
// 	}	// End-of addGeoJsonLayer

// 	addOrthoLayer(tile: any): any {
// 		let tileServer = GlobalService.databaseApiUrl + tile.url;

// 		let mybounds = [
// 			[tile.boundries.west, tile.boundries.south],
// 			[tile.boundries.west, tile.boundries.north],
// 			[tile.boundries.east, tile.boundries.north],
// 			[tile.boundries.east, tile.boundries.south]
// 		];

// 		// let myextents = [
// 		// 	tile.boundries.west, tile.boundries.south, tile.boundries.east, tile.boundries.north
// 		// ];

// 		let myextents = [-87, 41, -86, 42]

// 		let data = [{contour: mybounds}];
// 		let polygonLayer = new PolygonLayer({
// 		    id: 'polygon-layer',
// 		    data,
// 		    pickable: true,
// 		    stroked: true,
// 		    filled: true,
// 		    lineWidthMinPixels: 1,
// 		    getPolygon: d => d.contour,
// 		    getFillColor: d => [255, 0, 255, 64],
// 		    getLineColor: [255, 0, 255],
// 		    getLineWidth: 1
// 		})
// 		this._layers.push(polygonLayer);

// 		let tile_url = GlobalService.databaseApiUrl + tile.url + "/{z}/{x}/{y}.png";
// 		let error_img = load(`./assets/images/aa_logo.png`, ImageLoader);

// 		let tileLayer = new TileLayer({
// 			id: tile.name ? tile.name : 'bitlayer',
// 			minZoom: tile.minZoom,
// 			maxZoom: tile.maxZoom,
// 			// bounds: mybounds,
// 			// extent: myextents,
// 			maxRequests: 1,
// 			// tileSize: 512,
// 			// refinementStrategy: 'best-available',
// 			// visible: false,
// 			data: [tile_url],
// 			// getTileData: ({ x, y, z }) => {
// 			// 	return new Promise((resolve: Function, reject: Function) => {
// 			// 		load(`${tileServer}/${z}/${x}/${y}.png`, ImageLoader).then(res => {
// 			// 			console.log("YA BOY: ", res);
// 			// 			resolve(res);
// 			// 		}).catch(err => {
// 			// 			console.log("HOL UP: ", err);
// 			// 			resolve(load(`./assets/images/aa_logo.png`, ImageLoader));
// 			// 		});
// 			// 	});
// 			// },
// 			renderSubLayers: props => {
// 				const { bbox: { west, south, east, north } } = props.tile;

// 				// let box = turf.polygon([[
// 				// 	mybounds[0],
// 				// 	mybounds[1],
// 				// 	mybounds[2],
// 				// 	mybounds[3],
// 				// 	mybounds[0]]]);

// 				// let temp = [[west, south], [west, north], [east, north], [east, south], [west, south]];

// 				// let ans = temp.some(c => {
// 				// 	let pt = turf.point(c);
// 				// 	return turf.booleanPointInPolygon(pt, box)
// 				// });

// 				// if (ans) {
// 				// 	return new BitmapLayer(props, {
// 				// 		data: null,
// 				// 		image: props.data,
// 				// 		bounds: [west, south, east, north]
// 				// 	});
// 				// } else {
// 				// 	return null;
// 				// }
// 				let image = props.data
// 				if (!image) { image = error_img; }

// 				return new BitmapLayer(props, {
// 					data: null,
// 					image: image,
// 					bounds: [west, south, east, north]
// 				});
// 			},
// 			onTileError: error => {
// 				// console.log("WE GOT ERROR : ", error);
// 			}
// 		});
// console.log("GEO: ", tileLayer)
// 		this._layers.push(tileLayer);
// 		this.updateLayers();
// 		return tileLayer;
// 	}	// End-of addOrthoLayer

// 	addCesiumLayer(tile: any): any {
// console.log("TILE: ", tile);
// 		let tileServer = GlobalService.databaseApiUrl + tile.url;

// 		let cesiumLayer = new Tile3DLayer({
// 			id: 'tile-3d-layer',
// 			data: tileServer,
// 			onTilesetLoad: (tileset) => {

// 				// Recenter to cover the tileset
// 				// const { cartographicCenter, zoom } = tileset;
// 			},
// 			_subLayerProps: {
// 				scenegraph: { _lighting: 'flat' }
// 			}
// 		});

// 		this._layers.push(cesiumLayer);
// 		this.updateLayers();
// 		return cesiumLayer;
// 	}	// End-of addCesiumLayer

// 	private updateLayers(): void {
// 		this._deckInstance.setProps({layers: [...this._layers]});
// 	}	// End-of updateLayers

// 	isLayerVisible(name: string): boolean {
// 		if (this._layers) {
// 			let index = this._layers.findIndex(t => t.id == name);
// 			if (index >= 0) {
// 				return this._layers[index].props.visible;
// 			}
// 		}
// 		return false;
// 	}	// End-of isLayerVisible

// 	setLayerVisible(name?: string, visible?: boolean): void {
// 		if (name) {
// 			let index = this._layers.findIndex(t => t.id == name);
// 			if (index >= 0) {
// 				let newLayerArray = this._hiddenLayers.concat(this._layers.splice(index, 1));
// 				this._hiddenLayers = newLayerArray;
// 				this.updateLayers();
// 			} else {
// 				let hdly_index  = this._hiddenLayers.findIndex(t => t.id == name);
// 				if (hdly_index >= 0) {
// 					let newLayerArray = this._layers.concat(this._hiddenLayers.splice(index, 1));
// 					this._layers = newLayerArray;

// 					console.log("ASDASD : ", this._layers);
// 					this.updateLayers();
// 				}
// 			}

// 		} else {
// 			// this._layers.forEach(layer => {
// 			// 	layer.visible = !layer.visible;
// 			// });
// 		}
// 	}	// End-of setLayerVisible
}	// End-of class_deckViewControllererComponent

interface InitialViewState {
	latitude: number,
	longitude: number,
	bearing: number,
	pitch: number,
	zoom: number,
	maxZoom: number,
	minZoom: number
}	// End-of InitialViewState

