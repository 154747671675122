import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export enum MapMode {
	View = "View",
	PlacingMarker = "PlacingMarker",
	PlacingPolyLine = "PlacingPolyLine",
	SelectedAnnotation = "SelectedAnnotation",
}

export type EMPTY = null;

const initialState: { mode: MapMode; selectedEntity: EMPTY | number } = {
	mode: MapMode.View,
	selectedEntity: null,
};

export const slice = createSlice({
	initialState,
	name: "mapMode",
	reducers: {
		setPlacingMarkerMode: (s) => {
			s.mode = MapMode.PlacingMarker;
			s.selectedEntity = null;
		},
		setPlacingPolyLineMode: (s) => {
			s.mode = MapMode.PlacingPolyLine;
			s.selectedEntity = null;
		},
		setSelectedAnnotationMode: (s, action: PayloadAction<number>) => {
			s.mode = MapMode.SelectedAnnotation;
			s.selectedEntity = action.payload;
		},
		setViewMode: (s) => {
			s.mode = MapMode.View;
			s.selectedEntity = null;
		},
	},
});

export const { actions, reducer } = slice;
