/* Imports */
import { Component, Input, Output, OnInit, ChangeDetectorRef, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import {
	AdminService,
	AuthenticationService,
	SessionService,
	OrganizationService,
	UtilsService,
	waitForElm,
	matcher
} from '@shared/services';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { byId } from '@shared/services/utils.service';
import { isNil, cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { Organization, OrgIdsChangesModel } from "@shared/models";
import { flagLayer } from "@shared/featureFlags";

@Component({
	selector: 'app-team-select',
	templateUrl: './team-select.component.html',
	styleUrls: ['./team-select.component.scss']
})
export class TeamSelectComponent implements OnInit, OnDestroy {

	/* ViewChild */
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild(MatTooltip) selectTooltip: MatTooltip;

	@Input('organizationId') orgId: any;
	@Output('upgradeAccount') upgradeAccount: EventEmitter<any> = new EventEmitter();

	public orgList: Array<any>;
	public user: any;
	public orgPlan: any = null;
	public orgControl: FormControl = new FormControl();
	public isMobile: boolean = false;
	public orgListSubscription: Subscription;
	public tooltipText: string = null;
	public numberOrgsChanged: number = 0;

	constructor(
		public dialog: MatDialog,
		private _adminService: AdminService,
		private _cdr: ChangeDetectorRef,
		private _utilsService: UtilsService,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService
	) {
	}

	ngOnInit() {

		this.isMobile = this._utilsService.getIsMobile();
		this.user = this._authService.user;

		this.orgListSubscription = this._orgService.getOrgListChange()
			.subscribe(({ orgList, orgListIdsChanges }) => {
				this.setup(cloneDeep(orgList), orgListIdsChanges); // Deep clone to prevent updates
			})

	}	// End-of ngOnInit

	ngOnDestroy() {
		this.orgListSubscription?.unsubscribe();
	}

	setup(orgList = [], orgListIdsChanges = {}) {
		this.getOrgList(orgList)
			.then(rtnList => {
				const checkedList = rtnList.map(org => org.name ? org : Object.assign(org, {name: `Org ID: ${org.id}`}));
				return this.markChangedOrgs(checkedList, orgListIdsChanges)
			})
			.then(this.sortOrgList.bind(this))
			.then(this.setActiveOrg.bind(this))
			.then(rtnList => {
				this.setTooltipText( rtnList, orgListIdsChanges );
			})
			.catch(console.error); // Should we give the user some indication as to what went wrong and what they should do next?
	}

	async getOrgList(orgList): Promise<Array<Organization>> {
		return orgList.length ?
			orgList :
			this._orgService.getList();
	}

	markChangedOrgs = (orgList, orgListIdsChanges) => {
		return orgList.map(org => {
			if (orgListIdsChanges.addedOrgIds?.includes( org.id )) {
				Object.assign(org, {status: "new"});
			}
			return org;
		});
	}

	sortOrgList(orgList) {
		this.orgList = orgList.sort((orgA, orgB) => {
			return orgA.name?.localeCompare(orgB.name, { sensitivity: 'base' });
		});
		return this.orgList;
	}

	setActiveOrg(orgList) {
		const sessionOrgId = SessionService.get("active_organization_id");
		const activeOrg =
			orgList.find(byId(sessionOrgId ?? this.user.default_organization_id)) || orgList[0];
		this.orgControl = new FormControl({value: activeOrg, disabled: orgList.length <= 1});
		this.orgId = activeOrg.id;
		this._orgService.setActiveOrg(activeOrg);
		this.orgList = orgList;
		return orgList;
	}

	setTooltipText(orgList, orgListIdsChanges) {
		const {addedOrgIds, removedOrgIds} = orgListIdsChanges;

		const numChanged = addedOrgIds?.length + removedOrgIds?.length;
		this.numberOrgsChanged = Math.abs(numChanged);

		this.tooltipText = matcher(
			{
				[(addedOrgIds?.length > 0) as any]: "You've been added to a Team! Click here to switch to it.",
				[(removedOrgIds?.length > 0) as any]: "You've been removed from a team, contact the team admin if you believe this is a mistake. Click to dismiss.",
				[(addedOrgIds?.length > 0 && removedOrgIds?.length > 0) as any]: "Your Teams have been updated, click here to see what's new.",
				[(orgList?.length === addedOrgIds?.length) as any]: "Click here to switch between your Teams.",
				[(orgList?.length === 1 && addedOrgIds?.length === 1) as any]: "When invited to a new Team, you'll be able to switch to it here. Click to dismiss.",
			}
		)

		if (numChanged) {
			waitForElm("#tooltip").then(() => {
				this.selectTooltip.show();
			})
		}
	}

	displayNumNotifications(): string {
		return this.numberOrgsChanged > 9 ? '9+' : '' + this.numberOrgsChanged;
	}

	openUpgrade(value): void {
		this.upgradeAccount.emit(value)
	}

	onSelectChange(event): void {
		this._orgService.setActiveOrg(event.value);
		SessionService.set("active_organization_id", event.value.id);
	}

	hasCredits = (org) =>
		!isNil(org.processing_credits) && !isNil(org.subscription_credits);

	isShared = (org) =>
		!this.hasCredits(org) && org.isFromProjectShare;

	clearNotifications() {
		this.tooltipText = null;
		this.numberOrgsChanged = 0;
		this._cdr.detectChanges();
	}

}	// End-of class TestComponent


