import { createSlice } from "@reduxjs/toolkit";

import { actions as mapActions } from "./mode";

export enum SidebarMode {
	Layers = "Layers",
	Annotations = "Annotations",
	SelectedAnnotation = "SelectedAnnotation",
	NewAnnotation = "NewAnnotation",
}

const initialState = {
	mode: SidebarMode.Layers,
};

export const slice = createSlice({
	extraReducers: {
		[mapActions.setSelectedAnnotationMode.type]: (s) => {
			s.mode = SidebarMode.SelectedAnnotation;
		},
		[mapActions.setPlacingMarkerMode.type]: (s) => {
			s.mode = SidebarMode.NewAnnotation;
		},
		[mapActions.setPlacingPolyLineMode.type]: (s) => {
			s.mode = SidebarMode.NewAnnotation;
		},
	},
	initialState,
	name: "mapSidebar",
	reducers: {
		setAnnotationsMode: (s) => {
			s.mode = SidebarMode.Annotations;
		},
		setLayersMode: (s) => {
			s.mode = SidebarMode.Layers;
		},
	},
});

export const { actions, reducer } = slice;
