<div class="render_container">
	<ng-container [ngSwitch]="view">
		<ng-container *ngSwitchCase="'3D'">
			<app-point-viewer
				(ready)="viewer3DReady($event)"
				[viewerParent]="this"
				[userId]="userId"
				[measurements]="pointMeasurements"
				[project]="project"
				[model]="model"
			>
			</app-point-viewer>
		</ng-container>
		<ng-container *ngSwitchCase="'2D'">
			<app-ortho-viewer
				(ready)="viewer2DReady($event)"
				[userId]="userId"
				[project]="project"
				[model]="model"
				[measurements]="orthoMeasurements"
			>
			</app-ortho-viewer>
		</ng-container>
		<ng-container *ngSwitchCase="'Map'">
			<app-map-viewer
				(ready)="viewerMapReady($event)">
			</app-map-viewer>
		</ng-container>

		<ng-container *ngSwitchCase="'NoWebGL'">
			<div *ngIf="!browser" class="webgl_fail">
				<h1>Your browser cannot support WebGL 2</h1>
				<div>Please make sure that your browser is up to date and that you have the latest drivers for your graphics card.</div>
				<br/>
				<div>Mapware works best on <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>.</div>
				<br/>
				<div>Visit: <a href="https://get.webgl.org/webgl2/" target="_blank">WebGL</a> for more information.</div>
			</div>
			<div *ngIf="browser" class="webgl_fail">
				<h1>Your browser needs to enable WebGL 2</h1>

				<div>Please visit <a href="https://resources.mapware.com/knowledge/webgl-error-and-troubleshooting#{{browser}}" target="_blank">our support page</a> for instructions to enable WebGL2 on your device.</div>
				<br/>
				<div>Visit: <a href="https://get.webgl.org/webgl2/" target="_blank">WebGL</a> for more information.</div>
			</div>
		</ng-container>

		<ng-container *ngSwitchDefault>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="view">
		<ng-container *ngTemplateOutlet="t_toolbar"></ng-container>
		<ng-container *ngTemplateOutlet="t_measurement"></ng-container>
	</ng-container>

<!-- 	<mat-slide-toggle #slideToggles *ngFor="let i of numberOfSlides"
		[checked]="true"
		style="float:right;">
	</mat-slide-toggle> -->
</div>

<ng-template #t_toolbar>
	<div class="toolbar_column_left">
		<!-- This code will give the viewer a back button (needed when opening in same tab rather than new one). Leaving it here in case we want that behaviour in the future -->
		<!-- <div class="toolbar_container">
			<div class="toolbar_item" (click)="navHome()">
				<mat-icon class="toolbar_item_icon">
					home
				</mat-icon>
			</div>
		</div> -->

		<div class="toolbar_container">
			<div data-testid="toolbar_zoomin" class="toolbar_item" (click)="activeViewer.onZoom(1)" matTooltip="Zoom in" matTooltipPosition="after">
				<mat-icon class="toolbar_item_icon">
					zoom_in
				</mat-icon>
			</div>
			<div data-testid="toolbar_zoomout" class="toolbar_item" (click)="activeViewer.onZoom(-1)" matTooltip="Zoom out" matTooltipPosition="after">
				<mat-icon class="toolbar_item_icon">
					zoom_out
				</mat-icon>
			</div>
			<div data-testid="toolbar_reset" class="toolbar_item" (click)="activeViewer.recenter()" matTooltip="Reset view" matTooltipPosition="after">
				<mat-icon class="toolbar_item_icon">
					center_focus_weak
				</mat-icon>
			</div>
		</div>
		<div class="toolbar_container" *ngIf="!isPublic">
			<div data-testid="toolbar_measure"
				matTooltip="Create New Measurement"
				matTooltipPosition="after"
				class="toolbar_item"
				[ngClass]="makeToolbarClass(isMeasureState( activeViewer ))"
				(click)="activeViewer.startMeasure(project)">

				<ng-container *featureEnabled="'!new-annotations'">
					<mat-icon class="toolbar_item_icon">
						straighten
					</mat-icon>
				</ng-container>
				<ng-container *featureEnabled="'new-annotations'">
					<img class="toolbar_item_icon" src="assets/icons/link.svg">
				</ng-container>
			</div>

			<ng-container *featureEnabled="'new-annotations'">
				<div data-testid="toolbar_measure"
					class="toolbar_item"
					matTooltip="Create New Marker"
					matTooltipPosition="after"
					[ngClass]="makeToolbarClass(isMarkerState( activeViewer ))"
					(click)="activeViewer.startMeasure(project, true)">
					<img class="toolbar_item_icon" src="assets/icons/tooltip-edit.svg">
				</div>
			</ng-container>
		</div>

		<div class="toolbar_container">
			<div data-testid="toolbar_settings" class="toolbar_item" matTooltip="Settings" matTooltipPosition="after" [matMenuTriggerFor]="settingsMenu">
				<mat-icon class="toolbar_item_icon">settings</mat-icon>
			</div>
		</div>
		<mat-menu #settingsMenu>
			<ng-template matMenuContent>
				<mat-list class="popup_options">

				<!-- 	<div class="popup_category_title">Map Background</div>
					<div class="popup_category_container">
						<mat-radio-group aria-label="Select an option"
							[(ngModel)]="backgroundColorSelection"
							[ngModelOptions]="{standalone: true}"
							aria-div="defcust">
							<mat-radio-button
								style="width:100%; display: flex"
								value="dark"
								(click)="activeViewer.setBackground(0,0,0);">
								Black
							</mat-radio-button>
							<mat-radio-button style="width:100%;"
								value="light"
								(click)="activeViewer.setBackground(255,255,255);">
								White
							</mat-radio-button>
						</mat-radio-group>
					</div> -->

					<div class="popup_category_title">Units</div>
					<div class="popup_category_container">
						<mat-radio-group
							aria-label="Select an option"
							[(ngModel)]="displayUnits"
							[ngModelOptions]="{standalone: true}"
							aria-div="defcust">

							<mat-radio-button
								style="width:100%; display: flex;"
								value="m"
								(click)="$event.stopPropagation();"
							>
								Meters
							</mat-radio-button>

							<mat-radio-button
								style="width:100%; display: flex;"
								value="ft"
								(click)="$event.stopPropagation();"
							>
								Feet
							</mat-radio-button>

							<mat-radio-button
								style="width:100%; display: flex;"
								value="in"
								(click)="$event.stopPropagation();"
							>
								Inches
							</mat-radio-button>
						</mat-radio-group>
					</div>

					<ng-container *ngIf="view == '3D'">
						<div class="popup_category_title">Controls</div>
						<div class="popup_category_container">
							<mat-radio-group aria-label="Select an option"
								[(ngModel)]="cameraSelection"
								[ngModelOptions]="{standalone: true}"
								aria-div="defcust">
								<mat-radio-button
									style="width:100%;"
									value="earth"
									(click)="activeViewer.setCameraControls('earth'); $event.stopPropagation();">
									Earth
								</mat-radio-button>

								<mat-radio-button
									style="width:100%;"
									value="orbit"
									(click)="activeViewer.setCameraControls('orbit'); $event.stopPropagation();">
									Orbit
								</mat-radio-button>

								<mat-radio-button
									style="width:100%;"
									value="fp"
									(click)="activeViewer.setCameraControls('fp'); $event.stopPropagation();">
									First Person
								</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="popup_category_container toolbar_toggle">
							Show Camera Pivot
							<mat-slide-toggle
								[checked]="activeViewer.showCameraPivot"
								(click)="$event.stopPropagation();"
								(change)="activeViewer.toggleCameraPivot($event)">
							</mat-slide-toggle>
						</div>
					</ng-container>
					<div *ngIf="isDeveloper" class="developer-controls">
						<h3 class="popup_category_title">
							Developer Controls
						</h3>
						<ng-container *ngIf="view == '2D'">
							<button
								mat-flat-button color="primary"
								*ngIf="geotiff_tile"
								(click)="activeViewer.recenter('orthomosaic')"
							>
								Center View on Othophoto
							</button>

							<button
								mat-flat-button color="primary"
								*ngIf="dsm_tile"
								(click)="activeViewer.recenter('dsm')"
							>
								Center View on DSM
							</button>
						</ng-container>

						<ng-container *ngIf="view == '3D' && activeViewer">

							<div class="toolbar_toggle">
								Show Tile Colors
								<mat-slide-toggle
									[checked]="activeViewer.showingDebugTiles"
									(click)="$event.stopPropagation();"
									(change)="activeViewer.toggleTileColors($event)"
									style="position: absolute; right: 0;">
								</mat-slide-toggle>
							</div>


							<div class="popup_category_title">FOV</div>
							<input
								type="range"
								#fovRangeRef
								(click)="$event.stopPropagation();"
								(input)="activeViewer.setFov(fovRangeRef.value)"
								min="30"
								max="120"
								value="60"
							/>
						</ng-container>
					</div>
				</mat-list>
			</ng-template>
		</mat-menu>

		<div class="toolbar_container toolbar_support" 
			matTooltip="Help / Feedback" matTooltipPosition="after">
			<div data-testid="toolbar_support" class="toolbar_item" matTooltip="Help / Feedback" matTooltipPosition="after" [matMenuTriggerFor]="supportMenu">
				<mat-icon class="toolbar_item_icon">contact_support</mat-icon>
			</div>
		</div>

		<mat-menu #supportMenu class="support-menu">
			<ng-template matMenuContent>
				<mat-list>
					<mat-list-item *ngIf="supportGuide" (click)="openFAQ()">
						<mat-icon style="margin-right: 16px;">help</mat-icon>
						<span class="H6Black700Regular">
							Mapware Knowledge Base
						</span>
					</mat-list-item>
					<mat-list-item (click)="sendFeedback()">
						<mat-icon style="margin-right: 16px;">email</mat-icon>
						<span class="H6Black700Regular">
							Send bug or feedback: <b>{{supportLink}}</b>
						</span>
					</mat-list-item>
				</mat-list>
			</ng-template>
		</mat-menu>
	</div>

	<div class="toolbar_column_right"
		[ngClass]="{'toolbar_column_expanded': !toolbarCollapsed}">
		<div [class]="toolbarCollapsed ? 'tch_closed': 'tch_open'" (click)="toolbarCollapsed = !toolbarCollapsed">
			<div class="header_text">
				{{ project?.name }}
			</div>

			<mat-icon class="toolbar_item_icon"
				style="margin: auto 0;"
				matTooltip="{{ toolbarCollapsed ? 'Expand' : 'Collapse' }}"
				matTooltipPosition="after">
				{{ toolbarCollapsed ? 'unfold_more' : 'unfold_less' }}
			</mat-icon>
		</div>

		<div class="toolbar_container_options"
			[ngClass]="{'toolbar_expanded': !toolbarCollapsed}">

			<div class="toolbar_container_interior">
				<ng-container *ngTemplateOutlet="t_mainView"></ng-container>
			</div>
			<ng-container *featureEnabled="'!disable-gcps'">
				<div class="toolbar_container_popout" [ngClass]="interiorCollapsed ? 'interior_collapsed' : 'interior_expanded'">
					<ng-container *ngTemplateOutlet="t_GCPView"></ng-container>
				</div>
			</ng-container>
			<div *ngIf="!interiorCollapsed" class="toolbar_container_overlay" (click)="!interiorCollapsed && interiorCollapsed = true;"></div>
		</div>
	</div>
</ng-template>

<ng-template #t_measurement>
	<div data-testid="measurement-dialog" class="toolbar_measurement" *ngIf="activeViewer?.measureInfo">
		<div class="toolbar_measurement_container_header">
			<input type="text"
				class="toolbar_measurement_header_text"
				maxLength="24"
				[placeholder]="isMarkerState(activeViewer) ? 'New Marker' : 'New Measurement'"
				[value]="activeViewer.measureInfo.name"
				(input)="activeViewer.updateMeasureName($event)">

			<button mat-button
				class="toolbar_measurement_delete"
				(click)="activeViewer.measureRemove();"
				[matTooltip]="isMarkerState(activeViewer) ? 'Remove marker' : 'Remove measurement'"
				matTooltipPosition="after">
				<mat-icon>delete</mat-icon>
			</button>

			<button mat-button
				class="toolbar_measurement_close"
				(click)="activeViewer.stopMeasure()"
				matTooltip="Close"
				matTooltipPosition="after">
				<mat-icon>close</mat-icon>
			</button>
		</div>

		<hr class="toolbar_br" style="margin: 0 0 12px;">

		<div style="padding: 0px 24px;">
			<div *ngIf="activeViewer.measureInfo.coordinate">
				<div class="toolbar_container_sub_title">
					Coordinate
				</div>
				<span class="coordinate_x" [innerHTML]="activeViewer.measureInfo.coordinate.dms.x"></span>

				<span [innerHTML]="activeViewer.measureInfo.coordinate.dms.y"></span>
			</div>

			<div *ngIf="activeViewer.measureInfo.lastCoordinate">
				<div class="toolbar_container_sub_title">
					Last Coordinate
				</div>

				<span [innerHTML]="activeViewer.measureInfo.lastCoordinate.dms.x"></span>

				<span [innerHTML]="activeViewer.measureInfo.lastCoordinate.dms.y"></span>
			</div>

			<div *ngIf="activeViewer.measureInfo.distance">
				<div class="toolbar_container_sub_title">
					Distance
				</div>
				{{convertUnits(activeViewer.measureInfo.distance, 'length', displayUnits) | number:'1.1-4'}} {{displayUnits}}
			</div>

			<div *ngIf="activeViewer.measureInfo.area">
				<div class="toolbar_container_sub_title">
					Area
				</div>
				{{convertUnits(activeViewer.measureInfo.area, 'area', displayUnits) | number:'1.1-4'}} {{displayUnits}}<sup>2</sup>
			</div>

			<div class="inline-toolbar" *ngIf="!isMarkerState( activeViewer )">
				<div class="toolbar_container" matTooltip="Add to Measure" matTooltipPosition="after">
					<div data-testid="toolbar_measure"
						[ngClass]="{ 'toolbar_item': true, 'toolbar_item_active': isAddingState(activeViewer) }"
						(click)="activeViewer.startAddingToMeasure()">

						<mat-icon class="toolbar_item_icon">
							add_circle_outline
						</mat-icon>
					</div>
				</div>

				<div class="toolbar_container" matTooltip="Edit Measure" matTooltipPosition="after">
					<div data-testid="toolbar_measure"
						[ngClass]="{ 'toolbar_item': true, 'toolbar_item_active': isEditingState(activeViewer)  }"
						(click)="activeViewer.startEditingMeasure()">

						<mat-icon class="toolbar_item_icon">
							edit
						</mat-icon>
					</div>
				</div>
			</div>

			<ng-container *featureEnabled="'new-annotations'">
				<div class="toolbar_container_sub_title">
					Details
				</div>
				<textarea class="marker-textarea"
						maxLength="256"
						placeholder="{{isMarkerState( activeViewer ) ? 'Marker' : 'Measurement'}} details"
						[value]="activeViewer.measureInfo.details ? activeViewer.measureInfo.details : ''"
						(input)="activeViewer.updateMeasureDetails($event)"></textarea>

				<button
					mat-stroked-button
					class="marker-save-button"
					[disabled]="!activeViewer.annotationHasChanges"
					(click)="activeViewer.updateAnnotation()">
					Update {{isMarkerState( activeViewer ) ? 'Marker' : 'Measurement'}}
				</button>
			</ng-container>
		</div>

		<ng-container *ngIf="!isMarkerState( activeViewer )">

			<!--
				<div class="toolbar_measurement_hint_sub_text">
					Points: {{activeViewer?.measurementPoints}}
				</div>
			-->
			<div class="toolbar_measurement_hint_sub_text">
				<b>Right-click</b> a point to remove it.
				<br/>
				Press <b>esc</b> to exit measurement mode.
			</div>
		</ng-container>

	</div>
</ng-template>


<ng-template #t_mainView>
	<div class="section mode">
		<div class="toolbar_container_sub_title"
			style="padding: 0px 0px 16px 0px;">
			MODE
		</div>
		<button
			color="primary"
			[disabled]="loading || !has2DLayers"
			[class]="view == '2D' ? 'viewer_button_active button-left': 'viewer_button_deactivated button-left'"
			(click)="view == '2D' ? activeViewer.recenter() : toggleView('2D')">
			<span class="toolbar_item_text"> Map </span>
		</button>
		<button
			color="primary"
			[disabled]="loading || !has3DLayers"
			[class]="view == '3D' ? 'viewer_button_active button-right': 'viewer_button_deactivated button-right'"
			(click)="view == '3D' ? activeViewer.recenter() :  toggleView('3D')">
			<span class="toolbar_item_text"> 3D </span>
		</button>
	</div>

	<div *ngIf="activeViewer" class="section new_section">
		<ng-container *featureEnabled="'viewer-layers'">
			<h1 class="toolbar_toggle_container">

				<div class="toolbar_container_sub_title">
					MAP DATA ({{activeViewer.layerGroups?.length}})

					<button class="inline-icon-button" *featureEnabled="'temp-map-add-layer'">
						<mat-icon aria-hidden="false" (click)="openAddLayerOptions()">add</mat-icon>
					</button>
				</div>

				<portal-hook *featureEnabled="'temp-map-mode-state'" selector="map-mode-demo"></portal-hook>
				<div class="layer-list">
					<portal-hook *featureEnabled="'temp-map-react-layer-controls'" selector="map-sidebar"></portal-hook>

					<ng-container *featureEnabled="'!temp-map-react-layer-controls'">
						<ng-container *ngFor="let layerGroup of activeViewer.layerGroups;">
							<div class="layer-row-container"
								[ngClass]="{'layer-container-collapsed': !layerGroup.expanded}">
								<div class="model-header layer-row"
									(click)="layerGroup.expanded = !layerGroup.expanded"
									[ngClass]="{'last-row': !layerGroup.expanded}">

									<div class="layer-icon">
										<mat-icon
											style="color: {{layerGroup.color}}">
											layers
										</mat-icon>
									</div>
									<div class="layer-name">
										<div class="layer-title">
											{{ layerGroup.name }}
										</div>
									</div>

									<div class="layer-toggle layer-group-expand">
										<mat-icon>
											keyboard_arrow_up
										</mat-icon>
									</div>
								</div>

								<ng-container *ngFor="let layer of layerGroup.layers; last as isLast">
									<ng-container *ngIf="activeViewer.rendersLayerType(layer.type)">
										<div class="child-content layer-row"
											(click)="activeViewer.toggleLayer(layer.id)"
											[ngClass]="{'child-visible': layer.visible}">
											<div class="layer-toggle layer-visibility">
												<mat-icon [ngClass]="{'bright': layer.visible}"
													data-testid="layer_visibility_toggle">
													{{ layer.visible ? 'visibility' : 'visibility_off' }}
												</mat-icon>
											</div>

											<div class="layer-name">
												<div class="layer-title" *ngIf="layer.type === 'dsm'; then upperCaseType else titleCaseType"></div>
												<ng-template #titleCaseType>{{ layer.type | titlecase }}</ng-template>
												<ng-template #upperCaseType>{{ layer.type | uppercase }}</ng-template>
											</div>
										</div>
								</ng-container>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
				</div>

				<!-- TODO @remove temp-team-measurements -->
				<ng-container *featureEnabled="'!temp-team-measurements'">
					<div class="toolbar_toggle">
						Measurements
						<mat-slide-toggle
							[checked]="activeViewer.showMeasureInfo.showMeasures"
							(change)="
								activeViewer.showMeasureInfo.showMeasures = !activeViewer.showMeasureInfo.showMeasures;
								activeViewer.updateMeasureVisibility();">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle_child_container" *ngIf="activeViewer.showMeasureInfo.showMeasures && view === '3D'">
						<div class="toolbar_toggle_child">
							Length
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showDistances"
								(change)="
									activeViewer.showMeasureInfo.showDistances = !activeViewer.showMeasureInfo.showDistances;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child">
							Area
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showArea"
								(change)="
									activeViewer.showMeasureInfo.showArea = !activeViewer.showMeasureInfo.showArea;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>
					</div>
				</ng-container>
				<ng-container *featureEnabled="'temp-team-measurements'">
					<div class="toolbar_toggle">
						Measurements ({{activeViewer._measurements.length}})
						<mat-slide-toggle
							[checked]="activeViewer.showMeasureInfo.showMeasures"
							(change)="
								activeViewer.showMeasureInfo.showMeasures = !activeViewer.showMeasureInfo.showMeasures;
								activeViewer.updateMeasureVisibility();">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle_child_container" *ngIf="activeViewer.showMeasureInfo.showMeasures">
						<div class="toolbar_toggle_child">
							Your Measurements ({{activeViewer.showMeasureInfo.yourMeasurementCount}})
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showYourMeasurements"
								(change)="
									activeViewer.showMeasureInfo.showYourMeasurements = !activeViewer.showMeasureInfo.showYourMeasurements;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child">
							Team Measurements ({{activeViewer.showMeasureInfo.teamMeasurementCount}})
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showTeamMeasurements"
								(change)="
									activeViewer.showMeasureInfo.showTeamMeasurements = !activeViewer.showMeasureInfo.showTeamMeasurements;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child" *ngIf="view === '3D'">
							Length
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showDistances"
								(change)="
									activeViewer.showMeasureInfo.showDistances = !activeViewer.showMeasureInfo.showDistances;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child" *ngIf="view === '3D'">
							Area
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showArea"
								(change)="
									activeViewer.showMeasureInfo.showArea = !activeViewer.showMeasureInfo.showArea;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>
					</div>
				</ng-container>

				<div class="toolbar_toggle" *ngIf="view === '3D'">
					Background Color
					<mat-slide-toggle
						[checked]="!activeViewer.lightBackground"
						(change)="activeViewer.toggleBackground();"
						[ngClass]="activeViewer.lightBackground ? 'white-toggled' : 'black-toggled'"
						style="position: absolute; right: 0;">
					</mat-slide-toggle>
				</div>

				<div class="toolbar_toggle toolbar_dropdown" *ngIf="view === '3D'">
					<!--*ngIf="model_url">-->
					Quality
					<span matTooltipPosition="after" class="viewer-menu-info-tip phone-hidden"
						matTooltip="Increasing quality will increase network and device load. This may affect performance.">
						<mat-icon>help_outline</mat-icon>
					</span>
					<mat-select
						class="H6Black700Regular toolbar_dropdown_button"
						[(value)]="activeViewer.qualitySelection"
						(valueChange)="activeViewer.changeQualitySetting()">
						<mat-option class="sort-option" *ngFor="let option of activeViewer.qualityOptions" [value]="option">
							<span class="H7Black700Regular"> {{option.text}} </span>
						</mat-option>
					</mat-select>
				</div>

				<ng-container *featureEnabled="'temp-2d-background-layers'">
					<div class="toolbar_toggle" *ngIf="view === '2D'">
						Background Layer
						<mat-select
							class="H6Black700Regular toolbar_dropdown_button"
							[(value)]="activeViewer.basemapSelection"
							(valueChange)="activeViewer.updateBasemap()">
							<mat-option class="sort-option" *ngFor="let option of activeViewer.basemapOptions" [value]="option">
								<span class="H7Black700Regular"> {{option.title}} </span>
							</mat-option>
						</mat-select>
					</div>

					<div class="toolbar_toggle" *ngIf="view === '2D'">
						Background Opacity
						<input
							#backgroundOpacityRangeRef
							type="range"
							[(value)]="activeViewer.basemapOpacity"
							(input)="activeViewer.changeBasemapOpacity(backgroundOpacityRangeRef.value)"
							min="0"
							max="100"
						/>
					</div>
				</ng-container>

				<ng-container *featureEnabled="'temp-viewer-details'">
					<ng-container *ngIf="activeViewer.debuggingViz">
						<div class="toolbar_toggle">
							Details
							<mat-slide-toggle
								[checked]="activeViewer.debuggingViz.showControls"
								(change)="
									activeViewer.debuggingViz.showControls = !activeViewer.debuggingViz.showControls;
									activeViewer.updateDebugging();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child_container" *ngIf="activeViewer.debuggingViz.showControls">

							<div class="toolbar_toggle_child">
								<div>Image Positions <span class="lil-dot white-background"></span></div>
								<mat-slide-toggle
									[checked]="activeViewer.debuggingViz.showImagePositions"
									(change)="activeViewer.toggleImagePositionViz()">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Flight Path
								<mat-slide-toggle
									[checked]="activeViewer.debuggingViz.showFlightPath"
									(change)="activeViewer.toggleFlightPathViz()">
								</mat-slide-toggle>
							</div>

							<ng-container *featureEnabled="'dev-testing'">
								<div class="toolbar_toggle_child">
									Camera View
									<div class="text-button-row">
										<div class="text-button bottom-bar" style="border-color:red;"
											(click)="activeViewer.goToCameraView('R')">Side</div>
										<div class="text-button bottom-bar" style="border-color:green;"
											(click)="activeViewer.goToCameraView('F')">Front</div>
										<div class="text-button bottom-bar" style="border-color:blue;"
											(click)="activeViewer.goToCameraView('U')">Top</div>
									</div>
								</div>

								<ng-container *ngIf="activeViewer._utmTransformationMatrix">
									<div class="toolbar_toggle_child">
										<div>Estimated Positions <span class="lil-dot cerulean-background"></span></div>
										<mat-slide-toggle
											[checked]="activeViewer.debuggingViz.showEstimatedPositions"
											(change)="activeViewer.toggleEstimatedPositionViz()">
										</mat-slide-toggle>
									</div>

									<div class="toolbar_toggle_child">
										<div>Estimated Error <span class="lil-text">x10</span></div>
										<mat-slide-toggle
											[checked]="activeViewer.debuggingViz.showError"
											(change)="activeViewer.toggleEstimatedErrorViz()">
										</mat-slide-toggle>
									</div>

									<div *ngIf="activeViewer.debuggingViz.showError">
										<div class="error-flex-table">
											<div class="table-header">Average Error (meters)</div>
											<div class="table-cell">
												<label>Long</label> {{ activeViewer.debuggingViz.averageError.long | number}}
											</div>
											<div class="table-cell">
												<label>Lat</label> {{ activeViewer.debuggingViz.averageError.lat | number}}
											</div>
											<div class="table-cell">
												<label>Altitude</label> {{ activeViewer.debuggingViz.averageError.alt | number}}
											</div>
											<div class="table-cell">
												<label>Total</label> {{ activeViewer.debuggingViz.averageError.total | number}}
											</div>
										</div>
									</div>

									<div class="toolbar_toggle_child">
										Model Space Axes
											<span class="lil-colored-label" style="background-color:red;">X</span>
											<span class="lil-colored-label" style="background-color:green;">Y</span>
											<span class="lil-colored-label" style="background-color: blue;">Z</span>
										<mat-slide-toggle
											[checked]="activeViewer.debuggingViz.showModelSpaceAxes"
											(change)="activeViewer.toggleModelSpaceAxesViz()">
										</mat-slide-toggle>
									</div>

									<div class="toolbar_toggle_child">
										UTM Space Axes
											<span class="lil-colored-label" style="background-color:magenta;">X</span>
											<span class="lil-colored-label" style="background-color:goldenrod;">Y</span>
											<span class="lil-colored-label" style="background-color: cyan;">Z</span>
										<mat-slide-toggle
											[checked]="activeViewer.debuggingViz.showUTMSpaceAxes"
											(change)="activeViewer.toggleUTMSpaceAxesViz()">
										</mat-slide-toggle>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
				</ng-container>
			</h1>
		</ng-container>
		<!-- TODO: @remove viewer-layers -->
		<ng-container *featureEnabled="'!viewer-layers'">
			<div class="toolbar_container_sub_title">
				VIEW LAYERS
			</div>

			<ng-container [ngSwitch]="view">
				<div class="toolbar_toggle_container" *ngSwitchCase="'3D'">
					<div class="toolbar_toggle"
						*ngIf="sparse_url || dense_url">
						Point Clouds
						<mat-slide-toggle
							[(ngModel)]="show_point_clouds"
							(click)="handleTogglePointCloud(show_point_clouds, activeViewer);">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle_child_container" *ngIf="show_point_clouds">
						<div class="toolbar_toggle_child"
							*ngIf="sparse_url">
							Sparse
							<mat-slide-toggle
								[checked]="activeViewer.isVisible('sparse')"
								(change)="activeViewer.setVisible('sparse')">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child"
							*ngIf="dense_url">
							Dense
							<mat-slide-toggle
								[checked]="activeViewer.isVisible('dense')"
								(change)="activeViewer.setVisible('dense')">
							</mat-slide-toggle>
						</div>
					</div>

					<div class="toolbar_toggle"
						*ngIf="model_url">
						Mesh
						<mat-slide-toggle
							[checked]="activeViewer.isVisible('model')"
							(change)="activeViewer.setVisible('model')">
						</mat-slide-toggle>
					</div>

					<!-- TODO @remove temp-team-measurements -->
					<ng-container *featureEnabled="'!temp-team-measurements'">
						<div class="toolbar_toggle">
							Measurements
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showMeasures"
								(change)="
									activeViewer.showMeasureInfo.showMeasures = !activeViewer.showMeasureInfo.showMeasures;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child_container" *ngIf="activeViewer.showMeasureInfo.showMeasures">
							<div class="toolbar_toggle_child">
								Length
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showDistances"
									(change)="
										activeViewer.showMeasureInfo.showDistances = !activeViewer.showMeasureInfo.showDistances;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Area
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showArea"
									(change)="
										activeViewer.showMeasureInfo.showArea = !activeViewer.showMeasureInfo.showArea;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

		<!--					<div class="toolbar_toggle_child">-->
		<!--						Height-->
		<!--						<mat-slide-toggle-->
		<!--							[checked]="activeViewer.isVisible('showHeight')"-->
		<!--							(change)="activeViewer.setVisible('showHeight')"-->
		<!--							style="position: absolute; right: 0;">-->
		<!--						</mat-slide-toggle>-->
		<!--					</div>-->

		<!--					<div class="toolbar_toggle_child">-->
		<!--						Angle-->
		<!--						<mat-slide-toggle-->
		<!--							[checked]="activeViewer.isVisible('showAngles')"-->
		<!--							(change)="activeViewer.setVisible('showAngles')"-->
		<!--							style="position: absolute; right: 0;">-->
		<!--						</mat-slide-toggle>-->
		<!--					</div>-->
						</div>
					</ng-container>
					<ng-container *featureEnabled="'temp-team-measurements'">
						<div class="toolbar_toggle">
							Measurements ({{activeViewer._measurements.length}})
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showMeasures"
								(change)="
									activeViewer.showMeasureInfo.showMeasures = !activeViewer.showMeasureInfo.showMeasures;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child_container" *ngIf="activeViewer.showMeasureInfo.showMeasures">
							<div class="toolbar_toggle_child">
								Your Measurements ({{activeViewer.showMeasureInfo.yourMeasurementCount}})
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showYourMeasurements"
									(change)="
										activeViewer.showMeasureInfo.showYourMeasurements = !activeViewer.showMeasureInfo.showYourMeasurements;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Team Measurements ({{activeViewer.showMeasureInfo.teamMeasurementCount}})
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showTeamMeasurements"
									(change)="
										activeViewer.showMeasureInfo.showTeamMeasurements = !activeViewer.showMeasureInfo.showTeamMeasurements;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Length
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showDistances"
									(change)="
										activeViewer.showMeasureInfo.showDistances = !activeViewer.showMeasureInfo.showDistances;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Area
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showArea"
									(change)="
										activeViewer.showMeasureInfo.showArea = !activeViewer.showMeasureInfo.showArea;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>
						</div>
					</ng-container>

					<div class="toolbar_toggle">
						Background Color
						<mat-slide-toggle
							[checked]="!activeViewer.lightBackground"
							(change)="activeViewer.toggleBackground();"
							[ngClass]="activeViewer.lightBackground ? 'white-toggled' : 'black-toggled'"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle toolbar_dropdown"
						*ngIf="model_url">
						Quality
						<span matTooltipPosition="after" class="viewer-menu-info-tip phone-hidden"
							matTooltip="Increasing quality will increase network and device load. This may affect performance.">
							<mat-icon>help_outline</mat-icon>
						</span>
						<mat-select
							secondary
							class="H7Black700Regular toolbar_dropdown_button"
							[(value)]="activeViewer.qualitySelection"
							(valueChange)="activeViewer.changeQualitySetting()">
							<mat-option class="sort-option" *ngFor="let option of activeViewer.qualityOptions" [value]="option">
								<span class="H7Black700Regular"> {{option.text}} </span>
							</mat-option>
						</mat-select>
					</div>

					<ng-container *featureEnabled="'temp-viewer-details'">
						<ng-container *ngIf="activeViewer.debuggingViz">
							<div class="toolbar_toggle">
								Details
								<mat-slide-toggle
									[checked]="activeViewer.debuggingViz.showControls"
									(change)="
										activeViewer.debuggingViz.showControls = !activeViewer.debuggingViz.showControls;
										activeViewer.updateDebugging();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child_container" *ngIf="activeViewer.debuggingViz.showControls">

								<div class="toolbar_toggle_child">
									<div>Image Positions <span class="lil-dot white-background"></span></div>
									<mat-slide-toggle
										[checked]="activeViewer.debuggingViz.showImagePositions"
										(change)="activeViewer.toggleImagePositionViz()">
									</mat-slide-toggle>
								</div>

								<div class="toolbar_toggle_child">
									Flight Path
									<mat-slide-toggle
										[checked]="activeViewer.debuggingViz.showFlightPath"
										(change)="activeViewer.toggleFlightPathViz()">
									</mat-slide-toggle>
								</div>
							</div>
						</ng-container>
					</ng-container>

					<div id="menu_scene" class="pv-menu-list" *ngIf="isDeveloper">

						<!-- <div id="scene_export"></div> -->

						<!-- <div class="divider"><span>Objects</span></div> -->

						<div id="scene_objects"></div>

						<!-- <div class="divider"><span>Properties</span></div> -->

						<!-- <div id="scene_object_properties"></div> -->
					</div>

					<!--TODO get updated designs and re-enable point cloud features-->
					<!-- <div *ngIf="view == '3D' && (activeViewer.isVisible('dense') || activeViewer.isVisible('sparse'))" style="height: auto;">
						<div class="toolbar_container_sub_title">
							POINT CLOUD SETTINGS
						</div>

						<div class="popup_category_title">RGB</div>
						<div style="padding: 0 10%">
							<input type="range"
								#pcattRGB
								(input)="activeViewer.updateAttribute({val: pcattRGB.value, type: 'rgb'})"
								min="0"
								max="1"
								value="1"
								step="0.01"
								style="width:100%">
						</div>

						<div class="popup_category_title">Elevation</div>
						<div style="padding: 0 10%">
							<input type="range"
								#pcattElevation
								(input)="activeViewer.updateAttribute({val: pcattElevation.value, type: 'elevation'})"
								min="0"
								max="1"
								[value]="isLidar ? 1 : 0"
								step="0.01"
								style="width:100%">
								<div class="popup_category_title_sub">Max</div>
								<input type="range"
									#pcattMaxElevation
									(input)="activeViewer.updateAttribute({val: pcattMaxElevation.value, type: 'maxheight'})"
									min="-1"
									max="1"
									value="0"
									step="0.01"
									style="width:100%">
								<div class="popup_category_title_sub">Min</div>
								<input type="range"
									#pcattMinElevation
									(input)="activeViewer.updateAttribute({val: pcattMinElevation.value, type: 'minheight'})"
									min="-1"
									max="1"
									value="0"
									step="0.01"
									style="width:100%">
						</div>

						<div class="popup_category_title">Intensity</div>
						<div style="padding: 0 10%">
							<input type="range"
								#pcattIntensity
								(input)="activeViewer.updateAttribute({val: pcattIntensity.value, type: 'intensity'})"
								min="0"
								max="1"
								value="0"
								step="0.01"
								style="width:100%">
						</div>

						<div class="popup_category_title">Classification</div>
						<div style="padding: 0 10%">
							<input type="range"
								#pcattClassification
								(input)="activeViewer.updateAttribute({val: pcattClassification.value, type: 'classification'})"
								min="0"
								max="1"
								value="0"
								step="0.01"
								style="width:100%">
						</div>

						<div class="popup_category_title">Point Size</div>
						<div style="padding: 0 10%">
							<input type="range"
								#pcattSize
								(input)="activeViewer.updateAttribute({val: pcattSize.value, type: 'size'})"
								min="0.1"
								max="4"
								value="2"
								step="0.01"
								style="width:100%">
						</div>

						<div style="padding: 0 10%; margin: 10px auto;">
							<mat-select primary
								placeholder="Gradient"
								class="H6Black700Regular"
								style="width: 100%;"
								(selectionChange)="activeViewer.updateAttribute({val: $event.value, type: 'gradientrepeat'})">
								<mat-option *ngFor="let gradient of activeViewer.gradientList"
									[value]="gradient">
									<span class="H6Black700Regular">
										{{gradient}}
									</span>
								</mat-option>
							</mat-select>
						</div>

						<div style="padding: 0 10%; margin: 10px auto;">
							<mat-select primary
								placeholder="Shape"
								class="H6Black700Regular"
								style="width: 100%;"
								(selectionChange)="activeViewer.updateAttribute({val: $event.value, type: 'shape'})">
								<mat-option *ngFor="let shape of activeViewer.pointShapeList"
									[value]="shape">
									<span class="H6Black700Regular">
										{{shape}}
									</span>
								</mat-option>
							</mat-select>
						</div>
					</div> -->
				</div>

				<div class="toolbar_toggle_container" *ngSwitchCase="'2D'">
					<div class="toolbar_toggle"
						*ngIf="geotiff_tile">
						Orthomosaic
						<mat-slide-toggle
							[checked]="activeViewer.isVisible('orthomosaic')"
							(change)="activeViewer.setVisible('orthomosaic')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle"
						*ngIf="dsm_tile">
						DSM
						<mat-slide-toggle
							[checked]="activeViewer.isVisible('dsm')"
							(change)="activeViewer.setVisible('dsm')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<!-- TODO @remove temp-team-measurements -->
					<ng-container *featureEnabled="'!temp-team-measurements'">
						<div class="toolbar_toggle">
							Measurements
							<mat-slide-toggle
								[checked]="activeViewer.isVisible('measure')"
								(change)="activeViewer.setVisible('measure')"
								style="position: absolute; right: 0;">
							</mat-slide-toggle>
						</div>
					</ng-container>
					<ng-container *featureEnabled="'temp-team-measurements'">
						<div class="toolbar_toggle">
							Measurements ({{activeViewer._measurements.length}})
							<mat-slide-toggle
								[checked]="activeViewer.showMeasureInfo.showMeasures"
								(change)="
									activeViewer.showMeasureInfo.showMeasures = !activeViewer.showMeasureInfo.showMeasures;
									activeViewer.updateMeasureVisibility();">
							</mat-slide-toggle>
						</div>

						<div class="toolbar_toggle_child_container" *ngIf="activeViewer.showMeasureInfo.showMeasures">
							<div class="toolbar_toggle_child">
								Your Measurements ({{activeViewer.showMeasureInfo.yourMeasurementCount}})
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showYourMeasurements"
									(change)="
										activeViewer.showMeasureInfo.showYourMeasurements = !activeViewer.showMeasureInfo.showYourMeasurements;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child">
								Team Measurements ({{activeViewer.showMeasureInfo.teamMeasurementCount}})
								<mat-slide-toggle
									[checked]="activeViewer.showMeasureInfo.showTeamMeasurements"
									(change)="
										activeViewer.showMeasureInfo.showTeamMeasurements = !activeViewer.showMeasureInfo.showTeamMeasurements;
										activeViewer.updateMeasureVisibility();">
								</mat-slide-toggle>
							</div>
						</div>
					</ng-container>

					<ng-container *featureEnabled="'temp-2d-background-layers'">
						<div class="toolbar_toggle" *ngIf="view === '2D'">
							Background Layer
							<mat-select
								class="H6Black700Regular toolbar_dropdown_button"
								[(value)]="activeViewer.basemapSelection"
								(valueChange)="activeViewer.updateBasemap()">
								<mat-option class="sort-option" *ngFor="let option of activeViewer.basemapOptions" [value]="option">
									<span class="H7Black700Regular"> {{option.title}} </span>
								</mat-option>
							</mat-select>
						</div>

						<div class="toolbar_toggle" *ngIf="view === '2D'">
							Background Opacity
							<input
								#backgroundOpacityRangeRef
								type="range"
								[(value)]="activeViewer.basemapOpacity"
								(input)="activeViewer.changeBasemapOpacity(backgroundOpacityRangeRef.value)"
								min="0"
								max="100"
							/>
						</div>
					</ng-container>

					<ng-container *featureEnabled="'temp-viewer-details'">
						<ng-container *ngIf="activeViewer.debuggingViz">
							<div class="toolbar_toggle">
								Details
								<mat-slide-toggle
									data-testid="2d_details_toggle"
									[checked]="activeViewer.debuggingViz.showControls"
									(change)="
										activeViewer.debuggingViz.showControls = !activeViewer.debuggingViz.showControls;
										activeViewer.updateDebugging();">
								</mat-slide-toggle>
							</div>

							<div class="toolbar_toggle_child_container" *ngIf="activeViewer.debuggingViz.showControls">

								<div class="toolbar_toggle_child">
									<div>Image Positions <span class="lil-dot white-background"></span></div>
									<mat-slide-toggle
										data-testid="2d_image_positions_toggle"
										[checked]="activeViewer.debuggingViz.showImagePositions"
										(change)="activeViewer.toggleImagePositionViz()">
									</mat-slide-toggle>
								</div>

								<div class="toolbar_toggle_child">
									Flight Path
									<mat-slide-toggle
										[checked]="activeViewer.debuggingViz.showFlightPath"
										(change)="activeViewer.toggleFlightPathViz()">
									</mat-slide-toggle>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>

				<ng-container *ngSwitchCase="'Map'">
					<div class="toolbar_toggle"
						*ngIf="geotiff_tile">
						Orthomosaic
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('orthomosaic')"
							(change)="activeViewer.setLayerVisible('orthomosaic')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle"
						*ngIf="dsm_tile">
						DSM
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('dsm')"
							(change)="activeViewer.setLayerVisible('dsm')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle"
						*ngIf="sparse_url">
						Sparse Point Cloud
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('sparse')"
							(change)="activeViewer.setLayerVisible('sparse')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle"
						*ngIf="dense_url">
						Dense Point Cloud
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('dense')"
							(change)="activeViewer.setLayerVisible('dense')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle"
						*ngIf="model_url">
						Mesh
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('model')"
							(change)="activeViewer.setLayerVisible('model')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>

					<div class="toolbar_toggle">
						Measurements
						<mat-slide-toggle
							[checked]="activeViewer.isLayerVisible('measure')"
							(change)="activeViewer.setLayerVisible('measure')"
							style="position: absolute; right: 0;">
						</mat-slide-toggle>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>


	<!-- GROUND CONTROL POINTS -->
	<ng-container *featureEnabled="'!disable-gcps'">
		<div class="section view_section" style="position: relative;" *ngIf="gcpList.length" (click)="interiorCollapsed = !interiorCollapsed; $event.stopPropagation();">
			<div class="toolbar_select">
				Ground Control Points

				<mat-icon style="position: absolute; right: 0;">
					navigate_next
				</mat-icon>
			</div>
		</div>
	</ng-container>

	<!-- SCALE CONSTRAINTS -->
<!-- 			<div class="toolbar_btn" style="position: inherit;">
		Scale Constraints

		<mat-icon style="position: absolute; right: 0;">
			navigate_next
		</mat-icon>
	</div>
	<hr class="toolbar_column_right_br">
-->
	<!-- PHOTOS -->
	<!-- <div class="toolbar_btn" style="position: inherit;">
		Photos
		<span>( {{photos ? photos.length : '0'}} )</span>

		<mat-icon style="position: absolute; right: 0;">
			navigate_next
		</mat-icon>
	</div>
	<hr class="toolbar_column_right_br"> -->
<!--
		<div class="slidecontainer">
			<input type="range"
				min="100"
				max="100000"
				value="1000"
				class="slider"
				id="myRange"
				(change)="setPointBudget($event.value)">
		</div> -->



	<div class="section export_info" *ngIf="model_status">

		<button (click)="openShareLinkDialog()"
				data-testid="share_button"
				mat-stroked-button
				color="primary"
				[disabled]="isExpired || !exportsEnabled"
				class="share-link-button">
			<mat-icon class="button-icon">share</mat-icon>
			<span class="H6WhiteRegular"> Share </span>
		</button>

		<ng-container *ngIf="!isPublic">

			<!-- Disabled Export explanations -->
			<div class="export-warning" *ngIf="!hasLayers()">Please wait until the model is fully processed to request an export.</div>
			<div class="export-warning" *ngIf="!exportsEnabled">Exporting is disabled for this project.</div>

			<button (click)="openExportModal()"
					data-testid="export_button"
					mat-stroked-button color="primary"
					*featureEnabled="'!disable-exports'"
					[disabled]="!hasLayers() || !userHasPermission || isExpired || !exportsEnabled"
					class="export-button">
			<span style="display: flex; margin: auto;">
				<mat-icon class="button-icon">merge_type</mat-icon>
				<span class="H6WhiteRegular"> Export </span>
			</span>
			</button>

			<!-- TODO: @remove remove-subscription-required : do we need this message  -->
			<ng-container *featureEnabled="'!remove-subscription-required'">
				<div class="P2White" *ngIf="userHasPermission == false" >
					Your trial has ended, but your models will be available here. If you wish to export a model, you can purchase a plan in the <span class="bold">Plans & Billing</span> section of <span class="bold">My profile.</span>
				</div>
			</ng-container>
		</ng-container>
	</div>
</ng-template>


<ng-template #t_GCPView>

	<div class="back_button">
		<mat-icon class="toolbar_item_icon"
			matTooltip="Close"
			(click)="interiorCollapsed = !interiorCollapsed">
			keyboard_backspace
		</mat-icon>
		<div class="H4WhiteRegular phone-hidden">Ground Control Points</div>
		<div class="H4WhiteRegular phone-visible">GCPs</div>
	</div>

	<div class="gcp_section overlay_section">
		<div class="toolbar_toggle" *ngFor="let gcp of gcpList">
			<div> {{gcp.name}}
				<!-- <mat-slide-toggle
					style="position: absolute; right: 0;">
				</mat-slide-toggle> -->
			</div>
			<div class="details_text">
				(Lat, Long): ({{gcp.latitude.toFixed(8)}}, {{gcp.longitude.toFixed(8)}})
			</div>
		</div>
	</div>

</ng-template>
