
// export abstract class LoginProvider implements LoginProvider {
export abstract class LoginProvider {

	constructor() {}	// End-of constructor

	abstract initialize(ref): Promise<any>;
	abstract login(): Promise<any>;
	abstract signout(): Promise<any>;

	loadScript(id: string, src: string, onload: any): void {

		if (document.getElementById(id)) { return ;}

		let signInJs = document.createElement("script");
		signInJs.async = true;
		signInJs.src = src;
		signInJs.onload = onload;
		document.head.appendChild(signInJs);

	}	// End-of loadScript

}	// End-of class LoginProvider