import {Component, Inject, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
	ProjectService,
	ModelService,
	isFulfilled,
	GlobalService,
	AIModelService
} from "@shared/services";


@Component({
    selector: "app-ai-analysis",
    templateUrl: "./analysis.component.html",
    styleUrls: ["./analysis.component.scss"]
})

export class AnalysisComponent implements OnInit {

	public aiWizardSteps = {
		aiModelSelected: false,
		layerSelected: false,
		continueToConfirmation: false,
		selectionsConfirmed: false
	}

	public filteredModels;
	public inferencingLayers;
	public selectedAiModel;
	public selectedLayer;
	public isLoading = true;
	public hasError = false;
	public project = null;

	public sortOptions = [
		{ text: "Name", value: "model_name" },
		{ text: "Date", value: "created_at" },
	]

	public sort: "model_name" | "created_at" = "created_at";
	public sortDirectionUp: boolean = false; // "up" starts the 'smallest' value at the beginning of the list
	// e.g. ["hall", "ceiling", "floor"] with a "name" sort, "up" sorts to -> ["ceiling", "floor", "hall"]

	public rootURL = GlobalService.databaseApiUrl;

    // vvv Temporary dummy data set vvv
    public aiTestModels = [
		{
			id: 1234,
			name: "Car Detector AI",
			createdBy: "Nic Lupfer",
			date: "Jan 2, 2024",
			type: "Object Detection",
			org: "Mapware",
			projects: "Parking Lot",
			layers: "Orthomosaic",
			labelSets: "Cars",
			thumbnail: "https://dab-api.staging.mapware.com/thumbnails/74d22e75-95d3-4a2d-adeb-096462121112.png",
			selected: false
		},
		{
			id: 1312,
			name: "Dinosaur Detector AI",
			createdBy: "Jessica Organ",
			date: "Feb 13, 2024",
			type: "Object Detection",
			org: "Mapware",
			projects: "James Neighborhood",
			layers: "Object Detection",
			labelSets: "Dinosaurs",
			thumbnail: "https://dab-api.staging.mapware.com/thumbnails/85fb3c84-c011-4800-b8b8-cb2b10d86c5a.png",
			selected: false
		},
		{
			id: 2134,
			name: "Bird Habitat",
			createdBy: "Carl Byrd",
			date: "Feb 1, 2024",
			type: "Segmentation",
			org: "Mapware",
			projects: "Carl ODM Test",
			layers: "Orthomosaic",
			labelSets: ["Birds", "Trees"],
			thumbnail: "https://dab-api.staging.mapware.com/thumbnails/f6a5c917-f1a3-4f48-8466-92de00a422c8.png",
			selected: false
		}
	];

    constructor(
        private _route: ActivatedRoute,
		private _aiService: AIModelService,
		private _project: ProjectService,
		private _modelService: ModelService,
		public _dialogRef: MatDialogRef<AnalysisComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
        ) {

		if (data.project_id) {
			this.getProjectData(data.project_id);
		}

    }

    ngOnInit(): void {
		this.setup();
	 }

	 setup() {
		this._route.queryParams.subscribe( params => {
			if (params.project_id)
				this.getProjectData( params.project_id )
		} );
	}

	async getProjectData( project_id ) {
		this.project = await this._project.getByIdV2(project_id);

		let models;
		const shallowModels = await this._modelService.getListV2({ project_id: this.project.id }) ?? [];

		this.filteredModels = shallowModels.filter(x => (x.active && !x.trash));

		models = await this.getDeepModels(this.filteredModels);

		this.filteredModels = models.filter(x => ((typeof x.id === "number") && x.active && !x.trash));
		this.filteredModels
			.forEach((model, ind) => {
				model["index"] = ind;
				model.thumbnail = this.findModelThumbnail(model);
			});

		this.filterAcceptableLayers(this.filteredModels);
	}

	filterAcceptableLayers(filteredModels) {
		this.inferencingLayers = filteredModels.reduce((acc, model) => {
			model.geotiffs.forEach((geotiff) => {
				if (geotiff.type === 'orthomosaic') {
					geotiff.layer_thumbnail = model.thumbnail;
					geotiff.model_name = model.name;
					acc.push(geotiff);
				}
			})
			return acc;
		}, [])
			.sort((a, b) => {
				let aVal = a[this.sort], bVal = b[this.sort];
				if (this.sort === "created_at") {
					return this.sortDirectionUp ?
						(new Date(aVal).getTime() - new Date(bVal).getTime()) :
						(new Date(bVal).getTime() - new Date(aVal).getTime());
				} else if (typeof aVal === "number") {
					return this.sortDirectionUp ?
						(aVal - bVal) :
						(bVal - aVal);
				}

				return this.sortDirectionUp ?
					(aVal.localeCompare(bVal)) :
					(bVal.localeCompare(aVal));
			});

		this.isLoading = false;
	}

	findModelThumbnail = (model) => {
		const thumbnailString = model?.geotiffs?.find(x => x.type === "orthomosaic" && x.thumbnail)?.thumbnail;

		return thumbnailString ? this.rootURL + thumbnailString : null;
	}

	async getDeepModels(models): Promise<any> {
		return Promise.allSettled(models.map(model => this._modelService.getModelV2(model.id)))
			.then(result => {
				return result.reduce((acc, res) => {
					if (isFulfilled(res)) {
						acc.push(res.value) }
					else console.error(res?.reason);
					return acc;
				}, []);
			})
	}

	selectAiModel(aiModel) {
		this.updateStepIndicator(aiModel);
		this.selectedAiModel = aiModel;

		this.aiTestModels.forEach((ai) => ai.selected = false)
		aiModel.selected = true;
	}

	selectLayer(layer) {
		this.updateStepIndicator(layer);
		this.selectedLayer = layer;

		this.inferencingLayers.forEach((layer) => layer.selected = false)
		layer.selected = true;
	}

	updateStepIndicator(selection) {
		if (selection.type === 'Object Detection' || selection.type === 'Segmentation') {
			this.aiWizardSteps.aiModelSelected = true;
		} else if (selection.type !== 'Object Detection' || selection.type !== 'Segmentation' && this.aiWizardSteps.aiModelSelected) {
			this.aiWizardSteps.layerSelected = true;
		}
	}

	goToConfirmationPage() {
		this.aiWizardSteps.continueToConfirmation = true;
		this.aiWizardSteps.selectionsConfirmed = true;
	}

	close(): void {
		this._dialogRef.close();
	}

	startAnalysis() {
		if (this.selectedLayer?.id && this.selectedAiModel?.id) {
			this.isLoading = true;
			this.hasError = false;
			this._aiService.createInferenceOrder(this.selectedAiModel.id, this.selectedLayer.id).then(rtn => {
				this.isLoading = false;
				this._dialogRef.close(rtn);
			}).catch(err => {
				console.error(err);
				this.isLoading = false;
				this.hasError = true;
			});
		}
	}

	editSelection() {
		this.aiWizardSteps.continueToConfirmation = false;
		this.aiWizardSteps.selectionsConfirmed = false;
	}

	setSort(sort) {
		if (this.isSort(sort)) {
			this.toggleSortDirection();
			this.filterAcceptableLayers(this.filteredModels);
		} else {
			this.sort = sort;
			this.sortDirectionUp = true;
			this.filterAcceptableLayers(this.filteredModels);
		}
	}

	toggleSortDirection() {
		this.sortDirectionUp = !this.sortDirectionUp;
	}

	isSort(sort) {
		return this.sort === sort;
	}

	checkSortAndDir(sort): boolean {
		return !this.isSort(sort) || this.sortDirectionUp;
	}
}
