import Input from "@mui/material/Input";
import React, { useState } from "react";

import { store } from "@app/store";
import { api } from "@features/Annotations";

import { AnnotationOptions } from "./AnnotationOptions";

export const Annotation = ({ annotation, getAnnotationsThunk }) => {
	const [name, setName] = useState(annotation.name);
	const [isEdit, setIsEdit] = useState(false);
	const onNameChange = (e) => setName(e.target.value);

	const updateAnnotationThunk = (name, annotation) => () => {
		const updates = { name };
		store
			.dispatch(
				api.endpoints.update.initiate({
					annotation_id: annotation.id,
					updates,
				})
			)
			.then(() => {
				getAnnotationsThunk();
			})
			.catch(console.error);
	};

	return (
		<div className="annotations">
			{isEdit ? (
				<Input defaultValue={name} onChange={onNameChange} />
			) : (
				<div className="annotation-name">
					Name: {annotation.name} <br />
					Id: {annotation.id}
				</div>
			)}

			<AnnotationOptions
				annotation={annotation}
				updateAnnotationThunk={updateAnnotationThunk(name, annotation)}
				getAnnotations={getAnnotationsThunk}
				isEdit={isEdit}
				setIsEdit={setIsEdit}
			/>
		</div>
	);
};
