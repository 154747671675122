
/* Imports */
import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation.modal.html',
	styleUrls: ['./confirmation.modal.scss']
})
export class ConfirmationModal implements OnInit {

	public title: string = 'Confirmation';
	public text: string = 'Are you sure?';
	public buttonText: string = 'Confirm';
	public cancelText: string = 'Cancel';
	public showCancel: boolean = true;

	constructor (
		public _dialogRef: MatDialogRef<ConfirmationModal>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (typeof data === 'string') {
			this.text = data;
		} else {
			if (data) {
				this.showCancel = data.showCancel;
				this.title = data.title ?? this.title;
				this.text = data.text ?? this.text;
				this.buttonText = data.buttonText ?? this.buttonText;
				this.cancelText = data.cancelText ?? this.cancelText;
			}
		}
	}

	ngOnInit() {
	}	// End-of ngOnInit

	confirmationAction(): void {
		this._dialogRef.close(true)
	}

	close(): void {
		this._dialogRef.close(false);
	}

}	// End-of class TestComponent
