
<div class="info-wrapper">
	<div class="location-wrapper">
		<p class="H6Black700Semibold">GCP Location Preview</p>
		<button mat-icon-button tooltip="More" [matMenuTriggerFor]="itemMenu">
			<mat-icon class="list-item-icon">more_horiz</mat-icon>
		</button>
		<mat-menu #itemMenu="matMenu" class="little-menu centered-menu-text">
			<ng-template matMenuContent>
				<mat-list style="width: 100%;">
					<mat-list-item [ngClass]="{'P1Black500': mapStyle === 'map'}"
						(click)="setMapStyle('map')" [matMenuTriggerFor]="itemMenu">
						Map view
					</mat-list-item>
					<mat-list-item [ngClass]="{'P1Black500': mapStyle === 'sat'}"
						(click)="setMapStyle('sat');" [matMenuTriggerFor]="itemMenu">
						Satellite view
					</mat-list-item>
				</mat-list>
			</ng-template>
		</mat-menu>
	</div>
</div>
<div *ngIf="!isProcessing"
	leaflet
	[leafletOptions]="options"
	[leafletLayers]="layers">
</div>
