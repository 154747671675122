import { Pipe, PipeTransform } from '@angular/core';
import pluralize from 'pluralize';

@Pipe({
	name: 'plural'
})
export class PluralPipe implements PipeTransform {

	transform(text: string, value: number): string {
		return pluralize(text, value);
	}

}
