
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';
import {PagingStreamService} from './paging-stream.service';
import { Organization } from "@shared/models";
import { makeURLParams } from "./utils.service";

import _ from 'lodash';

@Injectable()
export class FavoriteService {
	url = '/favorites';
	constructor(
		private httpService: HttpService,
		private pagingStreamService: PagingStreamService
	) {

	}	// End-of constructor

	public getByProjectId(projId: number): Promise<any> {
		return this.httpService
			.get(this.url)
			.then( favList =>
				favList?.projects?.find(f => f.project_id === projId ) ?? {}
			);
	}

	/**
	* [GETLIST]
	*
	*/
	public getList(org?): Promise<any> {
		const searchData = {organization_id: org?.id};
		const urlParams = makeURLParams(searchData)
		return this.httpService.get(this.url + urlParams);
	}	// End-of getList

	getFavoritesChunk = (org: Organization) => (chunkStartIndex: number = 0) => {
		const searchData = {organization_id: org?.id, start_at: chunkStartIndex};
		const urlParams = makeURLParams(searchData)
		return this.httpService.get(`${this.url}${urlParams}`);
	}

	streamFavoritesList(org: Organization) {
		return this.pagingStreamService.wrapFetchWithPagingStream(this.getFavoritesChunk(org));
	}

	/**
	* [CREATE]
	*
	* @param {Favorite} fav		Favorite data
	*/
	public create(fav: Favorite): Promise<any> {

				return this.httpService.post(this.url, fav);

	}	// End-of create

	/**
	* [REMOVE]
	*
	* @param {Favorite | number} fav		Favorite data or id
	*/
	public remove(fav: Favorite | number): Promise<any> {

		const url = '/favorites/' + ((typeof fav === 'object') ? fav.id : fav);
		return this.httpService.delete(url);

	}	// End-of remove

}	// End-of class FavoriteService

export interface Favorite {
	id?: number;
	created_by_user_id?: number;
	image_id?: number;
	project_id?: number;
	model_id?: number;
	project_file_id?: number;
	export_id?: number;
	trash?: boolean;
}
