
/* Imports */
import { Component, Input, Output, OnInit, OnChanges, OnDestroy, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DownloadService, ImageService, IMAGESIZES, GlobalService, ProjectService, ImageInfo, TitleService, UtilsService } from 'src/app/shared/services';
import { icon, latLng, Layer, Marker, marker, tileLayer } from 'leaflet';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { SelectionModel } from '@angular/cdk/collections';
import { GCP } from 'src/app/shared/models';
import { environment } from '@/environments/environment';

@Component({
	selector: 'app-map-preview',
	templateUrl: './map-preview.component.html',
	styleUrls: ['./map-preview.component.scss']
})
export class MapPreviewComponent implements OnInit, OnChanges, OnDestroy {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild(MatMenu) menu: MatMenu;

	@Output('backEvent') backEvent: EventEmitter<any> = new EventEmitter();

	@Input('selection') selection: SelectionModel<any>;
	@Input('headers') headers: Array<any> = [];
	@Input('coordinate_system') coordinate_system: any;

	public currentIndex: number;
	public map: any;
	public isProcessing: boolean = true;

	public mapStyle: 'map' | 'sat' = 'map';
	public options = {
		zoom: 9,
		minZoom: 3,
		maxZoom: 15,
		center: latLng(0, 0),
		doubleClickZoom: 0,
	};

	protected token: string = environment.mapbox;
	protected LAYER_MAP: Layer = tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
		tileSize: 512,
		zoomOffset: -1,
		id: 'mapbox/streets-v11',
		accessToken: this.token
	});
	protected LAYER_SAT: Layer = tileLayer(`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${this.token}`);
	public layers: Layer[] = [this.LAYER_MAP];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _cdr: ChangeDetectorRef,
		private _utilsService: UtilsService,
	) {

	}	// End-of constructor

	ngOnInit(): void {

	}	// End-of ngOnInit


	ngOnDestroy(): void {

	}	// End-of ngONDestroy


	ngOnChanges(): void {

		if (this.selection?.selected && this.headers && this.coordinate_system) {
			this.setup(this.selection.selected, this.headers, this.coordinate_system)
		}
	}


	setup(data, headers: Array<any>, coordinate_system): void {
		const locArray = this.convertToLocationArray(data, headers)
		this.checkIsLatLong(locArray, headers, coordinate_system);
	}


	convertToLocationArray(data, headers: Array<any>): Array<any> {
		return data.map(pos => {
			let row = {};
			headers.forEach(header => {
				const val = header.value;
				Object.assign(row, {[val]: pos[header.index]});
			})
			return row;
		})
	}


	checkIsLatLong(data, headers: Array<any>, coordinate_system): void {
		const hasNorthingAndEasting = headers.some(
			(x) =>
				x.value.localeCompare("northing") && x.value.localeCompare("easting")
		);
		if (hasNorthingAndEasting) {
			const proms = data.map((item) =>
				this.convertToLatLong({ ...item, coordinate_system })
					.then((rtn) => ({ ...item, ...rtn }))
					.catch((err) => (console.error(err), err))
			);
			// Catch errors
			Promise.all(proms).then(
				(rtnArray: Array<any>): void => {
					// Filter out any without lat/long
					rtnArray = rtnArray.filter((x) => x?.latitude && x?.longitude);
					rtnArray?.length && this.createMap(rtnArray);
				}
			);
		} else {
			this.createMap(data);
		}
	}

	convertToLatLong(item: any): Promise<any> {
		return this._utilsService.convertToLatLong({
			northing: parseFloat(item.northing),
			easting: parseFloat(item.easting),
			altitude: parseFloat(item.elevation || item.altitude || 1), // 0 Causes API issue
			coordinate_system: this.coordinate_system});
	}


	createMap(data): void {
		this.isProcessing = true;
		this.options.center = latLng(this.getCenter(data));
		this.setMapStyle('map');
		this.addMarkers(data);
	}


	setMapStyle(style: 'map' | 'sat'): void {

		this.mapStyle = style;
		this.layers[0] = style === 'map' ? this.LAYER_MAP : this.LAYER_SAT;
		this.isProcessing = false;

	}	// End-of setMapStyle


	getCenter(data): any {
	const { lat, lon } = data.reduce(
		(acc, val) => (
			{ lat: acc.lat + val.latitude, lon: acc.lon + val.longitude }),
			{ lon: 0, lat: 0 }
		);
		return [lat / data.length, lon / data.length];
	}


	addMarkers(data = []): void {

		const markers = data.map(pos => {
			return marker([pos.latitude, pos.longitude], {
				icon: icon({
					iconSize: [25, 41],
					iconAnchor: [13, 41],
					iconUrl: '/assets/icons/location.svg',
				})
			});
		})
		this.layers = this.layers.concat(markers);
		this.isProcessing = false;
	}


};	// End-of class SinglePhotoComponent
