import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-upload-circle',
	templateUrl: 'upload-circle.component.html',
	styleUrls: ['./upload-circle.component.scss']
})
export class UploadCircleComponent {

	@Input() percentage: number = 0;

	constructor() {}
}
