
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';
import { ProcessOrder } from "@shared/models";
import { makeURLParams } from "@shared/services/utils.service";

@Injectable()
export class OrderService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor

	/**
	 * v2 Routes
	 **/

	getByIdV2(id: number | string): Promise<any> {
		const url = '/v2/order/' + id;
		return this.httpService.get(url);
	}

	getListV2({model_id = null, project_id = null, organization_id = null}): Promise<any> {
		const searchData = {model_id, project_id, organization_id};
		const searchParams = makeURLParams(searchData);
		const url = '/v2/orders' + searchParams;
		return this.httpService.get(url);
	}

	/**
	 * v1 Routes
	 **/

	public getList(): Promise<any> {
		const url = '/orders';
		return this.httpService.get(url);
	}

	public getByOrgId(orgId: number): Promise<any> {
		const params = makeURLParams( { 'organization_id': orgId });
		const url = '/orders' + params;
		return this.httpService.get(url);
	}

	public getByProjectId(projectId: number): Promise<any> {
		const params = makeURLParams( { 'project_id': projectId });
		const url = '/orders' + params;
		return this.httpService.get(url);
	}

}	// End-of class OrderService
