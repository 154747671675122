import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alert } from '../../shared/models';
import {
	ProjectService, AlertService
} from '../../shared/services';
import { TagsComponent } from 'src/app/components';


@Component({
	selector: 'app-tags-modal',
	templateUrl: './tags.modal.html',
	styleUrls: ['./tags.modal.scss']
})
export class TagsModal implements OnInit {

	@ViewChild(TagsComponent) tagComp: TagsComponent;

	//public project: Project;
	public project: any;
	public projectTags: Array<string> = [];
	public tagList: Array<any> = [];

	constructor(public dialog: MatDialog,
		private _alertService: AlertService,
		private _projectService: ProjectService,
		public _dialogRef: MatDialogRef<TagsModal>,
		@Inject(MAT_DIALOG_DATA) public data: any,)
		{
			this.project = data.project;
			this.tagList = data.tagList;
			if(data.project.descriptors.tags)
			{
				this.projectTags = data.project.descriptors.tags.reverse();
			}
		}

	ngOnInit(): void {
	}

	updateTags(): void {
		let proj = this.project;
		proj.descriptors = proj.descriptors || { };
		proj.descriptors.tags = this.projectTags.reverse();
		//proj.descriptors = {'tags': this.projectTags.reverse()};
		//proj.descriptors['tags'] = this.projectTags.reverse();
		//proj.descriptors.tags = this.projectTags.reverse();

		this._projectService.update({
			id: proj.id,
			name: proj.name,
			trash: 0,
			descriptors: proj.descriptors
		}).then(rtnData => {
			this._alertService.success(new Alert(this.project.name + ' tag modifications saved.'));
		}, error => {
			console.warn(error)
			this._alertService.error(new Alert(this.project.name + ' tag modifications failed to update'));
		});
		this._dialogRef.close(true);
	}

}
