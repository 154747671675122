
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { GlobalService } from './global.service';
import { HttpService } from './http.service';

/* Models */
import { User } from '../models';

import { environment } from "@/environments/environment";

@Injectable()
export class UserService {

	constructor(
		private httpService: HttpService,
	) {

		// Add to global service
		GlobalService.userService = this;

	}    // End-of constructor

	/**
	* [GETBYID]
	*
	* @param {number} userId		ID of user
	*/
	public getById(userId: any): Promise<any> {

		let url = '/users/' + userId;
		return this.httpService.get(url);

	}	// End-of getById

	/**
	 * [CREATE]
	 *
	 * @param {User} user			User data
	 * @param {string} plan_id
	 * @param {string} payment_source_id		Chargebee token
	 */
	public create(user: User, plan_id?: string, payment_source_id?: string): Promise<any> {
		let url = '/user';
		return this.httpService.post(url, { ...user, plan_id, payment_source_id });
	}	// End-of create

	/**
	* [RESETPASSWORD]
	*
	* @param {password: string} password		Password string
	*/
	public resetPassword(password: string): Promise<any> {

		let body = { password: password };
		let url = '/user/update_password';
		return this.httpService.post(url, body);

	}	// End-of resetPassword

	/**
	* [UPDATE]
	*
	* @param {User} user		Data of the user
	*/
	update(user: User): Promise<any> {

		let url = '/users';
		return this.httpService.put(url, user);

	}	// End-of update

	/**
	* [SUPPORT]
	*
	* @param {any} ticket			Ticket details
	*/
	support(ticket: any): Promise<any> {

		let url = '/users/support_request';
		return this.httpService.post(url, ticket);

	}	// End-of support

	/**
	* [CONTACTS]
	*
	*/
	contacts(): Promise<any> {

		let url = '/users/contacts';
		return this.httpService.get(url);

	}	// End-of contacts

	/**
	* [LICENSE]
	*
	*/
	license(): Promise<any> {

		let url = '/users/license';
		return this.httpService.get(url);

	}	// End-of license

	/**
	* [getInvitation]
	*
	*/
	getInvitation(invitation_key: string): Promise<any> {

		let url = `/users/invitations/${invitation_key}`;
		return this.httpService.get(url);

	}	// End-of getInvitation

} // End-of class UserService
