
/* Imports */
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, ViewChild, Inject } from '@angular/core';
import { byId, AdminService, AuthenticationService, OrganizationService, FinanceService, AlertService, UtilsService } from '../../shared/services';
import { Alert, Plan } from '../../shared/models';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { InformationModal } from '../../components/information';
import { environment } from "@/environments/environment";

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-storage',
	templateUrl: './storage.component.html',
	styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {

	/* ViewChild */
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	@Input('organizationId') orgId: any;
	@Output('upgradeAccount') upgradeAccount: EventEmitter<any> = new EventEmitter();

	public orgList: Array<any>;
	public user: any;
	public orgPlan: any = null;
	public processingStorage = 0;
	public orgControl: FormControl = new FormControl();
	public currentPlan: any;
	public isMobile: boolean = false;

	constructor(
		public dialog: MatDialog,
		private _cdr: ChangeDetectorRef,
		private _adminService: AdminService,
		private _utilsService: UtilsService,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService,
		private _financeService: FinanceService,
	) {
	}

	ngOnInit() {

		this.isMobile = this._utilsService.getIsMobile();
		this.user = this._authService.user;
		this.setup();

		this._adminService.getOverrideChanged().subscribe(rtn => {
			this.setup();
		})

		this._orgService.getOrgListChange().subscribe(({ orgList }) => {
			this.orgList = orgList ?? [];
			this.getAllOrgData(this.orgList);
			this.setActiveOrg(this.orgList);
			this.sortOrgList(this.orgList);
		})

	}	// End-of ngOnInit


	getAllOrgData(orgList) {
		orgList = orgList.filter(x => !x.isFromProjectShare);
		orgList.forEach(this._orgService.getOrgData.bind(this));
		return orgList;
	}

	setActiveOrg(orgList) {
		const default_organization =
			orgList.find(byId(this.user.default_organization_id)) || orgList[0];
		if (default_organization?.id) {
			this.orgControl.setValue(default_organization);
			this.orgId = default_organization.id;
		}
		return orgList;
	}

	sortOrgList(orgList) {
		orgList.sort((orgA, orgB) => {
			return orgA.name.localeCompare(orgB.name, { sensitivity: 'base' });
		});
	}

	setup() {
		this._orgService
			.getList()
			.then((rtnList = []) => (this.orgList = rtnList))
			.then(this.getAllOrgData.bind(this))
			.then(this.setActiveOrg.bind(this))
			.then(this.sortOrgList.bind(this))
			.catch(console.error); // Should we give the user some indication as to what went wrong and what they should do next?
	}

	openStoragePurchaseModal(org: any): void {
		// TODO: Modify when Subscription management is added
		const dialogRef = this.dialog.open(StorageModal, {data: org});
		dialogRef.afterClosed().subscribe(rtn => {
			if (rtn) {
				this.setup();
				this.openInformationModal(rtn);
			}
		});
	}

	openInformationModal(storage: any = 0): void {
		this.orgControl.value.storage = storage;
		this.dialog.open(InformationModal, {data:
			{
				title: 'Storage purchase complete!',
				text: `You now have a total of ${storage.toLocaleString()} GB of storage available. We sent a receipt for your purchase to your email.`,
				buttonText: 'Continue to Mapware'
			}
		}).afterClosed().subscribe();
	}

	storagePercentage(full: number, used: number): string {
		const percentage = used / full * 100;
		const outString = percentage.toString() + '%';
		return outString;
	}

	bytesToGigabytes(value: number): number {
		const outVal = Math.round(value / 1000000000);
		return outVal ? outVal : 0;
	}

	openUpgrade(value): void {
		this.upgradeAccount.emit(value)
	}

}	// End-of class TestComponent


@Component({
	selector: 'app-storage-modal',
	templateUrl: './storage.modal.html',
	styleUrls: ['./storage.component.scss']
})
export class StorageModal implements OnInit {

	@Output('storageChange') storageChange: EventEmitter<boolean> = new EventEmitter();

	public supportEmail = environment.supportEmail;
	public user: any;
	public org: any = null;
	public cardList: Array<any> = [];
	public storagePurchasing: number;
	public purchaseCard: any = null;
	public isProcessing = true;
	public showError: string = null;
	public showIncrementError = false;
	public storeIncrement = 100;
	orgId: number = null;
	displayPage = 'default';
	public isMonthly: boolean = true;

	constructor(
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _financeService: FinanceService,
		private _utilsService: UtilsService,
		private _alertService: AlertService,
		public _dialogRef: MatDialogRef<StorageModal>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

		this.user = this._authService.user;
		this.org = data;
		this.orgId = data.id;
		if (this.org.orgPlan) {
			this.getCards(this.orgId);
		}
	}

	ngOnInit() {

	}	// End-of ngOnInit


	getCards(orgId) {
		this._financeService.getCards(orgId)
			.then(rtnCards => {
				this.cardList = rtnCards.data;
				this.purchaseCard = rtnCards.data[0];
			})
			.catch(console.error)
			.finally(() =>
				this.isProcessing = false
			)
		;
	}

	togglePage(state: string) {
		if ( state === 'default' ) {
			this.getCards(this.orgId);
		}
		this.displayPage = state;
	}

	calculatedTotal(inputVal: number = 0): number {
		const additional = this.storagePurchasing | 0;
		const value = inputVal + additional * this.getStorageCost();
		return value ? value : 0;
	}

	calculatedDue(): number {
		const fraction = this._utilsService.getFractionRemaining(this.org.orgPlan);
		const additional = this.storagePurchasing | 0;
		const value = fraction * additional * this.getStorageCost();
		return value ? value : 0;
	}

	completePurchase(): void {
		const plan = new Plan({
			additional_storage: this.storagePurchasing + this.org.storage - this.org.orgPlan.plan.storage_included_per_user,
			id: this.org.orgPlan.id
		});

		// TODO: Modify when Subscription management is added
		this._financeService.updatePlan(this.org.id, plan).then(rtn => {
			this._alertService.success(
				new Alert((`${this.storagePurchasing.toLocaleString()} GB of storage was added to your team's account.`))
			);
			this._dialogRef.close(this.storagePurchasing + this.org.storage);
		}, err => {
			this._alertService.error(new Alert('Your storage purchase could not be completed. Please try again.'));
			console.error(err);
		});
	}

	checkValid(): void {
		if (this.storagePurchasing >= this.storeIncrement) {
			const modulo = this.storagePurchasing % this.storeIncrement;
			this.storagePurchasing -= modulo;
			this.showIncrementError = modulo > 0;
		} else {
			this.showError = `Minimum storage purchasable is ${this.storeIncrement} GB.`;
		}
	}

	getDateText(): string {
		return this.isMonthly ? 'monthly' : 'yearly'
	}

	getStorageCost(): number {
		return this.isMonthly ? this.org.orgPlan.plan.additional_storage_cost_month : this.org.orgPlan.plan.additional_storage_cost_year;
	}

	getPlanCost(): number {
		return this._utilsService.getPlanPrice(this.org.orgPlan);
	}

}	// End-of class TestComponent
