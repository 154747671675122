import {isEmpty, isMatch, isNil} from 'lodash';
import {createLayerApi} from "../createLayerApi/createLayerApi";
import {environment} from "../../../environments/environment";
// @ts-ignore
import defaultFlags from "./flags.default.json";
import {FeatureFlagContext} from "../services/global.service";
/**
 * In the near future we will conduct a full migration from GitLab's Feature Flags to LaunchDarkly.
 * Once we make that move this file will act effectively as a switch between commercial and government.
 * For commercial it will be the `launchDarklyLayerDef` and for government we will define a `govLayerDef` that will largely replicate what is currently in this file
 *
 * But while we test and migrate, everything has to live in this awkwardly merged state. But once we make the switch we will
 * - [ ] extract the government feature flag layer to it's own file
 * - [ ] create an environment based switch to select the layer definition to use with `createLayerApi`
 */
import {launchDarklyLayerDef} from "./launchDarklyLayerDef";

export { availableFeatureFlags } from './availableFeatureFlags'

export const flagLayer = createLayerApi(launchDarklyLayerDef)
