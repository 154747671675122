
/* Imports */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-register-user',
	templateUrl: './register-user.component.html',
	styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent {

	@Input('formGroup') registerForm: FormGroup;
	@Input() emailAlreadyRegistered: boolean = false;
	@Output() emailAlreadyRegisteredChange: EventEmitter<boolean> = new EventEmitter();

	public showingPassword = false;
	public passwordReqsMet = false;
	public password;

	onEmailChange() {
		if (this.emailAlreadyRegistered) {
			this.emailAlreadyRegisteredChange.emit(false);
		}
	}


	get emailInvalid(): boolean {
		const email = this.registerForm.get('email');
		return email.invalid && email.dirty && email.touched;
	}
	get passwordInvalid(): boolean {
		const password = this.registerForm.get('password');
		this.password = password;
		if (password) {
			this.validatePasswordStrength(password.value);
		}
		return password.invalid && password.dirty && password.touched;
	}
	get firstNameInvalid(): boolean {
		const first_name = this.registerForm.get('first_name');
		return first_name.invalid && first_name.dirty && first_name.touched;
	}
	get lastNameInvalid(): boolean {
		const last_name = this.registerForm.get('last_name');
		return last_name.invalid && last_name.dirty && last_name.touched;
	}

	validatePasswordStrength(password) {
		const regex = /^(?=.{8,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*\W/;
		
		if (regex.test(password)) {
			this.passwordReqsMet = true;
		} else {
			this.passwordReqsMet = false;
		}
	}

}	// End-of class RegisterUserComponent
