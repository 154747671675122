import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'comma'
})
export class CommaPipe implements PipeTransform {
	transform(value: number, decimals: number = 0): string {
		return decimals
			? value.toLocaleString(undefined, { maximumFractionDigits: decimals })
			: Math.floor(value).toLocaleString()
	}

}
