import { Draft, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type vizDict = Record<number, boolean>;

export const annotationGroupsSlice = createSlice({
	initialState: {} as vizDict,
	name: "annotationGroups",
	reducers: {
		setAnnotationGroups(state, action) {
			return action.payload;
		},
		toggleAnnotationVis: (
			state: Draft<vizDict>,
			action: PayloadAction<number | string>
		) => {
			state[action.payload] = !state[action.payload];
		},
	},
});

const selectSelf = (state): vizDict => state[annotationGroupsSlice.name];
export const getVisibilityDict = selectSelf;

export const { setAnnotationGroups, toggleAnnotationVis } =
	annotationGroupsSlice.actions;
export const reducer = annotationGroupsSlice.reducer;

export function organizeAnnotationGroupsForControls(
	annotationGroups,
	layerTypeDict
) {
	return annotationGroups
		.flatMap((group) =>
			group.layers
				.filter((layer) => layer.type in layerTypeDict)
				.map(({ id, type }) => ({
					groupName: group["name"],
					id,
					type,
				}))
		)
		.reduce((acc, layer) => Object.assign(acc, { [layer.id]: layer }), {});
}
