
/* Imports */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


const accountTab = 'plan';

@Component({
	selector: 'app-paywall',
	templateUrl: './paywall.component.html',
	styleUrls: ['./paywall.component.scss']
})

export class PaywallComponent {

	@Input() disabled = false;
	@Input() showInfo = false;

	@Output('upgradeAccount') upgradeAccount: EventEmitter<any> = new EventEmitter();

	openUpgrade(): void {
		this.upgradeAccount.emit(accountTab);
	}

}
