
/* Imports */
import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, HostBinding, ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'aerial-avatar',
	templateUrl: './avatar.html',
	styleUrls: ['./avatar.scss']
})
export class AerialAvatar implements OnInit, AfterViewInit {

	public buttonClass: string = '';
	@ViewChild('contentRef') contentRef: ElementRef;
	@ViewChild('containerRef') containerRef: ElementRef;

	constructor (private _cdr: ChangeDetectorRef) {

	}	// End-of constructor

	ngOnInit() {
	}	// End-of OnInit

	ngAfterViewInit() {
		let color = this.stringToHslColor(this.contentRef.nativeElement.innerHTML, 50, 55);
		this.containerRef.nativeElement.style.background = color;
		setTimeout(() => {this._cdr.detectChanges()});
	}

	stringToHslColor(str, s, l): string {
		var hash = 0;
		for (var i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}

		var h = hash % 360;
		return 'hsl('+h+', '+s+'%, '+l+'%)';
	}

}	// End-of class NgcAvatar
