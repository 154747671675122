import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "../../store/utils/baseQuery";

export const api = createApi({
	baseQuery,
	endpoints: (build) => ({
		get: build.query({
			providesTags: ["Projects"],
			query: (project_id: number) => `projects/${project_id}`,
		}),

		getList: build.query<any, void>({
			providesTags: ["Projects"],
			query: () => `projects`,
		}),
	}),
	reducerPath: "projectsApi",
	tagTypes: ["Projects"],
});

export const { useGetListQuery, useGetQuery } = api;

export default api;
