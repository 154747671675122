/* Imports */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder } from "@angular/forms";

import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";
import { ExportDialog } from "../../components/export-dialog/export.dialog";
import { environment } from "./../../../environments/environment";

/* Services */
import {
	AlertService,
	AuthenticationService,
	FinanceService,
	GCPService,
	ImageService, makePublicShareLink,
	Measurement,
	MeasurementService,
	ModelService,
	PermissionService,
	ProjectService,
	TitleService,
	UserService,
	UtilsService
} from "../../shared/services";

/* Models */
import { Alert, GCP, Model, Project } from "../../shared/models";
import { OrthoTile } from "./ortho.viewer.component";
import { PointLayer } from "./point.viewer.component";
import { InformationModal } from "../../components/information";
import { ErrorModal } from "../../components/error-modal";

/* RXjs */
import { OrthoMeasure } from "./classes/measure";
import { Annotations } from "./classes/annotate";
import { detect } from "detect-browser";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModal, ShareLinkComponent } from "@app/components";
import { AddLayerDialog } from "@app/pages/viewer/features/add-layer/add-layer.component";
import { cloneDeep } from "lodash";
import { store } from "@app/store";
import { organizeLayerGroupsForControls, setLayerGroups } from "@features/ViewerLayers";

enum DisplayUnits {
	meter = "m",
	feet = "ft",
	inch = "in"
}

enum CameraControls {
	earth = "earth",
	orbit = "orbit",
	fp = "fp"
}

enum ConversionFromMeter {
	m = 1,
	ft = 3.28084,
	in = 39.3701
}

export enum VIEWERSTATE {
	NONE = "none",
	MEASURE = "measure",
	MEASURE_EDITING = "measure_editing",
	MEASURE_ADDING = "measure_adding",
	MARKER = "marker"
}

export interface ViewerLayerGroup {
	id: number,
	color: string,
	type: string,
	expanded: boolean,
	layers: Array<ViewerLayer>
}

export interface ViewerLayer {
	id: string,
	model_id: string,
	name: string,
	type: string,
	overlayType: string,
	fileType: string,
	mapType: string,
	url: string,
	source: string,
	status: string,
	createdBy: string,
	createdOn: string,
	coordinate: Object,
	visible: boolean,
	showDetails: boolean,
	exportForm: any,
	errors,
}

const isLatest = ( newDate: any, currentDate: any ): boolean =>
	( new Date( newDate ) ) > ( new Date( currentDate ) );

const sortByNewestToOldest = ( a, b ) => a.created_at < b.created_at ? 1 : a.created_at === b.created_at ? 0 : -1;
const sortByOldestToNewest = ( a, b ) => a.created_at > b.created_at ? 1 : a.created_at === b.created_at ? 0 : -1;

const isValidGeo = ( geo: any ): boolean => ( geo.north || geo.south ) && ( geo.east || geo.west ) && geo.url && geo.zoom && geo.minZoom;

const filterSort = ( arrayToFilterSort: Array<any>, filterFunc: any, sortFunc: any ): Array<any> =>
	arrayToFilterSort.filter( filterFunc )
		.sort( sortFunc );

// arrays should be ordered in terms of initial visibility priority
export const LAYER_TYPES_3D = {
	"3D model": true,
	"dense point cloud": true,
	"dense": true,
	"sparse point cloud": true,
	"sparse": true
};
export const LAYER_TYPES_2D = {
	"orthomosaic": true,
	"dsm": true,
	"dem": true,
	"analysis": true,
	"overlay": true
};

export const shouldShowLayer = ( layerData ): boolean => {
	if ( !flagLayer.isEnabled( availableFeatureFlags.betaLayers ) ) {
		if ( layerData.source && !/legacy|upload/i.test( layerData.source ) )
			return false;
	}

	// always show 2D layers
	if ( layerData.type === "orthomosaic" || layerData.type === "dem" )
		return true;

	return layerData.status === "Rendered";
};

const maybePadBoundaries = ( boundaries ): any => {
	if ( flagLayer.isEnabled( availableFeatureFlags.extendGeoTIFFBoundaries ) ) {
		if ( boundaries.north === boundaries.south ) {
			boundaries.north += 0.0004;
			boundaries.south -= 0.0004;
		}
		if ( boundaries.east === boundaries.west ) {
			boundaries.east -= 0.0004;
			boundaries.west += 0.0004;
		}
	}
	return boundaries;
};


const loadLayersFromPotreeFiles = async ( model ) => {
	if(!model.potree_files) model.potree_files = [];

	// Get Potree files, sort so newest is last, and will be selected for models
	const potree_files = model.potree_files.sort( sortByOldestToNewest );
	potree_files.forEach( layerData => {
		if ( shouldShowLayer( layerData ) ) {
			let newLayer = {
				id: "p" + layerData.id,
				model_id: model.id,
				name: layerData.type + " point cloud",
				type: layerData.type + " point cloud",
				fileType: "potree",
				url: layerData.url,
				source: layerData.source,
				createdOn: layerData.created_at,
				createdBy: "",
				visible: false,
				showDetails: false,
				errors: {}
			} as PointLayer;
			model.layers.push( newLayer );
		}
	} );
	return model;
};

const loadLayersFromCesiumFiles = async ( model ) => {
	if(!model.cesium_files) model.cesium_files = [];

	const cesium_files = model.cesium_files.sort( sortByOldestToNewest );
	cesium_files.forEach( layerData => {
		if ( shouldShowLayer( layerData ) ) {
			let newLayer = {
				id: "c" + layerData.id,
				model_id: model.id,
				name: "mesh", // TODO - set layer name from data - UX-204
				type: "3D model",
				fileType: "cesium",
				url: layerData.url,
				source: layerData.source,
				createdOn: layerData.created_at,
				createdBy: layerData.created_by_user_id,
				visible: false,
				showDetails: false,
				errors: {}
			} as PointLayer;
			model.layers.push( newLayer );
		}
	} );
	return model;
};

const loadLayersFromGeotiffs = async ( model ) => {
	if(!model.geotiffs) model.geotiffs = [];

	const geotiffs = model.geotiffs.sort( sortByNewestToOldest );
	geotiffs.forEach( geo => {
		if ( isValidGeo( geo ) && ( geo.type === "orthomosaic" || geo.type === "dem" )
			&& shouldShowLayer( geo ) ) {
			let type = geo.type;

			// Type Renaming
			if ( type === "dem" ) type = "dsm";

			let boundries = maybePadBoundaries( {
				north: geo.north,
				south: geo.south,
				west: geo.west,
				east: geo.east
			} );

			let newLayer = {
				id: "g" + geo.id,
				model_id: model.id,
				name: type, // TODO - set layer name from data - UX-204
				type: type,
				fileType: "geotiffs",
				url: geo.url,
				maxZoom: geo.zoom,
				minZoom: geo.minZoom,
				boundries: boundries,
				source: geo.source,
				createdOn: geo.created_at,
				createdBy: geo.created_by_id,
				visible: false,
				showDetails: false,
				errors: {}
			} as OrthoTile;
			model.layers.push( newLayer );
		}
	} );
	return model;
};

export const loadLayersFromModel = async ( model ) => {
	return loadLayersFromPotreeFiles( model )
		.then( loadLayersFromGeotiffs )
		.then( loadLayersFromCesiumFiles );
};

@Component( {
	selector: "app-viewer",
	templateUrl: "./viewer.component.html",
	styleUrls: [ "./viewer.component.scss" ]
} )
export class ViewerComponent implements OnInit, AfterViewInit {

	public activeViewer: any;
	public userHasPermission: boolean;
	public isLidar: boolean = false;
	public isExpired: boolean = true;
	public exportsEnabled: boolean = true;

	/* User */
	public userId: number;
	public isDeveloper: boolean;

	public toolbarCollapsed: boolean = false;
	public interiorCollapsed: boolean = true;
	public loading: boolean = false;
	public viewer_data_loaded: boolean = false;
	public view: string = "3D";
	public browser: string = "";

	/* Options */
	public backgroundColorSelection: "dark" | "light" = "dark";
	public cameraSelection: CameraControls = CameraControls.orbit;
	/* Project Info */
	public project: Project;
	public shareLink: string;
	private _token: number;
	private _model_id: number;
	public isPublic: boolean = true;
	public model: Model;
	public model_name: string;
	public model_status: string;
	public model_projection: string;
	public layerGroups: Array<ViewerLayerGroup> = [];
	public has2DLayers: boolean = false;
	public has3DLayers: boolean = false;
	/* Pointcloud */
	public sparse_url: string;
	public dense_url: string;
	/* Model */
	public model_url: string;
	/* Ortho */
	public geotiff_tile: OrthoTile;
	/* DSM */
	public dsm_tile: OrthoTile;

	// TODO: @remove viewer-layers
	/* Images */
	public photos: Array<any>;
	// TODO: @remove viewer-layers
	public show_point_clouds: boolean = false;
	/* Upload Element Reference */
	@ViewChild( "fileInput" ) fileReference: ElementRef;
	public fileName: string;
	public loadingProgress: number = 0;
	// TODO-END @remove viewer-layers
	public uploadingFile: boolean = false;
	public measuments: Measurement[] = [];
	public orthoMeasurements: Measurement[] = [];

	/* Slide toggles Reference */
	// @ViewChild('slideToggles') slidesReference: ElementRef;
	// @ViewChildren('slideToggles') slidesReference: QueryList<ElementRef>;
	// public numberOfSlides: Array<any> = new Array(10);
	public pointMeasurements: Measurement[] = [];
	public modelMeasurementService;
	public gcpList: Array<GCP> = [];
	/* Support Info */
	public supportLink: string = environment.supportEmail;
	public supportGuide: string = environment.supportGuide;
	/* Upload Variables */
	private file;
	private fileToUpload;
	private _measureService: any;

	constructor(
		private _dialog: MatDialog,
		private _authenticationService: AuthenticationService,
		private _formBuilder: FormBuilder,
		private _modelService: ModelService,
		private _imageService: ImageService,
		private _projectService: ProjectService,
		private _financeService: FinanceService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _permissionService: PermissionService,
		private _titleService: TitleService,
		private _gcpService: GCPService,
		private _userService: UserService,
		private _alertService: AlertService,
		private _measurementService: MeasurementService
	) {
		this.isDeveloper = this._authenticationService.isDeveloper;
		this._measureService = flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
			Annotations :
			_measurementService
		;
	}	// End-of constructor

	private _displayUnits: DisplayUnits = DisplayUnits.meter;

	get displayUnits(): DisplayUnits {
		return this._displayUnits;
	}

	set displayUnits( value: DisplayUnits ) {
		this._displayUnits = value;
		this.activeViewer.setLengthUnit( value );
	}

	ngOnInit() {

		this.userId = this._authenticationService.user.id;

		this.setup();

		if ( !flagLayer.isEnabled( availableFeatureFlags.disableGCPs ) ) {
			this.getGCPList();
		}

	}	// End-of ngOnInit

	ngAfterViewInit() {

	}	// End-of ngAfterViewInit

	getGCPList(): void {
		if ( this._token ) {
			this._gcpService.getListFromProject( this._token ).then( rtnList => {
				this.gcpList = rtnList.sort( ( a, b ) => a.name.localeCompare( b.name ) );
			} );
		}
	}

	setup() {
		if ( !this.webglDetect() ) {
			this.view = "NoWebGL";
			this.toolbarCollapsed = true;
			this.browserSupportDetect();
			return;
		}

		this.loading = true;

		// Gets passed-in inspection id
		// Then gathers model data from the inspection
		this._route.queryParams.subscribe( params => {
			this.getDataFromParams( params )
				.then( ( { public_share_guid, token, model_id } ) => {
					if ( token ) {
						this.isPublic = false; // Not public share
						this._model_id = model_id;
						this._token = token;
						this.loadProject(token);
					} else if (public_share_guid) {
						this._modelService.getPublicShare(public_share_guid).then(rtnProject => {
							this.isPublic = true;
							this._token = rtnProject.id;
							this._model_id = rtnProject.models[ 0 ]?.id;
							this.handleProject( rtnProject );
						} ).catch( err => {
							console.error( err );
							this.handleMissingShare();
						} );
					} else {
						this.loading = false;
						this._router.navigateByUrl( "/projects/list" );
					}
				} );
		} );
	}

	async getDataFromParams( params ) {

		const public_share_guid = params[ "s" ];
		const token = parseInt( this._route.snapshot.paramMap.get( "project" ) );
		const model_id = params[ "model" ];

		return { public_share_guid, token, model_id };
	}

	async loadProject(project_id) {
		if ( flagLayer.isEnabled( availableFeatureFlags.apiV2Routes ) ) {
			const project = await this._projectService.getByIdV2(project_id).catch(error => {
				console.warn("Token Error: ", error);
				this.loading = false;
				if ( error === 401 ) {
					this.handleUserAccessFail();
				}
			});

			if(project) {
				let models = await this._modelService.getListV2({project_id: project.id}).catch(() => []);
				models = (await Promise.all(models.map(async (model) => {
					return await this._modelService.getModelV2(model.id).catch(() => null);
				}))).filter((model) => {
					return !!model;
				});
				project.models = models;

				let batches = await this._imageService.getBatchesByProjectId(project.id).catch(() => []) ?? [];
				batches = await Promise.all(batches.map(async (batch) => {
					return await this._imageService.getDeepBatchById(batch.id).catch(() => null);
				}).filter((batch) => {
					return !!batch;
				}));
				project.batches = batches;

				this.handleProject(project);
			}
		} else {
			this._projectService.getById(project_id).then( project => {
				this.handleProject( project );
			}, error => {
				console.warn( "Token Error: ", error );
				this.loading = false;
				if ( error === 401 ) {
					this.handleUserAccessFail();
				}
			} );
		}
	}

	handleMissingShare(): void {
		this.view = null;
		let dialogRef = this._dialog.open( ConfirmationModal, {
			data:
				{
					title: "Model not found",
					text: "The model link is incorrect or has expired. Please check the URL or contact support if you believe this is an error.",
					buttonText: "Return to Mapware",
					showCancel: false
				}
		} );
		dialogRef.afterClosed().subscribe( val => {
			this._router.navigateByUrl( "/" );
		} );
	}

	// Expecting a deep Project
	async handleProject( project ) {
		this.isExpired = false;

		if ( !project || typeof project !== "object" ) {
			console.error( "User does not have access to model. ERROR:", project );
			this.handleUserAccessFail();
			return;
		}

		this._titleService.setTitle( project.name );
		this.photos = project.batches;
		this.layerGroups = [];
		this.project = project;

		this.loadMeasurements();

		if ( !this.isPublic ) {
			this._permissionService.checkHasPermission( project, "reader" ).then( rtn => {
				this.userHasPermission = rtn;
			} );
		}

		let layerPromises = [];

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			// load in all Project models
			layerPromises = project.models.map( model => this.loadLayersFromModel( model ) );
		} else {
			if ( this._model_id ) {
				// if there is a specified model, only load in that models
				let onlyModelToLoad = project.models.find( p => {
					return p.id == this._model_id;
				} );

				if ( !onlyModelToLoad ) {
					console.error( "Model not found" );
					this.handleModelMissingAllData();
					return;
				}
				layerPromises = [ this.loadLayersFromModel( onlyModelToLoad ) ];
			} else {
				// otherwise, load in all Project models
				layerPromises = project.models.map( model => this.loadLayersFromModel( model ) );
			}
		}

		await Promise.all( layerPromises ).then( rtnModels => {
			this.layerGroups = rtnModels.filter( x => !!x.layers.length );
		} );

		// check that there are some layers to show
		if ( !this.hasLayers() ) {
			this.handleModelMissingAllData();
			return;
		}

		// choose initial Model to view
		this.layerGroups.sort( sortByNewestToOldest );

		// if there is no specified model, show the most recent
		if ( !this._model_id ) {
			this._model_id = this.layerGroups[ 0 ].id;
		}

		this._measureService.setModelId( this._model_id );

		// expand the default Model's Layer-group
		let defaultModel = this.layerGroups.find( group => group.id == this._model_id );
		defaultModel.expanded = true;

		// make one of the 2D layers visible, if they exist
		for ( let type in LAYER_TYPES_2D ) {
			let layer = defaultModel.layers.find( layer => layer.type === type );
			if ( layer ) {
				layer.visible = true;
				this.view = "2D";
				break;
			}
		}

		// make one of 3D layers visible, default to 3D view initially
		for ( let type in LAYER_TYPES_3D ) {
			let layer = defaultModel.layers.find( layer => layer.type === type );
			if ( layer ) {
				layer.visible = true;
				this.view = "3D";
				break;
			}
		}

		if ( this.get2DLayers().length ) {
			this.has2DLayers = true;
		}
		if ( this.get3DLayers().length ) {
			this.has3DLayers = true;
		}

		switch (this.view) {
			case "3D":
				this.viewer3DLoad();
				break;
			case "2D":
				this.viewer2DLoad();
				break;
			case "Map":
				this.viewerMapLoad();
				break;
			case "NoWebGL":
				console.error( "View Type: NoWebGL", this.view );
				break;
			default:
				console.error( "Unrecognized view type: ", this.view );
				break;
		}

		this.loading = false;
	}

	async loadLayersFromModel( model ): Promise<any> {
		this.model = model;

		this.model_status = model.status;
		this.model_name = model.name;
		this.model_projection = model.descriptors?.crs?.proj4;

		if (this.isPublic && !model.public_share) this.handleMissingShare();

		if ( flagLayer.isEnabled( availableFeatureFlags.checkForExportsDisabled ) ) {
			this.exportsEnabled = !model.descriptors.exports_disabled;
		}

		model.expanded = false;
		model.color = "#005dea"; // blue-500 // --cerulean-blue
		model.layers = [];

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {

			return loadLayersFromModel( model );

		} else { // TODO: @remove viewer-layers
			let potree_files = model.potree_files.sort( sortByOldestToNewest );
			if ( potree_files?.length ) {
				potree_files.forEach( p => {
					if ( shouldShowLayer( p ) ) {
						if ( p.type === "dense" ) {
							this.dense_url = p.url;
						} else if ( p.type === "sparse" ) {
							this.sparse_url = p.url;
						} else {
							this.dense_url = p.url;
						}
						model.layers.push( p );
					}
				} );
			}

			let cesium_files = model.cesium_files.sort( sortByOldestToNewest );
			if ( cesium_files?.length ) {
				// Sort, and use newest model always
				cesium_files.forEach( cf => {
					if ( shouldShowLayer( cf ) ) {
						this.model_url = cf.url;
						cf.type = "3D model";
						model.layers.push( cf );
					}
				} );
			}

			let geotiffs = model.geotiffs;

			const geoFilterFunc = ( filterString ) => ( item ) => item.type === filterString && isValidGeo( item ) && shouldShowLayer( item );

			const geosAssignment = ( geoArray: Array<any>, geoType: string, tileType: string ): OrthoTile => {

				// Filter out broken geos, and sort by newest
				geoArray = filterSort( geoArray, geoFilterFunc( geoType ), sortByNewestToOldest );

				const geo = geoArray[ 0 ]; // TODO: Make it so we can have multiple
				if ( geo ) {
					let boundries = maybePadBoundaries( {
						north: geo.north,
						south: geo.south,
						west: geo.west,
						east: geo.east
					} );

					return {
						name: tileType, // TODO - set layer name from data - UX-204
						type: tileType,
						url: geo.url,
						maxZoom: geo.zoom,
						minZoom: geo.minZoom,
						boundries: boundries,
						visible: true
					} as OrthoTile;
				} else {
					console.warn( "No geotiff of type", geoType );
					return null;
				}
			};

			this.dsm_tile = geosAssignment( geotiffs, "dem", "dsm" );

			if ( this.geotiff_tile ) model.layers.push( this.geotiff_tile );
			if ( this.dsm_tile ) model.layers.push( this.dsm_tile );
		}
		return model;
	}

	// TODO: @remove viewer-layers
	handleTogglePointCloud( show_point_clouds: boolean, activeViewer ): void {
		if ( show_point_clouds ) { // if toggling off, toggle off any point clouds that are on
			activeViewer.isVisible( "sparse" ) && activeViewer.setVisible( "sparse" );
			activeViewer.isVisible( "dense" ) && activeViewer.setVisible( "dense" );
		} else { // if toggling on, toggle on dense to start (or sparse if no dense available)
			this.dense_url ? activeViewer.setVisible( "dense" ) : activeViewer.setVisible( "sparse" );
		}
	}

	handleUserAccessFail(): void {

		this.view = null;
		let dialogRef = this._dialog.open( InformationModal, {
			data:
				{
					title: "Error",
					text: "You do not have permission to view this model. Please contact your administrator or support if you believe this is a mistake.",
					buttonText: "Return to the home page"
				}
		} );
		dialogRef.afterClosed().subscribe( val => {
			this._router.navigateByUrl( "/projects/list" );
		} );
	}

	handleModelMissingAllData(): void {

		this.view = null;
		let dialogRef = this._dialog.open( InformationModal, {
			data:
				{
					title: "Error",
					text: "There are no data layers in this project. Please process or upload a model to see it in the map.",
					buttonText: "Return to the project"
				}
		} );
		dialogRef.afterClosed().subscribe( val => {
			this._router.navigateByUrl( "/projects/view/" + this._token );
		} );
	}

	handleViewerCrash(): void {
		this.view = null;
		let dialogRef = this._dialog.open( ErrorModal, {
			data:
				{
					error: "WebGL context lost",
					defaultActionText: "Return to the project",
					supportPage: {
						title: "WebGL Troubleshooting",
						path: "s/article/faq-browser-could-not-initialize-webgl"
					},
					couldRefresh: true
				}
		} );
		dialogRef.afterClosed().subscribe( shouldRefresh => {
			shouldRefresh ?
				window.location.reload() :
				this._router.navigateByUrl( "/projects/view/" + this._token );
		} );
	}

	openDenyMessage(): void {
		this.view = null;
		let dialogRef = this._dialog.open( InformationModal, {
			data:
				{
					title: "Access Denied",
					text: "You are unable to complete this action because your team does not have an active subscription. Please update your subscription plan or contact your admin.",
					buttonText: "Return to the home page"
				}
		} );
		dialogRef.afterClosed().subscribe( val => {
			this._router.navigateByUrl( "/projects/list" );
		} );
	}

	getAllLayers(): any {
		return this.layerGroups.flatMap( group => group.layers );
	}

	hasLayers(): boolean {
		return this.getAllLayers().length;
	}

	get3DLayers(): Array<any> {
		return this.getAllLayers().filter( layer => LAYER_TYPES_3D[ layer.type ] ?? false );
	}

	get2DLayers(): Array<any> {
		return this.getAllLayers().filter( layer => LAYER_TYPES_2D[ layer.type ] ?? false );
	}

	viewer3DReady( viewer3D ): void {
		this.activeViewer = viewer3D;
		this.viewer3DLoad();
	}	// End-of viewer3DReady

	viewer3DLoad(): void {
		if ( this.viewer_data_loaded || !this.activeViewer ) {
			return;
		}

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {


			this.activeViewer.setupLayers( this.layerGroups, this.model_projection ).then( data => {
				this.viewer_data_loaded = ( data?.length );
				if (flagLayer.isEnabled(availableFeatureFlags.tempMapReactLayerControls)) {
					store.dispatch( setLayerGroups( organizeLayerGroupsForControls( this.layerGroups, LAYER_TYPES_3D ) ) );
				}
			} );
		} else { // TODO: @remove viewer-layers
			let layers = [];
			if ( this.sparse_url ) {
				layers.push( {
					name: "sparse",
					type: "pointcloud",
					url: this.sparse_url,
					visible: this.dense_url ? false : true
				} );
			}

			if ( this.dense_url ) {
				layers.push( {
					name: "dense",
					type: "pointcloud",
					url: this.dense_url,
					visible: this.model_url ? false : true
				} );
			}

			if ( this.model_url ) {
				layers.push( {
					name: "model",
					type: "model",
					url: this.model_url,
					visible: true
				} );
			}

			this.show_point_clouds = this.model_url ? false : true;

			this.activeViewer.setupLayers( layers ).then( data => {
				this.viewer_data_loaded = ( data && data.length >= 1 );

				//TODO point cloud features disabled, check that LiDAR models display properly in interim
				this.isLidar = false;
				// TODO - we need a better way to identify uncolored Lidar point clouds
				// this.isLidar = !(this.photos?.length && (this.dense_url || this.sparse_url))

				if ( this.isLidar ) //colorize by elevation
				{
					this.activeViewer.updateAttribute( { val: 1, type: "elevation" } );
					this.dense_url ? this.activeViewer.setVisible( "dense" ) : this.activeViewer.setVisible( "sparse" );
				}


				// Injection of sliders into jstree elements
				// if (this.viewer_data_loaded) {
				// 	// DEMO BORK CODe
				// 	let elms = $("i.jstree-icon.jstree-checkbox");
				// 	// this.numberOfSlides = new Array(elms.length);
				// 	let hostElements = this.slidesReference.toArray();
				// 	console.log("What is a: ", hostElements, hostElements.length, elms.length);
				// 	// let hostElm = this.slidesReference.nativeElement;
				// 	// let children = hostElm.children;

				// 	for (let e of elms) {
				// 		e.replaceWith(hostElements[0].nativeElement);
				// 	}
				// }
			} );
		}
	}	// End-of viewer3DLoad

	viewer2DReady( viewer2D ): void {
		this.activeViewer = viewer2D;
		this.viewer2DLoad();
	}	// End-of viewer2DReady

	viewer2DLoad(): void {
		if ( this.viewer_data_loaded || !this.activeViewer ) {
			return;
		}

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			this.activeViewer.setupLayers( this.layerGroups );
			if (flagLayer.isEnabled(availableFeatureFlags.tempMapReactLayerControls)) {
				store.dispatch( setLayerGroups( organizeLayerGroupsForControls( this.layerGroups, LAYER_TYPES_2D ) ) );
			}
		} else { // TODO: @remove viewer-layers
			let layers = [];
			if ( this.dsm_tile ) {
				let lay = this.dsm_tile;
				lay.visible = this.geotiff_tile ? false : true;
				layers.push( lay );
			}

			if ( this.geotiff_tile ) {
				let lay = this.geotiff_tile;
				layers.push( lay );
			}

			this.activeViewer.addTileLayers( ...layers );
		}
	}	// End-of viewer2DLoad

	viewerMapReady( viewerMap ): void {
		this.activeViewer = viewerMap;
		this.viewerMapLoad();
	}	// End-of viewerMapReady

	// TODO: @remove viewer-layers
	viewerMapLoad(): void {
		if ( this.viewer_data_loaded || !this.activeViewer ) {
			return;
		}

		let layers = [];
		if ( this.sparse_url ) {
			layers.push( {
				name: "sparse",
				type: "pointcloud",
				url: this.sparse_url,
				visible: this.dense_url ? false : true
			} );
		}

		if ( this.dense_url ) {
			layers.push( {
				name: "dense",
				type: "pointcloud",
				url: this.dense_url,
				visible: this.model_url ? false : true
			} );
		}

		if ( this.model_url ) {
			layers.push( {
				name: "model",
				type: "model",
				url: this.model_url,
				visible: true
			} );
		}

		if ( this.geotiff_tile ) {
			this.geotiff_tile.visible = ( this.model_url && this.dense_url ) ? false : true;
			layers.push( this.geotiff_tile );
		}
		this.activeViewer.setupLayers( layers ).then( data => {
			this.viewer_data_loaded = ( data && data.length >= 1 );
		} );
	}	// End-of viewerMapReady

	toggleView( dimension? ): void {
		if ( dimension === this.view ) {
			return;
		}

		this.loadMeasurements();

		this.view = dimension;
		this.viewer_data_loaded = false;
	}	// End-of toggleView

	toggleToolbarExpand(): void {

	}	// End-of toggleToolbarExapnd

	openExportModal() {
		const models = this.project.models;
		const groupPromises = models.map( ( model: any ) => {
			model.layers = [];
			return loadLayersFromModel( model );
		} );
		Promise.all( groupPromises ).then( rtnLayers => {
			this._dialog.open( ExportDialog, {
				data: {
					model_id: this._model_id,
					layerGroups: cloneDeep( rtnLayers ),
					project: this.project
				}
			} );
		} ).catch( err => {
			this._alertService.error( new Alert( "Error opening Export, please contact support if this continues." ) );
			console.error( err );
		} );
	}	// End-of openExportModal

	webglDetect(): boolean {

		// WebGL2 detection code from Three.js - https://github.com/mrdoob/three.js/blob/master/examples/jsm/WebGL.js
		try {
			const canvas = document.createElement( "canvas" );
			return !!( window.WebGL2RenderingContext && canvas.getContext( "webgl2" ) );
		} catch (e) {
			return false;
		}

		// This code will check for WebGL2 - https://get.webgl.org/webgl2/
		/*
		let gl;
		var canvas = document.createElement("canvas");
		try {
			gl = canvas.getContext("webgl2");
		} catch (x) {
			gl = null;
		}
		if (gl) {
			// check if it really supports WebGL2. Issues, Some browers claim to support WebGL2
			// but in reality pass less than 20% of the conformance tests. Add a few simple
			// tests to fail so as not to mislead users.
			var params = [
				{ pname: "MAX_3D_TEXTURE_SIZE", min: 256 },
				{ pname: "MAX_DRAW_BUFFERS", min: 4 },
				{ pname: "MAX_COLOR_ATTACHMENTS", min: 4 },
				{ pname: "MAX_VERTEX_UNIFORM_BLOCKS", min: 12 },
				{ pname: "MAX_VERTEX_TEXTURE_IMAGE_UNITS", min: 16 },
				{ pname: "MAX_FRAGMENT_INPUT_COMPONENTS", min: 60 },
				{ pname: "MAX_UNIFORM_BUFFER_BINDINGS", min: 24 },
				{ pname: "MAX_COMBINED_UNIFORM_BLOCKS", min: 24 }
			];

			return params.every((param) => {
				var value = gl.getParameter(gl[param.pname]);

				return (
					typeof value === "number" &&
					!Number.isNaN(value) &&
					param.min <= value
				);
			});
		}
		return false;
		*/
	}	// End-of webglDetect

	browserSupportDetect(): void {
		const detectedBrowser = detect();
		const versionNum = parseInt( detectedBrowser.version );

		switch (detectedBrowser.name) { //https://caniuse.com/webgl2
			case "chrome":
				this.browser = versionNum >= 43 ? detectedBrowser.name : "";
				break;
			case "safari":
				this.browser = versionNum >= 11 ? detectedBrowser.name : "";
				break;
			case "firefox":
				this.browser = versionNum >= 25 ? detectedBrowser.name : "";
				break;
			case "edge-chromium":
				this.browser = versionNum >= 79 ? detectedBrowser.name : "";
				break;
			case "ios":
				this.browser = versionNum >= 12 ? "safari" : "";
				break;
			case "opera":
				this.browser = versionNum >= 43 ? detectedBrowser.name : "";
				break;
		}
	}

	// This code will give the viewer a back button(needed when opening in same tab rather than new one).Leaving it here in case we want that behaviour in the future
	// navHome(): void {
	//  	this._backButtonService.popFromStack();
	//  	this._router.navigate(['projects', 'view', this._token], { queryParams: { tab: 0 } });
	// }

	loadMeasurements(): void {
		// load measurements for all Models in the Project

		this.orthoMeasurements = [];
		this.pointMeasurements = [];

		Annotations.setProject( this.project );

		this.project.models.forEach( model => {
			this._measureService.getList( model.id ).then( res => {
				this.measuments = res;
				// use reduce and *then* assign rather than just pushing to the class properties
				// to avoid forcing angular to rerender as the props get updated
				const { ortho, point } = res.reduce(
					( acc, m ) => {
						OrthoMeasure.isOrthoMeasurement( m )
							? acc.ortho.push( m )
							: acc.point.push( m );
						return acc;
					}, { ortho: [], point: [] } );

				this.orthoMeasurements = this.orthoMeasurements.concat( ortho );
				this.pointMeasurements = this.pointMeasurements.concat( point );

			} ).catch( console.error );
		} );
	}

	convertUnits( value: number, distanceType: "length" | "area", toType: "m" | "in" | "ft" ): number {
		return value * ConversionFromMeter[ toType ] * ( distanceType === "length" ? 1 : ConversionFromMeter[ toType ] );
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	openFAQ(): void {
		window.open( this.supportGuide, "_blank" );
	}

	createOrModifyShareLink = (modelId, state?): Promise<string> =>
		this._modelService
			.createOrModifyShare(modelId, state)
			.then((rtnShare) => makePublicShareLink(rtnShare.public_guid));

	openShareLinkDialog(): void {

		const dialogData = {
			createLinkFunc: (modelId) => this.createOrModifyShareLink(modelId),
			shareType: "model",
			project: this.project,
			shareablesList: this.layerGroups
		}

		// If non-public, allow toggling
		if (!this.isPublic) Object.assign(dialogData, {
			setLinkAbilityFunc: (modelId, state) => this.createOrModifyShareLink(modelId, state) }
		)

		this._dialog.open(ShareLinkComponent, { data: dialogData })
	}

	openAddLayerOptions(): void {
		this._dialog.open( AddLayerDialog, {
			data: {
				project: this.project
			}
		} );
	}

	makeToolbarClass( isActive = false ): any {
		return { "toolbar_item_active": isActive };
	}

	isMarkerState( activeViewer ): boolean {
		const state = activeViewer?.activeState;
		return state && state === VIEWERSTATE.MARKER;
	}

	isAddingState( activeViewer ): boolean {
		const editorState = activeViewer?.measurementEditorState;
		return editorState && editorState === VIEWERSTATE.MEASURE_ADDING;
	}

	isEditingState( activeViewer ): boolean {
		const editorState = activeViewer?.measurementEditorState;
		return editorState && editorState === VIEWERSTATE.MEASURE_EDITING;
	}

	isMeasureState( activeViewer ): boolean {
		const state = activeViewer?.activeState;
		return state && state === VIEWERSTATE.MEASURE;
	}

}	// End-of class ViewerComponent
