import kindOf from "kind-of";

export const isAFunction = (val) => kindOf(val) === "function";
export const isAnObject = (val) => kindOf(val) === "object";
/**
 * A function that doesn't do anything
 * The purpose of this is when the function is going to be called regardless -
 * and you don't want it to do anything.
 * For example if you are deprecating function call, you can disable it with a noop before reworking the callsite
 *
 */
export const noop = () => Promise.resolve();

export class ValidationError extends Error {
    constructor(message) {
        super(message); // (1)
        this.name = "ValidationError";
    }
}

export const configValidationError = new ValidationError(`
    The configuration must either be a function eg. \`{ yourFunctionName: () => '' }\`
    or have the property \`func\` with a function eg.
    \`{
        yourFunctionName: {
            func: () => ''
        }
    }\`
`);
