

// Export Model
export class Plan {

	public active: number;
	public additional_gp_cost: number;
	public additional_storage_cost_month: number;
	public additional_storage_cost_year: number;
	public additional_user_cost_month: number;
	public additional_user_cost_year: number;
	public created_at: any;
	public description: string;
	public gp_included_month: number;
	public gp_included_year: number;
	public id: string;
	public name: number;
	public price_month: number;
	public price_year: number;
	public publicly_available: number;
	public storage_included_per_user: number;
	public updated_at: number;
	public users_included: number;
	public duration: number;
	public period_unit: string;
	public price: number;
	public meta_data: any;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of class ExportModel
