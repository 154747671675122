
/* Imports */
import { Component, ViewChild, ElementRef, Inject } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { getFileExtension } from '@shared/services';
import { isEmpty, isNil, uniq } from "lodash";
import { MinimapPosition } from "../minimap/minimap.component";
import { Model } from "@shared/models";
import {flagLayer} from '@shared/featureFlags';

const defaultTypes = [".las", ".laz"];


// @ts-ignore - we do not have the latest ts check, so it's not aware of the new `listFormat`
const formatter = new Intl.ListFormat("en", {
	style: "long",
	type: "disjunction"
});
const formatInputTypes = (types) => formatter
	.format(types.map(x => x.toLocaleUpperCase()
			.replace('.', '')
		)
	)

@Component({
	selector: "app-viewer-import-modal",
	templateUrl: "./import.dialog.html",
	styleUrls: ["./import.dialog.scss"],
})
export class ImportDialog {

	@ViewChild('fileInput') fileInput: ElementRef;

	public showDropzoneWarning: boolean = false;
	public filesToUpload: File[] = [];
	public showFiletypeError: boolean = false;
	public acceptedImportTypes = defaultTypes;
	public minimapValues: MinimapPosition[] = [];
	public importTypesString: string = formatInputTypes(defaultTypes);

	constructor(
		public _dialogRef: MatDialogRef<ImportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {

		const importsJSON = flagLayer.isEnabled("imports-list");
		this.acceptedImportTypes = importsJSON ? JSON.parse(importsJSON) : defaultTypes;
		this.importTypesString = formatInputTypes(this.acceptedImportTypes);
		this.makeMinimapValues(data);
		this.clearUpload();
	}

	makeMinimapValues(data: Array<any> = []) {
		if (!isEmpty(data)) {
			this.minimapValues = data.reduce((acc, model) => {
				const pos = this.getModelLayerPositions(model);
				if (pos?.length) acc.push(pos[0]);
				return acc;
			}, []);
		}
	}

	getModelLayerPositions(model: Model): MinimapPosition[] {
		const modelLayers = ["cesium_files", "geotiffs", "layers", "potree_files"];
		return modelLayers.map(layerName => {
			const layers = model[layerName];
			return layers.map(layer => ({
				name: model.name,
				latitude: layer.north,
				longitude: layer.west
			}));
		})
			.flat()
			.filter((layer) => {
			return !isNil(layer.latitude) && !isNil(layer.longitude);
		});
	}

	close(rtn: any = null): void {
		this._dialogRef.close(rtn);
	} // End-of close

	dropzoneUpload(files): void {
		if (files.length) {
			this.filesChanged(files);
		} else {
			this.showDropzoneWarning = true;
		}
	}

	filterImportAcceptedTypes = (files: File[], fileTypes: string[] = this.acceptedImportTypes ): Array<any> =>
		files.filter(file =>
			fileTypes.some(type =>
				file?.name && type && file.name.toLocaleLowerCase().includes(type.toLocaleLowerCase())
			)
	);

	filesChanged(input: FileList | File[] = this.filesToUpload): void {
		this.showFiletypeError = false;

		// Ensure files are of the proper extension, if not, filter and alert
		if (input.length) {
			const mergedFiles = uniq(this.filesToUpload.concat([...input]));
			const filteredFiles = this.filterImportAcceptedTypes(mergedFiles);
			this.showFiletypeError = filteredFiles.length !== input.length;
			this.filesToUpload = filteredFiles;
		}

	}	// End-of filesChanged

	submitImport() {
		if (this.filesToUpload.length) {
			const output = {
				files: this.filesToUpload,
			};
			this._dialogRef.close( output );
		}
	}

	removeFile(index: number): void {
		this.filesToUpload.splice(index, 1);
	}

	clearUpload() {
		this.filesToUpload = [];
	}

	getFileExtension(fileName: string): string {
		return getFileExtension(fileName);
	}
}
