
/* Imports */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

/* Services */
import { AuthenticationService, ProjectService } from '../../shared/services';

@Component({
	selector: 'app-rename-modal',
	templateUrl: './rename.modal.html',
	styleUrls: ['./rename.modal.scss']
})
export class RenameModal implements OnInit {

	public inData: any;
	public renameForm: FormGroup;
	private _existingNames: Array<string>;

	constructor (
		private _authService: AuthenticationService,
		private _projectService: ProjectService,
		private _formBuilder: FormBuilder,
		public _dialogRef: MatDialogRef<RenameModal>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.inData = data;
		this.renameForm = this.createRenameForm(this.inData);
	}

	ngOnInit() {
	}	// End-of ngOnInit

	createRenameForm(inData: any): FormGroup {
		this.isUniqueName('');

		let formConfig = {
			id: [inData.id, Validators.required],
			name: [inData.name ? inData.name : '', Validators.required]
		};
		return this._formBuilder.group(formConfig);

	}	// End-of createRenameForm

	async onRename() {
		this.renameForm.value.name = this.renameForm.value.name.trim();

		if(await this.isUniqueName(this.renameForm.value.name)) {
			this._dialogRef.close({ name: this.renameForm.value.name, id: this.renameForm.value.id });
		} else {
			this.renameForm.controls['name'].setErrors({'nameTaken': true});
		}
	}

	async isUniqueName(name: string): Promise<boolean> {

		if(this._existingNames) {
			return this._existingNames.indexOf(name) == -1;
		} else { // request specific names list
			if(this.inData.organization_id) {
				return await this._projectService.getList(this.inData.organization_id).then(projectList => {
					this._existingNames = projectList.map(proj => proj.name);
					return this._existingNames.indexOf(name) == -1;
				});
			} else if(this.inData.project_id) {
				return await this._projectService.getById(this.inData.project_id).then(project => {
					this._existingNames = project.models?.map(model => model.name);
					return this._existingNames.indexOf(name) == -1;
				});
			} else {
				return true;
			}
		}
	}

}	// End-of class TestComponent
