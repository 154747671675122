import { Injectable } from '@angular/core';
import {Project} from '../models';

@Injectable({
	providedIn: 'root'
})
export class SelectedProjectService {
	public defaultValue: { name: string, id: number, favorited: boolean, organization: {id: number, name: string} } = {
		name: 'Unknown', id: null, favorited: false, organization: {id: 0, name: 'Unknown'}
	};
	public selectedProject: Project | { name: string, id: number, favorited: boolean, organization: {id: number, name: string} } = this.defaultValue;
	constructor() { }

	setSelectedProject(project: Project): void {
		if (typeof project?.id === "string") project.id = parseInt(project.id);
		this.selectedProject = project;
	}
	getSelectedProject(): Project | { name: string, id: number, favorited: boolean, organization: {id: number, name: string} } {
		return this.selectedProject;
	}
	clearSelection(): void {
		this.selectedProject = this.defaultValue;
	}

	isEmpty(): boolean {
		return this.selectedProject === this.defaultValue;
	}
}
