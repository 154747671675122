import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertMessageObject } from "@shared/models/alert.model";
import { ViewerLayer, ViewerLayerGroup } from "@app/pages/viewer";
import { Project } from "@shared/models/project.model";
import { Alert } from "@shared/models/alert.model";
import { AlertService } from "@shared/services/alert.service";
import { ExportService } from "@shared/services/export.service";
import { UtilsService } from "@shared/services/utils.service";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";
import { isEmpty } from "lodash";
import { map, startWith } from 'rxjs/operators';

const FEtoAPIlayerAPITypes = {
	"sparse point cloud": "sparse_cloud",
	"dense point cloud": "dense_cloud",
	"3D model": "model",
	"orthomosaic": "orthomosaic",
	dsm: "dem",
};

const sparsePointcloudTypes = [];
const densePointcloudTypes = [".laz"];
const meshTypes = [];
const geoTiffTypes = [".tiff"];

const defaultFileFormats = {
	"sparse point cloud": sparsePointcloudTypes,
	"dense point cloud": densePointcloudTypes,
	"3D model": meshTypes,
	"orthomosaic": geoTiffTypes,
	dsm: geoTiffTypes,
};

@Component({
	selector: "app-viewer-export-modal",
	templateUrl: "./export.dialog.html",
	styleUrls: ["./export.dialog.scss"],
})
export class ExportDialog implements OnInit {
	public parentProject: Project;
	public model_id: number;
	public layerGroups: Array<ViewerLayerGroup>;

	/* Export */
	public exportFormGroup: FormGroup;

	public epsgsOptions: any[] = [];
	public filteredEpsgOptions: Observable<any[]>;
	public flagAPIFileFormats: any;

	constructor(
		// private _epsg: epsg,
		private _alertService: AlertService,
		private _utilService: UtilsService,
		private _formBuilder: FormBuilder,
		private _exportService: ExportService,
		private _router: Router,
		private _route: ActivatedRoute,
		public _dialogRef: MatDialogRef<ExportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.model_id = data.model_id;
		this.parentProject = data.project;
		this.layerGroups = data.layerGroups;

		this.flagAPIFileFormats = flagLayer.isEnabled("exports-list");

		this.layerGroups = this.layerGroups.filter((group) => {
			return group.type !== "import";
		})

		this.layerGroups = this.layerGroups.filter((group) => {
			return !(group as any).descriptors.exports_disabled;
		})

		this.layerGroups.forEach((group) => {
			group.layers = group.layers.filter(
				(layer) => this.getLayerExportFileTypes(layer.type).length > 0 
			);
			group.layers = group.layers.filter(layer => layer.type !== "analysis")
		});
		this.setupExportForm();

		if (flagLayer.isEnabled(availableFeatureFlags.exportEPSG)) {
			this._utilService.getEPSGList().then(({coordinate_systems}) => {
				this.epsgsOptions = Object.entries(coordinate_systems);
				this.filteredEpsgOptions = this.exportFormGroup.controls['epsgControl'].valueChanges.pipe(
					startWith(""),
					map((value) =>
						((typeof value === 'string') ? this.filterEpsg(value) : [])
					)
				);
			});
		} else {
			this._utilService.getEPSGList().then((d) => {
				this.epsgsOptions = Object.entries(d.coordinate_systems);
				this.exportFormGroup.patchValue({
					epsgControl: this.epsgsOptions.find(
						(x) => x[0] === "4326"
					),
				});
			});
		}
	} // End-of constructor

	ngOnInit() {} // End-of ngOnInit

	filterEpsg(name: string): any {
		if (typeof name === "string") {
			let filterValue = name.toLowerCase();

			return this.epsgsOptions
				.filter((option) => {
					return (
						option[0].toLowerCase().includes(filterValue) ||
						option[1].toLowerCase().includes(filterValue)
					);
				})
				.slice(0, 15);
		} else {
			return null;
		}
	} // End-of filterEpsg

	displayAutoComplete(option: any): string {
		return option && option.text ? option.text : "";
	} // End-of displayFn

	close(rtn: any = null): void {
		this._dialogRef.close(rtn);
	} // End-of close

	setupExportForm(): void {
		if (flagLayer.isEnabled(availableFeatureFlags.viewerLayers)) {
			let formControls = {
				epsgControl: [null, Validators.required],
			};
			this.layerGroups.forEach((group) => {
				group.layers.forEach((layer) => {
					layer.exportForm = {
						check: `${group.id}_${layer.id}_check`,
						format: group.id + "_" + layer.id + "_format",
					};

					formControls[layer.exportForm.check] = false;
					formControls[layer.exportForm.format] =
						this.getLayerExportFileTypes(layer.type)[0];
				});
			});
			this.exportFormGroup = this._formBuilder.group(formControls);
		} else {
			// TODO @remove viewer-layers
			this.exportFormGroup = this._formBuilder.group({
				dense_cloud: [false],
				dsm: [false],
				orthomosaic: [false],
				model: [false],
				epsgControl: [null, Validators.required],
			});
		}
	} // End-of setupExportForm

	exportFiles(): void {
		if (!flagLayer.isEnabled(availableFeatureFlags.disableExports)) {
			const exportAlert: AlertMessageObject[] = [
				{
					text: "Export request has been sent. File will be added to this project's ",
					isLink: false,
				},
				{
					text: "exports",
					isLink: true,
					linkRoute: ["projects", "view", this.parentProject.id],
					queryParams: { tab: "exports" },
				},
				{
					text: " page.",
					isLink: false,
				},
			];

			if (flagLayer.isEnabled(availableFeatureFlags.viewerLayers)) {
				let exportRequests = {};
				let layersToExport = this.getLayersToExport();

				layersToExport.forEach((layer) => {
					exportRequests[layer.model_id] = [].concat(
						this.exportRequestForLayer(layer),
						exportRequests[layer.model_id] ?? []
					);
				});

				Object.keys(exportRequests).forEach((model_id) => {
					let layersToRequest = exportRequests[model_id];

					let exportRequest = {
						model_id: +model_id,
						coordinate_system: {
							epsg: this.exportFormGroup.value.epsgControl[0],
							description: this.exportFormGroup.value.epsgControl[1],
						},
						layers: layersToRequest,
					};

					this._exportService.requestByLayers(exportRequest).then(
						() => {
							this.close();
							this._alertService.success(new Alert(exportAlert));
						},
						(err) => {
							console.error(err);
						}
					);
				});
			} else {
				// TODO @remove viewer-layers
				let exportRequest = {
					dense_cloud: this.exportFormGroup.value.dense_cloud,
					dsm: this.exportFormGroup.value.dsm,
					orthomosaic: this.exportFormGroup.value.orthomosaic,
					model: this.exportFormGroup.value.model,
					coordinate_system: {
						epsg: this.exportFormGroup.value.epsgControl[0],
						description: this.exportFormGroup.value.epsgControl[1],
					},
				};

				this._exportService.request(this.model_id, exportRequest).then(
					() => {
						this.close();
						this._alertService.success(new Alert(exportAlert));
					},
					(err) => {
						console.error(err);
					}
				);
			}
		}
	} // End-of exportFiles

	exportFormIsValid(): boolean {
		if (flagLayer.isEnabled(availableFeatureFlags.viewerLayers)) {
			return (
				this.getLayersToExport().length
				&& this.exportFormGroup.valid
				&& this.isValidExportCoordinates()
			);
		} else {
			// TODO @remove viewer-layers
			let control = this.exportFormGroup;
			return (
				(control.get("dense_cloud").value ||
					control.get("dsm").value ||
					control.get("orthomosaic").value ||
					control.get("model").value) &&
				control.valid
			);
		}
	} // End-of exportFormValidator

	getLayersToExport(): Array<ViewerLayer> {
		return this.layerGroups.flatMap((group) => {
			return group.layers.filter(
				(layer) =>
					this.exportFormGroup.controls[layer.exportForm.check].value
			);
		});
	}

	/**
	 * Reduces a ViewerLayer object into the format of an exports request.
	 * @param layer ViewerLayer to reduce
	 * @returns {
			layer_id: number,
			class: string, // potree, cesium, or geotiffs
			type: string, // dense point cloud, sparse point cloud, 3d model, orthomosaic, dsm
			format: string // file format
			}
	 */
	exportRequestForLayer(layer: ViewerLayer): any {
		return {
			layer_id: layer.id,
			class: layer.fileType,
			type: this.translateToAPILayerTypes(layer.type),
			format: this.exportFormGroup.controls[layer.exportForm.format]
				.value,
		};
	}

	getLayerExportFileTypes(layerType: string): Array<string> {
		const layerTypeAPI = FEtoAPIlayerAPITypes[layerType];

		if (isEmpty(this.flagAPIFileFormats)) {
			return defaultFileFormats[layerType] ?? ["unknown"];
		}
		return this.flagAPIFileFormats[layerTypeAPI] ?? ["unknown"];
	}

	isValidExportCoordinates(): boolean {
		return Array.isArray(this.exportFormGroup?.value?.epsgControl);
	}

	translateToAPILayerTypes(layerType: string): string {
		return FEtoAPIlayerAPITypes[layerType] ?? ["unknown"];
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	disableExport() {
		return (
			!this.exportFormIsValid() ||
			!this.parentProject ||
			flagLayer.isEnabled(availableFeatureFlags.disableExports)
		);
	}
} // End-of class ExportModal
