
<div class="container" [ngClass]="{'ms-photo-container': msDetail}">

	<div class="floating-container" *ngIf="showInfo && infoText" [ngClass]="{'bottom': !(GCPList && GCPList.complete)}">
		<div class="info-container">
			<span> {{infoText}} </span>
			<button mat-button matTooltip="close" (click)="showInfo = false;">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>

	<ng-container *ngIf="showNav && imageList">
		<div data-testid="navigate-before" class="nav-button-left" [ngClass]="{'disabled' : !hasPrevious}" style="margin-right: 1em;">
			<button (click)="previousPhoto()">
				<mat-icon>navigate_before</mat-icon>
			</button>
		</div>
	</ng-container>

	<div data-testid="loading-overlay" class="loading-overlay" *ngIf="isLoading">
		<div class="dot-spin"></div>
	</div>

	<div #imageElementRef class="panzoom-container" (click)="clickEvent($event); $event.stopPropagation();">

		<img class="image-container"
			 id="image-container"
			 [ngClass]="{'scale-view' : !GCPList, 'editing-gcp': GCPList?.active}"
			 *ngIf="imageInfo?.large_url"
			 [lazyLoad]="getURL(imageInfo)"
			 [errorImage]="getErrorURL(imageInfo)"
			 (onStateChange)="onImageLoad($event)">

		<ng-container *featureEnabled="'!disable-gcps'">
			<ng-container *ngIf="GCPList?.complete">
				<ng-container *ngFor="let placed of GCPList.placed; let i = index;">
					<div data-testid="click-relocate" class="pointer-container" [ngStyle]="placedGCPStyle(placed)" [ngClass]="{'hidden' : isLoading}" matTooltip="click to relocate" (click)="editGCP(i); $event.stopPropagation();">
						<mat-icon class="pointer-icon">gps_not_fixed</mat-icon>
						<span class="pointer-dot"></span>
						<span class="placed-number">{{i + 1}}</span>
					</div>
				</ng-container>

				<div *ngIf="GCPList.active" class="disabled-pointer-container" [ngStyle]="placedGCPStyle(GCPList.active)" [ngClass]="{'hidden' : isLoading}">
					<mat-icon class="pointer-icon">gps_not_fixed</mat-icon>
					<span class="pointer-dot"></span>
				</div>
			</ng-container>

			<ng-container *ngIf="scaleConstraint && scaleConstraint.images">
				<ng-container *ngFor="let image of scaleConstraint.images">
					<span *ngIf="image.image_id === selectedImage.id" class="dot" [ngStyle]="placedConstraintStyle(image)">
						<span class="remove-container">POINT {{image.point === 1 ? 'A' : 'B'}} <button mat-button (click)="removeConstraint(image.point); $event.stopPropagation();"><mat-icon>close</mat-icon></button></span>
					</span>
				</ng-container>
			</ng-container>
		</ng-container>

		<span *ngIf="placeholderDot" class="placeholder-dot" [ngStyle]="placedConstraintStyle(placeholderDot)"></span>

		<ng-container *ngIf="!(GCPList && GCPList.complete)">
			<button class="hidden" #constraintMenuTrigger [matMenuTriggerFor]="constraintMenu"></button>
			<mat-menu #constraintMenu="matMenu" menuWidth="200px" (menuState)="clearPlaceholder($event);">
				<div id="constraintMenu" class="constraint-menu">
					<div class="menu-title">Assign point:</div>
					<div class="menu-text">
						<button mat-button (click)="confirmConstraint(1);" matMenuClose class="option-block">
							<span class="top-dot"></span>
							<span>Point A</span>
							<!-- <span class="placed-text" *ngIf="scaleConstraint['point_a']">Placed</span> -->
						</button>
						<button mat-button (click)="confirmConstraint(2);" matMenuClose class="option-block">
							<span class="bottom-dot"></span>
							<span>Point B</span>
							<!-- <span class="placed-text" *ngIf="scaleConstraint['point_b']">Placed</span> -->
						</button>
					</div>
				</div>
			</mat-menu>
		</ng-container>
	</div>

	<span #confirmationMenuTrigger [matMenuTriggerFor]="confirmationMenu"></span>

	<mat-menu #confirmationMenu="matMenu" menuWidth="200px">
		<div id="menu" class="menu-view">
			<div class="H6Black700Semibold" class="menu-title">Confirm placement?</div>
			<div class="menu-text">
				<button mat-button class="link" (click)="confirmGCP()" matMenuClose>YES</button>
				<button mat-button class="link" (click)="cancelGCP()" style="margin-left: 24px;" matMenuClose>NO</button>
			</div>
		</div>
	</mat-menu>

	<ng-container *ngIf="showNav && imageList">
		<div data-testid="navigate-next" class="nav-button-right" [ngClass]="{'disabled' : !hasNext}">
			<button (click)="nextPhoto()">
				<mat-icon>navigate_next</mat-icon>
			</button>
		</div>
	</ng-container>

	<div class="control-buttons-left" [ngClass]="{'disabled': isLoading}">
		<button mat-stroked-button color="primary" (click)="resetImageViewer()">
			<mat-icon>filter_center_focus</mat-icon>
		</button>
		<button mat-stroked-button color="primary" (click)="zoomIn()">
			<mat-icon>zoom_in</mat-icon>
		</button>
		<button mat-stroked-button color="primary" (click)="zoomOut()">
			<mat-icon>zoom_out</mat-icon>
		</button>
	</div>
	<div class="control-buttons-right" [ngClass]="{'disabled': isLoading}">
		<button mat-stroked-button color="primary" (click)="openInfo()" class="tablet-visible">
			<mat-icon>info</mat-icon>
		</button>
	</div>

</div>

<div class="ms-band-list-container" *ngIf="msDetail">
	<div class="band-label-div H5Black500">
		Available Bands:
	</div>
	<ng-container *ngFor="let batchImg of batchImages">
		<div class="ms-band" id="{{batchImg.id}}" (click)="selectImage(batchImg)" [ngClass]="{'default-active-image': !activeImage && srcImageId === batchImg.id, 'active-image': activeImage === batchImg.id}">
			<img [src]="rootURL + batchImg.small_url" />
			<span class="H7Black700Regular ellipsis">
				{{batchImg.name}}
			</span>
		</div>
	</ng-container>
</div>
