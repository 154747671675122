<ngx-dropzone
	id="dropzone"
	[class]="class"
	(change)="onSelect($event)"
	(dragover)="onDragOver(); $event.stopPropagation();"
	processDirectoryDrop
	disableClick
>
	<ngx-dropzone-label>
		<ng-content></ng-content>

		<ng-container *ngIf="isHovered">
			<ng-container *ngIf="hoverTemplate">
				<ng-container *ngTemplateOutlet="hoverTemplate"></ng-container>
			</ng-container>
			<ng-container *ngIf="!hoverTemplate">
				<div class="hover-container">
					<mat-icon>cloud_upload</mat-icon>
					<div class="H6Black700Regular">Drop your files to upload to Mapware.</div>
				</div>
			</ng-container>
		</ng-container>

		<div class="drag-container"
			 [ngClass]="{'hovered': isHovered, 'no-border': noBorder}"
			 (dragleave)="onDragLeave(); $event.stopPropagation();">
		</div>
	</ngx-dropzone-label>
</ngx-dropzone>
