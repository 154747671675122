
/* Imports */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, OnChanges } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

/* Models */
import { Project } from '../../../shared/models'

/* Services */
import { ProjectService, GlobalService, ModelService, UtilsService } from '../../../shared/services';

@Component({
	selector: 'mat-list-grid-item',
	templateUrl: './list-grid-item.component.html',
	styleUrls: ['./list-grid-item.component.scss']
})

export class AerialListGridItem implements OnInit {

	@ViewChild(MatMenuTrigger) itemMenuTrigger: MatMenuTrigger;
	@ViewChild(MatMenu) itemMenu: MatMenu;

	// Inputs
	@Input('showCreatedAt') showCreatedAt: any;
	@Input('project') set project(proj: Project) {
		if (proj) { this.setup(proj); }
	}
	public _project: Project;
	get project(): Project { return this._project; }

	public contextMenuPosition = { x: '0px', y: '0px' };
	public usePosition: boolean = true;

	// Outputs
	@Output('clicked') click: EventEmitter<any> = new EventEmitter();
	@Output('eventTrigger') eventTrigger: EventEmitter<any> = new EventEmitter();

	public tags: any[] = [];
	public tagsText: string = '';
	public preview_image: string;
	public isProcessing: boolean = false;
	public menuOpen: boolean = false;
	public isMobile: boolean = false;
	public rootURL = GlobalService.databaseApiUrl;

	constructor (
		private _projectService: ProjectService,
		private _modelService: ModelService,
		private _utilsService: UtilsService,
	) {

		this.isMobile = this._utilsService.getIsMobile();

	}	// End-of constructor

	ngOnInit() {

	}	// End-of OnInit


	setup(project): void {

		const newTags = project.descriptors?.tags || []; // Ensure it's always at least an empty array
		this.tags = this.tags.concat(newTags); // cleanest way to combine the two arrays
		this.tagsText += newTags.slice(0, 3).join(", ");
		this._project = project;

	}	// End-of setup

	clickedImage(e): void {

		this.click.emit(e);
		e.stopPropagation();

	}	// End-of clickedImage

	clickedIcon(e, text): void {
		this.eventTrigger.emit({project: this._project, text: text});
		e.stopPropagation();
	}

	clickedTitle(e): void {

		this.click.emit(e);
		e.stopPropagation();

	}	// End-of clickedTitle

	openMenu(e): void {

		e.preventDefault();
		e.stopPropagation();

		this.menuOpen = true;
		this.contextMenuPosition.x = e.clientX + 'px';
		this.contextMenuPosition.y = e.clientY + 'px';
		this.itemMenuTrigger._handleClick(e);
	}

	contextMenuStyle(): any {
		return {
			left: this.contextMenuPosition.x,
			top: this.contextMenuPosition.y,
		};
	}

	getSharedTooltip = (project) => project['shared_with_user'] ? 'Shared with you' : 'Shared with others';

}	// End-of class AerialListGridItem
