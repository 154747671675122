import { MODEL_STATUS_EXPLANATION_PARTIAL } from "./constants";
import { isLayerRendered } from "./isLayerRendered";
import {hasValidSource} from "./hasValidSource";

export function constructPartialExplination(
  { geotiffs, potree_files, cesium_files },
  betaLayersEnabled
) {
  const hasGeotiffs = isLayerRendered(
    geotiffs.filter(betaLayersEnabled ? hasValidSource : () => true)
  );
  const hasPotree = isLayerRendered(
    potree_files.filter(betaLayersEnabled ? hasValidSource : () => true)
  );
  const hasCesium = isLayerRendered(
    cesium_files.filter(betaLayersEnabled ? hasValidSource : () => true)
  );

  let availableRenderings = [];
  if (hasCesium) availableRenderings.push("3D model");
  if (hasPotree) availableRenderings.push("3D point cloud");
  if (hasGeotiffs) availableRenderings.push("2D map");

  // @ts-ignore - we do not have the latest ts check, so it's not aware of the new `listFormat`
	const formatter = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction"
  });

  return (
    MODEL_STATUS_EXPLANATION_PARTIAL +
    formatter.format(availableRenderings) +
    " renderings."
  );
}
