/* Imports */
import {
	Component,
	OnInit,
	ViewChild,
	Input,
	ElementRef,
	ChangeDetectorRef,
	OnDestroy, Output, EventEmitter
} from "@angular/core";
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfirmationModal, MoveImagesDialog, UploadPhotosDialog} from "@app/components";
import {sortByNewestToOldest, UtilsService} from '@app/shared/services/utils.service';
import {RenameModal} from '@app/pages/rename';
import _, {isEmpty, isNil, cloneDeep, keyBy, isEqual} from 'lodash';
import pluralize from "pluralize";

/* Models */
import { Project, Alert, Image, ImageBatch } from '@shared/models';
import retry from 'async-retry';

/* Services */
import {
	byId,
	not,
	AlertService,
	AuthenticationService,
	OrganizationService,
	DownloadService,
	FavoriteService,
	ImageService,
	IMAGESIZES,
	PermissionService,
	UploadService,
	FinanceService,
	getAcceptedImageTypesString,
	getImagesGroupedByBatchId, convertFilesByType
} from 'src/app/shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';
import { first } from "rxjs/operators";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";
import { MatMenuTrigger } from "@angular/material/menu";
import * as asyncjs from "async";

const fulfilledStatus = "fulfilled";

const downloadAttempts = 4;
const refreshTimeout = 10000;

@Component({
	selector: 'app-photos-projects',
	templateUrl: './photos.projects.component.html',
	styleUrls: ['./photos.projects.component.scss']
})
export class PhotosProjectsComponent implements OnInit, OnDestroy {

	/* ViewChild */
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	@ViewChild('fileInput') uploadInput: ElementRef;

	@ViewChild(MatSort) set sort(s: MatSort) {
		if (s) {
			this.dataSource.sort = s;
		}
	}

	@ViewChild(MatPaginator) paginator: MatPaginator;

	/* Input */
	@Output() projectChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() set project(proj: Project) {
		if (proj && (proj.id !== this._project?.id)) {
			this.setup(proj);
		}
	}

	get project(): Project {
		return this._project;
	}

	public _project: Project;

	public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	public loadingData: MatTableDataSource<any> = new MatTableDataSource<any>();
	public selection = new SelectionModel<any>(true, []);

	public user: any;
	public userPermission: boolean = false;
	public imageList: Array<any> = [];
	public favoritesList: Array<any> = [];
	public srcImgBands: any = {};
	public batch: any = {};
	public batches: Array<any> = [];
	public isActive: Array<boolean> = [false];
	public selectedBatch: ImageBatch = null;
	public menuSubscriptions: Subscription[] = [];
	public displayedColumns: string[] = ['select', 'name', 'mp', 'size', 'date_taken', 'options'];

	public isLoading = true;
	public isDownloading = false;
	public isUploading = false;
	public isExpired: boolean = true;
	public gridDisplay: boolean = false;

	public projectListSubscription: any;
	public batchPromise: Promise<ImageBatch[]> = null;
	public failedDownloads: Array<any> = [];
	public getAcceptedImageTypesString = getAcceptedImageTypesString;

	constructor(
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
		private _downloadService: DownloadService,
		private _orgService: OrganizationService,
		private _financeService: FinanceService,
		private _imageService: ImageService,
		private _favoriteService: FavoriteService,
		private _uploadService: UploadService,
		private _utilsService: UtilsService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
	) {
		this.loadingData.data = Array(10)
			.fill({ select: 0, name: 0, mp: 0, size: 0, date_taken: 0, options: 0 });

	}	// End-of constructor

	ngOnInit() {
		this.user = this._authService.user;
	}

	ngOnDestroy() {
		this.menuSubscriptions.forEach(s => s.unsubscribe());
		this.projectListSubscription?.unsubscribe();
	}

	setup(proj: Project): void {

		// if this subscription exists, unsubscribe
		this.projectListSubscription?.unsubscribe();
		this.batchPromise = null;

		this.imageList = [];
		this._project = proj;

		this._permissionService.getUserPermissions(this._project.organization_id)
			.then(permission => {
				this.checkUserRole(permission);
			})

		// TODO: Reimplement when Subscription management is added
		this.isExpired = false;

		if (this.v2FlagEnabled()) {
			this.getBatchesV2(proj);
		} else {
			if (proj.batches) {
				proj.batches = proj.batches.sort(sortByNewestToOldest);
			}
			this.handleBatches(proj.batches);
		}

		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({ renderState }) => {
			this.trackAndUpdateUploads(renderState);
		});

	}	// End-of setup

	checkUserRole(permission) {
		this.userPermission = (permission.admin || permission.process);
	}

	getBatchesV2(project): void {
		// Check if data has already been loaded
		if (project.batches?.length && !this.v2FlagEnabled()) {
			this.handleBatches(project.batches);
		} else {
			this._imageService.getBatchListV2({ project_id: project.id }).then(rtnBatches => {
				if (isEmpty(rtnBatches)) {
					rtnBatches = [];
				}
				// If the batches have already been loaded and there are no changes, no need to update.
				if (!this._imageService.checkBatchesV2(this.batches, rtnBatches)) {
					project.batches = rtnBatches;
					this.handleBatches(rtnBatches);
				} else {
					this.isLoading = false;
				}

				this.getFavorites();
			}).catch(err => {
				this._alertService.error(new Alert('Could not get your Image Groups, please reload the tab or contact support if this persists.'));
				console.error(err);
			})
		}
	}

	trackAndUpdateUploads(renderState) {

		const project = this.project ? renderState.projectList?.find(byId(this.project.id)) : null;

		if (project) {
			const imageList = project.files.filter(x => x.uploadType === "Image");
			if (imageList?.length) this.updateBatchesByImages(imageList);
		}
	}

	async updateBatchesByImages(imageList: Array<any>) {

		const imagesGroupedByBatchId = getImagesGroupedByBatchId(imageList);

		const existingBatches = cloneDeep(this.batches);

		const batchPromises = Object.entries(imagesGroupedByBatchId)?.map(([key, images]) => {

			const batchId = parseInt(key);
			const existingBatch = existingBatches.find(batch => batch.id === batchId);

			return this.checkBatch(batchId, existingBatch)
				.then(rtnBatch => this.updateBatchImages(rtnBatch, images));
		})

		Promise.all(batchPromises).then(rtnBatches => {
			const newBatches = rtnBatches.filter(x => !isNil(x?.id));
			const merged = _.values(_.merge(_.keyBy(existingBatches, 'id'), _.keyBy(newBatches, 'id')));
			this.handleBatches(merged);
		})
	}

	async checkBatch(batch_id, batch?): Promise<ImageBatch> {
		if (!isEmpty(batch) && (batch.id === batch_id)) return batch;
		return this._imageService.getBatchV2(batch_id);
	}

	updateBatchImages(existingBatch, images) {
		if (existingBatch.images?.length) {
			const storeImages = cloneDeep(existingBatch.images);
			existingBatch.images = Object.values(images.reduce((acc, newOrUpdatedImage) => {
				newOrUpdatedImage.trash = 0;
				const existingById = acc?.find(byId(newOrUpdatedImage.id));
				if (
					isNil(existingById?.id)
				) {
					const existingByName = acc?.find(x => x.name === newOrUpdatedImage.name);
					if (!existingByName?.id) {
						acc.push(newOrUpdatedImage);
					}
				} else if (!isEqual(existingById, newOrUpdatedImage)) {
					Object.assign(existingById, newOrUpdatedImage);
				}
				return acc;
			}, storeImages));
		} else {
			existingBatch.images = images;
		}
		return existingBatch;
	}

	handleBatches(batches): void {

		const batchPromises = batches?.map(newBatch => {

			const existing = this.batches.find(byId(newBatch.id));
			return this.checkBatch(newBatch.id, existing).then(rtnBatch => {
				return this.filterAndAssignChildren(Object.assign({}, rtnBatch, newBatch));
			})
		});

		Promise.all(batchPromises).then(rtnBatches => {
			const newBatches = rtnBatches.filter(x => !isNil(x?.id));
			if (!this.batches.length) {
				this.batches = newBatches;
			} else {
				newBatches.forEach(newBatch => {
					const existing = this.batches.find(byId(newBatch.id));
					if (isEmpty(existing)) {
						this.batches.push(newBatch);
					} else {
						Object.assign(existing, newBatch);
					}
				})
			}
			const emitProject = Object.assign({}, this._project, { batches: this.batches });
			this.projectChange.emit({ project: emitProject, type: "batches" });
			this.isLoading = false;
			this._cdr.detectChanges();
		})

	}	// End-of handleBatches

	updateBatch(batch) {
		this.batch = batch;

		if (batch.sensor !== null) {
			this.srcImgBands = batch.images.reduce((acc, image) => {
				if (image.source_image_id !== null && !acc[image.source_image_id]) {
					acc[image.source_image_id] = batch.images?.filter((img) => {
						return image.source_image_id === img.source_image_id;
					})?.length ?? 0;
				}
				return acc;
			}, {});
		}

		const batches = cloneDeep(this.batches);
		let batchInd = batches.findIndex(x => x.id === batch.id);
		if (batchInd < 0 || !isEqual(batches[batchInd], batch)) {
			Object.assign(this.batches[batchInd], batch);
			this.project.batches = this.batches;
		}
		return this.filterAndAssignChildren(this.batches[batchInd]);
	}



	getFavorites(): void {

		this._favoriteService.getList().then(favoritesList => {
			if (favoritesList.hasOwnProperty("images") && favoritesList.images.length) {
				this.favoritesList = favoritesList.images.reduce(
					(acc, { id, image_id }) =>
						Number.isInteger(image_id) // id can be `0` which evaluates to false when checked with something like `!!id`
							? acc.concat({ fav_id: id, image_id })
							: acc,
					[]
				);
			} else {
				this.favoritesList = [];
			}
		});
	}

	filterAndAssignChildren(batch: any): any {
		batch.images = batch.images?.filter(x => !x.trash && isNil(x.source_image_id)) ?? [];
		batch["children"] = batch.images;
		return batch;
	}

	clicked({ batch_id, guid }) {

		if (guid) { // Uploading files will not have the guid yet
			this._router.navigate(['photos', batch_id, guid]);
		}

	}	// End-of clicked

	filesChanged(files: Array<any>): void {
		const batchId = this.selectedBatch?.id;
		this.selectedBatch = null;
		if (files?.length) {
			this.uploadFiles(files, batchId);
		}
		// Reset value to allow same file to be uploaded in the event of a failure
		this.uploadInput.nativeElement.value = '';

	}	// End-of filesChanged

	uploadToBatch(batch: ImageBatch): void {
		this.selectedBatch = batch;
		this.uploadInput.nativeElement.click();
	}

	uploadFiles(files, batchId, batchName?): void {

		const uploadItems = convertFilesByType([...files]);
		uploadItems.forEach(uploadItem => {
			Object.assign(uploadItem.uploadInfo, {
				batch: { id: batchId },
				project: this.project
			})
		});

		// TODO: @remove temp-create-batches
		if (!flagLayer.isEnabled(availableFeatureFlags.createBatches)) batchId = this.batches[0]?.id ?? null;
		this._uploadService.__uploadFiles({
			uploadItems,
			project_id: this.project.id,
			org_id: this.project.organization_id,
			batch_id: batchId,
			batch_name: batchName,
			project: this.project
		}
			// TODO: Made only null to create a new batch with each upload
		).catch(e => {
			console.error(e);
			this.isUploading = false;
			this.isLoading = false;
			this._alertService.notify("We're sorry. Something went wrong with the upload. Please reload the page and try again.", "error")
		})
	}

	isFavorite(item): boolean {
		return this.favoritesList.some(fav => fav.image_id === item.id);
	}

	toggleSelectAll(e, batch?): void {

		this.selection = new SelectionModel<Element>(true, e.checked ? batch.children : []);
		this._cdr.detectChanges();

	}	// End-of selectAll

	allChildrenSelected(children): boolean {
		return children?.length && children.every(child => this.selection.isSelected(child))
	}

	favorite(item): void {

		if (this.isFavorite(item)) {
			this._favoriteService.remove(this.favoritesList.find(x => x.image_id === item.id).fav_id).then(() => {
				this.favoritesList = this.favoritesList.filter(x => x.image_id != item.id);
				this._alertService.notification(new Alert('Removed from Favorites'));
				analyticsLayer.trackFavorite("remove", item, "Image");
			});
		} else {
			this._favoriteService.create({ image_id: item.id }).then(rtnData => {
				this.favoritesList.push({ fav_id: rtnData.favorite_id, image_id: item.id });
				this._alertService.notification(new Alert('Added to Favorites', 'star_border'));
				analyticsLayer.trackFavorite("add", item, "Image");
			});
		}

	}	// End-of favorites

	openMoveItemsDialog(selectedImages: Image[]): void {
		const fromBatch = this.batches.find(byId(selectedImages[0].batch_id));
		const batches = this.batches.filter(x => x.id !== fromBatch.id);

		const dialogRef = this._dialog.open(MoveImagesDialog, {
			data: {
				batches,
				fromBatch
			}
		})
		dialogRef.afterClosed()
			.pipe(first())
			.subscribe(toBatch => {
				if (toBatch?.id) {
					this.moveItems(fromBatch, toBatch, selectedImages);
				}
			})
	}

	sendMoveItemsReq(fromBatch, toBatch, selectedImages) {
		const promises = selectedImages.map((image) => {
			return this._imageService.moveImageToBatch(
				fromBatch.id,
				toBatch.id,
				image
			)
		});
		return Promise.allSettled(promises);
	}

	moveImagesBetweenBatches(fromBatch, toBatch, images) {
		const imageDict = keyBy(images, "id");

		fromBatch.images = fromBatch.images.filter(
			(image) => !(image.id in imageDict)
		);
		toBatch.images = toBatch.images.concat(
			images.map(
				(image) => ((image.batch_id = toBatch.id), image)
			)
		);

		this.resetTableSelection();
		this.handleBatches(this.batches);
	}

	handleImageMove = (fromBatch, toBatch, selectedImages) => (resultList) => {

		const failedImageList = resultList.reduce(
			(acc, { status }, i) => {
				if (status === fulfilledStatus) return acc;
				return acc.concat(selectedImages[i]);
			},
			[]
		);

		const formatter = new (Intl as any).ListFormat("en", {
			style: "long",
			type: "conjunction",
		});

		const formattedImageNames = formatter.format(
			failedImageList.map((img) => img.name)
		);

		if (failedImageList.length === 0) {
			this._alertService.notify(
				`${resultList.length} ${pluralize("image", resultList.length)} moved from ${fromBatch.name} to ${toBatch.name}`,
				"success"
			);
		} else {
			this._alertService.notify(
				`The following ${pluralize("image", failedImageList.length)} failed to move from ${fromBatch.name} to ${toBatch.name}: ${formattedImageNames}`,
				"error"
			);

			this.moveImagesBetweenBatches(toBatch, fromBatch, failedImageList)
		}
	}

	moveItems(fromBatch, toBatch, selectedImages: Image[]): void {
		this.moveImagesBetweenBatches(fromBatch, toBatch, selectedImages)

		this.sendMoveItemsReq(fromBatch, toBatch, selectedImages).then(
			this.handleImageMove(fromBatch, toBatch, selectedImages)
		).catch(err => {
			console.error(err);
			this._alertService.error(new Alert("Could not move the selected item(s), please try again."))
		});
	}

	resetTableSelection(): void {
		this.selection = new SelectionModel<Element>(true, []);
	}

	removePhotos(selectedPhotoIds: number[]): void {
		const batches = this.batches.map(batch => {
			batch.images = batch.images.map(image => {
				if (selectedPhotoIds[image.id]) {
					image.trash = 1;
					analyticsLayer.trackRemove(image, "Image");
				}
				return image;
			});
			return batch;
		})
		this.handleBatches(batches);
		this._cdr.detectChanges();
	}

	notify = message => {
		this._alertService.notification(
			new Alert(message)
		)
	}

	deleteItems(items) {

		items = Array.isArray(items) ? items : [items] // whether it's [ a, b, c ]  or [ z ] we can use all of the same code with negligible if any performance change

		const selectedIds = items.reduce(
			(acc, { id }) => Object.assign(acc, { [id]: true }), {}
		) // faster than searching through items multiple times

		const promises: Array<Promise<Image>> = items.map(item =>
			this._imageService.trash(new Image({ guid: item.guid }))
		)

		Promise.all(promises)
			.then(() => {
				this.removePhotos(selectedIds);
				this.notify(`${items.length} image${items.length === 1 ? '' : 's'} moved to trash`);
				this.resetTableSelection();
			})
			.catch(error => {
				switch (error) {
					case 402:
						console.error("Failed to remove image: ", error.status, items)
						this.notify(`Failed to move item${items.length === 1 ? '' : 's'} to trash`)
						break
					default:
						console.warn(error)
						break
				}
			})
	}

	downloadSelected(selected): void {

		if (selected.length) {
			this.downloadImages(selected);
			this.clearSelection();
		}

	}	// End-of download

	clearSelection(): void {
		this.selection = new SelectionModel<Element>(true, []);
	}

	downloadImages(images) {
		this._alertService.success(new Alert('Your download is preparing'));
		this.isDownloading = true;
		this.downloadAsync(images).then(() => {
			this.isDownloading = false;
			if (this.failedDownloads.length) {
				// @ts-ignore - we do not have the latest ts check, so it's not aware of the new `listFormat`
				const formatter = new Intl.ListFormat("en", {
					style: "long",
					type: "conjunction"
				});
				const maxStrings = 4;
				const extra = this.failedDownloads.length - maxStrings;
				const failedString = formatter.format(this.failedDownloads.slice(0, maxStrings).map(x => x.name));
				const extraString = extra > 0 ? 'and ' + extra + ` more ${pluralize('image', extra)}` : '';
				this._alertService.error(new Alert(`${failedString} ${extraString} failed to download. ${this.failedDownloads.length === 1 ? 'It' : 'They'} will be selected to retry.`));
				this.selection.select(...this.failedDownloads);
			}
		})
	}

	downloadAsync(images) {
		return asyncjs.mapLimit(
			images,
			4,
			asyncjs.asyncify(image => {
				return this.tryImageDownload(image).catch(err => {
					console.error("Image download failed for image", image);
					this.failedDownloads.push(image);
				});
			})
		)
	}

	tryImageDownload(image) {

		const bailCodes = [200];
		return retry(
			(bail) =>
				this.downloadSingle(image)
					.then((img: Blob) => {
						this._downloadService.downloadHelper(img, image.name);
						return img;
					})
					.catch((errorCode) => {
						if (bailCodes.includes(errorCode)) {
							bail(new Error(errorCode));
						} else {
							console.warn('Image download failed, retrying...');
							throw new Error(errorCode);
						}
					}),
			{
				retries: downloadAttempts,
				minTimeout: 50
			}
		);
	}

	downloadSingle(image): Promise<any> {

		analyticsLayer.trackDownload(image, "Image");
		return this._imageService.download(image.guid, IMAGESIZES.large);

	}	// End-of downloadSingle

	toggleGrid(): void {

		this.gridDisplay = !this.gridDisplay;

	}	// End-of changeGrid

	getBatchV2 = async (section) => {
		if (!section?.children.length || this.v2FlagEnabled() || this.batch) {
			return this._imageService.getBatchV2( section.id )
				.then( deepBatch => {
					return this.updateBatch( deepBatch );
				} )
				.catch( console.error );
		} else {
			return null;
		}
	}

	v2FlagEnabled() {
		return flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)
	}

	handleSectionEvent(event): void {
		switch (event.type) {
			case 'delete':
				this.removeBatch(event.section);
				break;
			case 'edit':
				this.openRenameDialog(event.section);
				break;
		}
	}

	handlePhotoEvent({ type, child }): void {
		// TODO: Burn the sWITCH!!!
		switch (type) {
			case 'open':
				this.clicked(child);
				break;
			case 'move':
				this.openMoveItemsDialog([child]);
				break;
			case 'download':
				this.downloadImages([child]);
				break;
			case 'favorite':
				this.favorite(child);
				break;
			case 'delete':
				this.deleteItems(child);
				break;
		}
	}

	removeBatch(batch): void {

		let confirmOptions = {
			title: 'Remove group',
			text: `Are you sure you want to permanently delete the image group "${batch.name}"? ` +
				(batch.children.length ?
					`This will also delete the ${pluralize("image", batch.children.length)} that ${pluralize('belong', batch.children.length)} to the group.` : ``)
				+ ` You cannot undo this action.`,
			buttonText: `Yes, delete permanently`
		};

		this._dialog.open(ConfirmationModal, { data: confirmOptions })
			.afterClosed().subscribe(rtn => {
				if (rtn) {
					this._imageService.trashBatchV2(batch).then(() => {
						this._alertService.success(new Alert(`Successfully removed ${batch.name}.`));
						this.batches = this.batches.filter(not(byId(batch.id)));
						const emitProject = Object.assign({}, this._project, { batches: this.batches });
						this.projectChange.emit({ project: emitProject, type: "batches" });
					}).catch(err => {
						console.error(err)
					});
				}
			})
	}

	openRenameDialog(batch): void {

		const dialogRef = this._dialog.open(RenameModal, {
			data: batch
		})
		const oldName = batch.name;
		dialogRef.afterClosed()
			.pipe(first())
			.subscribe(rtn => {
				if (rtn) {
					batch.name = rtn.name;
					this.renameBatch(batch, oldName, rtn)
				}
			})
	}

	renameBatch(batch, oldName, { id, name }): void {
		this._imageService.renameBatch(id, name)
			.then(() => {
				this._alertService.notify(`Your Photo Group was successfully renamed!`, 'success');
			})
			.catch(e => {
				console.error(e);
				batch.name = oldName;
				this._alertService.notify(
					`We're sorry, ${oldName} could not be renamed. Please try again`,
					'error'
				);
			})
	}

	editBatch(batch): void {
		this._imageService.renameBatch(batch.id, batch.name)
			.then(() => {
				this._alertService.notify(`${batch.name} was saved!`, 'success');
			})
			.catch(e => {
				console.error(e);
				this._alertService.notify(
					`We're sorry, ${batch.name} could not be saved. Please try again`,
					'error'
				);
			})
	}

	openUploadDialog(defaultBatch = null): void {
		const dialogRef = this._dialog.open(UploadPhotosDialog, {
			data: {
				batches: this.batches,
				defaultBatch
			}
		})
		dialogRef.afterClosed()
			.pipe(first())
			.subscribe(res => {
				if (res?.files?.length) {
					this.uploadFiles(res.files, res.batch_id, res.name);
				}
			})
	}

	checkPercentage(percentage) {
		return !isNil(percentage);
	}

	allFilesCompleted({ completedFiles, totalFiles }): boolean {
		return completedFiles >= totalFiles;
	}

}	// End-of class PhotosProjectsComponent
