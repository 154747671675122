import { Box, Tab, Tabs, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import produce from "immer";
import React from "react";
import { connect } from "react-redux";

import { RootState } from "@app/store";
import { AnnotationVisibilityControls } from "@features/AnnotationVisibilityControls";
import { ViewerLayers } from "@features/ViewerLayers";
import { Portal } from "@react/components";
import { themeDef } from "@react/theme";

import { mapMode, sidebarMode } from "../store";

const darkTheme = createTheme(
	produce(themeDef, (t) => {
		t.palette.mode = "dark";
	})
);

export const _Sidebar = ({ setLayersMode, setAnnotationsMode, activeMode }) => {
	console.log(activeMode);
	return (
		<aside>
			<Box>
				<Tabs value={activeMode} aria-label="basic tabs example">
					<Tab
						label="LAYERS"
						value={sidebarMode.SidebarMode.Layers}
						onClick={() => setLayersMode()}
					/>
					<Tab
						label="ANNOTATIONS"
						value={sidebarMode.SidebarMode.Annotations}
						onClick={() => setAnnotationsMode()}
					/>
				</Tabs>
			</Box>

			{activeMode === sidebarMode.SidebarMode.Layers && (
				<Box>
					<ViewerLayers />
				</Box>
			)}
			{activeMode === sidebarMode.SidebarMode.Annotations && (
				<Box>
					<AnnotationVisibilityControls />
				</Box>
			)}
		</aside>
	);
};

export const Sidebar = connect(
	(s: RootState) => ({
		activeMode: s.sidebarMode.mode,
		mapMode: s.mapMode,
	}),
	{
		...mapMode.actions,
		...sidebarMode.actions,
	}
)(_Sidebar);

export const SidebarPortal = () => (
	<Portal selector="map-sidebar">
		<ThemeProvider theme={darkTheme}>
			<Sidebar />
		</ThemeProvider>
	</Portal>
);
