/* Imports */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
	selector: 'app-tags',
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

	@Input('pristine-text') pristineText: string = "";
	@Input('dirty-text') dirtyText: string = "";
	@Input('hint-text') hintText: string = "";

	public inputText: string = "";
	public boolFocus: boolean = false;
	public tagControl: FormControl = new FormControl('');
	public filteredTagSuggestions: Observable<any[]>;

	@Input('items') itemArray: any[];
	@Input('tagList') tagList: Array<any> = [];
	@Output() itemsChanged = new EventEmitter<any>();

	// @Input()
	// get items()
	// {
	//   return this.itemArray;
	// }
	// set items(val)
	// {
	//   this.itemArray = val;
	//   this.itemsChanged.emit(this.itemArray);
	// }

	constructor() {

	}

	ngOnInit(): void {

		// Remove filters that exist in the project already
		if (this.itemArray.length) {
			this.tagList = this.tagList.filter((el) => !this.itemArray.includes(el));
		}

		this.filteredTagSuggestions = this.tagControl.valueChanges
			.pipe(
				startWith(''),
				map(tag => this.filterEmails(tag))
			);
	}

	filterEmails(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.tagList.filter(tag => tag.toLowerCase().includes(filterValue));
	}

	toggleFocus(): void {
		this.boolFocus = !this.boolFocus;
	}

	onEnter() {
		if (this.tagControl.value.length > 0) {
			this.deleteTag(this.tagControl.value); // no duplicates
			// this.itemArray.unshift(this.inputText);
			this.itemArray.push(this.tagControl.value);
			this.tagControl.setValue('');

			this.itemsChanged.emit(this.itemArray);
		}
	}

	deleteTag(delItem) {
		this.itemArray = this.itemArray.filter(x => x != delItem);

		this.itemsChanged.emit(this.itemArray);
	}

}
