<main>
	<header>
		<span class="photo-view-header">
			<button mat-button (click)="goBack()" matTooltip="Return to the project">
				<mat-icon class="back-button-icon">keyboard_backspace</mat-icon>
			</button>
			<span>
				<h4 class="H4Black700Regular">{{imageInfo ? imageInfo.name : ""}}</h4>
				<p *ngIf="imageGroup?.name">Image Group: {{ imageGroup.name }}</p>
			</span>
		</span>

		<img (click)="goHome()" matTooltip="Return to Mapware" class="logo-icon" src="../../../../../../assets/images/aa_logo.png">

		<div class="download-wrapper">
			<button mat-stroked-button color="primary" (click)="trash()">
				<mat-icon>delete_outline</mat-icon>
				<span class="phone-hidden">Trash</span>
			</button>
			<button mat-stroked-button color="primary" (click)="download()">
				<mat-icon>vertical_align_bottom</mat-icon>
				<span class="phone-hidden">Download</span>
			</button>
		</div>
	</header>
	<div class="content-wrapper">
		<section>
			<ng-container *ngIf="imageInfo">
				<app-photo-tag
					[batchImages]="batchImages"
					[srcImageId]="srcImageId"
					[currentIndex]="currentIndex"
					[imageInfo]="imageInfo"
					[imageList]="imageList"
					[msDetail]="msDetail"
					[showNav]="isFromProject"
					[projectId]="projectId"
					(openInfo)="hideAside = !hideAside"
					(navEvent)="changePhoto($event);"></app-photo-tag>
			</ng-container>
		</section>
		<aside [ngClass]="{'hide-aside': hideAside}" *ngIf="imageInfo">
			<ng-container *ngTemplateOutlet="t_photoInfo"></ng-container>
		</aside>
	</div>
</main>


<ng-template #unavailable>Unavailable</ng-template>


<ng-template #t_photoInfo>
	<div class="info-wrapper">
		<ng-container *ngTemplateOutlet="t_photoDetails"></ng-container>
	</div>
	<ng-container *ngIf="imageInfo.latitude && imageInfo.longitude">
		<mat-divider color="#dee0e3"></mat-divider>
		<div class="info-wrapper">
			<div class="location-wrapper">
				<p class="H5Black500">Location</p>
				<button mat-icon-button tooltip="More" [matMenuTriggerFor]="itemMenu">
					<mat-icon class="list-item-icon">more_horiz</mat-icon>
				</button>
			</div>
			<mat-menu #itemMenu="matMenu" class="little-menu centered-menu-text">
				<ng-template matMenuContent>
					<mat-list style="width: 100%;">
						<mat-list-item [ngClass]="{'P1Black500': mapStyle === 'map'}"
									   (click)="setMapStyle('map')">
							Map view
						</mat-list-item>
						<mat-list-item [ngClass]="{'P1Black500': mapStyle === 'sat'}"
									   (click)="setMapStyle('sat');">
							Satellite view
						</mat-list-item>
					</mat-list>
				</ng-template>
			</mat-menu>
			<div class="info-grid">
				<p class="H7Black700Semibold" style="margin-bottom: 0;">Latitude & Longitude:</p>
				<p class="H7Black700Regular" style="margin-bottom: 0;">
					({{imageInfo.latitude | number:'1.0-4'}},
					{{imageInfo.longitude| number:'1.0-4'}})
				</p>
				<p class="H7Black500Regular" style="margin-top: 0;">Center point of image</p>
			</div>
		</div>
		<div leaflet [leafletOptions]="options" [leafletLayers]="layers"></div>
	</ng-container>
</ng-template>

<ng-template #t_photoDetails>
	<div class="title-container">
		<p class="H5Black500">{{!msDetail ? 'Photo Detail' : 'Multispectral Photo Detail'}}</p>
		<button class="tablet-visible close-button" mat-stroked-button (click)="hideAside = !hideAside"><mat-icon>close</mat-icon></button>
	</div>
	<div class="info-grid">
		<p class="H7Black700Semibold" *ngIf="msDetail">Multispectral Sensor:</p>
		<p class="H7Black700Regular" *ngIf="msDetail">
			<ng-container *ngIf="msDetail; else unavailable">
				{{msDetail | titlecase}}
			</ng-container>
		</p>
		<p class="H7Black700Semibold">Camera:</p>
		<p class="H7Black700Regular">
			<ng-container *ngIf="imageInfo.descriptors && imageInfo.descriptors.make; else unavailable">
				{{imageInfo.descriptors.make}}-{{imageInfo.descriptors.model}}
			</ng-container>
		</p>
		<p class="H7Black700Semibold">ISO:</p>
		<p class="H7Black700Regular">
			<ng-container *ngIf="imageInfo.descriptors && imageInfo.descriptors.iso; else unavailable">
				{{imageInfo.descriptors.iso}}
			</ng-container>
		</p>
		<p class="H7Black700Semibold">Shutter Speed:</p>
		<p class="H7Black700Regular">
			<ng-container *ngIf="imageInfo.descriptors && imageInfo.descriptors.exposure; else unavailable">
				1/{{(1 / imageInfo.descriptors.exposure) | number:'1.0-0'}}s
			</ng-container>
		</p>
		<p class="H7Black700Semibold">F-stop:</p>
		<p class="H7Black700Regular">
			<ng-container *ngIf="imageInfo.descriptors && imageInfo.descriptors.f_stop; else unavailable">
				{{imageInfo.descriptors.f_stop}}s
			</ng-container>
		</p>
		<p class="H7Black700Semibold">Lens:</p>
		<p class="H7Black700Regular">
			<ng-container
				*ngIf="imageInfo.descriptors && imageInfo.descriptors.focal_length; else unavailable">
				{{imageInfo.descriptors.focal_length}}mm
			</ng-container>
		</p>
		<p class="H7Black700Semibold">Captured at:</p>
		<p class="H7Black700Regular">
			<ng-container *ngIf="imageInfo.altitude; else unavailable">
				{{imageInfo.altitude | number:'1.0-1'}} ft
			</ng-container>
		</p>
		<p class="H7Black700Semibold">Megapixels:</p>
		<p class="H7Black700Regular">{{imageInfo.pixels | bytes:'pixels'}}</p>
		<p class="H7Black700Semibold">Date taken:</p>
		<p class="H7Black700Regular">
			<ng-container
				*ngIf="imageInfo.descriptors && imageInfo.descriptors.captured_at; else unavailable">
				{{imageInfo.descriptors.captured_at | date:'M/d/yyyy'}}
			</ng-container>
		</p>
	</div>
</ng-template>
