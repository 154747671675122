
/* Imports */
import {Component, Inject, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, makePublicShareLink, ProjectService, OrganizationService, PermissionService, AuthenticationService } from '@shared/services';
import {ShareLink, Alert, Model, Project} from "@shared/models";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { first } from "rxjs/operators";

@Component({
	selector: 'app-share-link',
	templateUrl: './share-link.component.html',
	styleUrls: ['./share-link.component.scss']
})

export class ShareLinkComponent implements OnDestroy {

	@Input() createLinkFunc: Function;

	@Input() shareLink?: string = null;
	@Input() shareState?: boolean = true;
	@Input() setLinkAbilityFunc?: Function = null;
	@Input() project?: Project = null;

	@Output() shareLinkChange: EventEmitter<string> = new EventEmitter(null);

	public shareablesList: Array<any>;

	public selectedShareable;
	public shareName: string = null;
	public shareType: string = "item";
	public linkCopied: boolean = false;
	public isDialog: boolean = false;
	public canShare: boolean = false;
	public activeOrg;
	public closeSubscription;
	public isLoading: boolean = false;

	constructor (
		private _alertService: AlertService,
		private _cdr: ChangeDetectorRef,
		private _clipboard: Clipboard,
		public _authService: AuthenticationService,
		public _orgService: OrganizationService,
		public _permissionService: PermissionService,
		public _dialogRef: MatDialogRef<ShareLinkComponent>,
		public _projectService: ProjectService,
		@Inject(MAT_DIALOG_DATA) public data: ShareLink,
	) {

		Object.assign(this, data);
		if (data?.createLinkFunc) {
			this.isDialog = true;
		}

		if (this.shareablesList?.length) {
			this.updateShareable(this.shareablesList[0]);
		}

		// Override backdrop click to return variables
		this.closeSubscription = this._dialogRef.backdropClick()
			.pipe(first())
			.subscribe(() => {
				this.close();
			})

		if (this.project?.id) {
			this.getActiveOrg(this.project);
		}
	}

	ngOnDestroy() {
		this.closeSubscription?.unsubscribe();
	}

	async getActiveOrg(project) {
		this.isLoading = true;
		await this._orgService.getById(project.organization_id).then(org => {
			this.activeOrg = this._orgService.getOrgFromList(org.id);
		})
		this.checkSharePermissions();
	}

	checkSharePermissions() {
		const userId = this._authService.user.id;
		this.canShare = this._permissionService.compareToOrgPermissions(this.activeOrg, 'process', userId);
		this.isLoading = false;
	}

	createLink(): void {
		this.linkCopied = false;
		this.createLinkFunc(this.selectedShareable?.id).then(rtnLink => {
			this.shareState = true;
			this.shareLink = rtnLink;

			if (this.selectedShareable) {
				this.selectedShareable.public_share = 1;
				this.selectedShareable.public_guid = rtnLink;
			}

			if (!this.isDialog) this.shareLinkChange.emit(this.shareLink);
			this._alertService.success(new Alert(`Successfully created share link for ${this.shareName}.`))
		}).catch(err => {
			console.error(err);
			this._alertService.error(new Alert("Your link couldn't be created, please try again later."))
		});
	}

	copyLink(shareLink): void {
		this._clipboard.copy(shareLink);
		this.linkCopied = true;
	}

	onToggle(slideState: MatSlideToggleChange): void {
		const ability = slideState?.checked ?? false;

		if (!this.shareLink) {
			this.createLink();
		} else {
			this.setLinkAbilityFunc(this.selectedShareable.id, ability).then((rtnModel: Model) => {
				if (this.selectedShareable) this.selectedShareable.public_share = ability ? 1 : 0;
				this._alertService.success(new Alert(`${ability ? 'Enabled' : 'Disabled'} sharing for ${this.shareName}.`))
			}).catch(err => {
				console.error(err);
				this._alertService.error(new Alert(`Your link couldn't be ${ability ? 'enabled' : 'disabled'}, please try again.`));
			});
		}
	}

	updateShareable(selected): void {
		if (selected) {
			this.selectedShareable = selected;
			this.shareName = selected.name;
			this.shareLink = selected.public_guid ? makePublicShareLink(selected.public_guid) : "";
			this.shareState = selected.public_share;

			this._projectService.getById(selected?.project_id).then(project => {
				this.getActiveOrg(project);
			})
		}
	}

	close(): void {
		this._dialogRef.close( { shareLink: this.shareLink, shareState: this.shareState });
	}

}	// End-of class TestComponent
