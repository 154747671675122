function e(e, n) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    n && (r = r.filter(function (n) {
      return Object.getOwnPropertyDescriptor(e, n).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function n(n) {
  for (var t = 1; t < arguments.length; t++) {
    var o = null != arguments[t] ? arguments[t] : {};
    t % 2 ? e(Object(o), !0).forEach(function (e) {
      r(n, e, o[e]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(o)) : e(Object(o)).forEach(function (e) {
      Object.defineProperty(n, e, Object.getOwnPropertyDescriptor(o, e));
    });
  }
  return n;
}
function t(e) {
  return (t = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  })(e);
}
function r(e, n, t) {
  return n in e ? Object.defineProperty(e, n, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[n] = t, e;
}
function o(e, n) {
  return function (e) {
    if (Array.isArray(e)) return e;
  }(e) || function (e, n) {
    var t = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
    if (null != t) {
      var r,
        o,
        i = [],
        a = !0,
        u = !1;
      try {
        for (t = t.call(e); !(a = (r = t.next()).done) && (i.push(r.value), !n || i.length !== n); a = !0);
      } catch (e) {
        u = !0, o = e;
      } finally {
        try {
          a || null == t.return || t.return();
        } finally {
          if (u) throw o;
        }
      }
      return i;
    }
  }(e, n) || a(e, n) || function () {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function i(e) {
  return function (e) {
    if (Array.isArray(e)) return u(e);
  }(e) || function (e) {
    if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
  }(e) || a(e) || function () {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function a(e, n) {
  if (e) {
    if ("string" == typeof e) return u(e, n);
    var t = Object.prototype.toString.call(e).slice(8, -1);
    return "Object" === t && e.constructor && (t = e.constructor.name), "Map" === t || "Set" === t ? Array.from(e) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? u(e, n) : void 0;
  }
}
function u(e, n) {
  (null == n || n > e.length) && (n = e.length);
  for (var t = 0, r = new Array(n); t < n; t++) r[t] = e[t];
  return r;
}
function c(e) {
  function n(e, n) {
    Error.captureStackTrace && Error.captureStackTrace(this, this.constructor), this.message = e, this.code = n;
  }
  return n.prototype = new Error(), n.prototype.name = e, n.prototype.constructor = n, n;
}
var s = c("LaunchDarklyUnexpectedResponseError"),
  l = c("LaunchDarklyInvalidEnvironmentIdError"),
  f = c("LaunchDarklyInvalidUserError"),
  d = c("LaunchDarklyInvalidEventKeyError"),
  v = c("LaunchDarklyInvalidArgumentError"),
  g = c("LaunchDarklyFlagFetchError"),
  p = c("LaunchDarklyInvalidDataError");
function m(e) {
  return !(e >= 400 && e < 500) || 400 === e || 408 === e || 429 === e;
}
for (var h = Object.freeze({
    __proto__: null,
    LDUnexpectedResponseError: s,
    LDInvalidEnvironmentIdError: l,
    LDInvalidUserError: f,
    LDInvalidEventKeyError: d,
    LDInvalidArgumentError: v,
    LDFlagFetchError: g,
    LDInvalidDataError: p,
    isHttpErrorRecoverable: m
  }), y = [], w = [], b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", E = 0, k = b.length; E < k; ++E) y[E] = b[E], w[b.charCodeAt(E)] = E;
function D(e, n, t) {
  for (var r, o, i = [], a = n; a < t; a += 3) r = (e[a] << 16 & 16711680) + (e[a + 1] << 8 & 65280) + (255 & e[a + 2]), i.push(y[(o = r) >> 18 & 63] + y[o >> 12 & 63] + y[o >> 6 & 63] + y[63 & o]);
  return i.join("");
}
w["-".charCodeAt(0)] = 62, w["_".charCodeAt(0)] = 63;
var S = Array.isArray,
  O = Object.keys,
  P = Object.prototype.hasOwnProperty,
  U = ["key", "secondary", "ip", "country", "email", "firstName", "lastName", "avatar", "name"];
function I(e, n) {
  return (e.endsWith("/") ? e.substring(0, e.length - 1) : e) + (n.startsWith("/") ? "" : "/") + n;
}
function j(e) {
  return function (e) {
    for (var n, t = e.length, r = t % 3, o = [], i = 0, a = t - r; i < a; i += 16383) o.push(D(e, i, i + 16383 > a ? a : i + 16383));
    return 1 === r ? (n = e[t - 1], o.push(y[n >> 2] + y[n << 4 & 63] + "==")) : 2 === r && (n = (e[t - 2] << 8) + e[t - 1], o.push(y[n >> 10] + y[n >> 4 & 63] + y[n << 2 & 63] + "=")), o.join("");
  }(function (e) {
    for (var n = [], t = 0; t < e.length; t++) n.push(e.charCodeAt(t));
    return n;
  }(unescape(encodeURIComponent(e))));
}
function R(e) {
  return j(e).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}
function T(e) {
  return JSON.parse(JSON.stringify(e));
}
function C(e, n) {
  return function e(n, t) {
    if (n === t) return !0;
    if (n && t && "object" == typeof n && "object" == typeof t) {
      var r,
        o,
        i,
        a = S(n),
        u = S(t);
      if (a && u) {
        if ((o = n.length) != t.length) return !1;
        for (r = o; 0 != r--;) if (!e(n[r], t[r])) return !1;
        return !0;
      }
      if (a != u) return !1;
      var c = n instanceof Date,
        s = t instanceof Date;
      if (c != s) return !1;
      if (c && s) return n.getTime() == t.getTime();
      var l = n instanceof RegExp,
        f = t instanceof RegExp;
      if (l != f) return !1;
      if (l && f) return n.toString() == t.toString();
      var d = O(n);
      if ((o = d.length) !== O(t).length) return !1;
      for (r = o; 0 != r--;) if (!P.call(t, d[r])) return !1;
      for (r = o; 0 != r--;) if (!e(n[i = d[r]], t[i])) return !1;
      return !0;
    }
    return n != n && t != t;
  }(e, n);
}
function L(e) {
  setTimeout(e, 0);
}
function A(e, n) {
  var t = e.then(function (e) {
    return n && setTimeout(function () {
      n(null, e);
    }, 0), e;
  }, function (e) {
    if (!n) return Promise.reject(e);
    setTimeout(function () {
      n(e, null);
    }, 0);
  });
  return n ? void 0 : t;
}
function x(e) {
  var n = {};
  for (var t in e) V(e, t) && (n[t] = {
    value: e[t],
    version: 0
  });
  return n;
}
function F(e) {
  var n = {};
  for (var t in e) V(e, t) && (n[t] = e[t].value);
  return n;
}
function N(e, n) {
  for (var t, r = n.slice(0), o = [], i = e; r.length > 0;) {
    for (t = []; i > 0;) {
      var a = r.shift();
      if (!a) break;
      (i -= R(JSON.stringify(a)).length) < 0 && t.length > 0 ? r.unshift(a) : t.push(a);
    }
    i = e, o.push(t);
  }
  return o;
}
function q(e) {
  var n = e.version || "3.8.2";
  return e.userAgent + "/" + n;
}
function _() {
  for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
  return t.reduce(function (e, t) {
    return n(n({}, e), t);
  }, {});
}
function V(e, n) {
  return Object.prototype.hasOwnProperty.call(e, n);
}
function M(e) {
  if (!e) return e;
  var t;
  for (var r in U) {
    var o = U[r],
      i = e[o];
    void 0 !== i && "string" != typeof i && ((t = t || n({}, e))[o] = String(i));
  }
  return t || e;
}
for (var z = Object.freeze({
    __proto__: null,
    appendUrlPath: I,
    btoa: j,
    base64URLEncode: R,
    clone: T,
    deepEquals: C,
    onNextTick: L,
    wrapPromiseCallback: A,
    transformValuesToVersionedValues: x,
    transformVersionedValuesToValues: F,
    chunkUserEventsForUrl: N,
    getLDUserAgentString: q,
    extend: _,
    objectHasOwnProperty: V,
    sanitizeUser: M
  }), J = function (e, n) {
    return function (e) {
      var n = "undefined" != typeof crypto && crypto.getRandomValues && crypto.getRandomValues.bind(crypto) || "undefined" != typeof msCrypto && "function" == typeof window.msCrypto.getRandomValues && msCrypto.getRandomValues.bind(msCrypto);
      if (n) {
        var t = new Uint8Array(16);
        e.exports = function () {
          return n(t), t;
        };
      } else {
        var r = new Array(16);
        e.exports = function () {
          for (var e, n = 0; n < 16; n++) 0 == (3 & n) && (e = 4294967296 * Math.random()), r[n] = e >>> ((3 & n) << 3) & 255;
          return r;
        };
      }
    }(n = {
      exports: {}
    }), n.exports;
  }(), $ = [], H = 0; H < 256; ++H) $[H] = (H + 256).toString(16).substr(1);
var K,
  B,
  G = 0,
  X = 0,
  W = function (e, n, t) {
    var r = n && t || 0,
      o = n || [],
      i = (e = e || {}).node || K,
      a = void 0 !== e.clockseq ? e.clockseq : B;
    if (null == i || null == a) {
      var u = J();
      null == i && (i = K = [1 | u[0], u[1], u[2], u[3], u[4], u[5]]), null == a && (a = B = 16383 & (u[6] << 8 | u[7]));
    }
    var c = void 0 !== e.msecs ? e.msecs : new Date().getTime(),
      s = void 0 !== e.nsecs ? e.nsecs : X + 1,
      l = c - G + (s - X) / 1e4;
    if (l < 0 && void 0 === e.clockseq && (a = a + 1 & 16383), (l < 0 || c > G) && void 0 === e.nsecs && (s = 0), s >= 1e4) throw new Error("uuid.v1(): Can't create more than 10M uuids/sec");
    G = c, X = s, B = a;
    var f = (1e4 * (268435455 & (c += 122192928e5)) + s) % 4294967296;
    o[r++] = f >>> 24 & 255, o[r++] = f >>> 16 & 255, o[r++] = f >>> 8 & 255, o[r++] = 255 & f;
    var d = c / 4294967296 * 1e4 & 268435455;
    o[r++] = d >>> 8 & 255, o[r++] = 255 & d, o[r++] = d >>> 24 & 15 | 16, o[r++] = d >>> 16 & 255, o[r++] = a >>> 8 | 128, o[r++] = 255 & a;
    for (var v = 0; v < 6; ++v) o[r + v] = i[v];
    return n || function (e, n) {
      var t = n || 0,
        r = $;
      return [r[e[t++]], r[e[t++]], r[e[t++]], r[e[t++]], "-", r[e[t++]], r[e[t++]], "-", r[e[t++]], r[e[t++]], "-", r[e[t++]], r[e[t++]], "-", r[e[t++]], r[e[t++]], r[e[t++]], r[e[t++]], r[e[t++]], r[e[t++]]].join("");
    }(o);
  },
  Q = ["debug", "info", "warn", "error", "none"];
function Y(e, n) {
  if (e && e.destination && "function" != typeof e.destination) throw new Error("destination for basicLogger was set to a non-function");
  function t(e) {
    return function (n) {
      console && console[e] && console[e].call(console, n);
    };
  }
  var r = e && e.destination ? [e.destination, e.destination, e.destination, e.destination] : [t("log"), t("info"), t("warn"), t("error")],
    o = !(!e || !e.destination),
    a = e && void 0 !== e.prefix && null !== e.prefix ? e.prefix : "[LaunchDarkly] ",
    u = 1;
  if (e && e.level) for (var c = 0; c < Q.length; c++) Q[c] === e.level && (u = c);
  function s(e, t, u) {
    if (!(u.length < 1)) {
      var c,
        s = o ? t + ": " + a : a;
      if (1 !== u.length && n) {
        var l = i(u);
        l[0] = s + l[0], c = n.apply(void 0, i(l));
      } else c = s + u[0];
      try {
        r[e](c);
      } catch (e) {
        console && console.log && console.log("[LaunchDarkly] Configured logger's " + t + " method threw an exception: " + e);
      }
    }
  }
  for (var l = {}, f = function (e) {
      var n = Q[e];
      if ("none" !== n) if (e < u) l[n] = function () {};else {
        var t = e;
        l[n] = function () {
          s(t, n, arguments);
        };
      }
    }, d = 0; d < Q.length; d++) f(d);
  return l;
}
function Z(e, n) {
  return Y({
    level: e,
    prefix: n
  });
}
function ee(e) {
  return e && e.message ? e.message : "string" == typeof e || e instanceof String ? e : JSON.stringify(e);
}
var ne = " Please see https://docs.launchdarkly.com/sdk/client-side/javascript#initializing-the-client for instructions on SDK initialization.",
  te = function (e) {
    return 'Expected application/json content type but got "' + e + '"';
  },
  re = function (e) {
    return "local storage is unavailable: " + ee(e);
  },
  oe = function (e) {
    return "network error" + (e ? " (" + e + ")" : "");
  },
  ie = function (e) {
    return 'Custom event "' + e + '" does not exist';
  },
  ae = function () {
    return "Environment not found. Double check that you specified a valid environment/client-side ID." + ne;
  },
  ue = function () {
    return "No environment/client-side ID was specified." + ne;
  },
  ce = function (e) {
    return "Error fetching flag settings: " + ee(e);
  },
  se = function () {
    return "No user specified." + ne;
  },
  le = function () {
    return "Invalid user specified." + ne;
  },
  fe = function () {
    return "LaunchDarkly client was initialized with bootstrap data that did not include flag metadata. Events may not be sent correctly." + ne;
  },
  de = function (e, n) {
    return n ? '"' + e + '" is deprecated, please use "' + n + '"' : '"' + e + '" is deprecated';
  },
  ve = function (e, n, t) {
    return "Received error " + e + (401 === e ? " (invalid SDK key)" : "") + " for " + n + " - " + (m(e) ? t : "giving up permanently");
  },
  ge = function () {
    return "Cannot make HTTP requests in this environment." + ne;
  },
  pe = function (e) {
    return "Opening stream connection to " + e;
  },
  me = function (e, n) {
    return "Error on stream connection: " + ee(e) + ", will continue retrying after " + n + " milliseconds.";
  },
  he = function (e) {
    return "Error on stream connection ".concat(ee(e), ", giving up permanently");
  },
  ye = function (e) {
    return 'Ignoring unknown config option "' + e + '"';
  },
  we = function (e, n, t) {
    return 'Config option "' + e + '" should be of type ' + n + ", got " + t + ", using default value";
  },
  be = function (e, n) {
    return 'Config option "' + e + '" should be a boolean, got ' + n + ", converting to boolean";
  },
  Ee = function (e, n, t) {
    return 'Config option "' + e + '" was set to ' + n + ", changing to minimum value of " + t;
  },
  ke = function (e) {
    return "polling for feature flags at " + e;
  },
  De = function (e) {
    return 'received streaming update for flag "' + e + '"';
  },
  Se = function (e) {
    return 'received streaming update for flag "' + e + '" but ignored due to version check';
  },
  Oe = function (e) {
    return 'received streaming deletion for flag "' + e + '"';
  },
  Pe = function (e) {
    return 'received streaming deletion for flag "' + e + '" but ignored due to version check';
  },
  Ue = function (e) {
    return 'enqueueing "' + e + '" event';
  },
  Ie = function (e) {
    return "sending " + e + " events";
  },
  je = function (e) {
    return 'Config option "'.concat(e, '" must only contain letters, numbers, ., _ or -.');
  },
  Re = function (e, n) {
    return 'an inspector: "'.concat(n, '" of an invalid type (').concat(e, ") was configured");
  },
  Te = function (e, n) {
    return 'an inspector: "'.concat(n, '" of type: "').concat(e, '" generated an exception');
  },
  Ce = function (e) {
    return 'Value of "'.concat(e, '" was longer than 64 characters and was discarded.');
  },
  Le = Object.freeze({
    __proto__: null,
    clientInitialized: function () {
      return "LaunchDarkly client initialized";
    },
    clientNotReady: function () {
      return "LaunchDarkly client is not ready";
    },
    eventCapacityExceeded: function () {
      return "Exceeded event queue capacity. Increase capacity to avoid dropping events.";
    },
    eventWithoutUser: function () {
      return "Be sure to call `identify` in the LaunchDarkly client: https://docs.launchdarkly.com/sdk/features/identify#javascript";
    },
    invalidContentType: te,
    invalidKey: function () {
      return "Event key must be a string";
    },
    localStorageUnavailable: re,
    networkError: oe,
    unknownCustomEventKey: ie,
    environmentNotFound: ae,
    environmentNotSpecified: ue,
    errorFetchingFlags: ce,
    userNotSpecified: se,
    invalidUser: le,
    invalidData: function () {
      return "Invalid data received from LaunchDarkly; connection may have been interrupted";
    },
    bootstrapOldFormat: fe,
    bootstrapInvalid: function () {
      return "LaunchDarkly bootstrap data is not available because the back end could not read the flags.";
    },
    deprecated: de,
    httpErrorMessage: ve,
    httpUnavailable: ge,
    identifyDisabled: function () {
      return "identify() has no effect here; it must be called on the main client instance";
    },
    streamClosing: function () {
      return "Closing stream connection";
    },
    streamConnecting: pe,
    streamError: me,
    unrecoverableStreamError: he,
    unknownOption: ye,
    wrongOptionType: we,
    wrongOptionTypeBoolean: be,
    optionBelowMinimum: Ee,
    debugPolling: ke,
    debugStreamPing: function () {
      return "received ping message from stream";
    },
    debugStreamPut: function () {
      return "received streaming update for all flags";
    },
    debugStreamPatch: De,
    debugStreamPatchIgnored: Se,
    debugStreamDelete: Oe,
    debugStreamDeleteIgnored: Pe,
    debugEnqueueingEvent: Ue,
    debugPostingEvents: Ie,
    debugPostingDiagnosticEvent: function (e) {
      return "sending diagnostic event (" + e.kind + ")";
    },
    invalidTagValue: je,
    invalidInspector: Re,
    inspectorMethodError: Te,
    tagValueTooLong: Ce
  }),
  Ae = {
    baseUrl: {
      default: "https://app.launchdarkly.com"
    },
    streamUrl: {
      default: "https://clientstream.launchdarkly.com"
    },
    eventsUrl: {
      default: "https://events.launchdarkly.com"
    },
    sendEvents: {
      default: !0
    },
    streaming: {
      type: "boolean"
    },
    sendLDHeaders: {
      default: !0
    },
    requestHeaderTransform: {
      type: "function"
    },
    inlineUsersInEvents: {
      default: !1
    },
    allowFrequentDuplicateEvents: {
      default: !1
    },
    sendEventsOnlyForVariation: {
      default: !1
    },
    useReport: {
      default: !1
    },
    evaluationReasons: {
      default: !1
    },
    eventCapacity: {
      default: 100,
      minimum: 1
    },
    flushInterval: {
      default: 2e3,
      minimum: 2e3
    },
    samplingInterval: {
      default: 0,
      minimum: 0
    },
    streamReconnectDelay: {
      default: 1e3,
      minimum: 0
    },
    allAttributesPrivate: {
      default: !1
    },
    privateAttributeNames: {
      default: []
    },
    bootstrap: {
      type: "string|object"
    },
    diagnosticRecordingInterval: {
      default: 9e5,
      minimum: 2e3
    },
    diagnosticOptOut: {
      default: !1
    },
    wrapperName: {
      type: "string"
    },
    wrapperVersion: {
      type: "string"
    },
    stateProvider: {
      type: "object"
    },
    autoAliasingOptOut: {
      default: !1
    },
    application: {
      validator: function (e, n, t) {
        var r = {};
        return n.id && (r.id = Fe("".concat(e, ".id"), n.id, t)), n.version && (r.version = Fe("".concat(e, ".version"), n.version, t)), r;
      }
    },
    inspectors: {
      default: []
    }
  },
  xe = /^(\w|\.|-)+$/;
function Fe(e, n, t) {
  if ("string" == typeof n && n.match(xe)) {
    if (!(n.length > 64)) return n;
    t.warn(Ce(e));
  } else t.warn(je(e));
}
function Ne(e, n, r, o) {
  var i = _({
      logger: {
        default: o
      }
    }, Ae, r),
    a = {
      all_attributes_private: "allAttributesPrivate",
      private_attribute_names: "privateAttributeNames",
      samplingInterval: null,
      allowFrequentDuplicateEvents: void 0
    };
  function u(e) {
    L(function () {
      n && n.maybeReportError(new v(e));
    });
  }
  var c = _({}, e || {});
  return function (e) {
    var n = e;
    Object.keys(a).forEach(function (e) {
      if (void 0 !== n[e]) {
        var t = a[e];
        o && o.warn(de(e, t)), t && (void 0 === n[t] && (n[t] = n[e]), delete n[e]);
      }
    });
  }(c), function (e) {
    Q.forEach(function (n) {
      if ("none" !== n && (!e[n] || "function" != typeof e[n])) throw new Error("Provided logger instance must support logger." + n + "(...) method");
    });
  }((c = function (e) {
    var n = _({}, e),
      r = function (e) {
        if (null === e) return "any";
        if (void 0 !== e) {
          if (Array.isArray(e)) return "array";
          var n = t(e);
          return "boolean" === n || "string" === n || "number" === n || "function" === n ? n : "object";
        }
      };
    return Object.keys(e).forEach(function (t) {
      var a = e[t];
      if (null != a) {
        var c = i[t];
        if (void 0 === c) u(ye(t));else {
          var s = c.type || r(c.default),
            l = c.validator;
          if (l) {
            var f = l(t, e[t], o);
            void 0 !== f ? n[t] = f : delete n[t];
          } else if ("any" !== s) {
            var d = s.split("|"),
              v = r(a);
            d.indexOf(v) < 0 ? "boolean" === s ? (n[t] = !!a, u(be(t, v))) : (u(we(t, s, v)), n[t] = c.default) : "number" === v && void 0 !== c.minimum && a < c.minimum && (u(Ee(t, a, c.minimum)), n[t] = c.minimum);
          }
        }
      }
    }), n;
  }(c = function (e) {
    var n = _({}, e);
    return Object.keys(i).forEach(function (e) {
      void 0 !== n[e] && null !== n[e] || (n[e] = i[e] && i[e].default);
    }), n;
  }(c))).logger), c;
}
function qe(e) {
  var n = {};
  return e && (e.application && void 0 !== e.application.id && null !== e.application.id && (n["application-id"] = [e.application.id]), e.application && void 0 !== e.application.version && null !== e.application.id && (n["application-version"] = [e.application.version])), n;
}
function _e(e, n) {
  if (n && !n.sendLDHeaders) return {};
  var t = {
    "X-LaunchDarkly-User-Agent": q(e)
  };
  n && n.wrapperName && (t["X-LaunchDarkly-Wrapper"] = n.wrapperVersion ? n.wrapperName + "/" + n.wrapperVersion : n.wrapperName);
  var r = qe(n),
    o = Object.keys(r);
  return o.length && (t["x-launchdarkly-tags"] = o.sort().map(function (e) {
    return Array.isArray(r[e]) ? r[e].sort().map(function (n) {
      return "".concat(e, "/").concat(n);
    }) : ["".concat(e, "/").concat(r[e])];
  }).reduce(function (e, n) {
    return e.concat(n);
  }, []).join(" ")), t;
}
function Ve(e, t) {
  return t && t.requestHeaderTransform ? t.requestHeaderTransform(n({}, e)) : e;
}
function Me(e, n, t) {
  var r = "/a/" + n + ".gif",
    o = _({
      "Content-Type": "application/json"
    }, _e(e, t)),
    i = e.httpFallbackPing,
    a = {
      sendChunk: function (n, a, u, c) {
        var s = JSON.stringify(n),
          l = u ? null : W();
        return c ? function n(r) {
          var i = u ? o : _({}, o, {
            "X-LaunchDarkly-Event-Schema": "3",
            "X-LaunchDarkly-Payload-ID": l
          });
          return e.httpRequest("POST", a, Ve(i, t), s).promise.then(function (e) {
            if (e) return e.status >= 400 && m(e.status) && r ? n(!1) : function (e) {
              var n = {
                  status: e.status
                },
                t = e.header("date");
              if (t) {
                var r = Date.parse(t);
                r && (n.serverTime = r);
              }
              return n;
            }(e);
          }).catch(function () {
            return r ? n(!1) : Promise.reject();
          });
        }(!0).catch(function () {}) : (i && i(a + r + "?d=" + R(s)), Promise.resolve());
      },
      sendEvents: function (n, t, r) {
        if (!e.httpRequest) return Promise.resolve();
        var o,
          i = e.httpAllowsPost();
        o = i ? [n] : N(2e3 - t.length, n);
        for (var u = [], c = 0; c < o.length; c++) u.push(a.sendChunk(o[c], t, r, i));
        return Promise.all(u);
      }
    };
  return a;
}
function ze() {
  var e = {},
    n = 0,
    t = 0,
    r = {};
  return e.summarizeEvent = function (e) {
    if ("feature" === e.kind) {
      var o = e.key + ":" + (null !== e.variation && void 0 !== e.variation ? e.variation : "") + ":" + (null !== e.version && void 0 !== e.version ? e.version : ""),
        i = r[o];
      i ? i.count = i.count + 1 : r[o] = {
        count: 1,
        key: e.key,
        variation: e.variation,
        version: e.version,
        value: e.value,
        default: e.default
      }, (0 === n || e.creationDate < n) && (n = e.creationDate), e.creationDate > t && (t = e.creationDate);
    }
  }, e.getSummary = function () {
    var e = {},
      o = !0;
    for (var i in r) {
      var a = r[i],
        u = e[a.key];
      u || (u = {
        default: a.default,
        counters: []
      }, e[a.key] = u);
      var c = {
        value: a.value,
        count: a.count
      };
      void 0 !== a.variation && null !== a.variation && (c.variation = a.variation), a.version ? c.version = a.version : c.unknown = !0, u.counters.push(c), o = !1;
    }
    return o ? null : {
      startDate: n,
      endDate: t,
      features: e
    };
  }, e.clearSummary = function () {
    n = 0, t = 0, r = {};
  }, e;
}
function Je(e) {
  var n = {},
    t = e.allAttributesPrivate,
    r = e.privateAttributeNames || [],
    o = {
      key: !0,
      custom: !0,
      anonymous: !0
    },
    i = {
      key: !0,
      secondary: !0,
      ip: !0,
      country: !0,
      email: !0,
      firstName: !0,
      lastName: !0,
      avatar: !0,
      name: !0,
      anonymous: !0,
      custom: !0
    };
  return n.filterUser = function (e) {
    if (!e) return null;
    var n = e.privateAttributeNames || [],
      a = function (e, i) {
        return Object.keys(e).reduce(function (a, u) {
          var c = a;
          return i(u) && (function (e) {
            return !o[e] && (t || -1 !== n.indexOf(e) || -1 !== r.indexOf(e));
          }(u) ? c[1][u] = !0 : c[0][u] = e[u]), c;
        }, [{}, {}]);
      },
      u = a(e, function (e) {
        return i[e];
      }),
      c = u[0],
      s = u[1];
    if (e.custom) {
      var l = a(e.custom, function () {
        return !0;
      });
      c.custom = l[0], s = _({}, s, l[1]);
    }
    var f = Object.keys(s);
    return f.length && (f.sort(), c.privateAttrs = f), c;
  }, n;
}
function $e(e, n, t, r) {
  var o = {};
  function i() {
    var e = "",
      o = r.getUser();
    return o && (e = t || j(JSON.stringify(o))), "ld:" + n + ":" + e;
  }
  return o.loadFlags = function () {
    return e.get(i()).then(function (e) {
      if (null == e) return null;
      try {
        var n = JSON.parse(e);
        if (n) {
          var t = n.$schema;
          void 0 === t || t < 1 ? n = x(n) : delete n.$schema;
        }
        return n;
      } catch (e) {
        return o.clearFlags().then(function () {
          return null;
        });
      }
    });
  }, o.saveFlags = function (n) {
    var t = _({}, n, {
      $schema: 1
    });
    return e.set(i(), JSON.stringify(t));
  }, o.clearFlags = function () {
    return e.clear(i());
  }, o;
}
function He(e, n, t, r) {
  var o,
    i = n.streamUrl,
    a = n.logger,
    u = {},
    c = I(i, "/eval/" + t),
    s = n.useReport,
    l = n.evaluationReasons,
    f = n.streamReconnectDelay,
    d = _e(e, n),
    v = !1,
    g = null,
    p = null,
    h = null,
    y = null,
    w = null,
    b = 0;
  function E(e) {
    if (e.status && "number" == typeof e.status && !m(e.status)) return S(), a.error(he(e)), void (p && (clearTimeout(p), p = null));
    var n = function () {
      var e,
        n = (e = function () {
          var e = f * Math.pow(2, b);
          return e > 3e4 ? 3e4 : e;
        }()) - Math.trunc(.5 * Math.random() * e);
      return b += 1, n;
    }();
    v || (a.warn(me(e, n)), v = !0), O(!1), S(), k(n);
  }
  function k(e) {
    p || (e ? p = setTimeout(D, e) : D());
  }
  function D() {
    var r;
    p = null;
    var u = "",
      f = {
        headers: d,
        readTimeoutMillis: 3e5
      };
    if (e.eventSourceFactory) {
      for (var v in null != y && (u = "h=" + y), s ? e.eventSourceAllowsReport ? (r = c, f.method = "REPORT", f.headers["Content-Type"] = "application/json", f.body = JSON.stringify(h)) : (r = I(i, "/ping/" + t), u = "") : r = c + "/" + R(JSON.stringify(h)), f.headers = Ve(f.headers, n), l && (u = u + (u ? "&" : "") + "withReasons=true"), r = r + (u ? "?" : "") + u, S(), a.info(pe(r)), o = new Date().getTime(), g = e.eventSourceFactory(r, f), w) V(w, v) && g.addEventListener(v, w[v]);
      g.onerror = E, g.onopen = function () {
        b = 0;
      };
    }
  }
  function S() {
    g && (a.info("Closing stream connection"), g.close(), g = null);
  }
  function O(e) {
    o && r && r.recordStreamInit(o, !e, new Date().getTime() - o), o = null;
  }
  return u.connect = function (e, n, t) {
    h = e, y = n, w = {};
    var r = function (e) {
      w[e] = function (n) {
        v = !1, O(!0), t[e] && t[e](n);
      };
    };
    for (var o in t || {}) r(o);
    k();
  }, u.disconnect = function () {
    clearTimeout(p), p = null, S();
  }, u.isConnected = function () {
    return !!(g && e.eventSourceIsActive && e.eventSourceIsActive(g));
  }, u;
}
function Ke(e, n, t) {
  var r = n.baseUrl,
    o = n.useReport,
    i = n.evaluationReasons,
    a = n.logger,
    u = {},
    c = {};
  function s(t, r) {
    if (!e.httpRequest) return new Promise(function (e, n) {
      n(new g(ge()));
    });
    var o = r ? "REPORT" : "GET",
      i = _e(e, n);
    r && (i["Content-Type"] = "application/json");
    var a = c[t];
    a || (a = function (e) {
      var n,
        t,
        r,
        o,
        i = {
          addPromise: function (i, a) {
            n = i, t && t(), t = a, i.then(function (t) {
              n === i && (r(t), e && e());
            }, function (t) {
              n === i && (o(t), e && e());
            });
          }
        };
      return i.resultPromise = new Promise(function (e, n) {
        r = e, o = n;
      }), i;
    }(function () {
      delete c[t];
    }), c[t] = a);
    var u = e.httpRequest(o, t, Ve(i, n), r),
      s = u.promise.then(function (e) {
        if (200 === e.status) {
          if (e.header("content-type") && "application/json" === e.header("content-type").substring(0, "application/json".length)) return JSON.parse(e.body);
          var n = te(e.header("content-type") || "");
          return Promise.reject(new g(n));
        }
        return Promise.reject(function (e) {
          return 404 === e.status ? new l(ae()) : new g(ce(e.statusText || String(e.status)));
        }(e));
      }, function (e) {
        return Promise.reject(new g(oe(e)));
      });
    return a.addPromise(s, function () {
      u.cancel && u.cancel();
    }), a.resultPromise;
  }
  return u.fetchJSON = function (e) {
    return s(I(r, e), null);
  }, u.fetchFlagSettings = function (e, n) {
    var u,
      c,
      l,
      f = "";
    return o ? (c = [r, "/sdk/evalx/", t, "/user"].join(""), l = JSON.stringify(e)) : (u = R(JSON.stringify(e)), c = [r, "/sdk/evalx/", t, "/users/", u].join("")), n && (f = "h=" + n), i && (f = f + (f ? "&" : "") + "withReasons=true"), c = c + (f ? "?" : "") + f, a.debug(ke(c)), s(c, l);
  }, u;
}
function Be(e) {
  var n = {
    validateUser: function (n) {
      if (!n) return Promise.reject(new f(se()));
      var t = T(n);
      return null !== t.key && void 0 !== t.key ? (t.key = t.key.toString(), Promise.resolve(t)) : t.anonymous ? e.get("ld:$anonUserId").then(function (n) {
        if (n) return t.key = n, t;
        var r = W();
        return t.key = r, function (n) {
          return e.set("ld:$anonUserId", n);
        }(r).then(function () {
          return t;
        });
      }) : Promise.reject(new f(le()));
    }
  };
  return n;
}
var Ge = Object.freeze({
    __proto__: null,
    baseOptionDefs: Ae,
    validate: Ne,
    getTags: qe
  }).baseOptionDefs,
  Xe = z.appendUrlPath,
  We = function (e) {
    var n = {
      diagnosticId: W()
    };
    return e && (n.sdkKeySuffix = e.length > 6 ? e.substring(e.length - 6) : e), n;
  },
  Qe = {
    flagUsed: "flag-used",
    flagDetailsChanged: "flag-details-changed",
    flagDetailChanged: "flag-detail-changed",
    clientIdentityChanged: "client-identity-changed"
  };
function Ye(e, n) {
  var t,
    o = {},
    i = (r(t = {}, Qe.flagUsed, []), r(t, Qe.flagDetailsChanged, []), r(t, Qe.flagDetailChanged, []), r(t, Qe.clientIdentityChanged, []), t);
  return (null == e ? void 0 : e.map(function (e) {
    return function (e, n) {
      var t = !1,
        r = {
          type: e.type,
          name: e.name,
          method: function () {
            try {
              e.method.apply(e, arguments);
            } catch (e) {
              t || (t = !0, n.warn(Te(r.type, r.name)));
            }
          }
        };
      return r;
    }(e, n);
  })).forEach(function (e) {
    Object.prototype.hasOwnProperty.call(i, e.type) ? i[e.type].push(e) : n.warn(Re(e.type, e.name));
  }), o.hasListeners = function (e) {
    var n;
    return null === (n = i[e]) || void 0 === n ? void 0 : n.length;
  }, o.onFlagUsed = function (e, n, t) {
    i[Qe.flagUsed].length && L(function () {
      i[Qe.flagUsed].forEach(function (r) {
        return r.method(e, n, t);
      });
    });
  }, o.onFlags = function (e) {
    i[Qe.flagDetailsChanged].length && L(function () {
      i[Qe.flagDetailsChanged].forEach(function (n) {
        return n.method(e);
      });
    });
  }, o.onFlagChanged = function (e, n) {
    i[Qe.flagDetailChanged].length && L(function () {
      i[Qe.flagDetailChanged].forEach(function (t) {
        return t.method(e, n);
      });
    });
  }, o.onIdentityChanged = function (e) {
    i[Qe.clientIdentityChanged].length && L(function () {
      i[Qe.clientIdentityChanged].forEach(function (n) {
        return n.method(e);
      });
    });
  }, o;
}
function Ze(e, r, i, a, u) {
  var c,
    f,
    v,
    h = i && i.logger ? i.logger : u && u.logger && u.logger.default || Z("warn"),
    y = function (e) {
      var n = {},
        t = {};
      return n.on = function (e, n, r) {
        t[e] = t[e] || [], t[e] = t[e].concat({
          handler: n,
          context: r
        });
      }, n.off = function (e, n, r) {
        if (t[e]) for (var o = 0; o < t[e].length; o++) t[e][o].handler === n && t[e][o].context === r && (t[e] = t[e].slice(0, o).concat(t[e].slice(o + 1)));
      }, n.emit = function (e) {
        if (t[e]) for (var n = t[e].slice(0), r = 0; r < n.length; r++) n[r].handler.apply(n[r].context, Array.prototype.slice.call(arguments, 1));
      }, n.getEvents = function () {
        return Object.keys(t);
      }, n.getEventListenerCount = function (e) {
        return t[e] ? t[e].length : 0;
      }, n.maybeReportError = function (n) {
        n && (t.error ? this.emit("error", n) : (e || console).error(n.message));
      }, n;
    }(h),
    w = function (e) {
      var n = !1,
        t = !1,
        r = null,
        o = null,
        i = new Promise(function (n) {
          e.on("ready", function t() {
            e.off("ready", t), n();
          });
        }).catch(function () {});
      return {
        getInitializationPromise: function () {
          return o || (n ? Promise.resolve() : t ? Promise.reject(r) : o = new Promise(function (n, t) {
            e.on("initialized", function t() {
              e.off("initialized", t), n();
            }), e.on("failed", function n(r) {
              e.off("failed", n), t(r);
            });
          }));
        },
        getReadyPromise: function () {
          return i;
        },
        signalSuccess: function () {
          n || t || (n = !0, e.emit("initialized"), e.emit("ready"));
        },
        signalFailure: function (o) {
          n || t || (t = !0, r = o, e.emit("failed", o), e.emit("ready")), e.maybeReportError(o);
        }
      };
    }(y),
    b = Ne(i, y, u, h),
    E = Ye(b.inspectors, h),
    k = b.sendEvents,
    D = e,
    S = b.hash,
    O = function (e, n) {
      var t = {},
        r = !1,
        o = function (e) {
          r || (r = !0, n.warn(re(e)));
        };
      return t.isEnabled = function () {
        return !!e;
      }, t.get = function (n) {
        return new Promise(function (t) {
          e ? e.get(n).then(t).catch(function (e) {
            o(e), t(void 0);
          }) : t(void 0);
        });
      }, t.set = function (n, t) {
        return new Promise(function (r) {
          e ? e.set(n, t).then(function () {
            return r(!0);
          }).catch(function (e) {
            o(e), r(!1);
          }) : r(!1);
        });
      }, t.clear = function (n) {
        return new Promise(function (t) {
          e ? e.clear(n).then(function () {
            return t(!0);
          }).catch(function (e) {
            o(e), t(!1);
          }) : t(!1);
        });
      }, t;
    }(a.localStorage, h),
    P = Me(a, D, b),
    U = b.sendEvents && !b.diagnosticOptOut,
    j = U ? We(D) : null,
    R = U ? function (e) {
      var n, t, r, o;
      function i(e) {
        n = e, t = 0, r = 0, o = [];
      }
      return i(e), {
        getProps: function () {
          return {
            dataSinceDate: n,
            droppedEvents: t,
            eventsInLastBatch: r,
            streamInits: o
          };
        },
        setProps: function (e) {
          n = e.dataSinceDate, t = e.droppedEvents || 0, r = e.eventsInLastBatch || 0, o = e.streamInits || [];
        },
        incrementDroppedEvents: function () {
          t++;
        },
        setEventsInLastBatch: function (e) {
          r = e;
        },
        recordStreamInit: function (e, n, t) {
          var r = {
            timestamp: e,
            failed: n,
            durationMillis: t
          };
          o.push(r);
        },
        reset: i
      };
    }(new Date().getTime()) : null,
    x = U ? function (e, t, r, o, i, a, u) {
      var c,
        s,
        l = !!e.diagnosticUseCombinedEvent,
        f = "ld:" + i + ":$diagnostics",
        d = Xe(a.eventsUrl, "/events/diagnostic/" + i),
        v = a.diagnosticRecordingInterval,
        g = r,
        p = !!a.streaming,
        m = {};
      function h() {
        return {
          sdk: b(),
          configuration: (n = {
            customBaseURI: a.baseUrl !== Ge.baseUrl.default,
            customStreamURI: a.streamUrl !== Ge.streamUrl.default,
            customEventsURI: a.eventsUrl !== Ge.eventsUrl.default,
            eventsCapacity: a.eventCapacity,
            eventsFlushIntervalMillis: a.flushInterval,
            reconnectTimeMillis: a.streamReconnectDelay,
            streamingDisabled: !p,
            allAttributesPrivate: !!a.allAttributesPrivate,
            inlineUsersInEvents: !!a.inlineUsersInEvents,
            diagnosticRecordingIntervalMillis: a.diagnosticRecordingInterval,
            usingSecureMode: !!a.hash,
            bootstrapMode: !!a.bootstrap,
            fetchGoalsDisabled: !a.fetchGoals,
            sendEventsOnlyForVariation: !!a.sendEventsOnlyForVariation,
            autoAliasingOptOut: !!a.autoAliasingOptOut
          }, n),
          platform: e.diagnosticPlatformData
        };
        var n;
      }
      function y(e) {
        a.logger && a.logger.debug(Le.debugPostingDiagnosticEvent(e)), o.sendEvents(e, d, !0).then(function () {}).catch(function () {});
      }
      function w() {
        var e, r;
        y((e = new Date().getTime(), r = n({
          kind: l ? "diagnostic-combined" : "diagnostic",
          id: u,
          creationDate: e
        }, g.getProps()), l && (r = n(n({}, r), h())), g.reset(e), r)), s = setTimeout(w, v), c = new Date().getTime(), l && function () {
          if (t.isEnabled()) {
            var e = n({}, g.getProps());
            t.set(f, JSON.stringify(e));
          }
        }();
      }
      function b() {
        var t = n({}, e.diagnosticSdkData);
        return a.wrapperName && (t.wrapperName = a.wrapperName), a.wrapperVersion && (t.wrapperVersion = a.wrapperVersion), t;
      }
      return m.start = function () {
        l ? function (e) {
          if (!t.isEnabled()) return e(!1);
          t.get(f).then(function (n) {
            if (n) try {
              var t = JSON.parse(n);
              g.setProps(t), c = t.dataSinceDate;
            } catch (e) {}
            e(!0);
          }).catch(function () {
            e(!1);
          });
        }(function (e) {
          if (e) {
            var n = (c || 0) + v,
              t = new Date().getTime();
            t >= n ? w() : s = setTimeout(w, n - t);
          } else 0 === Math.floor(4 * Math.random()) ? w() : s = setTimeout(w, v);
        }) : (y(n({
          kind: "diagnostic-init",
          id: u,
          creationDate: g.getProps().dataSinceDate
        }, h())), s = setTimeout(w, v));
      }, m.stop = function () {
        s && clearTimeout(s);
      }, m.setStreaming = function (e) {
        p = e;
      }, m;
    }(a, O, R, P, D, b, j) : null,
    N = He(a, b, D, R),
    q = b.eventProcessor || function (e, n, t) {
      var r,
        o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null,
        i = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : null,
        a = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : null,
        u = {},
        c = a || Me(e, t, n),
        l = I(n.eventsUrl, "/events/bulk/" + t),
        f = ze(),
        d = Je(n),
        v = n.inlineUsersInEvents,
        g = n.samplingInterval,
        p = n.eventCapacity,
        h = n.flushInterval,
        y = n.logger,
        w = [],
        b = 0,
        E = !1,
        k = !1;
      function D() {
        return 0 === g || 0 === Math.floor(Math.random() * g);
      }
      function S(e) {
        return !!e.debugEventsUntilDate && e.debugEventsUntilDate > b && e.debugEventsUntilDate > new Date().getTime();
      }
      function O(e) {
        var n = _({}, e);
        return "alias" === e.kind || (v || "identify" === e.kind ? n.user = d.filterUser(e.user) : (n.userKey = e.user.key, delete n.user), "feature" === e.kind && (delete n.trackEvents, delete n.debugEventsUntilDate)), n;
      }
      function P(e) {
        w.length < p ? (w.push(e), k = !1) : (k || (k = !0, y.warn("Exceeded event queue capacity. Increase capacity to avoid dropping events.")), o && o.incrementDroppedEvents());
      }
      return u.enqueue = function (e) {
        if (!E) {
          var n = !1,
            t = !1;
          if (f.summarizeEvent(e), "feature" === e.kind ? D() && (n = !!e.trackEvents, t = S(e)) : n = D(), n && P(O(e)), t) {
            var r = _({}, e, {
              kind: "debug"
            });
            r.user = d.filterUser(r.user), delete r.trackEvents, delete r.debugEventsUntilDate, P(r);
          }
        }
      }, u.flush = function () {
        if (E) return Promise.resolve();
        var e = w,
          n = f.getSummary();
        return f.clearSummary(), n && (n.kind = "summary", e.push(n)), o && o.setEventsInLastBatch(e.length), 0 === e.length ? Promise.resolve() : (w = [], y.debug(Ie(e.length)), c.sendEvents(e, l).then(function (e) {
          e && (e.serverTime && (b = e.serverTime), m(e.status) || (E = !0), e.status >= 400 && L(function () {
            i.maybeReportError(new s(ve(e.status, "event posting", "some events were dropped")));
          }));
        }));
      }, u.start = function () {
        r = setTimeout(function e() {
          u.flush(), r = setTimeout(e, h);
        }, h);
      }, u.stop = function () {
        clearTimeout(r);
      }, u;
    }(a, b, D, R, y, P),
    z = Ke(a, b, D),
    J = {},
    $ = b.streaming,
    H = !1,
    K = !1,
    B = !0,
    G = b.stateProvider,
    X = function (e, n) {
      var t,
        r = {
          setUser: function (e) {
            var r = t && T(t);
            (t = M(e)) && n && n(T(t), r);
          },
          getUser: function () {
            return t ? T(t) : null;
          }
        };
      return r;
    }(0, function (e, n) {
      (function (e) {
        G || e && Y({
          kind: "identify",
          key: e.key,
          user: e,
          creationDate: new Date().getTime()
        });
      })(e), !b.autoAliasingOptOut && n && n.anonymous && e && !e.anonymous && le(e, n), E.hasListeners(Qe.clientIdentityChanged) && E.onIdentityChanged(X.getUser());
    }),
    W = Be(O),
    Q = O.isEnabled() ? new $e(O, D, S, X) : null;
  function Y(e) {
    if (D && !(G && G.enqueueEvent && G.enqueueEvent(e))) {
      if ("alias" !== e.kind) {
        if (!e.user) return void (B && (h.warn("Be sure to call `identify` in the LaunchDarkly client: https://docs.launchdarkly.com/sdk/features/identify#javascript"), B = !1));
        B = !1;
      }
      !k || K || a.isDoNotTrack() || (h.debug(Ue(e.kind)), q.enqueue(e));
    }
  }
  function ee(e, n) {
    E.hasListeners(Qe.flagDetailChanged) && E.onFlagChanged(e.key, ae(n));
  }
  function ne() {
    E.hasListeners(Qe.flagDetailsChanged) && E.onFlags(Object.entries(J).map(function (e) {
      var n = o(e, 2);
      return {
        key: n[0],
        detail: ae(n[1])
      };
    }).reduce(function (e, n) {
      return e[n.key] = n.detail, e;
    }, {}));
  }
  function te(e, n, t, r) {
    var o = X.getUser(),
      i = new Date(),
      a = {
        kind: "feature",
        key: e,
        user: o,
        value: n ? n.value : null,
        variation: n ? n.variationIndex : null,
        default: t,
        creationDate: i.getTime()
      };
    o && o.anonymous && (a.contextKind = se(o));
    var u = J[e];
    u && (a.version = u.flagVersion ? u.flagVersion : u.version, a.trackEvents = u.trackEvents, a.debugEventsUntilDate = u.debugEventsUntilDate), (r || u && u.trackReason) && n && (a.reason = n.reason), Y(a);
  }
  function oe(e, n, t, r, o) {
    var i;
    if (J && V(J, e) && J[e] && !J[e].deleted) {
      var a = J[e];
      i = ae(a), null !== a.value && void 0 !== a.value || (i.value = n);
    } else i = {
      value: n,
      variationIndex: null,
      reason: {
        kind: "ERROR",
        errorKind: "FLAG_NOT_FOUND"
      }
    };
    return t && te(e, i, n, r), o || function (e, n) {
      E.hasListeners(Qe.flagUsed) && E.onFlagUsed(e, n, X.getUser());
    }(e, i), i;
  }
  function ae(e) {
    return {
      value: e.value,
      variationIndex: void 0 === e.variation ? null : e.variation,
      reason: e.reason || null
    };
  }
  function se(e) {
    return e.anonymous ? "anonymousUser" : "user";
  }
  function le(e, n) {
    G || e && n && Y({
      kind: "alias",
      key: e.key,
      contextKind: se(e),
      previousKey: n.key,
      previousContextKind: se(n),
      creationDate: new Date().getTime()
    });
  }
  function de() {
    if (f = !0, X.getUser()) {
      var e = function (e) {
        try {
          return JSON.parse(e);
        } catch (e) {
          return void y.maybeReportError(new p("Invalid data received from LaunchDarkly; connection may have been interrupted"));
        }
      };
      N.connect(X.getUser(), S, {
        ping: function () {
          h.debug("received ping message from stream");
          var e = X.getUser();
          z.fetchFlagSettings(e, S).then(function (n) {
            C(e, X.getUser()) && pe(n || {});
          }).catch(function (e) {
            y.maybeReportError(new g(ce(e)));
          });
        },
        put: function (n) {
          var t = e(n.data);
          t && (h.debug("received streaming update for all flags"), pe(t));
        },
        patch: function (n) {
          var t = e(n.data);
          if (t) {
            var r = J[t.key];
            if (!r || !r.version || !t.version || r.version < t.version) {
              h.debug(De(t.key));
              var o = {},
                i = _({}, t);
              delete i.key, J[t.key] = i;
              var a = ae(i);
              o[t.key] = r ? {
                previous: r.value,
                current: a
              } : {
                current: a
              }, me(o), ee(t, i);
            } else h.debug(Se(t.key));
          }
        },
        delete: function (n) {
          var t = e(n.data);
          if (t) if (!J[t.key] || J[t.key].version < t.version) {
            h.debug(Oe(t.key));
            var r = {};
            J[t.key] && !J[t.key].deleted && (r[t.key] = {
              previous: J[t.key].value
            }), J[t.key] = {
              version: t.version,
              deleted: !0
            }, ee(t, J[t.key]), me(r);
          } else h.debug(Pe(t.key));
        }
      });
    }
  }
  function ge() {
    f && (N.disconnect(), f = !1);
  }
  function pe(e) {
    var t = {};
    if (!e) return Promise.resolve();
    for (var r in J) V(J, r) && J[r] && (e[r] && !C(e[r].value, J[r].value) ? t[r] = {
      previous: J[r].value,
      current: ae(e[r])
    } : e[r] && !e[r].deleted || (t[r] = {
      previous: J[r].value
    }));
    for (var o in e) V(e, o) && e[o] && (!J[o] || J[o].deleted) && (t[o] = {
      current: ae(e[o])
    });
    return J = n({}, e), ne(), me(t).catch(function () {});
  }
  function me(e) {
    var n = Object.keys(e);
    if (n.length > 0) {
      var t = {};
      n.forEach(function (n) {
        var r = e[n].current,
          o = r ? r.value : void 0,
          i = e[n].previous;
        y.emit("change:" + n, o, i), t[n] = r ? {
          current: o,
          previous: i
        } : {
          previous: i
        };
      }), y.emit("change", t), y.emit("internal-change", J), b.sendEventsOnlyForVariation || G || n.forEach(function (n) {
        te(n, e[n].current);
      });
    }
    return c && Q ? Q.saveFlags(J) : Promise.resolve();
  }
  function he() {
    var e = $ || v && void 0 === $;
    e && !f ? de() : !e && f && ge(), x && x.setStreaming(e);
  }
  function ye(e) {
    return "change" === e || "change:" === e.substr(0, "change".length + 1);
  }
  if ("string" == typeof b.bootstrap && "LOCALSTORAGE" === b.bootstrap.toUpperCase() && (Q ? c = !0 : h.warn(re())), "object" === t(b.bootstrap) && (J = function (e) {
    var n = Object.keys(e),
      t = e.$flagsState;
    !t && n.length && h.warn(fe()), !1 === e.$valid && h.warn("LaunchDarkly bootstrap data is not available because the back end could not read the flags.");
    var r = {};
    return n.forEach(function (n) {
      if ("$flagsState" !== n && "$valid" !== n) {
        var o = {
          value: e[n]
        };
        t && t[n] ? o = _(o, t[n]) : o.version = 0, r[n] = o;
      }
    }), r;
  }(b.bootstrap)), G) {
    var we = G.getInitialState();
    we ? be(we) : G.on("init", be), G.on("update", function (e) {
      e.user && X.setUser(e.user), e.flags && pe(e.flags);
    });
  } else (e ? W.validateUser(r).then(function (e) {
    return X.setUser(e), "object" === t(b.bootstrap) ? Ee() : c ? Q.loadFlags().then(function (e) {
      return null == e ? (J = {}, z.fetchFlagSettings(X.getUser(), S).then(function (e) {
        return pe(e || {});
      }).then(Ee).catch(function (e) {
        ke(new g(ce(e)));
      })) : (J = e, L(Ee), z.fetchFlagSettings(X.getUser(), S).then(function (e) {
        return pe(e);
      }).catch(function (e) {
        return y.maybeReportError(e);
      }));
    }) : z.fetchFlagSettings(X.getUser(), S).then(function (e) {
      J = e || {}, ne(), Ee();
    }).catch(function (e) {
      J = {}, ke(e);
    });
  }) : Promise.reject(new l(ue()))).catch(ke);
  function be(e) {
    D = e.environment, X.setUser(e.user), J = n({}, e.flags), L(Ee);
  }
  function Ee() {
    h.info("LaunchDarkly client initialized"), H = !0, he(), w.signalSuccess();
  }
  function ke(e) {
    w.signalFailure(e);
  }
  return {
    client: {
      waitForInitialization: function () {
        return w.getInitializationPromise();
      },
      waitUntilReady: function () {
        return w.getReadyPromise();
      },
      identify: function (e, n, t) {
        return K ? A(Promise.resolve({}), t) : G ? (h.warn("identify() has no effect here; it must be called on the main client instance"), A(Promise.resolve(F(J)), t)) : A((c && Q ? Q.clearFlags() : Promise.resolve()).then(function () {
          return W.validateUser(e);
        }).then(function (e) {
          return z.fetchFlagSettings(e, n).then(function (t) {
            var r = F(t);
            return X.setUser(e), S = n, t ? pe(t).then(function () {
              return r;
            }) : r;
          });
        }).then(function (e) {
          return f && de(), e;
        }).catch(function (e) {
          return y.maybeReportError(e), Promise.reject(e);
        }), t);
      },
      getUser: function () {
        return X.getUser();
      },
      variation: function (e, n) {
        return oe(e, n, !0, !1, !1).value;
      },
      variationDetail: function (e, n) {
        return oe(e, n, !0, !0, !1);
      },
      track: function (e, n, t) {
        if ("string" == typeof e) {
          a.customEventFilter && !a.customEventFilter(e) && h.warn(ie(e));
          var r = X.getUser(),
            o = {
              kind: "custom",
              key: e,
              user: r,
              url: a.getCurrentUrl(),
              creationDate: new Date().getTime()
            };
          r && r.anonymous && (o.contextKind = se(r)), null != n && (o.data = n), null != t && (o.metricValue = t), Y(o);
        } else y.maybeReportError(new d(ie(e)));
      },
      alias: le,
      on: function (e, n, t) {
        ye(e) ? (v = !0, H && he(), y.on(e, n, t)) : y.on.apply(y, arguments);
      },
      off: function (e) {
        if (y.off.apply(y, arguments), ye(e)) {
          var n = !1;
          y.getEvents().forEach(function (e) {
            ye(e) && y.getEventListenerCount(e) > 0 && (n = !0);
          }), n || (v = !1, f && void 0 === $ && ge());
        }
      },
      setStreaming: function (e) {
        var n = null === e ? void 0 : e;
        n !== $ && ($ = n, he());
      },
      flush: function (e) {
        return A(k ? q.flush() : Promise.resolve(), e);
      },
      allFlags: function () {
        var e = {};
        if (!J) return e;
        for (var n in J) V(J, n) && !J[n].deleted && (e[n] = oe(n, null, !b.sendEventsOnlyForVariation, !1, !0).value);
        return e;
      },
      close: function (e) {
        if (K) return A(Promise.resolve(), e);
        var n = function () {
          K = !0, J = {};
        };
        return A(Promise.resolve().then(function () {
          if (ge(), x && x.stop(), k) return q.stop(), q.flush();
        }).then(n).catch(n), e);
      }
    },
    options: b,
    emitter: y,
    ident: X,
    logger: h,
    requestor: z,
    start: function () {
      k && (x && x.start(), q.start());
    },
    enqueueEvent: Y,
    getFlagsInternal: function () {
      return J;
    },
    getEnvironmentId: function () {
      return D;
    },
    internalChangeEventName: "internal-change"
  };
}
Object.freeze(Qe);
function en(e, n) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    n && (r = r.filter(function (n) {
      return Object.getOwnPropertyDescriptor(e, n).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function nn(e) {
  for (var n = 1; n < arguments.length; n++) {
    var t = null != arguments[n] ? arguments[n] : {};
    n % 2 ? en(Object(t), !0).forEach(function (n) {
      tn(e, n, t[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : en(Object(t)).forEach(function (n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(t, n));
    });
  }
  return e;
}
function tn(e, n, t) {
  return n in e ? Object.defineProperty(e, n, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[n] = t, e;
}
var rn = Object.freeze({
  __proto__: null,
  commonBasicLogger: Y,
  createConsoleLogger: Z,
  get errors() {
    return h;
  },
  initialize: Ze,
  messages: Le,
  utils: z,
  version: "3.8.2"
}).commonBasicLogger;
var on = function (e) {
  return rn(nn({
    destination: console.log
  }, e));
};
var an = {
  promise: Promise.resolve({
    status: 200,
    header: function () {
      return null;
    },
    body: null
  })
};
function un(e, n, t, r, o) {
  if (o && !function () {
    var e = window.navigator && window.navigator.userAgent;
    if (e) {
      var n = e.match(/Chrom(e|ium)\/([0-9]+)\./);
      if (n) return parseInt(n[2], 10) < 73;
    }
    return !0;
  }()) return an;
  var i = new window.XMLHttpRequest();
  for (var a in i.open(e, n, !o), t || {}) Object.prototype.hasOwnProperty.call(t, a) && i.setRequestHeader(a, t[a]);
  if (o) return i.send(r), an;
  var u,
    c = new Promise(function (e, n) {
      i.addEventListener("load", function () {
        u || e({
          status: i.status,
          header: function (e) {
            return i.getResponseHeader(e);
          },
          body: i.responseText
        });
      }), i.addEventListener("error", function () {
        u || n(new Error());
      }), i.send(r);
    });
  return {
    promise: c,
    cancel: function () {
      u = !0, i.abort();
    }
  };
}
function cn(e) {
  var n,
    t = {
      synchronousFlush: !1
    };
  if (window.XMLHttpRequest) {
    var r = e && e.disableSyncEventPost;
    t.httpRequest = function (e, n, o, i) {
      var a = t.synchronousFlush & !r;
      return t.synchronousFlush = !1, un(e, n, o, i, a);
    };
  }
  t.httpAllowsPost = function () {
    return void 0 === n && (n = !!window.XMLHttpRequest && "withCredentials" in new window.XMLHttpRequest()), n;
  }, t.httpFallbackPing = function (e) {
    new window.Image().src = e;
  };
  var o,
    i = e && e.eventUrlTransformer;
  t.getCurrentUrl = function () {
    return i ? i(window.location.href) : window.location.href;
  }, t.isDoNotTrack = function () {
    var e;
    return 1 === (e = window.navigator && void 0 !== window.navigator.doNotTrack ? window.navigator.doNotTrack : window.navigator && void 0 !== window.navigator.msDoNotTrack ? window.navigator.msDoNotTrack : window.doNotTrack) || !0 === e || "1" === e || "yes" === e;
  };
  try {
    window.localStorage && (t.localStorage = {
      get: function (e) {
        return new Promise(function (n) {
          n(window.localStorage.getItem(e));
        });
      },
      set: function (e, n) {
        return new Promise(function (t) {
          window.localStorage.setItem(e, n), t();
        });
      },
      clear: function (e) {
        return new Promise(function (n) {
          window.localStorage.removeItem(e), n();
        });
      }
    });
  } catch (e) {
    t.localStorage = null;
  }
  if (e && e.useReport && "function" == typeof window.EventSourcePolyfill && window.EventSourcePolyfill.supportedOptions && window.EventSourcePolyfill.supportedOptions.method ? (t.eventSourceAllowsReport = !0, o = window.EventSourcePolyfill) : (t.eventSourceAllowsReport = !1, o = window.EventSource), window.EventSource) {
    var a = 3e5;
    t.eventSourceFactory = function (e, n) {
      var t = nn(nn({}, {
        heartbeatTimeout: a,
        silentTimeout: a,
        skipDefaultHeaders: !0
      }), n);
      return new o(e, t);
    }, t.eventSourceIsActive = function (e) {
      return e.readyState === window.EventSource.OPEN || e.readyState === window.EventSource.CONNECTING;
    };
  }
  return t.userAgent = "JSClient", t.version = "2.24.2", t.diagnosticSdkData = {
    name: "js-client-sdk",
    version: "2.24.2"
  }, t.diagnosticPlatformData = {
    name: "JS"
  }, t.diagnosticUseCombinedEvent = !0, t;
}
var sn = e => {
  if ("string" != typeof e) throw new TypeError("Expected a string");
  return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
};
function ln(e, n, t, r) {
  var o,
    i,
    a = (("substring" === e.kind || "regex" === e.kind) && r.includes("/") ? n : n.replace(r, "")).replace(t, "");
  switch (e.kind) {
    case "exact":
      i = n, o = new RegExp("^" + sn(e.url) + "/?$");
      break;
    case "canonical":
      i = a, o = new RegExp("^" + sn(e.url) + "/?$");
      break;
    case "substring":
      i = a, o = new RegExp(".*" + sn(e.substring) + ".*$");
      break;
    case "regex":
      i = a, o = new RegExp(e.pattern);
      break;
    default:
      return !1;
  }
  return o.test(i);
}
function fn(e, n) {
  for (var t = {}, r = null, o = [], i = 0; i < e.length; i++) for (var a = e[i], u = a.urls || [], c = 0; c < u.length; c++) if (ln(u[c], window.location.href, window.location.search, window.location.hash)) {
    "pageview" === a.kind ? n("pageview", a) : (o.push(a), n("click_pageview", a));
    break;
  }
  return o.length > 0 && (r = function (e) {
    for (var t = function (e, n) {
        for (var t = [], r = 0; r < n.length; r++) for (var o = e.target, i = n[r], a = i.selector, u = document.querySelectorAll(a); o && u.length > 0;) {
          for (var c = 0; c < u.length; c++) o === u[c] && t.push(i);
          o = o.parentNode;
        }
        return t;
      }(e, o), r = 0; r < t.length; r++) n("click", t[r]);
  }, document.addEventListener("click", r)), t.dispose = function () {
    document.removeEventListener("click", r);
  }, t;
}
function dn(e, n) {
  var t, r;
  function o() {
    r && r.dispose(), t && t.length && (r = fn(t, i));
  }
  function i(n, t) {
    var r = e.ident.getUser(),
      o = {
        kind: n,
        key: t.key,
        data: null,
        url: window.location.href,
        user: r,
        creationDate: new Date().getTime()
      };
    return r && r.anonymous && (o.contextKind = "anonymousUser"), "click" === n && (o.selector = t.selector), e.enqueueEvent(o);
  }
  return e.requestor.fetchJSON("/sdk/goals/" + e.getEnvironmentId()).then(function (e) {
    e && e.length > 0 && (r = fn(t = e, i), function (e, n) {
      var t,
        r = window.location.href;
      function o() {
        (t = window.location.href) !== r && (r = t, n());
      }
      !function e(n, t) {
        n(), setTimeout(function () {
          e(n, t);
        }, t);
      }(o, e), window.history && window.history.pushState ? window.addEventListener("popstate", o) : window.addEventListener("hashchange", o);
    }(300, o)), n();
  }).catch(function (t) {
    e.emitter.maybeReportError(new h.LDUnexpectedResponseError((t && t.message, t.message))), n();
  }), {};
}
var vn = {
  fetchGoals: {
    default: !0
  },
  hash: {
    type: "string"
  },
  eventProcessor: {
    type: "object"
  },
  eventUrlTransformer: {
    type: "function"
  },
  disableSyncEventPost: {
    default: !1
  }
};
function gn(e, n) {
  var t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
    r = cn(t),
    o = Ze(e, n, t, r, vn),
    i = o.client,
    a = o.options,
    u = o.emitter,
    c = new Promise(function (e) {
      var n = u.on("goalsReady", function () {
        u.off("goalsReady", n), e();
      });
    });
  i.waitUntilGoalsReady = function () {
    return c;
  }, a.fetchGoals ? dn(o, function () {
    return u.emit("goalsReady");
  }) : u.emit("goalsReady"), "complete" !== document.readyState ? window.addEventListener("load", o.start) : o.start();
  var s = function () {
    r.synchronousFlush = !0, i.flush().catch(function () {}), r.synchronousFlush = !1;
  };
  return window.addEventListener("beforeunload", s), window.addEventListener("unload", s), i;
}
var pn = on,
  mn = Z,
  hn = "2.24.2";
var yn = {
  initialize: function (e, n) {
    var t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
    return console && console.warn && console.warn(Le.deprecated("default export", "named LDClient export")), gn(e, n, t);
  },
  version: "2.24.2"
};
export { pn as basicLogger, mn as createConsoleLogger, yn as default, gn as initialize, hn as version };
