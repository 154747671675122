<div class="annotations-component">
	<div class="H3Black500">ANNOTATIONS</div>

	<div class="options">
		<button (click)="reactMode = false" [ngStyle]="getColor(reactMode, false)" mat-stroked-button>Angular</button>
		<button (click)="reactMode = true" [ngStyle]="getColor(reactMode, true)" mat-stroked-button>React</button>
	</div>

	<ng-container *ngIf="!reactMode">
		<div class="project-container">
			<div *ngFor="let project of projects" class="outlined-container">
				<div class="H3Black500">{{ project.name }}</div>
				<div class="H6Black500Semibold">ID: {{ project.id }}</div>

				<div class="annotation-tools">
					<div class="annotation-create">
						<mat-form-field><input [(ngModel)]="project.annotationName" matInput></mat-form-field>
						<button (click)="makeAnnotation(project)" color="primary" mat-icon-button>
							<mat-icon>add</mat-icon>
						</button>
					</div>
					<div *ngIf="createdAnnotation" class="annotation-created">ProjectAnnotations Created:
						<br>{{ createdAnnotation }}</div>
				</div>

				<button (click)="getAnnotations(project)" *ngIf="!project.annotations" color="primary"
						mat-stroked-button>Get Annotations
				</button>
				<div *ngFor="let annotation of project.annotations" class="annotations">
					<ng-container *ngIf="!annotation.isEdit">
						Name: {{ annotation.name }} <br>
						Id: {{ annotation.id }}
						<button (click)="toggleEdit(annotation)" mat-icon-button matTooltip="Edit">
							<mat-icon> edit</mat-icon>
						</button>
						<button (click)="deleteAnnotation(annotation, project)" mat-icon-button matTooltip="Remove">
							<mat-icon> delete_outline</mat-icon>
						</button>
					</ng-container>
					<ng-container *ngIf="annotation.isEdit">
						<mat-form-field><input [(ngModel)]="annotation.annotationName" matInput></mat-form-field>
						<button (click)="updateAnnotation(annotation, project)" mat-icon-button matTooltip="Update">
							<mat-icon> check</mat-icon>
						</button>
						<button (click)="toggleEdit(annotation)" mat-icon-button matTooltip="Cancel Edit">
							<mat-icon> close</mat-icon>
						</button>
					</ng-container>
				</div>
				<div *ngIf="project.annotations && !project.annotations.length" class="P1Black500"> No Annotations</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="reactMode">
		<portal-hook selector="annotations"></portal-hook>
	</ng-container>
</div>
