
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {sideNavModel} from "@app/components/sidenav/sidenav-list-item/sidenav-list-item";
import { UtilsService, AuthenticationService, SessionService, UserService } from "@shared/services";
import { SidenavModel } from "@shared/models";

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.html',
	styleUrls: ['./sidenav.scss']
})

export class AerialSidenav {

	@Input() set sidenavInput(input: SidenavModel) {
		Object.assign(this, input);
	};
	@Input("paywall") disabled = false;
	@Output() primaryButtonEvent = new EventEmitter<boolean>();
	@Output("openFly") flyPromoButtonEvent = new EventEmitter<boolean>();

	public isMobile: boolean = false;
	public expandSidenav = true;
	public supportLink = "";
	public supportGuide = "";
	public navItems: NavItem[];
	public primaryItem: NavItem;
	public appName: string = "";

	constructor(
		private _utilsService: UtilsService,
		private _dialog: MatDialog,
		private _authService: AuthenticationService,
		private _userService: UserService
	) {

		this.isMobile = this._utilsService.getIsMobile();
		this.expandSidenav = !this.isMobile;

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.isMobile = isMobile;
			this.expandSidenav = !isMobile;
		})
	}

	public expandSidenavItem = (expandSidenav): sideNavModel =>
		({
			icon: expandSidenav ? 'unfold_less' : 'unfold_more',
			name: expandSidenav ? 'Collapse' : 'Expand',
			rotateIcon: true
		});

	public supportSidenavItem = {icon: 'contact_support', name: 'Help & Feedback'};

	toggleSidenav(): void {

		this.expandSidenav = !this.expandSidenav;

	}	// End-of toggleSideNav

	primaryButtonClick(): void {
		this.primaryButtonEvent.emit(true);
	}

	sendFeedback(): void {
		window.open('mailto:' + this.supportLink, '_blank');
	}

	openFAQ(): void {
		window.open(this.supportGuide, '_blank');
	}

	openMapwareFly(): void {
		this.flyPromoButtonEvent.emit(true);
	}

}	// End-of class AerialSidenav

export interface NavItem {
	name: string;
	tooltip: string;
	icon: string;
	active?: boolean;
	route?: string | string[];
	externalURL?: string;
}
