import { Model } from "@shared/models/model.model";
import { MatTableDataSource } from "@angular/material/table";

type State = "pending" | "inprogress" | "completed";

// https://aerialapplications0.sharepoint.com/:x:/s/Engineering/EUP4U97omZ9FrpbbR5DJp48BlYYQRST1UOfN9sLOshqLsw?e=1buJRK
const mpOffset = 0.006; // Calculated from sheet, offset in days
const mpScale = 1.48E-05; // Calculated from sheet, ratio of MP to days
const buffer = 1.1; // Custom buffer, to add artificial time to the render estimation

export const getMegapixelsFromDays = (days) => Math.max(10, (days -  mpOffset) / (mpScale * buffer)); // Returns time in days (accounts for 10% buffer), min of 10MP
export const getDaysFromMegapixels = (megapixels) =>  mpScale * megapixels * buffer + mpOffset; // Returns time in days (with an extra 10% buffer)

export class Status {
	id: number;
	operation_id: number;
	task: string;
	status: State;
	description: string;
	active: number;
	created_at: string;
	updated_at: string;
	size?: number; // Not from the API
	time_scale?: number; // Not from the API

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
};

export class Operation {
	id: number;
	process: string;
	order_id: number;
	operation_number: number;
	active: number;
	created_by_user_id: number;
	created_at: string;
	updated_at: string;
	status: Status[];
	descriptors?: {
		image_count: number;
		key_points: number;
		mp: number;
		resolution: string;
		tie_points: number;
	}

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
};

export class Order {
	active: number;
	created_at: string;
	created_by_user_id: number;
	descriptors: {
		resolution: string;
		key_points: number;
		tie_points: number;
		mp: number;
		image_count: number
	};
	project: any;
	id: number;
	model: Model;
	model_id: number;
	operations: Operation[];
	type: string;
	updated_at: string;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
}

export class DisplayStatus {
	task: string;
	status: State;
	title: string;
	size: number;
	time_scale?: number;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
};

export class DisplayOperation {
	id: number;
	process: string; // Process Type
	project_name: string; // Project Name
	name: string; // Name
	elapsed_time: string; // Elapsed Time
	status: string
	originalStatuses: Status[];
	statuses: DisplayStatus[];
	open: boolean;
	model: Model;
	dataSource: MatTableDataSource<any>;
	remaining_time: string;
	created_at: string;
	updated_at: string;
	isMetashape: boolean;
	order: Order;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
};
