

<div class="register-inputs" *ngIf="registerForm">
	<form novalidate name="form" [formGroup]="registerForm" id="login-form">
		<div class="input-full-size flex-row-desktop" [ngClass]="{'invalid': firstNameInvalid || lastNameInvalid}">
			<!--First Name-->
			<div class="input-half-size-left" [ngClass]="{'invalid': firstNameInvalid}">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>First name</mat-label>
					<input matInput type="search" name="fname" autocomplete="off"
						placeholder="John" formControlName="first_name" />
				</mat-form-field>
			</div>

			<!-- Last Name -->
			<div class="input-half-size" [ngClass]="{'invalid': lastNameInvalid}">
				<mat-form-field appearance="fill" subscriptSizing="dynamic">
					<mat-label>Last name</mat-label>
					<input matInput type="search" name="lname" autocomplete="off"
						placeholder="Doe" formControlName="last_name" />
				</mat-form-field>
			</div>
		</div>
		<div class="error-text" *ngIf="firstNameInvalid || lastNameInvalid">
			Names may not contain special characters and may not start or end with spaces
		</div>

		<!-- Email -->
		<div class="input-full-size" [ngClass]="{'invalid': emailInvalid}">
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>Email address</mat-label>
				<input matInput type="text" name="email" autocomplete="email" (keyup)="onEmailChange()"
					placeholder="john.doe@email.com" formControlName="email" data-testid="register-email"/>
			</mat-form-field>
			<div class="error-text" *ngIf="emailInvalid">
				Please enter a valid email
			</div>
			<div class="error-text" *ngIf="emailAlreadyRegistered">
				This email is already associated with an account. Please sign in or reset your password
			</div>
		</div>

		<!-- Password -->
		<div class="input-full-size" [ngClass]="{'invalid': passwordInvalid}">
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>Password</mat-label>
				<input matInput [type]="showingPassword ? 'text' : 'password'" name="new-password" autocomplete="new-password" autofill
					placeholder="********" formControlName="password" />
			</mat-form-field>
			<div class="password-input-header">
				<span class="H7Black500Regular">
					<mat-checkbox data-testid="show-password" (click)="showingPassword = !showingPassword; $event.stopPropagation()"></mat-checkbox>
					Show Password
				</span>
			</div>
		</div>

		<div class="P2Black500 pw-reqs" [ngClass]="{'invalid-pw-reqs': !passwordReqsMet && password.touched}" >
			Passwords must be no fewer than 8 characters and contain at least one of each of the following: Uppercase and lowercase letters, numbers, and special characters.
		</div>

	</form>
</div>
