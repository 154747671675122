<div class="sidebar-container" [ngClass]="{'sidebar-collapsed': !expanded}">
	<div class="side-tab-button" (click)="toggleSidebar()"
		matTooltip="{{expanded ? 'Collapse' : 'Expand'}}" matTooltipPosition="{{expanded ? 'before' : 'after'}}">
		<mat-icon>
			chevron_right
		</mat-icon>
	</div>

	<div class="sidebar-header flex-horz">

		<div class="flex-left">
			<div class="header-label">
				Map Data
			</div>
			<!-- hiding this button for now until the new upload / import flow is ready - nic
			<button class="inline-icon-button"
				(click)="openAddLayerDialog();" *ngIf="!publicView">
				<mat-icon>
					add
				</mat-icon>
			</button>
			-->
		</div>

		<div class="flex-right button-group">
			<ng-container *ngIf="!publicView">
				<button
					data-testid="export_button"
					*featureEnabled="'!disable-exports'"
					(click)="openExportDialog();"
					class="action-button"
					[ngClass]="{'disabled': !mapPermissions.canExport}"
					[disabled]="!models?.length || !mapPermissions.canExport">
					<span style="display: flex; margin: auto;">
						<span class="white-thin"> Export </span>
					</span>
				</button>
			</ng-container>
			<button
				data-testid="share_button"
				*featureEnabled="'!disable-share'"
				(click)="openShareLinkDialog();"
				[disabled]="!models?.length"
				class="action-button">
				<span style="display: flex; margin: auto;">
					<span class="white-thin"> Share </span>
				</span>
			</button>
		</div>
	</div>

	<div class="data-filter-row flex-horz">
		<div class="data-filter"
			[ngClass]="{'selected': dataFilter === 'layers'}"
			(click)="filterLayers('layers');">
			layers
		</div>

		<div class="data-filter"
			*ngIf="!publicView"
			[ngClass]="{'selected': dataFilter === 'annotations'}"
			(click)="filterLayers('annotations');">
			annotations
		</div>
		<div class="data-filter"
			[ngClass]="{'selected': dataFilter === 'images'}"
			(click)="filterLayers('images');">
			images
		</div>
		<ng-container *ngIf="!publicView">
			<div class="data-filter"
				[ngClass]="{'selected': dataFilter === 'labels'}"
				(click)="filterLayers('labels');"
				*featureEnabled="'temp-labelling-tool'">
				labels
			</div>
		</ng-container>
	</div>

	<div class="data-list" *ngIf="dataFilter === 'layers'">
		<ng-container *ngFor="let model of models;">
			<div class="data-row-container"
				[ngClass]="{'data-container-collapsed': !model.expanded}">
				<div class="header-row flex-horz"
					(click)="toggleModel(model)"
					(dblclick)="showModel(model)">

					<div class="data-icon">
						<mat-icon
							[ngStyle]="{color: model?.color ?? 'red'}">
							{{getDataIcon(model.type, model.layers.length)}}
						</mat-icon>
					</div>

					<div class="data-group-name">
						<div *ngIf="model.missingCoordinateSystem" matTooltip="Missing coordinate system information">
							<mat-icon class="warning-icon">warning</mat-icon>
						</div>
						{{ model.name }}
					</div>

					<div class="data-row-toggle">
						<mat-icon>
							keyboard_arrow_up
						</mat-icon>
					</div>
				</div>

				<div class="child-content debug-details" *ngIf="map.debugDetails">
					<ng-container *ngTemplateOutlet="modelDetails; context: {model : model}"></ng-container>
				</div>

				<div class="child-content layer-group-header">
					<div class="horz-divider"></div>
					<div class="flex-horz">
						<div class="thin-label">LAYERS {{ model.layers.length }}</div>
					</div>
				</div>

				<ng-container *ngFor="let layer of model.layers">
					<div class="child-content"
						*ngIf="layer.loaded"
						[ngClass]="{'child-visible': layer.visible, 'layer-selected': layer === map.comparisonLayers.left || layer === map.comparisonLayers.right}"
						(dblclick)="showLayer(layer); $event.stopPropagation();">
						<div class="layer-row">
							<div class="layer-toggle layer-visibility"
								(click)="toggleLayer(layer); $event.stopPropagation();"
								[ngClass]="{'active-type': layerMatchesMode(layer) }">
								<mat-icon [ngClass]="{'bright': layer.visible}">
									{{ layer.visible ?  'visibility' : 'visibility_off' }}
								</mat-icon>
							</div>

							<div class="data-map-type"
								*ngIf="hideElevationModeLayerIcons(layer) && hideComparisonModeMapType(layer)"
								[ngClass]="{'active-type': layerMatchesMode(layer) }">
									{{ layer.mapType }}
							</div>

							<div *ngIf="showAvailableElevationModeLayers(layer)" class="data-map-type elevation-highlight"
								[ngClass]="{'active-type': layerMatchesMode(layer) }">
								<mat-icon class="point-cloud-icon">
									terrain
								</mat-icon>
							</div>

							<div *ngIf="showAvailableComparisonModeLayers(layer)"
								[ngClass]="{'active-type': layerMatchesMode(layer) }">
								<mat-icon class="comparison-icon">
									flip
								</mat-icon>
							</div>

							<div *ngIf="showNonElevationModeLayersIcons(layer) || showNonComparisonModeLayersIcons(layer)" class="data-map-type not-elevation-highlight"
								[ngClass]="{'active-type': layerMatchesMode(layer) && !map.toolbar.elevationModeActivated}">
								<mat-icon class="point-cloud-icon">
									not_interested
								</mat-icon>
							</div>

							<div class="layer-name">
								<div *ngIf="layer.missingCoordinateSystem" matTooltip="Missing coordinate system information">
									<mat-icon class="warning-icon">warning</mat-icon>
								</div>
								<div class="layer-title">
									{{ layer.name }}
								</div>
							</div>

							<ng-container *ngIf="layer.type === 'analysis'">
								<div class="analysis-layer-div">
									<div class="spacer-div"></div>
									<div class="slider-container">
										<div class="opacity-label">
											Opacity
										</div>
										<mat-slider #analysisOpacityRangeRef min="0" max="1" step="0.01" [(ngModel)]="layer.opacity" (input)="updateLayerOpacity(layer, $event)"></mat-slider>
									</div>
								</div>
							</ng-container>

						</div>

						<ng-container *featureEnabled="'temp-contour'">
							<div *ngIf="layer.overlayType === 'contour'" class="contour-sub-panel-container" [ngClass]="{'sub-panel-expanded': layer.expanded, 'sub-panel-collapsed': !layer.expanded}">
								<div *ngIf="layer.expanded" class="contour-details">
									<div class="top-details">
										<div class="details-left">
											<div class="elevation-title">
												Elevation <span>(1-150 ft)</span>
											</div>
											<div class="min-max-container">
												<span>Min: 1ft</span>
												<span>Max: 100ft</span>
											</div>
										</div>
										<div class="details-right">
											<div class="contour-visibility-toggles">
												<div class="label-toggle">
													<mat-icon (click)="toggleContourVisibility('index')">
														{{ showIndexContours ?  'visibility' : 'visibility_off' }}
													</mat-icon>
													<span>Index Contour</span>
												</div>
												<div class="label-toggle">
													<mat-icon (click)="toggleContourVisibility('labels')">
														{{ showContourLabels ?  'visibility' : 'visibility_off' }}
													</mat-icon>
													<span>Labels</span>
												</div>
											</div>
											<div class="interval-dropdown-container">
												<span>Interval:</span>
												<button class="interval-dropdown action-button" mat-icon-button [matMenuTriggerFor]="contourInterval" aria-label="Select Interval" matTooltip="Contour Interval">
													{{ contourIntervalOptions[0] }}
													<mat-icon class="add-icon">expand_more</mat-icon>
												</button>
												ft/m
											</div>
										</div>
									</div>
									<div class="adjustments-title">Adjustments</div>
									<div class="divider-bar"></div>
									<div class="contour-adjustments">
										<div class="adjustments-left">
											<div class="color-pickers">
												<span>Contour: </span>
												<div class="contour-color-picker"></div>
											</div>
											<div class="color-pickers">
												<span>Index Contour: </span>
												<div class="index-color-picker"></div>
											</div>
										</div>
										<div class="adjustments-right">
											<div class="contour-line-weight-dropdown-container">
												<span>Line Weight:</span>
												<button class="contour-line-weight-dropdown action-button" mat-icon-button [matMenuTriggerFor]="contourLineWeight" aria-label="Adjust Contour Line Weight" matTooltip="contour line weight">
													{{ contourLineWeightOptions[0] }}
													<mat-icon class="add-icon">expand_more</mat-icon>
												</button>
											</div>
											<div>Opacity:</div>
											<div class="opacity-slider-container">
												<mat-slider type="range" min="0" max="100" #ngSlider>
													<input matSliderThumb (input)="updatecontourOpacity(contourOpacity)" #ngSliderThumb="matSliderThumb" [(ngModel)]="contourOpacity" />
												</mat-slider>
											</div>
										</div>
									</div>

								</div>
								<div class="contour-sub-panel-expand-bar" (click)="expandContourSubPanel(layer)">
									<mat-icon>
										{{ !layer.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }} 
									</mat-icon>
								</div>
							</div>
						</ng-container>

						<!--
						<div class="layer-toggle"
							>
							<mat-icon>
								more_horiz
							</mat-icon>
						</div>
						-->
					</div>

					<div class="child-content debug-details" *ngIf="map.debugDetails">
						<ng-container *ngTemplateOutlet="layerDetails; context: {layer : layer}"></ng-container>
					</div>

				</ng-container>

				<!--
					<div class="child-content layer-group-header">
					<div class="flex-horz">
						<div class="thin-label">ANALYSIS (0)</div>

						<div class="layer-header-action">
							<button class="inline-icon-button">
								<mat-icon>
									add
								</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<div class="child-content layer-group-header">
					<div class="horz-divider"></div>
					<div class="data-group-details flex-horz">
						<div class="detail-label" *ngIf="model.captured_at">
							Captured
							<div class="detail-value">{{ model.captured_at | date }}</div>
						</div>
						<div class="detail-label" *ngIf="model.created_at">
							Processed
							<div class="detail-value">{{ model.created_at | date }}</div>
						</div>
					</div>

					<div class="flex-horz" *ngIf="model.images">
						<div class="thin-label">IMAGES {{model.images.length}}</div>

						<div class="layer-toggle">
							<mat-icon>
								keyboard_arrow_up
							</mat-icon>
						</div>
					</div>
				</div>
				-->

			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="dataFilter === 'annotations'">
		<div class="annotations-options-header">
			<div class="annotation-toggle">
				<span class="toggle-label">Annotations</span>
				<mat-slide-toggle [checked]="showAnnotations" (change)="toggleAnnotations($event)" hideIcon>
				</mat-slide-toggle>
			</div>
			<!--
				<mat-icon class="add-icon">add</mat-icon>
			-->
		</div>

		<div [id]="'annotationsList'" class="data-list">
			<ng-container *ngFor="let annotation of annotations">
				<div [id]="annotation.id" class="data-row-container annotation-row"
					[ngClass]="{'data-container-collapsed': !annotation.expanded, 'highlighted' : annotation.expanded}">
					<div class="header-row flex-horz"
						(click)="toggleAnnotationExpand(annotation)">
						<div class="data-icon">
							<img class="toolbar_item_icon" src="{{getAnnotationIcon(annotation)}}">
						</div>
						<div class="data-map-type"
							[ngClass]="{'active-type': (annotation.mapType == '3D') == map.threeDMode }">
								{{ annotation.mapType }}
						</div>
						<div class="data-group-name">
							<ng-container *ngIf="!isEditType(annotation, 'name')">
								{{annotation.name}}
								<button *ngIf="annotation.expanded" matTooltip="Rename" mat-button (click)="setEditAnnotation(annotation, 'name'); $event.stopPropagation();">
									<mat-icon>edit</mat-icon>
								</button>
							</ng-container>
							<ng-container *ngIf="isEditType(annotation, 'name')">
								<input name="name"
									   class="name-input"
									   [value]="annotation.name"
									   (input)="updateAnnotationName($event, annotation)"
									   (click)="focusAnnotationDetail('name-input'); $event.stopPropagation();"
									   (focusout)="focusAnnotationDetail(null)"
									   [ngClass]="{'annotation-disable': !mapPermissions.canAnnotate}"/>

								<button class="active" mat-button matTooltip="Confirm" (click)="clearEditAnnotation(); $event.stopPropagation();">
									<mat-icon>check</mat-icon>
								</button>
								<button class="active" mat-button matTooltip="Cancel" (click)="resetAnnotation(annotation); $event.stopPropagation();">
									<mat-icon>close</mat-icon>
								</button>
							</ng-container>
						</div>

						<div class="data-row-toggle">
							<mat-icon>
								keyboard_arrow_up
							</mat-icon>
						</div>
					</div>

					<div class="details-container child-content" >

						<div class="annotation-button-row">
							
							<span *ngIf="annotation.type === 'polyline'">
								<button class="action-button annotation-button" [ngClass]="{'active': annotation.measuring, 'disabled': !mapPermissions.canAnnotate}"
										(click)="annotation.start()">
									<mat-icon class="right-margin">
										add_circle_outline
									</mat-icon>
									Add Points
								</button>
								<button class="action-button annotation-button right-padding" [ngClass]="{'active': !annotation.measuring, 'disabled': !mapPermissions.canAnnotate}"
										(click)="annotation.stop(false)">
									<mat-icon class="lil-right-margin">
										edit
									</mat-icon>
									Edit
								</button>
							</span>

							<button class="action-button annotation-button delete-button"
									(click)="deleteAnnotation(annotation)" [ngClass]="{'disabled': !mapPermissions.canAnnotate}">
								<mat-icon>
									delete
								</mat-icon>
							</button>
						</div>

						<div class="description-div">
							<label class="description-label" [ngClass]="{'focused': (focusedElement === 'description-label') && mapPermissions.canAnnotate}">
								Description
							</label>
							<div *ngIf="!isEditType(annotation, 'details')">
								<p *ngIf="annotation.details">
									{{annotation.details}}
								</p>
								<i *ngIf="!annotation.details">
									No description.
								</i>

								<span>
									<button matTooltip="Edit Description" mat-button (click)="setEditAnnotation(annotation, 'details'); $event.stopPropagation();">
										<mat-icon>edit</mat-icon>
									</button>
								</span>
							</div>
							<div *ngIf="isEditType(annotation, 'details')">
								<textarea name="description" cdkAutosizeMaxRows="3" class="description-textarea"
										[value]="annotation.details"
										(input)="updateAnnotationDetails($event, annotation)"
										(click)="focusAnnotationDetail('description-label')"
										(focusout)="focusAnnotationDetail(null)"
										[ngClass]="{'annotation-disable': !mapPermissions.canAnnotate}">
								</textarea>
								<span>
									<button class="active" mat-button matTooltip="Confirm" (click)="clearEditAnnotation(); $event.stopPropagation();">
										<mat-icon>check</mat-icon>
									</button>
									<button class="active" mat-button matTooltip="Cancel" (click)="resetAnnotation(annotation); $event.stopPropagation();">
										<mat-icon>close</mat-icon>
									</button>
								</span>
							</div>
						</div>

							<!-- Data Reference currently commented out to be implemented later! -->
							<!-- <div class="details-div">
								<div class="reference-list">
									<div class="reference-tag">
										This is a "tag"
										<span class="remove-tag-button">
											X
										</span>
									</div>
								</div>
							</div>
							<div class="horz-divider"></div> -->


							<ng-container  *ngIf="annotation.type === 'polyline'">
								<div class="details-div">
									<div class="measurement-div" *ngIf="annotation.points.length < 3">
										<div>
											Length
										</div>
										<div *ngIf="annotation.extraInfo.distance">
											{{annotation.extraInfo.distance * map.selectedUnits.metersConversion | number: '1.1-3'}} {{map.selectedUnits.id}}
										</div>
										<div *ngIf="!annotation.extraInfo.distance">0 {{map.selectedUnits.id}}</div>
									</div>
									<div class="measurement-div" *ngIf="annotation.points.length > 2">
										<div>
											Ground Area
										</div>
										<div>
											{{annotation.extraInfo.area * map.selectedUnits.metersConversion * map.selectedUnits.metersConversion | number: '1.1-3'}} {{map.selectedUnits.id}}²
										</div>
									</div>
									<div class="measurement-div" *ngIf="annotation.extraInfo.face_area && annotation.extraInfo.face_area != 0">
										<div>
											Estimated Area
										</div>
										<div>
											{{annotation.extraInfo.face_area * map.selectedUnits.metersConversion * map.selectedUnits.metersConversion | number: '1.1-3'}} {{map.selectedUnits.id}}²
										</div>
									</div>
								</div>
							</ng-container>

							<ng-container *ngIf="annotation.type === 'marker' && annotation.points.length">
								<div class="details-div">
									<div class="measurement-div">
										<div>
											Latitude
										</div>
										<div>
											{{annotation.extraInfo.coordinate.dms.y}}
										</div>
									</div>
									<div class="measurement-div">
										<div>
											Longitude
										</div>
										<div>
											{{annotation.extraInfo.coordinate.dms.x}}
										</div>
									</div>
									<ng-container *featureEnabled="'temp-elevation-view'">
										<div class="measurement-div" *ngIf="annotation.mapType === '3D' && annotation.type === 'marker' && annotation.extraInfo.coordinate.alt">
											<div>
												Altitude
											</div>
											<div>
												{{annotation.extraInfo.coordinate.alt * map.selectedUnits.metersConversion | number: '1.0-2'}} {{map.selectedUnits.id}}
											</div>
										</div>
									</ng-container>
								</div>
							</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>

	<div class="data-list" *ngIf="dataFilter === 'images'">
		<ng-container *ngFor="let group of imageGroups;">
			<div class="data-row-container"
				[ngClass]="{'data-container-collapsed': !group.expanded}">
				<div class="header-row flex-horz"
					(click)="toggleImageGroup(group)">

					<div class="layer-toggle layer-visibility image-group-toggle"
						[ngClass]="{'bright': group.visible}"
						(click)="toggleImageGroupVisibility($event, group)">
						<mat-icon >
							{{ group.visible ?  'visibility' : 'visibility_off' }}
						</mat-icon>
					</div>

					<div class="data-icon">
						<mat-icon
							[ngStyle]="{color: group.color}">
							photo_library
						</mat-icon>
					</div>

					<div class="data-group-name">
						{{ group.name }}
					</div>

					<div class="data-row-toggle">
						<mat-icon>
							keyboard_arrow_up
						</mat-icon>
					</div>
				</div>

				<div class="child-content layer-group-header">
					<div class="horz-divider"></div>
					<div class="flex-horz">
						<div class="thin-label">IMAGES {{ group.images.length }}</div>
					</div>
				</div>

				<ng-container *ngFor="let image of group.images">
					<div class="child-content layer-row image-row"
						(mouseenter)="highlightImage(image)"
						(mouseleave)="unhighlightImage(image)"
						(click)="focusOnImage(image)">

						<div class="data-map-type">
							<mat-icon class="point-cloud-icon">
								photo
							</mat-icon>
						</div>

						<div class="layer-name">
							<div class="layer-title">
								{{ image.name }}
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="dataFilter === 'labels'">
		<div class="create-label-set-header">
			<div class="toggle-button" (click)="toggleLabelSets()">
				<mat-icon matTooltip="Toggle visibility on/off for all">{{ showLabelSets ? 'visibility' : 'visibility_off' }}</mat-icon>
				<span class="toggle-label">Label Sets</span>
			</div>

			<button class="create-label-set-button action-button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Create Label Set" matTooltip="Create Label Set" [ngClass]="{'disabled': !mapPermissions.canLabel}">
				<mat-icon class="add-icon">expand_more</mat-icon>
				Create Label Set
			</button>
		</div>

		<div class="data-list">
			<ng-container *ngFor="let labelSet of labelSets">
				<div [id]="labelSet.id" class="data-row-container labelSet-row"
					[ngClass]="{'data-container-collapsed': !labelSet.expanded, 'highlighted' : labelSet.expanded}">
						<div class="header-row flex-horz"
							(click)="toggleAnnotationExpand(labelSet)">

							<div class="layer-toggle layer-visibility image-group-toggle"
								[ngClass]="{'bright': labelSet.visible}"
								(click)="toggleLabelSetVisibility($event, labelSet)">
								<mat-icon >
									{{ labelSet.visible ?  'visibility' : 'visibility_off' }}
								</mat-icon>
							</div>

							<div class="data-map-type" [ngClass]="{'active-type': labelSet.mapType == '2D'}">
								{{ labelSet.mapType }}
							</div>

							<div class="data-icon">
								<mat-icon
									[ngStyle]="{color: labelSet.color}">
									{{ getDataIcon(labelSet.type) }}
								</mat-icon>
							</div>

							<div class="data-group-name">
								<input name="name" class="label-name-input"
									[value]="labelSet.name"
									(input)="updateLabelSetName($event, labelSet)"
									[ngClass]="{'annotation-disable': !mapPermissions.canLabel, 'disabled': !mapPermissions.canLabel}"/>
							</div>

							<div class="data-row-toggle">
								<mat-icon>
									keyboard_arrow_up
								</mat-icon>
							</div>
						</div>
					<div class="details-container child-content" >
						<div class="annotation-button-row">
							<button class="action-button annotation-button label-mode-button" [ngClass]="{'active': labelModeActive, 'disabled': !mapPermissions.canLabel}" (click)="activateDeactivateLabelingMode(labelSet)">
								{{ labelModeActive ? "Exit Labeling" : "Activate Labeling" }}
							</button>

							<button class="action-button annotation-button delete-button"
								(click)="deleteLabelSet(labelSet)"[ngClass]="{'disabled': !mapPermissions.canAnnotate}">
								<mat-icon>
									delete
								</mat-icon>
							</button>
						</div>

						<div class="details-div label-set-description">
							<label class="description-label" [ngClass]="{'focused': (focusedElement === 'description-label') && mapPermissions.canLabel}">
								Description
							</label>
							<textarea name="description" cdkAutosizeMaxRows="3" class="label-description-textarea"
									[value]="labelSet.details"
									[ngClass]="{'annotation-disable': !mapPermissions.canLabel}"
									(click)="focusAnnotationDetail('description-label')"
									(input)="updateLabelSetDetails($event, labelSet)"
									(focusout)="focusAnnotationDetail(null)">
							</textarea>
						</div>
						<ng-container *ngIf="labelSet.labels">
							<div class="label-list-container label-list-row" [ngClass]="{'label-list-collapsed': !showLabelList, 'highlighted' : showLabelList}">
								<div class="description-label list-header-row flex-horz" [ngClass]="{'focused': (focusedElement === 'list-header-row'), 'list-header-highlight': showLabelList}" (click)="toggleLabelListExpand('list-header-row')">
									Labels {{ labelSet.labels.length }}
									<div class="data-row-toggle">
										<mat-icon>
											keyboard_arrow_up
										</mat-icon>
									</div>
								</div>
								<div [ngClass]="{'label-list-scroll': showLabelList && labelSet.labels.length}">
									<ng-container *ngFor="let label of labelSet.labels">
										<div class="label-child-content"
											[ngClass]="{'label-selected': selectedLabel === label}"
											(mouseenter)="highlightLabel(label, labelSet)"
											(mouseleave)="unhighlightLabel(labelSet)"
											(dblclick)="goToLabelSetLabel(labelSet, label)"
											(click)="map.labelTools.selectLabel(labelSet, label)">
											<mat-icon [ngStyle]="{color: labelSet.color}">
												label_important
											</mat-icon>
											<span class="label-name">
												{{ label.nameId }}
											</span>
											<div class="remove-label-div hide" [ngClass]="{'disabled': !mapPermissions.canAnnotate}">
												<mat-icon class="remove-label-icon" (click)="deleteLabel(labelSet, label)">
													clear
												</mat-icon>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>

</div>
<!-- New label set options -->
<mat-menu #menu="matMenu" class="label-set-option-menu-panel">
	<button class="label-set-option" mat-menu-item *ngFor="let option of createLabelSetOptions" (click)=createNewLabelSet(option)>
		<mat-icon class="label-icon">{{ option.icon }}</mat-icon>
		<span>{{ option.name }}</span>
	</button>
</mat-menu>

<!-- Contour adjustment options -->
<mat-menu #contourInterval="matMenu" class="contour-interval-panel">
	<button class="contour-option" mat-menu-item *ngFor="let option of contourIntervalOptions" (click)=createNewLabelSet(option)>
		<span>{{ option }}</span>
	</button>
</mat-menu>

<!-- Contour line weight adjustment options -->
<mat-menu #contourLineWeight="matMenu" class="contour-line-weight-panel">
	<button class="contour-option" mat-menu-item *ngFor="let option of contourLineWeightOptions" (click)=adjustContourLineWeight(option)>
		<span>{{ option }}</span>
	</button>
</mat-menu>

<ng-template #modelDetails let-model="model">
	<div class="detail-row">
		<div>ID</div>
		<div>{{model.id}}</div>
	</div>
	<div class="detail-row">
		<div>Status</div>
		<div>{{model.status}}</div>
	</div>
	<div class="detail-row">
		<div>Type</div>
		<div>{{model.type}}</div>
	</div>
	<div class="detail-row">
		<div>Latitude</div>
		<div>{{model.latitude}}</div>
	</div>
	<div class="detail-row">
		<div>Longitude</div>
		<div>{{model.longitude}}</div>
	</div>
	<div class="detail-row">
		<div>EPSG</div>
		<div>{{model.descriptors.epsg_code}}</div>
	</div>
	<ng-container *ngIf="model.zone">
		<div class="detail-row">
			<div>Zone</div>
			<div>{{model.zone.zoneNum}} {{model.zone.zoneLetter}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Easting</div>
			<div>{{model.zone.easting}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Northing</div>
			<div>{{model.zone.northing}}</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!model.zone">
		<div class="detail-row">
			<div>Zone</div>
			<div>NULL</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #layerDetails let-layer="layer">
	<div class="detail-row">
		<div>ID</div>
		<div>{{layer.id}}</div>
	</div>
	<ng-container *ngIf="layer.fileType === 'potree'">
		<div class="detail-row">
			<div>Projection</div>
			<div>{{layer.data?.projection}}</div>
		</div>
		<div class="detail-row">
			UTM Position
		</div>
		<div class="detail-row sub-detail">
			<div>X</div>
			<div>{{layer.data?.position?.x}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Y</div>
			<div>{{layer.data?.position?.y}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Z</div>
			<div>{{layer.data?.position?.z}}</div>
		</div>
	</ng-container>

	<!-- Geotiff -->
	<ng-container *ngIf="layer.fileType === 'geotiffs'">
		<div class="detail-row">
			Coordinate
		</div>
		<div class="detail-row sub-detail">
			<div>Latitude</div>
			<div>{{layer.coordinate.dms.y}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Longitude</div>
			<div>{{layer.coordinate.dms.x}}</div>
		</div>

		<div class="detail-row">
			Boundaries
		</div>
		<div class="detail-row sub-detail">
			<div>East</div>
			<div>{{layer.boundries.east}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>West</div>
			<div>{{layer.boundries.west}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>North</div>
			<div>{{layer.boundries.north}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>South</div>
			<div>{{layer.boundries.south}}</div>
		</div>
	</ng-container>

	<!-- Cesium -->
	<ng-container *ngIf="layer.fileType === 'cesium'">
		<div class="detail-row">
			UTM Position
		</div>
		<div class="detail-row sub-detail">
			<div>X</div>
			<div>{{layer.boundingObj?.position?.x}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Y</div>
			<div>{{layer.boundingObj?.position?.y}}</div>
		</div>
		<div class="detail-row sub-detail">
			<div>Z</div>
			<div>{{layer.boundingObj?.position?.z}}</div>
		</div>
	</ng-container>
</ng-template>
