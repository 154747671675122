import { PREFIX as t, OBJECT as e, UNDEFINED as r } from "@analytics/type-utils";
var l = "global",
  o = t + "global" + t,
  n = typeof self === e && self.self === self && self || typeof global === e && global.global === global && global || void 0;
function a(t) {
  return n[o][t];
}
function f(t, e) {
  return n[o][t] = e;
}
function i(t) {
  delete n[o][t];
}
function u(t, e, r) {
  var l;
  try {
    if (b(t)) {
      var o = window[t];
      l = o[e].bind(o);
    }
  } catch (t) {}
  return l || r;
}
n[o] || (n[o] = {});
var c = {};
function b(t) {
  if (typeof c[t] !== r) return c[t];
  try {
    var e = window[t];
    e.setItem(r, r), e.removeItem(r);
  } catch (e) {
    return c[t] = !1;
  }
  return c[t] = !0;
}
export { l as GLOBAL, o as KEY, a as get, n as globalContext, b as hasSupport, i as remove, f as set, u as wrap };
