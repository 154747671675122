export var MS = '-ms-';
export var MOZ = '-moz-';
export var WEBKIT = '-webkit-';
export var COMMENT = 'comm';
export var RULESET = 'rule';
export var DECLARATION = 'decl';
export var PAGE = '@page';
export var MEDIA = '@media';
export var IMPORT = '@import';
export var CHARSET = '@charset';
export var VIEWPORT = '@viewport';
export var SUPPORTS = '@supports';
export var DOCUMENT = '@document';
export var NAMESPACE = '@namespace';
export var KEYFRAMES = '@keyframes';
export var FONT_FACE = '@font-face';
export var COUNTER_STYLE = '@counter-style';
export var FONT_FEATURE_VALUES = '@font-feature-values';
export var LAYER = '@layer';