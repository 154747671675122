<div class="table-tools project-page-header">

	<div class="back-container">
		<div class="H4Black700Regular" style="display: flex;" (click)="goBack()">
			<button mat-button>
				<mat-icon>keyboard_backspace</mat-icon>
			</button>
		</div>

		<div class="H4Black700Semibold ellipsis" [ngClass]="{'skel-list-count': isProcessing}">{{ project?.name }}</div>
		<div class="project-id">ID: {{ project?.id }}</div>
	</div>

	<span class="project-buttons">
		<ng-container *featureEnabled="'!disable-processing'">
			<button *featureEnabled="'temp-ai-inferencing'" mat-stroked-button color="primary" class="secondary-button" (click)="openAiInferencingWizard()"
					[disabled]="badProject() || !userPermissions['process']">
				<mat-icon class="phone-hidden">track_changes</mat-icon>
				Run AI Analysis
			</button>
			<button mat-stroked-button color="primary" class="secondary-button" (click)="openCreateProcess()"
				[disabled]="badProject() || !userPermissions['process']">
				<mat-icon class="phone-hidden" fontIcon="add"></mat-icon>
				Process Model
			</button>
			<button class="phone-hidden" mat-button *ngIf="!userPermissions['process']"
				matTooltipClass="complete-tooltip"
				matTooltip="You do not have permission to process a model in this project. If you believe this is an error, please contact the project owner.">
				<mat-icon class="help-icon-button">help_outline</mat-icon>
			</button>
		</ng-container>
		<ng-container *featureEnabled="'!disable-imports-for-maintenance'">
			<button mat-stroked-button color="primary" class="secondary-button" (click)="openImportDialog()"
				[disabled]="badProject() || !userPermissions['process']">
				<mat-icon class="phone-hidden">upload</mat-icon>
				Upload
			</button>
		</ng-container>
		<!--		<portal-hook *featureEnabled="'ml-wizard'" selector="ml-wizard"></portal-hook>-->
		<button class="phone-hidden" mat-button *ngIf="badProject() && userPermissions" style="margin-left: 8px;"
			matTooltip="Waiting for data to load...">
			<mat-icon class="help-icon-button">help_outline</mat-icon>
		</button>

		<button mat-flat-button
				color="primary"
				(click)="openMap()"
				[disabled]="disableMapButton()">
			<mat-icon>map</mat-icon>
			Open Map
		</button>
	</span>
</div>

<div class="project-content-container">

	<mat-tab-group animationDuration="0ms" disableRipple=true [selectedIndex]="selectedTabIndex"
		(selectedIndexChange)="onIndexChange($event);">

		<ng-container *ngIf="project && !isProcessing">
			<mat-tab>
				<ng-template mat-tab-label>
					<mat-icon>layers</mat-icon>
					<ng-container *ngIf="hasValue(project.models_count)">
						{{ project.models_count }} {{setPlural("Layers", project.models_count)}}
					</ng-container>
					<ng-container *ngIf="!hasValue(project.models_count)">Layers</ng-container>
				</ng-template>
				<ng-template matTabContent>
					<app-models-projects (projectChange)="onProjectUpdate($event)"
						[project]="project"></app-models-projects>
				</ng-template>
			</mat-tab>

			<ng-container *featureEnabled="'temp-processes-page'">
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon>memory</mat-icon>
						<ng-container *ngIf="hasValue(project.orders_count)">
							{{ project.orders_count }} {{setPlural("Processes", project.orders_count)}}
						</ng-container>
						<ng-container *ngIf="!hasValue(project.orders_count)">Processes</ng-container>
					</ng-template>
					<ng-template matTabContent>
						<app-processes [project]="project"></app-processes>
					</ng-template>
				</mat-tab>
			</ng-container>

			<mat-tab>
				<ng-template mat-tab-label>
					<mat-icon>photo_library</mat-icon>
					<ng-container *ngIf="hasValue(project.batches_count)">
						{{ project.batches_count }} Image {{setPlural("Group", project.batches_count)}}
					</ng-container>
					<ng-container *ngIf="!hasValue(project.batches_count)">Image Groups</ng-container>
				</ng-template>
				<ng-template matTabContent>
					<ng-container *featureEnabled="'temp-new-images'">
						<app-image-groups
							(openUpload)="openUploadDialog($event)"
							[project]="project"
							[hasPermissions]="hasPermissions">
						</app-image-groups>
					</ng-container>
					<ng-container *featureEnabled="'!temp-new-images'">
						<app-photos-projects (projectChange)="onProjectUpdate($event)"
							[project]="project"></app-photos-projects>
					</ng-container>
				</ng-template>
			</mat-tab>

			<mat-tab>
				<ng-template mat-tab-label>
					<mat-icon>description</mat-icon>
					<ng-container *ngIf="hasValue(project.project_files_count)">
						{{ project.project_files_count }} {{setPlural("File", project.project_files_count)}}
					</ng-container>
					<ng-container *ngIf="!hasValue(project.project_files_count)">Files</ng-container>
				</ng-template>
				<ng-template matTabContent>
					<app-data-projects (projectChange)="onProjectUpdate($event)"
						[project]="project"></app-data-projects>
				</ng-template>
			</mat-tab>

			<mat-tab>
				<ng-template mat-tab-label>
					<mat-icon>file_open</mat-icon>
					<ng-container *ngIf="hasValue(project.exports_count)">
						{{ project.exports_count }} {{setPlural("Export", project.exports_count)}}
					</ng-container>
					<ng-container *ngIf="!hasValue(project.exports_count)">Exports</ng-container>
				</ng-template>
				<ng-template matTabContent>
					<app-exports-projects [project]="project"></app-exports-projects>
				</ng-template>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</div>
