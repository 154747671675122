import Fade from "@mui/material/Fade";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

export const FadeTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Fade in ref={ref} {...props} />;
});
