
/* Imports */
import {Component, Input, Output, ViewChild, EventEmitter, ChangeDetectorRef, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/services';
import { icon, latLng, Layer, marker, tileLayer } from 'leaflet';
import L from "leaflet";
import 'leaflet.markercluster';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { environment } from '@/environments/environment';

export interface MinimapPosition {
	latitude: number;
	longitude: number;
	name?: string;
}

@Component({
	selector: 'app-minimap',
	templateUrl: './minimap.component.html',
	styleUrls: ['./minimap.component.scss']
})
export class MinimapComponent implements OnInit {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild(MatMenu) menu: MatMenu;

	@Output('backEvent') backEvent: EventEmitter<any> = new EventEmitter();
	@Input('positions') set positions(inPositions: MinimapPosition[]) {
		if (inPositions?.length) {
			this.addMarkers(inPositions);
		}
	};

	public map: any;
	public isProcessing: boolean = true;
	public markersLayer: L.layerGroup;
	public mapStyle: 'map' | 'sat' = 'map';
	public options = {
		zoom: 15,
		minZoom: 2,
		maxZoom: 18,
		center: latLng(39.8283, -98.5795),
		doubleClickZoom: 0,
	};

	protected token: string = environment.mapbox;
	protected LAYER_MAP: Layer = tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
		tileSize: 512,
		zoomOffset: -1,
		id: 'mapbox/streets-v11',
		accessToken: this.token
	});
	protected LAYER_SAT: Layer = tileLayer(`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${this.token}`);
	public layers: Layer[] = [this.LAYER_MAP];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _cdr: ChangeDetectorRef,
		private _utilsService: UtilsService,
	) {

	}	// End-of constructor

	ngOnInit() {
		this.createMap();
	}

	createMap(): void {
		this.setMapStyle('map');
		this.map = L.map('map', (Object.assign({}, this.options, {layers: this.layers})));
	}

	createCluster(nItems: number): void {

		if (this.markersLayer) this.map.removeLayer(this.markersLayer);
		this.markersLayer = nItems > 100 ? new L.MarkerClusterGroup({
			chunkedLoading: true,
			spiderLegPolylineOptions: { weight: 1.5, color: '#222', opacity: 0.2 }
		}) : new L.FeatureGroup();
		this.map.addLayer(this.markersLayer);
	}

	setMapStyle(style: 'map' | 'sat'): void {

		this.mapStyle = style;
		this.layers[0] = style === 'map' ? this.LAYER_MAP : this.LAYER_SAT;
		this.isProcessing = false;

	}	// End-of setMapStyle

	addMarkers(data = []): void {

		this.createCluster(data?.length);

		data.forEach((pos) => {
			const newMarker = this.makeMarker(pos);
			newMarker.bindPopup(pos.name ?? "", {maxWidth: 120});
			this.markersLayer.addLayer(newMarker);
		})

		this.map.fitBounds(this.markersLayer.getBounds())
		this.isProcessing = false;
	}

	makeMarker(pos) {
		return L.marker(
			[pos.latitude, pos.longitude],
			{
				title: pos.name ?? "",
				icon: icon({
					iconSize: [25, 41],
					iconAnchor: [12, 32],
					iconUrl: '/assets/icons/location.svg',
					popupAnchor: [0, -32]
				})
			}
		);
	}


}	// End-of class SinglePhotoComponent
