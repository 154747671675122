import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { groupBy } from "lodash";

export interface ViewerLayer {
	id: string;
	model_id: string;
	name: string;
	type: string;
	fileType: string;
	url: string;
	source: string;
	createdBy: string;
	createdOn: string;
	coordinate: unknown;
	visible: boolean;
	showDetails: boolean;
	exportForm: any;
	errors;
}

export interface VisibleLayer extends ViewerLayer {
	visible: boolean;
}

export const layerGroupsSlice = createSlice({
	initialState: {},
	name: "layerGroups",
	reducers: {
		setLayerGroups(state, action) {
			return action.payload;
		},
		toggleLayerVis: (state, action: PayloadAction<number | string>) => {
			state[action.payload].visible = !state[action.payload].visible;
		},
	},
});

type LayerGroups = Record<string, VisibleLayer>;

const selectSelf = (state): LayerGroups => state[layerGroupsSlice.name];
export const getLayerGroupsList = createSelector(
	selectSelf,
	(state: LayerGroups) => groupBy(Object.values(state), "groupName")
);
export const getVisibleDetails = createSelector(
	selectSelf,
	(state: LayerGroups) =>
		Object.entries(state).map(([id, details]) => [id, details.visible])
);

export const { setLayerGroups, toggleLayerVis } = layerGroupsSlice.actions;
export const reducer = layerGroupsSlice.reducer;

export function organizeLayerGroupsForControls(layerGroups, layerTypeDict) {
	return layerGroups
		.flatMap((group) =>
			group.layers
				.filter((layer) => layer.type in layerTypeDict)
				.map(({ id, type, visible }) => ({
					groupName: group["name"],
					id,
					type,
					visible,
				}))
		)
		.reduce((acc, layer) => Object.assign(acc, { [layer.id]: layer }), {});
}
