
<div [id]="'photo-container-' + index"
	class="photo-container"
	[ngClass]="getPhotoClasses(gridDisplay, menuOpen, child)"
	(contextmenu)="openMenu($event, child, true);">

	<!-- Checkbox Column -->
	<span class="select-cell phone-hidden">
		<mat-checkbox
			data-testid="checkbox"
			(change)="handleToggle($event, child);"
			[checked]="selection.isSelected(child)">
		</mat-checkbox>
	</span>

	<!-- File name Column -->
	<span class="name-cell table-cell phone-hidden" (click)="outEvent('open', child)">
		<span class="ms-mattooltip" matTooltip="Multispectral Image" [matTooltipDisabled]="!batch.sensor">
			<div *ngIf="!child.small_url && !child.thumbnail" class="view-skel-item view-skel-shimmer thumbnail"></div>
			<mat-icon *ngIf="batch.sensor" class="ms-mat-icon">burst_mode</mat-icon>
			<ng-container *ngIf="(child.small_url || child.medium_url)">
				<img
					data-max-retry="5"
					data-retry="0"
					(error)="waitAndReload($event)"
					[src]="file_url + (gridDisplay ? child.medium_url : child.small_url)"
					alt="Image"
					loading="lazy"
					class="thumbnail">
			</ng-container>
		</span>

		<span class="title-container">
			<span class="title-text ellipsis">
				{{child.name ? child.name : "No name provided"}}
			</span>
			<mat-icon
				class="favorited"
				matTooltip="Favorited"
				*ngIf="isFavorite(child)">
				star_border
			</mat-icon>

			<!-- Options Column -->
			<span class="options-container" *ngIf="gridDisplay">
				<ng-container *ngTemplateOutlet="t_menu"></ng-container>
			</span>
		</span>
	</span>

	<!-- MS Bands Column -->
	<span class="table-cell phone-hidden" *ngIf="batch?.sensor && child?.id">
		<ng-container *ngIf="gridDisplay">Bands:</ng-container>
		{{srcImgBands[child.id]}}
	</span>

	<!-- Megapixels Column -->
	<span class="table-cell phone-hidden">
		<ng-container *ngIf="gridDisplay">Megapixels:</ng-container>
		{{child.pixels ? (child.pixels | bytes:'pixels') : '- -'}}
	</span>

	<!-- Size Column -->
	<span class="table-cell phone-hidden">
		<ng-container *ngIf="gridDisplay">Size:</ng-container>
		{{child.size ? (child.size | bytes) : '- -'}}
	</span>

	<!-- MS Status Column -->
	<span class="table-cell phone-hidden">
		<ng-container *ngIf="gridDisplay">Status:</ng-container>
		{{(imgStatus ? imgStatus : '- -') | titlecase}}
	</span>

	<!-- TODO: @Luke combine these two functions in some manner -->
	<!-- Date taken Column -->
	<span class="table-cell phone-hidden">
		<ng-container *ngIf="child.status !== 'ACTIVE'">
			<span *ngIf="child.descriptors?.captured_at" [matTooltip]="child.descriptors.captured_at | date: 'long'">
				{{ ( child.descriptors.captured_at | date: 'longDate' ) }}
			</span>
			<span *ngIf="!child.descriptors?.captured_at">No date provided</span>
		</ng-container>
		<span *ngIf="child.status && child.status === 'ACTIVE'">
			<div matTooltip="Uploading">
				<div data-testid="spinning-loader" class="spinning-loader"></div>
			</div>
		</span>
	</span>

	<!-- Options Column -->
	<span class="options-cell" *ngIf="!gridDisplay">
		<ng-container *ngTemplateOutlet="t_menu"></ng-container>
	</span>
	<span class="error-message" *ngIf="gridDisplay && !canImageBeProcessed(child) && child.small_url">
		{{getInvalidMessage(child)}}
	</span>
</div>
<span class="error-message" *ngIf="!gridDisplay && !canImageBeProcessed(child) && child.small_url">
	{{getInvalidMessage(child)}}
</span>


<ng-template #t_menu>
	<button
		data-testId="mat-menu-button"
		mat-icon-button tooltip="More"
		(click)="openMenu($event, child, false);">
		<mat-icon class="list-item-icon">more_horiz</mat-icon>
	</button>

	<button
		class="context-button"
		[ngStyle]="contextMenuStyle()"
		[matMenuTriggerFor]="itemMenu">
	</button>

	<mat-menu #itemMenu="matMenu" (closed)="menuOpen = false;">
		<ng-template matMenuContent>
			<button mat-menu-item (click)="outEvent('open', child);">
				<mat-icon> zoom_out_map </mat-icon>
				<span> Open image </span>
			</button>
			<button mat-menu-item (click)="outEvent('download', child);">
				<mat-icon> vertical_align_bottom </mat-icon>
				<span> Download </span>
			</button>
<!--		TODO: @remove temp-move-images -->
			<ng-container *featureEnabled="'temp-move-images'">
				<button mat-menu-item (click)="outEvent('move', child);" [disabled]="disableMove || !userPermission">
					<mat-icon> drive_file_move_outline </mat-icon>
					<span> Move </span>
				</button>
			</ng-container>
			<button mat-menu-item (click)="outEvent('favorite', child);">
				<mat-icon> {{isFavorite(child) ? 'grade' : 'star_border'}} </mat-icon>
				<span> {{isFavorite(child) ? 'Remove from Favorites' : 'Add to Favorites'}} </span>
			</button>
			<button mat-menu-item (click)="outEvent('delete', child);" [disabled]="!userPermission">
				<mat-icon> delete_outline </mat-icon>
				<span> Delete </span>
			</button>
		</ng-template>
	</mat-menu>
</ng-template>
