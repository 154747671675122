import { isEqual } from 'lodash'
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';
import {PagingStreamService} from '@shared/services/paging-stream.service';
import { makeURLParams } from "./utils.service";
import { Organization } from "@shared/models";

const doneState = { projects:[], images: [], models: [], project_files: [] }

@Injectable()
export class TrashService {
	url = '/trash';
	urlV2 = '/v2/trash';
	constructor(
		private httpService: HttpService,
		private pagingStreamService: PagingStreamService
	) {

	}	// End-of constructor

	public getList(org?: Organization): Promise<any> {

		const searchData = {organization_id: org?.id};
		const searchParams = makeURLParams(searchData);

		return this.httpService.get(this.url + searchParams);

	}	// End-of getList

	public getTrashChunk(org: Organization, limit: number = 0, offset: number = 0, sort?: string, dir?: "asc" | "desc") {
		const searchData = { organization_id: org.id, limit, offset, sort, dir };
		const searchParams = makeURLParams(searchData);

		return this.httpService.get(this.urlV2 + searchParams);
	}

	public deleteAll() {
		return this.httpService.delete(this.url);
	}

}	// End-of class SearchService
