import { environment } from "../../../environments/environment";
import { createLayerApi } from "../createLayerApi/createLayerApi";
import { camelCase } from 'lodash';
import Analytics from 'analytics'

// Note: we might want to checkout getAnalytics.io in the future. at the moment they are missing GA4 support. (8/31/21 Nic L)

// gtag.js analytics
global.gtag = () => { }; // set a dummy gtag function which will get overriden on initialize

const analytics = Analytics({
	app: 'mapware',
	plugins: [
	],
	debug: /staging|development|default/.test(environment.envName)
})

// adds GoogleAnalytics tracking to generate gaClientId for use by the back-end
analytics.initialize = () => {
	if(!environment.googleMeasurementID) return; // dont add google analytics if the measurementID is missing

	let gtagScript = document.createElement("script");
		gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleMeasurementID}`;
		gtagScript.onload = () => {
			window.dataLayer = window.dataLayer || [];
			global.gtag = function() { dataLayer.push(arguments) };
			global.gtag('js', new Date());
			global.gtag('config', environment.googleMeasurementID, {
				'send_page_view': false
			});
		};
	document.head.appendChild(gtagScript);
}

analytics.failedToInitialize = () => {
	// TODO - work with backend to create a front-end analytics fallback?
	console.error("Analytics loading error - analyticsLayer");
};


/**
 * In order to prevent repetition, and to be as explicit as possible we are going to list every event we want to track here
 * as well as generate a `track{event}` function for each one.
 */
const events = {
	TEST: 'TEST',

	// Project List
	/**
	 * user changes the layout of the project list
	 * @param {boolean} gridDisplay
	 */
	switchProjectView: (gridDisplay) => ({ view: gridDisplay ? 'grid' : 'list'}),

	/**
	 * user sorts the project list
	 * @param {string} sort_by
	 */
	sortProjects: (sort_by) => ({ sort_by }),

	/**
	 * user shares a project thru the share modal
	 * @param {string} type	- type of sharing, email or link
	 * @param {number} id	- id, descronstructed from the project
	 */
	shareProject: (type, {id}) => ({ type, id }),

	// Object Interactions
	/**
	 * @param {number} id		- id deconstructed from the remove'd object
	 * @param {string} type		- type of the object
	 */
	remove: ({id}, type) => ({ id, type }),

	/**
	 * @param {string} action	- add or remove
	 * @param {number} id		- id deconstructed from the favorite'd or unfavorite'd object
	 * @param {string} type		- type of the object
	 */
	favorite: (action, {id}, type = "") => ({ action, id, type }),

	/**
	 * @param {number} id		- id deconstructed from the rename'd object
	 * @param {string} type		- type of the object
	 */
	rename: ({id}, type) => ({ id, type }),

	/**
	 * @param {number} id		- id deconstructed from the download'd object
	 * @param {string} type		- type of the object
	 */
	download: ({id}, type) => ({ id, type }),

	// Viewer
	/**
	 * user creates or deletes a measurement in the Viewer
	 * @param {string} action		- add or remove
	 * @param {Object} measureObj	- the created or removed measurement-object
	 */
	 measure: (action, measureObj) => ({ action, type: measureObj.constructor.name, id: measureObj.id }),

	 /**
	  * @param {string} units
	  */
	 switchMeasurementUnits: (units) => ({ units }),

	 /**
	  * @param {string} controls
	  */
	 switchViewerControls: (controls) => ({ controls })
}

/**
 * @typedef {Function} TrackEvent
 * @param  {Object}   [payload]   - Event payload
 * @param  {Object}   [options]   - Event options
 * @returns {Promise}
 *
 * @example
 *
 * // Basic event tracking
 * analyticsLayer.trackButtonClicked()
 *
 * // Event tracking with payload
 * analyticsLayer.trackItemPurchased({
 *   price: 11,
 *   sku: '1234'
 * })
 */

/**
 *
 * @type {Object<string, TrackEvent>}
 */
const trackEventFunctions = Object.entries(events).reduce(
	(acc, [eventName, payload]) => {
		acc[camelCase("track_" + eventName)] = (...args) => {
			if (typeof payload === "function")
				return analytics.track(eventName, payload(...args));

			return analytics.track(eventName, ...args);
		};
		return acc;
	},
	{}
);

export const analyticsLayer = createLayerApi({

	// the ClientID is specific to GoogleAnalytics-based implementations
	hasClientID: {
		forceSync: true,
		func: () => {
			// TODO: switch this file to TypeScript!
			// replace with: !!(window?.gaGlobal?.vid)
			return window.hasOwnProperty('gaGlobal') && window.gaGlobal.hasOwnProperty('vid')
		}
	},
	getClientID: {
		forceSync: true,
		func: () => {
			return window.gaGlobal.vid;
		}
	},

	initialize: analytics.initialize,
	identify: analytics.identify,
	failedToInitialize: analytics.failedToInitialize,

	/**
	 * @param {PageData}
	 * @returns {Promise}
	 *
	 * @example
	 *
	 * // Basic page tracking
	 * analyticsLayer.trackPage()
	 *
	 * // Page tracking with page data overrides
	 * analyticsLayer.trackPage({
	 *   url: 'https://google.com'
	 * })
	 */
	trackPage: analytics.page,
	...trackEventFunctions,
});

