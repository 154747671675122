import { environment } from "../../../../environments/environment";

const logError = (err, args, name, layerConfig) => {
    console.error(`${name} failed with `, ...args);
    if(environment.envName.includes('staging')) {
		console.error(layerConfig);
	}
};

/**
 * Create a function that is guaranteed to return a Promise as well as have error catching built in
 * @param {string} name
 * @param {FunctionConfig} functionConfig
 * @returns {LayerFunc}
 */
export const createLayerFunc = (name, functionConfig) => {
    const { func, useAdapter } = functionConfig;
    if (functionConfig.forceSync) {
        return {
            [name](...args) {
                try {
                	if(useAdapter) {
						return useAdapter(func)(...args)
					}
                    return func.call(functionConfig, ...args); // use `call` to ensure that if the function used `this` (meaning the config object) it would have the proper context. Without this, `this` would point to the global object
                } catch (e) {
                    logError(e, args, name, functionConfig);
                    throw e;
                }
            }
        }[name];
    }
    return {
        // `async` here automatically turns this into a `Promise`
        // using a rest operator as the only param lets us capture any and all params without caring how many there are
        async [name](...args) {
            try {
				if(useAdapter) {
					return useAdapter(func)(...args)
				}
                return func.call(functionConfig, ...args); // use `call` to ensure that if the function used `this` (meaning the config object) it would have the proper context. Without this, `this` would point to the global object
            } catch (e) {
                logError(e, args, name, functionConfig);
                throw e;
            }
        }
    }[name]; // this is a slightly hacky way of giving a dynamically created function a name, this can help for logging purposes
};
