/* Imports */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadService {

	private isActive = new Subject<string>();

	/**
	 * takes a blob and saves it to the user's filesystem
	 * @param blob  the blob you want to save
	 * @param name  the name you want for the saved file
	 */
	public downloadHelper(blob: Blob, name: string) {
		const fileURL = URL.createObjectURL(blob);
		const a: any = document.createElement('a');
		a.href = fileURL;
		a.download = name;
		a.style = 'display: none';
		document.body.appendChild(a);
		a.click();
		a.remove();
		URL.revokeObjectURL(fileURL);
	}

	public setStatus(status: string) {
		this.isActive.next(status);
	}

	public clearStatus() {
		this.isActive.next(null);
	}

	public getStatus(): Observable<string> {
		return this.isActive.asObservable();
	}

}
