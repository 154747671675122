/* Imports */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

/* Services */
import {
	ProjectService, AlertService, OrganizationService
} from '@shared/services';

/* Models */
import { Alert, Organization, Project } from '@shared/models';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

const thirtySeconds = 30 * 1000;

@Component({
	selector: 'app-create-projects',
	templateUrl: './create.projects.component.html',
	styleUrls: ['./create.projects.component.scss']
})
export class CreateProjectsComponent implements OnInit {
	@ViewChild("projectNameInput") projectNameInput;

	public projectTags: Array<string> = [];	
	public projectForm: FormGroup;
	public fileDataSource = new MatTableDataSource<any>();

	public isLoading: boolean = true;
	public showTags: boolean = false;
	public activeOrg: Organization;
	public createdProject: any;

	private _existingProjectNames: Array<string>;
	private _nameFocusCheck;

	constructor(
		public dialog: MatDialog,
		private _alertService: AlertService,
		private _projectService: ProjectService,
		private _formBuilder: FormBuilder,
		private _orgService: OrganizationService,
		private _router: Router,
		public _matDialogRef: MatDialogRef<CreateProjectsComponent>,
	) {}	// End-of constructor

	ngOnInit() {

		this._orgService.getActiveOrg().subscribe( ({ organization }) => {
			this.activeOrg = organization;
			this._projectService.getList(organization).then(projectList => {
				this._existingProjectNames = projectList.map(proj => proj.name);
				this.projectForm = this.createProjectForm();
				this.isLoading = false;
			});
		})

		this._nameFocusCheck = setInterval(() => {
			if(this.projectNameInput) {
				this.projectNameInput.nativeElement.focus();
				clearInterval(this._nameFocusCheck);
			}
		}, 20);
		
	}	// End-of ngOnInit

	closeModal(e?) {
		this._matDialogRef.close(e);
	}

	createProjectForm(): FormGroup {

		this.isUniqueProjectName('');

		let formConfig = {
			name: ['', [Validators.required, Validators.maxLength(128)]],
		};
		return this._formBuilder.group(formConfig);
	}	// End-of createProjectForm

	async createProject(): Promise<void> {

		this.projectForm.value.name = this.projectForm.value.name.trim();
		if(!this.isUniqueProjectName(this.projectForm.value.name)) {
			this.projectForm.controls['name'].setErrors({'nameTaken': true});
			return;
		}

		this.isLoading = true;

		const project = new Project({
			name: this.projectForm.value.name,
			organization_id: this.activeOrg.id,
			descriptors: { tags: this.projectTags.reverse() }
		})

		this._projectService.create(project).then(rtnData => {
			this.isLoading = false;
			this._alertService.success(new Alert(project.name + ' created and saved.', undefined, thirtySeconds));
			this.navigateTo(rtnData);
			this.closeModal('Project Created');
		}, error => {
			this.isLoading = false;
			console.error("Failed to create new project: ", error);
			this._alertService.error(new Alert(project.name + ' failed to create.'));
		});
	}	// End-of createProject

	navigateTo(project): void {
		this._router.navigate(['projects', 'view', project.project_id]);
	}	// End-of navigateTo

	toggleTags(): void {
		this.showTags = !this.showTags;
	}   // End-of toggleTags
	
	isUniqueProjectName(name: string): boolean {
		return this._existingProjectNames.indexOf(name) === -1;
	}
}	// End-of class CreateProjectsComponent
