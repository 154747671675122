
/* Imports */
import { Component, Input, Output, OnInit, OnChanges, OnDestroy, NgZone, ViewChild, EventEmitter, ElementRef, AfterContentInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, RouterModule, ActivatedRoute } from '@angular/router';
import { AuthenticationService, OrganizationService, FinanceService } from '../../shared/services';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-view-gcp',
	templateUrl: './view-gcp.component.html',
	styleUrls: ['./view-gcp.component.scss']
})
export class ViewGCPComponent implements OnInit, OnChanges {

	@ViewChild('editInput') editElement: ElementRef;
	@Input('disabled') disabled: boolean = false;
	@Input('dataSource') dataSource: MatTableDataSource<any> = new MatTableDataSource();
	@Input('selection') selection = new SelectionModel<any>(true, []);
	@Input('positionUnit') positionUnit: 'ft' | 'sft' | 'm' = 'ft';
	@Input('epsgPositionOptions') epsgPositionOptions: Array<any> = [];
	@Input('suggestedColumns') set suggestedColumns(columns: Array<any>) {
		columns?.forEach(col => {
			this.columnEPSG[col.index] = col.value;
		})
	};
	@Input('dataColumns') set dataColumns(data: Array<string>) {
		if (data?.length) {
			this._dataColumns = data;
			this.displayedColumns = this.displayedColumns.concat(data);
		}
	};
	@Input() altitudeUnit: 'ft' | 'sft' | 'm' = 'ft';
	@Output() altitudeUnitChange: EventEmitter<'ft' | 'sft' | 'm'> = new EventEmitter();

	@Output('parsingIsValid') parsingIsValid: EventEmitter<boolean> = new EventEmitter();
	@Output('outHeaders') outHeaders: EventEmitter<any> = new EventEmitter();

	public _dataColumns: Array<string> = [];
	// public displayedColumns: Array<string> = ['select', 'number'];
	public displayedColumns: Array<string> = ['select', 'number', 'delete'];

	public altitudeUnitList: Array<any> = [
		{text: 'International Feet', value: 'ft'},
		{text: 'US Survey Feet', value: 'sft'},
		{text: 'Meters', value: 'm'}
	];
	public editCell: any = null;
	public columnEPSG: Array<any> = [];

	constructor (
		private _cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
		this.selectAll();
		this.checkAllEPSGOptions();
	}	// End-of ngOnInit

	ngOnChanges() {
		this.checkAllEPSGOptions();
	}

	removeRow(ind): void {
		let arr = this.dataSource.data;
		arr.splice(ind, 1);
		this.dataSource.data = arr;
		this._cdr.detectChanges();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}	// End-of isAllSelected


	/** Selects all rows if they are not all selected; otherwise clear selection. */
	selectAll(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));

	}	// End-of selectAll


	showElevationOptions(i: number): boolean {
		// Checks if using altitude or elevation
		return !this.disabled
			&& (this.columnEPSG[i] === 'elevation' && this.epsgPositionOptions.some(x => x.value === 'elevation')
				|| this.columnEPSG[i] === 'altitude' && this.epsgPositionOptions.some(x => x.value === 'altitude'));
	}


	/** The label for the checkbox on the passed row */
	selectRow(row?: any): string {

		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;

	}	// End-of selectRow

	editSelectedCell(row, r, c): void {
		this.editCell = {row: r, col: c, value: row[c]};
		setTimeout(() => { this.editElement?.nativeElement?.focus() });
	}

	saveSelectedCell(): void {
		if (this.editCell) {
			// Update the datasource with the new value. Unsure of a better way to do this.
			const dataSource = this.dataSource.data;
			dataSource[this.editCell.row][this.editCell.col] = this.editCell.value;
			this.dataSource.data = dataSource;
			this._cdr.detectChanges();
		}
		this.editCell = null;
	}

	checkUsedColumn(ind, colName): boolean {
		// Return true iff column is used elsewhere
		return this.columnEPSG.some((opt, optInd) => ind !== optInd && opt === colName);
	}

	checkAllEPSGOptions() {

		// Return true iff column is used elsewhere
		let res = this.epsgPositionOptions.every(epsg => {
			return this.columnEPSG.some(opt => opt === epsg.value);
		})
		this.parsingIsValid.emit(res);
		if (res) {
			let outColumnHeaders = this.columnEPSG.map((x, ind) => {
				if (this.epsgPositionOptions.some(opt => opt.value === x) || x === 'name') {
					if (x === 'elevation') {x = 'altitude'}; // Override elevation to altitude
					return {value: x, index: ind}
				}
			}).filter(Boolean);
			this.outHeaders.emit(outColumnHeaders)
		}
	}

	altitudeChange(): void {
		this.altitudeUnitChange.emit(this.altitudeUnit);
	}

}	// End-of class TestComponent
