/* Imports */
import { Component, Input, Output, ViewChild, EventEmitter, TemplateRef, AfterViewInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatExpansionPanel } from '@angular/material/expansion';
import {AlertService} from '@shared/services/alert.service';
import { isEmpty } from "lodash";

@Component({
	selector: 'app-section',
	templateUrl: './section.component.html',
	styleUrls: ['./section.component.scss']
})
export class SectionComponent implements AfterViewInit{

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

	@Input("section") section: any = null;
	@Input('type') type: string = null;
	@Input('isUsingService') isUsingService: boolean = false;
	@Input('showOptions') showOptions: boolean = true;
	@Input('showSelect') showSelect: boolean = false;
	@Input('sectionOpen') sectionOpen: boolean = false;
	@Input("menuContent") menuContent: TemplateRef<any>;
	@Input("headerContent") headerContent: TemplateRef<any>;
	@Input() updateSectionFunction: Function;

	@Output('sectionEvent') sectionEvent: EventEmitter<any> = new EventEmitter();
	@Output('sectionOpenToggle') sectionOpenToggle: EventEmitter<any> = new EventEmitter();

	editSection = false;
	sectionName;
	public usePosition: boolean = true;
	public menuOpen: boolean = false;
	public open: boolean = false;
	public contextMenuPosition = { x: '0px', y: '0px' };
	public isLoading = false;

	constructor(
		private _alertService: AlertService,
	) {
	}

	ngAfterViewInit() {
		if (this.sectionOpen) {
			this.expansionPanel.open();
		}
	}

	toggleEditSection(): void {
		if( !this.editSection )
			this.sectionName = this?.section?.name;
		this.editSection = !this.editSection;
	}

	toggleOpen(val: boolean): void {
		this.sectionOpenToggle.emit(val);

		if (val && this.updateSectionFunction) {
			this.isLoading = true;
			this.updateSectionFunction( this.section ).then( rtnSection => {
				this.isLoading = false;
				if (!isEmpty(rtnSection)) this.section = rtnSection;
			} ).catch( console.error );
		}
	}

	openMenu(e, index, usePosition): void {

		e.preventDefault();
		e.stopPropagation();

		this.usePosition = usePosition;
		this.contextMenuPosition.x = e.pageX + 'px';
		this.contextMenuPosition.y = e.pageY + 'px';
		this.menuOpen = true;
		this.menuTrigger._handleClick(e);
	}

	contextMenuStyle(): any {
		return this.usePosition
			? {
				left: this.contextMenuPosition.x,
				top: this.contextMenuPosition.y,
			}
			: null;
	}
}
