
/* Imports */
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { AlertService, UtilsService, GCPService } from '../../../shared/services';
import { GCP, Alert } from '../../../shared/models';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
	selector: 'app-create-gcp-modal',
	templateUrl: './create-gcp.modal.html',
	styleUrls: ['./create-gcp.modal.scss']
})
export class CreateGCPModal implements OnInit {

	public epsgControl: FormControl = new FormControl(null, Validators.required);
	public confirmationText: string = 'Are you sure?';
	public epsgsOptions: any[] = [];
	public gcpFormGroup: FormGroup;
	public project_id: number = null;
	public showAngleEPSG: boolean = true;
	public positionUnit: 'ft' | 'sft' | 'm' = 'ft';
	public altitudeUnit: 'ft' | 'sft' | 'm' = 'ft';

	public tipText: Array<any> = [
		{title: 'Longitude:', text: 'Geographic coordinate that indicates the east-west position of a location on the Earth’s surface, measured in meridians east or west in relation to the 0° prime meridian in Greenwich, England.'},
		{title: 'Latitude:', text: 'Geographic coordinate that indicates the north-south position of a location on the Earth’s surface, with parallels running from 0° at the equator to 90° at the north and south poles.'},
		{title: 'Altitude:', text: 'The distance above the earth’s surface in units of feet. A negative altitude indicates a position below the horizon.'},
	];

	public secondaryTipText: Array<any> = [
		{title: 'Northing:', text: 'Geographic coordinate that indicates the northern position of a location relative to a designated locale.'},
		{title: 'Easting:', text: 'Geographic coordinate that indicates the eastern position of a location relative to a designated locale.'},
		{title: 'Elevation:', text: 'The distance above the locale in units of feet. A negative elevation indicates a position below the locale.'},
	];

	public altitudeUnitList: Array<any> = [
		{text: 'International Feet', value: 'ft'},
		{text: 'US Survey Feet', value: 'sft'},
		{text: 'Meters', value: 'm'}
	];

	constructor(
		private _cdr: ChangeDetectorRef,
		private _alertService: AlertService,
		public _dialogRef: MatDialogRef<CreateGCPModal>,
		private _gcpService: GCPService,
		private _utilService: UtilsService,
		private _formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.project_id = data.projectId;
		this.gcpFormGroup = this.createFormGroup();
	}

	ngOnInit() {
	}	// End-of ngOnInit

	createFormGroup(): FormGroup {
		return this._formBuilder.group({
			name: ['', [Validators.required]],
			longitude: [{value: '', disabled: true}, [Validators.required, isFinite]], // isFinite checks that the value is a number _and_ not Infinity or NaN
			latitude: [{value: '', disabled: true}, [Validators.required, isFinite]],
			altitude: [{value: '', disabled: true}, [Validators.required, isFinite]],
			northing: [{value: '', disabled: true}, [Validators.required, isFinite]],
			easting: [{value: '', disabled: true}, [Validators.required, isFinite]],
		});
	}


	toggleValidators(disableGroup, enableGroup?): void {

		let group = this.gcpFormGroup;

		disableGroup.forEach(key => {
			let control = group.get(key);
			control.disable();
		})
		enableGroup?.forEach(key => {
			let control = group.get(key);
			control.enable();
		})
		group.updateValueAndValidity();
		this._cdr.detectChanges();
	}

	handleCoordinateChange(e): void {
		if (e.type === 'latlong') this.setEPSGToLatLon()
		if (e.type === 'positional') this.setEPSGToPositional(e.data.epsg);
		this.toggleValidators(e.data.disableGroup, e.data.enableGroup)
	}

	setEPSGToLatLon(): void {
		this.showAngleEPSG = true;
		this.tipText = [
			{title: 'Longitude:', text: 'Geographic coordinate that indicates the east-west position of a location on the Earth’s surface, measured in meridians east or west in relation to the 0° prime meridian in Greenwich, England.'},
			{title: 'Latitude:', text: 'Geographic coordinate that indicates the north-south position of a location on the Earth’s surface, with parallels running from 0° at the equator to 90° at the north and south poles.'},
			{title: 'Altitude:', text: 'The distance above the earth’s surface in units of feet. A negative altitude indicates a position below the horizon.'},
		];
	}

	setEPSGToPositional(epsg): void {
		this.positionUnit = epsg.unit.startsWith('met') ? 'm' :
			epsg.unit.startsWith('US') ? 'sft' : 'ft';
		this.altitudeUnit = this.positionUnit;
		this.showAngleEPSG = false;
		this.tipText = this.secondaryTipText;
	}

	confirmationAction(gcpParams?: GCP): void {
		// Set to either params or true, to alert the confirmationModal of the confirmation
		this._dialogRef.close(gcpParams ? gcpParams : true)
	}

	createGCP(): void {
		let gcpParams = new GCP({
			project_id: this.project_id,
			name: this.gcpFormGroup.value.name,
			units: this.altitudeUnit,
			longitude: this.gcpFormGroup.value.longitude,
			latitude: this.gcpFormGroup.value.latitude,
			altitude: this.gcpFormGroup.value.altitude,
			northing: this.gcpFormGroup.value.northing,
			easting: this.gcpFormGroup.value.easting,
			coordinate_system: {
				epsg: this.epsgControl.value[0],
				description: this.epsgControl.value[1],
			},
		})
		this._gcpService.create(gcpParams).then(rtn => {
			this._alertService.success(new Alert(gcpParams.name + ' created and saved.'));
			this.confirmationAction(rtn);
		}, err => {
			console.error(err);
			this._alertService.error(new Alert('Failed to create GCP.'));
		})
		// this._dialogRef.close(gcpParams)
	}

}	// End-of class TestComponent
