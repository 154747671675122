import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {

	constructor(private _titleService: Title) { }

	public setTitle(text: string): void {
		this._titleService.setTitle('Mapware: ' + text);
	}

}