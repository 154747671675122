
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from './../../../environments/environment';

@Component({
	selector: 'app-error',
	templateUrl: './error.modal.html',
	styleUrls: ['./error.modal.scss']
})
export class ErrorModal implements OnInit {

	public info: any = {
		title: 'Mapware has encountered an error',
		error: 'Unknown error',
		supportPage: {
			title: 'Mapware Support',
			path: ''
		},
		defaultActionText: 'Return',
		couldRefresh: true,
	};

	public supportEmail = environment.supportEmail;

	constructor (
		public _dialogRef: MatDialogRef<ErrorModal>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data) {
			Object.assign(this.info, data);
		}
		this.info.supportPage.path = environment.supportGuide + this.info.supportPage.path;
	}

	ngOnInit(): void {
	}

	defaultAction(): void {
		this._dialogRef.close(false)
	}

	refreshAction(): void {
		this._dialogRef.close(true);
	}

}
