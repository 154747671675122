<mat-radio-group
	*ngIf="!_activePlan"
	[(ngModel)]="selectedPlan"
	(change)="changeValue()"
	class="H6Black700Regular plan-radio-container"
>
	<mat-radio-button
		*ngFor="let plan of chargeBeePlansForDisplay"
		[value]="plan"
	>
		{{ plan.name }}
	</mat-radio-button>
</mat-radio-group>
<mat-card appearance="outline" class="play-details-card">
	<div style="display: flex">
		<span class="H6Blue500Semibold">{{ profPlan.name }} Plan</span>
		<span class="H6Black700Regular" style="margin-left: auto">
            {{ selectedPlan.price | currency }}
			{{ selectedPlan.isYearly ? " / year" : " / month" }}
        </span>
	</div>
	<mat-divider color="#aeb3b9" style="margin: 16px 0"></mat-divider>
	<section class="plan-details-section">
		<div class="H7Black500Regular">
			{{ selectedPlan.description.header }}
		</div>
		<div
			class="H7Black700Regular"
			*ngFor="let detail of selectedPlan.description.details"
		>
			{{ detail }}
		</div>
	</section>
</mat-card>
