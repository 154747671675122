
<div class="image-group-controls" [ngClass]="{'disabled': !images?.length}">
	<ng-container *ngIf="listType !== 'list'">
		<span>
			<mat-checkbox class="H6Black500Regular" [(ngModel)]="showInfo">Show Info</mat-checkbox>
		</span>
		<span class="sort-options-select">
			<mat-form-field appearance="fill">
				<mat-select class="H6Black500Regular" [value]="sort" (selectionChange)="setSort($event.value)">
					<ng-container *ngFor="let option of sortOptions">
						<mat-option [value]="option.value" *ngIf="option.value">{{ option.text }}</mat-option>
					</ng-container>
				</mat-select>
			</mat-form-field>
			<button mat-button (click)="toggleSortDirection()" matTooltip="Sort Direction" matTooltip>
				<mat-icon [ngClass]="{'rotated': sortDirectionUp}">arrow_downward</mat-icon>
			</button>
		</span>
	</ng-container>
	<span *ngIf="listType === 'list'" [ngClass]="{'disabled': !someSelected()}">
		<ng-container>
			<button mat-icon-button (click)="outEvent('download');" matTooltip="Download Selected">
				<mat-icon> vertical_align_bottom </mat-icon>
			</button>
			<button mat-icon-button (click)="outEvent('move');" [disabled]="disableMove"  [ngClass]="{'disabled': !hasPermissions}" matTooltip="Move Selected">
				<mat-icon> drive_file_move_outline </mat-icon>
			</button>
			<button mat-icon-button (click)="outEvent('favorite');" [matTooltip]="selectedAreFavorited() ? 'Remove Selected from Favorites' : 'Add Selected to Favorites'">
				<mat-icon> star_border </mat-icon>
			</button>
			<button mat-icon-button (click)="outEvent('delete-images');" [ngClass]="{'disabled': !hasPermissions}" matTooltip="Move Selected To Trash">
				<mat-icon> delete_outline </mat-icon>
			</button>
		</ng-container>
	</span>
	<span>
		<button [ngClass]="{'active': listType === 'list'}" mat-icon-button (click)="setListType('list')" matTooltip="List Items"
				matTooltipShowDelay="500">
			<mat-icon>list</mat-icon>
		</button>
		<button [ngClass]="{'active': listType === 'small'}" mat-icon-button (click)="setListType('small')" matTooltip="Small Items"
				matTooltipShowDelay="500">
			<mat-icon>grid_on</mat-icon>
		</button>
		<button [ngClass]="{'active': listType === 'large'}" mat-icon-button (click)="setListType('large')" matTooltip="Large Items"
				matTooltipShowDelay="500">
			<mat-icon>grid_view</mat-icon>
		</button>
	</span>
</div>

<div class="images" [ngClass]="{ listType, 'short-list': !hasScroll}">
	<div class="images-header" [ngClass]="{'has-scroll': hasScroll}" *ngIf="listType === 'list'">
		<div class="image-selection">
			<span>
				<mat-checkbox [disabled]="isLoading"
							  [checked]="allSelected()"
							  [indeterminate]="!allSelected() && someSelected()"
							  (change)="toggleAll()">
				</mat-checkbox>
			</span>
		</div>
		<span *ngFor="let sortOption of sortOptions">
			<ng-container *ngIf="!sortOption.value">
				<p>{{ sortOption.text }}</p>
			</ng-container>
			<ng-container *ngIf="sortOption.value">
				<button [ngClass]="{'active': isSort(sortOption.value)}" mat-button (click)="setSort(sortOption.value)">
					<p>{{ sortOption.text }}</p>
					<mat-icon [ngClass]="{'rotated': checkSortAndDir(sortOption.value)}">arrow_downward</mat-icon>
				</button>
			</ng-container>
		</span>
	</div>

	<div class="uploading-images" *ngIf="uploadImages?.length">
		<ng-container *ngIf="uploadingItems?.length">
			<span>
				<p>
					Uploading {{ uploadImages.length }} {{plural("Image", uploadImages.length)}} ({{ percentageComplete }}% complete)
				</p>
				<mat-icon *ngIf="failedUploadItems?.length" matTooltip="One or more images had an issue, more information will be available after the upload.">warning</mat-icon>
			</span>
		</ng-container>
		<ng-container *ngIf="!uploadingItems?.length">
			<span>
				Upload complete:
				<span *ngIf="completedItems?.length">
					Uploaded {{ completedItems.length }} {{plural("Image", completedItems.length)}} to <b>{{ imageGroup.name }}</b>.
				</span>
				<span class="error" *ngIf="failedUploadItems?.length">
					{{ failedUploadItems.length }} {{plural("Image", failedUploadItems.length)}} failed to upload. <button mat-button [matMenuTriggerFor]="failedMenu">More Info</button>
					<mat-menu #failedMenu>
						<span class="failed-menu">
							<b>The following images could not be uploaded:</b>
							<span class="content">
								<p *ngFor="let item of failedUploadItems"> {{ item.name }} </p>
							</span>
						</span>
					</mat-menu>
				</span>
			</span>
		</ng-container>
		<button class="link" mat-button (click)="clearAndUpdate()">Refresh list</button>
	</div>

	<ng-container *ngIf="images?.length">
		<virtual-scroller #scroll [items]="images" [ngClass]="listType" (vsStart)="onVSStart($event)" (vsChange)="onVSChange($event);">
			<app-image
				*ngFor="let image of scroll.viewPortItems;"
				[index]="image.index"
				[ngClass]="listType"
				[showInfo]="showInfo"
				[uploaded]="uploadedImageIds[image.id]"
				[data]="{image, project_id: imageGroup?.project_id}"
				[disableMove]="disableMove"
				[sensor]="imageGroup.sensor"
				[selected]="image.selected"
				(selectedChange)="onSelectionChange($event, image)"
				[listType]="listType"
				(outEvent)="outEvent($event)"
				[hasPermissions]="hasPermissions">
			</app-image>
			<div *ngIf="isLoading" class="loading-container">
				<div class="dot-stretching"></div>
			</div>
			<div class="loading-container" *ngIf="!canGetMoreImages && hasScroll">No more images to load.</div>
		</virtual-scroller>
	</ng-container>

	<span class="floating-info" *ngIf="!images?.length && !uploadImages?.length">
		<span *ngIf="isLoading" class="loading-container">
			<div class="dot-stretching"></div>
		</span>
		<span class="empty-image-group" *ngIf="!isLoading">
			<p>No images in the Image Group.</p>
			<p>Click "Upload" in the top right to get started.</p>
		</span>
	</span>

</div>
