
/* Imports */
import { Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageBatch, Project } from "@shared/models";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { flagLayer, availableFeatureFlags } from '@shared/featureFlags';

import { isNil } from "lodash";
import moment from "moment";
import {
	findUniqueName,
	getAcceptedImageTypes,
	getAcceptedImageTypesString,
	filterImagesAcceptedTypes,
	listFormatters
} from "@shared/services";

@Component({
	selector: 'app-upload-photos-dialog',
	templateUrl: './upload-photos.dialog.html',
	styleUrls: ['./upload.component.scss']
})

export class UploadPhotosDialog implements AfterViewInit {

	@ViewChild('fileInput') fileInput: ElementRef;
	@ViewChild('folderInput') folderInput: ElementRef;

	public batches: ImageBatch[];
	public project: Project;
	public showUpload: boolean = false;
	public showBatch: boolean = false;
	public showDropzoneWarning: boolean = false;
	public batchForm: FormGroup;
	public filesToUpload: File[] = [];
	public folderName: string = "";
	public showFiletypeError: boolean = false;
	public acceptedImageTypesString = getAcceptedImageTypesString();
	public imageTypesOrString: string = "JPEG, TIFF, or PNG";

	constructor (
		public _dialogRef: MatDialogRef<UploadPhotosDialog>,
		private _formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.createFormGroup(data).then(rtnForm => {
			this.batchForm = rtnForm;
		});

		const { formatOr } = listFormatters;
		this.imageTypesOrString = formatOr(
			getAcceptedImageTypes()
				.map(x => x.toLocaleUpperCase()
					.replace('.', ''))
		);
	}

	ngAfterViewInit() {
		this.fileInput.nativeElement.value = ''; // Allow same file to be uploaded, in the event of similar names
	}	// End-of ngOnInit

	async createFormGroup({ batches = this.batches, defaultBatch = null }) {

		if (batches?.length) {
			this.batches = batches;
		}

		const baseName = "Uploaded On " + moment().format('M/D/Y');
		const defaultBatchName = await findUniqueName(baseName, this.isUniqueName.bind(this));

		return this._formBuilder.group({
			batch: 	[defaultBatch ?? "", [Validators.required]],
			name: 	[defaultBatchName, [Validators.required, Validators.maxLength(128)]],
		});

	}	// End-of setupForms

	isUniqueName(name: string): boolean {
		return !this.batches?.length || (this.batches?.length && isNil(this.batches.find(x => x.name === name)));
	}

	close(): void {
		this._dialogRef.close(false);
	}

	dropzoneUpload(files): void {
		if (files.length) {
			if (flagLayer.isEnabled(availableFeatureFlags.folderUpload)) {
				this.filesToUpload = files;
			} else {
				this.filesChanged(files);
			}
		} else {
			this.showDropzoneWarning = true;
		}
	}

	folderChanged(event): void {
		if (event.target?.files?.length) {
			const files = event.target.files;
			const fileName = files[0].webkitRelativePath;
			this.folderName = fileName?.match(/([\w ]+)\//)[1] ?? "";
			if (this.folderName) this.batchForm.patchValue({ name: this.folderName });
			this.filesToUpload = files; // Does not submit, but stores these for the user to confirm
		}
	}

	filesChanged(input: FileList | File[] = this.filesToUpload): void {

		this.showFiletypeError = false;

		// Ensure files are of the proper extension, if not, filter and alert
		const files = filterImagesAcceptedTypes(input);
		this.showFiletypeError = files.length !== input.length;

		if (files.length) {
			const batch_id = this.batchForm.value.batch?.id ? parseInt(this.batchForm.value.batch.id) : null;
			const output = {
				files: input,
				batch_id,
				name: this.batchForm.value.name
			};
			this._dialogRef.close( output );
		}

	}	// End-of filesChanged

	clearFolderUpload() {
		this.filesToUpload = [];
		this.folderName = "";
		this.createFormGroup({}).then(rtnForm => {
			this.batchForm = rtnForm;
		});
	}

}	// End-of class TestComponent
