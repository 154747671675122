<ng-container *ngIf="listItem">
	<div class="sidenav-list-item" [ngClass]="{'sidenav-shrunk': !expandSidenav}" (click)="navigateTo(listItem.route)">
		<span class="item-icon">
			<mat-icon [ngClass]="{'rotate': listItem.rotateIcon}">{{listItem.icon}}</mat-icon>
		</span>

		<span *ngIf="expandSidenav" class="item-text">
			{{listItem.name}}
		</span>
	</div>
</ng-container>

<ng-container *ngIf="!listItem">
	<ng-content></ng-content>
</ng-container>
