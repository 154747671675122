
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'sidenav-list-item',
	templateUrl: './sidenav-list-item.html',
	styleUrls: ['./sidenav-list-item.scss']
})

export class AerialSidenavListItem implements OnInit {

	@Input('item') listItem: sideNavModel;
	@Input('expandSidenav') expandSidenav: boolean = true;

	constructor (
		private _router: Router,
	) {
	}

	ngOnInit() {
	}

	navigateTo(route): void {
		if (route) {
			this._router.navigate(route);
		}
	}
}	// End-of AerialSidenavListItem

export class sideNavModel {
	name: string
	icon: string;
	route?: string;
	rotateIcon?: boolean;

	constructor(name = '', icon = '', route = '', rotateIcon = false) {
		this.name = name;
		this.icon = icon;
		this.route = route;
		this.rotateIcon = rotateIcon;
	}
}
