
<mat-card appearance="outline">
	<ng-container *ngIf="!displayFile && !mapView">
		<ng-container *ngTemplateOutlet="t_confirmation"></ng-container>
	</ng-container>
	<ng-container *ngIf="displayFile && !mapView">
		<ng-container *ngTemplateOutlet="t_fileView"></ng-container>
	</ng-container>
	<ng-container *ngIf="mapView">
		<ng-container *ngTemplateOutlet="t_mapView"></ng-container>
	</ng-container>
</mat-card>

<ng-template #t_confirmation>
	<div class="card-container">
		<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>

		<div class="title"> Confirm file type </div>

		<div class="subtitle">
			Files larger than 5 MB cannot be previewed or parsed for GCP data.
		</div>

		<div>
			<span class="add">
				<button mat-button
					data-testid="fileUpload"
					(click)="uploadInput.nativeElement.click()"
					class="add-button">
					<mat-icon>add_circle_outline</mat-icon>
					<span class="add-text">Add more</span>
				</button>
			</span>
		</div>

		<mat-divider color="#dee0e3"></mat-divider>

		<div class="header-container">
			<span class="header-left">File Name</span>
			<span class="header-right">Action</span>
		</div>

		<div class="body-container" *ngFor="let file of fileList; let i = index;">
			<span class="body-left" [ngClass]="{'body-flex' : !file.data}">
				<div class="file-title">
					{{file.name}}
					<button data-testid="delete_button" mat-icon-button class="delete-button" (click)="removeFile(i);" matTooltip="Delete file"><mat-icon>delete_outline</mat-icon></button>
				</div>
			</span>
			<span class="body-right">
				<button mat-stroked-button color="primary" (click)="showFile(file);" [disabled]="!file.data">Parse GCP data</button>
			</span>
		</div>
		<input style="display: none;" multiple type="file" accept="{{acceptedFileTypes}}" #fileInput (change)="filesChanged($event.target.files)">
	</div>
</ng-template>


<ng-template #t_fileView>
	<div class="title-container">
		<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
		<div class="title-pretext" *ngIf="processName">{{processName}}</div>
		<div class="title">
			<button *ngIf="!processName" mat-button class="back-button" matTooltip="Back to file list" (click)="showFile(null);">
				<mat-icon class="back-icon">keyboard_backspace</mat-icon>
			</button>
			{{displayFile.name}}
		</div>

		<mat-divider color="#dee0e3"></mat-divider>

		<div class="secondary-container">
			<span class="secondary-subtitle">Output Preview</span>
			<span class="link H7Blue500Regular" (click)="showMapView();" [ngClass]="{'disabled' : epsgControl.invalid || !parsingIsValid}">View selected on map</span>
			<!-- <span class="link">Preview all positions on map.</span> -->
			<button [disabled]="epsgControl.invalid || !parsingIsValid" class="save-button" mat-stroked-button color="primary" (click)="submitFile(displayFile, selection);">
				Parse File
			</button>
		</div>
	</div>


	<mat-form-field appearance="fill" class="autocomplete-container">
		<mat-label>Search for a coordinate system</mat-label>
		<div class="autocomplete-inner">
			<mat-icon class="search-icon">search</mat-icon>
			<input
				class="autocomplete-input"
				type="text"
				matInput
				placeholder="Search for a coordinate system"
				[matAutocomplete]="auto"
				[formControl]="epsgControl">
				<mat-autocomplete
					#auto="matAutocomplete" class="autocomplete" [displayWith]="displayOptions" (optionSelected)="onAutocomplete($event)">
					<mat-option
						data-testid="coordinate_option"
						class="option"
						*ngFor="let opt of filteredEpsgOptions | async"
						[value]="opt">
						<div class="epsg-view">
							<span class="epsg-left">{{ opt[1] }}</span>
							<span>EPSG: {{ opt[0] }}</span>
						</div>
				</mat-option>
			</mat-autocomplete>
			<span class="sub-text" *ngIf="epsgControl.value && isObject(epsgControl.value)">(EPSG: {{epsgControl.value[0]}})</span>
			<button mat-button matTooltip="Clear" class="autocomplete-clear-button" *ngIf="epsgControl.valid" (click)="epsgControl.setValue(null);">
				<mat-icon class="search-icon">close</mat-icon>
			</button>
		</div>
	</mat-form-field>

	<div class="gcp-card">
		<app-view-gcp *ngIf="dataSource && dataSource.data"
			[ngClass]="{'disabled': epsgControl.invalid}"
			[selection]="selection"
			[disabled]="epsgControl.invalid"
			[dataSource]="dataSource"
			[dataColumns]="dataColumns"
			[suggestedColumns]="suggestedColumns"
			[(altitudeUnit)]="altitudeUnit"
			[positionUnit]="positionUnit"
			[epsgPositionOptions]="epsgPositionOptions"
			(parsingIsValid)="setValidation($event);"
			(outHeaders)="outHeaders = $event;"></app-view-gcp>
	</div>

</ng-template>

<ng-template #t_mapView>
	<div class="title-container">
		<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
		<div class="title-pretext" *ngIf="processName">{{processName}}</div>
		<div class="title">
			<button *ngIf="!processName" mat-button class="back-button" matTooltip="Back to file list" (click)="mapView = false;">
				<mat-icon class="back-icon">keyboard_backspace</mat-icon>
			</button>
			{{displayFile.name}}
		</div>
		<mat-divider color="#dee0e3"></mat-divider>
	</div>
	<app-map-preview [selection]="selection" [headers]="outHeaders" [coordinate_system]="coordinate_system"></app-map-preview>
</ng-template>
