
<ng-container *ngIf="isDeveloper && permissionList.length" >
	<button mat-button class="rounded-menu" [matMenuTriggerFor]="adminMenu">
		<span class="H6Black700Regular">Admin tools</span>
	</button>

	<mat-menu #adminMenu class="mat-menu large-menu">
		<ng-template matMenuContent>
			<span class="rounded-menu-container" (click)="$event.stopPropagation();">

				<div style="margin-bottom: 32px; display: flex;">
					<span class="left-text">View page as:</span>
					<mat-form-field appearance="fill">
						<mat-select primary
							[value]="'admin'"
							(selectionChange)="overridePermission($event.value)"
							class="H7Black700Regular">
							<mat-option class="H7Black700Regular" *ngFor="let permission of permissionList" [value]="permission.value">{{permission.text}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="H7Black500Regular">Press enter to update the following.<br> An empty value will reset.</div>

				<div style="display: flex; margin-top: 24px; margin-bottom: 24px;">
					<span style="width: 200px; margin-right: auto;">
						<div class="H6Black700Regular">Override credits</div>
						<div class="H7Black500Regular">Will override all orgs.</div>
					</span>
					<span style="width: 128px;">
						<mat-form-field appearance="fill">
							<input matInput placeholder="#" data-testid="admin-credits" autocomplete="new-password" (keydown.enter)="overrideEvent($event.target.value, 'credits')" style="text-align: right; padding-right: 40px; width: 72px;">
							<span style="position: absolute; right: 12px;" class="H6Black500Regular">MP</span>
						</mat-form-field>
					</span>
				</div>

				<div style="display: flex; margin-bottom: 32px;">
					<span style="width: 200px; margin-right: auto;">
						<div class="H6Black700Regular">Override storage</div>
						<div class="H7Black500Regular">Will override all orgs.</div>
					</span>
					<span style="width: 128px;">
						<mat-form-field appearance="fill">
							<input matInput placeholder="#" data-testid="admin-storage" autocomplete="new-password" (keydown.enter)="overrideEvent($event.target.value, 'storage')" style="text-align: right; padding-right: 40px; width: 72px;">
							<span style="position: absolute; right: 12px;" class="H6Black500Regular">GB</span>
						</mat-form-field>
					</span>
				</div>

				<div style="display: flex;">
					<span style="width: 200px; margin-right: auto;">
						<div class="H6Black700Regular">Override Trial</div>
						<div class="H7Black500Regular">Set remaining days</div>
						<div class="H7Black500Regular">(Set to 0 for expired)</div>
					</span>
					<span style="width: 128px;">
						<mat-form-field appearance="fill">
							<input matInput placeholder="#" data-testid="admin-trial" autocomplete="new-password" (keydown.enter)="overrideEvent($event.target.value, 'trial')" style="text-align: right; padding-right: 40px; width: 64px;">
							<span style="position: absolute; right: 5px;" class="H6Black500Regular">Days</span>
						</mat-form-field>
					</span>
				</div>

				<div class="alert-options">
					<div class="H6Black700Regular">Test alerts</div>
					<div>
						<span *ngFor="let alert of alertOptions" class="link" (click)="sendTestAlert(alert);">{{alert}}</span>
					</div>
				</div>
			</span>
		</ng-template>
	</mat-menu>
</ng-container>
