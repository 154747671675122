<div class="map-floating-container top-left">

	<div class="map-component-box map-header">

		<div class="logo-button toolbar-item" (click)="returnToProject()">
			<img src="assets/icons/aa_logo.png">
		</div>

		<div class="project-title header-text" *ngIf="map.project">
			{{ map.project.name }}
		</div>
	</div>
	<div class="map-component-box map-header">
		<div class="orientation-header" matTooltip="Orient North" (click)="orientNorth()">
			<span class="user-orientation" *ngIf="!map.userCoords?.heading">
				<mat-icon class="circle">circle</mat-icon>
			</span>
			<span class="user-orientation" *ngIf="map.userCoords?.heading" [ngStyle]="getUserOrientationStyle(map.userCoords?.heading + map.orientation)">
				<mat-icon class="active">location_on</mat-icon>
			</span>
			<span class="orientation" [ngStyle]="getOrientationStyle(map.orientation)">
				<mat-icon class="offset-icon">expand_less</mat-icon>
				<b>N</b>
			</span>
		</div>
		<div class="orientation-header" *ngIf="map.userCoords?.heading" matTooltip="Add Marker At User Location" (click)="addUserMarker()">
			<span class="user-orientation">
				<mat-icon>location_on</mat-icon>
				<mat-icon class="add">add</mat-icon>
				<span class="mode">{{ map.threeDMode ? '3D' : '2D' }}</span>
			</span>
		</div>
	</div>

	<div class="map-component-box header-info" *ngIf="publicView">
		<span class="H6WhiteRegular">
			You are viewing a shared Map.
			<a href="https://mapware.com/" target="_blank" class="powered-by-link">Click Here</a> to learn more.
		</span>
	</div>
</div>

<div id="comparisonLayerNames" class="map-floating-container comparison-names-container"
	*ngIf="map.comparisonModeActivated && (map.comparisonLayers.left || map.comparisonLayers.right)"
	[ngStyle]="{'left': map.comparisonLayers.range}">
	<div class="map-component-box comparison-layers" *ngIf="map.comparisonLayers.left">
		<div class="layer-name">
			{{ map.comparisonLayers.left.name }}
		</div>
	</div>
	<div class="map-component-box comparison-layers" *ngIf="map.comparisonLayers.right">
		<div class="layer-name">
			{{ map.comparisonLayers.right.name }}
		</div>
	</div>
</div>

<a href="https://mapware.com/" target="_blank" class="powered-by-link">
	<div class="map-floating-container bottom-right powered-by-label" *ngIf="publicView">
		<img src="assets/icons/aa_logo.png" class="mapware-icon">
		<div class="text-container">
			<div>
				Powered By
			</div>
			<div class="mapware">
				Mapware
			</div>
		</div>
	</div>
</a>
