<div class="main-container">
	<app-map-sidebar #sidebar
		[map]="this"
		[models]="models"
		[annotations]="annotations"
		[publicView]="publicView"
		[imageGroups]="imageGroups"
		[labelSets]="labelSets"
		[mapPermissions]="mapPermissions">
	</app-map-sidebar>

	<div class="left-container">
		<app-map-header #header
			[map]="this"
			[publicView]="publicView">
		</app-map-header>

		<app-map-tools #toolbar
			[map]="this"
			[publicView]="publicView"
			[mapPermissions]="mapPermissions">
		</app-map-tools>

		<app-map-elevation-legend #legend
			[map]="this"
			[toolbar]="toolbar">
		</app-map-elevation-legend>

		<app-map-minimap
			[renderer]="this.renderer"
			[showOutOfBoundsError]="showOutOfBoundsError"
			[threeDMode]="threeDMode">
		</app-map-minimap>

		<app-map-renderer *ngIf="!hidden"
			[map]="this"
			(ready)="rendererReady($event)">
		</app-map-renderer>
	</div>
</div>
