/* Imports */
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';

/* RxJS */
import {Observable, of} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';

/* Services */
import {GlobalService} from './global.service';
import {analyticsLayer} from '@shared/analyticsLayer';

import SparkMD5 from 'spark-md5';
import {isPlainObject, isNil} from "lodash";

interface RecorderRequestPayload {
	type: string;
	url: string;
	body?: object;
	result?: object;
	error?: object;
}

const MATCH_KEY = true as any;
// typescript doesn't like booleans as index values, but jokes on typescript,
// it's perfectly valid and we are taking advantage of that and the fact that duplicate keys
// overwrite one another in objects, so the **only** `true` key will be the **only** value to pick

/**
 * This function ensures the payload is a plain object because `window.postMessage` uses the internal [structure clone](https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm) which expects ordinary objects
 * @param {*} payloadValue
 * @returns {Object|Array}
 */
const formatPayload = (payloadValue) => {
	const matcher = {
		[MATCH_KEY]: () => ({value: payloadValue}), // any primative is converted to a simple object
		[(typeof payloadValue === "object") as any]: () =>
			// the payload maybe some Angular class with quirty properties, this will strip those troublemakers out
			JSON.parse(JSON.stringify(payloadValue)),
		[(payloadValue instanceof FormData) as any]: () =>
			Object.fromEntries(payloadValue),
		[isPlainObject(payloadValue) || (Array.isArray(payloadValue) as any)]: () =>
			payloadValue
	};
	return matcher[MATCH_KEY]();
};


const ensureObjectPayload = ({body, result, error}) => {
	const payload = {};

	if (!isNil(body)) payload["body"] = formatPayload(body);
	if (!isNil(result)) payload["result"] = formatPayload(result);
	if (!isNil(error)) payload["error"] = formatPayload(error);

	return payload;
};


function postRecorderMessage({
								 type,
								 url,
								 body = {},
								 result = {},
								 error = {}
							 }: RecorderRequestPayload) {
	try {
		window.postMessage(
			{
				action: "request",
				for: "cy-recorder",
				type,
				url,
				...ensureObjectPayload({body, result, error})
			},
			"*"
		);
	} catch (e) {
		console.error(
			"Cannot Post Message to the Cy Recorder\n",
			{url, body, result, type},
			e
		);
	}
}

@Injectable()
export class HttpService {

	public static paymentError: boolean = false;

	constructor(
		private http: HttpClient
	) {

	}   // End-of constructor

	private checkOptions(options): any {
		if (options == undefined) {
			options = {};
		}

		let user = GlobalService?.getUser();
		if (user && user.api_key) {
			if (options.headers) {
				options.headers = options.headers.set('x-api-key', GlobalService.getToken());
			} else {
				options.headers = new HttpHeaders().set('x-api-key', GlobalService.getToken());
			}
		}

		if (analyticsLayer.hasClientID()) {
			options.headers = options?.headers ?? new HttpHeaders();
			options.headers = options.headers.set('ga-client-id', analyticsLayer.getClientID());
		}

		return options;
	}	// End-of checkOptions

	/**
	 * [HANDLEERROR]
	 *
	 * @param {any} error
	 */
	private handleError(error): any {
		// if (GlobalService.isDeveloper) {
		// }
		if (error.error instanceof ErrorEvent) {
			console.warn("Network Error: ", error);
			return {};
		} else {
			console.warn(`Error ${error.status}: ${error}`, error);
			return {
				status: error.status,
				ignore: [200].includes(error.status)
			}
		}
	}	// End-of handleError

	/**
	 * [GET]
	 *
	 * @param {string} requestUrl
	 * @param {any} options
	 */
	public get(requestUrl: string, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl + requestUrl;

			options = this.checkOptions(options);

			this.http.get<any>(url, options).subscribe(rtn => {
				postRecorderMessage({url, result: rtn, type: "GET"});
				resolve(rtn);

			}, error => {
				let hOut = this.handleError(error);
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of get


	/**
	 * [GETRESPONSEHEADER]
	 *
	 * @param {string} requestUrl
	 * @param {string} header
	 * @param {any} options
	 */
	public getResponseHeader(requestUrl: string, header: string, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl + requestUrl;

			options = this.checkOptions(options);
			options.observe = 'response';

			this.http.get<any>(url, options).subscribe(rtn => {
				resolve(rtn["headers"].get(header));
			}, error => {
				let hOut = this.handleError(error);
				postRecorderMessage({url, error, type: "GET"});
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of getResponseHeader


	/**
	 * [POST]
	 *
	 * @param {string} requestUrl
	 * @param {any} body
	 * @param {any} options
	 */
	public post(requestUrl: string, body: any, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl + requestUrl;

			options = this.checkOptions(options);

			this.http.post<any>(url, body, options).subscribe(rtn => {
				postRecorderMessage({url, body, result: rtn, type: "POST"});
				resolve(rtn);
			}, error => {
				let hOut = this.handleError(error);
				postRecorderMessage({url, body, error, type: "POST"});
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of post


	/**
	 * [POSTPROGRESS]
	 *
	 * @param {string} requestUrl
	 * @param {any} body
	 * @param {any} options
	 */
	public postProgress(requestUrl: string, body: any, options?): Observable<HttpEvent<any>> {
		let url = GlobalService.databaseApiUrl + requestUrl;

		options = this.checkOptions(options);
		Object.assign(options, {reportProgress: true, observe: 'events', responseType: 'text'});

		return this.http.post<any>(url, body, options)
	}	// End-of post

	/**
	 * [PUT]
	 *
	 * @param {string} requestUrl
	 * @param {any} body
	 * @param {any} options
	 */
	public put(requestUrl: string, body: any, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl + requestUrl;
			let httpOptions = {
				headers: new HttpHeaders({'Content-Type': 'application/json',}),
				responseType: 'text' as 'json'
			};
			options = this.checkOptions(options);
			this.http.put<any>(url, body, {
				...options,
				responseType: "text" // JSON.parse-ing issue caused by response of "OK" https://github.com/angular/angular/issues/18396
			}).subscribe(rtn => {
				postRecorderMessage({url, body, result: rtn, type: "PUT"});
				resolve(rtn);
			}, error => {
				let hOut = this.handleError(error);
				postRecorderMessage({url, body, error, type: "PUT"});
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of put

	/**
	 * [DELETE]
	 *
	 * @param {string} requestUrl
	 * @param {any} options
	 */
	public delete(requestUrl: string, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {

			let url = GlobalService.databaseApiUrl + requestUrl;

			options = this.checkOptions(options);

			this.http.delete<any>(url, options).subscribe(rtn => {
				postRecorderMessage({url, result: rtn, type: "DELETE"});

				resolve(rtn);
			}, error => {
				postRecorderMessage({url, error, type: "DELETE"});

				let hOut = this.handleError(error);
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of delete

	/**
	 * [REQUEST] further control over your request
	 *
	 * @param {string} requestUrl
	 * @param {any} options {requestType, body, progressCallback, headers}
	 */
	public request(requestUrl: string, options?): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl + requestUrl;

			options = this.checkOptions(options);
			if (!options.requestType) {
				reject("Request type not specified");
			}

			let callback = options.progressCallback;
			const init = {
				reportProgress: !!callback,
				headers: options.headers,
				observe: 'events'
			}

// Local Testing
// url = 'http://localhost:4500' + requestUrl;

			let request;
			if (options.file) {
				let formData: FormData = new FormData();
				formData.append('file', options.file);

				if (options.body) {
					// Object.entries(options.body)
					// 	.forEach( ([ k, v ]) => formData.append(k, v as Blob) )
					let keys = Object.keys(options.body);
					keys.forEach(k => {
						formData.append(k, options.body[k]);
					});
				}

				request = new HttpRequest(options.requestType, url, formData, init);
			} else if (options.body || options.form) {
				request = new HttpRequest(options.requestType, url, options.body || options.form, init);
			} else {
				request = new HttpRequest(options.requestType, url, init);
			}

			this.http.request<any>(request).subscribe((event: HttpEvent<any>) => {
				switch (event.type) {
					case HttpEventType.Sent:
						console.log("Request has been made!");
						break;
					case HttpEventType.UploadProgress:
						if (callback) {
							callback(event.loaded / event.total);
						}
						break;
					case HttpEventType.DownloadProgress:
						if (callback) {
							callback(event.loaded / event.total);
						}
						break;
					case HttpEventType.ResponseHeader:
						resolve(event.status);
						break;
					case HttpEventType.Response:
						resolve(event.status);
						break;
					default:
						console.warn("Unrecognized: ", event);
						break;
				}
			}, error => {
				let hOut = this.handleError(error);
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});

// 			.pipe(
// 				catchError(this.handleError)
// // catchError((err, caught) => {
// // console.log('Request Error: ', err, caught);
// // 	return of(err);
// // }),
// // retry(3)
// 				)

		});
	}	// End-of request

	public async chunk(requestUrl, file, progressCallback?): Promise<any> {
		const chunk_size = (10 * 1024 * 1024); // Set to 10Mb
		const block_count = Math.ceil(file.size / chunk_size);
		const blob_slice = File.prototype.slice;
		const promise_array = [];
		const hash = await this.hashFile(file, chunk_size);
		const chunks_progress = new Array(chunk_size);

		for (let i = 0; i < block_count; i++) {
			const start = i * chunk_size;
			const end = Math.min(file.size, start + chunk_size);

			const form = new FormData();
			form.append('file', blob_slice.call(file, start, end));
			form.append('total', block_count.toString());
			form.append('index', i.toString());
			form.append('size', file.size);
			form.append('hash', hash);

			const options = {
				requestType: 'POST',
				form: form,
				progressCallback: (e) => {
					// console.log(block_count, i, e, file);
					// Calculate total upload progress
					chunks_progress[i] = e;
					let total = chunks_progress.reduce((a, b) => {
						return a + b
					}, 0) / block_count;

					progressCallback(total);
				}
			};

			promise_array.push(this.request(requestUrl, options));
		}

		return new Promise((resolve, reject) => {
			Promise.all(promise_array).then((val) => {
				const data = {
					size: file.size,
					name: file.name,
					total: block_count,
					hash
				};

				// Done, send merge request
				this.request('/data/chunk_merge', {body: data, requestType: 'POST'}).then(res => {
					resolve(res);
				});

			}).catch(error => {
				let hOut = this.handleError(error);
				hOut.ignore ? resolve(hOut.status) : reject(hOut.status);
			});
		});
	}	// End-of chunk

	private hashFile(file, chunk_size): Promise<any> {
		return new Promise((resolve, reject) => {
			const blob_slice = File.prototype.slice;

			const chunks = Math.ceil(file.size / chunk_size);
			let current_chunk = 0;

			const spark = new SparkMD5.ArrayBuffer();
			const file_reader = new FileReader();

			let load_next = () => {
				const start = current_chunk * chunk_size;
				const end = start + chunk_size >= file.size ? file.size : start + chunk_size;
				file_reader.readAsArrayBuffer(blob_slice.call(file, start, end));
			}	// End-of load_next

			file_reader.onload = (e) => {
				spark.append(e.target.result);
				current_chunk += 1;

				if (current_chunk < chunks) {
					load_next();
				} else {
					const result = spark.end();
					const sparkMd5 = new SparkMD5();
					sparkMd5.append(result);
					sparkMd5.append(file.name);
					const hex_hash = sparkMd5.end();
					resolve(hex_hash);
				}
			};

			file_reader.onerror = () => {
				console.warn("File reading failed");
			};

			load_next();
		});
	}	// End-of hashFIle
}   // End-of BannerService
