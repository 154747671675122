
/* Imports */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, NavigationEnd, NavigationStart, RoutesRecognized } from '@angular/router';

@Injectable()
export class NavigationService {
	private routes: string[] = [];

	constructor(
		private _router: Router
	) {

		_router.events.subscribe((event) => {
			if(event instanceof NavigationStart){
				this.routes.push(event.url);
			}
		});

	}	// End-of constructor

	public goBack(): void {

		let currentPath = this.routes.pop();
		let path = this.routes.pop();
		if(path){
			this._router.navigateByUrl(path);
		} else {
			let currentPathSegments = currentPath.split('/');
			if(currentPathSegments.length > 1){
				currentPathSegments.pop();
				this._router.navigateByUrl(currentPathSegments.join('/'));
			} else {
				this._router.navigateByUrl("/");
			}
		}

	}	// End-of goBack

	public goTo(url, query): void {

		this._router.navigate([url], {queryParams: query});

	}	// End-of goTo

}	// End-of DebugService
