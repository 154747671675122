/* Imports */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";

/* Services */
import { GlobalService } from "../../shared/services/global.service";
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";
import { environment } from "@/environments/environment";

/* RXJs */
/* Leaflet */
import L from "leaflet";

/* Geospatial analysis */
/* Classes */
import { LAYER_TYPES_2D, ViewerLayer, ViewerLayerGroup, VIEWERSTATE } from "./";
import { OrthoMeasure, AnnotationType } from "./classes/measure";
import { Measurement, MeasurementService } from "@shared/services";
import { Image, Model, Project } from "@shared/models";
import calc from "./classes/calc";
import { connect, store } from "@app/store";
import { getVisibleDetails } from "@features/ViewerLayers";
import { Annotations, Mark2D } from "@app/pages/viewer/classes/annotate";

/* TILE LAYER FALLBACK */
L.TileLayer.Fallback = L.TileLayer.extend( {
	options: {
		minNativeZoom: 0
	},
	initialize: function( urlTemplate, options ) {
		L.TileLayer.prototype.initialize.call( this, urlTemplate, options );
	},
	createTile: function( coords, done ) {
		var tile = L.TileLayer.prototype.createTile.call( this, coords, done );
		tile._originalCoords = coords;
		tile._originalSrc = tile.src;
		return tile;
	},
	_createCurrentCoords: function( originalCoords ) {
		var currentCoords = this._wrapCoords( originalCoords );
		currentCoords.fallback = true;
		return currentCoords;
	},
	_originalTileOnError: L.TileLayer.prototype._tileOnError,
	_tileOnError: function( done, tile, e ) {
		var layer = this, // `this` is bound to the Tile Layer in L.TileLayer.prototype.createTile.
			originalCoords = tile._originalCoords,
			currentCoords = tile._currentCoords = tile._currentCoords || layer._createCurrentCoords( originalCoords ),
			fallbackZoom = tile._fallbackZoom = tile._fallbackZoom === undefined ? originalCoords.z - 1 : tile._fallbackZoom - 1,
			scale = tile._fallbackScale = ( tile._fallbackScale || 1 ) * 2,
			tileSize = layer.getTileSize(),
			style = tile.style,
			newUrl, top, left;

		// If no lower zoom tiles are available, fallback to errorTile.
		if ( fallbackZoom < layer.options.minNativeZoom ) {
			return this._originalTileOnError( done, tile, e );
		}

		// Modify tilePoint for replacement img.
		currentCoords.z = fallbackZoom;
		currentCoords.x = Math.floor( currentCoords.x / 2 );
		currentCoords.y = Math.floor( currentCoords.y / 2 );

		// Generate new src path.
		newUrl = layer.getTileUrl( currentCoords );

		// Zoom replacement img.
		style.width = ( tileSize.x * scale ) + "px";
		style.height = ( tileSize.y * scale ) + "px";

		// Compute margins to adjust position.
		top = ( originalCoords.y - currentCoords.y * scale ) * tileSize.y;
		style.marginTop = ( -top ) + "px";
		left = ( originalCoords.x - currentCoords.x * scale ) * tileSize.x;
		style.marginLeft = ( -left ) + "px";

		// Crop (clip) image.
		// `clip` is deprecated, but browsers support for `clip-path: inset()` is far behind.
		// http://caniuse.com/#feat=css-clip-path
		style.clip = "rect(" + top + "px " + ( left + tileSize.x ) + "px " + ( top + tileSize.y ) + "px " + left + "px)";

		layer.fire( "tilefallback", {
			tile: tile,
			url: tile._originalSrc,
			urlMissing: tile.src,
			urlFallback: newUrl
		} );

		tile.src = newUrl;
	},
	getTileUrl: function( coords ) {
		var z = coords.z = coords.fallback ? coords.z : this._getZoomForUrl();

		var data = {
			r: L.Browser.retina ? "@2x" : "",
			s: this._getSubdomain( coords ),
			x: coords.x,
			y: coords.y,
			z: z
		};
		if ( this._map && !this._map.options.crs.infinite ) {
			var invertedY = this._globalTileRange.max.y - coords.y;
			if ( this.options.tms ) {
				data[ "y" ] = invertedY;
			}
			data[ "-y" ] = invertedY;
		}
		return L.Util.template( this._url, L.extend( data, this.options ) );
	}
} );

// Supply with a factory for consistency with Leaflet.
L.tileLayer.fallback = function( urlTemplate, options ) {
	return new L.TileLayer.Fallback( urlTemplate, options );
};

const connection = connect( {
		mapStateToProps: ( state, cmp ) => {
			return {
				_layerVisibility: getVisibleDetails( state )
			};
		}
	}
);

export interface OrthoTile extends ViewerLayer {
	maxZoom: number,
	minZoom: number,
	boundries: any,
	mapLayer: L.TileLayer,
	opacity: number,
}

@Component( {
	selector: "app-ortho-viewer",
	template: "<div #renderTarget class=\"render_target\" data-testid=\"leaflet_renderTarget\"></div>",
	styleUrls: [ "./viewer.component.scss" ]
} )
export class OrthoViewerComponent implements OnInit, AfterViewInit {

	@ViewChild( "renderTarget" )
	public renderTarget: any;

	@Output( "ready" ) ready: EventEmitter<any> = new EventEmitter<any>();

	@Output( "measureStop" ) measureStop: EventEmitter<any> = new EventEmitter<any>();
	@Input() userId: number;
	@Input() project: Project;
	@Input() model: Model;
	/* External Expressions */
	public measureInfo: any = null;
	public showMeasureInfo = {
		showMeasures: true,
		yourMeasurementCount: 0,
		teamMeasurementCount: 0,
		showYourMeasurements: true,
		showTeamMeasurements: true,
		showDistances: false,
		showArea: false,
		showHeight: false,
		showAngles: false
	};
	/* Layers */
	public layerGroups: Array<ViewerLayerGroup> = [];
	public layers: Map<string, OrthoTile>;
	public basemapOptions = [ {
		id: "streets-v11",
		title: "Street"
	}, {
		id: "satellite-v9",
		title: "Satellite"
	}, {
		id: "outdoors-v11",
		title: "Terrain"
	}, {
		id: "dark-v10",
		title: "Dark"
	} ];
	public basemapSelection = this.basemapOptions[ 0 ];
	public basemapOpacity = 60;
	/* Debugging Visualizations */
	public debuggingViz = {
		showControls: false,
		showImagePositions: false,
		showFlightPath: false
	};
	private viewerSetup: boolean = false;
	private _ms: Measurement[];
	private showMeasure: boolean = true; // TODO @remove temp-team-measurements
	/* Variables */
	private _map: any;
	private _selectedMeasurement: any;
	private _mapState: VIEWERSTATE = VIEWERSTATE.NONE;
	/* Mouse */
	private _lastMouseCoords: any;
	private _lastViewedLayerId: string;
	private _projectImages: Array<Image> = new Array<Image>();
	// TODO @remove viewer-layers
	private _layers: Array<any> = [];
	/* Basemap */
	private _basemapLayer;
	private _debuggingGroups: any = {};

	constructor(
		private _measurementService: MeasurementService,
		private _cdr: ChangeDetectorRef
	) {
		connection( this, store );
	}	// End-of constructor

	private _annotationHasChanges: boolean = false;

	get annotationHasChanges() {
		return this._annotationHasChanges;
	}

	set annotationHasChanges( val: boolean ) {
		this._annotationHasChanges = val;
	}

	set _layerVisibility( visibleDetails ) {
		visibleDetails.forEach( ( [ id, visibility ] ) => {
			let layer = this.layers.get( id );
			if ( layer ) {
				layer.visible = visibility;
				this.updateLayerVisibility( layer );
			}
		} );
	}

	private _measurements: Array<any> = new Array<any>();

	get measurements(): Measurement[] {
		return this._ms;
	}

	@Input() set measurements( ms: Measurement[] ) {
		this._ms = ms;
		if ( this._ms.length && this.viewerSetup ) this.setupMeasurements();
	}

	public get measurementEditorState(): VIEWERSTATE {
		if ( this._selectedMeasurement != null ) {
			return this._selectedMeasurement.measuring ?
				VIEWERSTATE.MEASURE_ADDING : VIEWERSTATE.MEASURE_EDITING;
		}
		return VIEWERSTATE.NONE;
	}

	public get activeState(): VIEWERSTATE {
		return this._mapState;
	}

	public clearMeasureInfo() {
		this.measureInfo = null;
	}

	ngOnInit() {

	}	// End-of ngOnInit

	ngAfterViewInit() {
		if ( !this._map ) {
			this.setupMap();
		}
	}	// End-of ngAfterViewInit

	setupMap(): void {
		let target = this.renderTarget.nativeElement;

		this._map = L.map( target, {
			// center: [vert, hor],
			center: [ 37.0902, -95.7129 ],
			wheelPxPerZoomLevel: 300,
			maxZoom: 25,
			minZoom: 12,
			detectRetina: true,
			attributionControl: false,
			zoomControl: false
		} );

		// Scale
		L.control.scale().addTo( this._map );

		this.loadSavedPreferences();

		// Base Map
		this.updateBasemap();


		// Leaflet.control.attribution({position: 'bottomleft'}).addTo(mymap);
		this.ready.emit( this );

		if ( this.measurements.length ) this.setupMeasurements();

		if ( flagLayer.isEnabled( availableFeatureFlags.devTesting ) ) {
			( window as any ).orthoViewer = this;
		}

		this.viewerSetup = true;
	}	// End-of setupMap

	setupMeasurements(): void {
		// measurements are what has been gotten from the backend and passed down by the viewer component
		this._ms.forEach( ( { id, model, model_id, name, type, details, created_by_id, data: { points } } ) => {
			let existingMeasurement = this._measurements.find( measurement => measurement.measure.id == id );
			if ( !existingMeasurement ) {
				let measureOptions = {
					showDistances: true,
					showAngles: false,
					closed: true,
					id,
					model_id,
					project: this.project,
					model,
					name,
					type,
					details,
					created_by_id,
					points,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				};

				let measure;
				if ( type === AnnotationType.MARKER ) {
					measure = new Mark2D( this._map, measureOptions );
				} else {
					measure = new OrthoMeasure( this._map, measureOptions );
				}

				const sub_change = measure.change.subscribe( d => {
					this.measureInfo = d.info;
					this.selectMeasurement( d.ref );
				} );

				this._measurements.push( {
					measure,
					sub_change
				} );
			}
		} );
		this.updateMeasurementCounts();
		this.stopMeasure();
	}

	@HostListener( "document:keydown", [ "$event" ] ) onKeydownHandler( event: KeyboardEvent ) {
		const ESCAPE_KEYCODE = 27;
		if ( event.keyCode === ESCAPE_KEYCODE ) {
			this.stopMeasure();
		}
	}	// HostListner on keydown

	// TODO: @remove viewer-layers
	addTileLayers( ...tiles: Array<OrthoTile> ): void {
		tiles.forEach( tile => {
			this.addTileLayer( tile );
		} );
		this.recenter();
	}	// End-of addTileLayers

	setupLayers( incomingLayerGroups: Array<any> ): void {
		this.layerGroups = incomingLayerGroups;

		this.layers = new Map<string, any>();

		this.layerGroups.forEach( layerGroup => {
			layerGroup.layers?.forEach( layer => {
				switch (layer.type) {
					case "orthomosaic":
					case "dsm":
						this.addTileLayer( layer as OrthoTile );
						break;
					default:
						console.warn( "Type unknown" );
						break;
				}
			} );
		} );
	}

	showInitialLayers(): void {
		const firstOrthoLayer = [ ...this.layers ].find( ( [ k, v ] ) => v.type === "orthomosaic" );
		if ( firstOrthoLayer ) {
			this.goToLayer( firstOrthoLayer[ 0 ] );
		} else if ( this.layers.size > 0 ) {
			this.goToLayer( this.layers.values().next().value.id );
		}
	}

	addTileLayer( tile: OrthoTile ): void {
		let tile_url = GlobalService.databaseApiUrl + tile.url + "/{z}/{x}/{y}.png";

		let mybounds = [ [ tile.boundries.south, tile.boundries.west ], [ tile.boundries.north, tile.boundries.east ] ];

		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			let latLng = new L.LatLng( ( tile.boundries.north + tile.boundries.south ) / 2, ( tile.boundries.east + tile.boundries.west ) / 2 );
			this._map.setView( latLng, 18 );

			let mapLayer = L.tileLayer.fallback( tile_url, {
				minNativeZoom: tile.minZoom ?? 15,
				maxNativeZoom: tile.maxZoom,
				maxZoom: 25,
				noWrap: true,
				bounds: mybounds,
				attributionControl: false
			} );
			tile.mapLayer = mapLayer;
			tile.coordinate = calc( [ latLng ] ).coordinate;
			this.layers.set( tile.id, tile );
			this.toggleLayer( tile.id, tile.visible );
		} else { // TODO: @remove viewer-layers
			this._map.setView( new L.LatLng( ( tile.boundries.north + tile.boundries.south ) / 2, ( tile.boundries.east + tile.boundries.west ) / 2 ), 18 );

			let layer = L.tileLayer.fallback( tile_url, {
				minNativeZoom: tile.minZoom ?? 15,
				maxNativeZoom: tile.maxZoom,
				maxZoom: 25,
				noWrap: true,
				bounds: mybounds,
				attributionControl: false
				// errorTileUrl: "./assets/images/aa_logo.png"
			} ).addTo( this._map );

			this._layers.push( { info: tile, layer: layer } );

			if ( typeof tile.visible != "undefined" && !tile.visible ) {
				this.setVisible( tile.name, tile.visible );
			}
		}
	}	// End-of addTileLayer

	updateMeasurementCounts(): void {
		this.showMeasureInfo.yourMeasurementCount = this._measurements?.filter( m => m.measure.created_by_id === this.userId ).length;
		this.showMeasureInfo.teamMeasurementCount = this._measurements.length - this.showMeasureInfo.yourMeasurementCount;
	}

	updateMeasureVisibility(): void {
		const shouldShowYourMeasures = this.showMeasureInfo.showMeasures && this.showMeasureInfo.showYourMeasurements;
		const shouldShowTeamMeasures = this.showMeasureInfo.showMeasures && this.showMeasureInfo.showTeamMeasurements;

		// measurement tool
		if ( !this.showMeasureInfo.showMeasures ) {
			if ( this._mapState === VIEWERSTATE.MEASURE
				|| this._mapState === VIEWERSTATE.MARKER ) {
				this.stopMeasure();
			} else {
				this.measureInfo = null;
			}
		} else if ( this._selectedMeasurement ) {
			const isYours = this._selectedMeasurement.created_by_id === this.userId;
			if (
				( isYours && !shouldShowYourMeasures ) ||
				( !isYours && !shouldShowTeamMeasures )
			) {
				this.stopMeasure();
			}
		}

		// measurements
		this._measurements.forEach( ( { measure } ) => {
			// TODO @remove new-annotations
			if(flagLayer.isEnabled( availableFeatureFlags.newAnnotations)) measure.toggleAnno(shouldShowYourMeasures)
			const isYours = measure.created_by_id === this.userId;
			(
				( isYours && shouldShowYourMeasures ) ||
				( !isYours && shouldShowTeamMeasures )
			)
				? measure.show()
				: measure.hide();
		} );
	}

	setVisible( name?: string, visible?: boolean ): void {
		if ( name ) { // TODO @remove temp-team-measurements
			if ( name === "measure" ) {
				this.showMeasure = visible === undefined ? !this.showMeasure : visible; // visible || !this.showMeasure would miss when visible is deliberately false

				if ( !this.showMeasure ) {
					if ( this._mapState === VIEWERSTATE.MEASURE
						|| this._mapState === VIEWERSTATE.MARKER ) {
						this.stopMeasure();
					} else {
						this.measureInfo = null;
					}
				}

				this._measurements.forEach( ( { measure } ) => {
					this.showMeasure
						? measure.show()
						: measure.hide();
				} );
				return;
			}

			if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
				let layer = this._layers.find( t => t.info.name === name )?.layer;

				if ( layer ) {
					if ( visible === undefined ) {
						this._map.hasLayer( layer ) ? this._map.removeLayer( layer ) : this._map.addLayer( layer );
					} else {
						if ( this._map.hasLayer( layer ) && !visible ) {
							this._map.removeLayer( layer );
						} else if ( !this._map.hasLayer( layer ) && visible ) {
							this._map.addLayer( layer );
						}
					}
				}
			}
		} else if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
			this._layers.forEach( layer => {
				if ( this._map.hasLayer( layer ) ) {
					this._map.removeLayer( layer );
				} else {
					this._map.addLayer( layer );
				}
			} );
		}
	}	// End-of setVisible

	// TODO @remove temp-team-measurements
	isVisible( name: string ): boolean {
		if ( name === "measure" ) {
			return this.showMeasure;
		}

		if ( !flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) { // TODO: @remove viewer-layers
			if ( this._layers ) {
				let index = this._layers.findIndex( t => t.info.name === name );
				if ( index >= 0 ) {
					return this._map.hasLayer( this._layers[ index ].layer );
				}
			}
		}
		return false;
	}	// End-of isVisible

	toggleLayer( layerId: string, visible?: boolean ): void {
		let layer = this.layers.get( layerId );
		if ( layer ) {
			layer.visible = visible != null ? visible : !layer.visible;
			this.updateLayerVisibility( layer );
		}
	}

	updateLayerVisibility( layer ): void {
		if ( this._map.hasLayer( layer.mapLayer ) && !layer.visible ) {
			this._map.removeLayer( layer.mapLayer );
		} else if ( !this._map.hasLayer( layer.mapLayer ) && layer.visible ) {
			this._map.addLayer( layer.mapLayer );
		}
	}

	rendersLayerType( layerType: string ): boolean {
		return LAYER_TYPES_2D[ layerType ] ?? false;
	}

	selectMeasurement( m: any ): void {
		if ( this._selectedMeasurement )
			this._selectedMeasurement.deselect();

		this._selectedMeasurement = m;

		if ( this._selectedMeasurement ) {
			this._mapState = this._selectedMeasurement.type === AnnotationType.POLYLINE ? VIEWERSTATE.MEASURE : VIEWERSTATE.MARKER;
			this._measurementService.setModelId( this._selectedMeasurement.model_id );
			this._selectedMeasurement.select();
		} else {
			this._mapState = VIEWERSTATE.NONE;
		}
	}

	updateBasemap(): void {
		const mapboxToken = environment.mapbox;
		const basemapURL = `https://api.mapbox.com/styles/v1/mapbox/${ this.basemapSelection.id }/tiles/{z}/{x}/{y}?access_token={accessToken}`;
		if ( this._basemapLayer ) {
			this._basemapLayer.setUrl( basemapURL );
			this.savePrefernces();
		} else {
			this._basemapLayer = L.tileLayer( basemapURL, {
				tileSize: 512,
				maxNativeZoom: 23,
				maxZoom: 25,
				zoomControl: false,
				zoomOffset: -1,
				opacity: this.basemapOpacity / 100,
				accessToken: mapboxToken
			} );
			this._basemapLayer.addTo( this._map );
		}
	}

	changeBasemapOpacity( value ): void {
		this.basemapOpacity = value;
		this._basemapLayer.setOpacity( this.basemapOpacity / 100 );
		this.savePrefernces();
	}

	recenter( layerId?: string ): void {
		if ( flagLayer.isEnabled( availableFeatureFlags.viewerLayers ) ) {
			if ( layerId ) {
				let layer = this.layers.get( layerId );
				if ( layer && this._map.hasLayer( layer.mapLayer ) ) {
					this._lastViewedLayerId = layerId;
					this._map.fitBounds( layer.mapLayer.options.bounds );
				}
			} else {
				const lastViewedLayer = this.layers.get( this._lastViewedLayerId );
				if ( lastViewedLayer && lastViewedLayer.visible ) {
					this._map.fitBounds( lastViewedLayer.mapLayer.options.bounds );
				}
				const visibleLayers = [ ...this.layers ].filter( ( [ k, v ] ) => v.visible );
				if ( visibleLayers.length > 0 ) {
					const firstLayer = visibleLayers[ 0 ][ 1 ];
					this._map.fitBounds( firstLayer.mapLayer.options.bounds );
				}
			}
		} else { // TODO: @remove viewer-layers
			if ( this._layers.length ) {
				this._map.fitBounds( this._layers[ this._layers.length - 1 ].layer.options.bounds );
			}
		}
	}	// End-of recenter

	goToLayer( layerId ): void {
		let layer = this.layers.get( layerId );
		if ( layer ) {
			this._lastViewedLayerId = layerId;
			layer.visible = true;
			this.updateLayerVisibility( layer );
			this.recenter( layerId );
		}
	}

	// Positive zoom in, Negative zoom out
	onZoom( direction: number ): void {
		this._map.setZoom( this._map.getZoom() + ( 1 * direction ) );
	}	// End-of onZoom

	onMouseMove( e ): void {

	}	// End-of onMouseMove

	onMouseClick( e ): void {
		let lng = e.latlng.lng;
		let lat = e.latlng.lat;
	}	// End-of onMouseClick

	stopMeasure(): void {
		// Remove listeners
		this._map.off( "mousemove", this.onMouseMove, this );
		this._map.off( "click", this.onMouseClick, this );
		if ( this._selectedMeasurement ) {
			this._selectedMeasurement.stop();
		}

		this.selectMeasurement( null );
		this.measureInfo = null;
		this._mapState = VIEWERSTATE.NONE;

	}	// End-of stopMeasure

	updateAnnotation(): void {
		this.annotationHasChanges = false;
		this._selectedMeasurement.markAndUpdate();
	}

	updateMeasureName( event ): void {
		if ( this._selectedMeasurement ) {
			this.annotationHasChanges = true;
			this._selectedMeasurement.setName( event.target.value );
		}
	}

	updateMeasureDetails( event ): void {
		if ( this._selectedMeasurement ) {
			this.annotationHasChanges = true;
			this._selectedMeasurement.setDetails( event.target.value );
		}
	}

	startMeasure( project, isMarker = false ): void {
		if ( this._mapState != VIEWERSTATE.NONE ) {
			this.stopMeasure();
		} else {
			// Set visibility
			if ( !flagLayer.isEnabled( availableFeatureFlags.teamMeasurements ) ) {
				this.setVisible( "measure", true );
			} else {
				this.showMeasureInfo.showYourMeasurements = true;
				this.showMeasureInfo.showMeasures = true;
				this.updateMeasureVisibility();
				this._cdr.detectChanges();
			}

			// Measure
			let newMeasurement;

			// Measure
			if ( isMarker ) {
				newMeasurement = new Mark2D( this._map, {
					name: "New Marker",
					type: AnnotationType.MARKER,
					details: "",
					project,
					showDistances: false,
					showEdges: false,
					closed: false,
					model: this.model,
					model_id: this.model.id,
					created_by_id: this.userId,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				} );
			} else {
				newMeasurement = new OrthoMeasure( this._map, {
					showDistances: true,
					showAngles: false,
					closed: true,
					name: "New Measurement",
					type: AnnotationType.POLYLINE,
					details: "",
					project,
					model: this.model,
					model_id: this.model.id,
					created_by_id: this.userId,
					mapwareViewer: this,
					measurementService: ( flagLayer.isEnabled( availableFeatureFlags.newAnnotations ) ?
						Annotations :
						this._measurementService )
				} );
			}
			newMeasurement.start();

			this.selectMeasurement( newMeasurement );

			// Create subscription from measurementChanges
			let sub_change = this._selectedMeasurement.change.subscribe( d => {
				// info and ref are undefined on the initial call
				this.measureInfo = d.info || this.measureInfo;
				this.selectMeasurement( d.ref || this._selectedMeasurement );
			} );

			this._measurements.push( {
				measure: this._selectedMeasurement,
				sub: sub_change
			} );

			this.updateMeasurementCounts();

			// Set State
			this._mapState = newMeasurement.type === AnnotationType.POLYLINE ? VIEWERSTATE.MEASURE : VIEWERSTATE.MARKER;
		}
	}	// End-of measureAll

	startAddingToMeasure(): void {
		this._selectedMeasurement?.start();
	}

	startEditingMeasure(): void {
		this._selectedMeasurement?.stop();
	}


	measureRemove(): void {
		// Removes one measurement
		if ( this._selectedMeasurement ) {
			// Removes selected
			this._selectedMeasurement.clear();
			if ( this._measurements ) {
				let index = this._measurements.findIndex( x => {
					if ( x.measure ) {
						return x.measure.id === this._selectedMeasurement.id;
					} else {
						return false;
					}
				} );

				if ( index >= 0 ) {
					this._measurements.splice( index, 1 );
					this.selectMeasurement( null );
					this.measureInfo = null;
				}
			}
			this.updateMeasurementCounts();
		}
	}	// End-of measureRemove

	public toggleFlightPathViz(): void {
		this.debuggingViz.showFlightPath = !this.debuggingViz.showFlightPath;
		this.updateDebugging();
	}

	public toggleImagePositionViz(): void {
		this.debuggingViz.showImagePositions = !this.debuggingViz.showImagePositions;
		this.updateDebugging();
	}

	setupDetailsViz(): void {
		if ( !this._projectImages.length && this.project.batches?.length )
			this._projectImages = this.project.batches.flatMap( ( batch ) => batch.images );
	}

	updateDebugging(): void {
		if ( this.debuggingViz.showControls ) {
			this._debuggingGroups.parent = this._debuggingGroups.parent ?? L.layerGroup();
			this._debuggingGroups.parent.addTo( this._map );
			this.setupDetailsViz();
		}

		this.renderImagePositions( this.debuggingViz.showControls && this.debuggingViz.showImagePositions );
		this.renderFlightPath( this.debuggingViz.showControls && this.debuggingViz.showFlightPath );

		if ( !this.debuggingViz.showControls && this._debuggingGroups.parent ) {
			this._map.removeLayer( this._debuggingGroups.parent );
			delete this._debuggingGroups.parent;
		}
	}

	renderImagePositions( visible ): void {
		if ( !visible && this._debuggingGroups.images ) {
			this._debuggingGroups.parent.removeLayer( this._debuggingGroups.images );
			delete this._debuggingGroups.images;

		} else if ( visible && !this._debuggingGroups.images ) {
			this._debuggingGroups.images = L.layerGroup();
			this._debuggingGroups.parent.addLayer( this._debuggingGroups.images );
			if ( this._projectImages.length ) {
				this._projectImages.forEach( ( image ) => {
					if ( image.latitude && image.longitude ) {
						L.circleMarker( [ image.latitude, image.longitude ],
							{ radius: 4, stroke: false, fill: true, fillOpacity: 1, color: "#ffffff" }
						).addTo( this._debuggingGroups.images );
					}
				} );

			}
		}
	}

	renderFlightPath( visible ): void {
		if ( !visible && this._debuggingGroups.flight ) {
			this._debuggingGroups.parent.removeLayer( this._debuggingGroups.flight );
			delete this._debuggingGroups.flight;

		} else if ( visible && !this._debuggingGroups.flight ) {
			this._debuggingGroups.flight = L.layerGroup();
			this._debuggingGroups.parent.addLayer( this._debuggingGroups.flight );

			if ( this._projectImages.length ) {
				// @ts-ignore
				this._projectImages.sort( ( a, b ) => new Date( a.descriptors.captured_at ) - new Date( b.descriptors.captured_at ) );

				const delayPerPoint = 20;
				let count = 0;

				let polyline = L.polyline( [], { color: "#00ff00", weight: 1 } ).addTo( this._debuggingGroups.flight );
				this._projectImages.forEach( ( image ) => {
					if ( image.latitude && image.longitude ) {
						setTimeout(
							() => {
								polyline.addLatLng( [ image.latitude, image.longitude ] );
							},
							count * delayPerPoint );
						count++;
					}
				} );

			}
		}
	}

	handleMapMouseOut( e ): void {

	}	// End-of handleMapMouseOut

	mapSettingsString(): string {
		return `map-settings-${ this.project.id }`;
	}

	loadSavedPreferences(): void {
		// check localStorage for preferences
		let savedPrefs = localStorage[ this.mapSettingsString() ];
		if ( savedPrefs ) {
			savedPrefs = JSON.parse( savedPrefs );
			if ( savedPrefs.basemapId ) {
				// match basemapId with object from basemap options list
				this.basemapSelection =
					this.basemapOptions.find( option => option.id === savedPrefs.basemapId )
					?? this.basemapSelection;
			}
			this.basemapOpacity = savedPrefs.basemapOpacity ?? this.basemapOpacity;
		}
	}

	savePrefernces(): void {
		localStorage[ this.mapSettingsString() ] = JSON.stringify( {
			basemapId: this.basemapSelection.id,
			basemapOpacity: this.basemapOpacity
		} );
	}
}	// End-of class ViewerComponent
