/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import { Image, ImageBatch } from '../models';

import crc32 from 'crc/crc32';
import _ from 'lodash';
import { makeURLParams } from "./utils.service";
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';

@Injectable()
export class ImageService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor

	/**
	 * v2 Routes
	 **/

	getBatchListV2({ project_id = null, organization_id = null }: { project_id?: number | null, organization_id?: number | null }): Promise<any> {
		const searchData = { project_id, organization_id };
		const searchParams = makeURLParams(searchData);
		const url = '/v2/batches' + searchParams;
		return this.httpService.get(url);
	}

	getBatchV2(id: number): Promise<any> {
		const url = `/v2/batch/${id}`;
		return this.httpService.get(url);
	}

	getBatchImagesV2(batch_id: number, limit?: number, offset?: number, sort?: string, dir?: "asc" | "desc"): Promise<any> {
		const searchData = { batch_id, limit, offset, sort, dir };
		const searchParams = makeURLParams(searchData);
		const url = `/v2/images` + searchParams;
		return this.httpService.get(url);
	}

	checkBatchesV2(oldBatches, newBatches): boolean {
		return _.isEqual(oldBatches.sort(), newBatches.sort());
	}

	uploadImagesToBatchV2(batchId: number, imageFile: File): Promise<any> {
		return imageFile['arrayBuffer']().then(res => {
			const crc = crc32(res).toString(16)
			const url = '/v2/batch/' + batchId + '/upload?crc=' + crc;
			const body: FormData = new FormData();
			body.set('image', imageFile, imageFile.name);
			return this.httpService.post(url, body);
		});
	}

	uploadImagesToBatchV2WithPercentage(batchId: number, file: File, resImage: Blob): Observable<HttpEvent<any>> {
		const crc = crc32(resImage).toString(16)
		const url = '/v2/batch/' + batchId + '/upload?crc=' + crc;
		const body: FormData = new FormData();
		body.set('image', file, file.name);
		return this.httpService.postProgress(url, body);
	}

	public trashBatchV2(batch: any): Promise<any> {

		const url = '/v2/batches/' + batch.id;
		return this.httpService.put(url, {trash: 1});

	}

	public recoverBatchV2(batch: any): Promise<any> {

		const url = '/v2/batches/' + batch.id;
		return this.httpService.put(url, {trash: 0});

	}

	public removeBatchV2(batch: any): Promise<any> {

		const url = '/v2/batches/' + batch.id;
		return this.httpService.delete(url);

	}

	/**
	 * v1 Routes
	 **/

	/**
	 * [GETBATCHLIST]
	 *
	 */
	public getBatchList(): Promise<any> {

		let url = '/batches';
		return this.httpService.get(url);

	}	// End-of getList

	/**
	 * [CREATEBATCH]
	 *
	 * @param {string} name			name of batch
	 * @param {number} ordId			ID of organization to create batch under
	 */
	public createBatch(name: string, orgId: number, projectId: number = 0): Promise<any> {

		let url = '/batches';
		return this.httpService.post(url, { name: name, organization_id: orgId, project_id: projectId });

	}	// End-of createBatch

	/**
	 * [CREATE MULTISPECTRAL BATCH]
	 *
	 * @param {string} name			name of batch
	 * @param {number} ordId		ID of organization to create batch under
	 * @param {number} projectId	ID of project o create batch under
	 * @param {string} sensor		name of the sensor to creat the batch with
	 */
	public createMultispectralBatch(name: string, orgId: number, projectId: number = 0, sensor: string): Promise<any> {

		let url = '/v2/batches';
		return this.httpService.post(url, {
			name: name,
			organization_id: orgId,
			project_id: projectId,
			sensor: sensor
		});
	}

	/**
	 * [RENAMEBATCH]
	 *
	 * @param {number} batchId		batch ID to add image to
	 * @param {any} imageGuid		GUID of image to add
	 */
	public renameBatch(batchId: number, name: string): Promise<any> {

		let url = '/batches/' + batchId;
		return this.httpService.put(url, { name })

	}	// End-of renameBatch

	/**
	 * [ADDTOBATCH]
	 *
	 * @param {number} batchID		batch ID to add image to
	 * @param {any} imageGuid		GUID of image to add
	 */
	public addToBatch(batchId: number, imageGuid: any): Promise<any> {

		let url = '/batches/' + batchId + '/images';
		return this.httpService.post(url, { image_guid: imageGuid })

	}	// End-of addToBatch

	/**
	 * [REMOVEFROMBATCH]
	 *
	 * @param {Image} image		Image to remove
	 */
	public removeFromBatch(img: Image | number, batchId?: number): Promise<any> {

		let imageId = (typeof img === 'object') ? img["id"] : img;
		batchId = (batchId == undefined) ? img["batch_id"] : batchId;

		let url = '/batches/' + batchId + '/images/' + imageId;
		return this.httpService.delete(url);

	}	// End-of removeFromBatch

	/**
	 * [REMOVEBATCH]
	 *
	 * @param {number} batchId		Batch to remove
	 */
	public removeBatch(batch: any): Promise<any> {

		const url = '/batches/' + batch.id;
		return this.httpService.delete(url, batch);

	}	// End-of removeBatch

	/**
	 * [UPLOADIMAGESTOBATCH]
	 *
	 * @param {file} imageFile		Image file to upload
	 */
	public uploadImagesToBatch(batchId: number, imageFile: File): Promise<any> {
		return imageFile['arrayBuffer']().then(res => {
			const crc = crc32(res).toString(16)
			const url = '/batches/' + batchId + '/images/upload?crc=' + crc;
			const body: FormData = new FormData();
			body.set('image', imageFile, imageFile.name);

			return this.httpService.post(url, body);
		});

	}	// End-of upload

	/**
	 * [MOVEIMAGE]
	 *
	 * @param {number} batchId		Batch ID
	 * @param {Image} img			Image
	 */
	public moveImageToBatch(fromBatchId: number, toBatchId: number, image: Image): Promise<any> {

		let url = `/images/${image.guid}/move`;
		return this.httpService.put(url, { batch_id: toBatchId });

	}	// End-of moveImage

	/**
	 * [GETBYID]
	 *
	 * @param {number} batchId		ID of batch
	 */
	public getBatchById(batchId: number): Promise<any> {

		let url = '/batches/' + batchId;
		return this.httpService.get(url);

	}	// End-of getById

	/**
	 * [UPLOAD]
	 *
	 * @param {file} imageFile		Image file to uploade
	 */
	public upload(imageFile: File): Promise<any> {

		let url = '/images';
		let body: FormData = new FormData();
		body.set('image', imageFile, imageFile.name);

		return this.httpService.post(url, body);

	}	// End-of upload

	/**
	 * [DOWNLOAD]
	 *
	 * @param {string} guid			GUID of image
	 * @param {number} size			Image resolution
	 */
	public download(guid: string, size: IMAGESIZES): Promise<any> {

		if (size === IMAGESIZES.large) {
			return this.info(guid).then(res => {
				const extension = res.extension.startsWith('.') ? res.extension : '.' + res.extension;
				const originalUrl = res.large_url.replace(/\.[^.]*$/, extension);
				return this.downloadHelper(originalUrl);
			});
		} else {
			const url = '/images/' + guid + '/' + size + '.png';
			return this.downloadHelper(url);
		}

	}	// End-of download

	private downloadHelper(url: string): Promise<any> {
		return this.httpService.get(url, { responseType: 'blob' });
	}	// End-of downloadHelper


	/**
	 * [INFO]
	 *
	 * @param {string} guid			GUID of image
	 */
	public info(guid: string): Promise<any> {

		let url = '/images/' + guid + '/info';
		return this.httpService.get(url);

	}	// End-of info

	/**
	 * [UPDATE]
	 *
	 * @param {Image} img		Image data
	 */
	public update(img: Image): Promise<any> {

		let url = '/images/' + img.guid;
		return this.httpService.put(url, img);

	}	// End-of update

	/**
	 * [TRASH]
	 *
	 * @param {Image} img		Image data
	 */
	public trash(img: Image): Promise<any> {

		img.trash = 1;
		return this.update(img);

	}	// End-of trash

	/**
	 * [RECOVER]
	 *
	 * @param {Image} img		Image data
	 */
	public recover(img: Image): Promise<any> {

		img.trash = 0;
		return this.update(img);

	}	// End-of trash


	public checkBatches = async (imageList, batches): Promise<ImageBatch[]> => {
		// If there are no existing batches
		const missingBatchIds = _.uniq(imageList.filter((image) =>
			(!batches.length || batches.every(batch => batch.id !== image.batchId))
		).map(image => image.batchId));

		return missingBatchIds.length
			? Promise.all(
				missingBatchIds.map((batchId: number) => {
					return this.getBatchById(batchId);
				})
			).then((rtnBatches) => batches.concat(rtnBatches))
			: batches;
	}

	/*** V2 Routes ***/

	public getBatchesByProjectId(project_id: string | number): Promise<any> {
		let url = `/v2/batches?project_id=${project_id}`;
		return this.httpService.get(url);
	}

	public getDeepBatchById(batchId: number): Promise<any> {
		let url = '/v2/batch/' + batchId;
		return this.httpService.get(url);
	}

	public checkDeepBatchesV2 = async (batches, imageList): Promise<ImageBatch[]> => {
		// If there are no existing batches
		let missingBatchIds = [];
		if (imageList.length) {
			missingBatchIds = _.uniq(imageList.reduce((acc, image) => {
				if (!batches.length || batches.every(batch => batch.id !== image.batchId)) {
					acc.push(image.batchId)
				}
				return acc;
			}, []));
		} else {
			missingBatchIds = batches.map(batch => batch.id);
		}

		return missingBatchIds.length
			? Promise.all(
				missingBatchIds.map((batchId: number) => {
					return this.getDeepBatchById(batchId);
				})
			).then((rtnBatches) => {
				return _.values(_.merge(_.keyBy(batches, 'id'), _.keyBy(rtnBatches, 'id')));;
			})
			: batches;
	}

	async getMultispectralSensors(): Promise<string[]> {
		let url = '/v2/sensors/';
		return this.httpService.get(url);
	}


}	// End-of class ImageService

/* Types of image sizes allowed */
export enum IMAGESIZES {
	small = 'small',
	medium = 'medium',
	large = 'large'
}


export interface ImageInfo {
	id: number;
	name: string;
	size: number;
	batch_id: number;
	guid: string;
	extension: string;
	content_type: string;
	created_by_id: number;
	storage_url: string;
	small_url: string;
	medium_url: string;
	large_url: string;
	latitude?: number;
	longitude?: number;
	altitude?: number;
	rotation: number;
	x_res: number;
	y_res: number;
	pixels: number;
	descriptors: Descriptors;
	trash: number;
	active: number;
	created_at: string;
	updated_at: string;
}

export interface Descriptors {
	make?: string;
	model?: string;
	iso?: number;
	exposure?: number;
	f_stop?: number;
	focal_length?: number;
	captured_at?: string;
}

export const getImagesGroupedByBatchId = (imageList: Array<any>): { [val: number]: Array<any> } =>
	_.groupBy(
		imageList.map((image) => {
			image.trash = 0;
			return image;
		}),
		(i) => i.batchId ?? i.batch_id
		// Images are returned with batch_id from the API, but somewhere are assigned `batchId` beforehand
	);

