
/* Imports */
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Alert } from '../models';

@Injectable()
export class AlertService {

	private subject: Subject<any> = new Subject<any>();
	private keepAfterNavigationChange: boolean = false;
	private showAlertInMenu: BehaviorSubject<boolean>;

	constructor(
		private router: Router,
	) {

		this.showAlertInMenu = new BehaviorSubject<boolean>(false);

		router.events.subscribe((event: any) => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterNavigationChange) {
					this.keepAfterNavigationChange = false;
				} else {
					this.subject.next();
				}
			}
		});
		this.notify.bind(this)

	}	// End-of constructor

	setAlertInMenu(val: boolean): void {
		this.showAlertInMenu.next(val);
	}
	getAlertInMenu(): Observable<any> {
		return this.showAlertInMenu.asObservable();
	}

	success(inAlert: Alert): void {

		this.keepAfterNavigationChange = inAlert.keep;
		inAlert.type = 'success';
		inAlert.icon = inAlert.icon ? inAlert.icon : 'done';
		this.subject.next(inAlert);

	} // End-of success

	warning(inAlert: Alert): void {

		this.keepAfterNavigationChange = inAlert.keep;
		inAlert.type = 'warning';
		inAlert.icon = inAlert.icon ? inAlert.icon : 'warning';
		this.subject.next(inAlert);

	}	// End-of warning

	error(inAlert: Alert): void {

		this.keepAfterNavigationChange = inAlert.keep;
		inAlert.type = 'error';
		inAlert.icon = inAlert.icon ? inAlert.icon : 'close';
		this.subject.next(inAlert);

	}	// End-of error

	notification(inAlert: Alert): void {

		this.keepAfterNavigationChange = inAlert.keep;
		inAlert.type = 'notification';
		this.subject.next(inAlert);

	}  // End-of notification

	/**
	 * Most of our alerts are `this._alertService.success(new Alert(someMessage));`.
	 * This is a shorthand. If you need to customize your Alert. Use `new Alert` directly.
	 */
	notify(message: string, type: 'error' |  'warning' | 'success' | 'notification' = 'notification') {
		const alert = new Alert(message);

		const alertFunc = { // use an object rather than this[type] in case we need special conditions or names change down the road.
			error: this.error.bind(this),
			warning: this.warning.bind(this),
			success: this.success.bind(this),
			notification: this.notification.bind(this)
		};

		alertFunc[type](alert);
	}

	trashed(name: string) {
		const alert = new Alert(name +' was moved to the trash.');
		this.success(alert);
	}

	message(inAlert: Alert): void {

		this.keepAfterNavigationChange = inAlert.keep;
		inAlert.type = 'message';
		this.subject.next(inAlert);

	}  // End-of notification

	getMessage(): Observable<any> {

		return this.subject.asObservable();

	}	// End-of getMessage

	close(): void {

		this.subject.next(null);

	} // End-of close

} // End-of AlertService
