
/* Imports */
import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { mapwareFlyLink } from "@shared/constants";

@Component({
	selector: 'app-mapware-fly-dialog',
	templateUrl: './mapware-fly.dialog.html',
	styleUrls: ['./mapware-fly.dialog.scss']
})
export class MapwareFlyDialog {

	public title: string = 'Confirmation';
	public text: string = 'Are you sure?';
	public buttonText: string = 'Confirm';
	public cancelText: string = 'Cancel';
	public showCancel: boolean = true;

	constructor (
		public _dialogRef: MatDialogRef<MapwareFlyDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	openMapwareFly(): void {
		window.open(mapwareFlyLink, '_blank')
	}

	close(): void {
		this._dialogRef.close(false);
	}

}	// End-of class TestComponent
