import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PermissionService } from "@shared/services";
import { Router } from "@angular/router";

export interface DialogData {
	project: number;
}

@Component({
	selector: "app-add-layer",
	templateUrl: "./add-layer.component.html",
	styleUrls: ["./add-layer.component.scss"],
})
export class AddLayerDialog implements OnInit {
	dialogDisplayState;
	userHasPermission = false;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private _permissionService: PermissionService,
		private _router: Router,
		public dialogRef: MatDialogRef<AddLayerDialog>
	) {
		this._permissionService
			.checkHasPermission(data.project, "process")
			.then((rtn) => {
				this.userHasPermission = rtn;
			});
	}

	ngOnInit(): void {}
}
