
<div id="main" class="accordion-container">
	<ng-container *ngFor="let section of sections; let i = index;">
		<mat-accordion
				[id]="'section-container-' + i"
				class="section-container"
				[ngClass]="{'no-pad': !section.name, 'open-section': i === openSection}">
			<app-section
				[type]="type"
				[section]="section"
				[showSelect]="showSelect"
				[showOptions]="showOptions"
				[sectionOpen]="i === openSection"
				[updateSectionFunction]="updateSectionFunction"
				[menuContent]="menuContent"
				[headerContent]="headerContent"
				[isUsingService]="isUsingService"
				(sectionEvent)="handleSectionEvent($event, i);"
				(sectionOpenToggle)="toggleOpenSections($event, i);">
				<ng-container *ngIf="headerTemplate">
					<ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: section }"></ng-container>
				</ng-container>
				<!-- Render only if the section is selected -->
				<div id="virtual-scroller-container">
					<virtual-scroller #scroll
						[items]="section.children"
						class="section-list"
						[ngClass]="{'grid-class': showGrid, 'section-list-disabled': !section.name}">
						<div class="no-children" *ngIf="!section.children?.length">No {{type}}s.</div>
						<ng-container *ngFor="let child of scroll.viewPortItems; let ind = index;">
							<ng-container *ngTemplateOutlet="t_content; context: { $implicit: child, state: {index: ind} }"></ng-container>
						</ng-container>
					</virtual-scroller>
				</div>
			</app-section>
			<!-- TODO: Reimplement functions of Sections -->
		</mat-accordion>
	</ng-container>
</div>

<ng-template #t_content let-child let-ind="index">
	<div class="drag-content-container"
		 [ngClass]="{'drag-content-container-grid': showGrid}"
		 [ngStyle]="setCellWidthStyle(showGrid)">
		<div class="section-title">
			<ng-container *ngIf="itemTemplate">
				<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: child, state: {index: ind} }"></ng-container>
			</ng-container>
			<ng-content *ngIf="!itemTemplate"></ng-content>
		</div>
	</div>
</ng-template>
