
<div class="dialog-container">
	<button mat-button class="close-button" matTooltip="Close" matDialogClose>
		<mat-icon>close</mat-icon>
	</button>
	<div class="fly-title">
		<img class="fly-logo" src="assets/images/Mapware%20Fly-Icon_Color.png" alt="Mapware Fly">
		<b>Mapware </b>
		<span class="thin">Fly</span>
	</div>
	<div mat-dialog-content>
		<p class="main-text"><b>Have you tried our free Flight Planning and Capture App?</b></p>
		<p class="H7Black700Regular"><i>Visit the Google Play store listing for a full list of supported devices.</i></p>
	</div>
	<img class="fly-qr-code phone-hidden" src="assets/images/MapwareFlyQR.png" alt="Mapware Fly QR">
	<img class="fly-badge" src="assets/images/Mapware-fly-google-badge.png" alt="Mapware Fly Google Badge" (click)="openMapwareFly()">
</div>
