import { isEqual } from "lodash";

const updater = (relevantState, component) => {
	Object.entries(relevantState).forEach(([key, value]) => {
		if (!isEqual(component[key], value)) component[key] = value;
	});
};

export const connect =
	({ mapStateToProps = null, mapDispatchToProps = null }) =>
	(component, store) => {
		if (mapDispatchToProps) {
			const res =
				typeof mapDispatchToProps === "function"
					? mapDispatchToProps(store.dispatch, component)
					: mapDispatchToProps;

			Object.entries(res).reduce((cmp, [name, func]) => {
				if (typeof func === "function")
					cmp[name] = (...args) => {
						return store.dispatch(func(...args));
					};
				else throw new Error(`${func} is not a function`);
				return cmp;
			}, component);
		}
		if (mapStateToProps) {
			return store.subscribe(() => {
				const relevantState = mapStateToProps(
					store.getState(),
					component
				);
				updater(relevantState, component);
			});
		}
	};
