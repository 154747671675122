import { createApi } from "@reduxjs/toolkit/query/react";

import { Annotation } from "@shared/models/annotation.model";
import { GlobalService } from "@shared/services";

import baseQuery from "../../../store/utils/baseQuery";

export const api = createApi({
	baseQuery,
	endpoints: (build) => ({
		create: build.mutation({
			invalidatesTags: ["Annotation"],
			query: (annotation: Annotation) => ({
				body: JSON.stringify(annotation),
				method: "POST",
				url: GlobalService.databaseApiUrl + `/annotations`,
			}),
		}),

		delete: build.mutation({
			invalidatesTags: ["Annotation"],
			query: (annotation_id: number) => ({
				method: "DELETE",
				responseHandler: (response) => response.text(),
				url:
					GlobalService.databaseApiUrl +
					`/annotations/${annotation_id}`,
			}),
		}),

		get: build.query({
			providesTags: ["Annotation"],
			query: (annotation_id: number) =>
				GlobalService.databaseApiUrl + `/annotations/${annotation_id}`,
		}),

		getList: build.query({
			providesTags: ["Annotation"],
			query: (project_id: number) => {
				return (
					GlobalService.databaseApiUrl +
					`/projects/${project_id}/annotations`
				);
			},
		}),

		update: build.mutation({
			invalidatesTags: ["Annotation"],
			query: ({ annotation_id, updates }) => ({
				body: updates,
				method: "PUT",
				url:
					GlobalService.databaseApiUrl +
					`/annotations/${annotation_id}`,
			}),
		}),
	}),
	reducerPath: "annotationApi",
	tagTypes: ["Annotation"],
});

export const {
	useGetListQuery,
	useGetQuery,
	useCreateMutation,
	useUpdateMutation,
	useDeleteMutation,
} = api;
