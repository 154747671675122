
/* Imports */
import { fail } from 'assert';
import { LoginProvider } from './login.provider';
import {isNil} from 'lodash';

declare let gapi: any;



export class GoogleProvider extends LoginProvider {

	public static readonly PROVIDER_ID: string = "GOOGLE";

	protected googleUser: any;
	protected auth2: any;
	protected ref: any;

	constructor(private clientId: string) { super(); }

	get auth(): any {

		return this.auth2;

	}	// End-of get auth

	initialize(ref?): Promise<string> {

		return new Promise((resolve, reject) => {
			this.ref = (ref ? ref : this.ref);
			this.loadScript(GoogleProvider.PROVIDER_ID,
			'https://apis.google.com/js/platform.js',
			() => {
				try {
					// TODO: look into solution for https://www.npmjs.com/package/ng-gapi
					gapi.load('auth2', () => {
						this.listen().then(resolve).catch(reject);
					});
				} catch (err){
					reject(err)
				}
			});
		});

	}	// End-of initialize

	listen(): Promise<any> {

		if (!isNil(this.clientId)) {

			return new Promise((resolve: Function, reject: Function) => {
				gapi.auth2.init({
					client_id: this.clientId,
					scope: 'profile'
				}).then((data: any) => {
					this.auth2 = data;
					// Listen for sign-in state changes.
					this.auth2.isSignedIn.listen(this.signinChanged);

					// Listen for changes to current user
					this.auth2.currentUser.listen(this.userChanged);

					if (this.auth2.isSignedIn.get()) {
						// let user: UserModel = new UserModel({});
						let profile = this.auth2.currentUser.get().getBasicProfile();
						let token = this.auth2.currentUser.get().getAuthResponse(true).id_token;

						// Get info from google about user
						let user = this.extractProfileInfo(profile);
						user.google_token = token;

						resolve(user);
					} else {
						resolve();
					}

				}, (error: any) => {
					console.error(error);
					reject(error);
				});

			});
		} else {
			console.error("No client ID");
		}

	}	// End-of listen

	button(id, option): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			if (!this.auth2) {
				this.initialize().then(() => {
					this.listen().then(() => {
						gapi.signin2.render(id, option);
						resolve();
					});
				});
			} else {
				gapi.signin2.render(id, option);
				resolve();
			}
		});

	}	// End-of button

	customButton(id, success, failure): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			if (!this.auth2) {
				this.initialize().then(() => {
					this.listen().then(() => {
						this.auth2.attachClickHandler(id, {scope: 'openid'}, success, failure);
						resolve();
					});
				}).catch( () => { reject() });
			} else {
				this.auth2.attachClickHandler(id, {scope: 'openid'}, success, failure);
				resolve();
			}
		});

	}	// End-of button

	login(): Promise<string> {

		return new Promise((resolve: Function, reject: Function) => {

			let promise = this.auth2.signIn();

			promise.then(() => {
				// let user: UserModel = new UserModel({});
				let profile = this.auth2.currentUser.get().getBasicProfile();
				// let token = this.auth2.currentUser.get().getAuthResponse(true).access_token;
				let token = this.auth2.currentUser.get().getAuthResponse(true).id_token;

				// Get info from google about user
				let user = this.extractProfileInfo(profile);
				user.google_token = token;

				resolve(token);
			}).catch((error) => {
				reject(error);
			});

		});

	}	// End-of signin

	signout(): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			this.auth2.signOut().then((error: any) => {
				if (error) {
					reject(error);
				} else {
					this.auth2.disconnect();
					this.auth2 = null;
					resolve("Successfully Logged Out");
				}
			});

		});

	}	// End-of signout

	signup(): Promise<any> {

		return new Promise((resolve: any, reject: any) => {

			let promise = this.auth2.signUp();

			promise.then(() => {

			}).catch((error) => {

			});
		});

	}	// End-of of signup

	signinChanged(d): void {

		// Listen for changes with signin
		// If logged out force out
		if (!d && this && this.ref) {
			this.ref.logout();
		}

	}	// End-of signInChanged

	userChanged(d): void {
		// console.log("Google: User changed: ", d);
		// Listen for changes to current user.
		// console.log("User: ", d.currentUser.get().getBasicProfile())

	}	// End-of userChanged

	extractProfileInfo(profile): any {

		let user = {
			"id": profile.getId(),
			"first_name": profile.getGivenName(),
			"last_name": profile.getFamilyName(),
			"email": profile.getEmail(),
		};
		return user;

	}	// End-of extract

}	// End-of GoogleLoginProvider
