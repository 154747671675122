<ng-container *ngIf="displayPage==='default'">
	<ng-container *ngTemplateOutlet="purchase_credits"></ng-container>
</ng-container>
<ng-container *ngIf="displayPage!=='default'">
	<ng-container *ngTemplateOutlet="t_showPayment"></ng-container>
</ng-container>

<ng-template #t_showPayment>
	<div class="content">
		<!-- TODO: the Storage.Modal isn't currently used anywhere and is flagged for removal.
			adding this text just in case -->
		<div class="H2Black500" style="margin-bottom: 40px;">
			We’re renovating our online billing and purchasing to make it faster and easier.
			While that’s underway, please contact our sales team at {{supportEmail}}.
		</div>
	</div>
</ng-template>

<ng-template #purchase_credits>
<span style="padding: 40px 48px; display: block; position: relative; background-color: white;">
	<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
	<div class="H2Black700" style="margin-bottom: 40px;">Purchase storage</div>

	<div style="display: flex;">
		<span class="input-title-container">
			<div class="input-title">Enter GB of storage:</div>
		</span>
		<span style="width: 200px;">
			<mat-form-field appearance="fill" style="width: 100%;">
				<input
					data-testid="storage-amount"
					matInput
					placeholder="0"
					autocomplete="off"
					[(ngModel)]="storagePurchasing"
					style="text-align: right; padding-right: 40px; width: 140px;"
					mask="separator.0"
					thousandSeparator=","
					(focusout)="checkValid()"
					type="tel"
					step="100">
				<span style="position: absolute; right: 12px;" class="H6Black500Regular">GB</span>
			</mat-form-field>
		</span>
	</div>

	<div class="increment-error" [ngStyle]="{height: showIncrementError ? '22px' : '2px'}">
		<mat-error *ngIf="showIncrementError">Storage purchases can only be made in increments of {{storeIncrement}} GB </mat-error>
	</div>

	<mat-divider color="#dee0e3"></mat-divider>

	<div class="details-container">
		<span class="details-left">Purchasing team:</span>
		<span class="details-right">{{org.name}}</span>
	</div>

	<mat-select data-testid="card-select" primary style="margin-top: 32px; margin-bottom: 24px; width: 100%" *ngIf="!isProcessing && cardList && cardList.length > 0" [(value)]="purchaseCard">
		<mat-option *ngFor="let card of cardList" class="H6Black700Regular" [value]="card">{{card.brand}} - ending in {{card.last4}}</mat-option>
	</mat-select>

	<div class="add-payment-link H7Black700Semibold link" (click)="togglePage('payment')"> Add a payment method </div>

	<mat-divider color="#dee0e3" style="display: block; margin-bottom: 24px;"></mat-divider>

	<div class="details-container">
		<span class="details-left">Current storage (GB):</span>
		<span class="details-right">{{org.storage | comma}}</span>
	</div>

	<div class="details-container">
		<span class="details-left">GB of {{getDateText()}} storage purchasing:</span>
		<span class="details-right">{{storagePurchasing ? (storagePurchasing | comma) : 0}}</span>
	</div>

	<div class="details-container">
		<span class="details-left">Cost per {{storeIncrement}} GB of storage per {{isMonthly ? 'month' : 'year'}}:</span>
		<span *ngIf="org" class="details-right">${{(getStorageCost() * storeIncrement) | comma : 2}}</span>
	</div>

	<mat-divider color="#dee0e3"></mat-divider>

	<div class="details-container">
		<span class="details-left">Plan {{getDateText()}} cost:</span>
		<span *ngIf="org" class="details-right">${{getPlanCost() | comma : 2}}</span>
	</div>

	<div class="details-container">
		<span class="details-left">Storage cost {{getDateText()}}:</span>
		<span *ngIf="org" class="details-right">${{calculatedTotal() | comma : 2}} </span>
	</div>

	<mat-divider color="#dee0e3"></mat-divider>

	<div class="details-container">
		<span class="details-left">New {{getDateText()}} cost:</span>
		<span *ngIf="org" class="details-right">${{calculatedTotal(getPlanCost()) | comma : 2}} </span>
	</div>

	<mat-divider color="#dee0e3"></mat-divider>

	<div class="details-container" style="margin-bottom: 48px;">
		<span class="details-left">Due today: <span style="font-size: 12px">(amount is pro-rated based on date)</span></span>
		<span *ngIf="org" class="details-right">${{calculatedDue() | comma : 2}} </span>
	</div>

	<div style="margin: 48px 0 16px 0;" class="H7RedRegular" *ngIf="showError">{{showError}}</div>

	<button mat-stroked-button color="primary" [disabled]="!storagePurchasing || storagePurchasing < storeIncrement" (click)="completePurchase();" style="width: 100%;">Complete purchase</button>
	<span style="width: 100%; display: flex;">
		<button mat-stroked-button color="primary" style="margin: 24px auto 0 auto;" matDialogClose>Cancel</button>
	</span>

</span>
</ng-template>
