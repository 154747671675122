/* Imports */
import { Injectable } from "@angular/core";
import isEmpty from "lodash/isEmpty";
import { UserService } from "./user.service";
import { AuthenticationService } from "./authentication.service";
import { SessionService } from "./session.service";

/* Environments */
import { environment } from "@/environments/environment";
import { availableFeatureFlags, flagLayer } from "../featureFlags";
import { User } from "@shared/models";


@Injectable( {
	providedIn: "root"
} )
export class GlobalService {

	/* Reference Values */
	static userService: UserService;
	static authenticationService: AuthenticationService;
	static sessionService: SessionService = new SessionService();
	public token: string = null;

	/* Const hack for database Url */
	static get databaseApiUrl(): string {
		// TODO: (featureFlag: apiUrl PERMANENT FLAG!!!) Follow steps in LaunchDarkly flag page
		// https://app.launchdarkly.com/mapware-commercial/test/features/api-redirect/targeting
		// const redirectUrl = flagLayer.isEnabled( availableFeatureFlags.apiUrl );
		// return redirectUrl && ( typeof redirectUrl === "string" )
		// 	? redirectUrl
		// 	: environment.apiUrl;
		return environment.apiUrl;
	}

	static getEnvironmentVariable( key: string ) {
		return environment[ key ];
	}

	static getAccountSettings(): any {
		// let user = this.auth.currentUser;
	}	// End-of getAccountSettings

	static getToken(): string {
		return this.authenticationService.token;
	}

	static getUser(): User {
		return this.authenticationService.user;
	}

	static getVersion(): any {

		let build_data = {
			"env": environment.envName
		};
		console.log( "BDT: ", build_data );

		return environment.envName;

	}	// End-of getVersion

	/*	creates a FeatureFlag context based on the environment
		we probably want to expand the context to include more fields in the future - nic l.
	*/
	static getFeatureFlagContext(): FeatureFlagContext {
		const { user } = GlobalService.authenticationService;

		// TODO: Remove with gitlab FF
		const env = {
			appName: environment.envName, // environment
			environment: environment.envName // also, environment
		};

		// https://docs.launchdarkly.com/sdk/features/user-config#javascript
		// When the user isn't available (ie. not logged in) we will use this as the standard
		const userTokens = isEmpty( user )
			? {
				key: "mapware",
				anonymous: true
			}
			: {
				email: user.email,
				key: user.id,
				userToken:
					GlobalService.authenticationService?.user.api_key ?? null,
				anonymous: false // have to ensure we overwrite
			};
		return { ...env, ...userTokens };
	}

}	// End-of class GlobalService

export interface FeatureFlagContext {
	appName?: string;
	environment?: string;
	userToken?: string;
	key?: string;
	anonymous?: boolean;
}
