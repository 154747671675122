export { default as dotProp } from "dlv";
import { isBrowser as e, isString as r, isRegex as t } from "@analytics/type-utils";
function n(e) {
  try {
    return decodeURIComponent(e.replace(/\+/g, " "));
  } catch (e) {
    return null;
  }
}
function o() {
  if (e) {
    var r = navigator,
      t = r.languages;
    return r.userLanguage || (t && t.length ? t[0] : r.language);
  }
}
function a() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {}
}
function i(r) {
  if (!e) return !1;
  var t = r || document.referrer;
  if (t) {
    var n = window.document.location.port,
      o = t.split("/")[2];
    return n && (o = o.replace(":" + n, "")), o !== window.location.hostname;
  }
  return !1;
}
function u(n) {
  if (!e) return !0;
  var o = document.getElementsByTagName("script");
  return !!Object.keys(o).filter(function (e) {
    var a = o[e].src;
    return r(n) ? -1 !== a.indexOf(n) : !!t(n) && a.match(n);
  }).length;
}
function c(e, r) {
  var t = (e.split("?") || [,])[1];
  if (!t || -1 === t.indexOf(r)) return e;
  var n = new RegExp("(\\&|\\?)" + r + '([_A-Za-z0-9"+=.\\/\\-@%]+)', "g"),
    o = ("?" + t).replace(n, "").replace(/^&/, "?");
  return e.replace("?" + t, o);
}
function l(e, r) {
  return n((RegExp(e + "=(.+?)(&|$)").exec(r) || [, ""])[1]);
}
function s(r) {
  return function (e) {
    for (var r, t = Object.create(null), o = /([^&=]+)=?([^&]*)/g; r = o.exec(e);) {
      var a = n(r[1]),
        i = n(r[2]);
      "[]" === a.substring(a.length - 2) ? (t[a = a.substring(0, a.length - 2)] || (t[a] = [])).push(i) : t[a] = "" === i || i;
    }
    for (var u in t) {
      var c = u.split("[");
      c.length > 1 && (m(t, c.map(function (e) {
        return e.replace(/[?[\]\\ ]/g, "");
      }), t[u]), delete t[u]);
    }
    return t;
  }(function (r) {
    if (r) {
      var t = r.match(/\?(.*)/);
      return t && t[1] ? t[1].split("#")[0] : "";
    }
    return e && window.location.search.substring(1);
  }(r));
}
function m(e, r, t) {
  for (var n = r.length - 1, o = 0; o < n; ++o) {
    var a = r[o];
    if ("__proto__" === a || "constructor" === a) break;
    a in e || (e[a] = {}), e = e[a];
  }
  e[r[n]] = t;
}
function f(r, t) {
  return e ? new Promise(function (e, n) {
    if (window.history && window.history.replaceState) {
      var o = window.location.href,
        a = c(o, r);
      o !== a && history.replaceState({}, "", a);
    }
    return t && t(), e();
  }) : Promise.resolve();
}
function g(r) {
  if (!e) return null;
  var t = document.createElement("a");
  return t.setAttribute("href", r), t.hostname;
}
function p(e) {
  return (g(e) || "").split(".").slice(-2).join(".");
}
function x(e) {
  var r = e.split(".");
  return r.length > 1 ? r.slice(0, -1).join(".") : e;
}
var d = {
  trimTld: x,
  getDomainBase: p,
  getDomainHost: g
};
function v(r, t) {
  if (!e) return !1;
  var n = {
    source: "(direct)",
    medium: "(none)",
    campaign: "(not set)"
  };
  r && i(r) && (n.referrer = r);
  var o = function (r) {
    if (!r || !e) return !1;
    var t = p(r),
      n = document.createElement("a");
    if (n.href = r, n.hostname.indexOf("google") > -1 && (t = "google"), w[t]) {
      var o = w[t],
        a = new RegExp(("string" == typeof o ? o : o.p) + "=.*?([^&#]*|$)", "gi"),
        u = n.search.match(a);
      return {
        source: o.n || x(t),
        medium: "organic",
        term: (u ? u[0].split("=")[1] : "") || "(not provided)"
      };
    }
    var c = i(r) ? "referral" : "internal";
    return {
      source: n.hostname,
      medium: c
    };
  }(r);
  o && Object.keys(o).length && (n = Object.assign({}, n, o));
  var a = s(t),
    u = Object.keys(a);
  if (!u.length) return n;
  var c = u.reduce(function (e, r) {
    return r.match(/^utm_/) && (e["" + r.replace(/^utm_/, "")] = a[r]), r.match(/^(d|g)clid/) && (e.source = "google", e.medium = a.gclid ? "cpc" : "cpm", e[r] = a[r]), e;
  }, {});
  return Object.assign({}, n, c);
}
var h = "q",
  w = {
    "daum.net": h,
    "eniro.se": "search_word",
    "naver.com": "query",
    "yahoo.com": "p",
    "msn.com": h,
    "aol.com": h,
    "ask.com": h,
    "baidu.com": "wd",
    "yandex.com": "text",
    "rambler.ru": "words",
    google: h,
    "bing.com": {
      p: h,
      n: "live"
    }
  };
function y() {
  for (var e = "", r = 0, t = 4294967295 * Math.random() | 0; r++ < 36;) {
    var n = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[r - 1],
      o = 15 & t;
    e += "-" == n || "4" == n ? n : ("x" == n ? o : 3 & o | 8).toString(16), t = r % 8 == 0 ? 4294967295 * Math.random() | 0 : t >> 4;
  }
  return e;
}
function b(e, r) {
  var t,
    n,
    o,
    a = null,
    i = 0,
    u = function () {
      i = new Date(), a = null, o = e.apply(t, n);
    };
  return function () {
    var c = new Date();
    i || (i = c);
    var l = r - (c - i);
    return t = this, n = arguments, l <= 0 ? (clearTimeout(a), a = null, i = c, o = e.apply(t, n)) : a || (a = setTimeout(u, l)), o;
  };
}
export { n as decodeUri, o as getBrowserLocale, a as getTimeZone, i as isExternalReferrer, u as isScriptLoaded, c as paramsClean, l as paramsGet, s as paramsParse, f as paramsRemove, v as parseReferrer, b as throttle, d as url, y as uuid };
