<div class="create-container">
	<h3 mat-dialog-title class="H4Black700Regular text-centered">
		New Image Group
	</h3>
	<mat-form-field appearance="fill">
		<input matInput placeholder="Image Group Name" [(ngModel)]="newBatchString" (keydown.enter)="submit()">
	</mat-form-field>
	<div *ngIf="(!hasUniqueName && newBatchString.length > 0)" class="duplicate-name-msg">
		An image group with this name already exists.
	</div>
	<div class="dialog-actions">
		<button mat-button class="back-button" (click)="close()">
			Cancel
		</button>
		<button mat-stroked-button [disabled]="!org?.id" color="primary" (click)="submit()">
			Create
		</button>
	</div>
	<button mat-button class="close-button" matTooltip="Close" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>
</div>