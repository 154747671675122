<div class="dialog-base">
	<form [formGroup]="renameForm" (ngSubmit)="onRename();">
		<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose>
			<mat-icon>close</mat-icon>
		</button>
		<div class="H4Black700Regular" style="padding: 0 0 16px 0;">
			Rename
		</div>
		<mat-form-field appearance="fill" style="width: 500px;">
			<input matInput formControlName="name" data-testid="rename" placeholder="Choose a new name" cdkFocusInitial maxlength="128">
		</mat-form-field>
		<div *ngIf="renameForm.controls['name'].errors?.nameTaken" class="name-error">
			<mat-error class="H7RedRegular">Name already exists. Please choose a unique name.</mat-error>
		</div>
		<div style="display: flex; margin-top: 32px;">
			<button mat-button matDialogClose class="link H7Blue500Regular" style="margin-left: auto;">
				Cancel
			</button>
			<button mat-stroked-button color="primary" style="margin-left: 32px;"
				[disabled]="(renameForm.untouched && renameForm.pristine) || renameForm.invalid" (click)="onRename()">
				Update
			</button>
		</div>
	</form>
</div>
