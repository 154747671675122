
// User Model
export class User {

	public id: number;
	public first_name: string;
	public last_name: string;
	public email: string;
	public phone: string;

	public organization_id: number;
	public organization_plan_id: number;
	public default_organization_id: number;

	public org_users: any;

	/* Tokens */
	public google_token: string;

	public api_key: string;
	public descriptors: any;

	public avatar: string;

	/* Trial */
	public trial_started_at: Date;
	public trial_ends_at: Date;
	public trial: boolean;

	public payment_override: boolean;
	public license: any;
	public is_aerial_admin: boolean;

	/* Verification */
	public email_verified: boolean;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of class UserModel

// Verification Model
export class Verification {
	public id: number;
	public first_name: string;
	public last_name: string;
	public email: string;
	public phone: string;
	public created_at: string;
	public updated_at: string;
	public password_encrypted: string;
	public email_verified: number;
	public email_code: string;
	public reset_code: string;
	public avatar: string;
	public api_key: string;
	public active: number;
	public descriptors: object;
	public default_organization_id: number;
	public internal: number;
}
