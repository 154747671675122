
<ng-container *ngIf="isInTrial(activeOrg) && !isProcessing">
	<button mat-button class="rounded-menu" (click)="openUpgrade()">
		<mat-icon [ngClass]="getTrialClass(trialDaysRemaining)">star</mat-icon>
		<span *ngIf="trialDaysRemaining >= 1">Free Trial: {{trialDaysRemaining}} {{pluralize('day', trialDaysRemaining)}} remaining</span>
		<span *ngIf="trialDaysRemaining < 1">Trial expired</span>
	</button>

	<mat-menu #creditsMenu class="mat-menu medium-menu">
		<ng-template matMenuContent>
			<span class="rounded-menu-container" (click)="$event.stopPropagation();">
				<div class="LabelBlack500 uppercase" style="margin-bottom: 8px;">Mapware Trial</div>

				<div class="H3Black700" *ngIf="trialDaysRemaining >= 1">{{trialDaysRemaining}} {{trialDaysRemaining === 1 ? 'Day' : 'Days'}} Remaining</div>
				<div class="H3Black700" *ngIf="trialDaysRemaining < 1">Trial expired</div>

				<div style="margin-top: 16px; margin-bottom: 16px">
					<mat-divider color="#dee0e3"></mat-divider>
				</div>

				<div style="margin-bottom: 16px; display: flex;">
					<span class="trialInfo" *ngIf="trialDaysRemaining >= 1">
						<div>At the end of your trial, your Professional subscription will begin automatically.
							You'll continue to have uninterrupted access to your existing models, and be able to upload photos and create new models.</div>
						<div>If you cancel before your trial period is over, you will no longer be able to download or export your models, or to upload photos and create new models.</div>
						<span>If you have any questions, please don't hesitate to reach out to
							<button mat-button class="link" (click)="sendFeedback();">customer support</button>.
						</span>
					</span>
					<span class="H7Black700Regular" *ngIf="trialDaysRemaining < 1">Your trial has expired, you will no longer be able to upload photos, process models, or download or export your processed models.</span>
				</div>

	<!--			TODO: Reimpliment when Subscription management is added -->
	<!--			<div class="P2Black500" *ngIf="trialDaysRemaining >= 1">-->
	<!--				<span class="link CenterP2Blue" (click)="openUpgrade(orgControl.value)">Upgrade to Professional</span> to keep access to all of your data and continue processing models.-->
	<!--			</div>-->
	<!--			<div class="P2Black500" *ngIf="trialDaysRemaining < 1">-->
	<!--				<span class="link CenterP2Blue" (click)="openUpgrade(orgControl.value)">Upgrade to Professional</span> to regain access to all of your data and be able to export models.-->
	<!--			</div>-->

				<app-contact-component>to convert to a paid plan</app-contact-component>

				<button *ngIf="isMobile" mat-stroked-button color="primary" (click)="menuTrigger.closeMenu();">Back</button>
			</span>
		</ng-template>
	</mat-menu>
</ng-container>
