
/* Imports */
import { Component, Output, OnInit, OnDestroy, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
	AlertService,
	DownloadService,
	ImageService,
	IMAGESIZES,
	GlobalService,
	ProjectService,
	ImageInfo,
	TitleService,
	UtilsService,
	isFulfilled
} from 'src/app/shared/services';
import { Image } from '@shared/models';
import { icon, latLng, Layer, Marker, marker, tileLayer } from 'leaflet';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { environment } from '@/environments/environment';
import { UploadService } from '../../../../../shared/services/upload.service';
import {availableFeatureFlags, flagLayer} from '@shared/featureFlags';

@Component({
	selector: 'app-photos-view',
	templateUrl: './photos-view.component.html',
	styleUrls: ['./photos-view.component.scss']
})
export class PhotosViewComponent implements OnInit, OnDestroy {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild(MatMenu) menu: MatMenu;

	@Output('backEvent') backEvent: EventEmitter<any> = new EventEmitter();

	private _imagePanZone: any;
	private activatedRouteSubscription: Subscription;

	public currentIndex: number;
	public imageList: ImageInfo[] = [];
	public paramsData: string = '';
	public projectId: number;
	public rootURL = GlobalService.databaseApiUrl;
	public imageInfo: ImageInfo;
	public map: any;

	public mapStyle: 'map' | 'sat' = 'map';
	public options = {
		zoom: 12,
		minZoom: 12,
		maxZoom: 12,
		center: latLng(0, 0),
		doubleClickZoom: 0,
		zoomControl: false,
		dragging: false,
		attributionControl: false
	};
	public layers: Layer[] = [];
	public hideAside: boolean = false;

	protected token: string = environment.mapbox;
	protected LAYER_MAP: Layer = tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
		tileSize: 512,
		maxZoom: 18,
		zoomOffset: -1,
		id: 'mapbox/streets-v11',
		accessToken: this.token
	});
	protected LAYER_SAT: Layer = tileLayer(`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${this.token}`);
	protected marker: Marker;

	public msDetail: string = '';
	public batchImages: Array<any> = [];
	public srcImageId: number;

	public imageGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _alertService: AlertService,
		private downloadService: DownloadService,
		private imageService: ImageService,
		private projectService: ProjectService,
		private _cdr: ChangeDetectorRef,
		private _titleService: TitleService,
		private _utilsService: UtilsService,
		private _uploadService: UploadService,
		private _imageService: ImageService
	) {

		this.hideAside = this._utilsService.getIsTablet();

	}	// End-of constructor

	ngOnInit(): void {

		this.checkRoute();

	}	// End-of ngOnInit


	ngOnDestroy(): void {

		this.activatedRouteSubscription && this.activatedRouteSubscription.unsubscribe();

	}	// End-of ngONDestroy


	checkRoute(): void {
		this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe((urlParams: any) => {
			this.paramsData = urlParams.params.batch_id;
			this.getPhotoInfo(urlParams.params.guid);
		});
	}


	getPhotoInfo(imageGUID: string): void {

		this.imageService.info(imageGUID).then(res => {
			this._titleService.setTitle(res.name);
			this.imageInfo = res;

			if (res.latitude && res.longitude) {
				this.options.center = latLng(res.latitude, res.longitude);
				this.marker = marker([res.latitude, res.longitude], {
					icon: icon({
						iconSize: [25, 41],
						iconAnchor: [13, 41],
						iconUrl: '/assets/icons/location.svg',
					})
				});
			}

			this.setMapStyle('map');

			this.isFromProject && this.getPhotoList();
		});
	}

	private getPhotoList() {

		let batchId = parseInt(this.paramsData, 10);
		this.projectId = batchId;
		if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
			this.getDeepBatch(batchId);
		} else {
			this.projectService.getById(batchId).then((res: { batches: Array<{ images: Array<ImageInfo>; }>; }) => {
				this.handlePhotos(res.batches);
			});
		}
	}

	getDeepBatch(batchId) {
		return this._imageService.getBatchV2(batchId)
			.then((rtnBatch)=> {
				this.getMSDetails([rtnBatch])
				this.imageGroup = rtnBatch;
				if (!rtnBatch.images?.length) {
					this.imageList = [];
					this.currentIndex = 0;
					return rtnBatch;
				}
				let imageList = [...rtnBatch.images];
				imageList = imageList.filter(photo => photo?.trash === 0 && !this._uploadService.isFileInactive(photo))
				imageList.sort((a, b) => a.name.localeCompare(b.name));
				this.imageList = imageList;
				this.currentIndex = imageList.findIndex(item => item.guid === this.imageInfo.guid);
				return rtnBatch;
			});
	}

	handlePhotos(batches): Array<any> {
		let imageList = [];
		this.getMSDetails(batches)
		batches.forEach((batch) => {
			if (batch?.images?.length) {
				imageList.push(...batch.images);
			}
		});
		imageList = imageList.filter(photo => photo?.trash === 0 && !this._uploadService.isFileInactive(photo))
		imageList.sort((a, b) => a.name.localeCompare(b.name));
		this.imageList = imageList;
		return imageList;
	}

	getMSDetails(batches){
		batches?.forEach((batch) => {
			if (this.imageInfo?.batch_id === batch?.id) {
				this.msDetail = batch.sensor;
				this._imageService.getBatchImagesV2(batch.id)
				.then(this.getBatchImages)
				.catch(console.error);
			}
		})
	}

	getBatchImages = async (batchImages) => {
		batchImages?.forEach((img) => {
			if (this.imageInfo.id === img.source_image_id) {
				this.batchImages.push(img);
			}
			if (this.imageInfo.id === img.id) {
				this.batchImages.unshift(img);
				this.srcImageId = img.id;
			}
		})
	}

	onMapReady(map): void {

		this.map = map;

	}	// End-of onMapReady


	download() {

		this.imageService.download(this.imageInfo.guid, IMAGESIZES.large)
			.then((img: Blob) => this.downloadService.downloadHelper(img, this.imageInfo.name));

	}	// End-of download

	trash() {
		this.imageService.trash(new Image({guid: this.imageInfo.guid}))
			.then(() => {
				this._alertService.notify("Image moved to trash.", "success");
				this.changePhoto(1);
			})
			.catch(() => {
				this._alertService.notify("Unable to move image to trash.", "error");
			});
	}


	goBack(): void {

		const projId = this.imageGroup.project_id ??
			this.paramsData;

		if (this.paramsData.startsWith('search')) {
			this.router.navigate(['search']);
		} else if (this.paramsData.startsWith('favorites')) {
			this.router.navigate(['favorites']);
		} else if (this.isFromProject) {
			this.router.navigate(['projects', 'view', projId], { queryParams: { tab: 2 } });
		} else {
			this.router.navigate(['/']);
		}

	}	// End-of goBack

	goHome(): void {
		this.router.navigate(['/']);
	}


	setMapStyle(style: 'map' | 'sat'): void {

		this.mapStyle = style;
		let baseLayer: Layer = style === 'map' ? this.LAYER_MAP : this.LAYER_SAT;
		this.layers = [baseLayer, this.marker];

	}	// End-of setMapStyle


	get isFromProject(): boolean {
		return !(this.paramsData.startsWith('search') || this.paramsData.startsWith('favorites')) && !isNaN(parseInt(this.paramsData, 10));
	}	// End-of isFromProject


	get readyForButtons(): boolean {
		return this.imageList && (this.currentIndex || this.currentIndex === 0) && this.isFromProject;
	}


	changePhoto(dir: number = 0): void {
		this.currentIndex += dir;
		if(this.currentIndex < 0) this.currentIndex = 0;
		if(this.currentIndex >= this.imageList.length) this.currentIndex = this.imageList.length - 1;

		this.router.navigate(['..', this.imageList[this.currentIndex].guid], { relativeTo: this.activatedRoute });
	}


};	// End-of class SinglePhotoComponent
