import { styled } from "@mui/system";

export const StepIndicator = styled("div")<{ completed: boolean }>(
	({ theme, completed }) => ({
		backgroundColor: completed ? theme.palette.primary.main : "translucent",
		border: `2px solid`,
		borderColor: completed ? theme.palette.primary.main : "#A4ABB3",
		borderRadius: "50%",
		boxSizing: "border-box",
		height: 11,
		width: 11,
	})
);
