import { MODEL_STATUSES } from "./constants";

/**
 * For any layer group (e.g. cesium_files, potree_files, and geotiffs)
 * we only need to care if **everything** has been "Rendered" or not.
 * So it is enough to return a simple boolean
 *
 * @param {{ status: string }[]} layers
 * @returns boolean;
 */

export function isLayerRendered(layers) {
  return (
    layers?.length &&
    layers.some((l) => (l.status ? l.status === MODEL_STATUSES.rendered : !!l))
  );
}
