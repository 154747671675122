
<div class="confirmation-container" data-testid="confirmation-modal">
	<ng-container *ngTemplateOutlet="t_header"></ng-container>
	<ng-container *ngTemplateOutlet="t_upload"></ng-container>
	<ng-container *ngTemplateOutlet="t_footer"></ng-container>
</div>

<ng-template #t_header>
	<div mat-dialog-title>
		<div class="H2Black700">Upload geospatial files</div>
		<div class="H4Black500Regular">Import your <b>{{importTypesString}}</b> files to view in the Project Map</div>
	</div>
	<button mat-button class="close-button" matTooltip="Close" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>
</ng-template>

<ng-template #t_upload>

	<input data-testid="dialogUpload" style="display: none;" multiple type="file" [accept]="acceptedImportTypes" #fileInput (change)="filesChanged($event.target.files)">

	<div class="dialog-content">
		<div class="minimap-container" *ngIf="filesToUpload">
			<app-minimap [positions]="minimapValues"></app-minimap>
		</div>

		<aerial-dropzone (filesEmitter)="dropzoneUpload($event)" [noBorder]="true">
			<div class="upload-container" [ngClass]="{'no-files': !filesToUpload.length}">
				<ng-container *ngIf="!filesToUpload.length">
					<ng-container *ngTemplateOutlet="t_emptyDropzone"></ng-container>
				</ng-container>
				<ng-container *ngIf="filesToUpload.length">
					<ng-container *ngTemplateOutlet="t_loadedDropzone"></ng-container>
				</ng-container>
			</div>
		</aerial-dropzone>

	</div>

	<div class="dropzone-error">
		<ng-container *ngIf="showFiletypeError">
			One or more files weren't of the {{ importTypesString }} file types and were not uploaded.
		</ng-container>
	</div>

</ng-template>

<ng-template #t_emptyDropzone>
	<div class="upload-icon">
		<mat-icon>
			cloud_upload
		</mat-icon>
	</div>

	<div class="flex-column-center">
		<div class="dropzone-text">
			Drag and drop your {{ importTypesString }} files here
		</div>
		<div class="H7Black500Regular">- or -</div>
		<div class="link-container" (click)="fileInput.nativeElement.click()">
			<span class="link">Click to upload files</span>
			<mat-icon>add_circle_outline</mat-icon>
		</div>
	</div>
</ng-template>

<ng-template #t_loadedDropzone>
	<div class="loaded-title">
		<span class="H5Black700">Files to import</span>
		<span class="flex-row-center link-container" (click)="fileInput.nativeElement.click()">
			<span class="link">Upload more</span>
			<mat-icon>add_circle_outline</mat-icon>
		</span>
	</div>
	<ng-container *ngFor="let file of filesToUpload; let ind = index;">
		<div class="file-list-item">
			<div class="grid-container">
				<b class="title">{{file.name}}</b>
				<span class="icon"><mat-icon>map</mat-icon></span>
				<div class="info">
					<span class="uppercase">{{getFileExtension(file.name)}}</span>
					|
					<span class="uppercase">{{file.size ? (file.size | bytes) : '- -'}}</span>
				</div>
			</div>
			<button class="remove-button" (click)="removeFile(ind)" mat-button matTooltip="Remove">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</ng-container>
</ng-template>

<ng-template #t_footer>
	<div class="primary-buttons">
		<button *ngIf="filesToUpload?.length"
				mat-button
				(click)="clearUpload()">
			Cancel
		</button>
		<button *ngIf="!filesToUpload?.length"
				mat-button
				(click)="close()">
			Close
		</button>
		<button
			[disabled]="!filesToUpload?.length"
			(click)="submitImport()"
			mat-stroked-button
			color="primary">
			Begin Import
		</button>
	</div>
</ng-template>
