
/* Imports */
import { Component, Input } from '@angular/core';

@Component({
	selector: 'info-box',
	templateUrl: './info-box.component.html',
	styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
	@Input() color: string = "black";

	public makeColorStyle(color) {
		return {color};
	}
}
