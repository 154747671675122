<div class="page-background">
	<div class="page-background-grid-overlay"></div>
	<div class="page-background-overlay"></div>
</div>

<div class="registration-card-container" *ngIf="registerForm && paymentForm">

	<ng-container *featureEnabled="'disable-signup'">
		<ng-container *ngTemplateOutlet="t_invitedOnly"></ng-container>
	</ng-container>

	<ng-container *featureEnabled="'!disable-signup'">

		<!-- TODO: @remove remove-subscription-required : block marked for deprecation -->
		<ng-container *featureEnabled="'!remove-subscription-required'">
			<ng-container *ngTemplateOutlet="t_subscribeMain"></ng-container>
		</ng-container>

		<ng-container *featureEnabled="'remove-subscription-required'">
			<ng-container *ngTemplateOutlet="t_registerCard"></ng-container>
		</ng-container>

	</ng-container>

</div>

<ng-template #t_registerCard>
	<div class="card registration-card">
		<div class="card-header login-card ">
			<img class="logo" src="./assets/images/Mapware_Logo_CMYK.png">
		</div>

		<div class="card-body">

			<div class="card-title centered">
				{{getTitle(registerStepper?.selectedIndex)}}
			</div>

			<div class="card-info centered" *ngIf="invitation_id">
				You've been invited to join <b>{{orgName}}</b>
			</div>

			<app-register-user #registerUserForm [formGroup]="registerForm" [(emailAlreadyRegistered)]="emailAlreadyRegistered"></app-register-user>

			<div class="card-footer">

				<ng-container *ngIf="googleAuthAvailable">
					<!-- or divider-->
					<div class="signin-divider">
						<div class="half-line"></div>
						or
						<div class="half-line"></div>
					</div>
					<div class="card-actions">
						<ng-container *ngTemplateOutlet="t_google_register"></ng-container>
					</div>
				</ng-container>

				<div class="terms-agreement">
					<mat-checkbox [(ngModel)]="termsAgreement" data-testid="terms-agreement">
					</mat-checkbox>
					<span class="P1Black700"> I agree to the
						<span class="policy" (click)="openLink('termsOfService'); $event.stopPropagation();">Terms of Service</span>,
						<span class="policy" (click)="openLink('privacyPolicy'); $event.stopPropagation();">Privacy Policy</span>, and
						<span class="policy" (click)="openLink('cookiePolicy'); $event.stopPropagation();">Cookie Policy</span>
					</span>
				</div>

				<div class="card-actions">
					<button mat-flat-button
							color="primary"
							class="main-card-button"
							[disabled]="!enableRegisterButton(registerForm, termsAgreement, isProcessing, emailAlreadyRegistered)"
							(click)="appRegister();">
						{{invitation_id ? "Create account and log in" : "Create account"}}
					</button>
				</div>

				<div class="H7Black700Semibold centered" *ngIf="!invitation_id">
					Click "Create Account" to begin your 15-day free trial.
					Once the trial is over, you'll be able to select a subscription plan or just pay-as-you-go.
				</div>
			</div>
		</div>

		<div class="nav-container">
			<div class="nav-link" (click)="navigateToLogin()">{{isRegistered ? 'Sign into another account' : 'Already have an account? Sign in.'}}</div>
		</div>

	</div>
</ng-template>


<ng-template #t_invitedOnly>
	<div class="card registration-card">
		<div class="card-header login-card ">
			<img class="logo" src="./assets/images/Mapware_Logo_CMYK.png">
		</div>

		<div class="card-body">

			<div class="card-title centered">
				Create your account
			</div>

			<div class="card-info centered" *ngIf="invitation_id">
				You've been invited to join <b>{{orgName}}</b>
			</div>

			<app-register-user #registerUserForm [formGroup]="registerForm" [(emailAlreadyRegistered)]="emailAlreadyRegistered"></app-register-user>

			<div class="card-footer">

				<div class="terms-agreement">
					<mat-checkbox [(ngModel)]="termsAgreement" style="margin-bottom: 2rem; display: flex;" data-testid="terms-agreement">
					</mat-checkbox>
					<span class="P1Black700"> I agree to the
						<button mat-button class="policy" (click)="openLink('termsOfService'); $event.stopPropagation();">Terms of Service</button>,
						<button mat-button class="policy" (click)="openLink('privacyPolicy'); $event.stopPropagation();">Privacy</button>, and
						<button mat-button class="policy" (click)="openLink('cookiePolicy'); $event.stopPropagation();">Cookie Policy</button>
					</span>
				</div>

				<div class="card-actions">
					<button mat-button
						class="main-card-button"
						[disabled]="!enableRegisterButton(registerForm, termsAgreement, isProcessing, emailAlreadyRegistered)"
						(click)="appRegister();">
						Create account
					</button>
				</div>
			</div>

		</div>
	</div>
</ng-template>


<!-- TODO: @remove remove-subscription-required : t_subscribeMain template -->
<ng-template #t_subscribeMain>
	<div class="card old-registration" [ngClass]="{'freeToPaid' : freeToPaid}">
		<!-- <div class="card-container"> -->
			<div class="card-header" style="display: flex;">
				<!-- <button *ngIf="freeToPaid" mat-button style="margin-right: auto;" matTooltip="Return to account" (click)="returnToAccount()">
					<mat-icon style="font-size: 28px;">keyboard_backspace</mat-icon>
				</button> -->
				<img class="logo-icon" src="./assets/images/aa_logo.png">
				<ng-container *ngIf="!freeToPaid && !isRegistered && googleAuthAvailable">
					<ng-container *ngTemplateOutlet="t_google_register"></ng-container>
				</ng-container>
			</div>

			<div class="card-body">

				<div class="card-title">
					<div class="card-sub-title" *ngIf="registerStepper.selectedIndex >= 1">Start your Subscription</div>
					<div class="card-sub-title" *ngIf="registerStepper.selectedIndex < 1">MAPWARE: Aerial Applications </div>
						{{titleList[registerStepper.selectedIndex]}}
					<div class="LabelBlack500" *ngIf="!isRegistered && registerStepper.selectedIndex === 1">All plans begin with a 14 day free trial</div>
					<div class="H7Black700Semibold" *ngIf="isRegistered">Welcome back, {{user.first_name}}.</div>
				</div>

				<div class="card-info centered" *ngIf="invitation_id">
					You've been invited to join <b>{{orgName}}</b>
				</div>

				<mat-horizontal-stepper #registerStepper [@.disabled]="true" class="stepper-with-dots">
					<mat-step *ngIf="!freeToPaid && !isRegistered">
						<div style="width: 100%;">
							<app-register-user #registerUserForm [formGroup]="registerForm"></app-register-user>
						</div>
					</mat-step>
					<mat-step>
						<div style="width: 100%;">
							<app-register-cycle [(displayPlan)]="displayPlan" ></app-register-cycle>
						</div>
					</mat-step>
					<ng-container *ngIf="paymentForm">
						<mat-step>
							<div style="width: 100%;">
								<!-- <app-register-billing [formGroup]="paymentForm"></app-register-billing> -->
								<!--Single line, all card fields in one element-->
								<div class="ChargeBeeElement" cbCardField id='card-field' (change)="cbIsCardComplete($event)" (ready)="setComponent($event)" style="margin-top: 32px; margin-bottom: 8px;"></div>
								<div class="H7RedRegular" *ngIf="cbError">{{cbError}}</div>
								<!--Multiple elements, seperate card item fields-->
								<!-- <div class="ex1 container">
        						    <div class="ex1-wrap">
        						        <div class="ex1-fieldset">
        						            <div id="card-field" cbCardField class="fieldset field"
        						                 [styles]="styles"
        						                 [classes]="classes"
        						                 [fonts]="fonts"
        						                 [placeholder]="placeholder"
        						                 [locale]="locale"
        						                 (ready)="setComponent($event)"
        						            >
        						                <div class="ex1-field">
        						                    <div class="H6Black700Regular" style="margin-top: 24px; margin-bottom: 8px;">
        						                        Card number
        						                    </div>
        						                    <div id="number-field" cbNumberField class="ex1-input ChargeBeeElement" [placeholder]="'4111 1111 1111 1111'"></div>
        						                </div>
        						                <div class="ex1-fields">
        						                    <div class="ex1-field">
        						                        <div class="H6Black700Regular" style="margin-top: 24px; margin-bottom: 8px;">
        						                            Expiration date
        						                        </div>
        						                        <div id="expiry-field" cbExpiryField class="ex1-input ChargeBeeElement"></div>
        						                    </div>
        						                    <div class="ex1-field">
        						                        <div class="H6Black700Regular" style="margin-top: 24px; margin-bottom: 8px;">
        						                            Security code
        						                        </div>
        						                        <div id="cvv-field" cbCvvField class="ex1-input ChargeBeeElement"></div>
        						                    </div>
        						                </div>
        						            </div>
        						        </div>
        						    </div>
        						</div> -->
							</div>
						</mat-step>
						<mat-step>
							<div style="width: 100%;">
							<app-register-confirmation
								[displayPlan]="displayPlan"
								[registerFormGroup]="registerForm"
								[paymentFormGroup]="paymentForm"
								(setPage)="addSteps($event)">
							</app-register-confirmation>
							</div>
						</mat-step>
					</ng-container>
				</mat-horizontal-stepper>
			</div>

			<div class="card-actions"
				*ngIf="registerStepper && registerStepper._steps && (registerStepper._steps.length - registerStepper.selectedIndex > 1)"
				[ngClass]="{'card-actions-relative': registerStepper._steps.length - registerStepper.selectedIndex === 2}">
				<div style="margin-left: auto;">
					<button mat-stroked-button color="primary" style="margin: 8px 16px;" (click)="prevStep()" *ngIf="registerStepper.selectedIndex > 0">
						Back
					</button>
					<button class="next-button"
						mat-stroked-button color="primary"
						(click)="nextStep()"
						type="submit"
						[disabled]="!enableNext()">
						Next
					</button>
				</div>
			</div>
			<div class="card-footer" style="display: block;" *ngIf="registerStepper && registerStepper._steps && (registerStepper._steps.length - registerStepper.selectedIndex === 1)">
				<div style="display: flex;">
					<mat-checkbox [(ngModel)]="termsAgreement" style="margin-bottom: 16px; display: flex;" data-testid="terms-agreement">
					</mat-checkbox>
					<span class="P1Black700"> I agree to the
						<button mat-button class="policy" (click)="openLink('termsOfService'); $event.stopPropagation();">Terms of Service</button>,
						<button mat-button class="policy" (click)="openLink('privacyPolicy'); $event.stopPropagation();">Privacy</button>, and
						<button mat-button class="policy" (click)="openLink('cookiePolicy'); $event.stopPropagation();">Cookie Policy</button>
					</span>
				</div>
				<button mat-stroked-button color="primary" style="margin: 0 auto 8px auto; display: flex;" (click)="prevStep()">
					Back
				</button>

				<button mat-stroked-button color="primary"
					style="width: 100%"
					[disabled]="!(termsAgreement && paymentForm.valid && (registerForm.valid || registerForm.disabled)) || isProcessing"
					(click)="appRegister();">Create account & complete subscription</button>
				<mat-error *ngIf="subscribedError" class="H7RedRegular" style="margin: 16px 0;">{{subscribedError}}</mat-error>
				<mat-error *ngIf="purchaseError" class="H7RedRegular" style="margin: 16px 0;">{{purchaseError}} If this issue persists, please <button mat-button class="link" (click)="sendFeedback();">contact support</button>.</mat-error>
				<div class="dot-container">
					<div class="dot-stretching" *ngIf="isProcessing"></div>
				</div>
			</div>

		<div class="card-footer">
			<div class="nav-container">
				<div class="nav-link" (click)="navigateToLogin()">{{isRegistered ? 'Sign into another account' : 'Already have an account? Sign in.'}}</div>
			</div>
		</div>
		<!-- </div> -->
	</div>

</ng-template>
<!-- TODO: @remove-end remove-subscription-required -->

<ng-template #t_google_register>
	<div class="custom-google-button main-card-button" #googleLogin id="my-signup2" (click)="googleOnClick()">
		<div class="custom-google-icon"></div>
		<span class="gText">{{gButtonText}}</span>
	</div>
</ng-template>
