
<div class="project-table">

	<div class="loading-container" *ngIf="isProcessing && !dataSource?.data?.length">
		<div class="dot-stretching"></div>
	</div>

	<div class="table-content">
		<ng-container *ngIf="dataSource.data?.length">
			<div class="group-controls">
				<ng-container *ngFor="let sortOption of sortOptions">
					<button [ngClass]="{'active': isSort(sortOption.value)}"
							class="group-control"
							mat-button
							(click)="setSort(sortOption.value)">
						{{ sortOption.text }}
						<mat-icon [ngClass]="{'rotated': checkSortAndDir(sortOption.value)}">arrow_downward</mat-icon>
					</button>
				</ng-container>
				<button
					*ngIf="_project?.id"
					tabindex="0"
					class="refresh-button"
					[ngClass]="{'refresh': isProcessing}"
					mat-icon-button matTooltip="Refresh"
					(click)="refreshProject()">
					<mat-icon class="list-item-icon">refresh</mat-icon>
				</button>
			</div>
			<ng-container *ngFor="let model of dataSource.data; let i = index;">
				<app-model
					[model]="model"
					[project]="_project"
					[isProcessing]="isProcessing"
					(update)="onUpdate($event, i);"
				></app-model>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!isProcessing && !dataSource.data?.length">
			<div class="project-info">
				<mat-icon class="large-icon">layers</mat-icon>
				<div>You have not imported or processed any data layers.</div>
				<div>Click on ‘Upload’ or ‘Process New Model’ to begin.</div>
			</div>
		</ng-container>
	</div>

	<!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->

	<div *ngIf="!isProcessing && !dataSource?.data?.length" class="project-info">
		<mat-icon class="large-icon">layers</mat-icon>

		<div *ngIf="trialExpired">Your trial has expired. You will not be able to process a new model until you start a subscription.</div>
		<ng-container *ngIf="!trialExpired">
			<ng-container *featureEnabled="'!disable-processing'">
				<div>You have not processed any models.</div>
				<div>Click on ‘Process New Model’ to create your first model.</div>
			</ng-container>
			<ng-container *featureEnabled="'disable-processing'">
				<div>You do not have any imported models.</div>
				<div>Click on ‘Upload Data’ to import a processed model.</div>
			</ng-container>
		</ng-container>
	</div>
</div>
