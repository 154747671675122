import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { reducer as portalRegistry } from "@components/Portal";
import { api as annotationsAPI } from "@features/Annotations";
import { reducer as annotationGroups } from "@features/AnnotationVisibilityControls";
import { mapMode, sidebarMode } from "@features/Map";
import { api as projectsAPI } from "@features/MLWizard";
import { reducer as layerGroups } from "@features/ViewerLayers";
import { api as projectAPI } from "@react/apis";

import { connect as _connect } from "./utils/connect";

export const store = configureStore({
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(annotationsAPI.middleware)
			.concat(projectsAPI.middleware)
			.concat(projectAPI.middleware),

	reducer: {
		annotationGroups,
		layerGroups,
		mapMode: mapMode.reducer,
		portalRegistry,
		sidebarMode: sidebarMode.reducer,
		[annotationsAPI.reducerPath]: annotationsAPI.reducer,
		[projectsAPI.reducerPath]: projectsAPI.reducer,
		[projectAPI.reducerPath]: projectAPI.reducer,
	},
});
export type RootState = ReturnType<typeof store.getState>;

export const connect = _connect;
export const ngConnection = (connectionConfig) => (cmp) =>
	_connect(connectionConfig)(cmp, store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
