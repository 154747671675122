import isNil from "lodash/isNil";
import memoize from "lodash/memoize";
import { FileData } from "@shared/services";
// Image Model
export class Image {
	public batch_id: number;
	public id: number;
	public size: number;
	public guid: string;
	public extension: string;
	public content_type: string;
	public created_by_id: number;
	public storage_url: string;
	public small_url: string;
	public medium_url: string;
	public large_url: string;
	public large_png_url: string;
	public latitude: number;
	public longitude: number;
	public altitude: number;
	public rotation: number;
	public x_res: number;
	public y_res: number;
	public pixels: number;
	public descriptors: {
		make: string;
		model: string;
		iso: number;
		exposure: number;
		f_stop: number;
		focal_length: number;
		equivalent_focal_length: number;
		captured_at: string;
	};
	public trash: number = 0;
	public active: number;
	public created_at: Date;
	public updated_at: Date;
	public fileData?: FileData;

	constructor(params: any = {}) {
		Object.assign(this, params);
	} // End-of constructor

	static invalidSpecifiers = {};

	/**
	 * An image is invalid for processing if it does not
	 * have location data (`latitude`, `longitude`, and `altitude`)
	 * @param {Image} image
	 * @return boolean
	 */
	static canBeProcessed = (({ altitude, longitude, latitude }: Image): boolean  =>
		!(
			isNil(latitude) ||
			isNil(longitude) ||
			isNil(altitude)
		)
	)

	/**
	 * Creates a message to be displayed with images that don't have the proper location data.
	 * `memoize` is used to save time for large batches that might have a number of offending images.
	 *
	 * @param {Image}
	 * @return string
	 */
	static getInvalidMessage = memoize(
		({ altitude, longitude, latitude }: Image): string => {
			const propList = Object.entries({ altitude, longitude, latitude })
				.reduce(
					(props, [k, v]) => (isNil(v) ? props.concat(k) : props),
					[]
				)
				.join(', ')
				.replace(/,([^,]+$)/, ", and$1"); // eats, shoots, and leaves
			return `This photo is missing ${propList}. Please delete and reupload the image.`;
		}
	);
} // End-of class ImageModel
