import { Component, Input, ElementRef, ViewChild } from "@angular/core";
import { MapComponent } from "../map.component";
import { MapToolsComponent } from "../tools/map.tools.component";

@Component({
	selector: "app-map-elevation-legend",
	styleUrls: ["./elevation.legend.component.scss"],
	templateUrl: "./elevation.legend.component.html",
})
export class ElevationLegendComponent {
	@Input() map: MapComponent;
    @Input() toolbar: MapToolsComponent;

	@ViewChild('thumbOne') thumbOne: ElementRef;
	@ViewChild('thumbTwo') thumbTwo: ElementRef;

	public legendRanges;

	public units = "m";

	private _barHeight = 192
	public globalRange = 0;
	public  maxElevation = 0;
	private _hasBeenSet = false;

	public currentRange = this._barHeight;

	public thumbOnePos = 0;
	public thumbTwoPos = this._barHeight;
	public gradientPos = 0;
	
	public maxNumberOpacity = 1;
	public middleNumberOpacity = 1;
	public minNumberOpacity = 1;

	public mediumHatches:Array<number> = [];
	public smallHatches:Array<number> = [];

	setLegendGradientColors() {
		const gradients = this.map.renderer._threeDRenderer.Potree.Gradients['TURBO'].reduce((acc, gradient) => {
				const values =  Object.values(gradient[1]);
				acc.push(values);
				return acc;
		}, []).map((gradient) => {
			return `rgb(${gradient[0] * 255}, ${gradient[1] * 255}, ${gradient[2] * 255})`;
		}).reverse().join(", ");
		return `linear-gradient(${gradients})`;
	}

	setLegendElevationValues(range) {
		// dont update if it's just the default range
		if(this.globalRange !== 0 && range[0] === 0 && range[1] === 100) return;

		let [start, end] = range;
		let middle = (start + end) / 2;
		const difference = (end) - (start);

		this.globalRange = difference;
		this.maxElevation = end;

		if(this._hasBeenSet) {
			const max = Math.max(this.legendRanges[0], this.legendRanges[2]);
			const min = Math.min(this.legendRanges[0], this.legendRanges[2]);
			start = Math.max(min, start);
			end = Math.min(max, end);
			middle = (max + min) / 2;
		}
		this.legendRanges = [end, middle, start];

		this.map.renderer.updateElevationRange(true, [start, end], false);
		this.updateGradientBar(true);
		this.updateHatches();
	}

	updateHatches(): void {
		let mediumHatchScale = 500;
		let smallHatchScale = 100;

		if(this.globalRange < 1000
			&& this.globalRange >= 500) {
			mediumHatchScale = 100;
			smallHatchScale = 50;
		} else if(this.globalRange < 500
			&& this.globalRange >= 100) {
			mediumHatchScale = 50;
			smallHatchScale = 25;
		} else if(this.globalRange < 100
			&& this.globalRange >= 20) {
			mediumHatchScale = 50;
			smallHatchScale = 10;
		} else if(this.globalRange < 20) {
			mediumHatchScale = 5;
			smallHatchScale = 1;
		}

		this.smallHatches = [];
		this.mediumHatches = [];
		for(let i = Math.round(this.maxElevation); i > this.maxElevation - this.globalRange; i--)
		{
			if(i % mediumHatchScale === 0) this.mediumHatches.push(
				((this.maxElevation - i) / this.globalRange) * this._barHeight);

			if(i % smallHatchScale === 0) this.smallHatches.push(
				((this.maxElevation - i) / this.globalRange) * this._barHeight);
		}
	}

	updateGradientBar(forceVisualUpdate = false): void {
		const max = Math.max(this.legendRanges[0], this.legendRanges[2]);
		const min = Math.min(this.legendRanges[0], this.legendRanges[2]);
		this.currentRange = ((max - min) / this.globalRange) * this._barHeight;
		this.legendRanges[1] = (max + min) / 2;
		this.gradientPos = ((this.maxElevation - max) / this.globalRange) * this._barHeight;

		const maxPos = ((this.maxElevation - max) / this.globalRange) * this._barHeight;
		const minPos = ((this.maxElevation - min) / this.globalRange) * this._barHeight;

		this.middleNumberOpacity = this.currentRange < 50
			? Math.max((this.currentRange - 20) / 30, 0)
			: 1;

		this.maxNumberOpacity = maxPos < 40
			? Math.max((maxPos - 10) / 30, 0)
			: 1;

		this.minNumberOpacity = minPos > 150
			? 1 - Math.min((minPos - 150) / 30, 1)
			: 1;

		if(forceVisualUpdate) {
			this.thumbOnePos = maxPos;
			this.thumbTwoPos = minPos;

			if(this.thumbOne) this.thumbOne.nativeElement.style.top = this.thumbOnePos + "px";
			if(this.thumbTwo) this.thumbTwo.nativeElement.style.top = this.thumbTwoPos + "px";
		}

		this.map.renderer.updateElevationRange(true, [min, max], false);
	}

	startDraggingThumb(event): void {
		const startPageY = event.pageY;
		const activeThumb = event.target.parentElement;
		const startThumbPos = parseFloat(activeThumb.style.top);

		let rangeIndex = 0;
		if(event.target.parentElement === this.thumbTwo.nativeElement) {
			rangeIndex = 2;
		}

		const mouseMove = (event) => {
			this._hasBeenSet = true;
			const deltaY = event.pageY - startPageY;
			let newPos = startThumbPos + deltaY;
			newPos = Math.min(Math.max(newPos, 0), this._barHeight);
			activeThumb.style.top = newPos + "px";

			const percentFromTop = newPos / this._barHeight;
			const elevation = this.maxElevation - (percentFromTop * this.globalRange);
			this.legendRanges[rangeIndex] = elevation;
			this.updateGradientBar();
		}

		const mouseUp = (event) => {
			const max = Math.max(this.legendRanges[0], this.legendRanges[2]);
			const min = Math.min(this.legendRanges[0], this.legendRanges[2]);
			this.map.renderer.updateElevationRange(true, [min, max], false);

			window.removeEventListener("mousemove", mouseMove);
			window.removeEventListener("mouseup", mouseUp);
		}

		window.addEventListener("mousemove", mouseMove);
		window.addEventListener("mouseup", mouseUp);
	}

	startDraggingWindow(event): void {
		const startPageY = event.pageY;
		const startThumbOnePos = parseFloat(this.thumbOne.nativeElement.style.top);		
		const startThumbTwoPos = parseFloat(this.thumbTwo.nativeElement.style.top);

		const mouseMove = (event) => {
			this._hasBeenSet = true;
			const deltaY = event.pageY - startPageY;

			let newOnePos = startThumbOnePos + deltaY;
			let newTwoPos = startThumbTwoPos + deltaY;

			// check if there is room to move
			if(newOnePos >= 0 && newOnePos <= this._barHeight
				&& newTwoPos >= 0 && newTwoPos <= this._barHeight) {
				this.thumbOne.nativeElement.style.top = newOnePos + "px";
				this.thumbTwo.nativeElement.style.top = newTwoPos + "px";
			
				this.legendRanges[0] = this.maxElevation - ((newOnePos / this._barHeight) * this.globalRange);
				this.legendRanges[2] = this.maxElevation - ((newTwoPos / this._barHeight) * this.globalRange);
			}
			this.updateGradientBar();
		}

		const mouseUp = (event) => {
			const max = Math.max(this.legendRanges[0], this.legendRanges[2]);
			const min = Math.min(this.legendRanges[0], this.legendRanges[2]);
			this.map.renderer.updateElevationRange(true, [min, max], false);

			window.removeEventListener("mousemove", mouseMove);
			window.removeEventListener("mouseup", mouseUp);
		}

		window.addEventListener("mousemove", mouseMove);
		window.addEventListener("mouseup", mouseUp);
	}
}