import { MODEL_STATUS_EXPLANATION_MISSING, MODEL_STATUSES } from "./constants";
import { constructPartialExplination } from "./constructPartialExplination";
import { isLayerRendered } from "./isLayerRendered";
import { hasValidSource } from "./hasValidSource";

/**
 *
 * @param {{ cesium_files: [], potree_files: [], geotiffs: [] }} model
 * @returns string;
 */
export function getModelStatusFromLayers(model) {
	if (model.status !== MODEL_STATUSES.rendered) {
		return model.status;
	}

	const { geotiffs, potree_files, cesium_files } = model;

	const validLayerCount =
		isLayerRendered(geotiffs.filter(hasValidSource)) +
		isLayerRendered(potree_files.filter(hasValidSource)) +
		isLayerRendered(cesium_files.filter(hasValidSource));

	return [
		MODEL_STATUSES.missing,
		MODEL_STATUSES.partial,
		MODEL_STATUSES.partial,
		MODEL_STATUSES.rendered
	][validLayerCount];
}

/**
 *
 * @param {{ cesium_files: [], potree_files: [], geotiffs: [] }} model
 * @returns string;
 */
getModelStatusFromLayers.beta = (model) => {
	// model status should be calculated via checking the statuses of each layer,
	// similar to how it is done in verifyAndUpdateModelStatuses()

	const { geotiffs, potree_files, cesium_files } = model;

	const validLayerCount =
		isLayerRendered(geotiffs) +
		isLayerRendered(potree_files) +
		isLayerRendered(cesium_files);

	/* booleans get coerced to 0 and 1.
	 * `isLayerRendered` will only return 1 if all of the given layers are "Rendered"
	 * so we can forcibly add the result of `isLayerRendered` to get at total status key
	 */
	return [
		model.status === MODEL_STATUSES.rendered
			? MODEL_STATUSES.missing
			: model.status, // 0 + 0 + 0
		MODEL_STATUSES.partial, // 0 + 1 + 0, 1 + 0 + 0, 0 + 0 + 1
		MODEL_STATUSES.partial, // 1 + 1 + 0, 1 + 0 + 1, 0 + 1 + 1
		MODEL_STATUSES.rendered // 1 + 1 + 1
	][validLayerCount];
};

export function verifyAndUpdateModelStatuses(models, betaLayersEnabled) {
	if (!models || !models.length) {
		return [];
	}
	models.forEach((model) => {
		model.statusName = betaLayersEnabled
			? getModelStatusFromLayers.beta(model)
			: getModelStatusFromLayers(model);

		if (model.statusName === MODEL_STATUSES.missing) {
			model.statusExplanation = MODEL_STATUS_EXPLANATION_MISSING;
		} else if (model.statusName === MODEL_STATUSES.partial) {
			model.enabled = true;

			model.statusExplanation = constructPartialExplination(
				model,
				betaLayersEnabled
			);
		} else if (model.statusName === MODEL_STATUSES.rendered) {
			model.enabled = true;
		}
	});
	return models;
}
