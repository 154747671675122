<ng-container *ngIf="orgControl && orgControl.value">
	<button mat-button class="rounded-menu" [matMenuTriggerFor]="storageMenu">
		<mat-icon style="color: #ED4C16; margin-right: 8px;">storage</mat-icon>
		<span class="H7Black700Regular">{{bytesToGigabytes(orgControl.value.size)}} GB of
			{{orgControl.value.storage}} GB used</span>
	</button>

	<mat-menu #storageMenu class="mat-menu medium-menu">
		<ng-template matMenuContent>
			<span class="rounded-menu-container" (click)="$event.stopPropagation();">
				<div class="LabelBlack500 uppercase" style="margin-bottom: 8px;">Storage</div>

				<mat-form-field appearance="fill" *ngIf="orgList.length >= 1">
					<mat-select primary
						class="H6Black700Regular" [formControl]="orgControl">
						<mat-select-trigger>
							{{orgControl.value.name}}
						</mat-select-trigger>
						<mat-option *ngFor="let org of orgList" [value]="org" data-testid="storage-orgList">
							<span>
								<div class="H6Black700Regular" data-testid="option-title">{{org.name}}</div>
							<div class="H7Black500Regular">{{org.total_storage ? org.total_storage : '0'}} GB</div>
							</span>
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div class="H3Black700">{{bytesToGigabytes(orgControl.value.size)}} GB of {{orgControl.value.storage}}
					GB used</div>

				<div class="rect-container">
					<span class="rect-full"></span>
					<span class="rect-storage"
						[ngStyle]="{'width': storagePercentage(orgControl.value.storage, bytesToGigabytes(orgControl.value.size)) }"></span>
				</div>

	<!--			 TODO: Reimplement when Subscription management is added -->
				<div style="margin-bottom: 8px; display: flex;" *ngIf="orgControl.value.orgPlan">
					<span class="H7Black700Regular">Total storage: </span>
					<span class="H7Black700Semibold" style="margin-left: auto;">{{orgControl.value.storage}} GB</span>
				</div>

				<div style="margin-bottom: 32px; display: flex;">
					<span class="H7Black700Regular">Remaining storage:</span>
					<span class="H7Black700Semibold"
						style="margin-left: auto;">{{orgControl.value.storage - bytesToGigabytes(orgControl.value.size)}}
						GB</span>
				</div>

				<!-- TODO: Reimplement when Subscription management is added -->
				<!-- <button mat-stroked-button color="primary" style="margin-bottom: 8px; width: 100%;" *ngIf="orgControl.value.orgPlan?.plan_id > 0"
					(click)="openStoragePurchaseModal(orgControl.value)" matMenuClose>Purchase storage</button>
				<div class="CenterP2Black500" *ngIf="!orgControl.value.orgPlan || orgControl.value.orgPlan.plan_id === 0"><span class="link CenterP2Blue"
					(click)="openUpgrade(orgControl.value)">Subscribing to Mapware</span> will provide more storage to
				use.</div> -->

				<div *ngIf="isMobile" class="back-button">
					<button mat-stroked-button color="primary" (click)="menuTrigger.closeMenu();">Back</button>
				</div>
			</span>
		</ng-template>
	</mat-menu>
</ng-container>
