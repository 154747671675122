/* Imports */
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Alert, Upload, GCP, Project } from '../../shared/models';
import { AlertService, FileService, ProjectService, GCPService, AuthenticationService, OrganizationService, UtilsService, UploadService, isObject } from '../../shared/services';
import {spreadsheetFileTypes} from '@shared/constants';
import { SelectionModel } from '@angular/cdk/collections';

const epsgPrimaryOptions = [
	{value: 'latitude', text: 'Latitude'},
	{value: 'longitude', text: 'Longitude'},
	{value: 'altitude', text: 'Altitude'}
];
const epsgSecondaryOptions = [
	{value: 'northing', text: 'Northing'},
	{value: 'easting', text: 'Easting'},
	{value: 'elevation', text: 'Elevation'}
];

@Component({
	selector: 'app-file-handler',
	templateUrl: './file-handler.modal.html',
	styleUrls: ['./file-handler.modal.scss']
})
export class FileHandlerModal implements OnInit {

	@ViewChild('fileInput') uploadInput: ElementRef;

	public isObject = isObject;

	public selection = new SelectionModel<any>(true, []);
	public epsgPositionOptions = epsgPrimaryOptions;

	public fileList: Array<any> = [];
	public gcpList: Array<any> = [];
	public fileType: string = 'scale_constraint';
	public displayFile: any = null;
	public project: Project = null;
	public processName: string = null;
	public dataSource: MatTableDataSource<any> = new MatTableDataSource();

	public dataColumns: Array<string> = [];
	public suggestedColumns: Array<string> = [];

	public positionUnit: 'ft' | 'sft' | 'm' = 'ft';
	public epsgControl: FormControl = new FormControl(null, Validators.required);
	public epsgsOptions: any[] = [];
	public filteredEpsgOptions: Observable<any[]>;
	public mapView: boolean = false;

	public altitudeUnit: 'ft' | 'sft' | 'm' = 'ft';
	public parsingIsValid: boolean = false;
	public outHeaders: Array<any> = [];

	public coordinate_system: Object = {};

	acceptedFileTypes = spreadsheetFileTypes;
	orgId: number;

	constructor (
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _fileService: FileService,
		private _orgService: OrganizationService,
		private _projectService: ProjectService,
		private _utilService: UtilsService,
		private _cdr: ChangeDetectorRef,
		private _gcpService: GCPService,
		public _dialogRef: MatDialogRef<FileHandlerModal>,
		private _uploadService: UploadService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {

		this.project = data.project;
		this.processName = data.processName ? data.processName : null;
		this.orgId = data.orgId
		this._utilService.getEPSGList().then(rtn => {

			this.epsgsOptions = Object.entries(rtn.coordinate_systems);
			this.filteredEpsgOptions = this.epsgControl.valueChanges
				.pipe(
					startWith(''),
					map(name => name ? this.filterEpsg(name) : this.epsgsOptions ? this.epsgsOptions.slice(0, 15) : [])
				);

			this.handleFiles(data.fileList);
		});

	}	// End-of constructor

	ngOnInit() {

		this._dialogRef.beforeClosed().subscribe(() => {
			this._dialogRef.close({fileList: this.fileList, gcpList: this.gcpList});
		})

	}	// End-of ngOnInit


	onAutocomplete(e): void {
		this._utilService.getEPSGByID(parseInt(e.option.value)).then(rtn => {
			if (!rtn.unit.startsWith('degree')) {
				this.epsgPositionOptions = epsgSecondaryOptions;
				this.positionUnit = rtn.unit.startsWith('met') ? 'm' :
					rtn.unit.startsWith('US') ? 'sft' : 'ft';
				this.displayFile.units = rtn.unit.startsWith('met') ? 'm' : 'ft';
			}
		});
	}


	filterEpsg(name: string): any {
		if (typeof name === "string") {
			let filterValue = name.toLowerCase();

			return this.epsgsOptions.filter(option => {
				return option[0].toLowerCase().includes(filterValue) || option[1].toLowerCase().includes(filterValue)
			}).slice(0, 15);
		} else {
			return null;
		}

	}	// End-of filterEpsg


	filesChanged(files: Array<any>): void {
		if (files.length > 0) {
			this._uploadService.__uploadFiles({
				files,
				project_id: this.project.id,
				org_id: this.orgId,
				batch_id: this.project.batches[0] ? this.project.batches[0].id : null
			}).then(uploadedFiles => {
				this.handleFiles(uploadedFiles);
			}).catch(e => {
				console.error(e);
				this._alertService.notify("We're sorry. Something went wrong with the upload. Please reload the page and try again.", "error")
			});
		}

	}	// End-of filesChanged


	handleFiles(fileList: Array<any>): any {
		fileList.forEach(file => {
			file.fileType = 'uncategorized';
			file.units = 'ft';

			this._projectService.getFile(file.id).then(rtn => {
				this._fileService.parseCSV(rtn).then(rtnFile => {
					this._fileService.convertToTableData(rtnFile)
					.then(rtnData => {
						file.data = rtnData;
					})
				});
			})
		})

		// Remove duplicates, using the newest version always
		this.fileList = this.fileList.filter(x => {
			return !fileList.find(file => file.id === x.id);
		})

		this.fileList = this.fileList.concat(fileList);
	}


	processFile(file): Promise<any> {

		return new Promise((resolve: Function) => {
			// Reader
			let reader = new FileReader();
			reader.readAsText(file);
			reader.onload = (e) => {

				let loadedFile = {name: file.name, size: file.size, type: file.type, data: e.target["result"]};

				if (file.size <= 5000000) { // Limit to 5MB

					this._fileService.parseCSV(loadedFile.data).then(rtnFile => {
						this._fileService.convertToTableData(rtnFile)
						.then(rtnData => {
							file.data = rtnData;
						})
					});
				} else {
					resolve(loadedFile);
				}
			}
		})

	}	// End-of processSelectedFile


	submitFile(file, selection): void {

		let newFile = $.extend({}, file);
		newFile.epsg = this.epsgControl.value;
		this.epsgControl.setValue(null);

		if (newFile.epsg && this.outHeaders.length >= 3) {
			let promises = [];
			selection.selected.forEach((data, index) => {
				promises.push(this.createGCP(data, newFile, this.outHeaders, index));
			})
			Promise.all(promises.map(p => p.catch(e => e)))
				.then(data => {
					this.gcpList = data.filter(x => {return (x && (typeof x === "object"))});
					this.gcpList.forEach(x => {x.gcps_images = [];}) // This is a field that is populated later, if we don't initialize it now it'll break.
					this._dialogRef.close({fileList: this.fileList, gcpList: this.gcpList});
				})
				.catch(e => console.error(e));
		}
	}


	createGCP(fileData, file, headers, index): Promise<any> {
		return new Promise((resolve: Function,  reject: Function) => {

			let markFailed = false;

			let gcpParams = new GCP({
				project_id: this.project.id,
				name: file.name.replace(/(\..*)/, `-${index}`),
				units: this.altitudeUnit,
				coordinate_system: {
					epsg: file.epsg[0],
					description: file.epsg[1],
				},
			})

			headers.forEach(head => {
				if (!fileData[head.index]) {markFailed = true;}
				// Parse value to floating point number if not labeled as 'name', and therefore text
				let val = (head.value === 'name') ? fileData[head.index] : parseFloat(fileData[head.index]);
				Object.assign(gcpParams, {[head.value]: val})
			})

			if (!markFailed) {
				this._gcpService.create(gcpParams).then(rtn => {
					resolve(rtn);
				}, err => {
					reject(err);
				});
			} else {
				reject(null);
			}
		})
	}

	showFile(file): void {
		if (!file && this.displayFile) {
			let ind = this.fileList.findIndex(x => x.id === this.displayFile.id);
			this.epsgControl.setValue(null);
			this.fileList[ind] = this.displayFile;
		} else {
			this.dataColumns = file.data.displayedColumns;
			this.suggestedColumns = file.data.suggestedColumns;
			this.dataSource.data = file.data.parsedData;
		}
		this.displayFile = file;
	}


	removeFile(ind): void {
		const removeFile = this.fileList.splice(ind, 1);
		this._projectService.trashFile(removeFile[0]).then(rtn => {
			this._alertService.success(new Alert('File was successfully deleted'))
		}).catch(err => {
			this._alertService.warning(new Alert('File could not be deleted'))
		});
	}


	displayOptions(option: any): string {
		return option ? option[1] : null;
	}

	public showMapView(): void {
		this.coordinate_system = {
			epsg: this.epsgControl.value[0],
			description: this.epsgControl.value[1],
		}
		this.mapView = true;
	}

	setValidation(e): void {
		this.parsingIsValid = e;
		this._cdr.detectChanges();
	}

}	// End-of class TestComponent
