import { Component } from '@angular/core';
import { environment } from '@/environments/environment';
import { UtilsService } from '@/app/shared/services';

@Component({
	selector: 'app-contact-component',
	templateUrl: 'contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

	constructor() { }

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}
}
