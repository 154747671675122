
/* Imports */
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { byId, AdminService, AuthenticationService, OrganizationService, FinanceService, UtilsService, convertTermToDays } from '@shared/services';
import { Organization } from '@shared/models';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import pluralize from 'pluralize';

import { MatDialog } from '@angular/material/dialog';

interface trialClass {
	className: string;
	minDays: number;
}

const trialColors: trialClass[] = [
	{className: "trial-green", minDays: 5},
	{className: "trial-yellow", minDays: 3},
	{className: "trial-red", minDays: 0},
];

const defaultTrial = trialColors[trialColors.length - 1].className;
const accountTab = 'plan';

@Component({
	selector: 'app-trial',
	templateUrl: './trial.component.html',
	styleUrls: ['./trial.component.scss']
})

export class TrialComponent implements OnInit {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@Input('organizationId') orgId: any;
	@Output('upgradeAccount') upgradeAccount: EventEmitter<any> = new EventEmitter();

	public orgList: Array<any>;
	public user: any;
	public orgControl: FormControl = new FormControl();

	public trialDaysRemaining: number;
	public showTrial: boolean = false;
	public isMobile: boolean = false;
	public overrideTrial: number = null;

	public activeOrg: Organization = null;
	public pluralize = pluralize;
	public isProcessing = true;

	constructor (
		public dialog: MatDialog,
		private _adminService: AdminService,
		private _utilsService: UtilsService,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService,
		private _financeService: FinanceService,
		private _cdr: ChangeDetectorRef
	) {
	}

	ngOnInit() {

		this.isMobile = this._utilsService.getIsMobile();
		this.user = this._authService.user;

		this._adminService.getOverrideChanged().subscribe(rtn => {
			this.setup();
		})

	}	// End-of ngOnInit

	getAllOrgData(orgList) {
		orgList = orgList.filter(x => !x.isFromProjectShare);
		orgList.forEach(this._orgService.getOrgData.bind(this));
		return orgList;
	}

	setActiveOrg(orgList) {
		const default_organization =
			orgList.find(byId(this.user.default_organization_id)) || orgList[0];
		this.orgControl.setValue(default_organization);
		this.activeOrg = default_organization;
		this.trialDaysRemaining = this.getSubDaysRemaining(this.activeOrg)
		return orgList;
	}

	getSubDaysRemaining = (org: Organization) =>
		convertTermToDays(org.subscription?.current_term_end);

	setup(): void {
		this._orgService
			.getList()
			.then(this.getAllOrgData.bind(this))
			.then(this.setActiveOrg.bind(this))
			.then(rtnList => {
				this.isProcessing = false;
				this.orgList = rtnList;
				this._cdr.detectChanges();
			})
			.catch(console.error); // Should we give the user some indication as to what went wrong and what they should do next?
	}

	getTrialClass = (daysRemaining) =>  (
		trialColors
			.find(x =>
				daysRemaining > x.minDays
			)
			?.className ?? defaultTrial
		);

	isInTrial = (org: Organization) =>
			org?.subscribed && org.subscription?.plan_id === "free-trial";

	openUpgrade(): void {
		this.upgradeAccount.emit(accountTab)
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

}	// End-of class TestComponent
