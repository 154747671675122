import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { GlobalService } from "@shared/services";

const baseQuery = fetchBaseQuery({
	baseUrl: GlobalService.databaseApiUrl,
	prepareHeaders: (headers) => {
		const user = GlobalService?.authenticationService?.user;
		if (user && user.api_key) {
			headers.set("x-api-key", GlobalService.authenticationService.token);
		}
		headers.set("Content-Type", "application/json");
		return headers;
	},
});

export default baseQuery;
