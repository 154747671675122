import { isAFunction, isAnObject, noop, configValidationError } from "./index.js";


/**
 *
 * @type {FunctionConfig}
 */
export const defaultConfig = {
    adapter: undefined,
    func: undefined,
    deprecating: false,
    forceSync: false
};

export const deprecatedFunc = (config) => {
    const { alt, date } = isAnObject(config.deprecated)
        ? config.deprecated
        : {};

    return () => {
        console.warn(
            `${config.name} is being deprecated.`,
            date && `It will no longer be available as of ${date}.`,
            alt && `Please use ${alt} instead.`
        );
        return noop();
    };
};

/**
 *
 * @param {FunctionConfig|LayerFunc} config
 * @returns {FunctionConfig}
 */

export const normalizeFunctionConfig = (config = {}) => {
    // If the config is a function, return the function inside an object
    if (isAFunction(config)) {
        return {
            ...defaultConfig,
            func: config
        };
    }

    if (!config.func) throw configValidationError;

    // If deprecating, return the "deprecatedFunc" function, else return the function
    let maybeDeprecatedFunc = config.func;
    if(config && config.deprecating)
        maybeDeprecatedFunc = deprecatedFunc(config)
    
    return {
        ...defaultConfig,
        ...config,

        
        func: maybeDeprecatedFunc
    };
};
