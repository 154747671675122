/* Imports */
import { Component, Input, Output, TemplateRef, OnInit, ViewChild, EventEmitter, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertService } from '@app/shared/services/';
import _ from 'lodash';
import { sortByNewestToOldest } from '@app/shared/services/utils.service';
import {VirtualScrollerComponent} from "@iharbeck/ngx-virtual-scroller";

const gridWidthMin = 400 + 2*3*8; // Min width of grid items (400px = width, 2 * 3rem = 2*3*8px)

@Component({
	selector: 'app-sections',
	templateUrl: './sections.component.html',
	styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit, AfterContentInit {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
	@ViewChild("scroll") virtualScroller: VirtualScrollerComponent;

	@Input("type") type: string = null;
	@Input("itemTemplate") itemTemplate: TemplateRef<any>;
	@Input("headerTemplate") headerTemplate: TemplateRef<any>;
	@Input("menuContent") menuContent: TemplateRef<any>;
	@Input("headerContent") headerContent: TemplateRef<any>;
	@Input("sectionClass") sectionClass: Object = {};
	@Input() updateSectionFunction: Function;
	@Input('sections') set inSections (sections) {
		this.sections = sections;
		this.openSection = sections.length > 1 ? null : 0;
		this.prepareSections(sections);
	};

	@Input('gridView') set gridView (view) {
		this.showGrid = view;
		// Refresh on view change, grid takes up more space for fewer items so the view can have empty items
		this.virtualScroller?.refresh();
		this.prepareVirtualScroll();
	};
	@Input('organization_id') organization_id: number = null;
	@Input('showOptions') showOptions: boolean = true;
	@Input('showSelect') showSelect: boolean = false;

	@Output('sectionEvent') sectionEvent: EventEmitter<any> = new EventEmitter();

	public sections: Array<any> = [];
	public editSection: boolean = false;
	public openSection: number = null;
	public sectionLookup: any = {};
	public isUsingService: boolean = false;
	public showGrid: boolean = false;

	public virtualRowHeight: number = 108;
	public gridWidth: number = 100/3; // Width of grid items

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _alertService: AlertService,
		private _cdr: ChangeDetectorRef
	) {

	}	// End-of constructor

	ngOnInit(): void {

		$(window).on('resize', _.debounce(
			() => {
				// Need to ensure the resize is caught
				if (this.showGrid) this.prepareVirtualScroll();
			}, 200)
		)

	}	// End-of ngOnInit

	ngAfterContentInit() {
		this.prepareVirtualScroll();
	}

	renameSection(): void {
		this.editSection = true;
	}

	prepareSections(sections): void {
		this.sections = sections.sort(sortByNewestToOldest);
	}

	prepareVirtualScroll(): void {
		this.calculateGridWidth();
		// Angular doesn't detect a change to a variable and update immediately, only after a certain period of time or a forced update
		this._cdr.detectChanges();
	}

	handleSectionEvent(event, index): void {
		if (this.isUsingService && event.type === 'delete') {
			this.sectionRemoved(index);
		} else {
			this.sectionEvent.emit(event);
		}
	}

	sectionRemoved(index): void {
		this.sections.splice(index, 1);
	}

	toggleOpenSections(val: boolean, index: number): void {
		if (val && index >= 0) {
			this.scrollToTop();
			this.sectionEvent.emit({type: 'toggle', section: index})
		}
		this.openSection = val ? index : null;
	}

	// Used to inform the virtual scroll about the size of the items being virtualized
	calculateGridWidth() {
		const container = $('#virtual-scroller-container');
		const nCols = this.calculateColumns(container);
		this.gridWidth = 100 / nCols;
	}

	calculateColumns(container) {
		let nCols = 3;
		if (container?.[0]) {
			const scrollWidth = container.width(); // Width of the parent container
			nCols = Math.floor(scrollWidth / gridWidthMin); // Important, for dividing the height by this to simulate a smaller object
		}
		return isFinite(nCols) ? nCols : 3;
	}

	scrollToTop(): void {
		const mainContainer = $('#main');

		// .length checks for jquery result
		if (mainContainer?.length) {
			mainContainer.animate({scrollTop : 0} ,800);
		}
	}

	setCellWidthStyle(showGrid) {
		return showGrid ? { width: this.gridWidth + '%' } : {};
	}

}
