
<div class="dialog-base">
	<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
	<div class="title">New ground control point</div>

	<div class="step-container">
		<span class="step-left">
			<form [formGroup]="gcpFormGroup">

				<div class="form-title">Coordinate System</div>

				<app-coordinate-system
					[epsgControl]="epsgControl"
					(outEvent)="handleCoordinateChange($event);">
				</app-coordinate-system>

				<div class="form-title">Ground control point name</div>
				<mat-form-field appearance="fill">
					<input matInput
							 data-testid="name"
							 type="text"
							 placeholder="Enter name"
							 formControlName="name"
							 autocomplete="off"
							 autofocus>
				</mat-form-field>

				<ng-container *ngIf="showAngleEPSG">

					<div class="form-title">Latitude</div>
					<mat-form-field appearance="fill">
						<input matInput
								 data-testid="lat"
								 type="number"
								 placeholder="Enter number"
								 formControlName="latitude"
								 autocomplete="off"
								 autofocus>
						<span class="input-tip">deg</span>
					</mat-form-field>

					<div class="form-title">Longitude</div>
					<mat-form-field appearance="fill">
						<input matInput
								 data-testid="lon"
								 type="number"
								 placeholder="Enter number"
								 formControlName="longitude"
								 autocomplete="off"
								 autofocus>
						<span class="input-tip">deg</span>
					</mat-form-field>

					<div class="form-title">Altitude</div>
					<div class="altitude-container">
						<mat-form-field appearance="fill" class="altitude-value">
							<input matInput
								   data-testid="altitude"
								   type="number"
								   placeholder="Enter number"
								   formControlName="altitude"
								   autocomplete="off"
								   autofocus>
						</mat-form-field>
						<mat-form-field appearance="fill" class="altitude-units">
							<mat-select placeholder="Choose Units" [(value)]="altitudeUnit" [disabled]="epsgControl.invalid">
								<mat-option *ngFor="let option of altitudeUnitList" [value]="option.value"><span class="option-text">{{option.text}}</span></mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</ng-container>

				<ng-container *ngIf="!showAngleEPSG">
					<div class="form-title">Northing</div>
					<mat-form-field appearance="fill">
						<input matInput
								 data-testid="northing"
								 type="number"
								 placeholder="Enter number"
								 formControlName="northing"
								 autocomplete="off"
								 autofocus>
						<span class="input-tip">{{positionUnit}}</span>
					</mat-form-field>

					<div class="form-title">Easting</div>
					<mat-form-field appearance="fill">
						<input matInput
								 data-testid="easting"
								 type="number"
								 placeholder="Enter number"
								 formControlName="easting"
								 autocomplete="off"
								 autofocus>
						<span class="input-tip">{{positionUnit}}</span>
					</mat-form-field>

					<div class="form-title">Elevation</div>
					<div class="altitude-container">
						<mat-form-field appearance="fill" class="altitude-value">
							<input matInput
								   data-testid="altitude"
								   type="number"
								   placeholder="Enter number"
								   formControlName="altitude"
								   autocomplete="off"
								   autofocus>
						</mat-form-field>
						<mat-form-field appearance="fill" class="altitude-units">
							<mat-select placeholder="Choose Units" [(value)]="altitudeUnit" [disabled]="epsgControl.invalid">
								<mat-option *ngFor="let option of altitudeUnitList" [value]="option.value"><span class="option-text">{{option.text}}</span></mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</ng-container>
			</form>
		</span>

		<span class="step-right">
			<div class="tip-header">Helpful tips</div>
			<div *ngFor="let tip of tipText;" class="tip-container">
				<div class="tip-title">{{tip.title}}</div>
				<div class="tip-text">{{tip.text}}</div>
			</div>
		</span>
	</div>

	<div class="confirmation">

		<button mat-stroked-button color="primary"
				matDialogClose
				class="cancel">
			Cancel
		</button>

		<button mat-stroked-button color="primary"
				(click)="createGCP();"
				[disabled]="gcpFormGroup.invalid || epsgControl.invalid"
				class="confirm">
			Create
		</button>
	</div>
</div>

<ng-template #t_anglePosition>
</ng-template>

<ng-template #t_distancePosition>
</ng-template>
