import L from "leaflet";

import { AnnotationType } from "../../../viewer/classes/measure";
import { LabelSetAnnotation } from "./labelSetAnnotation";

export class PolyLabelSet extends LabelSetAnnotation {

	name = "New Polygon Label Set";
	type = AnnotationType.POLY_LABEL_SET;

	public tempPoly: L.Polygon;

	constructor(
		params = {},
		labels: [] = []
	) {
		super();
		Object.assign(this, params);
		this.labels = labels;
	}

	render(): void {
		this.labels?.forEach( (label) => {
			label.leafletLayer = L.polygon(label.coordinates, {color: this.color, weight: 2, fill: (this.color + '44')}).addTo(this.layer);
		});
	}

	highlight(highlightedLabel) {
		if (this.layer) {
			this.tempPoly = L.polygon(highlightedLabel.coordinates,
				{color: this.color, weight: 4, fill: ''}).addTo(this.layer);
		}
	}

	unhighlight() {
		if (this.layer) {
			this.layer.removeLayer(this.tempPoly)
		}
	}

	getLabelCoordinates(label) {
		// TODO - get the center point of the all coordinates
		
		// for now, just return the first point coordinates
		return label.coordinates[0];
	}
}