
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';
import {makeURLParams} from '@shared/services/utils.service';

@Injectable()
export class ShareService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor

	public getByProjectIdV2(project_id: number): Promise<any> {

		const searchParams = makeURLParams({project_id});
		let url = '/v2/shares' + searchParams;
		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [GETLIST]
	*
	*/
	public getList(): Promise<any> {

		let url = '/shares';
		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [CREATE]
	*
	* @param {Share} share		Share data
	*/
	public create(share: Share): Promise<any> {

		let url = '/shares';
		return this.httpService.post(url, share);

	}	// End-of create

	/**
	* [UPDATE]
	*
	* @param {Share} share		Share data
	*/
	public update(share: Share): Promise<any> {

		let url = '/shares/' + share["id"];
		return this.httpService.put(url, share);

	}	// End-of update

	/**
	* [REMOVE]
	*
	* @param {Share | number} share		Share data or id
	*/
	public remove(share: Share | number): Promise<any> {

		let url = '/shares/' + ((typeof share === 'object') ? share["id"] : share);
		return this.httpService.delete(url);

	}	// End-of remove

	/**
	* [JOINSHARE]
	*
	* @param {string} guid			Share GUID
	*/
	public joinShare(guid: string): Promise<any> {

		let url = '/shares/join?guid=' + guid;
		return this.httpService.post(url, null);

	}	// End-of joinShare

}	// End-of class ShareService

export interface Share {
	project_id?: number;
	email?: string;
	permissions?: string;
}
