
<div class="project-table">

	<ng-container *ngTemplateOutlet="t_menuTemplate"></ng-container>

	<mat-table *ngIf="dataSource?.data?.length" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="desc" matSortDisableClear class="table-content">
		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<mat-header-cell style="min-width: 240px;" *matHeaderCellDef mat-sort-header disableClear> File name </mat-header-cell>
			<mat-cell style="min-width: 240px;" *matCellDef="let row">
				<span class="title-text" matTooltip="Download" (click)="download(row);">{{row.name}}</span>
				<mat-icon matTooltip="Favorited" *ngIf="row.favorited"
				style="margin: auto 8px; cursor: pointer; color: #6a7483;">star_border</mat-icon>
			</mat-cell>
		</ng-container>

		<!-- Exported by Column - Commentting out until we can get the requested by user fixed - Nic 7/26/22
		<ng-container matColumnDef="created_by">
			<mat-header-cell class="phone-hidden" style="max-width: 248px;" *matHeaderCellDef mat-sort-header disableClear> Exported by </mat-header-cell>
			<mat-cell class="phone-hidden ellipsis" style="max-width: 248px;" *matCellDef="let row"> {{row.created_by}}</mat-cell>
		</ng-container>
		-->

		<!-- Type Column -->
		<ng-container matColumnDef="filetype">
			<mat-header-cell style="max-width: 248px;" *matHeaderCellDef mat-sort-header disableClear> Type </mat-header-cell>
			<mat-cell style="max-width: 248px;" *matCellDef="let row"> {{row.filetype ? (row.filetype) : '---'}} </mat-cell>
		</ng-container>

		<!-- Size Column -->
		<ng-container matColumnDef="size">
			<mat-header-cell class="phone-hidden" style="max-width: 124px;" *matHeaderCellDef mat-sort-header disableClear> Size </mat-header-cell>
			<mat-cell class="phone-hidden" style="max-width: 124px;" *matCellDef="let row"> {{row.size ? (row.size | bytes) : '---'}} </mat-cell>
		</ng-container>

		<!-- Created on Column -->
		<ng-container matColumnDef="created_at">
			<mat-header-cell class="phone-hidden" style="max-width: 248px;" *matHeaderCellDef mat-sort-header disableClear> Created on </mat-header-cell>
			<mat-cell class="phone-hidden" style="max-width: 248px;" *matCellDef="let row"> {{row.created_at ? (row.created_at | date) : '---'}}</mat-cell>
		</ng-container>

		<!-- Options Column -->
		<ng-container matColumnDef="options">
			<mat-header-cell *matHeaderCellDef style="max-width: 80px;"> </mat-header-cell>
			<mat-cell *matCellDef="let row; let i = index"
				style="max-width: 80px; cursor: pointer;">

				<button mat-icon-button tooltip="More" (click)="openMenu($event, i, row); $event.stopPropagation();">
					<mat-icon class="list-item-icon">more_horiz</mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row [ngClass]="{'active-item' : activeIndex === i}" *matRowDef="let row; let i = index; columns: displayedColumns;" (contextmenu)="openMenu($event, i, row)" (mouseover)="over(row.id)" (mouseout)="out()"></mat-row>
	</mat-table>
	<!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->

	<div *ngIf="!dataSource?.data?.length" class="project-info">
		<mat-icon class="large-icon">layers</mat-icon>

		<div *ngIf="isProcessing; else show_trial_or_empty">
			Your exported models are currently being processed. They will appear here when they are finished.
		</div>

		<!--TODO: @remove-subscription-required -->
		<ng-template #show_trial_or_empty>
			<ng-container *featureEnabled="'!remove-subscription-required'">
				<div *ngIf="trialExpired">Your trial has expired. You will not be able to process models until you start a subscription.</div>
			</ng-container>
			<ng-container *ngIf="!trialExpired">
				<div>You have not exported any models.</div>
				<div>You can export a model through the Map after it has finished processing.</div>
			</ng-container>
		</ng-template>
	</div>
</div>

<ng-template #t_menuTemplate>

	<button
			class="context-button"
			[matMenuTriggerFor]="itemMenu"
			[style.left]="contextMenuPosition.x"
			[style.top]="contextMenuPosition.y">
	</button>

	<mat-menu #itemMenu="matMenu" (closed)="onMenuClose()" class="little-menu centered-menu-text">
		<ng-template matMenuContent>
			<mat-list style="width: 100%; padding: 2px 0;">
				<mat-list-item (click)="download();">
					<mat-icon> vertical_align_bottom </mat-icon>
					<span style="margin-left: 16px;"> Download {{ activeItem?.name }} </span>
				</mat-list-item>
				<mat-list-item newSection="true" *ngIf="!activeItem?.favorited" (click)="favorite();">
					<mat-icon> star_border </mat-icon>
					<span style="margin-left: 16px;"> Add to Favorites </span>
				</mat-list-item>
				<mat-list-item newSection="true" *ngIf="activeItem?.favorited" (click)="favorite();" >
					<mat-icon> grade </mat-icon>
					<span style="margin-left: 16px;"> Remove from Favorites </span>
				</mat-list-item>
			</mat-list>
		</ng-template>
	</mat-menu>
</ng-template>
