
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import {AIModel} from '../models';
import { makeURLParams } from "./utils.service";

@Injectable()
export class AIModelService {

	constructor(
		private httpService: HttpService
	) {}

	public get(ai_model_id): Promise<any> {
		const url = `/v2/ai/model/${ai_model_id}`;
		return this.httpService.get(url);
	}

	public getList({organization_id = null}): Promise<any> {
		const searchData = { organization_id };
		const searchParams = makeURLParams(searchData);
		let url = '/v2/ai/models' + searchParams;
		return this.httpService.get(url);
	}

	public create(ai_model: AIModel): Promise<any> {
		const url = '/v2/ai/models';
		return this.httpService.post(url, ai_model);
	}

	public update(ai_model: AIModel): Promise<any> {
		const url = `/v2/ai/model/${ai_model.id}`;
		return this.httpService.put(url, ai_model);
	}

	public delete(ai_model_id): Promise<any> {
		const url = `/v2/ai/model/${ai_model_id}`;
		return this.httpService.delete(url);
	}

	public createInferenceOrder(ai_model_id, geotiff_id) {
		const url = "/v2/ai/inference";
		return this.httpService.post(url, {ai_model_id, geotiff_id});
	}

}
