
<div class="project-table">

	<ng-container *ngIf="!isLoading">
		<ng-container *ngIf="batches?.length">
			<app-sections
				type="image"
				[sections]="batches"
				[updateSectionFunction]="v2FlagEnabled ? getBatchV2 : null"
				[menuContent]="t_menuContent"
				[headerContent]="t_uploadStatus"
				[itemTemplate]="t_imageList"
				[headerTemplate]="t_tableHeaders"
				[gridView]="gridDisplay"
				[organization_id]="_project.organization_id"
				(sectionEvent)="handleSectionEvent($event);">
			</app-sections>
		</ng-container>

		<div *ngIf="!batches.length" class="project-info">
			<mat-icon class="large-icon">photo_camera</mat-icon>

			<div *ngIf="isExpired">Your subscription has expired. You will not be able to upload images until you start a subscription.</div>
			<ng-container *ngIf="!isExpired">
				<div>You have not uploaded any images. Click on ‘Upload’ to upload</div>
				<div>images to Mapware. You can upload JPEG, TIFF, or PNG files</div>
			</ng-container>
		</div>
	</ng-container>

	<div class="loading-container" *ngIf="isLoading">
		<div class="dot-stretching"></div>
	</div>
</div>

<ng-template #t_tableHeaders let-section>
	<div class="header-row phone-hidden">
		<span class="table-cell select-cell">
			<mat-checkbox
				[ngClass]="{'disabled': !section.children?.length}"
				data-testid="select-all"
				(click)="$event.stopPropagation()"
				(change)="toggleSelectAll($event, section)"
				[checked]="allChildrenSelected(section.children)"
				matTooltip="Select all">
			</mat-checkbox>
			<ng-container *ngIf="gridDisplay">Select All</ng-container>
		</span>
		<ng-container *ngIf="!gridDisplay">
			<span class="name-cell">File Name</span>
			<span class="table-cell" *ngIf="section.sensor">Bands</span>
			<span class="table-cell phone-hidden">Megapixels</span>
			<span class="table-cell phone-hidden">Size</span>
			<span class="table-cell phone-hidden">Status</span>
			<span class="table-cell phone-hidden">Date Taken</span>
			<span class="options-cell"></span>
		</ng-container>
	</div>
</ng-template>


<ng-template #t_imageList let-child let-state='state'>
	<div [ngClass]="[gridDisplay ? 'grid-item' : 'table-row']">
		<app-photo
			[srcImgBands]="srcImgBands"
			[batch]="batch"
			[child]="child"
			[index]="state.index"
			[(selection)]="selection"
			[gridDisplay]="gridDisplay"
			[favoritesList]="favoritesList"
			[imageList]="imageList"
			[disableMove]="batches.length <= 1"
			[userPermission]="userPermission"
			(photoEvent)="handlePhotoEvent($event);">
		</app-photo>

	</div>
</ng-template>


<input #fileInput data-testid="imageUpload" style="display: none;" multiple type="file" [accept]="getAcceptedImageTypesString()"
	   (change)="filesChanged($event.target.files)">


<ng-template #t_menuContent let-child>
	<!-- TODO: @remove temp-create-batches -->
	<ng-container *featureEnabled="'temp-create-batches'">
		<button mat-menu-item (click)="uploadToBatch(child);" data-testId="batch-upload">
			<mat-icon> upload </mat-icon>
			<span> Upload </span>
		</button>
	</ng-container>
	<button mat-menu-item (click)="downloadImages(child.images);" data-testId="batch-download">
		<mat-icon> download </mat-icon>
		<span> Download </span>
	</button>
	<button mat-menu-item (click)="openRenameDialog(child);">
		<mat-icon> text_format </mat-icon>
		<span> Rename </span>
	</button>
	<button mat-menu-item (click)="removeBatch(child);" data-testId="batch-trash">
		<mat-icon> delete_outline </mat-icon>
		<span> Trash </span>
	</button>
</ng-template>


<ng-template #t_uploadStatus let-child>
	<span class="upload-status" *ngIf="checkPercentage(child.percentageComplete)">
		<span class="H7Black500Regular" *ngIf="!allFilesCompleted(child)">
			Uploaded {{ child.completedFiles }} of {{ child.totalFiles }} images
		</span>
		<span class="H7Black500Regular" *ngIf="allFilesCompleted(child)">
			All images uploaded
		</span>
		<app-upload-circle *ngIf="!allFilesCompleted(child)" [percentage]="child.percentageComplete"></app-upload-circle>
		<mat-icon *ngIf="allFilesCompleted(child)">check</mat-icon>
	</span>
</ng-template>
