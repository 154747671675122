

/* Imports */
import { Component, OnInit, Inject, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { UtilsService, isObject } from '../../shared/services';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import _ from 'lodash';

// export class coordinate

@Component({
	selector: 'app-coordinate-system',
	templateUrl: './coordinate-system.component.html',
	styleUrls: ['./coordinate-system.component.scss']
})
export class CoordinateSystemComponent {

	@Input('epsgControl') epsgControl: FormControl;

	@Output() outEvent: EventEmitter<any> = new EventEmitter();

	public filteredEpsgOptions: Observable<any>;
	public isObject = isObject;

	constructor(
		private _cdr: ChangeDetectorRef,
		private _utilService: UtilsService,
		private _formBuilder: FormBuilder
	) {

		this._utilService.getEPSGList().then(({coordinate_systems}) => {

			this.filteredEpsgOptions = this.epsgControl.valueChanges.pipe(
				startWith(""),
				map((value) =>
					((typeof value === 'string') ? this.filterEpsg(value, coordinate_systems) : [])
				)
			);
		});
	}

	filterEpsg(value: string, coordinate_systems): Array<any> {

		// Regex saves resources doing testing vs toLowerCase() and a comparison
		const filterValue = new RegExp(value, "i");

		// Object.entries here creates an array of arrays from the object, it is fastest here AFTER filtering
		return Object.entries(
			// Lodash's pickBy returns a filtered object, which is programmatically faster than filtering an array
			_.pickBy(coordinate_systems, (value, key) =>
				filterValue.test(value) || filterValue.test(key)
			)
		).slice(0, 15); // Only send 15 items

	}	// End-of filterEpsg


	onAutocomplete(e): void {
		this.setEPSGType(e.option.value[0])
	}

	setEPSGType(val): void {
		const groupAngle = ['longitude', 'latitude', 'altitude'];
		const groupPos = ['northing', 'easting', 'altitude'];
		this._utilService.getEPSGByID(parseInt(val)).then(epsg => {
			const usesLatLon = epsg?.unit?.startsWith('degree'); // https://en.wikipedia.org/wiki/EPSG_Geodetic_Parameter_Dataset

			if( usesLatLon ) {
				this.outEvent.emit(
					{
						type: 'latlong',
						data: {disableGroup: groupPos, enableGroup: groupAngle}
					}
				);
			} else {
				this.outEvent.emit(
					{
						type: 'positional',
						data: {epsg, disableGroup: groupAngle, enableGroup: groupPos}
					}
				);
			}
		});
	}

	clearEPSGControl(): void {
		this.epsgControl.setValue(null);
		this.outEvent.emit(
			{
				type: null,
				data: {disableGroup: ['longitude', 'latitude', 'altitude', 'northing', 'easting']}
			}
		);
	}

	displayOptions(option: any): string {
		return option ? option[1] : null;
	}
}
