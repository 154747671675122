
import * as THREE from 'three';

export const MAP_OPTIONS = {
	BACKGROUND_COLORS: {
		DARK: new Cesium.Color(4 / 256, 10 / 256, 20 / 256, 1.0),
		LIGHT: new Cesium.Color(207 / 256, 216 / 256, 220 / 256, 1.0),
	},
	BASEMAP_OPTIONS: [
		{
			id: "streets-v11",
			title: "Street",
		},
		{
			id: "satellite-v9",
			title: "Satellite",
		},
		{
			id: "outdoors-v11",
			title: "Terrain",
		},
		{
			id: "dark-v10",
			title: "Dark",
		},
	],
	DISPLAY_UNITS: [
		{
			id: "m",
			name: "meters",
			metersConversion: 1
		},
		{
			id: "ft",
			name: "feet",
			metersConversion: 3.28084
		}
	],
	QUALITY_OPTIONS: [
		{
			maxScreenSpaceError: 2,
			name: "low",
		},
		{
			maxScreenSpaceError: 1,
			name: "medium",
		},
		{
			maxScreenSpaceError: 0.5,
			name: "high",
		},
		{
			maxScreenSpaceError: 0.1,
			name: "ultra",
		},
	],
	ZOOM_POWER: {
		THREE_D: 0.32, // 32% zoom in / out
		TWO_D: 0.8
	},
	LAYER_GROUP_COLORS: [
		"#3B88F2", // blue
		"#6DD66B", // green
		"#F24B4B", // red
		"#8E5FDA", // violet
		"#EE9328", // orange
		"#59EFCB", // cyan
		"#E276E4", // pink
		"#EDF069", // yellow
		"#407A7A", // dark teal
		"#962136", // maroon
		"#72801E", // olive
		"#1E167E", // navy
	],
	geometry: {
		imageSphereGeometry: new THREE.SphereGeometry( 2, 12, 12),
		sphereGeometry: new THREE.SphereGeometry( 5, 12, 12)
	},
	materials: {
		lineMaterial: new THREE.LineBasicMaterial({
			color: 0x00ffff,
			linewidth: 5
		}),
		meshMaterial: new THREE.MeshBasicMaterial({
			color: 0x00ffff,
			side: THREE.DoubleSide
		}),
		imageSphereMaterial: new THREE.MeshBasicMaterial( {
			color: 0xffffff,
		} ),
		sphereMaterial: new THREE.MeshBasicMaterial( {
			color: 0x00ffff,
		} )
	}
};
