import { Url } from 'url';
import { RouterLink, NavigationExtras } from '@angular/router';

export class Alert {

	message: string | AlertMessageObject[];		// Message content
	icon: string;
	duration: number;    // Length message is displayed
	actionCallback: any;	// Function callback
	messageLabel: string; // Optional label for secondary function
	messageUserId: any;
	keep: boolean;	// Keep after navigation change
	type: string;		// Type of alert
	useComponent: boolean;
	display: boolean; // Set if alert is active
	isSimple: boolean; // If message is simple string

	constructor(
		message: string | AlertMessageObject[],
		icon: string = null,
		duration: any = 7000,
		messageLabel: string = '',
		useComponent: boolean = true,
		linkRoute: any = null,
		queryParams: any = null,
	) {

		this.message = message;
		this.icon = icon;
		this.duration = duration;
		this.messageLabel = messageLabel;
		this.useComponent = useComponent;

	}	// End-of constructor

} // End-of AlertModel

export interface AlertMessageObject {
	text: string;
	isLink: boolean;
	linkRoute?: Array<any>;
	queryParams?: NavigationExtras['queryParams'];
}
