

/* Interface for ScaleConstraint */
export class ScaleConstraint {
	public id: number;
	public name: string;
	public units: string = 'ft';
	public distance: number = -1;
	public project_id: string;
	public point_1_name: string = 'Point A';
	public point_2_name: string = 'Point B';

	public images?: Array<ScaleConstraintImage>;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
	
}	// End-of ScaleConstraint


/* Interface for ScaleConstraintImage */
export class ScaleConstraintImage {
	public id: number;
	public image_id: number;
	public x_position: number;
	public y_position: number;
	public scale_constraint_id: number;
	public point: 1 | 2;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of ScaleConstraintImage