import { LatLng } from "../../../../shared/models/annotation.model";
import { LabelAnnotation } from "./labelAnnotation";

export type Label = {
	nameId: number,
	coordinates: LatLng[],
	leafletLayer: any
};

export abstract class LabelSetAnnotation extends LabelAnnotation {

	public labels: Label[] = [];

    public assignUniqueNameId(): number{
		let biggestId = 0;
		this.labels.forEach((label) => {
			if(label.nameId > biggestId)
				biggestId = label.nameId;
		});

		return biggestId + 1;
	}

    getDescriptors():{} {
		let labelData: any[] = [];
		this.labels.forEach((label) => {
			labelData.push({nameId: label.nameId, coordinates: label.coordinates})
		})

		return {
			labels: labelData
		};
	}

    removeLabel(label) {
		const index = this.labels.indexOf(label);
		if (index > -1) {
			this.labels.splice(index, 1);
		}

		this.save();
		this.replaceLayers();
	}

    abstract getLabelCoordinates(label: Label);
}