
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';


@Injectable()
export class ChargebeeService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor


	/**
	 * [CHECKOUTSUBSCRIPTIONS]
	 *
	 * @param {number} orgId			Organization ID
	 * @param {string} plan_id			Plan ID
	 */
	public checkoutSubscriptions(orgId: number, plan_id: string): Promise<any> {

		let url = `/organizations/${orgId}/subscriptions/checkout`;
		return this.httpService.post(url, {plan_id});

	}	// End-of checkoutSubscriptions


	/**
	 * [GETSUBCRIPTIONS]
	 *
	 * @param {number} orgId			Organization ID
	 */
	public getSubscriptions(): Promise<any> {

		let url = '/chargebee/plans';
		return this.httpService.get(url);

	}	// End-of getSubscriptions

	/**
	 * [GETPORTAL]
	 *
	 * @param {number} orgId			Organization ID
	 */
	public getPortal(orgId: number): Promise<any> {

		let url = `/organizations/${orgId}/subscriptions/portal`;
		return this.httpService.post(url, {});

	}	// End-of getPortal

}	// End-of class ImageService
