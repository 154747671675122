import { remove as e, set as o, get as n } from "@analytics/global-storage-utils";
var t = "cookie",
  i = a(),
  r = d,
  c = d;
function u(o) {
  return i ? d(o, "", -1) : e(o);
}
function a() {
  if (void 0 !== i) return i;
  var e = "cookiecookie";
  try {
    d(e, e), i = -1 !== document.cookie.indexOf(e), u(e);
  } catch (e) {
    i = !1;
  }
  return i;
}
function d(e, t, r, c, u, a) {
  if ("undefined" != typeof window) {
    var d = arguments.length > 1;
    return !1 === i && (d ? o(e, t) : n(e)), d ? document.cookie = e + "=" + encodeURIComponent(t) + (r ? "; expires=" + new Date(+new Date() + 1e3 * r).toUTCString() + (c ? "; path=" + c : "") + (u ? "; domain=" + u : "") + (a ? "; secure" : "") : "") : decodeURIComponent((("; " + document.cookie).split("; " + e + "=")[1] || "").split(";")[0]);
  }
}
export { t as COOKIE, r as getCookie, a as hasCookies, u as removeCookie, c as setCookie };
