import {
	Component,
	Output,
	EventEmitter,
	ContentChild,
	Directive,
	TemplateRef, SimpleChanges, Input
} from "@angular/core";

import { debounce } from "lodash";

@Component({
	selector: "aerial-dropzone",
	templateUrl: "./dropzone.html",
	styleUrls: ["./dropzone.scss"]
})
export class AerialDropzone {

	@Input() noBorder: boolean = false;
	@Input() class: string = "";

	// (Optional) Implement with: *ngTemplateOutlet="t_standardDropzone"
	hoverTemplate?: TemplateRef<any>;

	public isHovered: boolean = false;

	files: File[] = [];
	@Output() filesEmitter: EventEmitter<File[]> = new EventEmitter<File[]>();

	onSelect(event: any) {
		if (event?.addedFiles) {
			this.files.push(...event.addedFiles);
			this.isHovered = false;
			this.filesEmitter.emit(this.files);
		}
	}

	onDragOver() {
		this.isHovered = true;
	}

	onDragLeave() {
		this.isHovered = false;
	}


}
