import React from "react";

import { store } from "@app/store";
import { matchPaths } from "@app/utils/matchPaths";
import { Step1, Step2, Step3, Step4, api } from "@features/MLWizard";

const matcher = matchPaths({
	"#/projects/view/:projectId\\?tab=:tab": (params) => params["projectId"],
});

export const WizardSteps = ({
	activeStep,
	selections,
	setSelection,
	getOrthos,
	project,
	handleBack,
	closeWizard,
}) => (
	<>
		{activeStep === 0 && (
			<Step1
				selection={selections.step1}
				setSelection={(v) => setSelection((s) => ({ ...s, step1: v }))}
			/>
		)}
		{activeStep === 1 && (
			<Step2
				selection={selections.step2}
				setSelection={(v) => setSelection((s) => ({ ...s, step2: v }))}
			/>
		)}
		{activeStep === 2 && (
			<Step3
				selection={selections.step3}
				setSelection={(v) => setSelection((s) => ({ ...s, step3: v }))}
				orthophotos={getOrthos(project)}
			/>
		)}
		{activeStep === 3 && (
			<Step4
				handleBack={handleBack}
				handleClose={() => {
					closeWizard();
					store.dispatch(
						api.util.updateQueryData(
							"getProject",
							matcher(window.location.hash),
							(draft) => {
								draft.models.push({
									...selections.step3,
									created_at: new Date().toISOString(),
									name:
										"AI Analysis " +
										selections.step3.fullName,
									status: "Rendered",
									type: "processed",
									updated_at: new Date().toISOString(),
								});
							}
						)
					);
				}}
				ortho={selections.step3}
			/>
		)}
	</>
);
