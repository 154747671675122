import L from "leaflet";

import { AnnotationType } from "../../../viewer/classes/measure";
import { LabelSetAnnotation } from "./labelSetAnnotation";

export class BoxLabelSet extends LabelSetAnnotation {
	
	name = "New Box Label Set";
	type = AnnotationType.BOX_LABEL_SET;
	
	public tempRect: L.Rectangle;

	constructor(
		params = {},
		labels: [] = []
	) {
		super();
		Object.assign(this, params); // will only overwrite the default with values that are in `params`, everything stays
		this.labels = labels;
	}

	render(): void {
		this.labels?.forEach( (label) => {
			label.leafletLayer = L.rectangle([label.coordinates[0], label.coordinates[1]], {color: this.color, weight: 2, fill: ''}).addTo(this.layer);
		});
	}

	highlight(highlightedLabel) {
		if (this.layer) {
			this.tempRect = L.rectangle([highlightedLabel.coordinates[0], highlightedLabel.coordinates[1]], {color: this.color, weight: 4, fill: ''}).addTo(this.layer);
		}
	}

	unhighlight() {
		if (this.layer) {
			this.layer.removeLayer(this.tempRect)
		}
	}

	getLabelCoordinates(label) {
		// TODO - get the center point of the 2 coordinates
		
		// for now, just return the first point coordinates
		return {
			lat: label.coordinates[0][0],
			lng: label.coordinates[0][1],
		}
	}
}