/* Imports */
import { Component, Input, OnInit } from '@angular/core';
import { AdminService, AlertService, AuthenticationService, FinanceService, OrganizationService, UtilsService, PermissionService } from '../../shared/services';
import { Alert } from '../../shared/models';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-admin-view',
	templateUrl: './admin-view.component.html',
	styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {

	@Input('organizationId') orgId: any;

	public orgList: Array<any>;
	public user: any;
	public orgPlan: any = null;
	public isMobile: boolean = false;
	public isDeveloper: boolean = false;
	public permissionList: Array<any> = [];
	public creditsOverride: number = null;
	public storageOverride: number = null;
	public trialOverride: number = null;

	public alertOptions = ['success', 'error', 'warning', 'trashed', 'long', 'complex'];

	constructor(
		public dialog: MatDialog,
		private _adminService: AdminService,
		private _utilsService: UtilsService,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService,
		private _permissionService: PermissionService,
		private _financeService: FinanceService,
		private _alertService: AlertService,
	) {
	}

	ngOnInit() {

		this.isMobile = this._utilsService.getIsMobile();
		this.permissionList = this._permissionService.getPermissionList();
		this.isDeveloper = this._authService.isDeveloper;
		this.user = this._authService.user;
		this.setup();

	}	// End-of ngOnInit

	setup(): void {
		if (this.orgId) {
			this.orgList = [];
			this._orgService.getById(this.orgId).then(rtnOrg => {
				this.orgList.push(rtnOrg);
			});
		}
	}

	overridePermission(value): void {
		this._permissionService.setPermissionOverride(value);
	}

	overrideEvent(value, type: "trial" | "permission" | "credits" | "storage"): void {
		this._adminService.setOverride(value, type)
	}

	sendTestAlert(type: 'success' | 'error' | 'warning' | 'trashed' | 'long' | 'complex'): void {
		switch (type) {
			case 'success':
				this._alertService.success(new Alert('Test Success Alert'));
				break;
			case 'error':
				this._alertService.error(new Alert('Test Error Alert'));
				break;
			case 'warning':
				this._alertService.warning(new Alert('Test Warning Alert'));
				break;
			case 'trashed':
				this._alertService.trashed('Test Trashed Alert');
				break;
			case 'long':
				this._alertService.success(new Alert('30 Second Test Success Alert', null, 30000));
				break;
			case 'complex':
				const alert = [
				{
					text: 'Test link to navigate to',
					isLink: false
				}, {
					text: 'projects list',
					isLink: true,
					linkRoute: ['projects', 'list'],
				}, {
					text: ' page.',
					isLink: false
				}
			];
			this._alertService.success(new Alert(alert));
		}
	}

}	// End-of class TestComponent


