import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { flagLayer } from "../../../shared/featureFlags";

/*
	Feature Flag Directive
	- assumes that the flagLayer context has already been set
	- *featureEnabled="'feature-name'"
	- *featureEnabled="'!feature-name'"
*/
@Directive({
	selector: '[featureEnabled]'
})
export class FeatureFlagDirective implements OnInit {
	
	private flagName: string = "";
	private isHidden = true;
	
	@Input() set featureEnabled(val: string) {
		this.flagName = val;
		this.updateView();
	}

	constructor(
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef
	) {}

	ngOnInit() {
		this.updateView();
	}

	private updateView() {
		if (this.isEnabled()) {
			if (this.isHidden) {
				this._viewContainer.createEmbeddedView(this._templateRef);
				this.isHidden = false;
			}
		} else {
			this._viewContainer.clear();
			this.isHidden = true;
		}
	}

	private isEnabled() {
		if(this.flagName && this.flagName?.length > 0) {
			let reversed = this.flagName.startsWith('!');
			let flagString = reversed ? this.flagName.slice(1) : this.flagName;
			let isFlagEnabled = flagLayer.isEnabled(flagString);
			return reversed ? !isFlagEnabled : isFlagEnabled;
		}
		throw new Error('FeatureFlagDirective: flagName not set')
	}
}