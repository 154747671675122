import React from "react";

export const useSimpleStepper = (initialState = 0) => {
	const [activeStep, setActiveStep] = React.useState(initialState);

	const stepForward = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const stepBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return {
		activeStep,
		reset: () => setActiveStep(initialState),
		stepBack,
		stepForward,
	};
};
