
/* Imports */
import { Component, Inject, ViewChild, ElementRef } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ImageBatch, Organization, Project} from '@shared/models';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { isNil } from "lodash";
import moment from "moment";
import {findUniqueName, ImageService, OrganizationService} from '@shared/services';

@Component({
	selector: 'app-move-images-dialog',
	templateUrl: './move-images.dialog.html',
	styleUrls: ['./move-images.dialog.scss']
})
export class MoveImagesDialog {

	@ViewChild('fileInput') uploadInput: ElementRef;

	public batches: ImageBatch[];
	public fromBatch: ImageBatch;
	public project: Project;
	public activeOrg: Organization;
	public batchForm: FormGroup;
	public selectedImages: Array<any>;
	public isLoading: boolean = true;

	constructor (
		public _dialogRef: MatDialogRef<MoveImagesDialog>,
		private _orgService: OrganizationService,
		private _formBuilder: FormBuilder,
		private _imageService: ImageService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.fromBatch = data.fromBatch;
		this.selectedImages = data.selectedImages ?? [];

		if (this.fromBatch?.id) {
			this._imageService.getBatchListV2({project_id: this.fromBatch.project_id}).then(rtnBatches => {
				this.createFormGroup(rtnBatches);
			}).catch(err => {
				console.error(err);
				this.isLoading = false;
			})
		}

		if (data.batches) {
			this.createFormGroup(data.batches);
		}
	}

	createFormGroup( batches ) {

		if (batches?.length) {
			this.batches = batches;

			this.batchForm = this._formBuilder.group({
				batch: 	[batches[0], [Validators.required]],
			});
		}

		this.isLoading = false;

	}	// End-of setupForms

	isUniqueName(name: string): boolean {
		return !this.batches?.length || (this.batches?.length && isNil(this.batches.find(x => x.name === name)));
	}

	confirmationAction(): void {
		this._dialogRef.close( this.batchForm.value.batch )
	}

	close(): void {
		this._dialogRef.close(false);
	}

	getSelectedImagesText(selectedImages) {
		return selectedImages.length === 1 ?
			(selectedImages[0].name ?? "Unnamed Image") :
			`the selected ${selectedImages.length} images`
	}

}	// End-of class TestComponent
