import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Button from "@mui/material/Button";
import React from "react";

import { Annotation } from "./Annotation";

export const AnnotationsList = ({
	project,
	annotations,
	getAnnotationsThunk,
}) => {
	return (
		<div>
			{annotations?.length ? (
				annotations.map((annotation) => (
					<Annotation
						key={`project-${project.id}-anno-${annotation.id}`}
						annotation={annotation}
						getAnnotationsThunk={getAnnotationsThunk}
					/>
				))
			) : (
				<GetAnnotations
					annotations={annotations}
					getAnnotationsThunk={getAnnotationsThunk}
				/>
			)}
		</div>
	);
};

const GetAnnotations = ({ annotations, getAnnotationsThunk }) => {
	return annotations && !annotations.length ? (
		<div> No annotations </div>
	) : (
		<Button
			onClick={getAnnotationsThunk}
			className="button-outlined-primary"
		>
			Get Annotations
			<ImageSearchIcon className="icon-button-primary-blue" />
		</Button>
	);
};
