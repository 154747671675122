/* Imports */
import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GCPService, DownloadService, ImageService, GlobalService, ProjectService, ImageInfo, ScaleConstraintService } from 'src/app/shared/services';
import { ScaleConstraint, ScaleConstraintImage } from 'src/app/shared/models';
import Panzoom from '@panzoom/panzoom';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';

const ImageLoadStatuses = {
	setup: "setup",
	observerEmit: "observer-emit",
	startLoading: "start-loading",
	mountImage: "mount-image",
	loadingSucceeded: "loading-succeeded",
	finally: "finally",
}

@Component({
	selector: 'app-photo-tag',
	templateUrl: './photo-tag.component.html',
	styleUrls: ['./photo-tag.component.scss']
})

export class PhotoTagComponent {

	@ViewChild('imageElementRef') imageElementRef: ElementRef;

	@ViewChild(MatMenu) menu: MatMenu;
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;


	@Input('selectedImage') selectedImage: any;
	@Input('GCPList') GCPList: any;


	@Input('currentIndex') currentIndex: number;
	@Input('imageInfo') imageInfo: ImageInfo;
	@Input('imageList') imageList: ImageInfo[];
	@Input('showNav') showNav: boolean = true;


	@Input('projectId') project_id: any;
	@Input('scaleConstraint') scaleConstraint: ScaleConstraint;


	@Output('gcpEvent') gcpEvent: EventEmitter<any> = new EventEmitter();
	@Output('navEvent') navEvent: EventEmitter<-1 | 1> = new EventEmitter();
	@Output('openInfo') openInfoEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

	@Input('msDetail') msDetail: string = '';
	@Input('srcImageId') srcImageId: number = 0;
	@Input('batchImages') batchImages: Array<any> = [];


	private _panzoom: any;

	public paramsData: string = '';
	public rootURL = GlobalService.databaseApiUrl;
	public map: any;

	public infoText: string;
	public showInfo: boolean = false;

	/* GCP */
	public startPlacement: boolean = false;
	public gcpView: boolean = false;
	public isLoading: boolean = true;
	public isPanning: boolean = false;

	/* Constraint */
	public placeholderDot: any;

	public imageStatus: string = 'init';
	public activeImage: number = 0;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _gcpService: GCPService,
		private _scaleConstraintService: ScaleConstraintService,
		private downloadService: DownloadService,
		private imageService: ImageService,
		private projectService: ProjectService,
		private _cdr: ChangeDetectorRef,
	) {

	}	// End-of constructor

	get hasNext() {
		return this.imageList && (this.currentIndex + 1 < this.imageList.length);
	}
	get hasPrevious() {
		return this.imageList && (this.currentIndex > 0);
	}


	onImageLoad(e): void {
		this.isLoading = e.reason !== ImageLoadStatuses.finally;
		if (e.reason === ImageLoadStatuses.mountImage) {
			this.setupImageViewer();
		}
		this._cdr.detectChanges();
	}


	setupImageViewer(): void {

		const elem = this.imageElementRef.nativeElement;
		this._panzoom = Panzoom(elem, {
			handleStartEvent: (event) => {
				event.preventDefault();
				event.stopPropagation();
			}
		});
		elem.addEventListener('panzoompan', (event) => {
			if (!this.isPanning && event.detail.x > 0 || event.detail.y > 0) {
				this.isPanning = true;
			}
		})

		const parent = elem.parentElement;

		// No function bind needed
		parent.addEventListener('wheel', this._panzoom.zoomWithWheel)

	}

	zoomIn(): void {

		this._panzoom.zoomIn();

	}

	zoomOut(): void {

		this._panzoom.zoomOut();

	}

	resetImageViewer(): void {

		this._panzoom.reset();

	}

	nextPhoto(): void {
		if (this.hasNext) {
			this.navEvent.emit(1);
			this.imageStatus = 'init';
		}
	}	// End-of nextPhoto


	previousPhoto(): void {
		if (this.hasPrevious) {
			this.navEvent.emit(-1);
			this.imageStatus = 'init';
		}
	}	// End-of previousPhoto


	placedGCPStyle(placed): any {
		let tag;
		if (placed.x_position && placed.y_position) {
			tag = placed;
		} else {
			tag = placed.gcps_images.find(x => x.image_id === this.imageList[this.currentIndex].id);
		}
		// 110vw and vh guarantee the tag will be outside of the field of view
		const styles = {
			'left': tag?.x_position ? `${tag.x_position - 11}px` : '-110vw',
			'top': tag?.y_position ? `${tag.y_position - 11}px` : '-110vh',
		};
		return styles;
	}


	confirmGCP(): void {
		this.gcpEvent.emit({type: 'confirm', pos: this.GCPList.active});
		this.menuTrigger.closeMenu();
	}

	editGCP(i: number): void {
		this.gcpEvent.emit({type: 'edit', index: i})
	}

	cancelGCP(): void {
		this.gcpEvent.emit({type: 'confirm'});
		this.menuTrigger.closeMenu();
	}


	panStart(): void {
		if (this.showInfo) {
			this.showInfo = null;
		}
	}

	panEnd(e): void {
		if (!this.menuTrigger.menuOpen) {
			this.menuTrigger.openMenu()
		}
	}


	// Scale-Constraint-Specific

	setupConstraintTagging(): void {
		this.infoText = "Scroll to zoom in/out. ”Click and drag” to move across the photo. “Click” to designate your point.";
		this._cdr.detectChanges();
	}

	clickEvent(e): void {
		if (this.GCPList) {
			if (!this.isPanning && this.GCPList.active && !this.menuTrigger.menuOpen) {
				this.GCPList.active.x_position = e.offsetX;
				this.GCPList.active.y_position = e.offsetY;
				e.customPositions = {left: e.x + 16, top: e.y + 16};
				this.menuTrigger.openMenu();
			} else {
				this.isPanning = false;
			}
		}
	}

	confirmConstraint(val: 1 | 2): void {

		let constraintImage = new ScaleConstraintImage({
			image_id: this.selectedImage.id,
			x_position: this.placeholderDot.x_position,
			y_position: this.placeholderDot.y_position,
			scale_constraint_id: this.scaleConstraint.id,
			point: val,
		});

		let imageInd = this.scaleConstraint.images.findIndex(image => {
			return (image.image_id === this.selectedImage.id) && (image.point === val)
		});

		if (imageInd > -1) {
			this._scaleConstraintService.updateConstraintInImage(this.project_id, this.scaleConstraint.id, constraintImage).then(rtn => {
				this.scaleConstraint.images[imageInd] = rtn;
			})
		} else {
			this._scaleConstraintService.addConstraintToImage(this.project_id, this.scaleConstraint.id, constraintImage).then(rtn => {
				this.scaleConstraint.images.push(rtn);
			})
		}

		this.placeholderDot = null;
	}

	removeConstraint(val: 1 | 2): void {

		let imageInd = this.scaleConstraint.images.findIndex(image => {
			return (image.image_id === this.selectedImage.id) && (image.point === val)
		});
		this._scaleConstraintService.removeConstraintFromImage(this.project_id, this.scaleConstraint.id, this.scaleConstraint.images[imageInd]).then(rtn => {
			this.scaleConstraint.images.splice(imageInd, 1);
		})
	}

	clearPlaceholder(e): void {
		if (e === 'container') {
			this.placeholderDot = null;
		}
	}

	placedConstraintStyle(image): any {
		const styles = {
			left: '' + image.x_position + 'px',
			top: '' + image.y_position + 'px',
			position: 'absolute',
			'background-color': image.point ? (image.point === 1 ? '#edb716' : '#02adc0') : '#414141',
		};
		return styles;
	}

	openInfo(): void {
		this.openInfoEvent.emit(true);
	}

	getURL = (imageInfo): string =>
		this.rootURL + (imageInfo.large_png_url ?? imageInfo.large_url); // Get the created large png image, or the fallback

	getErrorURL = (imageInfo) =>
		this.rootURL + (imageInfo.large_url ?? imageInfo.medium_url); // in the exceedingly rare case large doesn't exist, medium

	selectImage(image) {
			this.activeImage = image.id;
			const currentIndex = this.batchImages.indexOf(image);

			if (image?.guid && image?.id !== this.srcImageId) {
				this.srcImageId = 0;
				this.router.navigate(['..', this.batchImages[currentIndex]?.guid], { relativeTo: this.activatedRoute });
				this.batchImages = [...new Set(this.batchImages)];
			}
	}

}	// End-of class SinglePhotoComponent
