


/* Interface for GCP_Tag */
export class GCP_Tag {
	public image_id: number;
	public x_position: number;
	public y_position: number;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
}	// End-of GCP_Tag


/* Interface for GCP */
export class GCP {
	public id: number;
	public name: string;
	public longitude: string;
	public latitude: number;
	public altitude: number;
	public northing: number;
	public easting: number;
	public units: string;
	public precision: string;
	public project_id: string;
	public coordinate_system: {
		epsg: string,
		description: string
	}
	public x_position: number;
	public y_position: number;
	public gcps_images: Array<any>;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor
}	// End-of GCP


/* Interface for CreditCard */
export class NorthingEastingCoord {
	public altitude: number;
	public northing: number;
	public easting: number;
	public coordinate_system: {
		epsg: number,
	}

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of CreditCard

