import { Injectable, Inject } from '@angular/core';
import { mergeMap, delay } from 'rxjs/operators';
import { ReplaySubject, Observable, forkJoin, concat, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class PotreeService {

	private _loadedLibraries: { [url: string]: ReplaySubject<any>} = {};

	constructor(@Inject(DOCUMENT) private readonly document: any) {}

	lazyLoad(): Observable<any> {
		return new Observable(sub => {
			forkJoin([
			this.loadScript('assets/libs/jquery/jquery-3.1.1.min.js'),
			this.loadScript('assets/libs/jquery-ui/jquery-ui.min.js'),
			this.loadScript('assets/libs/other/BinaryHeap.js'),
			this.loadScript('assets/libs/tween/tween.min.js'),
			this.loadScript('assets/libs/d3/d3.js'),
			this.loadScript('assets/libs/proj4/proj4.js'),
			this.loadScript('assets/libs/three.js/build/three.min.js')
			]).subscribe(res => {
				forkJoin([
					this.loadScript('assets/libs/three.js/extra/lines.js'),
					this.loadScript('assets/libs/potree/potree.js')
				]).subscribe(d => {
					sub.next();
					sub.complete();
				});
			});

		});
	}	// End-of lazyLoad

	private loadScript(url: string): Observable<any> {
		if (this._loadedLibraries[url]) {
			return this._loadedLibraries[url].asObservable();
		}

		this._loadedLibraries[url] = new ReplaySubject();

		const script = this.document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src= url;
		script.onload = () => {
			this._loadedLibraries[url].next();
			this._loadedLibraries[url].complete();
		};

		this.document.body.appendChild(script);

		return this._loadedLibraries[url].asObservable();
	}	// End-of loadScript

	private loadStyle(url: string): Observable<any> {
		if (this._loadedLibraries[url]) {
			return this._loadedLibraries[url].asObservable();
		}

		this._loadedLibraries[url] = new ReplaySubject();

		const style = this.document.createElement('link');
		style.type = 'text/css';
		style.href = url;
		style.rel = 'stylesheet';
		style.onload = () => {
			this._loadedLibraries[url].next();
			this._loadedLibraries[url].complete();
		};

		const head = document.getElementsByTagName('head')[0];
		head.appendChild(style);

		return this._loadedLibraries[url].asObservable();
	}	// End-of loadStyle
}	// End-of class PotreeService
