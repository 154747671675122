import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface ModelsStreamPayload<T> {
	isLoading: boolean;
	fullList: T[];
	subList: T[];
}

const FULL_SUBLIST_SIZE = 10;

@Injectable({
  providedIn: 'root'
})
export class PagingStreamService {

  constructor() { }

	loopGet(fetchFunc, { completionCheck, fullList = [], nextIndex = 0, subject, maxChunk }) {
		fetchFunc(nextIndex).then((subList: any[]) => {
			let isLoading = true;
			const state = {
				completionCheck,
				fullList: fullList.concat(subList),
				nextIndex: nextIndex + FULL_SUBLIST_SIZE,
				subject,
				maxChunk
			};
			if (completionCheck(subList) || nextIndex >= maxChunk) {
				isLoading = false;
			} else {
				this.loopGet(fetchFunc, state);
			}
			subject.next({ isLoading, fullList, subList });
		});
	}

	wrapFetchWithPagingStream<T>(
		fetchFunc,
		completionCheck = sl => sl.length < FULL_SUBLIST_SIZE,
		maxChunk = 2
	): BehaviorSubject<ModelsStreamPayload<T>> {
		const modelsStream = new BehaviorSubject<ModelsStreamPayload<T>>({
			isLoading: true,
			fullList: [],
			subList: []
		});
		this.loopGet(fetchFunc, { completionCheck, subject: modelsStream, maxChunk });
		return modelsStream;
	}
}
