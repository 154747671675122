
/* Imports */
import { Injectable } from '@angular/core';

/* Internal */
import { CustomState } from '../classes/customState';
// var packageJson = require('./../../../../package.json');

const storageType = (useLocal) => useLocal ? localStorage : sessionStorage;

@Injectable()
export class SessionService {

	// protected _localSession: BehaviorSubject<any> = new BehaviorSubject(null);
	protected static _localSession: CustomState<any> = new CustomState({});
	protected static _session: CustomState<any> = new CustomState({});
	protected static _active: Boolean = false;

	constructor() {

	}	// End-of constructor

	public static getSessionType = (useLocal) => useLocal ? SessionService._localSession : SessionService._session;

	public static get session(): CustomState<any> {

		return this._localSession;

	}	// End-of get

	// Returns if a session is active
	public static get isActive(): Boolean {
		this.loadLocalSession();
		return this._active;
	}	// End-of isActive

	static startSession(token, useLocal: boolean = true): void {

		let time = new Date();
		storageType(useLocal).setItem('sessionStartDate', time.toString());
		storageType(useLocal).setItem('token', token);
		SessionService._active = true;

	}	// End-of startSession

	static loadLocalSession(): void {

		const session = SessionService._localSession;

		if (localStorage?.session) {
			let ses = session.data = JSON.parse(localStorage.session);

			if (localStorage.token && ses?.user?.api_key && localStorage.token === ses.user.api_key) {
				SessionService._active = true;
				return;
			}
		}

		// SessionService._active = false;

	}	// End-of loadSession

	static get(key: string, useLocal: boolean = true) {

		const session = SessionService.getSessionType(useLocal);

		if (!session) {
			console.warn("Session missing: ", session);
			return null;
		} else {
			return session.data[key];
		}

	}	// End-of get

	static set(key: string, data: any, useLocal: boolean = true) {

		const session = SessionService.getSessionType(useLocal);

		if (data != null || data != undefined) {
			if (typeof data === "object") {
				session.data[key] = data;
			} else {
				session.data[key] = data;
			}
			SessionService.save(useLocal);
		}

	}	// End-of set

	static remove(key: string, useLocal: boolean = true) {

		const session = SessionService.getSessionType(useLocal);

		if (!session) {
			console.warn("Session missing: ", session);
			return null;
		} else {
			if (key in session.data) {
				delete session.data[key];
			}
		}

	}	// End-of remove

	static save(useLocal: boolean = true): void {

		const session = SessionService.getSessionType(useLocal);

		storageType(useLocal).setItem("session", JSON.stringify(session.data));

	}	// End-of save

	static clear(useLocal: boolean = true): void {

		// dont clear any feature flags which might be in localStorage
		let flags = localStorage.getItem("flags");

		storageType(useLocal).clear();

		if(flags)
			localStorage.setItem("flags", flags);

		SessionService.getSessionType(useLocal).data = {};

	}	// End-of clearz

}	// End-of class SessionService
