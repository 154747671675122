import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

import { titleCase } from "@utils/titleCase";

export const ListItem = ({ expanded, visible, id, toggleVisibility, name }) => (
	<Collapse in={expanded || visible} timeout="auto" unmountOnExit key={id}>
		<ListItemButton
			sx={{
				pl: 4,
			}}
			onClick={toggleVisibility}
		>
			<ListItemIcon>
				{visible ? (
					<VisibilityIcon />
				) : (
					<VisibilityOffIcon color="disabled" />
				)}
			</ListItemIcon>
			<ListItemText primary={titleCase(name)} />
		</ListItemButton>
	</Collapse>
);
