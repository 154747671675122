
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import { GCP, GCP_Tag, Project, Image } from '../models';

@Injectable()
export class GCPService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor

	/**
	* [GETLIST]
	*
	*/
	public getListFromProject(project: Project | number): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let url = '/projects/' + projectId + '/gcps';
		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [CREATE]
	*
	* @param {GCP} gcp		GCP data
	*/
	public create(gcp: GCP): Promise<any> {

		let url = '/gcps';
		return this.httpService.post(url, gcp);

	}	// End-of create

	/**
	* [GETBYID]
	*
	* @param {number} projId		ID of gcp
	*/
	public getById(gcp_id: number): Promise<any> {

		let url = '/gcps/' + gcp_id;
		return this.httpService.get(url);

	}	// End-of getById

	/**
	* [REMOVE]
	*
	* @param {GCP | number} gcp		GCP data or id
	*/
	public remove(gcp: GCP | number): Promise<any> {

		let gcp_id = ((typeof gcp === 'object') ? gcp["id"] : gcp);
		let url = '/gcps/' + gcp_id;
		return this.httpService.delete(url);

	}	// End-of remove

	/**
	* [UPDATE]
	*
	* @param {GCP} gcp		GCP data
	*/
	public update(gcp: GCP): Promise<any> {

		let gcp_id = ((typeof gcp === 'object') ? gcp["id"] : gcp);
		let url = '/gcps/' + gcp_id;
		return this.httpService.put(url, gcp);

	}	// End-of update

	/**
	* [GETIMAGESBYID]
	*
	* @param {number} projId		ID of gcp
	*/
	public getImagesById(gcp_id: number): Promise<any> {

		let url = '/gcps/' + gcp_id + '/images';
		return this.httpService.get(url);

	}	// End-of getImagesById

	/**
	* [CREATE]
	*
	* @param {GCP} gcp		GCP data
	*/
	public addGCPTag(gcp: GCP | number, tag: GCP_Tag): Promise<any> {

		let gcp_id = ((typeof gcp === 'object') ? gcp["id"] : gcp);
		let url = '/gcps/' + gcp_id + '/images';
		return this.httpService.post(url, tag);

	}	// End-of create

	/**
	* [UPDATE]
	*
	* @param {GCP} gcp		GCP data
	*/
	public updateGCPTag(gcp: GCP | number, tag: GCP_Tag): Promise<any> {

		let gcp_id = ((typeof gcp === 'object') ? gcp["id"] : gcp);
		let image_id = tag.image_id;
		let url = '/gcps/' + gcp_id + '/images/' + image_id;
		return this.httpService.put(url, tag);

	}	// End-of update

	/**
	* [UPDATE]
	*
	* @param {GCP} gcp		GCP data
	*/
	public removeGCPTag(gcp: GCP | number, tag: GCP_Tag | number): Promise<any> {

		let tag_id = ((typeof tag === 'object') ? tag["image_id"] : tag);
		let gcp_id = ((typeof gcp === 'object') ? gcp["id"] : gcp);
		let url = '/gcps/' + gcp_id + '/images/' + tag_id;
		return this.httpService.delete(url);

	}	// End-of update

}	// End-of class GCPService
