
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Environments */
import { environment } from '../../../environments/environment';
import { Plan } from '../models';
import {ChargebeePlan} from "@shared/models/chargebee-plan.model";

@Injectable()
export class FinanceService {

	constructor(
		private httpService: HttpService,
		) {

	}	// End-of constructor

	/**
	* [GETCARD]
	*
	* @param {number} orgId			Organization identifier
	*/
	getCards(orgId: number): Promise<any> {

		let url = `/organizations/${orgId}/cards`;
		return this.httpService.get(url);

	}	// End-of getCard

	/**
	* [CREATECARD]
	*
	* @param {number} orgId			Organization identifier
	* @param {string} cbToken		Chargebee Token
	*/
	createCard(orgId: number, cbToken: string ): Promise<any> {

		let url = `/organizations/${orgId}/cards`;
		return this.httpService.post(url, { token_id: cbToken });

	}	// End-of createCard

	/**
	* [REMOVECARD]
	*
	* @param {number} orgId				Organization identifier
	* @param {string} paymentSourceId	PaymentSourceID from Chargebee Object
	*/
	removeCard(orgId: number, paymentSourceId: string ): Promise<any> {

		let url = `/organizations/${orgId}/cards/${paymentSourceId}`;
		return this.httpService.delete(url);

	}	// End-of createCard

	/**
	* [SETDEFAULTCARD]
	*
	* @param {number} orgId				Organization identifier
	* @param {number} paymentSourceId	Chargebee payment identifier
	*/
	setDefaultCard(orgId: number, paymentSourceId: any): Promise<any> {

		let url = `/organizations/${orgId}/cards/${paymentSourceId}`;
		return this.httpService.put(url, {isPrimary: true});

	}	// End-of setDefaultCard

	/**
	* [GETPLANS]
	*
	*/
	getPlans(): Promise<any> {

		let url = `/plans`;
		return this.httpService.get(url);

	}	// End-of getPlans

	/**
	* [GETPLANSFORORGANIZATION]
	*
	* @param {number} orgId				Organization identifier
	*/
	getPlansForOrganization(orgId: number): Promise<any> {

		let url = `/plans/organizations/${orgId}`;
		return this.httpService.get(url);

	}	// End-of getPlansForOrganization

	/**
	* [PURCHASEPLAN]
	*
	* @param {number} orgId					Organization identifier
	* @param {string} plan_id				Chargebee Plan Identifier
	* @param {string} payment_source_id	Plan Chargebee token
	*/
	purchasePlan(orgId: number, plan_id: string, payment_source_id: string): Promise<any> {

		let url = `/organizations/${orgId}/subscription`;
		let body = {
			plan_id,
			payment_source_id
		};
		return this.httpService.post(url, body);

	}	// End-of purchasePlan

	/**
	* [UPDATEPLAN]
	*
	* @param {number} orgId					Organization identifier
	* @param {number} planId				Plan identifier
	* @param {'month' | 'year'} duration	Plan duration
	*/
	updatePlan(orgId: number, plan: Plan): Promise<any> {

		let planId = plan["id"];
		let url = `/plans/${planId}/organizations/${orgId}`;
		let body = plan;
		return this.httpService.put(url, body);

	}	// End-of updatePlan

	/**
	* [CANCELPLAN]
	*
	* @param {number} orgId		Organization identifier
	*/
	cancelPlan(orgId: number): Promise<any> {

		let url = `/plans/organizations/${orgId}`;
		return this.httpService.delete(url);

	}	// End-of cancelPlan

	/**
	* [ADDUSER]
	*
	* @param {number} orgId		Organization identifier
	* @param {number} userId	User Identifier
	*/
	addUser(orgId: number, userId: number): Promise<any> {

		let url = `/plans/organizations/${orgId}/users/${userId}`;
		return this.httpService.post(url, {});

	}	// End-of addUserToPlan

	/**
	* [REMOVEUSER]
	*
	* @param {number} orgId		Organization identifier
	* @param {number} userId	User identifier
	*/
	removeUser(orgId: number, userId: number): Promise<any> {

		let url = `/plans/organizations/${orgId}/users/${userId}`;
		return this.httpService.delete(url);

	}	// End-of removeUser

	/**
	* [PURCHASECREDITS]
	*
	* @param {number} orgId		Organization identifier
	* @param {number} quantity
	* @param {string} cardId
	*/
	purchaseCredits(orgId: number, quantity: number, cardId?: string): Promise<any> {

		let url = `/processing_credits`;
		let body = {
			organization_id: orgId,
			quantity: quantity,
			card_id: cardId
		};
		return this.httpService.post(url, body);

	}	// End-of purchaseCredits

	/**
	* [CHECKCREDITPRICE]
	*
	* @param {number } orgId	Organization identifier
	* @param {number} quantity	Number of credits
	*/
	checkCreditPrice(orgId: number, quantity: number): Promise<any> {

		let url = `/processing_credits/price`;
		let body = {
			organization_id: orgId,
			quantity: quantity
		};
		return this.httpService.post(url, body);

	}	// End-of checkCreditPrice

	getChargeBeePlans(): Promise<ChargebeePlan[]> {
		return this.httpService.get('/chargebee/plans');
	}
}	// End-of class FinanceService

export interface Payment {
	name: string;
	address_line1: string;
	address_line2: string;
	address_city: string;
	address_state: string;
	address_zip: string;
}	// End-of CreditCard
