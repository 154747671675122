<div class="auto-width" *ngIf="orgControl?.value"
		 (click)="clearNotifications()">
	<mat-form-field
			*ngIf="orgList.length"
			appearance="outline"
			class="rounded-menu">
		<mat-select
				id="tooltip"
				#selectTooltip="matTooltip"
				[matTooltip]="tooltipText"
				matTooltipClass="select-tooltip"
				disableOptionCentering
				(selectionChange)="onSelectChange($event)"
				data-testid="credits-orgList"
				class="H6Black700Regular"
				[formControl]="orgControl">
			<mat-select-trigger>
				<div class="team-header">
					<span class="menu-team">
						<ng-container *ngIf="hasCredits(orgControl.value)">
							<mat-icon>group</mat-icon>
						</ng-container>
						<ng-container *ngIf="isShared(orgControl.value)">
							<mat-icon matTooltip="From item shared with you">link</mat-icon>
						</ng-container>
						{{ orgControl.value.name }}
					</span>
					<span class="menu-text" *ngIf="hasCredits(orgControl.value)">
						{{ orgControl.value.processing_credits + orgControl.value.subscription_credits | comma }}
						credits
					</span>
				</div>
			</mat-select-trigger>
			<mat-option *ngFor="let org of orgList" [value]="org">
				<div class="H6Black700Regular">
					<span data-testid="option-title">{{org.name}}</span>
					<span class="H7Black500Regular" *ngIf="org.status">({{ org.status }})</span>
				</div>
				<div class="H7Black500Regular" *ngIf="hasCredits(org)">{{org.processing_credits + org.subscription_credits | comma}} credits</div>
				<div class="H7Black500Regular" *ngIf="isShared(org)">(from share)</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
	<div *ngIf="numberOrgsChanged" data-testid="notifications-label" class="notifications-label">{{displayNumNotifications()}}</div>
</div>
