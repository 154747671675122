export const MODEL_STATUSES = {
  rendered: "Rendered",
  partial: "Partially Rendered",
  missing: "Missing Data",
  queued: "Queued",
  failed: "Failed"
};

export const MODEL_STATUS_EXPLANATION_MISSING =
  "This model is missing required data, please reprocess or contact Mapware support.";
export const MODEL_STATUS_EXPLANATION_PARTIAL = "This model only contains: ";
