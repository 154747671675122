
<div class="scroll-container"
	 #scrollContainerRef
	 id="scrollContainerRef"
	 (scroll)="loadChunk($event)">

	<div style="display: contents;" #scrollContentRef id="scrollContentRef">
		<ng-content></ng-content>
	</div>

	<div class="loading-container">
		<ng-container *ngIf="isLoadingChunk">
			<div class="dot-stretching"></div>
		</ng-container>
		<ng-container *ngIf="!isLoadingChunk">
			<div *ngIf="moreToLoad" class="LabelBlack500">Continue scrolling to load more</div>
			<div *ngIf="!moreToLoad && (itemsLoaded > 0)" class="LabelBlack500">All files loaded.</div>
			<div *ngIf="!moreToLoad && (itemsLoaded <= 0)" class="LabelBlack500">No files to load.</div>
		</ng-container>
	</div>
</div>
