import { createLayerFunc } from "./utils/createLayerFunc.js";
import { normalizeFunctionConfig } from "./utils/normalizeFunctionConfig.js";
import { isAnObject, isAFunction, configValidationError } from "./utils/index.js";


/**
 *
 * @param {Object<string, LayerFunc>} layer - an object that we will be building onto
 * @param {[string, FunctionConfig]} - this will define the new function, name being the new name, and the config used to build the function
 */
const addLayerFuncToLayer = (layer, [functionName, functionConfig]) => {
    if (checkIsValidLayerFuncConfig(functionConfig)) {
        layer[functionName] = createLayerFunc(
            functionName, // name is needed here to help with debugging later
            // because config can be a function, or an object with various params,
            // we normalize to ensure how ever it came in, it has all of the necessary parts
            normalizeFunctionConfig(functionConfig)
        );

        return layer;
    } else {
        throw configValidationError;
    }
};

export const checkIsValidLayerFuncConfig = (config) =>
    isAnObject(config)
        ? config.func && isAFunction(config.func)
        : isAFunction(config);

/**
 * For as important as having a well done abstraction layer maybe
 * the code is really quite bland
 * all that is done here is to convert the configuration
 * @param {Object.<FunctionConfig>} layerConfig - this is a dictionary mapping the new function names to their configurations that define how they will work and be used
 * @returns {Object.<string, LayerFunc>}
 */
export const createLayerApi = (layerConfig) => {
    if (!isAnObject(layerConfig)) throw configValidationError;
    return Object.entries(layerConfig).reduce(addLayerFuncToLayer, {});
};

// We can bring this back once things settle in, but it's not necessary for work
// makeSpellCheckProxy(
//    ...
//{ noopForInvalidKey }
//)
