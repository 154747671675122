import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import React, { useState } from "react";

import { store } from "@app/store";

import { api as annotationsAPI } from "../api";

export const AnnotationCreate = ({ project, getAnnotationsThunk }) => {
	const defaultName = "New Annotation";
	const [name, setName] = useState(defaultName);
	const [createdAnnotation, setCreatedAnnotation] = useState(null);

	const onNameChange = (e) => setName(e.target.value);

	const makeAnnotation = (project) => () => {
		const annotation = {
			color: "ff00ff",
			descriptors: null,
			details: "Test",
			model_references: null,
			name: name,
			project_id: project.id,
			type: "Test",
		};

		store
			.dispatch(
				annotationsAPI.endpoints.create.initiate(annotation as any)
			)
			.then((data) => {
				setCreatedAnnotation(JSON.stringify(data));
				getAnnotationsThunk();
			})
			.catch(console.error);
	};

	return (
		<div className="annotation-tools">
			<div className="annotation-create">
				<Input defaultValue={name} onChange={onNameChange} />
				<IconButton onClick={makeAnnotation(project)}>
					<AddIcon />
				</IconButton>
			</div>
			{createdAnnotation && (
				<div className="annotation-created">{createdAnnotation}</div>
			)}
		</div>
	);
};
