import L from "leaflet";

import { store } from "@app/store";
import { api as api } from "@features/Annotations";

import { LabelAnnotationData } from "../../../../shared/models/annotation.model";

export abstract class LabelAnnotation implements MapInteractable {

	public id: number;
	public projectId: number;

	public name: string = "Unkown Label Annotation";
	public details: string = "";
	public type = "unknownLabelAnnotation";

	public mapType: string = "2D";
	public color: string = "#0000FF";
	
	public expanded = false;
	public visible = false;

	public layer: L.Layer = null;

	constructor(
		params = {}
	) {
		Object.assign(this, params); // will only overwrite the default with values that are in `params`, everything stays
	}

	select() { }
	deselect() { }
	stop() { }

	async create() {
		const labelSetData = new LabelAnnotationData({
			name: this.name,
			details: this.details,
			color: this.color,
			project_id: this.projectId,
			type: this.type,
			descriptors: {},
		});

		const createdLabelSet:any = await store
			.dispatch(api.endpoints.create.initiate(labelSetData));
		
		if(createdLabelSet.data) {
			this.id = createdLabelSet.data.id;
		}
	}

	async save() {

		const updates = {
			name: this.name,
			details: this.details,
			descriptors: this.getDescriptors()
		};
		store.dispatch( api.endpoints.update.initiate( {
							annotation_id: this.id,
							updates
						})
		);
	}

	delete() {
		this.visible = false;
		store.dispatch(api.endpoints.delete.initiate(this.id));
	}

	public createLayer(): L.Layer {
		this.layer = L.layerGroup();
		this.render();	
		return this.layer;
	}

	replaceLayers() {
		if(this.layer) {
			this.layer.clearLayers();
			this.render();
		}
	}

	public abstract render();

	abstract getDescriptors():{};

	public abstract highlight(highlightedLabel);

	public abstract unhighlight();
}

interface MapInteractable {
	select();

	deselect();

	stop();
}