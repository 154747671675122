
/* Imports */
import { Component, ChangeDetectorRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';

/* Services */
import { ProjectService, FavoriteService, AlertService, DownloadService, PermissionService, OrganizationService } from '@shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";

/* Models */
import { Project, Alert } from '@shared/models';
import { isEmpty } from "lodash";

@Component({
	selector: 'app-exports-projects',
	templateUrl: './exports.projects.component.html',
	styleUrls: ['./exports.projects.component.scss']
})
export class ExportsProjectsComponent implements OnDestroy {

	/* ViewChild */
	@ViewChild(MatSort) set sort(s: MatSort) {
		if (s) { this.dataSource.sort = s; }
	}
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	/* Inputs */
	@Input('project') set project(proj: Project) {
		if (proj) { this.setup(proj); }
	}
	public _project: Project;
	get project(): Project { return this._project; }

	public defaultMenuTriggers: Array<any> = [];

	public dataSource: MatTableDataSource<any> = new MatTableDataSource();
	public isProcessing: boolean = false;
	public isDownloading: boolean = false;
	public activeIndex: number;
	public activeItem: any;
	public menuSubscriptions: Subscription[] = [];
	public isHovered: number;
	public displayedColumns: string[] = ['name', 'filetype', 'size', 'created_at', 'options']; // 'created_by' - removing created_by since it is incorrect for now
	public userHasDownloadPermission: boolean = false;
	public trialExpired: boolean = false;
	public contextMenuPosition = { x: '0px', y: '0px' };

	constructor(
		private _projectService: ProjectService,
		private _favoriteService: FavoriteService,
		private _orgService: OrganizationService,
		private _alertService: AlertService,
		private _downloadService: DownloadService,
		private _permissionService: PermissionService,
		private _cdr: ChangeDetectorRef,) {

	}	// End-of constructor

	ngOnDestroy() {
		this.menuSubscriptions?.forEach(s => s?.unsubscribe());
	}

	async setup(proj: Project) {
		this._permissionService.checkHasPermission(proj, "reader").then(rtn => {
			this.userHasDownloadPermission = rtn;
		});
		this._orgService.getTrialDays().subscribe(rtn => {
			if (!isNaN(parseInt(rtn))) { this.trialExpired = rtn <= 0; }
		});

		this._project = proj;

		if(flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
			if(!this._project['export_files']) {
				this._project['export_files'] = (await this._projectService.getExportFilesV2(this._project)) ?? [];
	
				await this._favoriteService.getList().then(favList => {
					if (favList.project_files && Array.isArray(favList.project_files)) {
						favList.project_files.forEach(fav => {
							const index = this._project['export_files'].findIndex(item => item.id === fav.project_file_id);
							if (index > -1) {
								this._project['export_files'][index].favorited = true;
								this._project['export_files'][index].favorited_id = fav.id;
							}
						});
					}
				});
			}
			this.dataSource = new MatTableDataSource(this._project['export_files']);
		} else { // TODO @remove temp-api-v2-routes
			const export_files = this._project.project_files?.filter(x => !!x.export);
			this._favoriteService.getList().then(favList => {
				if (Array.isArray(favList)) {
					favList.forEach(fav => {
						const index = export_files.findIndex(item => item.id === fav.project_file_id);
						if (index > -1) {
							export_files[index].favorited = true;
							export_files[index].favorited_id = fav.id;
						}
					});
				}
			});

			this.dataSource = new MatTableDataSource(export_files);
		}
		this._cdr.detectChanges();

	}	// End-of setup

	over(id: number): void {

		this.isHovered = id;

	}	// End-of over

	out(): void {

		this.isHovered = null;

	}	// End-of out

	favorite(item = this.activeItem): void {

		if (item.favorited) {
			this._favoriteService.remove(item.favorited_id).then(() => {
				item.favorited = false;
				this._alertService.notification(new Alert('Removed from Favorites'));
				analyticsLayer.trackFavorite("remove", item, "Export");
			});
		} else {
			this._favoriteService.create({ project_file_id: item.id }).then(rtnData => {
				item.favorited_id = rtnData.favorite_id;
				item.favorited = true;
				this._alertService.notification(new Alert('Added to Favorites', 'star_border'));
				analyticsLayer.trackFavorite("add", item, "Export");
			});
		}

	}	// End-of favorites

	download(file = this.activeItem): void {
		if (!isEmpty(file)) {
			this._downloadService.setStatus( "Downloading file, please do not refresh this page." );
			this.isDownloading = true;
			this._projectService.downloadFile( file ).then( rtnFile => {
				this._downloadService.downloadHelper( rtnFile, file.name );
				this.isDownloading = false;
				this._downloadService.clearStatus();
			} );
			analyticsLayer.trackDownload( file, "Export" );
		}
	}

	openMenu(e, i, data): void {
		e.preventDefault();
		this.contextMenuPosition.x = e.clientX + 'px';
		this.contextMenuPosition.y = e.clientY + 'px';
		this.activeIndex = i;
		this.activeItem = data;
		this.menuTrigger.openMenu();
		this._cdr.detectChanges();
	}

	onMenuClose() {
		this.activeIndex = null;
		this.activeItem = null;
	}

}	// End-of ExportsProjectsComponent
