
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import { Export } from '../models'

@Injectable()
export class ExportService {

	constructor(
		private httpService: HttpService
		) {

	}	// End-of constructor

	/**
	* [GETBYID]
	*
	* @param {number} exportId		ID of export
	*/
	public request(modelId: number, data: any): Promise<any> {

		let url = '/models/' + modelId + '/export';
		return this.httpService.post(url, data);

	}	// End-of getById

	public requestByLayers(requestPayload: any): Promise<any> {
		let url = '/exports/layers';
		return this.httpService.post(url, requestPayload);
	}

	/**
	* [GETBYID]
	*
	* @param {number} exportId		ID of export
	*/
	public getById(exportId: number): Promise<any> {

		let url = '/exports/' + exportId;
		return this.httpService.get(url);

	}	// End-of getById

	/**
	* [GETLIST]
	*
	*/
	public getList(): Promise<any> {

		let url = '/exports';
		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [UPDATE]
	*
	* @param {Export} exp		Export data
	*/
	public update(exp: Export): Promise<any> {

		let url = '/exports';
		return this.httpService.put(url, exp);

	}	// End-of update

	/**
	* [TRASH]
	*
	* @param {Export} exp		Export data
	*/
	public trash(exp: Export): Promise<any> {

		exp.trash = 1;
		return this.update(exp);

	}	// End-of trash

	/**
	* [RECOVER]
	*
	* @param {Export} exp		Export data
	*/
	public recover(exp: Export): Promise<any> {

		exp.trash = 0;
		return this.update(exp);

	}	// End-of trash

}	// End-of class ExportService
