<mat-card appearance="outline" class="opened" *ngIf="section">
	<mat-expansion-panel hideToggle (opened)="toggleOpen(true)" (closed)="toggleOpen(false)">
		<mat-expansion-panel-header class="no-select" expandedHeight="72px" collapsedHeight="64px"
			(contextmenu)="openMenu($event, section, true);">
			<mat-panel-title class="title">
				<div class="section-title">
					<mat-icon>expand_more</mat-icon>
					<mat-icon class="image-groups-icons">
						{{ !section.sensor ? 'image' : 'filter' }}
					</mat-icon>
					<span>{{section.name ?? ('- - -')}}</span>
					<span *ngIf="section.children?.length">({{section.children ? section.children.length : '0'}} {{ type | plural: section.children.length }})</span>
					<span class="sensor-info" *ngIf="section.sensor">Sensor: {{section.sensor | titlecase}}</span>
				</div>

				<div class="flex-row-center">
					<ng-container *ngIf="headerContent">
						<ng-container *ngTemplateOutlet="headerContent; context: { $implicit: section }"></ng-container>
					</ng-container>

					<ng-container *ngIf="menuContent">
						<ng-container *ngTemplateOutlet="t_menu; context: { $implicit: section }"></ng-container>
					</ng-container>

					<span class="select-cell" *ngIf="showSelect">
						<mat-checkbox [ngClass]="{'disabled': !section.children?.length}" data-testid="select-all"
							(click)="$event.stopPropagation()" [(ngModel)]="section.selected" matTooltip="Select batch">
						</mat-checkbox>
					</span>
				</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-template matExpansionPanelContent>
			<span class="content-container" #contentWrapper [ngClass]="{'disabled': showSelect && !section.selected}">
				<span class="content-inner bottom-border">
					<span *ngIf="isLoading" class="loading-container">
						<div class="dot-stretching"></div>
					</span>
					<ng-container *ngIf="!isLoading">
						<ng-content></ng-content>
					</ng-container>
				</span>
			</span>
		</ng-template>
	</mat-expansion-panel>
</mat-card>



<ng-template #t_menu>
	<div>
		<button data-testId="mat-menu-button" mat-icon-button tooltip="More" class="dialog-button"
			(click)="openMenu($event, section, false);">
			<mat-icon class="list-item-icon">more_horiz</mat-icon>
		</button>

		<button class="context-button" [ngStyle]="contextMenuStyle()" [matMenuTriggerFor]="itemMenu">
		</button>

		<mat-menu #itemMenu="matMenu" (closed)="menuOpen = false;">
			<ng-template matMenuContent>
				<ng-container *ngTemplateOutlet="menuContent; context: { $implicit: section }"></ng-container>
			</ng-template>
		</mat-menu>
	</div>
</ng-template>
