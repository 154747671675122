import React from "react";

import { api as ProjectsAPI } from "@app/react/apis";

import ProjectAnnotations from "./ProjectAnnotations";

export const Annotations = () => {
	const { data: projects } = ProjectsAPI.useGetListQuery();

	return (
		<div className="project-container">
			{projects?.map((project, index) => (
				<ProjectAnnotations
					project={project}
					key={"project-" + index}
				/>
			))}
		</div>
	);
};

export default Annotations;
