import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Model } from "@shared/models";
import { ActivatedRoute } from "@angular/router";

export interface OrthoPoint {
	lat: number;
	lng: number;
}

export interface Annotation {
	id?: string;
	text: string;
}

export interface NewMeasurement {
	points?: OrthoPoint[];
	annotations?: Annotation[];
}

export interface Measurement {
	id: string;
	model_id: string;
	model?: Model;
	name: string;
	type: string;
	details?: string;
	created_by_id: number;
	data: {
		points: OrthoPoint[];
		annotations: Annotation[];
	};
}

const url = ( modelId: number, measurementId: number | string = "" ): string =>
	`/models/${ modelId }/measurements/${ measurementId }`;

const wrapMeasurement = ( { points, annotations, name } ) => {
	const body = { data: {} };
	if ( name ) {
		// @ts-ignore
		body.name = name;
	}
	if ( points !== null ) { // null is intentionally empty, so we dont mistake it for [], which may be intentional
		// @ts-ignore
		body.data.points = points;
	}
	if ( annotations !== null ) {
		// @ts-ignore
		body.data.annotations = annotations;
	}
	return body;
};

const prepData = ( { points = [], annotations = [] } ) => ( {
	data: { points, annotations }
} );

interface CreateParams {
	modelId?: number;
	name?: string;
	type?: string;
	details?: string;
	points?: OrthoPoint[];
	annotations?: Annotation[];
}

interface UpdateParams {
	modelId?: number;
	measurementId: number;
	name?: string;
	details?: string;
	points?: OrthoPoint | any[];
	annotations?: Annotation;
}

function optionallyInclude( existingObj, valueObj = {} ) {
	if ( Object.values( valueObj ).every( ( v ) => v !== undefined ) ) {
		return { ...existingObj, ...valueObj };
	}
	return existingObj;
}

@Injectable()
export class MeasurementService {

	public _modelId: number;

	constructor( private httpService: HttpService, private _route: ActivatedRoute ) {
		this._route.queryParams.subscribe( ( { model } ) => {
			this._modelId = model;
		} );
	}	// End-of constructor

	public setModelId( modelId: number ) {
		this._modelId = modelId;
	}	// End-of setModelId

	public getList( modelId: number = this._modelId ): Promise<Measurement[]> {
		return this.httpService.get( url( modelId ) );
	}	// End-of getList

	public create( {
		               modelId = this._modelId,
		               name = "",
		               points = [],
		               annotations = []
	               }: CreateParams = { modelId: this._modelId } ): Promise<Measurement> {
		return this.httpService.post(
			url( modelId ),
			{
				name,
				data: {
					annotations,
					points
				}
			}
		);
	}	// End-of create

	public update( {
		               modelId = this._modelId,
		               measurementId,
		               name,
		               details,
		               points = null, // null is intentionally empty, so we dont mistake it for [], which may be intentional
		               annotations = null
	               }: UpdateParams ): Promise<Measurement> {
		return this.httpService.put(
			url( modelId, measurementId ),
			wrapMeasurement( { points, annotations, name } )
		);
	}	// End-of update

	public delete( {
		               modelId = this._modelId,
		               measurementId
	               }: UpdateParams ): Promise<void> {
		return this.httpService.delete( url( modelId, measurementId ) );
	}	// End-of delete
} // End-of class MeasurementService
