
<div class="list-item" [ngClass]="{'active-item' : menuOpen}" *ngIf="project; else t_skeleton" (contextmenu)="openMenu($event)">
	<div class="list-image-container" (click)="clickedImage($event)">
		<ng-container *ngIf="project.thumbnail">
			<img class="list-image"
				[src]="rootURL + project.thumbnail">
		</ng-container>
		<ng-container *ngIf="!project.thumbnail">
			<div class="P2Black500" style="margin: auto;">
				No model processed
			</div>
		</ng-container>
	</div>

	<div class="list-item-text-container">
		<div style="display: flex;">
			<div class="list-item-title"
				(click)="openItem()"
				>
				{{ _project.name ? _project.name : 'No Project Name' }}
			</div>
			<ng-container *featureEnabled="'!disable-sharing'">
				<button *ngIf="_project['shares']?.length"
						data-testid="shared-button"
						matTooltip="{{getSharedTooltip(_project)}}"
						mat-icon-button
						class="shared-button"
						(click)="clickedIcon($event, 'share')">
					<mat-icon class="shared-icon">link</mat-icon>
				</button>
			</ng-container>

			<mat-icon matTooltip="Favorited" class="favorited-icon" *ngIf="_project['favorited']">star_border</mat-icon>
		</div>

		<div class="list-item-text">
			<div *ngIf="showCreatedAt" class="description-container">
				<span>Created on:</span>
				<span>{{_project.created_at ? (_project.created_at | date) : 'Never'}}</span>
			</div>
			<div *ngIf="!showCreatedAt" class="description-container">
				<span>Last modified on:</span>
				<span>{{_project.updated_at ? (_project.updated_at | date) : 'Never'}}</span>
			</div>
		</div>
		<div class="list-item-text" *featureEnabled="'!temp-api-v2-routes'">
			<div class="description-container">
				<span>Project ownership:</span>
				<span>{{project.organization ? project.organization.name : ' Unknown owner'}}</span>
			</div>
		</div>
		<div class="list-item-text" *featureEnabled="'temp-api-v2-routes'">
			<div class="description-container project-links">
				<span (click)="openItem(0)">{{ project.models_count }} {{ plural('Model', project.models_count) }}</span>
				<span (click)="openItem(2)">{{ project.batches_count }} Image {{ plural('Group', project.batches_count) }}</span>
				<span (click)="openItem(3)">{{ project.project_files_count }} {{ plural('Files', project.project_files_count) }}</span>
				<span (click)="openItem(4)">{{ project.exports_count }} {{ plural('Exports', project.exports_count) }}</span>
			</div>
		</div>
		<!-- <div class="list-item-text">
			Tags:
			<div class="list-item-tags">
				<span *ngIf="tags.length > 0" class="tag-text">
					{{tagsText}}
				</span>
				<ng-container *ngIf="tags.length > 4">
					...
					<button mat-button (click)="clickedIcon($event, 'tags')">
						<span class="none-button"> {{tags.length-4}} more</span>
					</button>
				</ng-container>
				<span *ngIf="tags.length <= 0" class="tag-text">None</span>
			</div>
		</div> -->
	</div>

	<div class="menu-container">

		<button
			data-testId="options-menu"
			style="margin: auto;"
			mat-icon-button tooltip="More"
			class="dialog-button"
			(click)="openMenu($event);">
			<mat-icon class="list-item-icon">more_horiz</mat-icon>
		</button>

		<button
			class="context-button"
			[ngStyle]="contextMenuStyle()"
			[matMenuTriggerFor]="itemMenu">
		</button>

		<mat-menu
			id="itemMenu"
			#itemMenu="matMenu"
			(closed)="menuOpen = false;">
			<ng-template matMenuContent>
				<ng-content></ng-content>
			</ng-template>
		</mat-menu>
	</div>
</div>

<ng-template #t_skeleton>
	<div class="skel-row-item">
		<div class="skel-list-image-container skel-shimmer"></div>

		<div class="skel-list-text-container">
			<div class="skel-list-top">
				<div class="skel-title skel-shimmer"></div>
			</div>

			<div class="skel-text-date skel-shimmer"></div>
		</div>

		<div class="skel-list-options">
			<div class="skel-text-tag skel-shimmer"></div>
		</div>
	</div>
</ng-template>
