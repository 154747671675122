
import { Component, Input, Output, EventEmitter } from '@angular/core';
import pluralize from "pluralize";

@Component({
	selector: 'app-image-group-label',
	templateUrl: './image-group-label.component.html',
	styleUrls: ['./image-group-label.component.scss']
})
export class ImageGroupLabelComponent {
	@Input() imageGroup;
	@Input() active = false;
	@Output('outEvent') outEventEmitter: EventEmitter<any> = new EventEmitter();
	@Input() hasPermissions;

	public project;
	public isLoading = true;
	public pluralize = pluralize;

	constructor() {}

	openMenu(e, usePosition): void {

		e.preventDefault();
		e.stopPropagation();
	}

	outEvent(type): void {
		this.outEventEmitter.emit(type);
	}

}
