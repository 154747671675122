import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bytes'
})
export class BytesPipe implements PipeTransform {

	transform(bytes: number, style: 'bytes' | 'pixels' = 'bytes', decimals: number = 1): string {
		let endings: Array<string>;
		let power: number;
		if (style === 'bytes') {
			endings = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			power = 1024;
		} else {
			endings = ['Pixels', 'KP', 'MP', 'GP', 'TP'];
			power = 1000;
		}

		if (bytes === 0) { return '0' + ' ' + endings[0]; }
		decimals = decimals < 0 ? 0 : decimals;

		const base = Math.floor(Math.log(bytes) / Math.log(power));

		return (bytes / Math.pow(power, base)).toFixed(decimals) + ' ' + endings[base];
	}

}
