<div class="image-groups-container">
	<div class="image-groups">
		<div class="image-groups-header">
			<button mat-button class="image-group-sort" (click)="sortImageGroups()">
				Most Recent
				<mat-icon [ngClass]="{'flipped': sortByNewest}">
					keyboard_arrow_down
				</mat-icon>
			</button>
			<!-- ngClass "disabled" here for permissions in future -->
			<button (click)="openCreate()" mat-icon-button class="create-imageGroup" matTooltip="Create Image Group"
					[disabled]="!hasPermissions"
				matTooltipShowDelay="500" [ngClass]="{'disabled': !project?.id}">
				<mat-icon>add</mat-icon>
			</button>
		</div>
		<div class="image-group-list">
			<ng-container *ngFor="let imageGroup of imageGroups">
				<app-image-group-label
					(click)="selectImageGroup(imageGroup)"
					(outEvent)="outEvent($event, imageGroup)"
					[imageGroup]="imageGroup"
					[active]="selectedImageGroup?.id === imageGroup.id"
					[hasPermissions]="hasPermissions">
				</app-image-group-label>
			</ng-container>
		</div>
	</div>
	<div class="image-group" *ngIf="selectedImageGroup">
		<app-image-group
			[imageGroup]="selectedImageGroup"
			[editName]="editName"
			[project]="project"
			[disableMove]="disableMove || imageGroups.length <= 1"
			(outEvent)="outEvent($event, selectedImageGroup)"
			(imageGroupChange)="updateImageGroup($event)"
			[hasPermissions]="hasPermissions">
		</app-image-group>
	</div>
	<div class="info" *ngIf="!selectedImageGroup && hasPermissions">
		<p>
			Select an Image Group from the left or
			<span class="link" (click)="openCreate()">create a new one</span>.
		</p>
	</div>
	<div class="info" *ngIf="!selectedImageGroup && !hasPermissions">
		<p>
			This project has no Image Groups.
		</p>
	</div>
</div>
<div class="image-groups-container" *ngIf="!imageGroups?.length">
	<div class="info">
		<p>Upload Images to create an Image Group</p>
	</div>
</div>
