import { createTheme, ThemeOptions } from "@mui/material/styles";

/**
 * We needed to add values to the theme so we had to override the existing one
 * https://stackoverflow.com/questions/70002471/mui-v5-extending-typography-variant-in-typescript-creates-error-no-overload-m
 */
type IThemeOptions = ThemeOptions;

export const themeDef = {
	palette: {
		error: {
			main: "#EB6F65",
		},
		primary: {
			contrastText: "#fff",
			main: "#004BEE",
		},
		secondary: {
			main: "#FFBB54",
		},
		success: {
			main: "#A2FFBC",
		},
		warning: {
			main: "#E3D06D",
		},
	},
	typography: {
		caption: {
			color: "#768396",
			fontSize: 12,
		},
		display1: {
			fontSize: 72,
		},
		display2: {
			fontSize: 64,
		},
		fontFamily: "Muli",
		fontSize: 28,
		h1: {
			fontSize: 47,
		},
		h2: {
			fontSize: 37,
		},
		h3: {
			fontSize: 29,
		},
		h4: {
			fontSize: 23,
		},
		h5: {
			fontSize: 18,
		},
		h6: {
			fontSize: 16,
		},
		subtitle1: {
			fontSize: 18,
		},
	},
	// overrides: {
	// 	MuiButton: {
	// 		raisedPrimary: {
	// 			color: 'white',
	// 		},
	// 	},
	// }
} as IThemeOptions;

export const theme = createTheme(themeDef);
