
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import { ScaleConstraint, ScaleConstraintImage, Project, Image } from '../models';

@Injectable()
export class ScaleConstraintService {

	constructor(
		private httpService: HttpService
	) {

	}	// End-of constructor

	/**
	* [GETLISTFROMPROJECT]
	*
	*/
	public getListFromProject(project: Project | number): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		
		let url = '/projects/' + projectId + '/scale_constraints';

		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [CREATE]
	*
	* @param {ScaleConstraint} scale_constraint		ScaleConstraint data
	*/
	public create(project: Project | number, scale_constraint: ScaleConstraint): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;

		let url = '/projects/' + projectId + '/scale_constraints';

		return this.httpService.post(url, scale_constraint);

	}	// End-of create

	/**
	* [UPDATE]
	*
	* @param {ScaleConstraint} scale_constraint		ScaleConstraint data
	*/
	public update(project: Project | number, scale_constraint: ScaleConstraint): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let scale_constraint_id = ((typeof scale_constraint === 'object') ? scale_constraint["id"] : scale_constraint);

		let url = '/projects/' + projectId + '/scale_constraints/' + scale_constraint_id;

		return this.httpService.put(url, scale_constraint);

	}	// End-of update

	/**
	* [REMOVE]
	*
	* @param {ScaleConstraint | number} scale_constraint		ScaleConstraint data or id
	*/
	public remove(project: Project | number, scale_constraint: ScaleConstraint): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let scale_constraint_id = ((typeof scale_constraint === 'object') ? scale_constraint["id"] : scale_constraint);

		let url = '/projects/' + projectId + '/scale_constraints/' + scale_constraint_id;

		return this.httpService.delete(url);

	}	// End-of remove

	/**
	* [ADDCONSTRAINTTOIMAGE]
	*
	* @param {number} projId		ID of scale_constraint
	*/
	public addConstraintToImage(project: Project | number, scale_constraint: ScaleConstraint | number, scale_constraint_image: ScaleConstraintImage): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let scale_constraint_id = ((typeof scale_constraint === 'object') ? scale_constraint["id"] : scale_constraint);
		let scale_constraint_image_id = scale_constraint_image["image_id"];

		let url = '/projects/' + projectId + '/scale_constraints/' + scale_constraint_id + '/images/' + scale_constraint_image_id;

		return this.httpService.post(url, scale_constraint_image);

	}	// End-of getImagesById

	/**
	* [REMOVECONSTRAINTFROMIMAGE]
	*
	* @param {number} projId		ID of scale_constraint
	*/
	public removeConstraintFromImage(project: Project | number, scale_constraint: ScaleConstraint | number, scale_constraint_image: ScaleConstraintImage): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let scale_constraint_id = ((typeof scale_constraint === 'object') ? scale_constraint["id"] : scale_constraint);
		let scale_constraint_image_id = scale_constraint_image["image_id"];
		let scale_constraint_image_point = scale_constraint_image["point"];

		let url = '/projects/' + projectId + '/scale_constraints/' + scale_constraint_id + '/images/' + scale_constraint_image_id + '?point=' + scale_constraint_image_point;

		return this.httpService.delete(url);

	}	// End-of getImagesById

	/**
	* [UPDATECONSTRAINTINIMAGE]
	*
	* @param {number} projId		ID of scale_constraint
	*/
	public updateConstraintInImage(project: Project | number, scale_constraint: ScaleConstraint | number, scale_constraint_image: ScaleConstraintImage): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let scale_constraint_id = ((typeof scale_constraint === 'object') ? scale_constraint["id"] : scale_constraint);
		let scale_constraint_image_id = scale_constraint_image["image_id"];

		let url = '/projects/' + projectId + '/scale_constraints/' + scale_constraint_id + '/images/' + scale_constraint_image_id;

		return this.httpService.put(url, scale_constraint_image);

	}	// End-of getImagesById

}	// End-of class ScaleConstraintService
