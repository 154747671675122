
/* Imports */
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Inject, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from "lodash";
import { RegisterUserComponent } from './children/user/register-user.component';

/* Services */
import { AuthenticationService, FinanceService, UserService, AlertService, OrganizationService, SessionService, UtilsService, TitleService } from '../../shared/services';

import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

/* Models */
import { User } from '@shared/models';

/* Providers */
import { GoogleProvider } from '@shared/providers';
import { environment } from '@/environments/environment';

const handleModal = (targetModal, modalType, data, afterClose = _.noop) => {
	targetModal.open(modalType, { data }).afterClosed().subscribe(afterClose);
};
const hasData = (obj: Object) => Object.values(obj).some(Boolean);

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

	@ViewChild('registerStepper') registerStepper: MatStepper;
	@ViewChild('registerUserForm') registerUserForm: RegisterUserComponent;
	@Output('close') closeEvent: EventEmitter<any> = new EventEmitter();
	@Input('selectedTeam') selectedTeam: any = null;

	public gButtonText: string = "Sign up with Google";
	public googleAuthAvailable: boolean = true;
	public registerForm: FormGroup;
	public isRegistered: boolean = false;
	public stateList: Array<any> = [];
	public selectedState: any;
	public isProcessing: boolean = false;
	public termsAgreement: boolean = false;
	public showCreditsDetail: boolean = false;
	public orgName: string = "Mapware";
	public invitation_id: string = null;
	public freeCredits: number = environment.freeCredits;
	public user: any;
	public emailAlreadyRegistered: boolean = false;

	// TODO: @remove remove-subscription-required : refactor
	titleList: Array<string> = [
		'Start your subscription',
		'Select your billing plan',
		'Enter your payment information',
		'Confirm your information'
	];

	// TODO: @remove remove-subscription-required : variables below marked for deprecation
	// code which references these variables should be removed
		public paymentForm: FormGroup;
		public displayPlan = {
			id: null
		};
		public freeToPaid = false;
		public cbValidCard: boolean = false;
		public professionalPlan: any;
		public purchaseError: string = null;
		public subscribedError: string = null;

		CREDITCARD_STEP = 2;

		//chargebee stuff:
		cardComponent: any;
		token: string;
		cbError: string;
	// TODO: @remove-end remove-subscription-required

	@ViewChild("googleRegister") googleElement: ElementRef;

	constructor(
		public dialog: MatDialog,
		private _userService: UserService,
		private _authenticationService: AuthenticationService,
		private _activatedRoute: ActivatedRoute,
		private _financeService: FinanceService, // TODO: @remove remove-subscription-required : _financeService
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _changeDetectorRef: ChangeDetectorRef,
		private _orgService: OrganizationService,
		private _ngZone: NgZone,
		private _alertService: AlertService,
		private _titleService: TitleService
	) {

		/* TODO: @remove remove-subscription-required : rename the titles
		 * override the initial titleList with this list and remove this block
		*/
		if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
			this.titleList = [
				'Create your account',
				'Welcome'
			];

			// there is no creditcard step in this flag config
			this.CREDITCARD_STEP = 555;
		}

		this.user = this._authenticationService.user;
		if (!_.isNil(this.user) && !_.isNil(this.user.email)) {
			this.isRegistered = true;
		}

		if (this._router.url.split('/')[1] != 'register' && this.user?.id) {
			this.titleList.splice(0, 1);
			this.freeToPaid = true;
		}

		this._financeService.getPlans().then(rtnPlans => {
			this.professionalPlan = rtnPlans.find(x => {
				return x.name === "Professional";
			});
		});

		this._activatedRoute.queryParams.subscribe(params => {
			if (params['invitation_id']) {
				this.invitation_id = params['invitation_id'];
				this.isProcessing = true;
				this._userService.getInvitation(params.invitation_id).then(invitation => {
					if (invitation?.active) {
						this.isProcessing = false;

						// set email and disable email input field
						this.registerForm.controls.email.setValue(invitation.email);
						this.registerForm.controls.email.disable();

						// disable google auth
						this.googleAuthAvailable = false;

						// set the orgName from the invitation object
						this.orgName = invitation.organization.name;
					} else {
						this._router.navigateByUrl('/support/no_invite');
					}
				}).catch(error => {
					this._router.navigateByUrl('/support/no_invite');
				});
			} else {
				if(flagLayer.isEnabled(availableFeatureFlags.disableSignup)) {
					this._router.navigateByUrl("/");
				}
			}
		});

		this.setupForms({});
	}	// End-of constructor

	ngOnInit() {

		this._titleService.setTitle('Register');
		this.stateList = UtilsService.stateList;
		this.selectedState = this.stateList[0].abbreviation;
		this.checkStoredData();
		this._changeDetectorRef.detectChanges();

	}	// End-of OnInit

	// TODO: @remove remove-subscription-required : tokenize()
	tokenize() {
		// Use card component instance to call the tokenize method
		this.cardComponent.tokenize().then((cbData) => {
			this.paymentForm.value.cardType = this.cardComponent.fields[0].status.cardType;
			this.token = cbData.token;
			this.cbError = '';
			this.registerStepper.next();
			this._changeDetectorRef.detectChanges();
		}).catch((error) => {
			this.cbError = 'Problem validating your card\'s details';
			this.token = '';
		});
	}

	// TODO: @remove remove-subscription-required
	// Receive the cardComponent instance on ready event
	setComponent(cardComponent) {
		// @ts-ignore
		this.cardComponent = cardComponent;
	}

	// TODO: @remove remove-subscription-required
	cbIsCardComplete = (currentState) => {
		this.cbValidCard = currentState.complete;
		currentState.complete && this.enableNext();
		this._changeDetectorRef.detectChanges(); // Added to ensure Angular updates the html variables
		// It's not an instantaneous check, Angular's lifecycle checks the changeDetector roughly once every 2-3 seconds,
		// hence why sometimes the button would time to update. detectChanges triggers it immediately, updating the button status
	}

	ngOnDestroy() {
		if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
			if (hasData(this.registerForm.value)) {
				SessionService.set('registration_info', {
					registerForm: this.registerForm.value,
				}, false);
			}
		} else { // TODO: @remove remove-subscription-required : else-block
			this.paymentForm.patchValue({ cvc: null }); // Removing CVC for legal reasons
			this.paymentForm.value.address_state === 'AL' && this.paymentForm.patchValue({ address_state: null }); // Removing state if it's "AL", because it's defined by default as "AL"
			if (hasData(this.paymentForm.value) || hasData(this.registerForm.value)) {
				SessionService.set('registration_info', {
					displayPlan: this.displayPlan,
					paymentForm: this.paymentForm.value,
					registerForm: this.registerForm.value,
				}, false);
			}
		}
	}

	ngAfterViewInit() {

		if (!this.freeToPaid) {
			let googleProvider = <GoogleProvider>this._authenticationService.getProvider(GoogleProvider.PROVIDER_ID);
			googleProvider.customButton('my-signup2', (d) => this.googleOnSuccess(d), (d) => this.googleOnFailure(d)).catch((e) => {
				this.googleAuthAvailable = false;
			});
		}
		this._changeDetectorRef.detectChanges();
	}

	checkStoredData(): void {

		// TODO: Re-enable in the future
		// this.setupForms({}); // To create forms initially
		// const data = SessionService.get('registration_info', false);
		// if (data) {
		// 	const confirmOptions = {
		// 		title: `Use previous form info?`,
		// 		text: `Would you like to use the information you had already entered?`,
		// 		cancelText: `No, start from scratch`,
		// 		buttonText: `Yes`,
		// 	};
		// 	this.openModal(ConfirmationModal, confirmOptions, (userConfirmation) => {
		// 		if (userConfirmation) {
		// 			this.displayPlan = data.displayPlan;
		// 			this.setupForms(data);
		// 		}
		// 		else{location.reload();}
		// 	});
		// } else {
			//this.setupForms({});
		// }

		const googleAccountData = SessionService.get("google_account_data", true);
		if(googleAccountData) {
			this.titleList[0] = "Register with your Google account";
			this.fillOutFormFromGoogleProfile(googleAccountData);
			// should auto next?
		}
	}

	setupForms(data): void {

		// TODO @remove remove-subscription-required : paymentForm
		const paymentForm = data.paymentForm || {};
		const registerForm = data.registerForm || {};

		// removing the billing address info from the paymentForm, but keeping the form to store some info from chargebee
		this.paymentForm = this._formBuilder.group({
			//first_name: [registerForm.first_name || '', [Validators.required, Validators.pattern("[a-zA-Z]{1,}")]],
			//last_name: [registerForm.last_name || '', [Validators.required, Validators.pattern("[a-zA-Z]{1,}")]],
			//address_line1: [paymentForm.address_line1 || '', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9 .()\/#+-]+)$")]], //Can't prefectly verify an address with regex, but can require a mininum of 1 number and 1 char and prevent certain special chars
			//address_city: [paymentForm.address_city || '', [Validators.required, Validators.pattern("[a-zA-Z .-]{2,}")]],
			//address_state: [UtilsService.stateList[0].abbreviation ? UtilsService.stateList[0].abbreviation : paymentForm.address_state || '', [Validators.required]],
			//address_zip: [paymentForm.address_zip || '', [Validators.required]],
		});

		this.registerForm = this._formBuilder.group({
			first_name: [registerForm.first_name || '', [Validators.required, Validators.pattern("[a-zA-Z]{1,}")]],
			last_name: [registerForm.last_name || '', [Validators.required, Validators.pattern("[a-zA-Z]{1,}")]],
			email: [registerForm.email || '', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
			password: [registerForm.password || '', [Validators.required, Validators.minLength(8)]],
		});

	}	// End-of setupForms

	appRegister(): void {
		// Prevent resubmission
		if (!this.isProcessing) {
			this.isProcessing = true;
			let user = new User(this.registerForm.value);

			if (this.invitation_id) {
				user["invitation_id"] = this.invitation_id;

				// because the email input is disabled, we have to pull the value from the controls
				user.email = this.registerForm.controls.email.value;
			}

			// If we fail to get the info, bork it all!
			if (!user.first_name || !user.last_name || !user.email) {
				return;
			}

			// reset error states
			this.emailAlreadyRegistered = false;

			if (user.google_token) {
				this.googleRegistration(user);
			} else {
				this.apiRegistration(user);
			}
		}
	}	// End-of appRegister

	googleRegistration(user) {
		this._userService
			.create(user, this.displayPlan.id, this.token)
			.then(() => {
				// Sign in and redirect to landing, which should drop the new user in the app
				this._authenticationService.loginByToken(GoogleProvider.PROVIDER_ID, user.google_token)
					.then(() => {

						if(flagLayer.isEnabled(availableFeatureFlags.disableSignup)) {
							// Skip the Welcome step of the registration
							this.registerForm.reset();
							this.navigateToMapware();
							return;
						}

						// TODO @remove remove-subscription-required
						if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
							this.titleList[1] = 'Welcome ' + user.first_name;
							this.invitation_id ? this.navigateToMapware() : this.navigateToVerification();
						} else {
							this.registerForm.reset();
							this.navigateToMapware();
						}
					});
			})
			.catch((error) => {
				if (error === 412) {
					this._alertService.notify(
						"Account already exists, logging in."
					);
					// Navigate back to intended view
					this._router.navigate(['projects']);
				}

				const errorMessageOptions = {
					412: "Account already exists",
					400: "Rejected:",
					401: "Unauthenticated provider:",
					_default: "Register:"
				};

				console.warn(
					errorMessageOptions[error] ?? errorMessageOptions._default,
					error
				);
				this.isProcessing = false;
			});
	}

	apiRegistration(user) {
		this._userService
			.create(user, this.displayPlan.id, this.token)
			.then(() => {
				this._authenticationService
					.loginByEmail(
						this.registerForm.controls.email.value,
						this.registerForm.value.password
					).then(() => {

					// TODO @remove remove-subscription-required
					if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
						this.titleList[1] = 'Welcome ' + user.first_name;
						this.invitation_id ? this.navigateToMapware() : this.navigateToVerification();
					} else {
						this.registerForm.reset();
						this.navigateToMapware();
					}
				});
			})
			.catch((error) => {
				this.isProcessing = false;
				if (error === 412) {
					this.emailAlreadyRegistered = true;
				} else {
					this._alertService.notify(
						"An error occurred, please ensure the email was entered correctly.",
						"error"
					);
					console.warn(error);
				}
			});
	}

	navigateToVerification(): void {
		this._router.navigate(['/verification'])
	}

	navigateToMapware(): void {
		this._router.navigate(['/']);
	}

	googleOnClick(): void {
		// Start loading animation
		this.isProcessing = true;

	}	// End-of googleOnClick

	private googleOnSuccess(data): void {

		// Get provider to decipher data
		let gprovider = this._authenticationService.getProvider(GoogleProvider.PROVIDER_ID);

		// Get Token
		let token = gprovider['auth'].currentUser.get().getAuthResponse(true).id_token;
		let profile = gprovider['auth'].currentUser.get().getBasicProfile();

		this.fillOutFormFromGoogleProfile({
			email: profile.getEmail(),
			first_name: profile.getGivenName(),
			last_name: profile.getFamilyName(),
			token: token,
		});

		if(!flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
			this.nextStep();
		}
	}	// End-of googleOnSuccess

	private fillOutFormFromGoogleProfile(profileData): void {

		const {email, first_name, last_name, token, avatar} = profileData;

		this.gButtonText = email;

		this.registerForm = this._formBuilder.group({
			first_name: [first_name, [Validators.required]],
			last_name: [last_name, [Validators.required]],
			email: [email, [Validators.required]],
			password: [''],
			google_token: [token, [Validators.required]],
			avatar: [avatar]
		});
		this.registerForm.disable();
		this._changeDetectorRef.detectChanges();
	}

	private googleOnFailure(data): void {

		this.isProcessing = false;
		this._changeDetectorRef.detectChanges();
		console.error("Google Login: ", data);

	}	// End-of googleOnFailure

	nextStep(): void {

		// if on the creditcard input step, then tokenize the card thru chargebee
		if (this.registerStepper.selectedIndex === this.CREDITCARD_STEP ){
			this.tokenize();
		}
		else {

			// TODO: @remove remove-subscription-required
			if(!flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement) && this.invitation_id) {
				// jump to confirmation
				// this.registerStepper.selectedIndex = 3;
			} else {
				this.registerStepper.next();
			}
			this._changeDetectorRef.detectChanges();
		}
	}	// End-of nextStep

	prevStep(): void {

		this.registerStepper.previous();
		this._changeDetectorRef.detectChanges();

	}	// End-of prevStep

	returnToAccount(): void {

		this.closeEvent.emit('account');

	}	// End-of

	addSteps(steps: number): void {
		if (this.registerStepper) {
			// this.registerStepper.selectedIndex += steps;
		}
	}

	enableNext(): boolean {
		if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {

			return this?.registerForm?.valid || this?.registerForm?.disabled;

		} else { // TODO: @d: else-block marked for deprecation: remove-subscription-required
			if (this.registerStepper) {
				// Move up an index if the first page is hidden
				const index = this.registerStepper.selectedIndex + ((this.freeToPaid || this.isRegistered) ? 1 : 0);
				return (
					[
						() => this.registerForm.valid || this.registerForm.disabled,
						() => true,
						() => this.cbValidCard,
						() => true,
					][index]() ?? false
				);
			}
		}

		return false;
	}	// End-of enableNext

	openLink(link: 'termsOfService' | 'privacyPolicy' | 'cookiePolicy'): void {
		window.open(environment[link], "_blank");
	}

	navigateToLogin(): void {
		this._authenticationService.logout().then(rtn => {
			setTimeout(() => { this._router.navigateByUrl('/login'); }, 100);
		});
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	toggleCreditsDetail(): void {
		this.showCreditsDetail = !this.showCreditsDetail;
	}

	getTitle = (index) =>
		 this.titleList[_.isNil(index) ? 0 : index];

	enableRegisterButton = (registerForm, termsAgreement, isProcessing, emailAlreadyRegistered) =>
		(registerForm.valid || registerForm.disabled) && termsAgreement && !isProcessing && !emailAlreadyRegistered && this.registerUserForm?.passwordReqsMet;

}	// End-of class RegisterComponent


@Component({
	selector: 'app-register-modal',
	templateUrl: './register.modal.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterModal implements OnInit {

	@ViewChild("googleRegister") googleElement: ElementRef;
	public selectedTeam: any;

	constructor(
		public _dialogRef: MatDialogRef<RegisterComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.selectedTeam = data;

	}	// End-of constructor

	ngOnInit() {

	}

	handleClose(e): void {
		this._dialogRef.close(e);
	}

}
