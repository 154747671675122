var e = this && this.__spreadArray || function (e, r) {
    for (var t = 0, n = r.length, u = e.length; t < n; t++, u++) e[u] = r[t];
    return e;
  },
  r = Object.create,
  t = Object.defineProperty,
  n = Object.defineProperties,
  u = Object.getOwnPropertyDescriptor,
  i = Object.getOwnPropertyDescriptors,
  o = Object.getOwnPropertyNames,
  s = Object.getOwnPropertySymbols,
  c = Object.getPrototypeOf,
  a = Object.prototype.hasOwnProperty,
  f = Object.prototype.propertyIsEnumerable,
  l = function (e, r, n) {
    return r in e ? t(e, r, {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    }) : e[r] = n;
  },
  d = function (e, r) {
    for (var t in r || (r = {})) a.call(r, t) && l(e, t, r[t]);
    if (s) for (var n = 0, u = s(r); n < u.length; n++) f.call(r, t = u[n]) && l(e, t, r[t]);
    return e;
  },
  p = function (e, r) {
    return n(e, i(r));
  },
  v = function (e) {
    return t(e, "__esModule", {
      value: !0
    });
  },
  y = function (e, r, n) {
    if (r && "object" == typeof r || "function" == typeof r) for (var i = function (i) {
        a.call(e, i) || "default" === i || t(e, i, {
          get: function () {
            return r[i];
          },
          enumerable: !(n = u(r, i)) || n.enumerable
        });
      }, s = 0, c = o(r); s < c.length; s++) i(c[s]);
    return e;
  },
  b = function (e) {
    return y(v(t(null != e ? r(c(e)) : {}, "default", e && e.__esModule && "default" in e ? {
      get: function () {
        return e.default;
      },
      enumerable: !0
    } : {
      value: e,
      enumerable: !0
    })), e);
  };
v(exports), function (e, r) {
  for (var n in r) t(e, n, {
    get: r[n],
    enumerable: !0
  });
}(exports, {
  ApiProvider: function () {
    return W;
  },
  createApi: function () {
    return J;
  },
  reactHooksModule: function () {
    return F;
  },
  reactHooksModuleName: function () {
    return N;
  }
});
var g = b(require("@reduxjs/toolkit/query")),
  h = b(require("@reduxjs/toolkit")),
  m = b(require("react")),
  q = b(require("@reduxjs/toolkit/query")),
  O = b(require("react-redux")),
  S = b(require("react"));
function k(e, r, t, n) {
  var u = (0, S.useMemo)(function () {
      return {
        queryArgs: e,
        serialized: "object" == typeof e ? r({
          queryArgs: e,
          endpointDefinition: t,
          endpointName: n
        }) : e
      };
    }, [e, r, t, n]),
    i = (0, S.useRef)(u);
  return (0, S.useEffect)(function () {
    i.current.serialized !== u.serialized && (i.current = u);
  }, [u]), i.current.serialized === u.serialized ? i.current.queryArgs : e;
}
var E = Symbol(),
  j = b(require("react")),
  M = b(require("react-redux"));
function x(e) {
  var r = (0, j.useRef)(e);
  return (0, j.useEffect)(function () {
    (0, M.shallowEqual)(r.current, e) || (r.current = e);
  }, [e]), (0, M.shallowEqual)(r.current, e) ? r.current : e;
}
var Q,
  w,
  A = b(require("@reduxjs/toolkit")),
  R = WeakMap ? new WeakMap() : void 0,
  L = function (e) {
    var r = e.endpointName,
      t = e.queryArgs,
      n = "",
      u = null == R ? void 0 : R.get(t);
    if ("string" == typeof u) n = u;else {
      var i = JSON.stringify(t, function (e, r) {
        return (0, A.isPlainObject)(r) ? Object.keys(r).sort().reduce(function (e, t) {
          return e[t] = r[t], e;
        }, {}) : r;
      });
      (0, A.isPlainObject)(t) && (null == R || R.set(t, i)), n = i;
    }
    return r + "(" + n + ")";
  },
  z = "undefined" != typeof window && window.document && window.document.createElement ? m.useLayoutEffect : m.useEffect,
  P = function (e) {
    return e;
  },
  C = function (e) {
    return e.isUninitialized ? p(d({}, e), {
      isUninitialized: !1,
      isFetching: !0,
      isLoading: void 0 === e.data,
      status: q.QueryStatus.pending
    }) : e;
  };
function D(e) {
  return e.replace(e[0], e[0].toUpperCase());
}
function I(r) {
  for (var t = [], n = 1; n < arguments.length; n++) t[n - 1] = arguments[n];
  Object.assign.apply(Object, e([r], t));
}
(w = Q || (Q = {})).query = "query", w.mutation = "mutation";
var _ = b(require("react-redux")),
  N = Symbol(),
  F = function (e) {
    var r = void 0 === e ? {} : e,
      t = r.batch,
      n = void 0 === t ? _.batch : t,
      u = r.useDispatch,
      i = void 0 === u ? _.useDispatch : u,
      o = r.useSelector,
      s = void 0 === o ? _.useSelector : o,
      c = r.useStore,
      a = void 0 === c ? _.useStore : c,
      f = r.unstable__sideEffectsInRender,
      l = void 0 !== f && f;
    return {
      name: N,
      init: function (e, r, t) {
        var u = e,
          o = function (e) {
            var r = e.api,
              t = e.moduleOptions,
              n = t.batch,
              u = t.useDispatch,
              i = t.useSelector,
              o = t.useStore,
              s = e.serializeQueryArgs,
              c = e.context,
              a = t.unstable__sideEffectsInRender ? function (e) {
                return e();
              } : m.useEffect;
            return {
              buildQueryHooks: function (e) {
                var t = function (t, n) {
                    var i = void 0 === n ? {} : n,
                      o = i.refetchOnReconnect,
                      s = i.refetchOnFocus,
                      f = i.refetchOnMountOrArgChange,
                      l = i.skip,
                      d = void 0 !== l && l,
                      p = i.pollingInterval,
                      v = void 0 === p ? 0 : p,
                      y = r.endpoints[e].initiate,
                      b = u(),
                      g = k(d ? q.skipToken : t, L, c.endpointDefinitions[e], e),
                      h = x({
                        refetchOnReconnect: o,
                        refetchOnFocus: s,
                        pollingInterval: v
                      }),
                      O = (0, m.useRef)(!1),
                      S = (0, m.useRef)(),
                      E = S.current || {},
                      j = E.queryCacheKey,
                      M = E.requestId,
                      Q = !1;
                    if (j && M) {
                      var w = b(r.internalActions.internal_probeSubscription({
                        queryCacheKey: j,
                        requestId: M
                      }));
                      Q = !!w;
                    }
                    var A = !Q && O.current;
                    return a(function () {
                      O.current = Q;
                    }), a(function () {
                      A && (S.current = void 0);
                    }, [A]), a(function () {
                      var e,
                        r = S.current;
                      if (g === q.skipToken) return null == r || r.unsubscribe(), void (S.current = void 0);
                      var t = null == (e = S.current) ? void 0 : e.subscriptionOptions;
                      if (r && r.arg === g) h !== t && r.updateSubscriptionOptions(h);else {
                        null == r || r.unsubscribe();
                        var n = b(y(g, {
                          subscriptionOptions: h,
                          forceRefetch: f
                        }));
                        S.current = n;
                      }
                    }, [b, y, f, g, h, A]), (0, m.useEffect)(function () {
                      return function () {
                        var e;
                        null == (e = S.current) || e.unsubscribe(), S.current = void 0;
                      };
                    }, []), (0, m.useMemo)(function () {
                      return {
                        refetch: function () {
                          var e;
                          if (!S.current) throw new Error("Cannot refetch a query that has not been started yet.");
                          return null == (e = S.current) ? void 0 : e.refetch();
                        }
                      };
                    }, []);
                  },
                  l = function (t) {
                    var i = void 0 === t ? {} : t,
                      o = i.refetchOnReconnect,
                      s = i.refetchOnFocus,
                      c = i.pollingInterval,
                      f = void 0 === c ? 0 : c,
                      l = r.endpoints[e].initiate,
                      d = u(),
                      p = (0, m.useState)(E),
                      v = p[0],
                      y = p[1],
                      b = (0, m.useRef)(),
                      g = x({
                        refetchOnReconnect: o,
                        refetchOnFocus: s,
                        pollingInterval: f
                      });
                    a(function () {
                      var e,
                        r,
                        t = null == (e = b.current) ? void 0 : e.subscriptionOptions;
                      g !== t && (null == (r = b.current) || r.updateSubscriptionOptions(g));
                    }, [g]);
                    var h = (0, m.useRef)(g);
                    a(function () {
                      h.current = g;
                    }, [g]);
                    var q = (0, m.useCallback)(function (e, r) {
                      var t;
                      return void 0 === r && (r = !1), n(function () {
                        var n;
                        null == (n = b.current) || n.unsubscribe(), b.current = t = d(l(e, {
                          subscriptionOptions: h.current,
                          forceRefetch: !r
                        })), y(e);
                      }), t;
                    }, [d, l]);
                    return (0, m.useEffect)(function () {
                      return function () {
                        var e;
                        null == (e = null == b ? void 0 : b.current) || e.unsubscribe();
                      };
                    }, []), (0, m.useEffect)(function () {
                      v === E || b.current || q(v, !0);
                    }, [v, q]), (0, m.useMemo)(function () {
                      return [q, v];
                    }, [q, v]);
                  },
                  v = function (t, n) {
                    var u = void 0 === n ? {} : n,
                      a = u.skip,
                      l = u.selectFromResult,
                      d = r.endpoints[e].select,
                      p = k(void 0 !== a && a ? q.skipToken : t, s, c.endpointDefinitions[e], e),
                      v = (0, m.useRef)(),
                      y = (0, m.useMemo)(function () {
                        return (0, h.createSelector)([d(p), function (e, r) {
                          return r;
                        }, function (e) {
                          return p;
                        }], f);
                      }, [d, p]),
                      b = (0, m.useMemo)(function () {
                        return l ? (0, h.createSelector)([y], l) : y;
                      }, [y, l]),
                      g = i(function (e) {
                        return b(e, v.current);
                      }, O.shallowEqual),
                      S = o(),
                      E = y(S.getState(), v.current);
                    return z(function () {
                      v.current = E;
                    }, [E]), g;
                  };
                return {
                  useQueryState: v,
                  useQuerySubscription: t,
                  useLazyQuerySubscription: l,
                  useLazyQuery: function (e) {
                    var r = l(e),
                      t = r[0],
                      n = r[1],
                      u = v(n, p(d({}, e), {
                        skip: n === E
                      })),
                      i = (0, m.useMemo)(function () {
                        return {
                          lastArg: n
                        };
                      }, [n]);
                    return (0, m.useMemo)(function () {
                      return [t, u, i];
                    }, [t, u, i]);
                  },
                  useQuery: function (e, r) {
                    var n = t(e, r),
                      u = v(e, d({
                        selectFromResult: e === q.skipToken || (null == r ? void 0 : r.skip) ? void 0 : C
                      }, r));
                    return (0, m.useDebugValue)({
                      data: u.data,
                      status: u.status,
                      isLoading: u.isLoading,
                      isSuccess: u.isSuccess,
                      isError: u.isError,
                      error: u.error
                    }), (0, m.useMemo)(function () {
                      return d(d({}, u), n);
                    }, [u, n]);
                  }
                };
              },
              buildMutationHook: function (e) {
                return function (t) {
                  var o = void 0 === t ? {} : t,
                    s = o.selectFromResult,
                    c = void 0 === s ? P : s,
                    a = o.fixedCacheKey,
                    f = r.endpoints[e],
                    l = f.select,
                    v = f.initiate,
                    y = u(),
                    b = (0, m.useState)(),
                    g = b[0],
                    q = b[1];
                  (0, m.useEffect)(function () {
                    return function () {
                      (null == g ? void 0 : g.arg.fixedCacheKey) || null == g || g.reset();
                    };
                  }, [g]);
                  var S = (0, m.useCallback)(function (e) {
                      var r = y(v(e, {
                        fixedCacheKey: a
                      }));
                      return q(r), r;
                    }, [y, v, a]),
                    k = (g || {}).requestId,
                    E = (0, m.useMemo)(function () {
                      return (0, h.createSelector)([l({
                        fixedCacheKey: a,
                        requestId: null == g ? void 0 : g.requestId
                      })], c);
                    }, [l, g, c, a]),
                    j = i(E, O.shallowEqual),
                    M = null == a ? null == g ? void 0 : g.arg.originalArgs : void 0,
                    x = (0, m.useCallback)(function () {
                      n(function () {
                        g && q(void 0), a && y(r.internalActions.removeMutationResult({
                          requestId: k,
                          fixedCacheKey: a
                        }));
                      });
                    }, [y, a, g, k]);
                  (0, m.useDebugValue)({
                    endpointName: j.endpointName,
                    data: j.data,
                    status: j.status,
                    isLoading: j.isLoading,
                    isSuccess: j.isSuccess,
                    isError: j.isError,
                    error: j.error
                  });
                  var Q = (0, m.useMemo)(function () {
                    return p(d({}, j), {
                      originalArgs: M,
                      reset: x
                    });
                  }, [j, M, x]);
                  return (0, m.useMemo)(function () {
                    return [S, Q];
                  }, [S, Q]);
                };
              },
              usePrefetch: function (e, t) {
                var n = u(),
                  i = x(t);
                return (0, m.useCallback)(function (t, u) {
                  return n(r.util.prefetch(e, t, d(d({}, i), u)));
                }, [e, n, i]);
              }
            };
            function f(e, r, t) {
              if ((null == r ? void 0 : r.endpointName) && e.isUninitialized) {
                var n = r.endpointName,
                  u = c.endpointDefinitions[n];
                s({
                  queryArgs: r.originalArgs,
                  endpointDefinition: u,
                  endpointName: n
                }) === s({
                  queryArgs: t,
                  endpointDefinition: u,
                  endpointName: n
                }) && (r = void 0);
              }
              var i = e.isSuccess ? e.data : null == r ? void 0 : r.data;
              void 0 === i && (i = e.data);
              var o = void 0 !== i,
                a = e.isLoading,
                f = !o && a,
                l = e.isSuccess || a && o;
              return p(d({}, e), {
                data: i,
                currentData: e.data,
                isFetching: a,
                isLoading: f,
                isSuccess: l
              });
            }
          }({
            api: e,
            moduleOptions: {
              batch: n,
              useDispatch: i,
              useSelector: s,
              useStore: a,
              unstable__sideEffectsInRender: l
            },
            serializeQueryArgs: r.serializeQueryArgs,
            context: t
          }),
          c = o.buildQueryHooks,
          f = o.buildMutationHook;
        return I(u, {
          usePrefetch: o.usePrefetch
        }), I(t, {
          batch: n
        }), {
          injectEndpoint: function (r, t) {
            if (t.type === Q.query) {
              var n = c(r),
                i = n.useQuery,
                o = n.useLazyQuery;
              I(u.endpoints[r], {
                useQuery: i,
                useLazyQuery: o,
                useLazyQuerySubscription: n.useLazyQuerySubscription,
                useQueryState: n.useQueryState,
                useQuerySubscription: n.useQuerySubscription
              }), e["use" + D(r) + "Query"] = i, e["useLazy" + D(r) + "Query"] = o;
            } else if (t.type === Q.mutation) {
              var s = f(r);
              I(u.endpoints[r], {
                useMutation: s
              }), e["use" + D(r) + "Mutation"] = s;
            }
          }
        };
      }
    };
  };
y(exports, b(require("@reduxjs/toolkit/query")));
var K = b(require("@reduxjs/toolkit")),
  H = b(require("react")),
  T = b(require("react")),
  U = b(require("react-redux")),
  V = b(require("@reduxjs/toolkit/query"));
function W(e) {
  var r = T.default.useState(function () {
    var r;
    return (0, K.configureStore)({
      reducer: (r = {}, r[e.api.reducerPath] = e.api.reducer, r),
      middleware: function (r) {
        return r().concat(e.api.middleware);
      }
    });
  })[0];
  return (0, H.useEffect)(function () {
    return !1 === e.setupListeners ? void 0 : (0, V.setupListeners)(r.dispatch, e.setupListeners);
  }, [e.setupListeners, r.dispatch]), T.default.createElement(U.Provider, {
    store: r,
    context: e.context
  }, e.children);
}
var J = (0, g.buildCreateApi)((0, g.coreModule)(), F());
