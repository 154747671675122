
<mat-form-field appearance="fill">
	<div class="autocomplete-inner">
		<!-- <mat-icon class="search-icon">search</mat-icon> -->
		<input
			class="autocomplete-input"
			type="text"
			matInput
			placeholder="Search for a coordinate system"
			[matAutocomplete]="auto"
			[formControl]="epsgControl">
		<mat-autocomplete
			#auto="matAutocomplete"
			class="autocomplete"
			[displayWith]="displayOptions"
			(optionSelected)="onAutocomplete($event)">
			<mat-option
				class="option"
				data-testid="coordinate_option"
				*ngFor="let opt of filteredEpsgOptions | async"
				[value]="opt">
				<div class="epsg-view">
					<span class="epsg-left">{{ opt[1] }}</span>
					<span>EPSG: {{ opt[0] }}</span>
				</div>
			</mat-option>
		</mat-autocomplete>
		<span class="sub-text" *ngIf="epsgControl.valid && isObject(epsgControl.value)">(EPSG: {{epsgControl.value[0]}})</span>
		<button
			mat-button
			matTooltip="Clear"
			matTooltipPosition="right"
			class="autocomplete-clear-button"
			*ngIf="epsgControl.valid"
			(click)="clearEPSGControl()">
			<mat-icon class="search-icon">close</mat-icon>
		</button>
	</div>
</mat-form-field>
