
<mat-table [dataSource]="dataSource" matSort>

	<ng-container matColumnDef="select">
		<mat-header-cell *matHeaderCellDef class="select-cell">
			<mat-checkbox
				data-id="gcp_header_parse_toggle"
				(change)="$event ? selectAll() : null"
				[checked]="selection.hasValue() && isAllSelected()"
				[indeterminate]="selection.hasValue() && !isAllSelected()"
				[aria-label]="selectRow()"
                 data-testid="select-all"
			>
			</mat-checkbox>
		</mat-header-cell>
		<mat-cell *matCellDef="let row" class="select-cell">
			<mat-checkbox
				data-id="gcp_parse_toggle"
				(click)="$event.stopPropagation()"
				(change)="$event ? selection.toggle(row) : null"
				[checked]="selection.isSelected(row)"
				[aria-label]="selectRow(row)">
			</mat-checkbox>
		</mat-cell>
	</ng-container>

	<!-- Number Column -->
	<ng-container matColumnDef="number">
		<mat-header-cell *matHeaderCellDef mat-sort-header disableClear class="number-cell"> Row # </mat-header-cell>
		<mat-cell class="number-cell"
			*matCellDef="let row; let i = index;"> {{i}} </mat-cell>
	</ng-container>

	<!-- Label Column -->
	<ng-container *ngFor="let column of _dataColumns; let i = index;" [matColumnDef]="column">
		<mat-header-cell class="data-cell" *matHeaderCellDef [ngStyle]="{'display': showElevationOptions(i) ? 'block' : 'flex'}">
			<mat-select data-testid="header-selection" secondary [(value)]="columnEPSG[i]" placeholder="Choose Type" (selectionChange)="checkAllEPSGOptions()">
				<mat-option value="name"><span class="option-text">Label</span></mat-option>
				<mat-option
					*ngFor="let option of epsgPositionOptions;"
					[disabled]="checkUsedColumn(i, option.value)"
					[value]="option.value">
					<span class="option-text">{{option.text}}</span>
				</mat-option>
				<mat-option value=""><span class="option-text">-- Empty --</span></mat-option>
			</mat-select>

			<ng-container *ngIf="columnEPSG[i] && !showElevationOptions(i)">
				({{positionUnit}})
			</ng-container>

			<ng-container *ngIf="showElevationOptions(i)">
				<mat-select secondary placeholder="Choose Units" [(value)]="altitudeUnit" (selectionChange)="altitudeChange();">
					<mat-option *ngFor="let option of altitudeUnitList" [value]="option.value"><span class="option-text">{{option.text}}</span></mat-option>
				</mat-select>
			</ng-container>

		</mat-header-cell>
		<mat-cell class="data-cell" *matCellDef="let row; let ind = index;">
			<span *ngIf="!(editCell && editCell.row === ind && editCell.col === i)"
				class="edit-button"
				[ngClass]="{'disabled' : !selection.isSelected(row)}"
				(click)="editSelectedCell(row, ind, i); $event.stopPropagation();">
				{{row[i]}}
			</span>
			<ng-container *ngIf="editCell && editCell.row === ind && editCell.col === i">
				<ng-container *ngTemplateOutlet="t_editView"></ng-container>
			</ng-container>
		</mat-cell>
	</ng-container>

	<!-- Delete Column -->
	<ng-container matColumnDef="delete">
		<mat-header-cell class="trash-cell" *matHeaderCellDef> </mat-header-cell>
		<mat-cell class="trash-cell" *matCellDef="let row; let i = index;">
			<button matTooltip="Remove row" mat-icon-button (click)="removeRow(i)">
				<mat-icon>delete_outline</mat-icon>
			</button>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="saveSelectedCell();"></mat-row>

</mat-table>


<ng-template #t_editView>
	<mat-form-field appearance="fill" class="edit-button edit-field" (click)="$event.stopPropagation();">
		<input #editInput
			class="edit-input"
			matInput
			type="text"
			placeholder="Enter data"
			autocomplete="off"
			data-testid="enter_data"
			[(ngModel)]="editCell.value"
			(keyup.enter)="saveSelectedCell();">
	</mat-form-field>
</ng-template>
