
<div class="grid-item" [ngClass]="{'active-item' : menuOpen}" *ngIf="project; else t_skeleton" (contextmenu)="openMenu($event)">
	<div class="list-image-container"
		(click)="clickedImage($event)">
		<ng-container *ngIf="project.thumbnail">
			<img class="list-image"
				[lazyLoad]="rootURL + project.thumbnail">
		</ng-container>
		<ng-container *ngIf="!project.thumbnail">
			<div class="P2Black500" style="margin: auto;">
				No model processed
			</div>
		</ng-container>
	</div>

	<div class="list-item-text-container">
		<div style="display: flex; margin-bottom: 4px;">
			<span class="list-item-title"
				(click)="clickedTitle($event)">
				{{ _project.name ? _project.name : 'No Project Name' }}
			</span>

			<ng-container *featureEnabled="'!disable-sharing'">
				<button *ngIf="_project['shares']?.length"
						matTooltip="{{getSharedTooltip(_project)}}"
						mat-icon-button
						class="shared-button"
						(click)="clickedIcon($event, 'share')">
					<mat-icon class="shared-icon">link</mat-icon>
				</button>
			</ng-container>

			<mat-icon matTooltip="Favorited" class="favorited-icon" *ngIf="_project['favorited']">star_border</mat-icon>

			<button
				data-testId="options-menu"
				style="margin: auto; margin-right: 0;"
				mat-icon-button tooltip="More"
				(click)="openMenu($event);">
				<mat-icon class="list-item-icon">more_horiz</mat-icon>
			</button>

			<button
				class="context-button"
				[ngStyle]="contextMenuStyle()"
				[matMenuTriggerFor]="itemMenu">
			</button>

			<mat-menu
				id="itemMenu"
				#itemMenu="matMenu"
				(closed)="menuOpen = false;">
				<ng-template matMenuContent>
					<ng-content></ng-content>
				</ng-template>
			</mat-menu>
		</div>

		<div class="list-item-text">
			<ng-container *ngIf="showCreatedAt">
				Created on: {{_project.created_at ? (_project.created_at | date) : 'Never'}}
			</ng-container>
			<ng-container *ngIf="!showCreatedAt">
				Last modified on: {{_project.updated_at ? (_project.updated_at | date) : 'Never'}}
			</ng-container>
		</div>
		<div class="list-item-text">
			Project ownership: {{project.organization ? project.organization.name : ' Unknown owner'}}
		</div>
		<!-- <div class="list-item-text" *ngIf="!isMobile">
			<div>
				Tags:
			</div>
			<div class="list-item-tags">
				<span *ngIf="tags.length > 0" class="tag-text">
					{{tagsText}}
				</span>
				<ng-container *ngIf="tags.length > 3">
					...
					<button mat-button (click)="clickedIcon($event, 'tags')">
						<span class="none-button"> {{tags.length-3}} more</span>
					</button>
				</ng-container>
				<span *ngIf="tags.length <= 0" class="tag-text">None</span>
			</div>
		</div> -->

		<!-- <div class="list-item-text">
			Tags:
			<div class="list-item-tags">
				<ng-container *ngIf="tags" >
					<span *ngFor="let tag of tags | slice:0:5;"style="width: auto; margin: auto 5px">
						{{tag}},
					</span>
					<span *ngIf="tags.length > 5">... {{tags.length-5}} more</span>
				</ng-container>
				<ng-container *ngIf="!tags">
					<span style="width: auto; margin: auto 5px">None</span>
				</ng-container>
			</div>
		</div> -->
	</div>
</div>

<ng-template #t_skeleton>
	<div class="skel-grid-item">
		<div class="skel-list-image-container skel-shimmer"></div>

		<div class="skel-list-text-container">
			<div class="skel-list-top">
				<span class="skel-title skel-shimmer"></span>
			</div>
			<div class="skel-text-date skel-shimmer"></div>
			<div class="skel-text-tag skel-shimmer"></div>
		</div>
	</div>
</ng-template>
