
<mat-card appearance="outline">
	<div class="error-dialog">
		<div class="H3Black700">
			<mat-icon>error</mat-icon>
			{{info.title}}
		</div>

		<div class="content">
			<div class="error">Error: {{info.error}}</div>

			<div>
				Check our support guide for more information:
				<a href="{{info.supportPage.path}}">
					{{info.supportPage.title}}
				</a>
			</div>

			<div>
				Contact our support team for assistance:
				<a href="mailto:{{supportEmail}}" target="_blank">
					{{supportEmail}}
				</a>
			</div>
		</div>

		<div class="buttons">
			<button *ngIf="info.couldRefresh" mat-stroked-button color="secondary" matDialogClose (click)="refreshAction()">Refresh</button>
			<button mat-stroked-button color="primary" matDialogClose (click)="defaultAction()">{{info.defaultActionText ? info.defaultActionText : 'Return'}}</button>
		</div>
	</div>
</mat-card>
