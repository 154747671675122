import React from "react";

export const useModal = (initialState = false) => {
	const [modalStatus, setOpen] = React.useState<boolean>(initialState);

	return {
		closeModal: () => Promise.resolve((setOpen(false) as unknown) ?? false),
		modalStatus,
		openModal: () => Promise.resolve(setOpen(true) as unknown),
	};
};
