<ng-container [ngSwitch]="dialogDisplayState">
	<mat-dialog-content class="controlled-content" *ngSwitchCase="'processing'">
		<app-process
			[input_project]="data.project"
			[closeDialog]="dialogRef.close.bind(dialogRef)"
		></app-process>
	</mat-dialog-content>
	<mat-dialog-content class="controlled-content" *ngSwitchCase="'import'">
		<app-process
			[_isImport]="true"
			[input_project]="data.project"
			[closeDialog]="dialogRef.close.bind(dialogRef)"
		></app-process>
	</mat-dialog-content>
	<ng-container *ngSwitchDefault>
		<h1 mat-dialog-title class="H2Black700 text-centered">Add Map Layers</h1>
		<div mat-dialog-content class="H6Black500Regular text-centered info-copy">
			<p>Process a new model from imagery or import map data.</p>
		</div>
		<mat-dialog-content class="controlled-content options">
			<ng-container *featureEnabled="'!disable-processing'">
				<button
					mat-stroked-button
					color="primary"
					class="secondary-button"
					(click)="dialogDisplayState = 'processing'"
					[disabled]="!userHasPermission"
				>
					Process New Model
				</button>
			</ng-container>
			<!-- commenting this out for now, until it can get updated - Nic
			<ng-container *featureEnabled="'!disable-imports-for-maintenance'">
				<button
					mat-stroked-button
					color="primary"
					class="secondary-button"
					(click)="dialogDisplayState = 'import'"
					[disabled]="!userHasPermission"
				>
					Import Model
				</button>
			</ng-container>
			-->
		</mat-dialog-content>
		<button mat-icon-button mat-dialog-close
				class="close-icon"
				data-testid="dialog-close"
				>
			<mat-icon>close</mat-icon>
		</button>
	</ng-container>
</ng-container>
