/* Imports */
import { Injectable } from "@angular/core";

/* Services */
import { HttpService } from "./http.service";
import { Annotation } from "@shared/models/annotation.model";

@Injectable()
export class AnnotationService {

	constructor(
		private httpService: HttpService
	) {

	}

	public get( annotation_id: number ): Promise<any> {
		const url = "/annotations/" + annotation_id;
		return this.httpService.get( url );
	}

	public getList( project_id: number ): Promise<any> {
		const url = `/projects/${ project_id }/annotations`;
		return this.httpService.get( url );
	}

	public create( annotation: Annotation ): Promise<any> {
		const url = "/annotations";
		return this.httpService.post( url, annotation );
	}

	public update( annotation: Annotation ): Promise<any> {
		const url = "/annotations/" + annotation.id;
		return this.httpService.put( url, annotation );
	}

	public delete( annotation_id: number ): Promise<any> {
		const url = "/annotations/" + annotation_id;
		return this.httpService.delete( url );
	}
}