import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";

import { matchPaths } from "@app/utils/matchPaths";
import { Flex } from "@components/layout";
import {
	DialogHeader,
	FadeTransition,
	getOrthos,
	NextButton,
	useGetProjectQuery,
	useModal,
	useSimpleStepper,
	WizardSteps,
} from "@features/MLWizard";

const steps = ["Select Your Data", "Pick Analyisis", "Select Data", "Review"];

export const matcher = matchPaths({
	"#/projects/view/:projectId\\?tab=:tab": (params) => params["projectId"],
});

const initialState = {
	step1: null,
	step2: null,
	step3: null,
};

export default function MLWizard() {
	const { isSuccess, data: project } = useGetProjectQuery(
		matcher(window.location.hash)
	);
	const { activeStep, stepForward, stepBack, reset } = useSimpleStepper();
	const { modalStatus, openModal, closeModal } = useModal();
	const closeWizard = () => {
		closeModal().then(() => {
			setSelection(initialState);
			reset();
		});
	};

	const [selections, setSelection] = useState(initialState);

	if (!isSuccess) {
		return null;
	}

	const isStepComplete =
		(activeStep === 0 && !selections.step1) ||
		(activeStep === 1 && !selections.step2) ||
		(activeStep === 2 && !selections.step3);

	return (
		<div>
			<Button onClick={openModal} className="button-outlined-primary">
				<ImageSearchIcon className="icon-button-primary-blue" />
				AI Analysis
			</Button>
			<Dialog
				fullScreen
				open={modalStatus}
				onClose={closeWizard}
				TransitionComponent={FadeTransition}
			>
				<DialogHeader
					handleClose={closeWizard}
					project={project}
					steps={steps}
					activeStep={activeStep}
				/>
				<Flex
					sx={{
						flex: 1,
						justifyContent: "center",
						overflowY: "auto",
						padding: "4rem",
					}}
				>
					<WizardSteps
						activeStep={activeStep}
						selections={selections}
						setSelection={setSelection}
						getOrthos={getOrthos}
						project={project}
						handleBack={stepBack}
						closeWizard={closeWizard}
					/>
				</Flex>
				{activeStep < 3 && (
					<NextButton
						activeStep={activeStep}
						handleBack={stepBack}
						handleNext={stepForward}
						disabled={isStepComplete}
					/>
				)}
			</Dialog>
		</div>
	);
}
