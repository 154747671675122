import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LayersIcon from "@mui/icons-material/Layers";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { map } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { useExpandableSections } from "@hooks/useExpandableSections";

import { getLayerGroupsList, toggleLayerVis } from "../slice";

import { LayerListItem } from "./LayerListItem";

export default function _ViewerLayers({ layerGroups, toggleVisibility }) {
	const [expansions, toggleExpansion] = useExpandableSections();

	return (
		<List
			sx={{
				color: "text.primary",
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			{map(layerGroups, (layers, name) => (
				<Box key={name} sx={{ bgcolor: "grey.900" }}>
					<ListItemButton
						onClick={() => toggleExpansion(name as string)}
					>
						<ListItemIcon>
							<LayersIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary={name} />
						{expansions[name] ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<List disablePadding>
						{layers.map((layer) => (
							<LayerListItem
								toggleVisibility={() =>
									toggleVisibility(layer.id)
								}
								expanded={expansions[name]}
								key={layer.id}
								{...layer}
							/>
						))}
					</List>
				</Box>
			))}
		</List>
	);
}

export const ViewerLayers = connect(
	(state) => ({
		layerGroups: getLayerGroupsList(state),
	}),
	(dispatch) => ({
		toggleVisibility: (id) => dispatch(toggleLayerVis(id)),
	})
)(_ViewerLayers);
