<div class="container inferencing-container">
    <div class="ai-inferencing-wizard-header-container">
        <div class="main-header">
            <div class="header-title">AI Analysis</div>
			<div class="step-counter-container">
				<div class="step-counter">
					<div class="step-one" [ngClass]="{'step-complete': aiWizardSteps.aiModelSelected}"></div>
					<div class="step-two" [ngClass]="{'step-complete': aiWizardSteps.layerSelected}"></div>
					<div class="step-three" [ngClass]="{'step-complete': aiWizardSteps.selectionsConfirmed}"></div>
				</div>
			</div>
			<button mat-icon-button class="close-button">
				<mat-icon (click)="close()">close</mat-icon>
			</button>
        </div>
        <div class="static-banner">
            Learn more about your data with Mapware AI Analysis
        </div>
    </div>

    <div class="next-container" *ngIf="!aiWizardSteps.continueToConfirmation">
        <ng-container *ngTemplateOutlet="steps_container"></ng-container>
    </div>
	<div class="next-container" *ngIf="aiWizardSteps.continueToConfirmation">
        <ng-container *ngTemplateOutlet="confirmation_container"></ng-container>
    </div>
</div>


<ng-template #t_thumbnail>
	<div class="default-thumbnail-div">
		<img src="/assets/icons/aa_logo.png" alt="mapware logo" class="default-thumbnail">
	</div>
</ng-template>


<ng-template #steps_container>
    <div class="steps-container" [ngClass]="{'step-one-completed': aiWizardSteps.aiModelSelected}">
        <div class="selection-container">
            <div class="instruction-text">
                <div class="subheader-text">
                    What would you like to learn?
                </div>
                <div>
                    Select the AI algorithm that you’d like to run <br> or <span class="highlight-link">upload</span> new data to Mapware for AI analysis
                </div>
            </div>
            <div class="ai-model-list-container">
                <div class="data-row ellipsis" *ngFor="let aiModel of aiTestModels" (click)="selectAiModel(aiModel)">
                    <div class="ellipsis">
                        <input class="radio-button" type="radio" id="{{aiModel.id}}" name="ai" value="{{aiModel.id}}" [checked]="aiModel.selected"/>
                        <label class="label" for="{{aiModel.id}}">{{ aiModel.name }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'divider-div-visible': aiWizardSteps.aiModelSelected, 'divider-div-hidden': !aiWizardSteps.aiModelSelected}">
            <div class="divider-line"></div>
            <div class="divider-prompt">&</div>
            <div class="divider-line"></div>
        </div>

        <div class="selection-container" [ngClass]="{'step-two-hidden': !aiWizardSteps.aiModelSelected}">
            <div class="instruction-text">
                <div class="subheader-text">
                    Select your data for analysis
                </div>
                <div class="H7Black500Regular">
                    In order to search for <span class="bold-ai-model-name">{{ this.selectedAiModel?.name }}</span>, we require an orthomosaic to inference on. <br> Below are the acceptable files that we could find in your project.
                </div>
            </div>
			<div class="group-controls">
				<span *ngFor="let sortOption of sortOptions">
					<button [ngClass]="{'active': isSort(sortOption.value)}" mat-button (click)="setSort(sortOption.value)">
						{{ sortOption.text }}
						<mat-icon [ngClass]="{'rotated': checkSortAndDir(sortOption.value)}">arrow_downward</mat-icon>
					</button>
				</span>
			</div>
            <div class="layer-list-container">
                <div class="data-row layer" *ngFor="let layer of inferencingLayers" (click)="selectLayer(layer)">
                    <div class="ellipsis layer-row">
                        <input class="radio-button" type="radio" id="{{layer.id}}" name="layer" value="{{layer.id}}" [checked]="layer.selected" />
                        <img *ngIf="layer?.layer_thumbnail" src="{{layer?.layer_thumbnail}}" alt="model" class="thumbnail">
                        <ng-container *ngIf="!layer?.layer_thumbnail">
                            <ng-container *ngTemplateOutlet="t_thumbnail"></ng-container>
                        </ng-container>
                        <div class="layer-label-container">
                            <div class="model-name">{{ layer?.model_name }}</div>
                            <label class="layer-label" for="{{layer.id}}">{{ layer.name }}</label>
                        </div>
                    </div>
                    <div class="layer-date ellipsis">{{ layer.created_at | date }}</div>
                </div>
                <div class="no-layers-message" *ngIf="!inferencingLayers?.length">
                    Uh oh! We were unable to find any acceptable orthomosaics...
                    <br>
                    Would you like to <span class="highlight-link">upload</span> new data to Mapware for AI analysis?
                </div>
            </div>
        </div>
    </div>
	<button mat-flat-button color="primary" class="next-button" (click)="goToConfirmationPage()" [disabled]="!(aiWizardSteps.aiModelSelected && aiWizardSteps.layerSelected)">
		Continue
	</button>
</ng-template>


<ng-template #confirmation_container>
	<div class="confirmation-container">
		<div class="confirmation-items">
			<div class="confirmation-text">
				Based on your selections, we will be using:
			</div>
			<div class="confirmation-item">
				<div>{{ selectedAiModel?.name }}</div>
				<mat-icon class="edit-button" (click)="editSelection()">edit</mat-icon>
			</div>
			<div class="confirmation-text">
				To search for:
			</div>
			<div class="confirmation-item">
				<div>{{ selectedLayer?.name }}</div>
				<mat-icon class="edit-button" (click)="editSelection()">edit</mat-icon>
			</div>
		</div>
	</div>
	<button mat-flat-button color="primary" (click)="startAnalysis()" *ngIf="aiWizardSteps.continueToConfirmation">
		Start Analysis
	</button>
</ng-template>
