
/* TODO: @remove remove-subscription-required : RegisterConfirmationComponent */

/* Imports */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-register-confirmation',
	templateUrl: './register-confirmation.component.html',
	styleUrls: ['./register-confirmation.component.scss']
})
export class RegisterConfirmationComponent {

	@Input('registerFormGroup') registerForm: FormGroup;
	@Input('paymentFormGroup') paymentForm: FormGroup;
	@Output('setPage') setPage: EventEmitter<any> = new EventEmitter();
	@Input('displayPlan') displayPlan = {
		price: 'N/A',
		isYearly: 'N/A'
	};

	constructor () {}

	addSteps(steps: number): void {
		this.setPage.emit(steps);
	}

}	// End-of class RegisterConfirmationComponent
