type DateStr = string;

export class BaseMapwareEntity {
	id: number;
	active: 0 | 1;
	created_by_user_id: number;
	created_at: DateStr;
	updated_at: DateStr;
}

export class Annotation extends BaseMapwareEntity {
	name: string;
	details: string; // long string
	color: string; // color hexcode string
	model_references: number[]; // array of model ids
	descriptors: unknown;
	project_id: number;

	constructor( params: any = {} ) {
		super();
		Object.assign( this, params );
	} // End-of constructor
}

export interface LatLng2D {
	lat: number;
	lng: number;
}

export interface LatLng3D extends LatLng2D {
	alt: number;
}

export interface LatLng extends LatLng2D {
	alt?: number;
}

export class Marker extends Annotation {
	type: "marker";
	descriptors: {
		point: LatLng;
	};
}

export class Polyline extends Annotation {
	type: "polyline";
	descriptors: {
		points: LatLng[];
		connected: boolean;
	};
}

export class LabelAnnotationData extends Annotation {
	type: string;
}

export class LabelSetData extends LabelAnnotationData {
	descriptors: {
		labels: [{
			id: number,
			coordinates: LatLng[][]
		}]
	};
}

export class TrainingRegionData extends LabelAnnotationData {
	descriptors: {
		coordinates: LatLng[][];
	};
}