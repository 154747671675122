import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';

import { MapComponent } from "../map.component";

@Component({
	selector: "app-map-header",
	styleUrls: ["./map.header.scss"],
	templateUrl: "./map.header.component.html",
})
export class MapHeaderComponent {
	@Input() map: MapComponent;
	@Input() publicView: boolean;

	constructor(
		private _router: Router,
		private snackBar: MatSnackBar
		) {}

	public returnToProject() {
		if (!this.map.project || this.map.publicView)
			this._router.navigateByUrl(`/`);
		else
			this._router.navigateByUrl(`/projects/view/${this.map.project.id}`);
	}

	showMessage(msg: string) {
		this.snackBar.open(msg, "", {
			duration: 3000,
			verticalPosition: "top",
			horizontalPosition: "center",
			panelClass: "viewer-snackbar"
		});

	}

	getOrientationStyle(orientation) {
		return {transform: `rotate(${orientation}deg) translateY(-8px)`};
	}

	getUserOrientationStyle(orientation) {
		return {transform: `rotate(${orientation}deg)`};
	}

	orientNorth() {
		this.map.orientNorth();
	}

	addUserMarker() {
		this.map.addUserMarker();
	}
}
