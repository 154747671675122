import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import React from "react";

import { store } from "@app/store";

import { api as annotationsAPI } from "../api";

export const AnnotationOptions = ({
	annotation,
	getAnnotations,
	updateAnnotationThunk,
	isEdit,
	setIsEdit,
}) => {
	const toggleEdit = () => {
		setIsEdit(() => !isEdit);
	};

	const deleteAnnotation = (annotation) => () => {
		store
			.dispatch(annotationsAPI.endpoints.delete.initiate(annotation.id))
			.then(() => {
				getAnnotations();
			})
			.catch(console.error);
	};

	const submitUpdate = () => {
		toggleEdit();
		updateAnnotationThunk();
	};

	return isEdit ? (
		<div>
			<IconButton onClick={submitUpdate}>
				<CheckIcon />
			</IconButton>
			<IconButton onClick={toggleEdit}>
				<CloseIcon />
			</IconButton>
		</div>
	) : (
		<div>
			<IconButton onClick={toggleEdit}>
				<EditIcon />
			</IconButton>
			<IconButton onClick={deleteAnnotation(annotation)}>
				<CloseIcon />
			</IconButton>
		</div>
	);
};
