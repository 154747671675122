import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

import { RootState } from "@app/store";

import { mapMode, sidebarMode } from "../store";

const Debug = ({
	children,
}: {
	children: typeof React.Children | { [x: string]: any };
}) => (
	<pre>
		<code>{JSON.stringify(children, null, 4)}</code>
	</pre>
);

function _Demo({
	setLayersMode,
	setAnnotationsMode,
	setSelectedAnnotationMode,
	setPlacingPolyLineMode,
	setPlacingMarkerMode,
	setViewMode,
	...data
}) {
	return (
		<div className="App">
			<Box>
				<Tabs
					value={data.sidebarMode.mode.toLowerCase()}
					aria-label="basic tabs example"
				>
					<Tab
						label="LAYERS"
						value="layers"
						onClick={() => setLayersMode()}
					/>
					<Tab
						label="ANNOTATIONS"
						value="annotations"
						onClick={() => setAnnotationsMode()}
					/>
				</Tabs>
			</Box>
			<Debug>{data}</Debug>
			<h3>Sidebar Buttons</h3>
			<button onClick={() => setLayersMode()}>setLayersMode</button>
			<button onClick={() => setAnnotationsMode()}>
				setAnnotationsMode
			</button>
			<br />
			<h3>Map Buttons</h3>
			<button onClick={() => setSelectedAnnotationMode(1)}>
				setSelectedAnnotationMode
			</button>
			<button onClick={() => setPlacingPolyLineMode()}>
				setPlacingPolyLineMode
			</button>
			<button onClick={() => setPlacingMarkerMode()}>
				setPlacingMarkerMode
			</button>
			<button onClick={() => setViewMode()}>setViewMode</button>
		</div>
	);
}

export const Demo = connect(
	(s: RootState) => ({
		mapMode: s.mapMode,
		sidebarMode: s.sidebarMode,
	}),
	{
		...mapMode.actions,
		...sidebarMode.actions,
	}
)(_Demo);
