
/* Depricated - Do not use */

/* Imports */
import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { HttpHeaders } from '@angular/common/http';

/* Services */
import { GlobalService } from './global.service';

/* Services */
import { HttpService } from './http.service';
import { GCP } from '../models/gcp.model';
import { makeURLParams } from "@shared/services/utils.service";

/* Models */

@Injectable()
export class FileService {

	public dataColumns: Array<any> = [
		{value: 'label', options: ['label', 'name', 'title']},
		{value: 'latitude', options: ['latitude', 'lat']},
		{value: 'longitude', options: ['longitude', 'long']},
		{value: 'altitude', options: ['altitude', 'alt']},
		{value: 'elevation', options: ['elevation', 'height']},
		{value: 'easting', options: ['easting']},
		{value: 'northing', options: ['northing']},
	];

	constructor(
		private httpService: HttpService,
	) {

	}    // End-of constructor

	getFilesV2({organization_id = null, project_id = null, exports = false}): Promise<any> {
		const searchParams = makeURLParams({organization_id, project_id, exports});
		let url = '/v2/files' + searchParams;
		return this.httpService.get(url);
	}

	parseCSV(csvString, options = {}): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			// Default
			const config = {
				delimiter: options['delimiter'] ? options['delimiter'] : "",
				newline: options['newline'] ? options['newline'] : "",
				quoteChar: '"',
				escapeChar: options['escapeChar'] ? options['escapeChar'] : '"',
				header: false,
				transformHeader: undefined,
				dynamicTyping: false,
				preview: 0,
				encoding: "",
				worker: false,
				comments: options['comments'] ? options['comments'] : '',
				step: undefined,
				complete: undefined,
				error: undefined,
				download: false,
				skipEmptyLines: false,
				chunk: undefined,
				fastMode: undefined,
				beforeFirstChunk: undefined,
				withCredentials: undefined,
				transform: undefined
			};
			let data = Papa.parse(csvString, config).data;
			resolve(data);
		})

	}	// End-of parseCSV

	convertToTableData(file): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			let dataCols = [];
			let displayedColumns = [];
			let suggestedColumns = [];

			this.dataColumns.forEach(dataCol => {

				for (let i = 0; i < 3; i++) {
					const fileCol = file[i];

					fileCol.forEach((x: string, ind) => {
						const textExists = dataCol.options.some(val => val.toLowerCase().trim() === x.toLowerCase().trim().substring(0, val.length));

						if (textExists) {
							suggestedColumns.push({index: ind, value: dataCol.value})
						}
						displayedColumns[ind] = textExists ? dataCol.value : ind.toString();
					});
				}
			})

			resolve({parsedData: file, displayedColumns: displayedColumns, suggestedColumns: suggestedColumns})
		})
	}

} // End-of class FileService
