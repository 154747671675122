
<div class="confirmation-container" data-testid="confirmation-modal">
	<h1 mat-dialog-title class="H3Black700 text-centered">
		Upload Images
	</h1>
	<button mat-button class="close-button" matTooltip="Close" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>

	<ng-container *ngTemplateOutlet="t_upload"></ng-container>
</div>

<ng-template #t_upload>
	<aerial-dropzone (filesEmitter)="dropzoneUpload($event)">
		<div class="upload-container">
			<ng-container *ngTemplateOutlet="t_batchSelect"></ng-container>
			<ng-container *ngTemplateOutlet="t_standardDropzone"></ng-container>
		</div>
	</aerial-dropzone>
	<ng-container *featureEnabled="'folder-upload'">
		<input data-testid="folderUpload"
			   style="display: none;"
			   type="file"
			   webkitdirectory mozdirectory msdirectory odirectory directory
			   multiple
			   #folderInput
			   (change)="folderChanged($event)">
	</ng-container>
	<input data-testid="dialogUpload" style="display: none;" multiple type="file" [accept]="acceptedImageTypesString" #fileInput (change)="filesChanged($event.target.files)">
</ng-template>

<ng-template #t_batchSelect>
	<form *ngIf="batchForm" [formGroup]="batchForm">
		<div class="upload-text">
			You can create a new <b>Image Group</b>, or upload to an existing one:
		</div>
		<mat-form-field appearance="outline" class="batch-select">
			<mat-select formControlName="batch" data-testid="upload-batch">
				<mat-select-trigger>
					<span class="trigger-text" *ngIf="batchForm.value.batch">
						<span class="phone-hidden">Add to existing Image Group:</span>
						<span class="phone-visible">Add to existing:</span>
						<span class="H6Black700Regular">{{ batchForm.value.batch.name }} ({{ batchForm.value.batch.images?.length }} images)</span>
					</span>
					<span class="H6Black700Regular" *ngIf="!batchForm.value.batch">
						Create New Image Group
					</span>
				</mat-select-trigger>
				<mat-option value="">
					Create New Image Group
				</mat-option>
				<mat-optgroup label="Add to existing Image Group" *ngIf="batches?.length">
					<mat-option *ngFor="let batch of batches" [value]="batch">{{ batch.name }}  ({{ batch.images.length }} images)</mat-option>
				</mat-optgroup>
			</mat-select>
		</mat-form-field>

		<mat-form-field appearance="fill" class="batch-name" *ngIf="!batchForm.value.batch">
			<mat-label>Image Group name</mat-label>
			<input matInput
				   data-testid="upload-name"
				   type="text"
				   placeholder="Enter Name"
				   formControlName="name"
				   autocomplete="off"
				   autofocus>
		</mat-form-field>
		<div *ngIf="batchForm.controls.name.errors?.maxLength" class="name-error">
			<mat-error class="H7RedRegular">Image Group name is too long. Please choose a shorter name.</mat-error>
		</div>
		<div *ngIf="batchForm.controls.name.errors?.nameTaken" class="name-error">
			<mat-error class="H7RedRegular">Image Group name already exists. Please choose a unique batch name.</mat-error>
		</div>
	</form>
</ng-template>

<ng-template #t_standardDropzone>
	<ng-container *ngIf="!filesToUpload.length">
		<div class="upload-icon">
			<mat-icon>
				cloud_upload
			</mat-icon>
		</div>

		<div class="dropzone-text">
			Drag and drop your {{ imageTypesOrString }} files or a folder containing individual files.
		</div>
	</ng-container>

	<ng-container *ngIf="filesToUpload.length">
		<div class="dropzone-text">
			Drag and drop to reupload your {{ imageTypesOrString }} files or a folder containing individual files.
		</div>
	</ng-container>

	<div class="dialog-buttons parent">
		<ng-container *ngIf="!filesToUpload.length">
			<div class="primary-buttons">
				<button
						mat-stroked-button
						color="primary"
						(click)="fileInput.nativeElement.click()">
					Select files
				</button>
				<ng-container *featureEnabled="'folder-upload'">
					<span>or</span>
					<button
							mat-stroked-button
							color="primary"
							(click)="folderInput.nativeElement.click()">
						Upload folders
					</button>
				</ng-container>
			</div>

			<ng-container *ngIf="showFiletypeError">
				<div class="dropzone-error">
					One or more files weren't of the {{ imageTypesOrString }} file types and were not uploaded.
				</div>
			</ng-container>

			<button *ngIf="!filesToUpload.length"
					mat-button
					matDialogClose>
				Cancel
			</button>
		</ng-container>

		<ng-container *ngIf="filesToUpload.length">
			<div class="primary-buttons">
				<button
						mat-flat-button
						color="primary"
						(click)="filesChanged()">
					Upload {{ filesToUpload.length }} images
				</button>
				<span>or</span>
				<button
						mat-stroked-button
						color="primary"
						(click)="folderInput.nativeElement.click()">
					Reupload folders
				</button>
			</div>
			<button *ngIf="filesToUpload.length"
					mat-button
					(click)="clearFolderUpload()">
				Back
			</button>
		</ng-container>

	</div>
</ng-template>
