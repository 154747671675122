<div class="profile">
	<ng-container >
		<div class="content">
			<div class="H3Black700 bottom-margin-medium">
				Create Your New Project
			</div>
			<div class="new-project-form" *ngIf="projectForm">
				<form [formGroup]="projectForm" class="max-width" (ngSubmit)="createProject();">
					<mat-divider color="#dee0e3"></mat-divider>
					<div class="bottom-margin-small top-margin-large">
						<span class="H6Black700Regular">Project Name</span>
						<span class="H7Black500Regular"> (Required)</span>
					</div>
					<mat-form-field subscriptSizing="dynamic" appearance="fill" class="max-width input-full-height">
						<input #projectNameInput matInput type="text" autocomplete="off" data-testid="enter_name"
							formControlName="name" placeholder="Enter Name">
					</mat-form-field>

					<div *ngIf="projectForm.controls['name'].errors?.maxLength" class="project-name-error">
						<mat-error class="H7RedRegular">It looks like your Project Name is too long. Please choose a shorter name.</mat-error>
					</div>
					<div *ngIf="projectForm.controls['name'].errors?.nameTaken" class="project-name-error">
						<mat-error class="H7RedRegular">Project Name already exists. Please choose a unique name.</mat-error>
					</div>
					<!-- Tag-Container -->
					<!-- <div class="tag-container">
						<div class="bottom-margin-small">
							<span class="H6Black700Regular">Project Tags</span>
						</div>
						<button mat-button
							(click)="toggleTags()"
							type="button"
							*ngIf="!showTags && projectTags.length == 0"
							class="H7Blue500Regular add-tag-button">
								<mat-icon class="tag-icon">
									add_circle_outline
								</mat-icon>
								<span class="tag-text">
									Add tags
								</span>
						</button>
						<app-tags #tags
							class="bottom-margin-small"
							*ngIf="showTags || projectTags.length > 0"
							[items]="projectTags"
							(itemsChanged)="projectTags=$event;"
							pristine-text="Type to search for an existing tag or create a new one"
							dirty-text="Enter another tag"
							hint-text="Hit 'enter' to add tag">
						</app-tags>
					</div> -->
					<button mat-flat-button
						class="create-project-button"
						type="submit"
						color="primary"
						[disabled]="projectForm.invalid || isLoading">
						Create Project
					</button>
				</form>
			</div>
			<div class="dot-container" *ngIf="isLoading">
				<div class="dot-stretching"></div>
			</div>
			<button mat-button class="close-button" matTooltip="Close" matDialogClose data-testid="createProject-menu-close">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</ng-container>
</div>
