
/* Imports */
import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	Output, EventEmitter
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

/* Services */
import {
	AlertService,
	AuthenticationService,
	DownloadService,
	FavoriteService,
	ProjectService,
	PermissionService,
	UploadService,
	byId
} from '@shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";

/* Models */
import { Alert, Project } from '@shared/models';
import _, {isEmpty, isNil, merge, unionBy} from 'lodash';
import moment from 'moment';

export const toAPIFormat = ( date ) => moment( date ).format( "YYYY-MM-DDTHH:mm:ss.SSSZ" );

@Component({
	selector: 'app-data-projects',
	templateUrl: './data.projects.component.html',
	styleUrls: ['./data.projects.component.scss']
})
export class DataProjectsComponent implements OnInit {

	/* ViewChild */
	@ViewChild("itemMenuTrigger") menuTrigger: MatMenuTrigger;

	public defaultMenuTriggers: Array<any> = [];

	@ViewChild('fileInput') uploadInput: ElementRef;
	@ViewChild(MatSort) set sort(s: MatSort) {
		if (s) { this.dataSource.sort = s; }
	};
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	/* Input */
	@Input('project') set project(proj: Project) {
		if (proj) { this.setup(proj); }
	}
	@Output() projectChange: EventEmitter<any> = new EventEmitter<any>();
	public _project: Project;
	get project(): Project { return this._project; }

	public dataSource: MatTableDataSource<any> = new MatTableDataSource();
	public selection = new SelectionModel<any>(true, []);
	public displayedColumns: string[] = ['name', 'filetype', 'size', 'created_at', 'options'];
	public user: any;
	public activeItem: any;

	public isProcessing: boolean = true;
	public isDownloading: boolean = false;
	public allSelected: boolean = false;

	public userPermissions: any;
	public isExpired: boolean = true;
	public contextMenuPosition = { x: '0px', y: '0px' };
	public projectListSubscription: any;

	constructor(
		private _cdr: ChangeDetectorRef,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _downloadService: DownloadService,
		private _favoriteService: FavoriteService,
		private _projectService: ProjectService,
		private _uploadService: UploadService,
		private _permissionService: PermissionService,
	) {

	}	// End-of constructor

	ngOnInit() {

		this.user = this._authService.user;

		this.projectListSubscription?.unsubscribe();
		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({renderState}) => {
			this.trackAndUpdateUploads(renderState);
		});

	}	// End-of ngOnInit

	trackAndUpdateUploads(renderState) {

		const project = this.project ? renderState.projectList?.find(byId(this.project.id)) : null;

		if (project) {
			const dataFiles = project.files.filter(x => x.uploadType === "File");
			if (dataFiles?.length) {
				this.handleFiles(dataFiles);
			}
		}
	}

	async setup(proj: Project) {

		this._project = proj;

		this._permissionService.getUserPermissions(proj.organization_id).then(rtnPermissions => {
			this.userPermissions = rtnPermissions;
		}).catch(console.error);

		this.isExpired = false;

		if(flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
			let projectFiles = this._project.project_files;
			if(!this._project.project_files) {
				projectFiles = (await this._projectService.getProjectFilesV2(this._project))
					?.filter((x) => !x.export && !x.trash);
				this._project.project_files = projectFiles;
			}
			this.handleFiles(projectFiles);
		} else { // TODO @remove temp-api-v2-routes
			const files = this._project.project_files
				.filter((x) => !x.export && !x.trash);
			this.handleFiles(files);
		}

	}	// End-of setup


	handleFiles(files: Array<any> = []): void {

		files.forEach((file, ind) => {

			let outFile = Object.assign({}, file, {index: ind});
			const fileTypeSource = file.type ? file.type : file.name;
			const fileTypeMatcher = /[.\/](?<fileType>\w+)/;
			outFile.filetype = fileTypeSource.match(fileTypeMatcher)?.groups.fileType;
			outFile.created_at = file.created_at ? file.created_at : new Date();
		});

		this._favoriteService.getList().then(favList => {
			if (favList.project_files && Array.isArray(favList.project_files)) {
				favList.project_files.forEach(fav => {
					const index = files.findIndex(item => item.id === fav.project_file_id);
					if (index > -1) {
						files[index].favorited = true;
						files[index].favorited_id = fav.id;
					}
				});
			}
		});

		const oldFiles = this.dataSource.data ?? [];

		if (oldFiles?.length) {

			oldFiles.forEach(file => {
				if (!file.created_at) {
					file.created_at = toAPIFormat(moment().utc()); // UTC time to match API
				}
			})
			const { mergeFiles, priorityFiles } = this.checkFilesData(oldFiles, files);

			const mergeData = _.values(_.merge(_.keyBy(priorityFiles, 'id'), _.keyBy(mergeFiles, 'id')));
			const emitProject = Object.assign({}, this._project, { project_files: mergeData });
			this.projectChange.emit({ project: emitProject, type: "project_files" });

			this.project.project_files = mergeData;
			this.dataSource.data = mergeData;
		} else {
			this.dataSource.data = files;
		}
		this.dataSource.paginator = this.paginator;
		this.isProcessing = false;
		this._cdr.detectChanges();

	}	// End-of updateFileList


	checkFilesData(files, filesToCheck) {
		if (filesToCheck.some(x => x.fileData?.id)) {
			const filesIds = files.map(x => x.id);
			const mergeFiles = filesToCheck.filter(x => {
				return x.fileData?.id ? !filesIds.includes(x.fileData?.id) : true;
			});
			return { mergeFiles, priorityFiles: files };
		} else if (files.some(x => x.fileData?.id)) {
			const checkFilesIds = filesToCheck.map(x => x.id);
			const mergeFiles = files.filter(x => {
				return x.fileData?.id ? !checkFilesIds.includes(x.fileData?.id) : true;
			});
			return { mergeFiles, priorityFiles: filesToCheck };
		}
		return { mergeFiles: filesToCheck, priorityFiles: files };
	}


	filesChanged(files: Array<any>): void {

		if (files.length > 0) {
			this._uploadService.__uploadFiles({
				files,
				project_id: this.project.id,
				org_id: this.project.organization_id,
			}).then(uploadedFiles => {
				this.handleFiles(uploadedFiles);
			}).catch(e => {
				console.error(e)
				this._alertService.notify("We're sorry. Something went wrong with the upload. Please reload the page and try again.", "error")
			});
		}

	}	// End-of filesChanged

	selectOne(row) {

		this.selection.toggle(row);
		if (this.allSelected) {
			this.allSelected = false;
		} else if (this.selection.selected.length === this.dataSource.data.length) {
			this.allSelected = true;
		}

	}	// End-of selectOne

	selectAll(): void {

		if (this.allSelected) {
			this.selection = new SelectionModel<Element>(true, []);
			this.allSelected = false;
		} else {
			this.selection = new SelectionModel<Element>(true, this.dataSource.data);
			this.allSelected = true;
		}

	}	// End-of selectAll

	download(file): void {
		this._downloadService.setStatus("Downloading file, please do not refresh this page.");
		this.isDownloading = true;
		this._projectService.downloadFile(file).then(rtnFile => {
			this._downloadService.downloadHelper(rtnFile, file.name);
			this.isDownloading = false;
			this._downloadService.clearStatus();
		});
		analyticsLayer.trackDownload(file, "ProjectFile");
	}


	favorite(item): void {

		if (item.favorited) {
			this._favoriteService.remove(item.favorited_id).then(() => {
				item.favorited = false;
				this._alertService.notification(new Alert('Removed from Favorites'));
				analyticsLayer.trackFavorite("remove", item, "ProjectFile");
			});
		} else {
			this._favoriteService.create({ project_file_id: item.id }).then(rtnData => {
				item.favorited_id = rtnData.favorite_id;
				item.favorited = true;
				this._alertService.notification(new Alert('Added to Favorites', 'star_border'));
				analyticsLayer.trackFavorite("add", item, "ProjectFile");
			});
		}

	}	// End-of favorites


	openMenu(e, i, row): void {
		if (this.menuTrigger) {
			e.preventDefault();
			this.contextMenuPosition.x = e.clientX + 'px';
			this.contextMenuPosition.y = e.clientY + 'px';
			this.activeItem = row;
			this.menuTrigger._handleClick(e);
			this._cdr.detectChanges();
		}
	}

	deleteItem(item) {
		this._projectService.trashFile(item)
			.then(() => {
				this.dataSource.data = this.dataSource.data?.filter(entry => entry.id !== item.id) ?? [];
				this.project = Object.assign(this.project, { project_files: this.dataSource.data });
				this._alertService.trashed(item.name);
				const emitProject = Object.assign({}, this._project, { project_files: this.dataSource.data });
				this.projectChange.emit({ project: emitProject, type: "project_files" });
				analyticsLayer.trackRemove(item, "ProjectFile");
			})
			.catch( e => {
				console.error(e)
				this._alertService.notify(`Failed to move ${item.name} to the trash`, 'error')
			} )
	}

	onMenuClose() {
		this.activeItem = null;
	}

	isActiveItem(item): boolean {
		return !isNil(item?.id) && item.id === this.activeItem?.id;
	}

}	// End-of DataProjectComponent
