/* Imports */
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

export class OverrideOptions {
	public permission: string = null;
	public credits: number = null;
	public storage: number = null;
	public trial: number = null;
}

@Injectable()
export class AdminService {

	public isDeveloper: boolean = false;

	public overrideOptions: OverrideOptions = new OverrideOptions();

    private overrideValues: BehaviorSubject<OverrideOptions>;

	constructor(private _authService: AuthenticationService) {
		this.overrideValues = new BehaviorSubject<OverrideOptions>(null);
		this.isDeveloper = this._authService.isDeveloper;
	}


	getOverrideChanged(): Observable<any> {
		return this.overrideValues.asObservable();
	}


	getOverride(type): any {
		return this.isDeveloper && this.overrideOptions[type] ? this.overrideOptions[type] : null; // For use with nullish coalescing
	}

	setOverride(value: any, type: 'permission' | 'credits' | 'storage' | 'trial'): void {
		(this.overrideOptions[type] as any) = value;
		this.overrideValues.next(this.overrideOptions);
	}

}
