import { isEqual } from 'lodash'
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';
import {PagingStreamService} from '@shared/services/paging-stream.service';
import { Organization } from "@shared/models";
import { makeURLParams } from "@shared/services/utils.service";

const doneState = { projects:[], images: [], models: [], project_files: [] }

@Injectable()
export class SearchService {

	constructor(
			private httpService: HttpService,
			private pagingStreamService: PagingStreamService
		) {

	}	// End-of constructor

	public getSearchChunk(org: Organization, searchText: string, chunkStartIndex: number = 0): Promise<any> {

		const searchData = {organization_id: org?.id, string: searchText, start_at: chunkStartIndex};
		const urlParams = makeURLParams(searchData);
		let url = '/search' + urlParams;
		return this.httpService.get(url);

	}

	public streamSearchList(org: Organization, searchText: string, maxChunk = 20) {
		const func = index => this.getSearchChunk(org, searchText, index)
		return this.pagingStreamService.wrapFetchWithPagingStream(func, (newChunk) => isEqual(newChunk, doneState), maxChunk);

	}

}	// End-of class SearchService
