import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageService } from '../../../shared/services/image.service';
import { OrganizationService } from '../../../shared/services/organization.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Alert } from '../../../shared/models';


@Component({
	selector: 'app-create-image-group-dialog',
	templateUrl: "./create-image-group.dialog.html",
	styleUrls: ["./create-image-group.dialog.scss"],
})
export class CreateImageGroupDialog {

	public org;
	public project_id;
	public isLoading = false;
	public newBatchString = '';
	public hasUniqueName = true;

	constructor(
		private _imageService: ImageService,
		private _alertService: AlertService,
		private _orgService: OrganizationService,
		private _dialogRef: MatDialogRef<CreateImageGroupDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

		this.project_id = data.project_id;

		this._orgService
			.getActiveOrg()
			.subscribe(({ organization }) => {
				this.org = organization;
			})
	}

	close(): void {
		this._dialogRef.close(false);
	}

	async submit() {
		await this._imageService.getBatchesByProjectId(this.project_id).then(batches => {
			this.checkBatchNames(batches);
			this.createNewImageGroup();
		}).catch(err => {
			console.error(err);
			this._alertService.success(new Alert(`Could not verify existing Image Groups, please try again.`));
		})
	}

	createNewImageGroup() {
		if (this.newBatchString?.length && this.org?.id && this.hasUniqueName) {
			this.isLoading = true;
			this._imageService.createBatch(this.newBatchString, this.org.id, this.project_id).then(rtnBatch => {
				this.isLoading = false;
				this._alertService.success(new Alert(`Created Image Group "${this.newBatchString}"`))
				this._dialogRef.close(rtnBatch);
			}).catch(err => {
				console.error(err);
				this._alertService.success(new Alert(`Could not create Image Group, please try again.`));
			})
		}
	}

	checkBatchNames(batches) {

		if (!batches?.length) return true;

		const batchNames = batches.map((batch) => {
			return batch.name
		})

		if (this.newBatchString !== '') {
			this.hasUniqueName = batchNames.every((name) => {
				return name !== this.newBatchString;
			});
		}
	}


}
