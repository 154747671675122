import { match, Path, MatchResult } from "path-to-regexp";
import noop from "lodash/noop";
type PathMatcher = [Path, (arg0?: Object) => any];
type PathHandler = (arg0?: Object) => any;
/**
 * This is for when you have multiple possible urls and different functions to handle each one
 * eg:
 *  `/project/:projectId` -> console.log
 *  `/map/:mapId` -> console.warn
 *  `/trash/` -> console.error
 *
 * @example
   ```js
   const matcher = matching({
     '/map/:mapId\\?model=:modelId': console.log,
     '/projects/process\\?project_id=:projId': console.error
     default: console.warn
   });

   matcher(window.location.pathname + window.location.search)
   ```
 */
export const matchPaths =
	(def: { [key: string]: PathHandler } = { _default: noop }) => (path: string): unknown => {
		const { _default = noop, ...pairs } = def;
		const pattern = Object.keys(pairs).find((pattern: Path) =>
			match(pattern)(path)
		) ?? null;

		if (pattern === null) {
			console.error(`No match found for '${path}'`);
			return _default();
		}

		return def[pattern]((match(pattern)(path) as MatchResult)?.params);
	};
