
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {cloneDeep, isNil, keyBy} from 'lodash';

import {ImageService, IMAGESIZES} from '@shared/services/image.service';
import {AlertService, DownloadService} from '@shared/services';
import { Project } from '@shared/models/project.model';
import { UrlService } from '@shared/services/url.service';
import {Alert, Image} from '@shared/models';
import pluralize from 'pluralize';
import * as asyncjs from 'async';

import { analyticsLayer } from '@shared/analyticsLayer';
import retry from 'async-retry';

const downloadAttempts = 4;

export enum uploadedStates {
	"uploaded",
	"completed",
	"converting",
	"converted"
}

@Component({
	selector: 'app-image-group',
	templateUrl: './image-group.component.html',
	styleUrls: ['./image-group.component.scss']
})
export class ImageGroupComponent {

	@Input('imageGroup') set inImageGroup(imageGroup) {
		if (!isNil(imageGroup?.id)) {
			this.imageGroup = null;
			this.editNameString = cloneDeep(imageGroup.name);
			if (imageGroup.id !== this.imageGroup?.id) {
				this.onImageGroupChange(imageGroup);
			}
		}
	}
	@Input() editName: boolean = false;
	@Input() disableMove: boolean = false;
	@Input() favoritesList: Object = {};
	@Input() project: Project;
	@Input() handlePhotoEvent: (event: any) => any;
	@Input('selection') selection = new SelectionModel<any>(true, []);
	@Input() hasPermissions;

	@Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter();
	@Output('imageGroupChange') imageGroupChange: EventEmitter<any> = new EventEmitter();
	@Output('outEvent') outEventEmitter: EventEmitter<any> = new EventEmitter();

	@ViewChild('fileInput') fileInput: ElementRef;

	public imageGroup;
	public isLoading = false;
	public showInfo = false;
	public editNameString: string;
	public maxLength = 32;
	public failedDownloads: Array<any> = [];

	constructor(
		private _alertService: AlertService,
		private _imageService: ImageService,
		private _downloadService: DownloadService,
		private _urlService: UrlService
	) {
	}

	onImageGroupChange(imageGroup) {
		this.imageGroup = imageGroup;
		this.getDeepImageGroup(imageGroup);
		this.showInfo = false;
		this._urlService.applyParams({ imageGroup: imageGroup.id });
	}

	getDeepImageGroup(imageGroup) {
		this._imageService.getBatchV2(imageGroup.id).then(deepImageGroup => {
			this.imageGroup = deepImageGroup;
		}).catch(err => {
			console.error(err);
			this._alertService.notify("Error getting Image Group", "error");
		})
	}

	toggleEditName() {
		this.editNameString = cloneDeep(this.imageGroup.name);
		this.editName = !this.editName;
	}

	submitEdit() {
		this._imageService.renameBatch(this.imageGroup.id, this.editNameString).then(() => {
			this.imageGroup.name = this.editNameString;
			this.editName = false;
		}).catch(err => {
			console.error(err);
			this._alertService.notify("Unable to rename batch.", "error");
		})
	}

	disableRename(editNameString) {
		return (editNameString.length < 1) || (editNameString > this.maxLength) || this.nameUnchanged(editNameString);
	}

	nameUnchanged(name) {
		const filterValue = name.toLowerCase().replace(/^\s+|\s+$|\s+(?=\s)/g, "");
		const compareValue = this.imageGroup.name.toLowerCase();
		return (filterValue === compareValue);
	}

	outEvent(event): void {
		if (event.type && event.images) {
			if (event.type === "download" && event.images?.length) this.downloadImages(event.images);
			else this.outEventEmitter.emit(event);
		}
		if (event === "download" && this.imageGroup?.images?.length) {
			if(this.imageGroup?.images?.length != this.imageGroup?.images_count) {
				// get all the images first
				this._alertService.success(new Alert('Your download is preparing'));
				this._imageService.getBatchImagesV2(this.imageGroup?.id).then( rtn => {
					this.downloadImages(rtn);
				})
			}
			else
				this.downloadImages(this.imageGroup.images);
		}
		else this.outEventEmitter.emit(event);
	}

	downloadImages(images) {
		this._alertService.success(new Alert('Beginning download'));
		this.downloadImagesAsync(images).then(() => {
			if (this.failedDownloads.length) {
				// @ts-ignore - we do not have the latest ts check, so it's not aware of the new `listFormat`
				const formatter = new Intl.ListFormat("en", {
					style: "long",
					type: "conjunction"
				});
				const maxStrings = 4;
				const extra = this.failedDownloads.length - maxStrings;
				const failedString = formatter.format(this.failedDownloads.slice(0, maxStrings).map(x => x.name));
				const extraString = extra > 0 ? 'and ' + extra + ` more ${pluralize('image', extra)}` : '';
				this._alertService.error(new Alert(`${failedString} ${extraString} failed to download. ${this.failedDownloads.length === 1 ? 'It' : 'They'} will be selected to retry.`));
			}
		})
	}

	downloadImagesAsync(images) {
		return asyncjs.mapLimit(
			images,
			4,
			asyncjs.asyncify(image => {
				return this.tryImageDownload(image).catch(err => {
					console.error("Image download failed for image", err);
					this.failedDownloads.push(image);
				});
			})
		)
	}

	tryImageDownload(image) {

		const bailCodes = [200];
		return retry(
			(bail) =>
				this.downloadSingleImage(image)
					.then((img: Blob) => {
						this._downloadService.downloadHelper(img, image.name);
						return img;
					})
					.catch((errorCode) => {
						if (bailCodes.includes(errorCode)) {
							bail(new Error(errorCode));
						} else {
							console.warn('Image download failed, retrying...');
							throw new Error(errorCode);
						}
					}),
			{
				retries: downloadAttempts,
				minTimeout: 50
			}
		);
	}

	downloadSingleImage(image): Promise<any> {

		analyticsLayer.trackDownload(image, "Image");
		return this._imageService.download(image.guid, IMAGESIZES.large);

	}	// End-of downloadSingle

}
