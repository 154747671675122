import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import AnnotationsIcon from "@mui/icons-material/Annotations";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { capitalize, groupBy, map } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import { connect } from "react-redux";

import { matchPaths } from "@app/utils/matchPaths";
import { useGetListQuery } from "@features/Annotations";
import { useExpandableSections } from "@hooks/useExpandableSections";

import { getVisibilityDict, toggleAnnotationVis } from "../slice";

import { ListItem } from "./ListItem";

export const matcher = matchPaths({
	"#/map/:projectId": (params) => params["projectId"],
	"#/map/:projectId\\?model=:modelId": (params) => params["projectId"],
});

export default function _AnnotationVisibilityControls({
	visibilityDict,
	toggleVisibility,
}) {
	const { data } = useGetListQuery(matcher(window.location.hash) as number);

	const [expansions, toggleExpansion] = useExpandableSections();

	return (
		<List
			sx={{
				color: "text.primary",
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			{map(groupBy(data, "type"), (annotations, name) => (
				<Box key={name} sx={{ bgcolor: "grey.900" }}>
					<ListItemButton
						onClick={() => toggleExpansion(name as string)}
					>
						<ListItemIcon>
							{/*<AnnotationsIcon color="primary" />*/}
						</ListItemIcon>
						<ListItemText primary={capitalize(pluralize(name))} />
						{expansions[name] ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<List disablePadding>
						{annotations.map((annotation) => (
							<ListItem
								toggleVisibility={() =>
									toggleVisibility(annotation.id)
								}
								expanded={expansions[name]}
								key={annotation.id}
								visible={visibilityDict[annotation.id]}
								{...annotation}
							/>
						))}
					</List>
				</Box>
			))}
		</List>
	);
}

export const AnnotationVisibilityControls = connect(
	(state) => ({
		visibilityDict: getVisibilityDict(state),
	}),
	(dispatch) => ({
		toggleVisibility: (id) => dispatch(toggleAnnotationVis(id)),
	})
)(_AnnotationVisibilityControls);
