import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";

import { Flex } from "../../../components/layout";
import { theme } from "../../../theme";

const hoverAppeal = {
	"&:hover": {
		boxShadow: 3,
		transform: "translateY(-5px) scale(1.01)",
	},
	transition: theme.transitions.create(["box-shadow", "transform"]),
};

export const Step2 = ({ selection, setSelection }) => {
	return (
		<Flex
			sx={{
				alignItems: "center",
				boxSizing: "border-box",
				flexDirection: "column",
				gap: theme.spacing(1),
				justifyContent: "center",
			}}
		>
			<Typography variant="h3" fontWeight="fontWeightBold">
				What would you like to learn about?
			</Typography>
			<Typography variant="h6">
				Select what you’d like to look for below.
			</Typography>

			<Card
				sx={{
					border: selection ? 3 : 0,
					borderColor: "primary.main",
					boxSizing: "border-box",
					cursor: "pointer",
					marginTop: "6rem",
					maxWidth: 345,
					...hoverAppeal,
				}}
				onClick={() => setSelection("Warbler")}
			>
				<div className="ai-model-image" />
				<CardContent>
					<Typography gutterBottom variant="h4" component="div">
						Golden Cheeked Warbler
					</Typography>
					<Typography variant="caption">
						Species Habitat Identification
					</Typography>
				</CardContent>
			</Card>
		</Flex>
	);
};
