
<div class="export-modal" *ngIf="parentProject">
	<div class="header-container">
		<button
				class="close-button"
				matTooltip="Close"
				mat-button
				(click)="close()">
			<mat-icon>close</mat-icon>
		</button>

		<div class="header">
			Export "{{ parentProject.name}}"
		</div>
	</div>

	<div class="selection-container">

		<form novalidate
			name="form"
			[formGroup]="exportFormGroup"
			class="export-form">

			<div class="title">
				SELECT DATA TO EXPORT
			</div>
			<div class="layer-group-list-div">

				<div *ngIf="layerGroups.length === 0" class="nothing-to-export-msg-div">
					No exports available.
					<small>
						Exports only available for processed map data.
					</small> 
				</div>

				<mat-accordion multi>
					<mat-expansion-panel *ngFor="let layerGroup of layerGroups"
										[expanded]="layerGroup.expanded"
										hideToggle
										class="export-layer-group-row"
										lazy
					>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<mat-icon class="panel-expand-arrow">
									keyboard_arrow_down
								</mat-icon>
								<span class="ellipsis">{{ layerGroup.name }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<ng-template class="panel-content" matExpansionPanelContent>
							<div class="export-layer-row"
								*ngFor="let layer of layerGroup.layers">

								<mat-checkbox class="export-option"
											color="primary"
											formControlName="{{layer.exportForm.check}}">

									<div class="layer-name ellipsis" *ngIf="layer.type === 'dsm'; then upperCaseType else titleCaseType"></div>
									<ng-template #titleCaseType>{{ layer.type | titlecase }}</ng-template>
									<ng-template #upperCaseType>{{ layer.type | uppercase }}</ng-template>

								</mat-checkbox>

								<!-- if there is only 1 export file type option -->
								<ng-container *ngIf="getLayerExportFileTypes(layer.type).length == 1">
									<div class="layer-export-format">
										{{ getLayerExportFileTypes(layer.type)[0] }}
									</div>
								</ng-container>

								<!-- if there are multiple export file types, show a selector -->
								<ng-container *ngIf="getLayerExportFileTypes(layer.type).length >= 2">
									<mat-form-field appearance="fill">
										<mat-select formControlName="{{layer.exportForm.format}}">
											<mat-option *ngFor="let fileType of getLayerExportFileTypes(layer.type)" [value]="fileType" >
												{{fileType}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</ng-container>
							</div>
						</ng-template>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
			<div *featureEnabled="'temp-export-epsg'" class="coordinate-system-div">
				<div class="title sub-section">
					EXPORT COORDINATE SYSTEM
				</div>
				<mat-form-field appearance="fill" class="export-coordinates"
					style="width: 100%; margin: 16px 0;">
					<input
						type="text"
						matInput
						placeholder="Search for a coordinate system"
						formControlName="epsgControl"
						[matAutocomplete]="auto">
						<mat-autocomplete #auto="matAutocomplete">
							<mat-option
								*ngFor="let opt of filteredEpsgOptions | async"
								[value]="opt">
							{{ opt[0] }} - {{ opt[1] }}
						</mat-option>
					</mat-autocomplete> 
				</mat-form-field>

				<div class="export-subtext">
					<div *ngIf="!exportFormIsValid()">
						Please select a coordinate system and a model layer to export.
					</div>
				</div>
			</div>

			<div *featureEnabled="'!temp-export-epsg'" class="coordinate-system-div">
				<div class="title sub-section">
					EXPORT COORDINATE SYSTEM
				</div>

				<mat-form-field appearance="fill" class="export-coordinates disabled"
							style="width: 100%; margin: 16px 0;">
					<input
							type="text"
							matInput
							placeholder="Search for a coordinate system"
							formControlName="epsgControl">
				</mat-form-field>

				<div class="export-subtext">
					All exports will be using the WGS 84 Coordinate System. Please <span class="link" (click)="sendFeedback();">contact support</span> if you would like this to be manually changed.
				</div>
			</div>
		</form>

	</div>

	<div class="action-container">
		<button mat-flat-button color="primary"
				class="export-button"
				[disabled]="disableExport()"
				(click)="exportFiles()">
			Export
			<ng-container *featureEnabled="'viewer-layers'">
				<ng-container *ngIf="getLayersToExport()?.length">
					({{ getLayersToExport().length }})
				</ng-container>
			</ng-container>
		</button>
	</div>
</div>
