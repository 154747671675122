/* Imports */
import {Component, OnChanges, Input, ChangeDetectorRef, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import {isNil, isUndefined} from 'lodash';

@Component({
	selector: 'app-scroll-load',
	templateUrl: './scroll-load.component.html',
	styleUrls: ['./scroll-load.component.scss']
})
export class ScrollLoadComponent implements OnChanges {

	@Input() chunkLoadFunction: Function = null;
	@Input() isLoading: boolean = false;
	@Input() limit: number = 10;
	@Input() offset: number = 0;

	@ViewChild("scrollContainerRef") scrollContainerRef: ElementRef;
	@ViewChild("scrollContentRef") scrollContentRef: ElementRef;

	public isLoadingChunk: boolean = false;
	public itemIndex: number = 0;
	public itemsLoaded: number = 0;
	public moreToLoad: boolean = true;

	constructor(private _cdr: ChangeDetectorRef) {
		this.reset();
	}

	ngOnChanges( changes: SimpleChanges ) {
		this.itemIndex = 0;

		if (!this.isLoading) {
			this.loadChunk();
		}
	}

	loadChunk = (e?) => {

		const scrollPos = e?.target?.scrollTop;
		const scrollHeight = this.scrollContainerRef?.nativeElement?.offsetHeight;
		const scrollContentHeight = this.scrollContentRef?.nativeElement?.offsetHeight;
		const isNearBottom = (isUndefined(e)) || (scrollPos >= (scrollContentHeight - scrollHeight - 200));

		if (isNearBottom && !this.isLoadingChunk && this.moreToLoad) {
			this.isLoadingChunk = true;
			this._cdr.detectChanges();

			this.chunkLoadFunction(this.limit, this.offset).then((itemsLoaded: number) => {
				this.moreToLoad = itemsLoaded >= this.limit;
				this.itemIndex += 1;
				this.offset += this.limit;
				this.itemsLoaded += itemsLoaded;
				this.isLoadingChunk = false;
				this._cdr.detectChanges();
			}).catch(err => {
				console.error(err);
				this.isLoadingChunk = false;
			});
		}
	}

	reset() {
		this.moreToLoad = true;
		this.itemIndex = 0;
		this.offset = 0;
		this.itemsLoaded = 0;
		this.isLoadingChunk = false;
	}

}
