
/* Imports */
import { Injectable } from '@angular/core';

/* Services */
import { HttpService } from './http.service';

/* Models */
import { Project, ProjectFile, Organization } from '../models';
import { OrganizationService } from "@shared/services/organization.service";
import { makeURLParams } from "./utils.service";
import {isEmpty} from 'lodash';
import {Observable} from 'rxjs';
import { HttpEvent } from '@angular/common/http';

@Injectable()
export class ProjectService {

	public activeOrg: Organization = null;

	constructor(
		private httpService: HttpService,
		private _orgService: OrganizationService
	) {

	}	// End-of constructor

	/**
	 * v2 Routes
	 **/

	public getByIdV2(projId: number | string): Promise<any> {
		const url = '/v2/projects/' + projId;
		return this.httpService.get(url);
	}

	public getProjectDetailsV2(projId: number | string): Promise<any> {
		const url = `/v2/projects/${projId}/details`;
		return this.httpService.get(url);
	}

	public getListV2(orgId: number | string): Promise<any> {
		const searchData = {organization_id: orgId};
		const searchParams = makeURLParams(searchData);
		const url = '/v2/projects' + searchParams;
		return this.httpService.get(url);
	}

	public getExportFilesV2(project: Project | number): Promise<any> {
		const projectId = (typeof project === 'object') ? project["id"] : project;
		const url = `/v2/files?project_id=${projectId}&exports=1`;
		return this.httpService.get(url);
	}

	public getProjectFilesV2(project: Project | number): Promise<any> {
		const projectId = (typeof project === 'object') ? project["id"] : project;
		const url = `/v2/files?project_id=${projectId}`;
		return this.httpService.get(url);
	}

	/**
	 * v1 Routes
	 **/


	/**
	* [GETBYID]
	*
	* @param {number} projId		ID of project
	*/
	public getById(projId: number | string): Promise<any> {
		let url = '/projects/' + projId;
		return this.httpService.get(url);

	}	// End-of getById

	/**
	* [GETLIST]
	*
	*/
	public getList(org?: Organization | number): Promise<any> {

		const searchData = {organization_id: typeof org === "number" ? org : org?.id};
		const searchParams = makeURLParams(searchData);

		const url = "/projects" + searchParams;
		return this.httpService.get(url);

	}	// End-of getList

	/**
	* [CREATE]
	*
	* @param {Project} project		Project data
	*/
	public create(project: Project): Promise<any> {

		let url = "/projects";
		return this.httpService.post(url, project);

	}	// End-of create

	/**
	* [UPDATE]
	*
	* @param {Project} project		Project data
	*/
	public update(project: Project): Promise<any> {

		let url = '/projects/' + project.id;
		return this.httpService.put(url, project);

	}	// End-of update

	/**
	* [TRASH]
	*
	* @param {Project} project		Project data
	*/
	public trash(project: Project): Promise<any> {

		project.trash = 1;
		return this.update(project);

	}	// End-of trash

	/**
	* [RECOVER]
	*
	* @param {Project} project		Project data
	*/
	public recover(project: Project): Promise<any> {

		project.trash = 0;
		return this.update(project);

	}	// End-of recover

	/**
	* [REMOVE]
	*
	* @param {Project | number} project		Project data or id
	*/
	public remove(project: Project | number): Promise<any> {

		let url = '/projects/' + ((typeof project === 'object') ? project["id"] : project);
		return this.httpService.delete(url);

	}	// End-of remove

	/**
	* [GETFILE]
	*
	* @param {ProjectFile | number} prjFile		Project file or id of file
	*/
	public getFile(prjFile: ProjectFile | number): Promise<any> {

		let fileId = (typeof prjFile === 'object') ? prjFile["id"] : prjFile;
		let url = '/project_files/' + fileId + '/data';
		return this.httpService.get(url, { responseType: 'text' });

	}	// End-of getFile

	/**
	* [DOWNLOADFILE]
	*
	* @param {ProjectFile | number} prjFile		Project file or id of file
	*/
	public downloadFile(prjFile: ProjectFile | number): Promise<any> {

		let fileId = (typeof prjFile === 'object') ? prjFile["id"] : prjFile;
		let url = '/project_files/' + fileId;
		return this.httpService.get(url, { responseType: 'blob' });

	}	// End-of downloadFile

	/**
	* [GETFILELIST]
	*
	* @param {Project} project		Project to get file list of
	*/
	public getFileList(project: Project | number): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let url = '/project_files';
		let body = { project_id: projectId };
		return this.httpService.get(url, body);

	}	// End-of getFileList

	/**
	* [ADDFILE]
	*
	* @param {Project | number} project		Project to add to
	* @param {File} file					File to add
	* @param {Function} progressCallback	Callback on upload updates
	*/
	public addFile(project: Project | number, file: any, fileName: string, progressCallBack?: Function): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;

		let formData = new FormData();
		formData.set('file', file);

		let url = '/projects/' + projectId + '/project_files/' + fileName;
		return this.httpService.post(url, formData);

	}	// End-of addFile

	public addFileWithPercentageV2(project: Project | number, file: any, fileName: string): Observable<HttpEvent<any>> {

		const projectId = (typeof project === 'object') ? project["id"] : project;
		let formData = new FormData();
		formData.set('file', file);

		const url = '/projects/' + projectId + '/project_files/' + fileName;
		return this.httpService.postProgress(url, formData);

	}

	/**
	* [UPDATEFILE]
	*
	* @param {ProjectFile} prjFile		Project file to update
	*/
	public updateFile(prjFile: ProjectFile): Promise<any> {

		let url = '/project_files/' + prjFile["id"];
		return this.httpService.put(url, prjFile);

	}	// End-of updateFile

	public trashFile(prjFile: ProjectFile): Promise<any> {
		const trashedFile = Object.assign({}, prjFile, {trash: 1});
		return this.updateFile(trashedFile);
	}

	public recoverFile(prjFile: ProjectFile): Promise<any> {
		const recoveredFile = Object.assign({}, prjFile, {trash: 0});
		return this.updateFile(recoveredFile);
	}

	/**
	* [REMOVEFILE]
	*
	* @param {ProjectFile | number} projectFile		Project file to remove
	*/
	public removeFile(prjFile: ProjectFile | number): Promise<any> {

		let fileId = (typeof prjFile === 'object') ? prjFile["id"] : prjFile;
		let url = '/project_files/' + fileId;
		return this.httpService.delete(url);

	}	// End-of removeFile

	/**
	* [GETEXPORT]
	*
	* @param {Object | number} exportFile		Export file or id of export
	*/
	public getExport(prjExport: Object | number): Promise<any> {

		let exportId = (typeof prjExport === 'object') ? prjExport["id"] : prjExport;
		let url = '/exports/' + exportId;
		return this.httpService.get(url);

	}	// End-of getExport

	/**
	* [GETEXPORTLIST]
	*
	* @param {Project} project		Project to get export list of
	*/
	public getExportList(project: Project | number): Promise<any> {

		let projectId = (typeof project === 'object') ? project["id"] : project;
		let url = "/exports";
		let body = { project_id: projectId };
		return this.httpService.get(url);

	}	// End-of getExportList

	/**
	* [UPDATEXPORT]
	*
	* @param {Object} prjExport		Export to update
	*/
	public updateExport(prjExport: Object): Promise<any> {

		let url = '/exports/' + prjExport["id"];
		return this.httpService.put(url, prjExport);

	}	// End-of updateExport

	/**
	* [REMOVEEXPORT]
	*
	* @param {Object} prjExport		Export to remove
	*/
	public removeExport(prjExport: Object | number): Promise<any> {

		let exportId = (typeof prjExport === 'object') ? prjExport["id"] : prjExport;
		let url = '/project_files/' + exportId;
		return this.httpService.delete(url);

	}	// End-of removeExport

	public addBatch(projectId: number, batchId: number): Promise<any> {

		let url = '/projects/' + projectId + '/batches';
		return this.httpService.post(url, { batch_id: batchId });

	}	// End-of addBatch

	public removeBatch(projectId: number, batchId: number): Promise<any> {

		let url = '/projects/' + projectId + '/batches' + batchId;
		return this.httpService.delete(url);

	}	// End-of removeBatch

	public getProcessingCost(project_id: number, batch_ids: Array<number>): Promise<any> {

		const batchIdQuerys = batch_ids.map( id => `batch_ids=`+id ).join('&')
		let url = `/projects/${project_id}/processing_cost?${batchIdQuerys}`;
		return this.httpService.get(url);

	}	// End-of getProcessingCost

}	// End-of class ProjectService
