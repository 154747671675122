/* Imports */
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Alert, Project } from '@shared/models';
import { FormControl } from '@angular/forms';
import { not, AlertService, ProjectService, AuthenticationService, OrganizationService, PermissionService, ShareService, SessionService } from '@shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import pluralize from 'pluralize';
import { ConfirmationModal } from "@app/components/confirmation";

@Component({
	selector: 'app-share',
	templateUrl: './share.component.html',
	styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

	@ViewChild(MatStepper) stepper: MatStepper;

	public shareUserList: Array<any> = [];
	public orgUserList: Array<any> = [];
	public orgDisplayList: Array<any> = [];
	public project: Project;

	public invitedList = [];
	public showPlaceholder: boolean = true;
	public showInvited: boolean = false;
	public displayError: string = '';
	public displayMessage: string = '';

	public permissionList: Array<any> = [];
	public userPermissions: string = 'process';
	public shareHistory: Array<string> = [];
	public showSuccessMessage: boolean = false;
	public filteredEmailSuggestions: Observable<any[]>;
	public emailControl: FormControl = new FormControl();

	public onlySharedWithYou = true;
	public selectedTabIndex: number = 0;
	public shareLink: string = null;

	constructor (
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _shareService: ShareService,
		private _permissionService: PermissionService,
		private _cdr: ChangeDetectorRef,
		private _clipboard: Clipboard,
		public _dialogRef: MatDialogRef<ShareComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.permissionList = this._permissionService.getPermissionList();
		this.project = data.project;
		this.showInvited = data.showShareList;

		const orgId = this.project.organization_id;
		orgId && this._orgService.getUserList(orgId).then(rtnList => {
			this.orgUserList = rtnList;
			this.onlySharedWithYou = false;
			this.shareUserList = this.handleShares(rtnList, this.project["shares"]);
			this.setDisplayList();
		}, err => {
			// If you do not have access, assume this was shared with you and you can't get info
			this.onlySharedWithYou = this.project["shares"].find(x => x.user.id === this._authService.user.id)?.permissions !== "admin";
			this.shareUserList = this.onlySharedWithYou ? [this._authService.user] : this.handleShares([], this.project["shares"]);
			this.setDisplayList();
		})
	}

	ngOnInit() {

		this.shareHistory = SessionService.get('share_email_list') || [];

		this.filteredEmailSuggestions = this.emailControl.valueChanges
			.pipe(
				startWith(''),
				map(email => email ? this._filterEmails(email) : this.shareHistory.slice(0, 8))
			);
	}	// End-of ngOnInit

	setPlural(text, number): string {
		return pluralize(text, number);
	}


	handleShares = (shareUserList: Array<any>, shares: Array<any> = []): Array<any> => {
		const orgUserIds = shareUserList.reduce(
			(acc, u) => ((acc[u.id] = true), acc),
			{}
		);
		const isExistingUser = (u) => orgUserIds[u.id];
		shares = shares
			.filter((s) => not(isExistingUser(s.user)))
			.map((s) => ({ ...s.user, role: s.permissions, shareId: s.id }));

		return [...shareUserList, ...shares];
	};


	private _filterEmails(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.shareHistory.filter(email => email.toLowerCase().indexOf(filterValue) === 0);
	}

	setDisplayList(): void {
		if (this.shareUserList.length > 2) {
			this.orgDisplayList.push(...this.shareUserList.slice(0, 3));
		} else {
			this.orgDisplayList.push(...this.shareUserList);
		}

		this._cdr.detectChanges();
	}

	addInviteToList(): void {

		const inviteEmail = this.emailControl.value;
		const emailIsUser = this.checkEmailInList(this.orgUserList, inviteEmail);
		const emailIsShare = this.checkEmailInList(this.project["shares"].map(share => share.user), inviteEmail);

		if (emailIsUser || emailIsShare) {
			const confirmOptions = {
				title: emailIsUser ? `Email already exists` : `Email already shared`,
				text: emailIsUser ? `${inviteEmail} already has access to ${this.project.name}.` : `${this.project.name} has already been shared with ${inviteEmail}.`,
				buttonText: `Ok`,
				showCancel: false
			};
			let dialogRef = this._dialog.open(ConfirmationModal, {data: confirmOptions})
			dialogRef.afterClosed().subscribe(() => {
				this.emailControl.setValue('');
				this._cdr.detectChanges();
			})

		} else if (this.checkEmail(inviteEmail)) {
			this.displayError = null;
			this.invitedList.push({email: inviteEmail, role: this.userPermissions, text: inviteEmail.split('@')[0]})
			this.emailControl.setValue('');
			this._cdr.detectChanges();
		} else {
			this.displayError = 'This is not a valid email address. Please check what has been entered.';
		}
	}

	removeInviteFromList(invite): void {
		let ind = this.invitedList.findIndex(x => x.email == invite.email);
		this.invitedList.splice(ind, 1);
	}

	editInvite(invite): void {
		let ind = this.invitedList.findIndex(x => x.email == invite.email);
		this.invitedList.splice(ind, 1);
		this.emailControl.setValue(invite.email);
		this._cdr.detectChanges();
	}

	sendInvites(): void {
		if (this.checkEmail(this.emailControl.value)) this.addInviteToList();
		let emailList = [];
		this.invitedList.forEach(invite => {
			this.sendInvite(invite);
			emailList.push(invite.email);
		})
		this.addEmailsToLocalStorage(emailList);
		analyticsLayer.trackShareProject("email", this.project);
	}

	sendInvite(invite): void {

		this._shareService.create({project_id: this.project.id, email: invite.email, permissions: invite.role}).then(rtn => {
			this.showSuccessMessage = true;
		}, err => {
			this.displayError = 'One or more emails are already registered or not a valid address.';
			console.error(err)
		})
	}

	closeShare(): void {
		this._dialogRef.close('account')
	}

	checkEmail(email): boolean {
		return (/\S+@\S+\.\S+/.test(email));
	}

	checkEmailInList = (emailList, email) => emailList.some(x => x.email?.toLowerCase() === email.toLowerCase());

	hideText(e): void {
		this.showPlaceholder = !e;
	}

	getRole(role): any {
		let ind = this.permissionList.findIndex(x => x.value == role);
		if (ind >= 0) {
			return this.permissionList[ind];
		} else {
			return this.permissionList[this.permissionList.length-1];
		}
	}

	addEmailsToLocalStorage(emailList): void {
		const newList = [...emailList, ...(this.shareHistory.filter((item) => emailList.indexOf(item) < 0))];
		SessionService.set('share_email_list', newList);
	}

	removeShare(user: any): void {
		this._shareService.remove(user.shareId).then(rtn => {
			this._alertService.success(new Alert(`Project ${this.project.name} is no longer shared with ${user.first_name} ${user.last_name}`))
			this.shareUserList = this.shareUserList.filter(x => !x.shareId || x.shareId !== user.shareId);
		}).catch(err => {
			console.error(err);
		})
	}

	getOrgViewList(list: Array<any>): string {
		return list.map( u => `${u.first_name} ${u.last_name}` ).join(', ');
	}

	createLink = (): Promise<string> => {
		return this._shareService.create({project_id: this.project.id, permissions: this.userPermissions}).then(rtnShare => {
			this.shareLink = window.location.origin + '/#/projects/list?share_guid=' + rtnShare.guid;
			analyticsLayer.trackShareProject("link", this.project);
			return this.shareLink;
		}).catch(err => {
			this.displayError = 'Could not create the share link, please try again.';
			console.error(err)
			return Promise.reject(err);
		})
	}

	copyLink(shareLink): void {
		this._clipboard.copy(shareLink);
		this.displayMessage = 'Successfully copied the link';
	}

	enableInvite = (): boolean =>
		!!this.invitedList?.length || this.checkEmail(this.emailControl.value);

	updateShareLink = (link: string): void => {
		this.shareLink = link;
	}

}	// End-of class TestComponent
