import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { Flex } from "../../../components/layout";
import { theme } from "../../../theme";

const RadioStyles = {
	border: "1px solid #D0D0D0",
	borderRadius: 1,
	boxShadow: 1,
	m: 0,
	padding: theme.spacing(2),
};

export const Step1 = ({ selection, setSelection }) => (
	<Flex
		sx={{
			alignItems: "center",
			flexDirection: "column",
			gap: theme.spacing(1),
			justifyContent: "center",
		}}
	>
		<Typography variant="h3" fontWeight="fontWeightBold">
			Learn more about your data with Mapware AI
		</Typography>
		<Typography variant="h6">
			Mapware will analyze your data and add a corresponding overlay to
			your map.
		</Typography>

		<FormControl sx={{ paddingTop: "6rem" }}>
			<RadioGroup
				name="radio-buttons-group"
				value={selection}
				onChange={(e) => setSelection(e.target.value)}
				sx={{ display: "flex", gap: theme.spacing(2) }}
			>
				<FormControlLabel
					sx={{
						"&:hover": {
							boxShadow: 3,
							transform: "translateY(-5px) scale(1.01)",
						},
						...RadioStyles,
						transition: theme.transitions.create([
							"box-shadow",
							"transform",
						]),
					}}
					value="existing-data"
					control={<Radio />}
					classes={{ label: "H6Black500Regular", root: "label-base" }}
					label="I want to analyze data that I’ve already uploaded or processed in Mapware"
				/>
				<FormControlLabel
					disabled
					sx={{
						...RadioStyles,
						padding: theme.spacing(2),
					}}
					value="upload-data"
					control={<Radio />}
					classes={{ label: "H6Black500Regular", root: "label-base" }}
					label="I want to process or upload new data to Mapware for AI analysis"
				/>
			</RadioGroup>
		</FormControl>
	</Flex>
);
