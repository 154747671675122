<mat-card appearance="outline" class="card-container">
	<div class="dialog-base">
		<div class="title">
			Manage Tags
			<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
		</div>
		<form>
			<app-tags
				class="tags"
				[items]="projectTags"
				[tagList]="tagList"
				(itemsChanged)="projectTags=$event;"
				pristine-text="Type to search for an existing tag or create a new one"
				dirty-text="Enter another tag"
				hint-text="Hit 'enter' to add tag">
			</app-tags>
			<div style="display: flex; margin-top: 32px;">
				<button mat-button matDialogClose class="link"
					style="margin-left: auto;">
					Cancel
				</button>
				<button mat-stroked-button color="primary" matDialogClose
					(click)="updateTags()"
					style="margin-left: 32px;"
					[disabled]="projectTags.length == 0">
					Update
				</button>
			</div>
		</form>
	</div>
</mat-card>

<!-- [items]="projectTags" (itemsChanged)="projectTags=$event;" -->
