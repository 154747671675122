/* Imports */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input, OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ImportDialog } from "@app/components/import-dialog/import.dialog";
import { UploadDialog } from "@app/components/upload-dialog/upload.dialog";
/* Router */
import { Router } from "@angular/router";

/* Services */
import {
	AlertService,
	AuthenticationService,
	GlobalService,
	ModelService,
	OrganizationService,
	PermissionService,
	ProjectService,
	UploadService,
	isFulfilled,
	sortDate,
	sortDateReversed,
	sortBooleanByVar, byId
} from '@shared/services';
import { Alert, Model, Project } from "@shared/models";
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";
import { verifyAndUpdateModelStatuses } from "./verifyAndUpdateModelStatuses";
import _, {cloneDeep, isEmpty, isNil} from 'lodash';
import moment, {Moment} from 'moment/moment';

@Component({
	selector: "app-models-projects",
	templateUrl: "./models.projects.component.html",
	styleUrls: ["./models.projects.component.scss"],
})
export class ModelsProjectsComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	public user: any;
	public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	public isProcessing: boolean = true;
	public displayedColumns: string[] = [
		"name",
		"options",
	];
	public isActive: Array<boolean> = [false];
	public trialExpired: boolean = false;
	public _project: Project;
	public rootURL = GlobalService.databaseApiUrl;
	public getProjectPromise: Promise<any> = null;

	public sort: "name" | "created_at" = "created_at";
	public sortDirectionUp: boolean = false; // "up" starts the 'smallest' value at the beginning of the list
	// e.g. ["hall", "ceiling", "floor"] with a "name" sort, "up" sorts to -> ["ceiling", "floor", "hall"]

	public sortOptions = [
		{ text: "Name", value: "name" },
		{ text: "Date", value: "created_at" },
	]
	public projectListSubscription: any;

	private _loopTime: number = 15000; // 15 second loop
	private _delayTime: number = 200; // 200ms delay

	constructor(
		private _alertService: AlertService,
		private _modelService: ModelService,
		private _permissionService: PermissionService,
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
		private _projectService: ProjectService,
		private _uploadService: UploadService
	) {
		if (
			flagLayer.isEnabled(availableFeatureFlags.projectViewExportLayers)
		) {
			this.displayedColumns.unshift("toggle");
		}
	} // End-of constructor

	@Output() projectChange: EventEmitter<any> = new EventEmitter<any>();
	@Input() set project(proj: Project) {
		if (proj) {
			if (proj?.id && proj.id !== this._project?.id) {
				this.dataSource = new MatTableDataSource<any>();
				this._project = proj;
				this.getProject(proj);
				this.setup(proj);
				this.getProjectLoop();
			}
		}
	}
	get project(): Project {
		return this._project;
	}

	ngOnInit() {
		this.user = this._authService.user;
		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({renderState}) => {
			this.trackAndUpdateUploads(renderState);
		});
	} // End-of ngOnInit

	trackAndUpdateUploads(renderState) {

		const project = this.project ? renderState.projectList?.find(byId(this.project.id)) : null;

		if (project) {
			this.getProject(project);
		}
	}

	ngAfterViewInit() {
		this._cdr.detectChanges();
	}

	ngOnDestroy() {
		this.projectListSubscription?.unsubscribe();
	}

	getProject(project) {
		if (!this.getProjectPromise) {
			this.getProjectPromise = (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)
				? this._projectService.getByIdV2(project.id)
				: this._projectService.getById(project.id))
					.then((rtnProj) => {
						this.setup(rtnProj);
						this.getProjectPromise = null;
					})
					.catch((err) => {
						console.error(err);
						this.getProjectPromise = null;
						this._alertService.error(
							new Alert("Could not reload project")
						);
					});
		}
	}

	refreshProject(): void {
		this.isProcessing = true;
		setTimeout(() => {
			if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
				this.setDataSource(this.project, true).catch(console.error);
			} else {
				this.getProject(this._project);
			}
		}, this._delayTime); // Fixed delay to give user more evident appearance of refresh, AND for refresh animation
	}

	getProjectLoop() {
		setTimeout(() => {
			if (this.anyModelsNonRendered(this.dataSource.data)) {
				if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)) {
					this.setDataSource(this.project, true).catch(console.error);
				} else {
					this.getProject(this._project);
				}
			} // No else, but this function will continue looping to re-check in case the user refreshed manually
			this.getProjectLoop();
		}, this._loopTime);
	}

	setup(proj: Project): void {

		let project = cloneDeep(proj); // data in the redux store is effectively frozen to prevent confusion, so if we want to add anything for the purpose of the Ng form, we need a new version

		this._orgService.getTrialDays().subscribe((rtn) => {
			if (!isNaN(parseInt(rtn))) {
				this.trialExpired = false;
			}
		});
		this.setDataSource(project).catch(console.error);

		this._modelService.getProcessList().subscribe((rtnList) => {
			if (rtnList?.length) {
				rtnList
					.filter((x) => x.trash === 0 && x.active === 1)
					.forEach((model) => {
						this.project.models.find(
							(x) => x.id == model.id
						).status = model.status;
					});
			}
			this._cdr.detectChanges();
		});
	} // End-of setup

	async setDataSource(project, forceRefresh = false) {

		let models = project.models;
		const hasExistingModels = this.hasDeepModels(project.models);

		if (flagLayer.isEnabled(availableFeatureFlags.apiV2Routes) && (forceRefresh || !hasExistingModels)) {
			const shallowModels = await this._modelService.getListV2({ project_id: project.id }) ?? [];
			const filteredModels = shallowModels.filter(x => (x.active && !x.trash));
			models = await this.getDeepModels(filteredModels);
		}

		const filteredModels = models.filter(x => ((typeof x.id === "number") && x.active && !x.trash));
		filteredModels
			.forEach((val, ind) => (val["index"] = ind));

		this.isProcessing = false;
		this.project.models = filteredModels;
		this.projectChange.emit({ project: this.project, type: "models" });

		filteredModels.forEach((model) => {
			model.thumbnail = this.findModelThumbnail(model);
		});

		const verified = verifyAndUpdateModelStatuses(
			filteredModels,
			flagLayer.isEnabled(availableFeatureFlags.betaLayers)
		).map(gatherLayers);

		this.sortList(verified);
	}

	findModelThumbnail = (model) => {
		const thumbnailString = model?.geotiffs?.find(x => x.type === "orthomosaic" && x.thumbnail)?.thumbnail;
		return thumbnailString ? this.rootURL + thumbnailString : null;
	}

	hasDeepModels = (models) => {
		return models?.length && models.some(x => x.layers?.length);
	}

	async getDeepModels(models): Promise<any> {
		return Promise.allSettled(models.map(model => this._modelService.getModelV2(model.id)))
			.then(result => {
				return result.reduce((acc, res) => {
					if (isFulfilled(res)) {
						acc.push(res.value) }
					else console.error(res.reason);
					return acc;
				}, []);
			})
	}

	sortList(list: Array<Model>, filterIds = true): void {

		const newList = list
			.filter(model => model.active && !model.trash);

		const oldList = this.dataSource.data ?? [];

		const merged = _.values(_.merge(_.keyBy(oldList, 'id'), _.keyBy(newList, 'id')))
			.filter(x => x.active && !x.trash && (filterIds ? (typeof x.id === "number") : true))
			.sort((a, b) => {
				let aVal = a[this.sort], bVal = b[this.sort];
				if (this.sort === "created_at") {
					return this.sortDirectionUp ?
						(new Date(aVal).getTime() - new Date(bVal).getTime()) :
						(new Date(bVal).getTime() - new Date(aVal).getTime());
				} else if (typeof aVal === "number") {
					return this.sortDirectionUp ?
						(aVal - bVal) :
						(bVal - aVal);
				}
				return this.sortDirectionUp ?
					(aVal.localeCompare(bVal)) :
					(bVal.localeCompare(aVal));
			});

		this.dataSource.data = [...merged];

	}	// End-of sortData

	onUpdate(event, ind): void {
		if (event === "trash") {
			this.removeModel(ind);
		}
	}

	anyModelsNonRendered(modelList: Model[]): boolean {
		return modelList.some(model => {
			const hours = this.getElapsedTime(moment(model.created_at)).hours() ?? 3;
			if (isEmpty(model.layers) && hours > 2) return false;
			return model.layers?.some((layer) => layer.status.toLowerCase() !== "rendered")
		});
	}

	removeModel(ind): void {
		let oldList = [...this.dataSource.data];
		oldList.splice(ind, 1)
		this.dataSource.data = oldList;
		this.project.models = oldList;
		this.projectChange.emit({ project: this.project, type: "models" });
	}

	getElapsedTime(
		elapsedMoment: Moment,
		baseMoment: Moment = moment()
	) {
		return moment.duration(elapsedMoment.diff(baseMoment));
	}

	setSort(sort) {
		if (this.isSort(sort)) {
			this.toggleSortDirection();
			this.sortList(this.dataSource.data);
		} else {
			this.sort = sort;
			this.sortDirectionUp = true;
			this.sortList(this.dataSource.data);
		}
	}

	toggleSortDirection() {
		this.sortDirectionUp = !this.sortDirectionUp;
	}

	isSort(sort) {
		return this.sort === sort;
	}

	checkSortAndDir(sort): boolean {
		return !this.isSort(sort) || this.sortDirectionUp;
	}

} // End-of class ModelsProjectsComponent

const sortByOldestToNewest = (a, b) => Math.sign(a.created_at - b.created_at);

const nameMapping = {
	orthomosaic: "Orthomosaic",
	dem: "DSM",
	dense: "Dense Point Cloud",
	sparse: "Sparse Point Cloud",
	_default: "N/A",
};

const addDataTypeLabel = (layer) => {
	layer.dataTypeLabel = nameMapping[layer.type] ?? nameMapping._default;
	return layer;
};

addDataTypeLabel.potree = (layer) => {
	layer.dataTypeLabel = nameMapping[layer.type] ?? nameMapping._default;
	layer.originTable = "potree";
	return layer;
};

addDataTypeLabel.geotiff = (layer) => {
	layer.dataTypeLabel = nameMapping[layer.type] ?? nameMapping._default;
	layer.originTable = "geotiff";
	return layer;
};

addDataTypeLabel.cesium = (layer) => {
	layer.dataTypeLabel = "3D Model";
	layer.originTable = "cesium";
	return layer;
};

addDataTypeLabel.overlays = (layer) => {
	layer.dataTypeLabel = "Overlay";
	layer.originTable = "overlay";
	if (flagLayer.isEnabled(availableFeatureFlags.aiResults)) {
		if (layer.name.includes("_results.kml")) {
			layer.dataTypeLabel = "AI Results";
		}
	}
	return layer;
};

const isValidGeo = (geo: any): boolean =>
	(geo.north || geo.south) &&
	(geo.east || geo.west) &&
	geo.url &&
	geo.zoom &&
	geo.minZoom;

function gatherLayers(model) {
	const { potree_files = [], cesium_files = [], geotiffs = [], overlays = [] } = model;
	model.layers = [
		...potree_files
			.filter((file) => file.active)
			.sort(sortByOldestToNewest)
			.map(addDataTypeLabel.potree),
		...overlays
			.filter((file) => file.active)
			.sort(sortByOldestToNewest)
			.map(addDataTypeLabel.overlays),
		...cesium_files
			.filter((file) => file.active)
			.sort(sortByOldestToNewest)
			.map(addDataTypeLabel.cesium),
		...geotiffs
			.filter((file) => file.active)
			.sort(sortByOldestToNewest)
			.map(addDataTypeLabel.geotiff)
			.map((geo) => {
				geo.status = isValidGeo(geo) ? "Rendered" : "Incomplete";
				return geo;
			}),
	];

	return model;
}
