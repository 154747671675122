import { cloneDeep } from "lodash";

export const getOrthos = (project) =>
	project.models
		.filter((x) => x.trash === 0 && x.active === 1)
		.flatMap((m) =>
			m?.geotiffs
				?.filter((g) => g.type === "orthomosaic")
				.map((o) =>
					Object.assign(cloneDeep(o), {
						fullName: `${m.name} - ${o.name}`,
					})
				)
		);
