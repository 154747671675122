import { createPortal } from "react-dom";
import { connect } from "react-redux";

export function _Portal({ isActive, selector, children }) {
	if (!isActive) return null;

	return createPortal(
		children,
		document.querySelector(`[data-portal-hook="${selector}"]`)
	);
}
function mapStateToProps(state, { selector }) {
	return { isActive: state.portalRegistry[selector] };
}

export const Portal = connect(mapStateToProps)(_Portal);
