/* Imports */
import { Component, OnInit } from "@angular/core";
import { Annotation } from "@shared/models/annotation.model";
import { api } from "@app/react/features/Annotations/api";
import { store } from "@app/store";
import { ProjectService } from "@shared/services";
import { cloneDeep } from "lodash";

@Component( {
	selector: "app-annotations",
	templateUrl: "./annotations.component.html",
	styleUrls: [ "./annotations.component.scss" ]
} )
export class AnnotationsComponent implements OnInit {
	public projects: Array<any> = [];
	public createdAnnotation: string = null;
	public reactMode = false;

	constructor( private _projectService: ProjectService ) {
		_projectService.getList().then( ( rtn ) => {
			rtn.map( ( project ) => ( project.annotationName = "Annotation Name" ) );
			this.projects = rtn;
		} );
	} // End-of constructor

	ngOnInit() {
	} // End-of OnInit

	getAnnotations( project ) {
		setTimeout( () => {
			store
				.dispatch( api.endpoints.getList.initiate( project.id ) )
				.then( ( { data } ) => {
					if ( data ) {
						const annotations = cloneDeep( data );
						project.annotations = annotations.map( ( annotation ) => {
							annotation.annotationName = "New Name";
							return annotation;
						} );
					}
				} )
				.catch( console.error );
		}, 200 ); // Delay required to load the data because of async issues (even with .then)
	}

	getAnnotationsDelayed( project ) {
		setTimeout( () => {
			this.getAnnotations( project );
		}, 200 ); // Delay required to load the data because of async issues (even with .then)
	}

	makeAnnotation( project ) {
		const annotation = new Annotation( {
			project_id: project.id,
			name: project.annotationName,
			type: "Test",
			details: "Test",
			color: "ff00ff",
			model_references: [ 5 ],
			descriptors: {
				connected: true,
				points: [ { lat: 30, lng: 30, alt: 30 } ]
			}
		} );

		store
			.dispatch( api.endpoints.create.initiate( annotation ) )
			.then( ( data ) => {
				this.createdAnnotation = JSON.stringify( data );
				this.getAnnotationsDelayed( project );
			} )
			.catch( console.error );
	}

	updateAnnotation( annotation, project ) {
		const updates = { name: annotation.annotationName };
		store
			.dispatch(
				api.endpoints.update.initiate( {
					annotation_id: annotation.id,
					updates
				} )
			)
			.then( () => {
				this.toggleEdit( annotation );
				this.getAnnotationsDelayed( project );
			} )
			.catch( console.error );
	}

	getAnnotation( annotation ) {
		return store.dispatch( api.endpoints.get.initiate( annotation.id ) );
	}

	deleteAnnotation( annotation, project ) {
		return store
			.dispatch( api.endpoints.delete.initiate( annotation.id ) )
			.then( () => {
				this.getAnnotationsDelayed( project );
			} )
			.catch( console.error );
	}

	toggleEdit( annotation ): void {
		Object.assign( annotation, { isEdit: !annotation.isEdit } );
	}

	getColor( reactMode, isReactButton ) {
		return {
			backgroundColor:
				reactMode === isReactButton ? "var(--blue-300)" : ""
		};
	}
} // End-of class NgcAvatar
