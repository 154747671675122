
/* Imports */
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

export interface queryParams {
	[name: string]: string
};

@Injectable()
export class UrlService {

	private _queryParams: any = {};
	private _url: any;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
	) {
		this._route.queryParams.subscribe(params => {
			this._queryParams = params;
		});

		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this._url = event.url;
			}
		})
	}

	private async _getCurrentUrl(): Promise<any> {
	}

	async getUrl(): Promise<any> {
		return this._url?.split('?')[0];
	}

	async getQueryParams(): Promise<any> {
		return this._queryParams;
	}

	navigateWithParams(url: string, queryParams?: queryParams): void {
		this._router.navigate([url], { queryParams })
			.catch(err => console.error(err));
	}

	applyParams(queryParams: queryParams): void {
		const newParams = { ...this._queryParams, ...queryParams };
		this._queryParams = newParams;

		this.getUrl().then(rtnUrl => {
			this._router.navigate([rtnUrl], { queryParams: newParams })
				.catch(err => console.error(err));
		});
	}
}
