var n,
  e = "function",
  t = "string",
  o = "undefined",
  r = "boolean",
  i = "object",
  u = "array",
  c = "number",
  a = function () {},
  f = "any",
  l = "*",
  s = "none",
  d = "hidden",
  p = "__",
  y = "form",
  g = "input",
  b = "button",
  m = "select",
  v = "change",
  j = "submit",
  h = "undefined" != typeof process ? process : {},
  A = (null == (n = h.env) ? void 0 : n.NODE_ENV) || "",
  O = "production" === A,
  w = "staging" === A,
  Z = "development" === A,
  z = "undefined" != typeof window,
  N = z && "localhost" === window.location.hostname,
  P = null != h.versions && null != h.versions.node,
  S = "undefined" != typeof Deno && void 0 !== Deno.core,
  D = "object" == typeof self && self.constructor && "DedicatedWorkerGlobalScope" === self.constructor.name,
  E = z && "nodejs" === window.name || "undefined" != typeof navigator && (navigator.userAgent.includes("Node.js") || navigator.userAgent.includes("jsdom"));
function C(n, e) {
  return typeof e === n;
}
var _ = C.bind(null, "function"),
  L = C.bind(null, "string"),
  $ = C.bind(null, "undefined"),
  x = C.bind(null, "boolean");
function F(n) {
  return null === n;
}
function T(n) {
  return !isNaN(parseFloat(n));
}
function k(n) {
  return !!_(n) && /^class /.test(Function.prototype.toString.call(n));
}
function G(n) {
  return "[object Array]" === Object.prototype.toString.call(n);
}
function H(n) {
  return n && ("object" == typeof n || null !== n);
}
function M(n) {
  if (!H(n)) return !1;
  for (var e = n; null !== Object.getPrototypeOf(e);) e = Object.getPrototypeOf(e);
  return Object.getPrototypeOf(n) === e;
}
function R(n) {
  if (F(n)) return !0;
  switch (typeof n) {
    case "string":
    case "number":
    case "symbol":
    case "undefined":
    case "boolean":
      return !0;
    default:
      return !1;
  }
}
function U(n) {
  return !!n && !!(!$(Promise) && n instanceof Promise || n.then && _(n.then));
}
function V(n) {
  return n instanceof RegExp;
}
function W(n) {
  return H(n) && L(n.message) && L(n.name);
}
function q(n) {
  if (!_(n)) return !1;
  var e = /{(\r|\n|\s)*}/gm,
    t = a + "";
  return t === (n.toString().match(e) || [""])[0].replace(e, t);
}
function B(n) {
  return !(L(n) && "false" === n.toLowerCase() || !n);
}
function I(n) {
  return !(n.length > 320) && /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(n);
}
function J(n) {
  return /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/.test(n);
}
function K(n) {
  return NodeList.prototype.isPrototypeOf(n);
}
function Q(n, e) {
  var t = n instanceof Element || n instanceof HTMLDocument;
  return t && e ? X(n, e) : t;
}
function X(n, e) {
  return void 0 === e && (e = ""), n && n.nodeName === e.toUpperCase();
}
function Y(n) {
  return function () {
    return n.apply(void 0, [].slice.call(arguments).concat([].slice.call(arguments, 1)));
  };
}
var nn = Y(Q),
  en = Y(Q),
  tn = Y(Q),
  on = Y(Q);
function rn(n, e) {
  if (!n || "hidden" === getComputedStyle(n).visibility) return !0;
  for (; n;) {
    if (null != e && n === e) return !1;
    if ("none" === getComputedStyle(n).display) return !0;
    n = n.parentElement;
  }
  return !1;
}
export { l as ALL, f as ANY, u as ARRAY, r as BOOLEAN, b as BUTTON, v as CHANGE, A as ENV, y as FORM, e as FUNCTION, d as HIDDEN, g as INPUT, s as NONE, c as NUMBER, i as OBJECT, p as PREFIX, m as SELECT, t as STRING, j as SUBMIT, o as UNDEFINED, G as isArray, x as isBoolean, z as isBrowser, en as isButton, k as isClass, S as isDeno, Z as isDev, Q as isElement, I as isEmail, W as isErrorLike, nn as isForm, _ as isFunction, rn as isHidden, tn as isInput, J as isIsoDate, E as isJsDom, N as isLocalHost, q as isNoOp, P as isNode, K as isNodeList, X as isNodeType, F as isNull, T as isNumber, M as isObject, H as isObjectLike, R as isPrimitive, O as isProd, U as isPromise, V as isRegex, on as isSelect, w as isStaging, L as isString, B as isTruthy, $ as isUndefined, D as isWebWorker, a as noOp, C as typeOf };
