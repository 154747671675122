
<circle-progress
		[percent]="percentage"
		[radius]="10"
		[space]="-4"
		[outerStrokeWidth]="4"
		[innerStrokeWidth]="4"
		[outerStrokeColor]="'#00000087'"
		[innerStrokeColor]="'#00000029'"
		[animation]="true"
		[startFromZero]="false"
		[animationDuration]="200"
		[showTitle]="false"
		[showSubtitle]="false"
		[showUnits]="false"
		[outerStrokeLinecap]="'butt'">
</circle-progress>
