<mat-list data-testid="project-list-dialog">
	<mat-list-item routerLink="/projects/view/{{project.id}}">
		<mat-icon> zoom_out_map </mat-icon>
		<span style="margin-left: 16px;"> Open Project </span>
	</mat-list-item>
		<mat-divider color="#dee0e3"></mat-divider>

	<ng-container *featureEnabled="'!disable-sharing'">
		<mat-list-item *ngIf="canShare" (click)="closeEvent('share');" [ngClass]="{'disabled': hasPermissionToShare}">
			<mat-icon> people </mat-icon>
			<span style="margin-left: 16px;"> Share </span>
		</mat-list-item>
	</ng-container>
	<mat-list-item *ngIf="!_project['favorited']" (click)="closeEvent('favorite');">
		<mat-icon> star_outline </mat-icon>
		<span style="margin-left: 16px;"> Add to Favorites </span>
	</mat-list-item>

	<mat-list-item *ngIf="_project['favorited']" (click)="closeEvent('favorite');">
		<mat-icon> grade </mat-icon>
		<span style="margin-left: 16px;"> Remove from Favorites </span>
	</mat-list-item>

	<ng-container *ngIf="isProcessRank()">
		<mat-list-item *ngIf="!_project['shared_with_user']" (click)="closeEvent('rename');">
			<mat-icon> text_format </mat-icon>
			<span style="margin-left: 16px;"> Rename </span>
		</mat-list-item>
		<mat-divider color="#dee0e3"></mat-divider>
		<mat-list-item (click)="closeEvent('remove');">
			<mat-icon> delete_outline </mat-icon>
			<span style="margin-left: 16px;"> Delete </span>
		</mat-list-item>
	</ng-container>
</mat-list>
