<div class="confirmation-group" *ngIf="registerForm">
	<div class="group-container">
		<span class="header">Name:</span>
		<span class="body">{{registerForm.value.first_name + ' ' + registerForm.value.last_name}}</span>
		<span class="tool" (click)="addSteps(-3)" data-testid="edit-billing">Edit</span>
	</div>
	<!-- <div class="container">
		<span class="header">Organization:</span>
		<span class="body">{{paymentForm.value.cc_org ? paymentForm.value.cc_org : '- -'}}</span>
	</div> -->
</div>

<div class="confirmation-group" *ngIf="paymentForm">
	<div class="group-container">
		<span class="header">Billing information:</span>
		<span class="body">
			<div>{{paymentForm.value.cardType  | titlecase }}</div>
			<div>**** **** **** ****</div>
		</span>
		<span class="tool" (click)="addSteps(-1)" data-testid="edit-billing">Edit</span>
	</div>
	<!-- <div class="group-container">
		<span class="header">Billing address:</span>
		<span class="body">
			<div>{{paymentForm.value.address_line1}}</div>
			<div>{{paymentForm.value.address_city}}, {{paymentForm.value.address_state}} {{paymentForm.value.address_zip}}</div>
		</span>
		<span class="tool" (click)="addSteps(-1)" data-testid="edit-address">Edit</span>
	</div> -->
</div>

<div class="confirmation-group">
	<div class="group-container" *ngIf="displayPlan">
		<span class="header">License cost:</span>
		<span class="body">
			{{ displayPlan.price | currency }}
			{{ displayPlan.isYearly ? " / year" : " / month" }}
		</span>
	</div>
</div>
