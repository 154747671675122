/* Imports */
import { Observable, BehaviorSubject } from 'rxjs';

export class CustomState<T> {

	private _state: BehaviorSubject<T>;
	private _filter: Function;

	constructor(data?: T, filter?: (data: T, dataList: T[]) => boolean) {

		this._state = new BehaviorSubject(data ? data : null);
		this._filter = filter ? filter : null;

	}	// End-of constructor

	update(): void {

		this._state.next(this._state.getValue());

	}	// End-of update

	get state(): Observable<T> {

		return this._state.asObservable();

	}	// End-of state

	get data(): T {

		return this._state.getValue();

	}	// End-of data

	set data(inData: T) {

		this._state.next(inData);

	}	// End-of data

	add(inData: any): void {

		if (Array.isArray(this.data)) {
			if (this._filter) {
				if (this._filter(inData, this.data)) {
					this.data.push(inData)
				}
			} else {
				this.data.push(inData)
			}
		} else {
			this.data = inData;
		}

	}	// End-of add

}	// End-of class CustomState