// TODO: Add bootstrapping for immediate initialization without user dependency https://docs.launchdarkly.com/sdk/features/bootstrapping#javascript
/**
 * LaunchDarkly's Docs https://launchdarkly.github.io/js-client-sdk/index.html
 */
import * as LDClient from "launchdarkly-js-client-sdk";
import { environment } from "../../../environments/environment";
import { flagLayer } from "@shared/featureFlags/index";
import { GlobalService } from "@shared/services";
import flags from "./flags.default.json";

let ldClient = null;

const defaultFlags = flags[ "com" ];

async function initializeLD( userContext ) {
	const client = LDClient.initialize(
		environment.launchDarklyClientSideId,
		userContext,
		{
			allAttributesPrivate: true,
			bootstrap: "localStorage" // with this LD will automatically save flags to localstorage and use it as a fallback
		}
	);

	return client
		.waitUntilReady()
		.then( () => {
			ldClient = client;
		} )
		.catch( console.error );
}

export const launchDarklyLayerDef = {
	setContext: async ( newContext ) =>
		( ldClient ? ldClient.identify( newContext ) : initializeLD( newContext ) ).catch( console.error ),
	isEnabled: {
		forceSync: true,
		func: ( flagId ) => {
			// pass the flag id to client.variation and return the result
			if ( ldClient === null ) {
				initializeLD( {
					anonymous: true,
					appName: "development",
					environment: "development"
				} ).catch( console.error );
				console.warn( "⚠️ `isEnabled` has been called before the LD Client was initialized." );
				return defaultFlags[ flagId ];
			}
			try {
				return ldClient.variation( flagId );
			} catch (e) {
				console.error( e );
				return null;
			}
			// TODO: add this when Gitlab is being removed
			// this should only be necessary when the app is loaded for the first time and there's nothing in localstorage for bootstrap
			// ?? env.envName.startsWith("gov") ? defaultFlags.gov[flagId] : defaultFlags.com[flagId]
		}
	}
};
