
/* Imports */
import {
	Component,
	OnInit,
	ViewChild,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { SelectionModel } from '@angular/cdk/collections';

import { GlobalService } from 'src/app/shared/services';
import { Image } from '../../../../shared/models';
import isNil from 'lodash/isNil';
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-photo',
	templateUrl: './photo.component.html',
	styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	@Input('srcImgBands') srcImgBands: any = {};
	@Input('batch') batch: any = {};
	@Input('child') child: any = {};
	@Input('gridDisplay') gridDisplay: boolean = false;
	@Input('favoritesList') favoritesList: Array<any> = [];
	@Input('imageList') imageList: Array<any> = [];
	@Input('index') index: number = 0;
	@Input('disableMove') disableMove: boolean = false;
	@Input() userPermission;

	@Input('selection') selection = new SelectionModel<any>(true, []);
	@Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter();

	@Output('photoEvent') photoEvent: EventEmitter<any> = new EventEmitter();

	public contextMenuPosition = { x: '0px', y: '0px' };
	public menuOpen: boolean = false;
	public usePosition: boolean = true;
	public file_url = GlobalService.databaseApiUrl;
	public imgStatus: string;

	ngOnInit(): void {
		if (!isEmpty(this.batch)) {
			this.setImgStatus();
		}
	}

	setImgStatus(): void {
		this.imgStatus = this.child.status;
		if (this.imgStatus === 'converted' && this.batch.sensor !== 'null') {
			const bandImgs = this.batch.images.filter((image) => image.source_image_id === this.child.id);
			if (bandImgs.some((image) => image.status === 'failed')) {
				this.imgStatus = 'failed';
			} else if (bandImgs.some((image) => image.status !== 'converted')) {
				this.imgStatus = 'converting';
			}
		}
	}

	isFavorite(item): boolean {
		return this.favoritesList.some(fav => fav.image_id === item.id);
	}

	openMenu(e, child, usePosition): void {

		e.preventDefault();
		e.stopPropagation();

		const container = $(`#photo-container-${this.index}`);

		this.usePosition = usePosition;
		this.contextMenuPosition.x = e.pageX - container.offset().left + 'px';
		this.contextMenuPosition.y = e.pageY - container.offset().top + 'px';
		this.menuOpen = true;
		this.menuTrigger._handleClick(e);
	}

	outEvent(type, child): void {
		this.photoEvent.emit({ type, child });
	}

	validateDateTaken(date: string): boolean {
		// validate the date because `| date` doesn't seem to have any built in validation
		return !isNaN(new Date(date).getTime());
	}

	canImageBeProcessed(image) {
		// @ts-ignore
		return Image.canBeProcessed(image);
	}

	getInvalidMessage(image) {
		// @ts-ignore
		return Image.getInvalidMessage(image)
	}

	contextMenuStyle(): any {
		return this.usePosition
			? {
				left: this.contextMenuPosition.x,
				top: this.contextMenuPosition.y,
			}
			: null;
	}

	getPhotoClasses(gridDisplay, menuOpen, child): Array<any> {
		return [
			gridDisplay ? 'table-grid' : 'table-row',
			menuOpen ? 'active-item' : ''
		];
	}

	handleToggle(e, child): void {
		if (e) this.selection.toggle(child);
	}

	waitAndReload(event) {

		const originalSrc = event.target.src;

		if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {

			event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);

			event.target.src = '/assets/images/placeholder.png';

			setTimeout(function () {
				event.target.src = originalSrc;
			}, 2000);
		} else {
			event.target.src = '/assets/images/placeholder.png';
		}
	}
}
